function generateRandomColor() {
  const red = Math.floor(Math.random() * 169);
  const green = Math.floor(Math.random() * 169);
  const blue = Math.floor(Math.random() * 169);

  return `#${red.toString(16).padStart(2, '0')}${green.toString(16).padStart(2, '0')}${blue.toString(16).padStart(2, '0')}`;
}

export const getColor = (modelName: string) => {
  return  {
    'IGNIS': '#eae3d1',
    'SWIFT': '#d41317',
    'SWIFT SPORT': '#efce2b',
    'VITARA': '#ebb04a',
    'S-CROSS': '#2e2e2e',
    'SWACE': '#ebedf4',
    'ACROSS': '#ddddd1',
    'JIMNY': '#becd1b',
    'HAYABUSA': '#cc3000',
    'GSX-R125': '#0063af',
    'GSX-8R': '#8490a3',
    'GSX-S1000GT': '#900d09',
    'KATANA': '#224a73',
    'GSX-8S': '#2596be',
    'SV650': '#354d7e',
    'SV650X': '#9ba0a9',
    'V-STROM 1050DE': '#f1d51f',
    'V-STROM 1050': '#4a5f98',
    'V-STROM 800DE': '#e5d210',
    'V-STROM 800': '#0029c9',
    'V-STROM 650XT': '#e1c900',
    'V-STROM 650': '#2f3380',
    'BURGMAN 400': '#9ba0a9',
    'BURGMAN STREET 125EX': '#343537',
    'AVENIS 125': '#d0d4ce',
    'ADDRESS 125': '#013095',
  }[modelName.toUpperCase()] || generateRandomColor();
}

import React from 'react';
import { WidgetValueRange } from '../model';

export interface WidgetKpiProps {
  text: string;
  value: number;
  valueRange: WidgetValueRange;
  className?: string;
}

const checkForValidRange = (
  kpi: number,
  valueRange: WidgetValueRange
): 'good' | 'warning' | 'info' | 'bad' | null => {
  if (!valueRange) {
    return null;
  }
  let k: 'good' | 'warning' | 'info' | 'bad' | null = null;
  Object.keys(valueRange).forEach((key: string) => {
    if (
      key === 'good' ||
      key === 'bad' ||
      key === 'warning' ||
      key === 'info' ||
      key === null
    ) {
      const range = valueRange[key];
      if (range && kpi <= range.end && kpi >= range.begin) {
        k = key;
      }
    }
  });
  return k;
};

const getColor = (kpi: number, valueRange: WidgetValueRange) => {
  switch (checkForValidRange(kpi, valueRange)) {
    case 'good':
      return valueRange.good && valueRange.good.color ? valueRange.good.color : '#34B233';
    case 'warning':
      return valueRange.warning && valueRange.warning.color ? valueRange.warning.color : '#FFA101';
    case 'info':
      return valueRange.info && valueRange.info.color ? valueRange.info.color : '#5EB6E4';
    case 'bad':
      return valueRange.bad && valueRange.bad.color ? valueRange.bad.color : '#F7403A';
    default:
      return '#C6CED1';
  }
};

export const WidgetKpi = (props: WidgetKpiProps) => {
  return (
    <div className={`${props.className ? props.className : ''}`}>
      <div className={`bg-gray flex`}>
        <div className="p-2 w-7/12">
          <span className="font-suzuki-regular text-dark text-sm">
            {props.text}
          </span>
        </div>
        <div className="w-5/12 flex justify-end">
          <div
            className="p-8 text-center"
            style={{
              backgroundColor: getColor(props.value, props.valueRange),
              minWidth: '110px',
            }}
          >
            <span className="text-3xl text-white font-suzuki-bold font-bold w-full">
              {props.value}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WidgetKpi;

import React from 'react';
import Icon from '../icon/icon';

/* eslint-disable-next-line */
export interface TabProps {
  isActive?: boolean;
  tabIndex?: number;
  title?: string;
  icon?: string;
  onClick?: (index: number) => void;
  children?: React.ReactElement;
}

export const Tab = (props: TabProps) => {
  return (
    <li>
      {props.isActive ? (
        <button
          className="bg-dark text-white  px-10 py-3 border-solid border-b-0 border border-border-gray flex flex-row gap-x-2"
          onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            event.preventDefault();
            if (props.onClick && props.tabIndex !== undefined) {
              props.onClick(props.tabIndex);
            }
          }}
        >
          {props.icon && <Icon iconName={props.icon} />}
          <p>{props.title}</p>
        </button>
      ) : (
        <button
          className="bg-white text-dark  px-10 py-3 cursor-pointer border border-b-0 border-border-gray flex flex-row gap-x-2"
          onClick={(event) => {
            event.preventDefault();
            if (props.onClick && props.tabIndex !== undefined) {
              props.onClick(props.tabIndex);
            }
          }}
        >
          {props.icon && <Icon iconName={props.icon} />}
          <p>{props.title}</p>
        </button>
      )}
    </li>
  );
};

export default Tab;

import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import {useTranslation} from "react-i18next";

interface PDFDisplayProps {
  filename: string;
}

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PDFDisplay: React.FC<PDFDisplayProps> = ({ filename }) => {
  const [numPages, setNumPages] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [scale, setScale] = useState<number>(1.0);
  const { t } = useTranslation();

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
    setPageNumber(1);
  };

  const handleNextPage = () => {
    if (pageNumber < numPages) {
      setPageNumber(pageNumber + 1);
    }
  };

  const handlePreviousPage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  const handleZoomIn = () => {
    setScale(scale + 0.1);
  };

  const handleZoomOut = () => {
    if (scale > 0.1) {
      setScale(scale - 0.1);
    }
  };

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = filename;
    const urlSegments = filename.split('/');
    const pdfFilename = urlSegments[urlSegments.length - 1];
    link.download = pdfFilename; // Set the filename for download
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const calculateHeight = (width: number) => {
    const aspectRatio = 1.4142; // Aspect ratio of A4 page (height/width = sqrt(2))
    const contentWidth = width * aspectRatio;
    const contentHeight = contentWidth / 5; // Set the height to half of the width
    return contentHeight;
  };



  return (
    <div style={{ maxWidth: '1000px', margin: 'auto', padding: '20px', border: '1px solid #ccc', borderRadius: '5px' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px' }}>
        <button onClick={handlePreviousPage} disabled={pageNumber <= 1}>{t('help.previous')}</button>
        <span>{t('help.page')} {pageNumber} {t('help.of')} {numPages}</span>
        <button onClick={handleNextPage} disabled={pageNumber >= numPages}>{t('help.next')}</button>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div>
          <button onClick={handleZoomOut} disabled={scale <= 1}> - </button>
          <button onClick={handleZoomIn} disabled={scale >= 4.5}>{t('help.zoom')}+  </button>
        </div>
        <button onClick={handleDownload}>{t('help.download')}</button>
      </div>
      <div style={{ border: '1px solid #ccc', overflowY: 'auto', maxHeight: '800px' }}>
        <Document file={filename} onLoadSuccess={onDocumentLoadSuccess}>
          <Page pageNumber={pageNumber} width={1000} height={calculateHeight(1000)} scale={scale} renderTextLayer={false} renderAnnotationLayer={false} />
        </Document>
      </div>
    </div>
  );
};

export default PDFDisplay;

'use client';
import React, { useEffect, useRef, useState } from 'react';
import { Button, DatePickerRange, Filter, Headline, Icon, SelectInput, SelectOptionsProps } from '@sde/basic-ui-library';
import { useTranslation } from 'react-i18next';
import { FilterItem } from '@sde/basic-ui-library/dist/components/filter/filter';
import { useSelector } from 'react-redux';
import Chart, { BubbleDataPoint, ChartData, Point } from 'chart.js/auto';
import { Bar, Pie } from 'react-chartjs-2';
import { CategoryScale } from 'chart.js';
import { base64StringToBlob } from 'blob-util';
import download from 'downloadjs';
import { toast } from 'react-toastify';
import {environment} from '../../../../environments/environment';
import {useApiGetData} from '../../../components/useApiGetData/useApiGetData';
import {GroupItem, ReportResponse, ReportResponseItemPeriod, SurveyReportModel} from '../../../models/survey-report';
import {useParams} from 'react-router-dom';
import {isAdmin, isAutoDealer, isMotoDealer} from "../../../helpers/session-utils";
import {sendApiRequest} from "../../../components/send-api-request/send-api-request";
import {VehicleModelType} from "../../../models/vehicle-model";
import {Session} from "@ory/client";
import {selectAllUserManagement} from "../../../store/slices/user-management.slice";
import {dashboardFilterSelector} from "../../../store/slices/dashboard.slice";
import {mapResponseFormatTypesId, ResponseFormatTypeEnum} from "../../../models/response-format-type";
import ReportFilters from "../../../components/report-filter/report-filter";
import {DealersScope} from "../../../models/dealer";
import CollapsableTab from "../../../components/collapsable-tab/collapsable-tab";
import { exportChartsAsImage } from '../../../helpers/html2canvas';
import SurveyReportTableDetailPopup
  from "../../../components/survey-report-table-detail-popup/survey-report-table-detail-popup";
import {mapSelectionMechanismId, SelectionMechanismEnum} from "../../../models/selection-mechanism";
import { ChartType } from '../../../models/report';
import { isAutoQuestionnaire, isMotoQuestionnaire } from '../../../helpers/survey-tool-helper';

Chart.register(CategoryScale);

export interface Model {
  objectId: string;
  model: string;
  type: ModelType;
}

export enum ModelType {
  UNDEFINED = 'UNDEFINED',
  AUTO = 'AUTO',
  MOTO = 'MOTO',
}

enum Key {
  WEEK = 'WEEK',
  MONTH = 'MONTH',
  YEAR = 'YEAR',
}

enum DateType {
  SEND_OUT = 'sendOutDate',
  RESPONSE = 'responseDate',
}

export default function SurveyReport() {
  const { t } = useTranslation();
  const dashboardFilterState = useSelector(dashboardFilterSelector);
  const { typeParam } = useParams();
  const [session, setSession] = useState({} as Session);
  const [isDetailModalOpen, setIsDetailModalOpen] = useState<boolean>(false);
  const [selectedDateType, setSelectedDateType] = useState<DateType>(DateType.SEND_OUT);
  const [currentItem, setCurrentItem] = useState<GroupItem>({
    id: 0,
    name: '',
    hint: '',
    position: 0,
    responseFormatTypeId: 0,
    selectionMechanismId: 0,
    calculationType: 'most_often',
    periods: [],
    threshold1: 0,
    threshold2: 0,
  });

  const entities = useSelector(selectAllUserManagement);
  useEffect(() => {
    const lastEntity = entities[entities.length - 1];
    if (lastEntity && lastEntity.currentUser) {
      setSession(lastEntity.currentUser);
    }
  }, [entities]);

  const [currentPeriod, setCurrentPeriod] = useState<ReportResponseItemPeriod>({
    startDate: new Date(),
    endDate: new Date(),
    value: 0,
    stringValue: '',
  });

  const [reportsOptions, setReportsOption] = useState<SelectOptionsProps[]>([]);
  const [selectedReportOption, setSelectedOption] = useState<number>(0);
  const [groupBy, setGroupBy] = useState<Key.WEEK | Key.MONTH | Key.YEAR>(Key.WEEK);
  const [models, setModels] = useState<Model[]>([]);
  const [modelsOptions, setModelsOptions] = useState<FilterItem[]>([]);
  const [selectedModels, setSelectedModels] = useState<string[]>([]);
  const groupByOptions = [
    {
      key: Key.WEEK,
      name: t('reports.survey.weekly'),
      value: Key.WEEK,
    },
    {
      key: Key.MONTH,
      name: t('reports.survey.monthly'),
      value: Key.MONTH,
    },
    {
      key: Key.YEAR,
      name: t('reports.survey.yearly'),
      value: Key.YEAR,
    },
  ];
  const [from, setFrom] = useState<Date>(new Date(new Date().setMonth(new Date().getMonth() - 12)));
  const [to, setTo] = useState<Date>(new Date());
  const [reportData, setReportData] = useState<ReportResponse>();

  const [sendOutDataChart, setSendOutDataChart] = useState<ChartData<'pie', (number | Point | [number, number] | BubbleDataPoint | null)[]>>({
    labels: [],
    datasets: [],
  });
  const [responseRateDataChart, setResponseRateDataChart] = useState<ChartData<'pie', (number | Point | [number, number] | BubbleDataPoint | null)[]>>({
    labels: [],
    datasets: [],
  });
  const [reasonDataChart, setReasonDataChart] = useState<ChartData<'bar', (number | Point | [number, number] | BubbleDataPoint | null)[]>>({
    labels: [],
    datasets: [],
  });


  const allReportsByType = useApiGetData<SurveyReportModel[]>(
    `${environment.restEndpoint}/survey-service/reports/type?typeId=${typeParam}`,
    'get'
  ).data;

  useEffect(() => {
    setReportsOption([]);
    setSelectedOption(0);
    if (!allReportsByType?.length) return;
    const options: {key: number, name: string, value: number}[] = [];
    allReportsByType?.forEach(report => {
      options.push(
        {
          key: report.id,
          name: report.name,
          value: report.id,
        }
      );
    })
    setReportsOption(options);
    setSelectedOption(options[0]?.value || 0);
  }, [allReportsByType, typeParam]);


  const getValue = (item: GroupItem, period: ReportResponseItemPeriod) => {
    if (period.stringValue?.length) {
      return period.stringValue;
    }

    return `${period.value}${[ResponseFormatTypeEnum.BOOLEAN].includes(mapResponseFormatTypesId(item.responseFormatTypeId)) ? '%' : ''}`;
  };

  const getColor = (item: GroupItem, period: ReportResponseItemPeriod) => {
    if (period.stringValue?.length || !['likert', 'mean', 'median'].includes(item.calculationType)) return;
    if (period.value > item.threshold2) return 'rgba(117,255,86,0.4)';
    if (period.value > item.threshold1) return 'rgba(255, 205, 86, 0.4)';
    return 'rgba(255, 99, 132, 0.4)';
  };

  const getWeekNumber = (date: Date): number => {
    const startOfYear = new Date(date.getFullYear(), 0, 0);
    const diff = (date.getTime() - startOfYear.getTime()) + ((startOfYear.getTimezoneOffset() - date.getTimezoneOffset()) * 60 * 1000);
    const oneWeek = 1000 * 60 * 60 * 24 * 7;
    const weekNumber = Math.floor(diff / oneWeek);
    return weekNumber + 1;
  };

  const getDateLabel = (date: Date) => {
    date = new Date(date);

    if (groupBy === Key.YEAR) return  date.getFullYear();

    if (groupBy === Key.MONTH) {
      const month = date.toLocaleString(localStorage.getItem('i18nextLng') ?? 'en-US', { month: 'short' }).toUpperCase();
      return `${month} ${date.getFullYear()}`;
    }

    if (groupBy === Key.WEEK) {
      const weekNumber = getWeekNumber(date);
      return `${t('reports.survey.week')} ${weekNumber} ${date.getFullYear()}`;
    }

    return '';
  };

  const getSelectedModelNames = () => {
    return  models.filter(model => selectedModels.includes(model.model)).map(model => model.model);
  };

  const getReport = async (
    id: number,
    groupBy: string,
    from: Date,
    to: Date,
    modelNames: string[],
    dateType: string,
    dealerCodes: string[],
  ) => {
    const resp = await fetch(
      `${environment.restEndpoint}/survey-service/reports/view/${id}?groupBy=${groupBy}&from=${from.toISOString().split('T')[0]}&to=${to.toISOString().split('T')[0]}&dateType=${dateType}`,
      {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          apikey: 'p0zLmdPds51XKPtTTc05z0L0',
        },
        body: JSON.stringify({
          modelNames: modelNames,
          dealerCodes: dealerCodes,
        }),
      }
    );

    const data = await resp.json();

    if (data?.groups) {
      data.groups.forEach((group: { items: { position: number }[] }) => {
        group.items.sort((a, b) => a.position - b.position);
      });
    }

    return data;
  };


  const fetchReport = async () => {
    try {
      const data = await getReport(selectedReportOption, groupBy, from, to, getSelectedModelNames(), selectedDateType, dashboardFilterState.dealerFilter.dealers);
      setReportData(data);

      const sendOutData = [
        {
          name: t('reports.survey.send_out_per'),
          percent: data?.sendOutRateChart?.sendOutPercentage || 0,
        },
        {
          name: t('reports.survey.no_send_out_per'),
          percent: data?.sendOutRateChart?.noSendOutPercentage || 0,
        },
      ];

      const responseRateData = [
        {
          name: t('reports.survey.fully_completed_percentage'),
          percent: data?.responseRateChart?.fullyCompletedPercentage || 0,
        },
        {
          name: t('reports.survey.partly_completed_percentage'),
          percent: data?.responseRateChart?.partlyCompletedPercentage || 0,
        },
        {
          name: t('reports.survey.not_completed_percentage'),
          percent: data?.responseRateChart?.notCompletedPercentage || 0,
        },
      ];

      const reasonsData = data?.topSendOutErrorsChart?.topSendOutError || [];

      setResponseRateDataChart({
        labels: responseRateData.map((dataSet) => dataSet.name),
        datasets: [
          {
            label: t('reports.survey.response_rate_data'),
            data: responseRateData.map((dataSet) => dataSet.percent),
            backgroundColor: [
              'rgba(117,255,86,0.4)',
              'rgba(255, 205, 86, 0.4)',
              'rgba(255, 99, 132, 0.4)',
            ],
            borderColor: 'black',
            borderWidth: 0,
          },
        ],
      });

      setSendOutDataChart({
        labels: sendOutData.map((dataSet) => dataSet.name),
        datasets: [
          {
            label: t('reports.survey.send_out_rate'),
            data: sendOutData.map((dataSet) => dataSet.percent),
            backgroundColor: [
              'rgba(117,255,86,0.4)',
              'rgba(255, 99, 132, 0.4)',
            ],
            borderColor: 'black',
            borderWidth: 0,
          },
        ],
      });

      const fill = ['rgba(255, 99, 132, 0.2)',
        'rgba(255, 159, 64, 0.2)',
        'rgba(166, 192, 192, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(54, 162, 235, 0.2)',
      ];

      const border = ['rgb(255, 99, 132)',
        'rgb(255, 159, 64)',
        'rgb(166, 192, 192)',
        'rgb(75, 192, 192)',
        'rgb(54, 162, 235)',
      ];

      setReasonDataChart({
        labels: [''],
        datasets: reasonsData.map((item: Record<string, string | number>, index: number) => ({
          label: t('communication.surveys.sendoutErrors.' + item.name),
          data: [item.percentage],
          backgroundColor: fill[index] || fill [0],
          borderColor: border[index] || border [0],
          borderWidth: 1,
        })),
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const scope = 
      isMotoQuestionnaire(Number(typeParam))
      ? VehicleModelType.MOTO
      : isAutoQuestionnaire(Number(typeParam))
      ? VehicleModelType.AUTO
      : VehicleModelType.UNDEFINED;
  
    sendApiRequest(
      `${environment.restEndpoint}/contact/newsletter/model?scope=${scope}&withLegacy=true`,
      'get'
    ).then(response => {
      if (response.ok) {
        response.json().then(data => {
          const models = data.data as Model[]
          setModels(models);
          setSelectedModels(models.map(item => item.model));
          setModelsOptions(models.map(item => ({
            id: item.model,
            name: item.model,
          })));
        })
      }
    })
  }, [session, typeParam]);

  const tableStyle = { border: '1px solid #33333340' } as React.CSSProperties;
  const headerNameStyle = { minWidth: '400px', maxWidth: '400px', border: '1px solid #33333340', textAlign: 'center' } as React.CSSProperties;
  const headerStyle = { border: '1px solid #33333340', textAlign: 'center', fontWeight: '700', minWidth: '120px' } as React.CSSProperties;
  const rowStyle = {  border: '1px solid #33333340', textAlign: 'center' } as React.CSSProperties;
  const groupRowStyle = { maxWidth: '400px', border: '1px solid #33333340', padding: '0 10px', textAlign: 'left' } as React.CSSProperties;
  const cellStyle = { border: '1px solid #33333340', textAlign: 'center', minWidth: '80px', maxWidth: '80px' } as React.CSSProperties;

  const getCellStyle = (item: GroupItem, period: ReportResponseItemPeriod): React.CSSProperties => {
    return {
      ...cellStyle,
      cursor: 'pointer',
      background: getColor(item, period),
    } ;
  };

  const openModal = async (item: GroupItem, period: ReportResponseItemPeriod) => {
    setCurrentItem(item);
    setCurrentPeriod(period);
    setIsDetailModalOpen(true);
  };

  const exportSendOut = useRef();
  const exportResponseRate = useRef();
  const exportReasons = useRef();

  const exportReportExcel = async() => {
    let reportResponse = reportData as ReportResponse;
    if (reportData) {
      reportResponse = {
        ...reportData,
        responseRateChart: {
          ...reportData.responseRateChart,
          titleTranslation: t('reports.survey.response_rate'),
          fullyCompletedTranslation: t('reports.survey.fully_completed_percentage'),
          notCompletedTranslation: t('reports.survey.not_completed_percentage'),
          partlyCompletedTranslation: t('reports.survey.partly_completed_percentage'),
        },
        sendOutRateChart: {
          ...reportData.sendOutRateChart,
          titleTranslation: t('reports.survey.send_out_rate'),
          sendOutTranslation: t('reports.survey.send_out_per'),
          noSendOutTranslation: t('reports.survey.no_send_out_per'),
        },
        topSendOutErrorsChart: {
          ...reportData.topSendOutErrorsChart,
          titleTranslation: t('reports.survey.fail_reason'),
        },
      };
      setReportData(reportResponse);
    }

    const resp = await fetch(
      `${environment.restEndpoint}/survey-service/reports/export`,
      {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          apikey: 'p0zLmdPds51XKPtTTc05z0L0',
        },
        body: JSON.stringify(reportResponse),
      }
    );
    return resp.json();
  }

  const exportExcel = async () => {
    try {
      const { content } = await exportReportExcel();
      const file = base64StringToBlob(
        content,
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      );
      download(
        file,
        'customer_retention_report.xlsx',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      );
    } catch (error) {
      toast.error(t('error') + error);
    }
  };

  useEffect(() => {
    if (!selectedReportOption) return;
    fetchReport().catch(error => console.error(error));
  }, [
    selectedReportOption,
    groupBy,
    from,
    to,
    reportsOptions,
    selectedModels,
    selectedDateType,
    dashboardFilterState.dealerFilter.dealers,
  ]);

  return <main className="w-full h-full p-8">
    {reportsOptions.length === 0 ? (
      <div>{t('reports.survey.no_report_available')}</div>
    ) : (
    <>
    <div className={'flex items-center justify-between'}>
      <Headline title={t('reports.survey.title')} />
      <Button onClick={() => exportExcel()}>
        {t('reports.survey.export_excel')}
      </Button>
    </div>
    <ReportFilters
      showOnlyDealersScope={
        isAutoDealer(session)
          ? DealersScope.AUTO
          : isMotoDealer(session)
            ? DealersScope.MOTO
            : undefined
      }

    />
    <div className="flex mb-2 items-center space-x-4">
      <SelectInput
        id={'reportsSelect'}
        value={selectedReportOption}
        options={reportsOptions}
        label={{ name: t('reports.survey.surveys_input'), position: 'top' }}
        onSelect={(value) => setSelectedOption(Number(value))}
      />
      <SelectInput
        id={'dateType'}
        options={[
          {
            key: DateType.SEND_OUT,
            value: DateType.SEND_OUT,
            name: t('reports.survey.send_out_date'),
          },
          {
            key: DateType.RESPONSE,
            value: DateType.RESPONSE,
            name: t('reports.survey.response_date'),
          },
        ]}
        label={{
          name: t('reports.survey.date_type'),
          position: 'top',
        }}
        onSelect={(value) => setSelectedDateType(value as DateType)}
      />
      <SelectInput
        id={'reportTimeUnit'}
        value={groupBy}
        options={groupByOptions}
        width="10rem"
        onSelect={(value: string) => setGroupBy(value as Key.WEEK | Key.MONTH | Key.YEAR)}
        label={{
          name: t('reports.survey.time_unit_filter'),
          position: 'top',
        }}
      />
      <DatePickerRange
        id={'reportDateRange'}
        labelFrom={t('reports.survey.from')}
        labelTo={t('reports.survey.to')}
        from={from}
        to={to}
        changeFrom={(value) => setFrom(value)}
        changeTo={(value) => setTo(value)}
      />
      <div className="pt-6">
        <Filter
          key={'modelFilter'}
          id={'modelFilter'}
          name={t('reports.survey.model_filter')}
          items={modelsOptions.filter(model => model.id)}
          selected={selectedModels.filter(model => model)}
          onChange={(_id, selected) => setSelectedModels(selected)}
          width="7rem"
        />
      </div>
    </div>
    <div className={'flex -ml-4'}>
      {reportData?.sendOutRateChart?.isDisplayed &&
        <div className={'min-w-[450px]'}>
          <CollapsableTab title={t('reports.survey.send_out_rate')}>
            <div className={'flex'}>
              {!(sendOutDataChart?.datasets[0]?.data as number[])?.reduce((acc, curr) => acc + curr) ?
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '0 auto' }}>
                  <Icon iconName="equalizer" style={{ color: '#bbb', fontSize: '200px' }} />
                  <div style={{ color: '#bbb', fontSize: '40px', fontWeight: 800 }}>
                    {t('reports.survey.no_data')}
                  </div>
                </div> : <>
                  <div ref={exportSendOut as unknown as undefined} className={'h-[300px] w-[300px] p-2'}>
                    <Pie
                      data={sendOutDataChart}
                      options={{
                        plugins: {
                          title: {
                            display: false,
                          },
                          legend: {
                            position: 'bottom',
                            align: 'start',
                          },
                          tooltip: {
                            callbacks: {
                              label: function (context) {
                                const label = context.label || '';
                                const value = context.raw || 0;
                                return `${label}: ${value}%`;
                              },
                            },
                          },
                        },
                      }}
                    />
                  </div>
                  <Button className={'mt-[19px]'}
                          onClick={() => exportChartsAsImage(sendOutDataChart, exportSendOut.current as unknown as HTMLElement, ChartType.SEND_OUT_RATE)}>
                    {t('reports.survey.export_png')}
                  </Button>
                </>
              }
            </div>
          </CollapsableTab>
        </div>
      }

      {reportData?.responseRateChart?.isDisplayed &&
        <div className={'min-w-[450px]'}>
          <CollapsableTab title={t('reports.survey.response_rate')}>
            <div className={'flex'}>
              {!(responseRateDataChart?.datasets[0]?.data as number[]).reduce((acc, curr) => acc + curr) ?
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '0 auto' }}>
                  <Icon iconName="equalizer" style={{ color: '#bbb', fontSize: '200px' }} />
                  <div style={{ color: '#bbb', fontSize: '40px', fontWeight: 800 }}>
                    {t('reports.survey.no_data')}
                  </div>
                </div> : <>
                  <div id={ChartType.RESPONSE_RATE} ref={exportResponseRate as unknown as undefined} className={'h-[300px] w-[300px] p-2'}>
                    <Pie
                      data={responseRateDataChart}
                      options={{
                        plugins: {
                          title: {
                            display: false,
                          },
                          legend: {
                            position: 'bottom',
                            align: 'start',
                          },
                          tooltip: {
                            callbacks: {
                              label: function (context) {
                                const label = context.label || '';
                                const value = context.raw || 0;
                                return `${label}: ${value}%`;
                              },
                            },
                          },
                        },
                      }}
                    />
                  </div>
                  <Button className={'mt-[-15px]'}
                          onClick={() => exportChartsAsImage(responseRateDataChart, exportResponseRate.current as unknown as HTMLElement, ChartType.RESPONSE_RATE)}>
                    {t('reports.survey.export_png')}
                  </Button>
                </>
              }
            </div>
          </CollapsableTab>
        </div>
      }

      {reportData?.topSendOutErrorsChart?.isDisplayed &&
        <CollapsableTab title={t('reports.survey.fail_reason')}>
          <div className={'flex'}>
            {!(reasonDataChart?.datasets[0]?.data as number[])?.reduce((acc, curr) => acc + curr ) ?
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '0 auto' }}>
                <Icon iconName="equalizer" style={{ color: '#bbb', fontSize: '200px' }} />
                <div style={{ color: '#bbb', fontSize: '40px', fontWeight: 800 }}>
                  {t('reports.survey.no_data')}
                </div>
              </div> : <>
                <div id={ChartType.FAIL_REASONS} ref={exportReasons as unknown as undefined} className={'h-[300px] w-[600px] p-2'}>
                  <Bar
                    data={reasonDataChart}
                    options={{
                      indexAxis: 'y',
                      plugins: {
                        title: {
                          display: false,
                        },
                        legend: {
                          display: true,
                          position: 'bottom',
                          align: 'start',
                        },
                        tooltip: {
                          callbacks: {
                            label: function (context) {
                              const label = context.dataset.label || '';
                              const value = context.raw || 0;
                              return `${label}: ${value}%`;
                            },
                          },
                        },
                      },
                      scales: {
                        y: {
                          display: false,
                          position: 'top',
                        },
                      },
                    }}
                  />
                </div>
                <Button className={'mt-[19px]'} onClick={() => exportChartsAsImage(reasonDataChart, exportReasons.current as unknown as HTMLElement, ChartType.FAIL_REASONS)}>
                  {t('reports.survey.export_png')}
                </Button>
              </>
            }
          </div>
        </CollapsableTab>
        }
      </div>
      <section>
        <div className={'-ml-4'}>
        {reportData?.groups?.length && reportData.groups.map(group => <>
          <CollapsableTab title={group.groupName}>
            <table style={tableStyle}>
              <thead>
              <tr style={rowStyle}>
                <th style={headerNameStyle}></th>
                {reportData.groups[0]?.items[0]?.periods.map(period => <>
                  <th style={headerStyle}>{getDateLabel(period.startDate)}</th>
                </>)}
              </tr>
              </thead>
              <tbody>
              {group.items.map((item) => <>
                <tr style={rowStyle}>
                  <td style={groupRowStyle}>
                    {item.name}
                    {
                      (item.hint !== '')
                      && <div className={'text-xs'}>
                        {item.hint}
                      </div>
                    }

                  </td>
                  {item.periods.map((period, index) =>
                    <td
                      key={`period-${index}`}
                      className={'transition duration-200 filter hover:backdrop-blur-3xl hover:opacity-80'}
                      style={getCellStyle(item, period)}
                      onClick={() => openModal(item, period)}
                    >
                      <div className="p-1">
                        {([ResponseFormatTypeEnum.STRING, ResponseFormatTypeEnum.NUMERIC].includes(mapResponseFormatTypesId(item.responseFormatTypeId)) &&
                          [SelectionMechanismEnum.TEXT_SINGLE_LINE, SelectionMechanismEnum.TEXT_MULTIPLE_LINE].includes(mapSelectionMechanismId(item.selectionMechanismId)))
                          ? t('reports.freeText.viewDetails')
                          : getValue(item, period)
                        }
                      </div>
                    </td>)}
                </tr>
              </>)}
              </tbody>
            </table>
          </CollapsableTab>
        </>)}
        </div>
      </section>

      <SurveyReportTableDetailPopup
        isDetailModalOpen={isDetailModalOpen}
        setIsDetailModalOpen={setIsDetailModalOpen}
        item={currentItem}
        period={currentPeriod}
        reportId={selectedReportOption.toString()}
        modelNames={getSelectedModelNames()}
        dateType={selectedDateType}
        dealerCodes={dashboardFilterState.dealerFilter.dealers}
      />
    </>
    )}
  </main>
;
}

import React, { useEffect, useState } from 'react';
import { sendApiRequest } from '../send-api-request/send-api-request';
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
  Bar,
  BarChart
} from 'recharts';
import { Headline} from '@suzuki-frontend-framework-update/ui';
import { Filter, FilterItem } from "@sde/basic-ui-library";
import { useTranslation } from 'react-i18next';
import { environment } from '../../../environments/environment';
import { DatePickerRange, SelectInput } from "@sde/basic-ui-library";
import { SelectOptionsProps } from "@sde/basic-ui-library/dist/components/select-input/select-input";
import styles from './appointments-chart.module.scss';

const AppointmentsChart = ({ dealers }: { dealers: string[] }) => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [isFetching, setIsFetching] = useState(false);

  const fillData = async () => {
    try {
      const tempData: any = [];
      setIsFetching(true);
      setData([]);
      await sendApiRequest(
        `${environment.restEndpoint}/action/appointments/dates`,
        'post',
        {
          dealerCodes: dealers,
          from: fromDate,
          to: toDate,
          groupBy: selectedScaleOption === 'weekly' ? 0 : 1,
        })
        .then(response => response.json())
        .then(resp => {
          resp['appointments']?.forEach((element: Record<string, string>, index: number) => {
            const date = new Date(Number(element.key.split('-')[0]), Number(element.key.split('-')[1]) - 1);
            const year = element.key.split('-')[0];
            const lang = localStorage.getItem('i18nextLng') as string;
            const monthYear = date.toLocaleString(lang ?? 'en-US', { month: 'short', year: 'numeric' });
            const weekYear = `${t('appointmentsChart.week')} ${element.key.split('-')[1]}`;
            tempData.push({
              name: selectedScaleOption === 'weekly' ? weekYear : monthYear,
              year,
              [t('appointmentsChart.appointments')]: resp['appointments'][index].value,
              [t('appointmentsChart.testDriveAppointments')]: resp['testDriveAppointment'][index].value,
              [t('appointmentsChart.serviceAppointments')]: resp['serviceAppointment'][index].value,
              [t('appointmentsChart.offers')]: resp['offers'][index].value,
            });
            setData(tempData);
            setIsFetching(false);
          })
        })
    } catch (error) {
      setIsFetching(false);
      console.error(error);
    }
  }

  const [selectedScaleOption, setSelectedScaleOption] = useState('weekly');
  const dateScaleOption: SelectOptionsProps[] = [
    {
      key: 'weekly',
      name: t('appointmentsChart.filter.scale.week'),
      value: 'weekly',
    },
    {
      key: 'monthly',
      name: t('appointmentsChart.filter.scale.month'),
      value: 'monthly',
    },
  ];

  const [fromDate, setFromDate] = useState<Date | null>(new Date(new Date().setMonth(new Date().getMonth() - 3)));
  const [toDate, setToDate] = useState<Date | null>(new Date());

  const filterItems: FilterItem[] = [
    {id: 'all', name: t('appointmentsChart.filter.forms.all')},
    {id: 'testDrive', name: t('appointmentsChart.filter.forms.testDrive')},
    {id: 'service', name: t('appointmentsChart.filter.forms.service')},
    {id: 'consulting', name: t('appointmentsChart.filter.forms.consulting')},
    {id: 'offer', name: t('appointmentsChart.filter.forms.offer')},
  ];

  const [selectedForms, setSelectedForms] = useState<string[]>(filterItems.map(item => item.id));

  function handleSelectedFormsChange(_id: string, selected: string[], changedId: string) {
    const AllOptions = filterItems.map(item => item.id).sort();
    if (changedId === 'all') setSelectedForms(selected.includes('all') ? AllOptions : []);
      else if (JSON.stringify(AllOptions) === JSON.stringify(['all', ...selected].sort())) setSelectedForms(AllOptions);
      else setSelectedForms(selected.filter(model => model !== 'all'));
  }

  useEffect(() => {
    fillData().catch(error => console.error(error));
  }, [dealers, toDate, fromDate, selectedScaleOption]);

  return (
    <section className={isFetching ? styles.loading : 'min-h-[400px]'}>
      <div className={isFetching ? styles.loader : ''}></div>
      <Headline className="text-dark-gray" title={t('appointmentsChart.title')}/>
      <div className="flex flex-row gap-x-8 pr-8 px-4">
        <SelectInput
          value={selectedScaleOption}
          options={dateScaleOption}
          width="10rem"
          onSelect={(value: string) => setSelectedScaleOption(value)}
          label={{
            name: t('appointmentsChart.filter.scale.label'),
            position: 'top',
          }}
        />
        <DatePickerRange
          id="t"
          labelFrom={t('appointmentsChart.filter.time.from')}
          labelTo={t('appointmentsChart.filter.time.to')}
          from={fromDate}
          to={toDate}
          changeFrom={(from) => setFromDate(from)}
          changeTo={(to) => setToDate(to)}
        />
        <div className="flex items-end gap-6 pb-2">
          <Filter
            key={'model'}
            id={'model'}
            name={t('appointmentsChart.filter.forms.label')}
            items={filterItems}
            selected={selectedForms}
            onChange={handleSelectedFormsChange}
            width="7rem"
          />
        </div>
      </div>
      <ResponsiveContainer width="100%" height="100%" minHeight={400}>
        <BarChart
          width={500}
          height={400}
          data={data}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" vertical={false}/>
          <XAxis dataKey="name" interval={Math.floor(data.length / 20)} domain={[`${t('appointmentsChart.week')}` + ' 50', `${t('appointmentsChart.week')}` + ' 05']} />
          {selectedScaleOption === 'weekly' &&
            <XAxis dataKey="year" domain={['2023', '2024']}
              interval={Math.floor(data.length / 20)}
              height={20}
              xAxisId="year"
              axisLine={false}
              tickLine={false}
              dy={-10}
            />
          }
          <YAxis allowDecimals={false} domain={[0, 16]}/>
          <Tooltip/>
          {
            selectedForms.find(item => item === "testDrive") &&
            <Bar
              dataKey={t('appointmentsChart.testDriveAppointments')}
              stackId="1"
              stroke="#8884d8"
              fill="#8884d895"
            />
          }
          {
            selectedForms.find(item => item === "service") &&
            <Bar
              dataKey={t('appointmentsChart.serviceAppointments')}
              stackId="1"
              stroke="#82ca9d"
              fill="#82ca9d95"

            />
          }
          {
            selectedForms.find(item => item === "consulting") &&
            <Bar
              dataKey={t('appointmentsChart.appointments')}
              stackId="1"
              stroke="#ffc658"
              fill="#ffc65895"
            />
          }
          {
            selectedForms.find(item => item === "offer") &&
            <Bar
              type="monotone"
              dataKey={t('appointmentsChart.offers')}
              stackId="1"
              stroke="#906090"
              fill="#90609095"
            />
          }
          <Tooltip/>
          <Legend verticalAlign="bottom" align="left" iconType="circle"/>
        </BarChart>
      </ResponsiveContainer>
    </section>
  );
}

export default AppointmentsChart;

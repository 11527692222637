import React, { ReactElement } from 'react';
import { Link, OutletProps, useLocation } from 'react-router-dom';
import { Icon } from '../icon/icon';
import styles from './sidebar.module.scss';

export interface SidebarLink {
  name: string;
  path: string;
  subPaths?: string[];
  icon?: { classname?: string; name: string };
}

export interface SidebarLinkGroup {
  title: string;
  items: SidebarLink[];
}

export type SidebarProps = {
  groups: SidebarLinkGroup[];
  children: ReactElement<OutletProps>;
};

export const Sidebar = (props: SidebarProps) => {
  const location = useLocation();

  return (
    <div className="grow flex flex-row w-full">
      <div className="w-72 border-x border-border-gray bg-white">
        <div
          className={`flex flex-col sticky top-16 overflow-y-auto overflow-x-hidden ${styles.custom_scrollbar}`}
          style={{ maxHeight: 'calc(100vh - 6rem)' }} 
        >
          {props.groups.map((group) => (
            <React.Fragment key={group.title}>
              <span className="px-8 pt-5 pb-2 w-72 text-left font-suzuki-bold">
                {group.title}
              </span>
              {group.items.map((link) => (
                <Link
                  key={link.name}
                  to={link.path}
                  className={`${
                    location.pathname.endsWith(link.path) ||
                    (link.subPaths &&
                      link.subPaths.some((path) =>
                        location.pathname.endsWith(path)
                      ))
                      ? 'bg-gray'
                      : 'cursor-pointer'
                  } w-full px-8 py-1 text-left`}
                >
                  {link.icon ? (
                    <div className="flex justify-center items-center">
                      <span className="w-1/6 mr-2">
                        <Icon
                          iconName={link.icon.name}
                          className={link.icon.classname}
                        />
                      </span>
                      <div className="w-5/6">
                        <span>{link.name}</span>
                      </div>
                    </div>
                  ) : (
                    link.name
                  )}
                </Link>
              ))}
            </React.Fragment>
          ))}
        </div>
      </div>
      <div className="grow bg-gray">{props.children}</div>
    </div>
  );
};

export default Sidebar;

import { Sidebar } from '@suzuki-frontend-framework-update/ui';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

export const Help = () => {
    const { t } = useTranslation();
    return (
        <Sidebar
            groups={[
                {
                    title: t('help.title'),
                    items: [],
                },
                {
                    title: t('help.manual.title'),
                    items: [
                        {
                            name: t('help.manual.title'),
                            path: 'manual',
                        },
                    ],
                },
                // {
                //     title: t('help.sample-letter.title'),
                //     items: [
                //         {
                //             name: t('help.PDF_1.title'),
                //             path: 'PDF_1',
                //         },
                //         {
                //             name: t('help.PDF_2.title'),
                //             path: 'PDF_2',
                //         },
                //     ],
                // },
            ]}
        >
            <Outlet />
        </Sidebar>
    );
};

export default Help;

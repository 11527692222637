import { useTranslation } from 'react-i18next';
import React, { useCallback, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import './test-drive-report.module.scss';
import {
  Button,
  Headline,
  Icon,
  Table,
  Tooltip,
} from '@suzuki-frontend-framework-update/ui';
import { useApiGetData } from '../../../../components/useApiGetData/useApiGetData';
import { environment } from '../../../../../environments/environment';
import { testDriveReportFilterActions, testDriveReportFilterSelector, } from '../../../../store/slices/test-drive-report.slice';
import { dashboardFilterSelector, } from '../../../../store/slices/dashboard.slice';
import {
  TestDriveData,
  TestDriveOrigin,
  TestDriveRequestBody,
  TestDriveStatus,
} from '../../../../models/test-drive';
import { AppDispatch } from '../../../../store/setup';
import { DataFetchProps } from '../../../../models/others';
import ReportFilters from "../../../../components/report-filter/report-filter";
import moment from "moment/moment";
import { Session } from "@ory/client";
import { ScheduleTestDrivePopup } from "../../../../components/schedule-test-drive-popup/schedule-test-drive-popup";
import { CancelTestDrivePopup } from "../../../../components/cancel-test-drive-popup/cancel-test-drive-popup";
import { Column, Row } from "react-table";
import { isAdmin, isAreaAdmin, isAutoDealer, isMotoDealer } from "../../../../helpers/session-utils";
import { selectAllUserManagement } from "../../../../store/slices/user-management.slice";
import { FinishTestDrivePopup } from "../../../../components/finish-test-drive-popup/finish-test-drive-popup";
import { CreateTestDrivePopup } from "../../../../components/create-test-drive-popup/create-test-drive-popup";
import {DatePickerRange, Filter, FilterItem, SelectInput, SelectOptionsProps, Tab, Tabs} from "@sde/basic-ui-library";
import { DealersScope } from "../../../../models/dealer";
import {
  ShowTestDriveDetailsPopup
} from "../../../../components/show-test-drive-details-popup/show-test-drive-details-popup";

const TestDriveReport = () => {
  const pageSize = 100;
  const {t, i18n} = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const entities = useSelector(selectAllUserManagement);

  const testDriveReportFilterState = useSelector(testDriveReportFilterSelector);
  const dashboardFilterState = useSelector(dashboardFilterSelector);
  const fromDashboardSevenDays = new URLSearchParams(location.search).get('dashboardSevenDays') === 'true';
  const fromDashboardOneMonth = new URLSearchParams(location.search).get('dashboardOneMonth') === 'true';
  const [option, setOption] = useState('createAt');
  const dispatch: AppDispatch = useDispatch();
  const [session, setSession] = useState({} as Session);
  const [popupTestDrive, setPopupTestDrive] = useState<TestDriveData>({} as TestDriveData);
  const [showTestDriveDetailsModalState, setShowTestDriveDetailsModalState] =
    useState(false);
  const [scheduleTestDriveModalState, setScheduleTestDriveModalState] =
    useState(false);
  const [cancelTestDriveModalState, setCancelTestDriveModalState] =
    useState(false);
  const [finishTestDriveModalState, setFinishTestDriveModalState] =
    useState(false);
  const [createTestDriveModalState, setCreateTestDriveModalState] =
    useState(false);

  const getInitialTestDriveDates = (fromDashboardSevenDays: boolean, fromDashboardOneMonth: boolean) => {
    if (fromDashboardOneMonth) return { initialDateFrom: undefined, initialDateTo: undefined };

    const initialDateFrom = new Date();
    initialDateFrom.setUTCHours(0, 0, 0, 0);

    const initialDateTo = new Date();
    initialDateTo.setUTCHours(0, 0, 0, 0);

    if (fromDashboardSevenDays) {
      initialDateFrom.setDate(initialDateFrom.getDate() - 7);
    } else {
      initialDateFrom.setMonth(initialDateFrom.getMonth() - 2);
      initialDateTo.setMonth(initialDateTo.getMonth() + 1);
    }

    return { initialDateFrom, initialDateTo };
  };

  const { initialDateFrom, initialDateTo} = getInitialTestDriveDates(fromDashboardSevenDays, fromDashboardOneMonth);

  const [dateFrom, setDateFrom] = useState<Date | undefined>(initialDateFrom);
  const [dateTo, setDateTo] = useState<Date | undefined>(initialDateTo);
  const {tabParam} = useParams();

  useEffect(() => {
    const lastEntity = entities[entities.length - 1];
    if (lastEntity && lastEntity.currentUser) {
      if (!isAdmin(lastEntity.currentUser)) {
        let queryParam = "";
        if (fromDashboardSevenDays) {
          queryParam = "?dashboardSevenDays=true";
        } else if (fromDashboardOneMonth) {
          queryParam = "?dashboardOneMonth=true";
        }

        if (isMotoDealer(lastEntity.currentUser)) {
          navigate('/communication/test-drives-report/moto' + queryParam);
        } else if (isAutoDealer(lastEntity.currentUser)) {
          navigate('/communication/test-drives-report/auto' + queryParam);
        }
      }
      setSession(lastEntity.currentUser);
    }
  }, [entities]);


  const filterByDateOptions: SelectOptionsProps[] = [
    {
      key: 'createAt',
      value: 'createAt',
      name: t(
        'communication.test-drive.report.filter-option.createAt'
      ),
    },
    {
      key: 'plannedDate',
      value: 'plannedDate',
      name: t(
        'communication.test-drive.report.filter-option.plannedDate'
      ),
    },
  ]

  const obtainFilterByOriginOptions = (): FilterItem[] => {
    const origins = Object.keys(TestDriveOrigin).filter(key => isNaN(Number(key)));
    const reorderedOrigins = origins.slice(1).concat(origins[0]);
    return reorderedOrigins.map(origin => {
      return {
        id: origin,
        name: t(`communication.test-drive.origin.${origin}`),
      }
    })
  }
  const [filterByOriginOptions, setFilterByOriginOptions] = useState(obtainFilterByOriginOptions());
  useEffect(() => {
    dispatch(testDriveReportFilterActions.updateFilter({
      ...testDriveReportFilterState.data.filter,
      origins: filterByOriginOptions.map(item => item.id)
    }));
  }, [filterByOriginOptions]);


  const obtainFilterByStatusOptions = (): FilterItem[] => {
    const statuses = Object.keys(TestDriveStatus).filter(key => isNaN(Number(key)));
    return statuses.map(status => {
      return {
        id: status,
        name: t(`communication.test-drive.status.${status}`),
      }
    });
  }
  const [filterByStatusOptions, setFilterByStatusOptions] = useState(obtainFilterByStatusOptions());
  useEffect(() => {
    dispatch(testDriveReportFilterActions.updateFilter({
        ...testDriveReportFilterState.data.filter,
        statuses: fromDashboardOneMonth ? [TestDriveStatus.NEW_OPEN] : filterByStatusOptions.map(item => item.id)
      })
    );
  }, [filterByStatusOptions]);


  const [testDriveRequestBody, setTestDriveRequestBody] =
    useState<TestDriveRequestBody>({
      pageSize: pageSize,
      pageIndex: testDriveReportFilterState.data.pageIndex,
      filter: {
        dateFrom: dateFrom,
        dateTo: dateTo,
        plannedDateFrom: dateFrom,
        plannedDateTo: dateTo,
        search: testDriveReportFilterState.data.filter.search,
        dealers: testDriveReportFilterState.data.filter.dealers,
      },
    });

  const testDriversData = useApiGetData<TestDriveData[]>(
    `${environment.restEndpoint}/action/testdrives/GetTestDrives`,
    'post',
    'testDrives',
    testDriveRequestBody
  );

  const testDriversCountData = useApiGetData<number>(
    `${environment.restEndpoint}/action/testdrives/GetTestDrivesCount`,
    'post',
    'count',
    testDriveRequestBody
  );

  const handleTestDriveReportFilterSearch = (value: string) => {
    dispatch(
      testDriveReportFilterActions.updateFilter({
        ...testDriveReportFilterState.data.filter,
        search: value,
      })
    );
  };

  const isTestDriveClosed = (row: Row<TestDriveData>): boolean => {
    const testDrive = row.original;
    return [
      TestDriveStatus.CANCELED,
      TestDriveStatus.FINISHED_CARRIED_OUT_BOUGHT,
      TestDriveStatus.FINISHED_CARRIED_OUT_NOT_BOUGHT,
      TestDriveStatus.FINISHED_NOT_CARRIED_OUT
    ].includes(testDrive.status as TestDriveStatus);
  };

  const isFinishButtonVisible = (row: Row<TestDriveData>): boolean => {
    const {status, plannedDate} = row.original;
    const finishingFriendlyStatuses = [
      TestDriveStatus.SCHEDULED.toString(),
      TestDriveStatus.FINISHED_CARRIED_OUT_PURCHASE_OPEN.toString(),
      TestDriveStatus.FINISHED_CARRIED_OUT_PURCHASE_OPEN_HOT_LEAD.toString()
    ];
    const doesStatusAllowFinishing = finishingFriendlyStatuses.includes(status ?? '');
    const isPlannedDateInThePast = plannedDate != undefined && new Date(plannedDate) < new Date();
    return doesStatusAllowFinishing && isPlannedDateInThePast;

  };

  const columns = [
    {
      Header: t('communication.test-drive.report.date'),
      accessor: 'createdAt',
      Cell: ({row}: any) => {
        return (
          <div>
            <span>
              {row.original.createAt
                ? new Date(row.original.createAt).toLocaleDateString('de-DE', {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                })
                : '-'}
            </span>
          </div>
        );
      },
    },
    {
      Header: (
        <span className="break-words">
          {t('communication.test-drive.report.plannedDate')}
        </span>
      ),
      accessor: 'plannedDate',
      Cell: ({row}: any) => {
        return (
          <div>
            <span>
              {row.original.plannedDate
                ? new Date(row.original.plannedDate).toLocaleDateString(
                  'de-DE',
                  {year: 'numeric', month: '2-digit', day: '2-digit'}
                )
                : '-'}
            </span>
          </div>
        );
      },
    },
    {
      Header: t('communication.test-drive.report.model'),
      accessor: 'modelName',
      Cell: ({row}: any) => {
        return (
          <div>
            <span>{row.original.modelName ? row.original.modelName : '-'}</span>
          </div>
        );
      },
    },
    {
      Header: t('communication.test-drive.report.dealercode'),
      accessor: 'dealercode',
      Cell: ({row}: any) => {
        return (
          <div>
            <span>
              {row.original.dealercode ? row.original.dealercode : '-'}
            </span>
          </div>
        );
      },
    },
    {
      Header: t('communication.test-drive.report.dealerName'),
      accessor: 'dealerName',

      Cell: ({row}: any) => {
        return (
          <div>
            <span>
              {row.original.dealerName ? row.original.dealerName : '-'}
            </span>
          </div>
        );
      },
    },
    {
      Header: t('communication.test-drive.report.contact-name'),
      accessor: 'contact',
      disableSortBy: true,
      Cell: ({row}: any) => {
        return (
          <div>
            {row.original.contactId && (
              <Link
                className={'underline'}
                to={`/contacts/view/${row.original.contactId}`}
                onClick={(e) => {
                  dispatch(
                    testDriveReportFilterActions.updateCurrentRow(row.id)
                  );
                  e.stopPropagation();
                }}
              >
                {row.original.contactFirstName +
                  ' ' +
                  row.original.contactLastName}
              </Link>
            )}
          </div>
        );
      },
    },
    {
      Header: t('communication.test-drive.report.email'),
      accessor: 'email',
      disableSortBy: true,
      Cell: ({row}: any) => {
        return <div>{row.original.contactEmail}</div>;
      },
    },
    {
      Header: t('communication.test-drive.report.phone'),
      accessor: 'contactPhone',
      disableSortBy: true,
      Cell: ({row}: any) => {
        return <div>{row.original.contactPhone}</div>;
      },
    },
    {
      Header: t('communication.test-drive.report.origin'),
      accessor: i18n.language === 'en' ? 'originSortOrderEnglish' : 'originSortOrderGerman',
      Cell: ({row}: any) => {
        return <div>{t(`communication.test-drive.origin.${row.original.origin}`)}</div>;
      },
    },
    {
      Header: t('communication.test-drive.report.status-header'),
      accessor: 'status',
      Cell: ({row}: any) => {
        return <div>{t(`communication.test-drive.status.${row.original.status ?? 'no-data'}`)}</div>
      }
    },
    !isAreaAdmin(session)
      && {
        accessor: 'actions',
        disableSortBy: true,
        Cell: ({row}: any) => {
          const isClosed = isTestDriveClosed(row);
          const iconColor = isClosed
            ? 'text-dark-gray'
            : 'text-black';

          return (
            <div className="flex gap-4 justify-end">
              <Tooltip
                content={t(
                  'communication.test-drive.report.modals.schedule-test-drive.button-tooltip'
                )}
              >
                <button
                  disabled={isClosed}
                  onClick={(e) => {
                    setScheduleTestDriveModalState(true);
                    setPopupTestDrive(row.original);
                    e.stopPropagation();
                  }}
                >
                  <Icon iconName={'edit_calendar'} className={iconColor}/>
                </button>
              </Tooltip>
              <Tooltip
                content={t(
                  'communication.test-drive.report.modals.cancel-test-drive.button-tooltip'
                )}
              >
                <button
                  disabled={isClosed}
                  onClick={(e) => {
                    setCancelTestDriveModalState(true);
                    setPopupTestDrive(row.original);
                    e.stopPropagation();
                  }}
                >
                  <Icon iconName={'cancel'} className={iconColor}/>
                </button>
              </Tooltip>
              {isFinishButtonVisible(row) && (
                <Tooltip
                  content={t(
                    'communication.test-drive.report.modals.finish-test-drive.button-tooltip'
                  )}
                >
                  <button
                    onClick={(e) => {
                      setFinishTestDriveModalState(true);
                      setPopupTestDrive(row.original);
                      e.stopPropagation();
                    }}
                  >
                    <Icon iconName={'sports_score'} className={iconColor}/>
                  </button>
                </Tooltip>
              )}
              {isClosed &&
                <Tooltip
                  content={t(
                      'communication.test-drive.report.modals.show_test_drive_details.button_tooltip'
                  )}
                >
                <button
                    onClick={(e) => {
                      setShowTestDriveDetailsModalState(true);
                      setPopupTestDrive(row.original);
                      e.stopPropagation();
                    }}
                >
                  <Icon iconName={'visibility'} className={'text-black'}/>
                </button>
              </Tooltip>}
            </div>
          );
        },
      },
  ].filter(Boolean) as Column<TestDriveData>[];

  const handleFilterDateFrom = (from: Date) => {
    setDateFrom(from)
    dispatch(testDriveReportFilterActions.updateFilter({
      ...testDriveReportFilterState.data.filter,
      dateFrom: moment(from).format('DD.MM.YYYY'),
      plannedDateFrom: moment(from).format('DD.MM.YYYY'),
    }));
  };

  const handleFilterDateTo = (to: Date) => {
    setDateTo(to)
    dispatch(testDriveReportFilterActions.updateFilter({
      ...testDriveReportFilterState.data.filter,
      dateTo: moment(to).format('DD.MM.YYYY'),
      plannedDateTo: moment(to).format('DD.MM.YYYY'),
    }));
  };

  const testDriversFilter = {
    id: 'testDriversFilters',
    key: 'testDriversFilters',
    placeholder: t('communication.test-drive.report.search'),
    onSearchUpdate: handleTestDriveReportFilterSearch,
    searchDelay: 500,
    initialSearchValue: testDriveReportFilterState.data.filter.search,
  };

  const handlePreviousRow = useCallback(
    async ({itemsRef}: any) => {
      if (testDriversData.data && testDriveReportFilterState.currentRow) {
        itemsRef.current
          .get(testDriveReportFilterState.currentRow)
          ?.scrollIntoView({behavior: 'smooth', block: 'center'});
        dispatch(testDriveReportFilterActions.updateCurrentRow(undefined));
      }
    },
    [testDriversData.data, testDriversData.loading]
  );

  const selectOptionsDependingOnAllSelectedOption = (allOptions: string[], selected: string[], changedId: string): string[] => {
    if (changedId === 'all') {
      return selected.includes('all') ? allOptions : [];
    }
    return selected.filter(id => id !== 'all');
  }

  const handleOriginFilterSelect = (_id: string, selected: string[], changedId: string) => {
    const allOptions = obtainFilterByOriginOptions().map(item => item.id).filter(id => id !== 'all').sort() as string[];
    const selectedIds = selectOptionsDependingOnAllSelectedOption(allOptions, selected, changedId)

    dispatch(testDriveReportFilterActions.updateFilter({
      ...testDriveReportFilterState.data.filter,
      origins: selectedIds
    }));
  }

  const handleStatusFilterSelect = (_id: string, selected: string[], changedId: string) => {
    const allOptions = filterByStatusOptions.map(item => item.id).filter(id => id !== 'all').sort() as string[];
    const selectedIds = selectOptionsDependingOnAllSelectedOption(allOptions, selected, changedId)

    dispatch(testDriveReportFilterActions.updateFilter({
      ...testDriveReportFilterState.data.filter,
      statuses: selectedIds
    }));
  }

  useEffect(() => {
    dispatch(
      testDriveReportFilterActions.updateFilter({
        ...testDriveReportFilterState.data.filter,
        dealers: dashboardFilterState.dealerFilter.dealers,
      })
    );
  }, [dashboardFilterState.dealerFilter.dealers]);

  const fetchTestDriversData = useCallback(
    async ({pageSize, pageIndex, pageSortBy}: DataFetchProps) => {
      dispatch(testDriveReportFilterActions.updatePageIndex(pageIndex));
      let sortByDir = 1;
      let sortByField = '';
      if (pageSortBy && pageSortBy.length > 0) {
        const sortParams = pageSortBy[0];
        sortByDir = sortParams.desc ? -1 : 1;
        sortByField = sortParams.id ?? 'createdAt';
      }
      if (option === 'createAt') {
        setTestDriveRequestBody({
          filter: {
            ...testDriveReportFilterState.data.filter,
            dateTo: dateTo,
            dateFrom: dateFrom,
            plannedDateFrom: undefined,
            plannedDateTo: undefined,
          },
          pageSize: pageSize,
          pageIndex: pageIndex,
          sortField: sortByField,
          sortDir: sortByDir,
        });
      } else if (option === 'plannedDate') {
        setTestDriveRequestBody({
          filter: {
            ...testDriveReportFilterState.data.filter,
            dateTo: undefined,
            dateFrom: undefined,
            plannedDateFrom: dateFrom,
            plannedDateTo: dateTo,
          },
          pageSize: pageSize,
          pageIndex: pageIndex,
          sortField: sortByField,
          sortDir: sortByDir,
        });
      }
    },
    [option, testDriveReportFilterState.data.filter]
  );

  const getTabIndex = () => {
    switch (tabParam) {
      case 'auto':
        return 0;
      case 'moto':
        return 1;
      default:
        return 0;
    }
  };

  const handleTabChange = (tabIndex: number) => {
    const isMotoTab = tabIndex === 1;
    navigate(
      `/communication/test-drives-report/${isMotoTab ? 'moto' : 'auto'}`
    );
  };

  return (
    <div className="w-full h-full">
      {(fromDashboardSevenDays || fromDashboardOneMonth) && (
        <div className="h-fit w-fit">
          <Button
            py="4"
            className="w-full mb-1"
            onClick={() => {
              navigate(-1);
            }}
          >
            <Icon iconName={'arrow_back'} className="mr-1"></Icon>
            {t('communication.test-drive.report.back')}
          </Button>
        </div>
      )}
      <Headline className="p-8" title={t('communication.test-drive.title')} />
      <div className="ml-8 mb-2 -mt-9">
          <ReportFilters showOnlyDealersScope={tabParam === 'moto' ? DealersScope.MOTO : DealersScope.AUTO}/>
      </div>
      <div className="mt-7">
      {isAdmin(session) &&
        <Tabs defaultActiveTabIndex={getTabIndex()} onTabChange={handleTabChange}>
          <Tab title={t('communication.test-drive.report.auto')} tabIndex={0}/>
          <Tab title={t('communication.test-drive.report.moto')} tabIndex={1}/>
        </Tabs>
      }
      </div>
      <div className="flex flex-row gap-x-8 -mt-4 ps-8 pb-8">
        <SelectInput
            options={filterByDateOptions}
            label={{
              name: t('communication.test-drive.report.filter-option.title'),
              position: 'top',
            }}
            onSelect={(value) => setOption(value)}
        />

        <DatePickerRange
            id="createAt"
            labelFrom={t(
                `communication.test-drive.report.filter-option.from`
            )}
            labelTo={t(`communication.test-drive.report.filter-option.to`)}
            from={dateFrom}
            to={dateTo}
            changeFrom={handleFilterDateFrom}
            changeTo={handleFilterDateTo}
        />

        <div className="flex flex-row gap-x-8 pt-8">
          <Filter
              id='test_drive_origin_filter'
              name={t('communication.test-drive.report.origin_filter.name')}
              items={filterByOriginOptions}
              selected={testDriveReportFilterState.data.filter.origins}
              onChange={handleOriginFilterSelect}
              width="20rem"
          />
          <Filter
              id='test_drive_origin_filter'
              name={t('communication.test-drive.report.status_filter.name')}
              items={filterByStatusOptions}
              selected={testDriveReportFilterState.data.filter.statuses}
              onChange={handleStatusFilterSelect}
              width="20rem"
          />
        </div>
      </div>

      <div className="p-8 -mt-6">
        <Table
          key={'test-drive-report-table'}
          filter={testDriversFilter}
          columns={columns}
          data={testDriversData.data ? testDriversData.data : []}
          initPageSize={pageSize}
          loading={testDriversData.loading}
          rowCount={testDriversCountData.data}
          countLabel={
            testDriversData.data && testDriversData.data.length === 1
              ? t('common.list.count.entry')
              : t('common.list.count.entries')
          }
          fetchData={fetchTestDriversData}
          initialPage={testDriveReportFilterState.data.pageIndex}
          basic={true}
          pagination
          resetOnChange={testDriveReportFilterState.data.filter}
          handleScrollToRow={handlePreviousRow}
        />

        <ScheduleTestDrivePopup
          setModalState={setScheduleTestDriveModalState}
          modalState={scheduleTestDriveModalState}
          testDrive={popupTestDrive}
          refreshData={testDriversData.refreshData}
        />
        <CancelTestDrivePopup
          setModalState={setCancelTestDriveModalState}
          modalState={cancelTestDriveModalState}
          testDrive={popupTestDrive}
          refreshData={testDriversData.refreshData}
          setCreateTestDriveModalState={setCreateTestDriveModalState}
        />
        <FinishTestDrivePopup
          setModalState={setFinishTestDriveModalState}
          modalState={finishTestDriveModalState}
          testDrive={popupTestDrive}
          refreshData={testDriversData.refreshData}
        />
        <CreateTestDrivePopup
          setModalState={setCreateTestDriveModalState}
          modalState={createTestDriveModalState}
          testDrive={popupTestDrive}
          refreshData={testDriversData.refreshData}
        />
        <ShowTestDriveDetailsPopup
          setModalState={setShowTestDriveDetailsModalState}
          modalState={showTestDriveDetailsModalState}
          testDrive={popupTestDrive}
          refreshData={testDriversData.refreshData}
        />
      </div>
    </div>
  );
};

export default TestDriveReport;

import { UiNode } from "@ory/client";
import { UiNodeInputAttributes } from "@ory/client/api";

export interface UserCredentials {
  email: string;
  password: string;
}

export function GetAuthTrait(
  traits: Array<UiNode>,
  name: string
): UiNodeInputAttributes | undefined {
  const findNode = (elem: UiNode): boolean => {
    const attrs = elem.attributes as UiNodeInputAttributes;
    return attrs.name === name;
  };
  const searchNode = traits.find(findNode);
  if (searchNode) {
    return searchNode.attributes as UiNodeInputAttributes;
  }
  return undefined;
}

export interface UserRequestBody {
  pageSize?: number;
  pageIndex?: number;
  filter?: UserFilter;
  sortField?: string;
  sortDir?: number;
}

export interface UserFilter {
  search?: string;
  filter?: {
    field?: string;
    value?: string;
  }[];
}


import { useTranslation } from 'react-i18next';
import {
  Button,
  ButtonStyle,
  Icon,
  Modal, Tooltip,
} from '@suzuki-frontend-framework-update/ui';
import { UnhideContactsRequest, UnhideContactsResponse } from "../../models/contact";
import * as Popper from "@popperjs/core";
import {toast} from "react-toastify";
import {sendApiRequest} from "../send-api-request/send-api-request";
import { environment } from '../../../environments/environment';

export interface UnhideContactButtonProps {
  selectedCount: number;
  selectedPeopleIds: string[];
  selectedCompaniesIds: string[];
  handleResetSelectionsAfterUnhide: () => void;
  refreshData: () => void;
}

export const UnhideContactButton = (props: UnhideContactButtonProps) => {
  const { t } = useTranslation();

  const customIdFailed = 'custom-id-Failed';
  const customIdSuccess = 'custom-id-success';

  const successToast = (successText: string) => {
    toast.success(successText, {
      position: 'top-center',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      toastId: customIdSuccess,
    });
  };

  const errorToast = (errorText: string) => {
    toast.error(errorText, {
      position: 'top-center',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      toastId: customIdFailed,
    });
  };

  const handleUnhideButtonClick = () => {
    sendApiRequest(
      `${environment.restEndpoint}/contact/contact-service/unhide`,
      'post',
      {
        contactIds: props.selectedPeopleIds,
        companyIds: props.selectedCompaniesIds,
      } as UnhideContactsRequest
    ).then((response ) => {
      props.handleResetSelectionsAfterUnhide();
      if (response.ok) {
        response.json().then((data) => {
          const unhidingResponseData = data as UnhideContactsResponse
          if (unhidingResponseData && unhidingResponseData.notUnhiddenContactsIds.length > 0) {
            const notHiddenContactsCount = unhidingResponseData.notUnhiddenContactsIds.length;
            errorToast(`${t('contacts.people-companies.unhide-button.contacts-unhiding-partial-success')} ${notHiddenContactsCount}.`);
          } else {
            successToast(t('contacts.people-companies.unhide-button.contacts-unhiding-success'));
          }
          props.refreshData();
        })
      } else {
        errorToast(t('contacts.people-companies.unhide-button.contacts-unhiding-failure'));
      }
    });
  }

  return (
      <Button
        disabled={props.selectedCount <= 0}
        buttonStyle={ButtonStyle.OUTLINE}
        className={`bg-white w-fit`}
        onClick={handleUnhideButtonClick}
      >
        <Icon iconName="visibility" />
        <span className=" pl-[5px]">
            {props.selectedCount <= 0
              ? t('contacts.people-companies.unhide-button.no-contacts-selected')
              : t('contacts.people-companies.unhide-button.title')}
          </span>
      </Button>
  );
};

export default UnhideContactButton;

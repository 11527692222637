import { useTranslation } from 'react-i18next';
import { BasicOutput, Button, FileBase64Info, FileBase64Input, Headline, Spinner, } from '@sde/basic-ui-library';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { sendApiRequest } from "../../../../components/send-api-request/send-api-request";
import { environment } from "../../../../../environments/environment";
import { ExcelImportRequest } from "../../../../models/test-drive";

export function TestDriveImport() {
  const { t } = useTranslation();
  const [selectedFile, setSelectedFile] = useState<FileBase64Info>();
  const [resultLoading, setResultLoading] = useState<boolean>(false);

  const successToast = (successText: string) => {
    toast.success(successText, {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      toastId: 'excel-import-success',
    });
  };

  const errorToast = (errorText: string) => {
    toast.error(errorText, {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      toastId: 'excel-import-error',
    });
  };

  const handleImport = () => {
    if (
      selectedFile?.type &&
      [
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.ms-excel.sheet.macroEnabled.12',
        'application/vnd.ms-excel.template.macroEnabled.12',
      ].includes(selectedFile.type)
    ) {
      setResultLoading(true);
      sendApiRequest(
        `${environment.restEndpoint}/action/testdrives/import`,
        'POST',
        {
          content: selectedFile?.base64,
        } as ExcelImportRequest
      )
        .then((response) => {
          if (response.ok) {
            successToast(t('communication.test-drive.import.excel-import-success'));
          } else if (response.status === 500) {
            errorToast(t('communication.test-drive.import.excel-import-failed'));
          } else {
            throw new Error(response.status + ' ' + response.statusText);
          }
        })
        .catch((e) => {
          console.log(e)
          errorToast(t('error') + e);
        })
        .finally(() => setResultLoading(false));
    } else {
      console.log("Wrong file format: " + selectedFile?.type)
      errorToast(t('communication.test-drive.import.excel-import-wrong-format'));
    }
  };

  return (
    <div className="w-full h-full grow">
      <div className="flex flex-row justify-between items-center">
        <Headline className="p-8" title={t('communication.test-drive.import.title')} />
      </div>
      <div className="flex flex-row p-8 gap-x-8">
        <div className="w-1/4 flex flex-col min-w-[20rem]">
          <BasicOutput>
            {resultLoading ? <Spinner/> : <>
              <FileBase64Input
              multiple={false}
              onDone={(files) => setSelectedFile(files[0])}
            />
              <Button className="mt-4" onClick={handleImport}>
                {t('communication.test-drive.import.button')}
              </Button>
            </>}
          </BasicOutput>
        </div>
        <div></div>
      </div>
    </div>
  );
}

export default TestDriveImport;

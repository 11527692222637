import React from 'react';
import Icon from '../icon/icon';
import './basic-input.module.scss';

/**
 * A basic input component.
 * All basic types are supported
 * The required field is for now only for visual purpose. No native check is applied.
 * Please handle error checking yourself.
 *
 */

export type BasicInputProps = {
  label?: {
    name?: string;
    position: 'top' | 'right' | 'left'; //only left, top and right
    hide?: boolean;
  };
  id?: string;
  error?: string;
  type?: string;
  className?: string;
  name?: string;
  value?: string | number;
  required?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  readOnly?: boolean;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLElement>) => void;
  onKeyUp?: (e: React.KeyboardEvent<HTMLElement>) => void;
  style?: string;
  width?: string;
  icon?: string; //icon name from https://fonts.google.com/icons
  min?: string | number;
  step?: number;
  regex?: RegExp;
  disabled?: boolean;
};

export const BasicInput = (props: BasicInputProps) => {
  const labler = props.label?.name ? (
    <label
      htmlFor={props.id}
      className={`text-base text-dark font-suzuki-bold block mr-4 ${
        props.label.hide ? 'invisible' : ''
      } whitespace-nowrap`}
    >
      {props.label.name.toUpperCase()}
      {props.required ? '*' : null}
    </label>
  ) : null;

  const icon = props.icon ? (
    <Icon className="absolute inset-y-2 left-3" iconName={props.icon} />
  ) : null;

  const error = (
    <p
      className="text-xs text-red-500 font-medium block mt-1 absolute"
      style={{ visibility: props.error ? 'visible' : 'hidden' }}
    >
      {props.error}
    </p>
  );

  return (
    <div className={props.className}>
      {props.label?.position === 'top' ? labler : ''}
      <div className="flex flex-nowrap items-center justify-between">
        {props.label?.position === 'left' ? labler : ''}
        <div
          className="relative"
          style={props.width ? { width: props.width } : { width: '100%' }}
        >
          {icon}
          <input
            name={props.name}
            className={`h-10 w-full border border-solid ${
              props.readOnly
                ? 'border-dark-gray  text-dark-gray'
                : 'border-dark text-dark'
            }  ${icon ? 'pl-12' : 'pl-4'} pr-4 py-3 text-base outline-none`}
            type={props.type ? props.type : 'text'}
            id={props.id}
            value={props.value}
            onChange={(e) => {
              if (
                props.onChange &&
                (!props.regex || props.regex.test(e.target.value))
              ) {
                props.onChange(e);
              }
            }}
            placeholder={props.placeholder}
            readOnly={props.readOnly}
            onBlur={props.onBlur}
            onKeyDown={props.onKeyDown}
            onKeyUp={props.onKeyUp}
            min={props.min}
            step={props.step}
            disabled={props.disabled}
          />

          {error}
        </div>
        {props.label?.position === 'right' ? labler : ''}
      </div>
    </div>
  );
};

export default BasicInput;

import { TimelineItem } from '@sde/basic-ui-library';
import { t } from 'i18next';
import { toast } from 'react-toastify';
import { environment } from '../../environments/environment';
import { sendApiRequest } from '../components/send-api-request/send-api-request';

export interface Action {
  objectId: string;
  title: string;
  message: string;
  note: string;
  actionDate: Date;
  origin?: string;
  contactId: string;
  vehicleId: string;
  referenceCollection?: string;
  referenceType: string;
  referenceId: string;
  contexts: ActionContext[];
  createdAt: Date;
}

export interface ActionContext {
  id: string;
  type: ContextType;
}

export enum ContextType {
  CONTACT = "CONTACT",
  COMPANY = "COMPANY",
  VEHICLE = "VEHICLE"
}

export interface ActionTimelineItem extends TimelineItem {
  objectId: string;
  title: string;
  message: string;
  note: string;
  actionDate: Date;
  origin: string;
  contactId: string;
  vehicleId: string;
  referenceType: string;
  referenceId: string;
  createdAt: Date;
}

export interface RemoveActionData {
    id?: string;
    context?: ActionContext;
}

export interface ActionsToTimelineItemsProps {
  actions?: Action[];
  onEdit?: (action: Action) => void;
  onDelete?: (action: Action) => void;
}
const customIdSuccess = 'custom-id-success';
const customIdFailed = 'custom-id-Failed';
const successToast = (successText: string) => {
  toast.success(successText, {
    position: 'top-center',
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    toastId: customIdSuccess,
  });
};

const errorToast = (errorText: string) => {
  toast.error(errorText, {
    position: 'top-center',
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    toastId: customIdFailed,
  });
};
export const ActionsToTimelineItems = (
  props: ActionsToTimelineItemsProps
): TimelineItem[] => {
  let result: TimelineItem[] = [];
  if (props.actions) {
    result = props.actions.map(
      (a) =>
        ({
          objectId: a.objectId,
          date: a.actionDate,
          name:
            a.title === 'note'
              ? t('add-action.options.note')
              : a.title === 'conversationLog'
              ? t('add-action.options.conversation-log')
              : a.title,
          origin: a.origin,
          description: a.message,
          onEdit:
            (a.title === 'note' || a.title === 'conversationLog') &&
            props.onEdit
              ? () => {
                  if (props.onEdit) props.onEdit(a);
                }
              : undefined,
          onDelete:
            (a.title === 'note' || a.title === 'conversationLog') &&
            props.onDelete
              ? () => {
                  if (props.onDelete) props.onDelete(a);
                }
              : undefined,
        } as TimelineItem)
    );
  }

  return result;
};

export const ActionsToActionsExtendingTimelineItems = (
  props: ActionsToTimelineItemsProps
): ActionTimelineItem[] => {
  let result: ActionTimelineItem[] = [];
  if (props.actions) {
    result = props.actions.map(
      (a) =>
        ({
          objectId: a.objectId,
          date: a.actionDate,
          name:
            a.title === 'note'
              ? t('add-action.options.note')
              : a.title === 'conversationLog'
                ? t('add-action.options.conversation-log')
                : a.title,
          description: a.message,
          note: a.note,
          referenceId: a.referenceId,
          referenceCollection: a.referenceCollection,
          onEdit:
            (a.title === 'note' || a.title === 'conversationLog') &&
            props.onEdit
              ? () => {
                if (props.onEdit) props.onEdit(a);
              }
              : undefined,
          onDelete:
            (a.title === 'note' || a.title === 'conversationLog') &&
            props.onDelete
              ? () => {
                if (props.onDelete) props.onDelete(a);
              }
              : undefined,
        } as ActionTimelineItem)
    );
  }

  return result;
};

export const deleteAction = (
  removeData?: RemoveActionData,
  refreshData?: () => void,
  closePopup?: () => void
) => {
  if (removeData?.id && removeData.context) {
    sendApiRequest(
      `${environment.restEndpoint}/action/action/RemoveForContext`,
      'post',
      {
        id: removeData.id,
        context: removeData.context
      }
    ).then((response) => {
      if (response.ok) {
        successToast(t('success'));
        if (refreshData) refreshData();
        if (closePopup) closePopup();
      } else {
        errorToast(t('error') + response.statusText);
      }
    });
  }
};

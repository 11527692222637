import { environment } from '../../../environments/environment';

const buildHeaders = (includeApiKey: boolean, headers?: Map<string, string>): { [key: string]: string } => {
  const reqHeaders: { [key: string]: string } = {};

  if (includeApiKey) {
    reqHeaders['apikey'] = environment.apiKey;
  }

  if (headers) {
    headers.forEach((value, key) => {
      reqHeaders[key] = value;
    });
  }

  if (!reqHeaders['Content-type']) {
    reqHeaders['Content-type'] = 'application/json';
  }

  return reqHeaders;
};

const sendRequest = async (
  url: string,
  method: string,
  requestBody: any,
  reqHeaders: { [key: string]: string }
) => {
  return await fetch(url, {
    method: method.toLocaleUpperCase(),
    headers: reqHeaders,
    body: JSON.stringify(requestBody),
  });
};

export const sendApiRequest = async (
  url: string,
  method: string,
  requestBody?: any,
  headers?: Map<string, string>
) => {
  const reqHeaders = buildHeaders(true, headers);
  return await sendRequest(url, method, requestBody, reqHeaders);
};

export const sendUnauthorizedApiRequest = async (
  url: string,
  method: string,
  requestBody?: any,
  headers?: Map<string, string>
) => {
  const reqHeaders = buildHeaders(false, headers);
  reqHeaders['apikey'] = 'NFc1fHBU3QtDaumPK9mMVtUv'; // dummy apikey
  return await sendRequest(url, method, requestBody, reqHeaders);
};

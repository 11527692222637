import { Trans, useTranslation } from 'react-i18next';
import React from 'react';

export interface NewsletterUnsubscriptionResultProps {
  isUnsubscriptionSuccessful: boolean;
  isMoto: boolean;
}

export const NewsletterUnsubscriptionResult = ({ isUnsubscriptionSuccessful, isMoto }: NewsletterUnsubscriptionResultProps) => {
  const { t } = useTranslation();
  const status = isUnsubscriptionSuccessful ? 'success' : 'failure';
  const url: string = isMoto ? 'https://motorrad.suzuki.de/newsletter' : 'https://auto.suzuki.de/ueber-suzuki/newsletter';
  const bannerSource = `https://signatur.suzuki.de/banners/redirection-pages/${isMoto ? 'moto' : 'auto'}/newsletters/default.jpg`;

  return (
        <div className="container mx-auto px-4 py-8">
            <div className="flex flex-col items-center justify-center">
                <h1 className="text-5xl font-suzuki-headline mb-4">{ t(`unsubscribe_newsletter.${status}.headline`) }</h1>
                <p className="text-lg font-suzuki-regular mb-6">
                    {isUnsubscriptionSuccessful ?
                        <Trans i18nKey="unsubscribe_newsletter.success.body">
                            newsletter<a className="underline" href={url} >newsletter</a>
                        </Trans>
                      :
                        <Trans i18nKey={`unsubscribe_newsletter.failure.body_${isMoto ? 'moto' : 'auto'}`}>
                            newsletter<a className="underline" href={url} >newsletter</a>
                        </Trans>
                    }
                </p>
              {
                isMoto ?
                  <div className="w-full md:w-2/3 lg:w-1/2">
                    <img
                      src={bannerSource}
                      alt="Newsletter subscription banner"
                      className="w-full h-auto object-cover"
                    />
                  </div>
                  :
                  <img
                    src={bannerSource}
                    alt="Newsletter subscription banner"
                    className="w-full h-auto object-cover"
                  />
              }
                <p className="text-lg mt-6">{ t(`unsubscribe_newsletter.${status}.ending`) }</p>
            </div>
        </div>
  );
};

import { Button, ButtonStyle, Modal, Textarea, } from '@suzuki-frontend-framework-update/ui';
import { environment } from '../../../environments/environment';
import { useTranslation } from 'react-i18next';
import { sendApiRequest } from '../send-api-request/send-api-request';
import { Action } from '../../models/action';
import { toast } from 'react-toastify';
import { SelectInput } from "@sde/basic-ui-library";

export interface EditActionPopupProps {
  setModalState: (state: boolean) => void;
  modalState: boolean;
  action?: Action;
  setActionState: (state: Action) => void;
  refreshData?: () => void;
}

export const EditActionPopup = (props: EditActionPopupProps) => {
  const { t } = useTranslation();

  const handleCancel = () => {
    if (props.action) {
      props.setActionState({
        ...props.action,
        title: 'conversationLog',
        message: '',
      });
    }
    props.setModalState(false);
  };
  const customIdSuccess = 'custom-id-success';
  const customIdFailed = 'custom-id-Failed';
  const successToast = (successText: string) => {
    toast.success(successText, {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      toastId: customIdSuccess,
    });
  };

  const errorToast = (errorText: string) => {
    toast.error(errorText, {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      toastId: customIdFailed,
    });
  };
  const handleSubmit = () => {
    if (props.action?.message) {
      const {  objectId, ...data} = props.action;
      sendApiRequest(`${environment.restEndpoint}/action/action/${objectId}`, 'put', {
        ...data
      }).then((response) => {
        if (response.ok) {
          successToast(t('success'));
          handleCancel();
          if (props.refreshData) props.refreshData();
        } else {
          errorToast(t('error') + response.statusText);
        }
      });
    } else {
      errorToast('Notiz fehlt');
    }
  };

  return (
    <div>
      <Modal
        key="add-action-modal"
        show={props.modalState}
        handleClose={() => handleCancel()}
        title={t('add-action.title')}
      >
        <div className="flex flex-col gap-y-6 pt-8 p-5">
          <div className="flex flex-col gap-y-6 items-end pr-28">
            <SelectInput
              options={[
                {
                  key: 0,
                  name: t('add-action.options.conversation-log'),
                  value: 'conversationLog',
                },
                {
                  key: 1,
                  name: t('add-action.options.note'),
                  value: 'note',
                },
              ]}
              value={props.action?.title}
              width="20rem"
              onSelect={(value) => {
                if (props.action) {
                  props.setActionState({ ...props.action, title: value });
                }
              }}
              label={{
                name: t('add-action.action-type'),
                position: 'left',
              }}
            />
            <Textarea
              value={props.action?.message}
              onChange={(value) => {
                if (props.action) {
                  props.setActionState({ ...props.action, message: value });
                }
              }}
              width="20rem"
              label={{
                name: t('add-action.note'),
                position: 'left',
              }}
              rows={8}
              resizeDisabled
            />
          </div>
          <div className="flex flex-row gap-x-6 justify-end">
            <Button
              className="bg-white"
              buttonStyle={ButtonStyle.OUTLINE}
              onClick={handleCancel}
              px="5"
              py="3"
            >
              {t('add-action.cancel')}
            </Button>
            <Button onClick={handleSubmit} px="5" py="3">
              {t('add-action.submit')}
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default EditActionPopup;

import Loader from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import React from 'react';

export interface SpinnerProps {
  text?: string;
}

export function Spinner(props: SpinnerProps) {
  return (
    <div className="flex flex-col justify-center items-center">
      <Loader type="Oval" color="#009DDB" height={60} width={60} />
      {props.text ? props.text : 'Lade Daten...'}
    </div>
  );
}

export default Spinner;

import { BasicOutput, Button, DataInfoContainer, DataInfoItemValue, Headline, Icon, Spinner, Table, Timeline, TimelineItem, Tooltip, } from '@sde/basic-ui-library';
import * as Popper from '@popperjs/core';
import { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import PageNotFound from '../page-not-found/page-not-found';
import './vehicle-details.module.scss';
import { format } from 'react-string-format';
import { environment } from '../../../environments/environment';
import { useApiGetData } from '../../components/useApiGetData/useApiGetData';
import { Driver, Vehicle } from '../../models/vehicle';
import { VehicleMileage } from '../../models/vehicle-milleage';
import { Company, Contact } from '../../models/contact';
import { VehicleRegistration } from '../../models/vehicle-registration';
import { Action, ActionsToActionsExtendingTimelineItems } from '../../models/action';
import { sendApiRequest } from '../../components/send-api-request/send-api-request';

import { Dealer } from '../../models/dealer';
import EditActionPopup from '../../components/edit-action-popup/edit-action-popup';
import { useDispatch, useSelector } from 'react-redux';
import { vehicleFilterActions } from '../../store/slices/vehicle-filter.slice';
import { AppDispatch } from '../../store/setup';
import { selectAllUserManagement } from "../../store/slices/user-management.slice";
import { Session } from "@ory/client";
import ChangeHistoryPopup from "../../components/change-history-popup/change-history-popup";
import { VEHICLE_TRANSLATION_ATTRIBUTES, VEHICLE_TRANSLATION_KEY } from "../../models/history-entry";
import { getHidingReasonOfVehicle } from "../../components/hide-vehicle-popup/hiding-deactivating-reasons";
import { getDealerCodesFromSession, isAdmin } from "../../helpers/session-utils";

export function VehicleDetails() {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const dispatch: AppDispatch = useDispatch();
    const entities = useSelector(selectAllUserManagement);
    const [session, setSession] = useState({} as Session);
    const {id} = useParams();
    const [editActionModalState, setEditActionModalState] = useState(false);
    const [editAction, setEditAction] = useState<Action>();
    const [contactsData, setContactsData] = useState<Contact[]>([]);
    const [companiesData, setCompaniesData] = useState<Company[]>([]);
    const [registrationData, setRegistrationData] = useState<VehicleRegistration>();
    const [changeHistoryModalState, setChangeHistoryModalState] = useState(false);
    const [contactsCompaniesLoading, setContactsCompaniesLoading] = useState(false);
    const [timelineItems, setTimelineItems] = useState<TimelineItem[]>([]);
    const [vehicleMileages, setVehicleMileages] = useState<VehicleMileage[]>([]);

    const vehicleData = useApiGetData<Vehicle>(
        `${environment.restEndpoint}/vehicle/vehicle-service/vehicle?ID=${id}`,
        'get'
    );

    const mileagesData = useApiGetData<VehicleMileage[]>(
        `${environment.restEndpoint}/vehicle/vehicle-service/mileage/GetByVehicleID?ID=${id}`,
        'get',
        'mileages'
    );

    const registrationsData = useApiGetData<VehicleRegistration[]>(
        `${environment.restEndpoint}/vehicle/vehicle-service/registration/GetByVehicleID?ID=${id}`,
        'get',
        'registrations'
    );

    const actionData = useApiGetData<{actions: Action[]}>(
        `${environment.restEndpoint}/action/action?contextId=${id}&contextType=VEHICLE`,
        'get',
        undefined
    );

    useEffect(() => {
        const lastEntity = entities[entities.length - 1];
        if (lastEntity && lastEntity.currentUser) {
            setSession(lastEntity.currentUser);
        }
    }, [entities]);

    useEffect(() => {
        const activeRegistration = registrationsData.data
            ? registrationsData.data.filter((r) => r.active).shift()
            : undefined;
        setRegistrationData(activeRegistration)
    }, [registrationsData.loading]);

    const filterDrivers = (driver: Driver, dealerCodes: string[]) => {
        if (isAdmin(session)) {
            return true;
        }
        return !!dealerCodes.find(code => code === driver.dealerCode);
    };

    const fetchDrivers = async (drivers?: Driver[]) => {
        if (!drivers) return;
        const contacts: Contact[] = [];
        const companies: Company[] = [];
        for (const driver of drivers) {
            if (driver.contactId) {
                const contact = await sendApiRequest(
                    `${environment.restEndpoint}/contact/contact-service/contact/GetContact`,
                    'post',
                    {
                      id: driver.contactId,
                      details: 'FULL',
                    }
                ).then(response => response.json());
                contacts.push(contact);
            } else if (driver.companyId) {
                const company = await sendApiRequest(
                    `${environment.restEndpoint}/contact/contact-service/company/GetCompany`,
                    'post',
                    {
                      id: driver.companyId,
                      details: 'FULL',
                  }
                ).then(response => response.json());
                companies.push(company);
            }
          }
        setContactsData(contacts);
        setCompaniesData(companies);
    };

    useEffect(() => {
        const userDealerCodes = getDealerCodesFromSession(session);
        const drivers =  vehicleData.data?.drivers?.filter((d) => d.active)
          .filter((d) => filterDrivers(d, userDealerCodes))
        setContactsCompaniesLoading(true);
        fetchDrivers(drivers)
            .catch((e) => console.log(e))
            .finally(() => setContactsCompaniesLoading(false));
    }, [
        vehicleData.data, vehicleData.loading
    ]);

    const [dealers, setDealers] = useState<Dealer[]>([]);

    useEffect(() => {
        if (vehicleData.data?.owner) {
            vehicleData.data?.owner.forEach((code) => {
                sendApiRequest(
                    `${environment.restEndpoint}/dealer/dealer-service/dealer?dealercode=${code}`,
                    'get'
                )
                    .then((response) => response.json())
                    .then((data) => {
                        const tmp = data.dealer as Dealer;
                        if (tmp) {
                            setDealers((prev) =>
                                prev.map((d) => d.objectID).indexOf(tmp.objectID) === -1
                                    ? [...prev, tmp]
                                    : prev
                            );
                        }
                    });
            });
        }
    }, [vehicleData.data, vehicleData.loading]);

  useEffect(() => {
    if (actionData.data) {
      const actions = actionData.data;

      const actionsTimelineItems = ActionsToActionsExtendingTimelineItems({
        actions: actions.actions,
        onEdit: onEditAction,
      })

      setTimelineItems(actionsTimelineItems);
    }

  }, [actionData.loading]);

    const onEditAction = (action: Action) => {
        setEditAction(action);
        setEditActionModalState(true);
    };

    const mileageColumns = [
        {
            Header: t('vehicle-details.mileage-section.state'),
            accessor: 'milage',
        },
        {
            Header: t('vehicle-details.mileage-section.date'),
            accessor: 'created',
            Cell: ({row}: any) => {
                return (
                    <span>
            {row.original.created
                ? new Date(row.original.created).toLocaleDateString()
                : ''}
          </span>
                );
            },
        },
        {
            Header: t('vehicle-details.mileage-section.origin'),
            accessor: 'origin',
        },
    ];

    const getDealersCodes = (): DataInfoItemValue[] => {
        const result: ReactElement[] = [];
        if (dealers.length === 1) {
            result.push(<span key={'only'}>{dealers[0].dealercode}</span>);
        } else if (dealers.length > 1) {
            dealers.forEach((dealer, index) => {
                if (dealer) {
                    result.push(
                        <span
                            key={index}
                            onClick={() => navigate(`/admin/dealer/${dealer.dealercode}`)}
                            className="cursor-pointer underline"
                        >
              {dealer.dealercode}
                            {index < dealers.length - 1 ? ', ' : ''}
            </span>
                    );
                }
            });
        }
        return [
            {
                value: (
                    <div className="flex flex-row gap-x-1">
                        {result.length > 0 ? result : '-'}
                    </div>
                ),
            },
        ];
    };
    const getDealersNames = (): DataInfoItemValue[] => {
        const result: ReactElement[] = [];
        dealers.forEach((dealer, index) => {
            if (dealer) {
                result.push(
                    <span key={index}>
            {dealer.name}
                        {index < dealers.length - 1 ? ', ' : ''}
          </span>
                );
            }
        });

        return [
            {
                value: (
                    <div className="flex flex-row gap-x-1">
                        {result.length > 0 ? result : '-'}
                    </div>
                ),
            },
        ];
    };
    const getVehicleColor = () => {
        if (vehicleData?.data?.VehicleSpecification?.color) {
            if (vehicleData?.data?.VehicleSpecification?.colorcode) {
                return (
                    vehicleData.data?.VehicleSpecification.color +
                    ' (' +
                    vehicleData?.data?.VehicleSpecification?.colorcode +
                    ')'
                );
            }
            return vehicleData.data?.VehicleSpecification.color;
        }
        return '-';
    };

    function setNameForVisibility() {
        const type = vehicleData?.data ? getHidingReasonOfVehicle(session, vehicleData.data) : undefined
        if (type) {
            return t('hide-vehicle.options.' + type)
        }
        if (vehicleData?.data?.deactivation?.deactivationReason) {
            const deactivatingReason = vehicleData?.data?.deactivation?.deactivationReason;
            return t('deactivate-vehicle.options.' + deactivatingReason)
        }
        return t('hide-vehicle.visible');
    }

  useEffect(() => {
    const isMileageDuplicate = (mileage: VehicleMileage, index: number, allMileagesOfTheVehicle: VehicleMileage[]) => {
      return (
        allMileagesOfTheVehicle.findIndex(
          (element) =>
            element.vehicleID === mileage.vehicleID &&
            element.origin === mileage.origin &&
            element.milage === mileage.milage &&
            element.created === mileage.created &&
            element.modified === mileage.created
        ) !== index
      );
    }
    if (!mileagesData.loading && mileagesData.data) {
      setVehicleMileages(mileagesData.data.filter(
        (item, index, array) => !isMileageDuplicate(item, index, array))
      );
    }
  }, [mileagesData.loading]);

    return !vehicleData.loading && !vehicleData.data ? (
        <div className="grow bg-gray">
            <PageNotFound/>
        </div>
    ) : (
        <div className="grow p-8  bg-gray">
            <div className="h-fit w-fit">
                <Button
                    py="4"
                    className="w-full mb-1 uppercase"
                    onClick={() => {
                        dispatch(vehicleFilterActions.updateVehicleFilter({dealercodes: []}));
                        navigate(-1);
                    }}
                >
                    <Icon iconName={'arrow_back'} className="mr-1"/>
                    {t('vehicle-details.back')}
                </Button>
            </div>
            {vehicleData.loading ? (
                <Spinner/>
            ) : (
                <>
                    <div className="flex flex-row justify-between ">
                        <Headline
                            title={
                                [
                                    vehicleData.data?.brand,
                                    vehicleData.data?.model,
                                    vehicleData.data?.type,
                                ]
                                    .filter((n) => n)
                                    .join(' ') +
                                ' - ' +
                                vehicleData.data?.vin
                            }
                        />
                        <div className="flex flex-row gap-x-4 ">
                          {isAdmin(session) && <Tooltip
                                content={t('vehicle-details.buttons.tooltip.edit-vehicle')}
                                placement={Popper.top}
                            >
                                <Button
                                    onClick={() => navigate(`/vehicles/edit/${id}`)}
                                    className="w-fit h-fit"
                                    py="1"
                                    px="1"
                                >
                                    <Icon iconName="edit" className="text-xl w-7"/>
                                </Button>
                            </Tooltip>}
                            <Tooltip
                                content={t('vehicle-details.history.title')}
                                placement={Popper.top}
                            >
                                <ChangeHistoryPopup
                                    setModalState={setChangeHistoryModalState}
                                    modalState={changeHistoryModalState}
                                    translationKey={VEHICLE_TRANSLATION_KEY}
                                    translationAttributes={VEHICLE_TRANSLATION_ATTRIBUTES}
                                    entries={vehicleData.data?.changeHistory}
                                />
                            </Tooltip>
                        </div>
                    </div>

                    <div className="flex flex-row -mt-2">
                        {vehicleData.data?.created && (
                            <span className="text-dark-gray  font-suzuki-regular">
                {format(
                    t('contact-details.created-at'),
                    new Date(vehicleData.data?.created).toLocaleDateString(
                        'de-DE',
                        {
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit',
                        }
                    ),
                    vehicleData.data?.createdBy
                        ? vehicleData.data?.createdBy
                        : vehicleData.data?.last_origin
                            ? vehicleData.data?.last_origin
                            : '-'
                )}
              </span>
                        )}

                        {vehicleData.data?.modified && (
                            <span className="text-dark-gray  font-suzuki-regular ml-3">
                {format(
                    t('contact-details.modified-at'),
                    new Date(vehicleData.data?.modified).toLocaleDateString(
                        'de-DE',
                        {
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit',
                        }
                    ),
                    vehicleData.data?.modifiedBy
                        ? vehicleData.data?.modifiedBy
                        : '-'
                )}
              </span>
                        )}
                    </div>
                    <div className="flex flex-row gap-x-8">
                        <div className="flex flex-col gap-y-4 w-1/4">
                            <DataInfoContainer
                                title={t('vehicle-details.about-section.title')}
                                className="w-full"
                                items={[
                                    {
                                        name: t('vehicle-details.about-section.vin'),
                                        values: [
                                            {
                                                value: vehicleData.data?.vin
                                                    ? vehicleData.data?.vin
                                                    : '-',
                                            },
                                        ],
                                    },
                                    {
                                        name: t('vehicle-details.about-section.make'),
                                        values: [
                                            {
                                                value: vehicleData.data?.brand
                                                    ? vehicleData.data?.brand
                                                    : '-',
                                            },
                                        ],
                                    },
                                    {
                                        name: t('vehicle-details.about-section.model'),
                                        values: [
                                            {
                                                value: vehicleData.data?.model
                                                    ? vehicleData.data?.model
                                                    : '-',
                                            },
                                        ],
                                    },
                                    {
                                        name: t('vehicle-details.about-section.modelcode'),
                                        values: [
                                            {
                                                value: vehicleData.data?.modelcode
                                                    ? vehicleData.data?.modelcode
                                                    : '-',
                                            },
                                        ],
                                    },
                                    {
                                        name: t('vehicle-details.about-section.type'),
                                        values: [
                                            {
                                                value: vehicleData.data?.modeldescription
                                                    ? vehicleData.data?.modeldescription
                                                    : '-',
                                            },
                                        ],
                                    },
                                    {
                                        name: t('vehicle-details.about-section.equipment'),
                                        values: [
                                            {
                                                value: vehicleData.data?.configurationtype
                                                    ? vehicleData.data?.configurationtype
                                                    : '-',
                                            },
                                        ],
                                    },
                                    {
                                        name: t('vehicle-details.about-section.first-registration'),
                                        values: [
                                            {
                                                value: vehicleData.data?.firstRegistraion
                                                    ? new Date(
                                                        vehicleData.data?.firstRegistraion
                                                    ).toLocaleDateString()
                                                    : '-',
                                            },
                                        ],
                                    },
                                    {
                                        name: t('vehicle-details.about-section.sign'),
                                        values: [
                                            {
                                                value: registrationData?.licensenumber
                                                    ? registrationData?.licensenumber
                                                    : '-',
                                            },
                                        ],
                                    },
                                    {
                                        name: t('vehicle-details.about-section.visibility'),
                                        values: [
                                            {
                                                value: setNameForVisibility()
                                            },
                                        ],
                                    },
                                ]}
                            />

                            <BasicOutput
                                title={t('vehicle-details.mileage-section.title')}
                                className="w-full pt-5"
                                table
                                styled
                            >
                                {!mileagesData.loading && vehicleMileages.length === 0 ? (
                                    <div className="w-full text-center"> - </div>
                                ) : (
                                    <Table
                                        data={vehicleMileages}
                                        columns={mileageColumns}
                                        loading={mileagesData.loading}
                                        basic
                                    />
                                )}
                            </BasicOutput>
                        </div>
                        <div className="flex flex-col gap-y-8 w-1/2">
                            {actionData.loading ? (
                                <Spinner/>
                            ) : (
                                <Timeline
                                    title={t('contact-details.actions')}
                                    httpConfig={{baseUrl: environment.restEndpoint, apiKey: environment.apiKey}}
                                    className="w-full"
                                    items={timelineItems}
                                    key={"history_timeline_vehicle_details"}
                                    timelineItemsContainerId={"timeline_history_container_vehicle_details"}
                                />
                            )}
                        </div>
                        <div className="flex flex-col gap-y-8 w-1/4">
                            {((contactsData && contactsData.length > 0) || (companiesData && companiesData.length > 0) || contactsCompaniesLoading) ? (
                                <>
                                    {contactsCompaniesLoading ? (
                                        <Spinner/>
                                    ) : (
                                        <div className="flex flex-col">
                    <span className="font-suzuki-bold">
                      {t('vehicle-details.registrations-section.title')}
                    </span>
                                            <div className="flex flex-col gap-y-5">
                                                {contactsData?.map((contact, index) =>
                                                    contact.customerNumber ? (
                                                        <DataInfoContainer
                                                            disableDefaultPadding={true}
                                                            className="w-full"
                                                            textBeforeValues={[contact.firstname, contact.name]
                                                                .filter((n) => n)
                                                                .join(' ')}
                                                            linksAfterValues={[
                                                                {
                                                                    name: t(
                                                                        'vehicle-details.registrations-section.more-information'
                                                                    ),
                                                                    onClick: () =>
                                                                        navigate(`/contacts/view/${contact.objectId}`),
                                                                },
                                                            ]}
                                                            items={[
                                                                {
                                                                    name: t('vehicle-details.registrations-section.number'),
                                                                    values: [
                                                                        {
                                                                            value: contact.customerNumber
                                                                                ? contact.customerNumber
                                                                                : '-',
                                                                        },
                                                                    ],
                                                                },
                                                            ]}
                                                        />
                                                    ) : null)
                                                }
                                                {companiesData?.map(company =>
                                                    company ? (
                                                        <DataInfoContainer
                                                            disableDefaultPadding={true}
                                                            className="w-full"
                                                            textBeforeValues={company.name}
                                                            linksAfterValues={[
                                                                {
                                                                    name: t(
                                                                        'vehicle-details.registrations-section.more-information'
                                                                    ),
                                                                    onClick: () =>
                                                                        navigate(`/companies/view/${company.objectId}`),
                                                                },
                                                            ]}
                                                            items={[
                                                                {
                                                                    name: t('vehicle-details.registrations-section.number'),
                                                                    values: [
                                                                        {
                                                                            value: company.customerNumber
                                                                                ? company.customerNumber
                                                                                : '-',
                                                                        },
                                                                    ],
                                                                },
                                                            ]}
                                                        />
                                                    ) : null)
                                                }
                                            </div>
                                        </div>
                                    )}
                                </>
                            ) : null}

                            <DataInfoContainer
                                items={[
                                    {
                                        name: t('dealer-information.basic-data.number'),
                                        values: getDealersCodes(),
                                    },
                                    {
                                        name: t('dealer-information.basic-data.name'),
                                        values: getDealersNames(),
                                    },
                                ]}
                                linksAfterValues={
                                    dealers.length === 1
                                        ? [
                                            {
                                                name: t(
                                                    'vehicle-details.registrations-section.more-information'
                                                ),
                                                onClick: () =>
                                                    navigate(`/admin/dealer/${dealers[0].dealercode}`),
                                            },
                                        ]
                                        : undefined
                                }
                                title={t('crm.dealer')}
                                className="w-full"
                            />
                            {vehicleData.data?.VehicleSpecification && (
                                <DataInfoContainer
                                    title={t('vehicle-details.more-data-section.title')}
                                    className="w-full"
                                    items={[
                                        {
                                            name: t('vehicle-details.more-data-section.body-color'),
                                            values: [
                                                {
                                                    value: getVehicleColor(),
                                                },
                                            ],
                                        },
                                        {
                                            name: t('vehicle-details.more-data-section.capacity'),
                                            values: [
                                                {
                                                    value: vehicleData.data?.VehicleSpecification
                                                        .vehicleloadcapacity
                                                        ? vehicleData.data?.VehicleSpecification.vehicleloadcapacity.toLocaleString()
                                                        : '-',
                                                },
                                            ],
                                        },
                                        {
                                            name: t('vehicle-details.more-data-section.fuel'),
                                            values: [
                                                {
                                                    value: vehicleData.data?.VehicleSpecification.fueltype
                                                        ? vehicleData.data?.VehicleSpecification.fueltype
                                                        : '-',
                                                },
                                            ],
                                        },
                                    ]}
                                />
                            )}
                        </div>
                    </div>
                </>
            )}
            <EditActionPopup
                action={editAction}
                setActionState={setEditAction}
                modalState={editActionModalState}
                setModalState={setEditActionModalState}
                refreshData={actionData.refreshData}
            />
        </div>
    );
}

export default VehicleDetails;

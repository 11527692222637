import {MultiselectOptionsProps} from "@suzuki-frontend-framework-update/ui";
import {SelectOptionsProps} from "@sde/basic-ui-library";

export interface Newsletter {
  objectId?: string;
  name?: string;
  code?: string;
  active?: boolean;
  isDefault?: boolean;
  isMoto?: boolean;
}

export interface NewsletterErrorResponse {
  errorCode: string,
  message: string,
}

export enum DataUpdateError {
  NO_ERROR = "NO_ERROR",
  RESOURCE_ALREADY_EXISTS = "RESOURCE_ALREADY_EXISTS",
  PRECONDITION_FAILED = "PRECONDITION_FAILED",
  CONFLICT = "CONFLICT",
  NOT_FOUND= "NOT_FOUND"
}

export interface NewsletterUpdateResponse {
  success: boolean
  error: DataUpdateError
}
export interface NewsletterCreateResponse {
  id: string
  error: DataUpdateError
}
export const NewsletterErrorResponseMessage = new Map<string, string>([
  ['newsletter_not_found', 'communication.newsletter.definition.popup.error_not_found'],
  ['newsletter_invalid_newsletter_id', 'communication.newsletter.definition.popup.error_invalid_newsletter'],
  ['newsletter_invalid_newsletter_name', 'communication.newsletter.definition.popup.error_invalid_newsletter'],
  ['newsletter_internal_error', 'error_popup.unknown'],
]);

export interface NewsletterInput {
  email: string;
  newsletterCodes: string[];
}

export interface NewsletterFilter {
  ActiveNewsletter?: Newsletter;
  DeactiveNewsletter?: Newsletter;
  dealers?: string[];
  search?: string;
}

export enum NewsletterActivationStatus {
  UNDEFINED = 'UNDEFINED',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export interface IdRequest {
  id: string;
}

export interface Subscriber {
  createdAt: string;
  customerNumber: string;
  customerState: string;
  salutation: string;
  firstname: string;
  name: string;
  email: string;
  customerId: string;
  lastContact: Date;
  newsletter: Newsletter;
}

export interface UnsubscribeInput {
  email: string;
  newsletterID?: string;
  newsletterCode?: string;
  byAdmin?: boolean;
}

export interface UnsubscribeResponse {
  code: string;
  message: string;
  error: string;
}

export interface UpdateSuccessful {
  result: boolean;
}

export interface GetSubscriberInput {
  input: IAPISubscriberFilter
}

export interface IGroup {
  id: string;
  name: string;
  locked: boolean;
  backup: boolean;
  receiverInfo: string;
  stamp: string;
  lastMailing: string;
  lastChange: string;
}


export interface IAPISubscriberFilter {
  newsletterIds: string[];
  exportType?: string;
  cleverreachGroupId?: string;
  prospectFilter?: IAPIFilter;
  customerFilter?: IAPIFilter;
  lostCustomerFilter?: IAPIFilter;
}

export interface ISubscriberFilter {
  newsletterIds: string[];
  exportType?: string;
  cleverreachGroupId?: string;
  prospectFilter?: IFilter;
  customerFilter?: IFilter;
  lostCustomerFilter?: IFilter;
}

export class APISubscriberFilter implements IAPISubscriberFilter {
  newsletterIds: string[] = [];
  exportType?;
  cleverreachGroupId?;
  prospectFilter = {} as IAPIFilter;
  customerFilter = {} as IAPIFilter;
  lostCustomerFilter = {} as IAPIFilter;

  constructor(params: ISubscriberFilter) {
    this.newsletterIds = params.newsletterIds || [];
    if (params.exportType) {
      this.exportType = params.exportType;
    }
    if (params.cleverreachGroupId) {
      this.cleverreachGroupId = params.cleverreachGroupId;
    }
    if (params.prospectFilter) {
      const { models, ...newObj } = params.prospectFilter;
      const transformedModels = transformModels(models);
      this.prospectFilter = {
        active: newObj.active,
        models: transformedModels,
        from: newObj.from,
        to: newObj.to,
      } as IAPIFilter;
    }
    if (params.customerFilter) {
      const { models, ...newObj } = params.customerFilter;
      const transformedModels = transformModels(models);
      this.customerFilter = {
        active: newObj.active,
        models: transformedModels,
        from: newObj.from,
        to: newObj.to,
      } as IAPIFilter;
    }
    if (params.lostCustomerFilter) {
      const { models, ...newObj } = params.lostCustomerFilter;
      const transformedModels = transformModels(models);
      this.lostCustomerFilter = {
        active: newObj.active,
        models: transformedModels,
        from: newObj.from,
        to: newObj.to,
      } as IAPIFilter;
    }
  }
}

export interface IAPIFilter {
  active: boolean;
  models: Array<string>;
  from?: Date;
  to?: Date;
}

export interface IFilter {
  active: boolean;
  models: Array<MultiselectOptionsProps>;
  from?: Date;
  to?: Date;
}

function transformModels(input: MultiselectOptionsProps[]): string[] {
  return input.map((m) => m.value + '');
}


export interface IGroupResponse {
  groups: Array<IGroup>
}

export class GroupsResponse implements IGroupResponse {
  groups: Array<IGroup>;

  constructor(data: Array<IGroup>) {
    this.groups = data;
  }

  toSelectOptions() {
    console.log(this.groups)
    if (this.groups.length > 0) {
      return this.groups.map((g, i) => {
        return {
          key: g.id,
          name: g.name,
          value: g.id,
        } as SelectOptionsProps;
      });
    } else {
      return [];
    }
  }
}

import './textarea.module.scss';
import React from 'react';

export interface TextareaProps {
  id?: string;
  label?: {
    name?: string;
    position: 'top' | 'right' | 'left'; //only left, top and right
  };
  value?: string;
  required?: boolean;
  onChange?: (e: string) => void;
  placeholder?: string;
  width?: string;
  rows?: number;
  className?: string;
  error?: string;
  resizeDisabled?: boolean;
}

export function Textarea(props: TextareaProps) {
  const labler = props.label?.name ? (
    <label
      htmlFor={props.id}
      className={`text-base text-dark font-suzuki-bold block mr-4 py-3 whitespace-nowrap`}
    >
      {props.label.name.toUpperCase()}
      {props.required ? '*' : null}
    </label>
  ) : null;

  const error = (
    <p
      className="text-xs text-red-500 block mt-1 absolute"
      style={{ visibility: props.error ? 'visible' : 'hidden' }}
    >
      {props.error}
    </p>
  );
  return (
    <div className={props.className}>
      {props.label?.position === 'top' ? labler : ''}
      <div className="flex flex-nowrap justify-between">
        {props.label?.position === 'left' ? labler : ''}
        <div
          className="relative"
          style={props.width ? { width: props.width } : { width: '100%' }}
        >
          <textarea
            className={`w-full border border-solid border-dark text-dark  pl-4 pr-4 py-3 text-base outline-none ${
              props.resizeDisabled ? 'resize-none' : ''
            }`}
            id={props.id}
            value={props.value}
            onChange={(e) => {
              if (props.onChange) props.onChange(e.target.value);
            }}
            placeholder={props.placeholder}
            rows={props.rows}
          />
          {error}
        </div>
        {props.label?.position === 'right' ? labler : ''}
      </div>
    </div>
  );
}

export default Textarea;

import React from 'react';
import { Button, ButtonStyle, Modal } from '@sde/basic-ui-library';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import {DataUpdateError, Newsletter} from "../../models/newsletter";
import { deleteNewsletter } from "../../store/slices/newsletter.slice";

export interface DeleteNewsletterPopupProps {
  isOpen: boolean,
  newsletter?: Newsletter,
  onClose: () => void,
  onDelete: () => void,
}

export default function DeleteNewsletterPopup(props: Readonly<DeleteNewsletterPopupProps>) {
  const { t } = useTranslation();
  const successToast = (successText: string) => {
    toast.success(successText, {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      toastId: 'custom-id-success',
    });
  };
  const errorToast = (errorText: string) => {
    toast.error(errorText, {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      toastId: 'excel-import-error',
    });
  };
  const handleDelete = async () => {
    if (!props.newsletter?.objectId) return;

    const response = await deleteNewsletter(props.newsletter.objectId);
    if (response.success){
      successToast(t('communication.newsletter.definition.popup.success_delete'));
      props.onDelete();
      props.onClose();
    } else {
      switch (response.error) {
        case DataUpdateError.CONFLICT:
          errorToast(t('communication.newsletter.definition.popup.error_newsletter_not_modifiable'));
          break;
        case DataUpdateError.NOT_FOUND:
          errorToast(t('communication.newsletter.definition.popup.error_not_found'));
          break;
        default:
          errorToast(t('error')+ response.error);
          break;
      }
    }
  };

  return (
        <Modal
            title={t('communication.newsletter.definition.modal.title_delete')}
            size={'w-[640px]'}
            show={props.isOpen}
            handleClose={() => props.onClose()}
        >
            <div className="px-20 pb-3">
                <div className="mb-10">
                    <span className="block font-bold whitespace-nowrap">{props.newsletter?.name}</span>
                    <span className="block">{t('communication.newsletter.definition.modal.text_delete')}</span>
                </div>
                <div className="flex justify-end gap-2">
                    <Button
                        className="bg-white"
                        buttonStyle={ButtonStyle.OUTLINE}
                        onClick={() => props.onClose()}
                    >
                        {t('communication.newsletter.definition.modal.button_cancel')}
                    </Button>
                    <Button
                        px="5"
                        py="3"
                        onClick={() => handleDelete()}
                    >
                        {t('communication.newsletter.definition.modal.button_delete')}
                    </Button>
                </div>
            </div>
        </Modal>
  );
}

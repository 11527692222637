import {useTranslation} from "react-i18next";
import {TestDriveEditable} from "./test-drive-editable";
import {TextLine} from "../text-line/text-line";
import React from "react";
import {TestDriveInformationProps} from "./test-drive-information";
import {dateFormat, dateTimeFormat, formatDate} from "../../helpers/date-helper";

export const TestDriveInformationAuto = ({testDrive, modelNameEditable, plannedDateEditable, dealerCommentEditable}: TestDriveInformationProps) => {
  const {t} = useTranslation();

  return (
    <div className="grid gap-y-6 pt-8 p-5">
      <div className="grid grid-cols-3">
        <TextLine
          labelText={t('communication.test-drive.report.modals.prospect.phone')}
          value={testDrive.contactPhone}
        />
        <TextLine
          labelText={t('communication.test-drive.report.modals.prospect.e-mail')}
          value={testDrive.contactEmail}
        />
      </div>
      <div className="grid grid-cols-3">
        <TestDriveEditable
          editableComponent={modelNameEditable}
          textLineLabelText={t('communication.test-drive.report.modals.model')}
          textLineValue={testDrive.modelName}
        />
        <TextLine
          labelText={t('communication.test-drive.report.modals.wish-date')}
          value={formatDate(dateFormat, testDrive.wishDate)}
        />
        <TestDriveEditable
          editableComponent={plannedDateEditable}
          textLineLabelText={t('communication.test-drive.report.modals.selected-date')}
          textLineValue={formatDate(dateTimeFormat, testDrive.plannedDate).replace(',', '')}
        />
      </div>
      <div className="grid grid-cols-[1fr_2fr]">
        <TextLine
          labelText={t('communication.test-drive.report.modals.prospect-notice')}
          value={testDrive.notice}
        />
        <TestDriveEditable
          editableComponent={dealerCommentEditable}
          textLineLabelText={t('communication.test-drive.report.modals.comment')}
          textLineValue={testDrive.dealerComment}
        />
      </div>
    </div>
  );
}

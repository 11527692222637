import React, {ReactElement} from "react";
import {DatePickerProps, SelectInputProps, TextareaProps} from "@sde/basic-ui-library";
import {Tooltip} from "@suzuki-frontend-framework-update/ui";
import {TextLine} from "../text-line/text-line";

export interface TestDriveEditableProps {
  editableComponent?: ReactElement<SelectInputProps | DatePickerProps | TextareaProps>;
  textLineLabelText?: string;
  textLineValue?: string;
  tooltipContent?: string;
}

export const TestDriveEditable: React.FC<TestDriveEditableProps> = ({
  editableComponent,
  textLineLabelText,
  textLineValue,
  tooltipContent
}) => {
  const component = <div className="pe-5">{editableComponent}</div>;
  if (editableComponent) {
    return tooltipContent ? <Tooltip content={ tooltipContent }>{ component }</Tooltip> : component;
  }
  return <TextLine labelText={textLineLabelText || ""} value={textLineValue} />;
}

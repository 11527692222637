import React, { useState } from 'react';
import { Icon } from '@sde/basic-ui-library';

export type CollapsableTabProps = {
  title: string;
  children: React.ReactNode
};

export default function CollapsableTab(props: CollapsableTabProps) {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);

  return (
    <section className={'px-2 pt-6'}>
      <button onClick={() => setIsCollapsed(!isCollapsed)} className={'flex items-center'}>
        <Icon iconName={ isCollapsed ? 'arrow_right' : 'arrow_drop_down'} className="mr-1"></Icon>
        <div className={'text-2xl'}>{props.title}</div>
      </button>
      <div className={ `px-4 pt-4 ${isCollapsed ? 'hidden' : ''}`} style={{ maxWidth: '70vw', overflowX: 'auto' }}>
        {props.children}
      </div>
    </section>
  );
}

import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button, CheckboxStatus, Headline, Icon, Tab, Tabs, Tooltip } from '@suzuki-frontend-framework-update/ui';

import {BasicTable, FilterItem, TableFiltersProps} from '@sde/basic-ui-library'
import { useDispatch, useSelector } from 'react-redux';
import './contacts.module.scss';
import React, { useCallback, useEffect, useState } from 'react';

import { Company, CompanyContactPerson, Contact, ContactAddressFilter, ContactEmail, ContactPhone, ContactRequestBody, Vehicle, } from '../../models/contact';
import { useApiGetData } from '../../components/useApiGetData/useApiGetData';
import { contactFilterActions, contactFilterSelector, } from '../../store/slices/contact-filter.slice';
import { AppDispatch } from '../../store/setup';
import { DataFetchProps } from '../../models/others';
import { Session } from '@ory/client';
import { selectAllUserManagement } from '../../store/slices/user-management.slice';
import HideContactPopup from "../../components/hide-contact-popup/hide-contact-popup";
import { isAdmin } from "../../helpers/session-utils";
import { getHidingReasons, getTranslatedHidingReasonOfContact } from "../../components/hide-contact-popup/hiding-reasons";
import UnhideContactButton from "../../components/unhide-contact-button/unhide-contact-button";
import { environment } from "../../../environments/environment";
import { ColumnDef, createColumnHelper, Row } from "@tanstack/react-table";
import ReportFilters from '../../components/report-filter/report-filter';
import {dashboardFilterActions, dashboardFilterSelector} from "../../store/slices/dashboard.slice";
import dashboard from "../dashboard/dashboard";

export function ContactsList() {
  const pageSize = 25;
  const {t} = useTranslation();
  const {tabParam} = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const entities = useSelector(selectAllUserManagement);
  const [session, setSession] = useState({} as Session);
  const [hideContactModalState, setHideContactModalState] = useState(false)
  const [contactId, setContactId] = useState("")
  const [companyId, setCompanyId] = useState("")

  const contactFilterState = useSelector(contactFilterSelector);
  const dashboardFilterState = useSelector(dashboardFilterSelector);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [selectedRowIds, setSelectedRowIds] = useState<Record<string, boolean>>({});
  const [allSelectedStatus, setAllSelectedStatus] = useState<CheckboxStatus>({
    checked: false,
    indeterminate: false,
  });

  const [contactsIds, setContactsIds] = useState<string[]>([]);
  const [hiddenContactsAreVisible, setHiddenContactsAreVisible] = useState<boolean>(false);


  const dispatch: AppDispatch = useDispatch();
  if (
    contactFilterState.key === '' ||
    location.key !== contactFilterState.key
  ) {
    dispatch(contactFilterActions.init(location.key));
  }

  useEffect(() => {
    const lastEntity = entities[entities.length - 1];
    if (lastEntity && lastEntity.currentUser) {
      setSession(lastEntity.currentUser);
    }
  }, [entities]);

  const [peopleRequestBody, setPeopleRequestBody] =
    useState<ContactRequestBody>({
      pageSize: pageSize,
      pageIndex: contactFilterState.contactPeopleFilter.pageIndex,
      details: 'WITH_VEHICLES',
      filter: contactFilterState.contactPeopleFilter.filter,
    });

  const [companiesRequestBody, setCompaniesRequestBody] =
    useState<ContactRequestBody>({
      pageSize: pageSize,
      pageIndex: contactFilterState.contactCompaniesFilter.pageIndex,
      details: 'WITH_VEHICLES',
      filter: contactFilterState.contactCompaniesFilter.filter,
    });
  const peopleData = useApiGetData<Contact[]>(
    `${environment.restEndpoint}/contact/contact-service/contact/GetContacts`,
    'post',
    'contacts',
    peopleRequestBody,
    tabParam !== 'people'
  );

  const companiesData = useApiGetData<Company[]>(
    `${environment.restEndpoint}/contact/contact-service/company/GetCompanies`,
    'post',
    'companies',
    companiesRequestBody,
    tabParam !== 'companies'
  );

  const peopleCountData = useApiGetData<number>(
    `${environment.restEndpoint}/contact/contact-service/contact/GetContactsCount`,
    'post',
    'count',
    peopleRequestBody,
    tabParam !== 'people'
  );

  const companiesCountData = useApiGetData<number>(
    `${environment.restEndpoint}/contact/contact-service/company/GetCompaniesCount`,
    'post',
    'count',
    companiesRequestBody,
    tabParam !== 'companies'
  );

  useEffect(() => {
    let ids: string[] = [];
    if (tabParam === 'people' && peopleData?.data) {
      ids = peopleData.data.map<string>((person) => person.objectId as string);
    } else if (tabParam === 'companies' && companiesData?.data) {
      ids = companiesData.data.map<string>((company) => company.objectId as string);
    }
    setContactsIds(ids);
  }, [peopleData.loading, companiesData.loading])

  useEffect(() => {
    setHiddenContactsAreVisible(areHiddenContactsVisible())
  })


  const filterShowPersonContactWithoutAddress = (id: string, selected: string[]) => {
    let updatedAddressFilter: ContactAddressFilter;
    const withAddressSelected = selected.includes(ContactAddressFilter.WITH_ADDRESS);
  const withoutAddressSelected = selected.includes(ContactAddressFilter.WITHOUT_ADDRESS);
    updatedAddressFilter = ContactAddressFilter.NOT_SET;

    if (withAddressSelected && withoutAddressSelected) {
      updatedAddressFilter = ContactAddressFilter.All;
    }
    if (withAddressSelected && !withoutAddressSelected) {
      updatedAddressFilter = ContactAddressFilter.WITH_ADDRESS;
    }
     if (withoutAddressSelected && !withAddressSelected) {
      updatedAddressFilter = ContactAddressFilter.WITHOUT_ADDRESS;
    }
    dispatch(contactFilterActions.updateSelectedContactPeopleAddressFilter(updatedAddressFilter));
     dispatch(contactFilterActions.updatePeopleFilter({
      ...contactFilterState.contactCompaniesFilter.filter,
      contactAddressFilter: updatedAddressFilter,
    }));
  };

  const filterShowCompanyContactWithoutAddress = (
    id: string,
    selected: string[]
  ) => {
    let updatedAddressFilter: ContactAddressFilter;
    const withAddressSelected = selected.includes(ContactAddressFilter.WITH_ADDRESS);
    const withoutAddressSelected = selected.includes(ContactAddressFilter.WITHOUT_ADDRESS);
    updatedAddressFilter = ContactAddressFilter.NOT_SET;

    if (withAddressSelected && withoutAddressSelected) {
      updatedAddressFilter = ContactAddressFilter.All;
    }
    if (withAddressSelected && !withoutAddressSelected) {
      updatedAddressFilter = ContactAddressFilter.WITH_ADDRESS;
    }
    if (withoutAddressSelected && !withAddressSelected) {
      updatedAddressFilter = ContactAddressFilter.WITHOUT_ADDRESS;
    }

    dispatch(contactFilterActions.updateSelectedCompanyContacAddressFilter(updatedAddressFilter));
    dispatch(
      contactFilterActions.updateCompaniesFilter({
        ...contactFilterState.contactCompaniesFilter.filter,
        contactAddressFilter: updatedAddressFilter,
      })
    );
  };


  const handleShowHiddenPeopleFilterSelect = (id: string, selected: Array<string>) => {
    dispatch(
      contactFilterActions.updatePeopleFilter({
        ...contactFilterState.contactPeopleFilter.filter,
        showHiddenContacts: selected.includes('showHiddenContacts')
      })
    );
  };

  const handleShowHiddenCompaniesFilterSelect = (id: string, selected: Array<string>) => {
    dispatch(
      contactFilterActions.updateCompaniesFilter({
        ...contactFilterState.contactCompaniesFilter.filter,
        showHiddenContacts: selected.includes('showHiddenContacts')
      })
    );
  };

  const handlePeopleFilterSearch = (value: string) => {
    dispatch(
      contactFilterActions.updatePeopleFilter({
        ...contactFilterState.contactPeopleFilter.filter,
        search: value,
      })
    );
  };

  const handleCompaniesFilterSearch = (value: string) => {
    dispatch(
      contactFilterActions.updateCompaniesFilter({
        ...contactFilterState.contactCompaniesFilter.filter,
        search: value,
      })
    );
  };

  const areHiddenContactsVisible = (): boolean => {
    const hiddenPeopleAreVisible = contactFilterState.contactPeopleFilter.filter.showHiddenContacts as boolean;
    const hiddenCompaniesAreVisible = contactFilterState.contactCompaniesFilter.filter.showHiddenContacts as boolean;
    return hiddenPeopleAreVisible || hiddenCompaniesAreVisible;
  }

  const canHidingButtonsBeVisible = (session: Session): boolean => {
    return !session.identity?.traits?.isAreaAdmin && !hiddenContactsAreVisible;
  }

  const canCheckboxesAndUnhidingButtonBeVisible = (session: Session): boolean => {
    if (!session.identity?.traits?.isAdmin) {
      return false
    }
    if (hiddenContactsAreVisible) {
      return true
    } else {
      return false
    }
  }
  const initialContactPeopleAddressFilter = contactFilterState.selectedContactPeopleAddressFilter;

  const peopleTableFilters = {
    id: 'peopleFilters',
    key: 'peopleFilters',
    placeholder: t('contacts.people-companies.filter.search'),
    onSearchUpdate: handlePeopleFilterSearch,
    searchDelay: 500,
    initialSearchValue: contactFilterState.contactPeopleFilter.filter.search,
    filters: [
      {
        id: 'ContactAddressFilter',
        name: t('contacts.people-companies.filter.address.title'),
        items: [
          {
            id: ContactAddressFilter.WITH_ADDRESS,
            name: t('contacts.people-companies.filter.address.with-address'),
          },
          {
            id: ContactAddressFilter.WITHOUT_ADDRESS,
            name: t('contacts.people-companies.filter.address.without-address'),
          },
        ],
        onChange: filterShowPersonContactWithoutAddress,
        selected:
        initialContactPeopleAddressFilter === ContactAddressFilter.All
          ? [ContactAddressFilter.WITHOUT_ADDRESS, ContactAddressFilter.WITH_ADDRESS]
          : [initialContactPeopleAddressFilter],
    },
      {
        id: 'hiddenContacts',
        name: t('contacts.people-companies.filter.hidden-contacts.title'),
        items: [
          {
            id: 'showHiddenContacts',
            name: t(
              'contacts.people-companies.filter.hidden-contacts.show-contacts'
            ),
          },
        ],
        onChange: handleShowHiddenPeopleFilterSelect,
        selected: contactFilterState.contactPeopleFilter.filter
          .showHiddenContacts
          ? ['showHiddenContacts']
          : [],
      },
    ],
  } as TableFiltersProps;


  const initialCompanyContactAddressFilter = contactFilterState.selectedCompanyContactAddressFilter;

  const companiesTableFilters = {
    id: 'companiesFilters',
    key: 'companiesFilters',
    placeholder: t('contacts.people-companies.filter.search'),
    onSearchUpdate: handleCompaniesFilterSearch,
    searchDelay: 500,
    initialSearchValue: contactFilterState.contactCompaniesFilter.filter.search,
    filters: [
      {
        id: 'CompaniesAddressFilter',
        name: t('contacts.people-companies.filter.address.title'),
        items: [
          {
            id: ContactAddressFilter.WITH_ADDRESS,
            name: t('contacts.people-companies.filter.address.with-address'),
          },
          {
            id: ContactAddressFilter.WITHOUT_ADDRESS,
            name: t('contacts.people-companies.filter.address.without-address'),
          },
        ],
        onChange: filterShowCompanyContactWithoutAddress,
      selected:
      initialCompanyContactAddressFilter === ContactAddressFilter.All
          ? [ContactAddressFilter.WITHOUT_ADDRESS, ContactAddressFilter.WITH_ADDRESS]
          : [initialCompanyContactAddressFilter],
  },
      {
        id: 'hiddenContacts',
        name: t('contacts.people-companies.filter.hidden-contacts.title'),
        items: [
          {
            id: 'showHiddenContacts',
            name: t(
              'contacts.people-companies.filter.hidden-contacts.show-contacts'
            ),
          },
        ],
        onChange: handleShowHiddenCompaniesFilterSelect,
        selected: contactFilterState.contactCompaniesFilter.filter
          .showHiddenContacts
          ? ['showHiddenContacts']
          : [],
      },
    ],
  } as TableFiltersProps;


  const columnContactHelper = createColumnHelper<Contact>();
  const columnCompanyHelper = createColumnHelper<Company>();

  const contactColumns: ColumnDef<Contact>[] = [
    hiddenContactsAreVisible ? columnContactHelper.accessor((row) => row, {
      id: 'contact-visibility-icon',
      enableSorting: false,
      header: () => <span></span>,
      cell: (info) => {
        const translatedHidingReason = getTranslatedHidingReasonOfContact(info.getValue())
        return (
          <div className="flex flex-col">
            <Tooltip content={translatedHidingReason}>
              <Icon iconName={'visibility_off'} className="text-blue-gray"/>
            </Tooltip>
          </div>
        );
      },
    }) : null,
    columnContactHelper.accessor((row) => row, {
      id: 'name',
      enableSorting: true,
      header: () => <span>{t('contacts.people-companies.name')}</span>,
      cell: (info) => {
        const contact = info.getValue()
        return <div className="flex flex-col">
          <span>
                {contact.firstname} {contact.name}
          </span>
          <span className="text-blue-gray">
              {contact.customerstate === 'CUSTOMER'
                ? t('contact-details.customer')
                : contact.customerstate === 'PROSPECT_CUSTOMER'
                  ? t('contact-details.prospect')
                  : contact.customerstate === 'LOST_CUSTOMER'
                    ? t('contact-details.lost-customer')
                    : ''}
            </span>
        </div>
      },
    }),
    columnContactHelper.accessor((row) => row.address, {
      id: 'address',
      enableSorting: true,
      header: () => <span>{t('contacts.people-companies.address')}</span>,
      cell: (info) => {
        const address = info.getValue()
        return <div className="flex flex-col">
          {address?.street && (
            <span>{address.street}</span>
          )}
          {address?.city && (
            <span>
                {address.zip ? address.zip + ' ' : ''}
              {address.city}
              </span>
          )}
        </div>
      },
    }),
    columnContactHelper.accessor((row) => row.email, {
      id: 'email',
      enableSorting: false,
      header: () => <span>{t('contacts.people-companies.email')}</span>,
      cell: (info) => {
        const defaultEmail = info.getValue()?.find((email: ContactEmail) => email.default);
        return <div className="flex flex-col">
          {defaultEmail && <span>{defaultEmail.email}</span>}
        </div>
      },
    }),
    columnContactHelper.accessor((row) => row.phone, {
      id: 'phone',
      enableSorting: false,
      header: () => <span>{t('contacts.people-companies.phone')}</span>,
      cell: (info) => {
        const defaultPhone = info.getValue()?.find((phone: ContactPhone) => phone.default);
        return <div className="flex flex-col">
          {defaultPhone && <span>{defaultPhone.phoneNumber}</span>}
        </div>
      },
    }),
    columnContactHelper.accessor((row) => row.vehicles, {
      id: 'vehicles',
      enableSorting: false,
      header: () => <span>{t('contacts.people-companies.vehicle')}</span>,
      cell: (info) => {
        const vehicles = info.getValue()
        const names: string[] = [];
        const vins: string[] = [];
        if (vehicles) {
          vehicles.forEach((vehicle: Vehicle) => {
            if (vehicle.brand || vehicle.model)
              names.push((vehicle.brand + ' ' + vehicle.model).trim());
            if (vehicle.vin)
              vins.push(vehicle.vin);
          });
        }
        return (
          <div className="flex flex-col">
            <span>{names.join(', ')}</span>
            <span className="text-blue-gray">{vins.join(', ')}</span>
          </div>
        );
      },
    }),
    canHidingButtonsBeVisible(session) ? columnContactHelper.accessor((row) => row.objectId, {
      id: 'actions',
      enableSorting: false,
      header: () => <span></span>,
      cell: (info) => {
        const objectId = info.getValue()
        return <div className="flex justify-between">
          <Tooltip content={t('contacts.hide')}>
            <button onClick={(e) => {
              setHideContactModalState(true);
              setContactId(objectId ? objectId : '')
              setCompanyId("")
              e.stopPropagation();
            }
            }>
              <Icon iconName={'visibility_off'} className="text-black"/>
            </button>

          </Tooltip>
        </div>
      }
    }) : null,
  ].filter(Boolean) as ColumnDef<Contact>[];

  const companyColumns: ColumnDef<Company>[] = [
    hiddenContactsAreVisible ? columnCompanyHelper.accessor((row) => row, {
      id: 'contact-visibility-icon',
      enableSorting: false,
      header: () => <span></span>,
      cell: (info) => {
        const translatedHidingReason = getTranslatedHidingReasonOfContact(info.getValue())
        return (
          <div className="flex flex-col">
            <Tooltip content={translatedHidingReason}>
              <Icon iconName={'visibility_off'} className="text-blue-gray"/>
            </Tooltip>
          </div>
        );
      },
    }) : null,
    columnCompanyHelper.accessor((row) => row, {
      id: 'name',
      enableSorting: true,
      header: () => <span>{t('contacts.people-companies.name')}</span>,
      cell: (info) => {
        const contact = info.getValue()
        return <div className="flex flex-col">
          <span>{contact.name}</span>
            <span className="text-blue-gray">
                {contact.companyContactPerson && contact.companyContactPerson.length && (
                  <span>
                    {contact.companyContactPerson.map((x: CompanyContactPerson) => `${x.salutation} ${x.firstname} ${x.name}`).join(', ')}
                  </span>
                )}
              {contact.additionalCompanyname}
                </span>
          <span className="text-blue-gray">
              {contact.customerstate === 'CUSTOMER'
                ? t('contact-details.customer')
                : contact.customerstate === 'PROSPECT_CUSTOMER'
                  ? t('contact-details.prospect')
                  : contact.customerstate === 'LOST_CUSTOMER'
                    ? t('contact-details.lost-customer')
                    : ''}
            </span>
        </div>
      },
    }),
    columnCompanyHelper.accessor((row) => row.address, {
      id: 'address',
      enableSorting: true,
      header: () => <span>{t('contacts.people-companies.address')}</span>,
      cell: (info) => {
        const address = info.getValue()
        return <div className="flex flex-col">
          {address?.street && (
            <span>{address.street}</span>
          )}
          {address?.city && (
            <span>
                {address.zip ? address.zip + ' ' : ''}
              {address.city}
              </span>
          )}
        </div>
      },
    }),
    columnCompanyHelper.accessor((row) => row.email, {
      id: 'email',
      enableSorting: false,
      header: () => <span>{t('contacts.people-companies.email')}</span>,
      cell: (info) => {
        const defaultEmail = info.getValue()?.find((email: ContactEmail) => email.default);
        return <div className="flex flex-col">
          {defaultEmail && <span>{defaultEmail.email}</span>}
        </div>
      },
    }),
    columnCompanyHelper.accessor((row) => row.phone, {
      id: 'phone',
      enableSorting: false,
      header: () => <span>{t('contacts.people-companies.phone')}</span>,
      cell: (info) => {
        const defaultPhone = info.getValue()?.find((phone: ContactPhone) => phone.default);
        return <div className="flex flex-col">
          {defaultPhone && <span>{defaultPhone.phoneNumber}</span>}
        </div>
      },
    }),
    columnCompanyHelper.accessor((row) => row.vehicles, {
      id: 'vehicles',
      enableSorting: false,
      header: () => <span>{t('contacts.people-companies.vehicle')}</span>,
      cell: (info) => {
        const vehicles = info.getValue()
        const names: string[] = [];
        const vins: string[] = [];
        if (vehicles) {
          vehicles.forEach((vehicle: Vehicle) => {
            if (vehicle.brand || vehicle.model)
              names.push((vehicle.brand + ' ' + vehicle.model).trim());
            if (vehicle.vin)
              vins.push(vehicle.vin);
          });
        }
        return (
          <div className="flex flex-col">
            <span>{names.join(', ')}</span>
            <span className="text-blue-gray">{vins.join(', ')}</span>
          </div>
        );
      },
    }),
    // todo: show contact hiding buttons only for automobile dealers and automobile admins once this admin type is implemented
    canHidingButtonsBeVisible(session) ? columnCompanyHelper.accessor((row) => row.objectId, {
      id: 'actions',
      enableSorting: false,
      header: () => <span></span>,
      cell: (info) => {
        const objectId = info.getValue()
        return <div className="flex justify-between">
          <Tooltip content={t('contacts.hide')}>
            <button onClick={(e) => {
              setHideContactModalState(true);
              setContactId("")
              setCompanyId(objectId ? objectId : '')
              e.stopPropagation();
            }
            }>
              <Icon iconName={'visibility_off'} className="text-black"/>
            </button>
          </Tooltip>
        </div>
      }
    }) : null,
  ].filter(Boolean) as ColumnDef<Company>[];

  const handleContactRowClick = (row: Row<Contact>) => {
    if (hiddenContactsAreVisible) {
      handleRowSelect(row);
      return;
    }
    dispatch(contactFilterActions.updatePeopleCurrentRow(row.id));
    navigate(`/contacts/view/${row.original.objectId}`);
  };

  const handleCompanyRowClick = (row: Row<Company>) => {
    if (hiddenContactsAreVisible) {
      handleRowSelect(row);
      return;
    }
    dispatch(contactFilterActions.updateCompaniesCurrentRow(row.id));
    navigate(`/companies/view/${row.original.objectId}`);
  };

  const sortFieldMapper = (uistring: string): string => {
    switch (uistring) {
      case 'address':
        return 'address.street';
      default:
        return tabParam === 'people' ? 'firstname' : 'name';
    }
  };

  const handlePeoplePreviousRow = useCallback(
    async ({itemsRef}: any) => {
      if (peopleData.data && contactFilterState.peopleCurrentRow) {
        itemsRef.current
          .get(contactFilterState.peopleCurrentRow)
          ?.scrollIntoView({behavior: 'smooth', block: 'center'});
        dispatch(contactFilterActions.updatePeopleCurrentRow(undefined));
      }
    },
    [peopleData.data, peopleData.loading]
  );

  const handleCompanyPreviousRow = useCallback(
    async ({itemsRef}: any) => {
      if (companiesData.data && contactFilterState.companiesCurrentRow) {
        itemsRef.current
          .get(contactFilterState.companiesCurrentRow)
          ?.scrollIntoView({behavior: 'smooth', block: 'center'});
        dispatch(contactFilterActions.updateCompaniesCurrentRow(undefined));
      }
    },
    [companiesData.data, companiesData.loading]
  );

  const fetchPeopleData = useCallback(
    async ({pageSize, pageIndex, pageSortBy}: DataFetchProps) => {
      dispatch(contactFilterActions.updatePeoplePageIndex(pageIndex));
      //console.log(pageSize, pageIndex, pageSortBy);
      let sortByDir = 1;
      let sortByField = '';
      if (pageSortBy && pageSortBy.length > 0) {
        const sortParams = pageSortBy[0];
        sortByDir = sortParams.desc ? -1 : 1;
        console.log(sortParams.id)
        sortByField = sortFieldMapper(sortParams.id);
      }
      setPeopleRequestBody({
        details: 'WITH_VEHICLES',
        pageSize: pageSize,
        pageIndex: pageIndex,
        filter: {
          ...contactFilterState.contactPeopleFilter.filter,
          dealers: dashboardFilterState.dealerFilter.dealers
        },
        sortField: sortByField,
        sortDir: sortByDir,
      });
    },
    [contactFilterState.contactPeopleFilter.filter]
  );

  const fetchCompanyData = useCallback(
    async ({pageSize, pageIndex, pageSortBy}: DataFetchProps) => {
      dispatch(contactFilterActions.updateCompaniesPageIndex(pageIndex));
      let sortByDir = 1;
      let sortByField = '';
      if (pageSortBy && pageSortBy.length > 0) {
        const sortParams = pageSortBy[0];
        sortByDir = sortParams.desc ? -1 : 1;
        sortByField = sortFieldMapper(sortParams.id);
      }
      setCompaniesRequestBody({
        details: 'WITH_VEHICLES',
        pageSize: pageSize,
        pageIndex: pageIndex,
        filter: {
          ...contactFilterState.contactCompaniesFilter.filter,
          dealers: dashboardFilterState.dealerFilter.dealers
        },
        sortField: sortByField,
        sortDir: sortByDir,
      });
    },
    [contactFilterState.contactCompaniesFilter.filter]
  );

  const getTabIndex = () => {
    switch (tabParam) {
      case 'people':
        return 0;
      case 'companies':
        return 1;
      default:
        return 0;
    }
  };

  const handleTabChange = (tabIndex: number) => {
    switch (tabIndex) {
      case 0:
        navigate(`/contacts/list/people`);
        break;
      case 1:
        navigate(`/contacts/list/companies`);
        break;
    }
  };

  const handleRowSelect = (row: Row<any>) => {
    if (disableSelection(row)) {
      return;
    }
    let result = selectedIds;
    const id = row.original.objectId;
    if (id) {
      if (result.includes(id)) {
        result = result.filter((v) => v !== id);
      } else {
        result.push(id);
      }
      setSelectedRowIds({...selectedRowIds, [row.id]: result.includes(id)});
      setSelectedIds(result);
      const checked = contactsIds.every((id) => result.includes(id));
      const indeterminate =
        !checked && contactsIds.some((id) => result.includes(id));
      setAllSelectedStatus({
        checked: checked,
        indeterminate: indeterminate,
      });
    }
  }

  const resetSelections = () => {
    setSelectedIds([]);
    setSelectedRowIds({});
    setAllSelectedStatus({checked: false, indeterminate: false});
  };

  const updateSelectedRowIdsAndSelectedIds = (selected: string[]) => {
    let data: Contact[] | Company[] = [];
    if (tabParam === 'people') {
      data = peopleData.data as Contact[];
    } else if (tabParam === 'companies') {
      data = companiesData.data as Company[];
    }
    if (data) {
      let resultSelectedRowIds: Record<string, boolean> = {};
      let resultSelectedIds: string[] = [];
      data.forEach((contact, index) => {
        if (contact.objectId && contact.accessibillity?.disablingReasonType !== getHidingReasons().gdpr.value) {
          resultSelectedRowIds = {
            ...resultSelectedRowIds,
            [index.toString()]: selected.includes(contact.objectId),
          };
          resultSelectedIds = resultSelectedIds.concat(contact.objectId);
        }
      });
      setSelectedRowIds(resultSelectedRowIds);
      setSelectedIds(resultSelectedIds);
    }
  };

  const handleAllRowsSelect = () => {
    if (allSelectedStatus.checked) {
      setAllSelectedStatus({checked: false, indeterminate: false})
      resetSelections();
    } else {
      updateSelectedRowIdsAndSelectedIds(contactsIds);
      setAllSelectedStatus({checked: true, indeterminate: false});
    }
  }

  const disableSelection = (row: Row<Contact> | Row<Company>): boolean => {
    const accessibilityAndReasonExist = row.original && row.original.accessibillity &&
      row.original.accessibillity.disablingReasonType;
    const reasonIsGDPR = row.original.accessibillity?.disablingReasonType === getHidingReasons().gdpr.value;
    if (accessibilityAndReasonExist && reasonIsGDPR) {
      return true;
    }
    return false;
  };

  const selectionHandler = {
    handleRowSelect: handleRowSelect,
    handleAllRowsSelect: handleAllRowsSelect,
    customSelectedRowIds: selectedRowIds,
    allSelectedStatus: allSelectedStatus,
    disableSelection: disableSelection,
  };

  useEffect(() => {
    dispatch(
      contactFilterActions.updatePeopleFilter({
        ...contactFilterState.contactPeopleFilter.filter,
        dealers: dashboardFilterState.dealerFilter.dealers,
      }),
    );
  }, [dashboardFilterState.dealerFilter.dealers]);

  useEffect(() => {
    dispatch(
      contactFilterActions.updateCompaniesFilter({
        ...contactFilterState.contactCompaniesFilter.filter,
        dealers: dashboardFilterState.dealerFilter.dealers,
      }),
    );
  }, [dashboardFilterState.dealerFilter.dealers]);

  return (
    <div className="w-full h-full grow">
      <div className="flex flex-col">
        <Headline
          className="p-8"
          title={t('contacts.people-companies.title')}
        />
        <div className="flex flex-row justify-between items-center pr-8">
          <div className="ml-8 mb-0 -mt-16">
            <ReportFilters/>
          </div>
          <div className="flex flex-row gap-x-4 justify-end">
            {!isAdmin(session) ? (
              <Tooltip content={t('contacts.create')}>
                <Button
                  onClick={() => {
                    if (getTabIndex() === 0) {
                      navigate('/contacts/create');
                    } else if (getTabIndex() === 1) {
                      navigate('/companies/create');
                    }
                  }}
                  className="w-fit h-fit"
                  py="1"
                  px="1"
                >
                  <Icon iconName="add" className="text-2xl w-7"/>
                </Button>
              </Tooltip>
            ) : null}
            {(session.identity?.traits?.isAdmin || session.identity?.traits?.isAreaAdmin) && (
              <Tooltip content={t('contacts.dpd-report.title')}>
                <Button
                  onClick={() => {
                    if (getTabIndex() === 0) {
                      navigate('/contacts/dpe-report/contact');
                    } else if (getTabIndex() === 1) {
                      navigate('/contacts/dpe-report/company');
                    }
                  }}
                  className="w-fit h-fit"
                  py="1"
                  px="1"
                >
                  <Icon iconName="strikethrough_s" className="text-2xl w-7"/>
                </Button>
              </Tooltip>
            )}
          </div>
        </div>
      </div>
      <Tabs defaultActiveTabIndex={getTabIndex()} onTabChange={handleTabChange}>
        <Tab title={t('contacts.people-companies.people')} tabIndex={0}>
          <BasicTable
            key="people-table"
            filter={peopleTableFilters}
            columns={contactColumns}
            data={peopleData.data ? peopleData.data : []}
            loading={peopleData.loading}
            initPageSize={pageSize}
            rowCount={peopleCountData.data ? peopleCountData.data : 0}
            countLabel={
              peopleCountData.data && peopleCountData.data === 1
                ? t('common.list.count.entry')
                : t('common.list.count.entries')
            }
            fetchData={fetchPeopleData}
            initialPage={contactFilterState.contactPeopleFilter.pageIndex}
            handleRowClick={handleContactRowClick}
            resetOnChange={contactFilterState.contactPeopleFilter.filter}
            handleScrollToRow={handlePeoplePreviousRow}
            customSelectionHandler={selectionHandler}
            basic={!canCheckboxesAndUnhidingButtonBeVisible(session)}
            customElement={
              <div>
                {canCheckboxesAndUnhidingButtonBeVisible(session) ? (
                  <UnhideContactButton
                    selectedCount={selectedIds.length}
                    selectedPeopleIds={tabParam === 'people' ? selectedIds : []}
                    selectedCompaniesIds={tabParam === 'companies' ? selectedIds : []}
                    handleResetSelectionsAfterUnhide={resetSelections}
                    refreshData={tabParam === 'people' ? peopleData.refreshData : companiesData.refreshData}
                  />
                ) : null}
              </div>
            }
          />
        </Tab>
        <Tab title={t('contacts.people-companies.companies')} tabIndex={1}>
            <BasicTable
            key="company-table"
            filter={companiesTableFilters}
            columns={companyColumns}
            data={companiesData.data ? companiesData.data : []}
            loading={companiesData.loading}
            initPageSize={pageSize}
            rowCount={companiesCountData.data ? companiesCountData.data : 0}
            countLabel={
              companiesCountData.data && companiesCountData.data === 1
                ? t('common.list.count.entry')
                : t('common.list.count.entries')
            }
            fetchData={fetchCompanyData}
            initialPage={contactFilterState.contactCompaniesFilter.pageIndex}
            handleRowClick={handleCompanyRowClick}
            resetOnChange={contactFilterState.contactCompaniesFilter.filter}
            handleScrollToRow={handleCompanyPreviousRow}
            customSelectionHandler={selectionHandler}
            basic={!canCheckboxesAndUnhidingButtonBeVisible(session)}
            customElement={
              <div>
                {canCheckboxesAndUnhidingButtonBeVisible(session) ? (
                  <UnhideContactButton
                    selectedCount={selectedIds.length}
                    selectedPeopleIds={tabParam === 'people' ? selectedIds : []}
                    selectedCompaniesIds={tabParam === 'companies' ? selectedIds : []}
                    handleResetSelectionsAfterUnhide={resetSelections}
                    refreshData={tabParam === 'people' ? peopleData.refreshData : companiesData.refreshData}
                  />
                ) : null}
              </div>
            }
          />
        </Tab>
      </Tabs>
      <HideContactPopup
        setModalState={setHideContactModalState}
        modalState={hideContactModalState}
        contactId={contactId}
        companyId={companyId}
        refreshData={tabParam === 'people' ? peopleData.refreshData : companiesData.refreshData}
      />
    </div>
  );
}

export default ContactsList;

import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { Sidebar } from '@suzuki-frontend-framework-update/ui';
import './contacts.module.scss';

export function Contacts() {
  const { t } = useTranslation();
  return (
    <Sidebar groups={[
      {
        title: t('contacts.contacts'),
        items: [
          {
            name: t('contacts.people-companies-item'),
            path: 'list',
            subPaths: ['list/people', 'list/companies']
          },
          {
            name: t('contacts.import-item'),
            path: 'import',
          },
        ],
      }
    ]}>
    <Outlet />
     </Sidebar>
  );
}

export default Contacts;

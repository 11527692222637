import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../setup'
import { RemindersFilters } from '../../models/warranty-reminder';

export const EXTENDED_WARRANTY_FILTER_FEATURE_KEY = 'extenede-warranty-filter';

export interface ExtendedWarrantyFilterState {
  key: string;
  currentRow: string | undefined;
  extendedWarrantyFilter: ExtendedWarrantyData;
}

export interface ExtendedWarrantyData {
  filter: RemindersFilters;
  pageIndex: number;
}

export const initialExtendedWarrantyFilterState: ExtendedWarrantyFilterState = {
  key: "",
  currentRow: undefined,
  extendedWarrantyFilter: {
    filter: {},
    pageIndex: 0
  }
};

const extendedWarrantyFilterSlice = createSlice({
  name: EXTENDED_WARRANTY_FILTER_FEATURE_KEY,
  initialState: initialExtendedWarrantyFilterState,
  reducers: {
    init: (state, { payload: key }: PayloadAction<string>) => {
      state.key = key;
      state.currentRow = undefined;
      state.extendedWarrantyFilter = {
        filter: {},
        pageIndex: 0
      };
    },
    updateExtendedWarrantyFilter: (state, { payload: extendedWarrantyFilter }: PayloadAction<RemindersFilters>) => {
      state.extendedWarrantyFilter.filter = extendedWarrantyFilter;
    },
    updateExtendedWarrantyPageIndex: (state, { payload: pageIndex }: PayloadAction<number>) => {
      state.extendedWarrantyFilter.pageIndex = pageIndex;
    },
    updateExtendedWarrantyCurrentRow: (state, { payload: currentRow }: PayloadAction<string | undefined>) => {
      state.currentRow = currentRow;
    },
  }
});

export const extendedWarrantyFilterActions = extendedWarrantyFilterSlice.actions;

export const extendedWarrantyFilterSelector = (state: RootState) => state[EXTENDED_WARRANTY_FILTER_FEATURE_KEY]

export const extendedWarrantyFilterReducer = extendedWarrantyFilterSlice.reducer;

import { Headline, Icon, Table } from '@suzuki-frontend-framework-update/ui';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './contact-pools.module.scss';
import { getPools, Pools } from './mock-api';
import { RemoveActionPopup } from '../../components/remove-action-popup/remove-action-popup';

export function ContactPools() {
  const { t } = useTranslation();
  const [loadingData, setLoadingData] = useState(false);
  const [poolsData, setPoolsData] = useState<Pools[]>([]);
  const [removeActionState, setRemoveActionState] = useState<boolean>(false);
  const [selectedPool, setSelectedPool] = useState<any>();

  useEffect(() => {
    setLoadingData(true);
    getPools()
      .then((value) => setPoolsData(value))
      .finally(() => setLoadingData(false));
  }, []);

  const handleRefresh = (id: string) => {
    // TODO: implement action
    console.log('Refresh ' + id);
  };

  const handleDelete = (id: string) => {
    // TODO: implement action
    console.log('Delete ' + id);
    clearRemoveActionState();
  };

  const clearRemoveActionState = () => {
    setRemoveActionState(false);
    setSelectedPool(null);
  };

  const columns = [
    {
      Header: t('contact-pools.pool-id'),
      accessor: 'id',
    },
    {
      Header: t('contact-pools.dealer-name'),
      accessor: 'dealerName',
    },
    {
      Header: t('contact-pools.dealer-number'),
      accessor: 'dealerNumber',
    },
    {
      Header: t('contact-pools.number-of-contacts'),
      accessor: 'numberOfContacts',
    },
    {
      Header: t('contact-pools.actions'),
      accessor: 'actions',
      Cell: ({ row }: any) => {
        return (
          <div className="flex flex-row gap-x-6">
            <button onClick={() => handleRefresh(row.original.id)}>
              <Icon iconName="change_circle" className="text-black" />
            </button>
            <button
              onClick={() => {
                setSelectedPool(row.original);
                setRemoveActionState(true);
              }}
            >
              <Icon iconName={'delete'} className="text-black" />
            </button>
          </div>
        );
      },
    },
  ];

  return (
    <div className="p-8">
      {selectedPool && (
        <RemoveActionPopup
          onHandleCancel={() => clearRemoveActionState()}
          onHandleRemove={() => handleDelete(selectedPool.id)}
          modalState={removeActionState}
          type="contact-pool"
          valueNameToDelete={selectedPool.id}
        />
      )}
      <Headline title={t('contact-pools.title')} className="mb-8 mt-2" />
      <Table
        pagination
        columns={columns}
        data={poolsData ? poolsData : []}
        loading={loadingData}
      />
    </div>
  );
}

export default ContactPools;

import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../setup'
import {CcpExportFilter, ExportState} from "../../models/ccp-export";

export const SALES_CCP_EXPORT_FILTER_FEATURE_KEY = 'sales-ccp-export-filter';

export interface SalesCcpExportFilterState {
  key: string;
  currentRow: string | undefined;
  salesCcpExportFilter: SalesCcpExportData;
}

export interface SalesCcpExportData {
  filter: CcpExportFilter;
  pageIndex: number;
}

export const initialSalesCcpExportFilterState: SalesCcpExportFilterState = {
  key: "",
  currentRow: undefined,
  salesCcpExportFilter: {
    filter: {
      sendoutTypes: ["1", "1e"],
      exported: ExportState.ALL
    },
    pageIndex: 0
  }
};

const salesCcpExportFilterSlice = createSlice({
  name: SALES_CCP_EXPORT_FILTER_FEATURE_KEY,
  initialState: initialSalesCcpExportFilterState,
  reducers: {
    init: (state, { payload: key }: PayloadAction<string>) => {
      state.key = key;
      state.currentRow = undefined;
      state.salesCcpExportFilter = {
        filter: {
          sendoutTypes: ["1", "1e"],
          exported: ExportState.ALL
        },
        pageIndex: 0
      };
    },
    updateSalesCcpExportFilter: (state, { payload: salesCcpExportFilter }: PayloadAction<CcpExportFilter>) => {
      state.salesCcpExportFilter.filter = salesCcpExportFilter;
    },
    updateSalesCcpExportPageIndex: (state, { payload: pageIndex }: PayloadAction<number>) => {
      state.salesCcpExportFilter.pageIndex = pageIndex;
    },
    updateSalesCcpExportCurrentRow: (state, { payload: currentRow }: PayloadAction<string | undefined>) => {
      state.currentRow = currentRow;
    },
  }
});

export const salesCcpExportFilterActions = salesCcpExportFilterSlice.actions;

export const salesCcpExportFilterSelector = (state: RootState) => state[SALES_CCP_EXPORT_FILTER_FEATURE_KEY]

export const salesCcpExportFilterReducer = salesCcpExportFilterSlice.reducer;

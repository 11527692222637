import {QuestionnairesType} from "../models/questionnaires-types";

export const compareQuestionnaireTypes = (firstType: QuestionnairesType, secondType: QuestionnairesType) => {
  const prefixOrder: { [key: string]: number } = { 'auto': 1, 'moto': 2 };
  const suffixOrder: { [key: string]: number } = { 'sales': 1, 'product': 2, 'service': 3 };
  const [prefixA, suffixA] = firstType.name.split('-');
  const [prefixB, suffixB] = secondType.name.split('-');

  return prefixA !== prefixB ? prefixOrder[prefixA] - prefixOrder[prefixB] : suffixOrder[suffixA] - suffixOrder[suffixB];
}

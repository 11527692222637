import * as React from "react";

function SvgBxsHourglassTop(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
      <path d="M15.566,11.021C17.685,9.771,19,7.464,19,5V4h1V2h-1H5H4v2h1v1c0,2.464,1.315,4.771,3.434,6.021 C8.788,11.229,9,11.566,9,11.921v0.158c0,0.354-0.212,0.69-0.566,0.9C6.315,14.229,5,16.536,5,19v1H4v2h1h14h1v-2h-1v-1 c0-2.464-1.315-4.771-3.433-6.02C15.212,12.77,15,12.433,15,12.079v-0.158C15,11.566,15.212,11.229,15.566,11.021z M17,19v1H7v-1 c0-1.76,0.938-3.406,2.45-4.299c0.681-0.401,1.153-1.01,1.384-1.701h2.332c0.23,0.691,0.704,1.3,1.385,1.702 C16.062,15.594,17,17.24,17,19z" />
    </svg>
  );
}

export default SvgBxsHourglassTop;

import { useEffect, useState } from 'react';
import { BasicInput, BasicOutput, Button, ButtonStyle, DatePickerInput, MultipleValueInput, MultipleValueInputRow, Multiselect, MultiselectOptionsProps, SideNavigation, } from '@suzuki-frontend-framework-update/ui';
import './contact-form.module.scss';
import { environment } from '../../../environments/environment';
import { useTranslation } from 'react-i18next';
import { sendApiRequest } from '../../components/send-api-request/send-api-request';
import { JobEntry } from '../../models/job-entry';
import { useSelector } from 'react-redux';
import { selectAllUserManagement } from '../../store/slices/user-management.slice';
import { Session } from '@ory/client';
import { Country } from '../../models/country';
import { SelectInput, SelectOptionsProps } from "@sde/basic-ui-library";

export interface ContactValue {
  gender?: string;
  title?: string;
  firstname?: string;
  surname?: string;
  birthday?: Date;
  street?: string;
  address?: string;
  postcode?: string;
  city?: string;
  countrycode?: string;
  email: Array<MultipleValueInputRow>;
  phone: Array<MultipleValueInputRow>;
  fax: Array<MultipleValueInputRow>;
  personsInHousehold?: string;
  job?: string;
  maritalStatus?: string;
  sports?: string;
  activities?: string;
  vin?: string;
  owner?: MultiselectOptionsProps[];
  companyId?: string;
}

export type ContactFormProps = {
  handleCancel: () => void;
  handleSubmit: (formValues: ContactValue) => void;
  handleChange?: (prop: string, value: string) => void;
  handleDayChange?: (date: Date) => void;
  handleMultipleInputChange?: (
    prop: string
  ) => (value: Array<MultipleValueInputRow>) => void;
  formValues: ContactValue;
  dealerOptions?: MultiselectOptionsProps[];
  companiesOptions?: MultiselectOptionsProps[];
};

export const ContactForm = (props: ContactFormProps) => {
  const { t } = useTranslation();

  const entities = useSelector(selectAllUserManagement);
  const [session, setSession] = useState({} as Session);

  useEffect(() => {
    const lastEntity = entities[entities.length - 1];
    if (lastEntity && lastEntity.currentUser) {
      setSession(lastEntity.currentUser);
    }
  }, [entities]);

  const [jobOptions, setJobOptions] = useState<SelectOptionsProps[]>([]);
  const [famOptions, setFamOptions] = useState<SelectOptionsProps[]>([]);
  const [countryOptions, setCountryOptions] = useState<SelectOptionsProps[]>(
    []
  );
  const [formValues, setFormValues] = useState<ContactValue>(props.formValues);

  useEffect(() => {
    setFormValues(props.formValues);
  }, [props.formValues]);

  useEffect(() => {
    sendApiRequest(
      `${environment.restEndpoint}/contact/contact-service/jobs/list`,
      'get'
    )
      .then((response) => response.json())
      .then((data) => {
        const jobOpts: SelectOptionsProps[] = [];
        jobOpts.push({
          key: '00000000000000000',
          value: '',
          name: `${t('contact-form.dropdownEmpty')}`,
        });
        if (data && data.job) {
          const uniqueNames: string[] = [];
          (data.job as JobEntry[]).forEach((i: JobEntry) => {
            if (!uniqueNames.includes(i.text)) {
              jobOpts.push({
                key: i.id ? i.id : '',
                value: i.id,
                name: `${i.text}`,
              });
              uniqueNames.push(i.text);
            }
          });
        }

        setJobOptions(jobOpts);
      });
    sendApiRequest(
      `${environment.restEndpoint}/contact/contact-service/familystatus/list`,
      'get'
    )
      .then((response) => response.json())
      .then((data) => {
        const famOpts: SelectOptionsProps[] = [];
        famOpts.push({
          key: '00000000000000000',
          value: '',
          name: `${t('contact-form.dropdownEmpty')}`,
        });
        if (data && data.familyState) {
          const uniqueNames: string[] = [];
          (data.familyState as JobEntry[]).forEach((i: JobEntry) => {
            if (!uniqueNames.includes(i.text)) {
              famOpts.push({
                key: i.id ? i.id : '',
                value: i.id,
                name: `${i.text}`,
              });
              uniqueNames.push(i.text);
            }
          });
        }
        setFamOptions(famOpts);
      });
    sendApiRequest(
      `${environment.restEndpoint}/contact/contact-service/country/list`,
      'get'
    )
      .then((response) => response.json())
      .then((data) => {
        const countryOpts: SelectOptionsProps[] = [];
        if (data && data.countries) {
          (data.countries as Country[]).forEach((i: Country) => {
            if (i.code && i.active && (i.textDe || i.textEn))
              countryOpts.push({
                key: i.code,
                value: i.code,
                name: i.textDe ? i.textDe : i.textEn ? i.textEn : '',
              });
          });
        }
        setCountryOptions([
          {
            key: '',
            value: '',
            name: `${t('contact-form.dropdownEmpty')}`,
          },
          ...countryOpts.sort((a, b) => a.name.localeCompare(b.name)),
        ]);
      });
  }, []);

  const getCompanyFromOptions = (
    companyId: string | undefined
  ): MultiselectOptionsProps[] => {
    if (companyId && props.companiesOptions) {
      const company = props.companiesOptions.find(
        (c) => c.key === formValues.companyId
      );
      return company ? [company] : [];
    }
    return [];
  };

  const handleChange = (prop: string, value: unknown) => {
    console.log(prop, value);
    setFormValues({ ...formValues, [prop]: value });
  };

  const handleDayChange = (date: Date) => {
    setFormValues({ ...formValues, birthday: date });
  };

  const handleMultipleInputChange =
    (prop: string) => (value: Array<MultipleValueInputRow>) => {
      setFormValues({ ...formValues, [prop]: value });
    };

  const handleDealerChange = (selected: MultiselectOptionsProps[]) => {
    setFormValues({ ...formValues, owner: selected });
  };

  const handleCompanyChange = (selected: MultiselectOptionsProps[]) => {
    setFormValues({
      ...formValues,
      companyId: selected.length <= 0 ? '' : selected[0].value.toString(),
    });
  };

  const isEmailFilled = () => {
    return formValues.email.some(email => email.inputValue?.trim());
  };

  const isAddressFilled = () => {
    return (
      formValues.street?.trim() &&
      formValues.postcode?.trim() &&
      formValues.city?.trim()
    );
  };

  const genderOptions = [
    { key: 0, name: t('gender.Herr'), value: 'Herr' },
    { key: 1, name: t('gender.Frau'), value: 'Frau' },
    { key: 2, name: t('gender.Divers'), value: 'Divers' },
  ];
  const personsInHouseholdOptions = [
    { key: 0, name: t('contact-form.dropdownEmpty'), value: '' },
    { key: 1, name: '1', value: '1' },
    { key: 2, name: '2', value: '2' },
    { key: 3, name: '3', value: '3' },
    { key: 4, name: '4', value: '4' },
    { key: 5, name: '5', value: '5' },
    { key: 6, name: '6', value: '6' },
    { key: 7, name: '7', value: '7' },
    { key: 8, name: '8', value: '8' },
    { key: 9, name: '9', value: '9' },
    { key: 10, name: '10', value: '10' },
  ];

  const sports = [
    { key: 0, name: t('sports-list.NOCOMMENT'), value: 'NOCOMMENT' },
    { key: 1, name: t('sports-list.SOCCER'), value: 'SOCCER' },
    {
      key: 2,
      name: t('sports-list.SAILING-WINDSURFING'),
      value: 'SAILING-WINDSURFING',
    },
    {
      key: 4,
      name: t('sports-list.SKIING-SNOWBOARDING'),
      value: 'SKIING-SNOWBOARDING',
    },
    { key: 5, name: t('sports-list.TENNIS'), value: 'TENNIS' },
    { key: 6, name: t('sports-list.SWIMMING'), value: 'SWIMMING' },
    { key: 7, name: t('sports-list.SQUASH'), value: 'SQUASH' },
    { key: 8, name: t('sports-list.GYM'), value: 'GYM' },
    { key: 9, name: t('sports-list.INLINE-SKATING'), value: 'INLINE-SKATING' },
    {
      key: 10,
      name: t('sports-list.JOGGING-WALKING'),
      value: 'JOGGING-WALKING',
    },
    {
      key: 11,
      name: t('sports-list.CYCLING-MOUNTAINBIKING'),
      value: 'CYCLING-MOUNTAINBIKING',
    },
    { key: 12, name: t('sports-list.GOLF'), value: 'GOLF' },
    { key: 13, name: t('sports-list.MOTORSPORTS'), value: 'MOTORSPORTS' },
    { key: 114, name: t('sports-list.OTHER'), value: 'OTHER' },
  ];

  const activities = [
    { key: 0, name: t('activities-list.NOCOMMENT'), value: 'NOCOMMENT' },
    { key: 1, name: t('activities-list.DIY'), value: 'DIY' },
    {
      key: 2,
      name: t('activities-list.VISITING-CULTURAL-EVENTS'),
      value: 'VISITING-CULTURAL-EVENTS',
    },
    { key: 4, name: t('activities-list.GARDENING'), value: 'GARDENING' },
    {
      key: 5,
      name: t('activities-list.VISITING-THE-CINEMA'),
      value: 'VISITING-THE-CINEMA',
    },
    { key: 6, name: t('activities-list.TRAVEL'), value: 'TRAVEL' },
    { key: 7, name: t('activities-list.TELEVISION'), value: 'TELEVISION' },
    { key: 8, name: t('activities-list.READING'), value: 'READING' },
    {
      key: 9,
      name: t('activities-list.ARTISTIC-WORK'),
      value: 'ARTISTIC-WORK',
    },
    { key: 10, name: t('activities-list.MUSIC'), value: 'MUSIC' },
    { key: 11, name: t('activities-list.OTHER'), value: 'OTHER' },
  ];

  const inputWidth = '20rem';
  return (
    <SideNavigation
      values={
        session?.identity?.traits?.isAdmin
          ? [
              { id: 'personal-data', title: t('contact-form.personal-data') },
              { id: 'kontakt-data', title: t('contact-form.kontakt-data') },
              { id: 'household-data', title: t('contact-form.household-data') },
              { id: 'registration', title: t('contact-form.registration') },
            ]
          : [
              { id: 'personal-data', title: t('contact-form.personal-data') },
              { id: 'kontakt-data', title: t('contact-form.kontakt-data') },
              { id: 'household-data', title: t('contact-form.household-data') },
            ]
      }
      topOffset="13rem"
    >
      <div className="w-full flex flex-col gap-y-10 bg-gray pr-4 pb-4">
        <div className="text-right flex flex-row gap-x-4 justify-end">
          <Button
            className="bg-white"
            buttonStyle={ButtonStyle.OUTLINE}
            onClick={props.handleCancel}
            px="5"
            py="3"
          >
            {t('contact-form.cancel')}
          </Button>
          <Button onClick={() => props.handleSubmit(formValues)} px="5" py="3">
            {t('contact-form.submit')}
          </Button>
        </div>
        <BasicOutput title={t('contact-form.personal-data')} id="personal-data">
          <div className="pl-12 pr-5 ">
            <div className="flex flex-col items-end gap-y-5 w-6/12">
              <SelectInput
                onSelect={(e) => handleChange('gender', e)}
                width={inputWidth}
                label={{ name: t('contact-form.gender'), position: 'left' }}
                value={formValues.gender}
                options={genderOptions}
              />
              <BasicInput
                onChange={(e) => handleChange('title', e.target.value)}
                width={inputWidth}
                label={{ name: t('contact-form.title'), position: 'left' }}
                placeholder={t('contact-form.title-placeholder')}
                value={formValues.title}
              />
              <BasicInput
                onChange={(e) => handleChange('firstname', e.target.value)}
                width={inputWidth}
                label={{
                  name: t('contact-form.firstname'),
                  position: 'left',
                }}
                value={formValues.firstname}
                required
              />
              <BasicInput
                onChange={(e) => handleChange('surname', e.target.value)}
                width={inputWidth}
                label={{ name: t('contact-form.surname'), position: 'left' }}
                value={formValues.surname}
                required
              />
              <DatePickerInput
                placeholder={
                  formValues.birthday
                    ? formValues.birthday?.toDateString()
                    : t('contact-form.dropdownEmpty')
                }
                selectedDay={formValues.birthday}
                handleDateChange={handleDayChange}
                width={'w-80'}
                label={{ name: t('contact-form.birthday'), position: 'left' }}
              />
            </div>
          </div>
        </BasicOutput>

        <BasicOutput title={t('contact-form.kontakt-data')} id="kontakt-data">
          <div className="pl-12 pr-5 ">
            <div className="flex flex-col items-end gap-y-5 w-6/12 pb-10">
              <BasicInput
                onChange={(e) => handleChange('street', e.target.value)}
                width={inputWidth}
                label={{ name: t('contact-form.street'), position: 'left' }}
                value={formValues.street}
                required={!isEmailFilled()}
              />
              <BasicInput
                onChange={(e) => handleChange('address', e.target.value)}
                width={inputWidth}
                label={{ name: t('contact-form.address'), position: 'left' }}
                value={formValues.address}
              />
              <BasicInput
                onChange={(e) => handleChange('postcode', e.target.value)}
                width={inputWidth}
                label={{ name: t('contact-form.postcode'), position: 'left' }}
                value={formValues.postcode}
                required={!isEmailFilled()}
              />
              <BasicInput
                onChange={(e) => handleChange('city', e.target.value)}
                width={inputWidth}
                label={{ name: t('contact-form.city'), position: 'left' }}
                value={formValues.city}
                required={!isEmailFilled()}
              />
              <SelectInput
                onSelect={(e) => handleChange('countrycode', e)}
                width={inputWidth}
                label={{
                  name: t('contact-form.country'),
                  position: 'left',
                }}
                value={formValues.countrycode}
                options={countryOptions}
              />
            </div>
            <div className="flex flex-col gap-y-10 pb-5">
              <MultipleValueInput
                showStandardLabel={true}
                onChange={handleMultipleInputChange('email')}
                width={inputWidth}
                label={t('contact-form.email')}
                options={[
                  {
                    key: 0,
                    name: t('device-type.private'),
                    value: 'private',
                  },
                  {
                    key: 1,
                    name: t('device-type.business'),
                    value: 'business',
                  },
                ]}
                value={formValues.email}
                required={!isAddressFilled()}
              />
              <MultipleValueInput
                onChange={handleMultipleInputChange('phone')}
                width={inputWidth}
                label={t('contact-form.phone')}
                options={[
                  {
                    key: 0,
                    name: t('device-type.private'),
                    value: 'private',
                  },
                  { key: 1, name: t('device-type.mobile'), value: 'mobile' },
                  {
                    key: 2,
                    name: t('device-type.business'),
                    value: 'business',
                  },
                ]}
                value={formValues.phone}
                regex={/^([0-9 \-+]*)$/}
                required={true}
              />
              <MultipleValueInput
                onChange={handleMultipleInputChange('fax')}
                width={inputWidth}
                label={t('contact-form.fax')}
                options={[
                  {
                    key: 0,
                    name: t('device-type.private'),
                    value: 'private',
                  },
                  {
                    key: 1,
                    name: t('device-type.business'),
                    value: 'business',
                  },
                ]}
                value={formValues.fax}
                regex={/^([0-9 \-+]*)$/}
              />
            </div>
            <div className="flex flex-col items-end gap-y-5 w-6/12 pt-5">
              <Multiselect
                options={props.companiesOptions ? props.companiesOptions : []}
                values={getCompanyFromOptions(formValues.companyId)}
                label={{ name: t('contact-form.company'), position: 'left' }}
                width={20}
                unit={'rem'}
                displayToken={'name'}
                onSelect={(selectedProps) => handleCompanyChange(selectedProps)}
                onRemove={(selectedProps) => handleCompanyChange(selectedProps)}
                selectionLimit={1}
              />
            </div>
          </div>
        </BasicOutput>
        <BasicOutput
          title={t('contact-form.household-data')}
          id="household-data"
        >
          <div className="pl-12 pr-5 ">
            <div className="flex flex-col items-end gap-y-5 w-6/12">
              <SelectInput
                onSelect={(e) => handleChange('personsInHousehold', e)}
                width={inputWidth}
                label={{
                  name: t('contact-form.personsInHousehold'),
                  position: 'left',
                }}
                value={formValues.personsInHousehold}
                options={personsInHouseholdOptions}
              />
              <SelectInput
                onSelect={(e) => handleChange('job', e)}
                width={inputWidth}
                label={{
                  name: t('contact-form.job'),
                  position: 'left',
                }}
                value={formValues.job}
                options={jobOptions}
              />
              <SelectInput
                onSelect={(e) => handleChange('maritalStatus', e)}
                width={inputWidth}
                label={{
                  name: t('contact-form.maritalStatus'),
                  position: 'left',
                }}
                value={formValues.maritalStatus}
                options={famOptions}
              />
              <SelectInput
                onSelect={(e) => handleChange('sports', e)}
                width={inputWidth}
                label={{
                  name: t('contact-form.sports'),
                  position: 'left',
                }}
                value={formValues.sports}
                options={sports}
              />
              <SelectInput
                onSelect={(e) => handleChange('activities', e)}
                width={inputWidth}
                label={{
                  name: t('contact-form.activities'),
                  position: 'left',
                }}
                value={formValues.activities}
                options={activities}
              />
            </div>
          </div>
        </BasicOutput>
        {session?.identity?.traits?.isAdmin && (
          <BasicOutput title={t('contact-form.registration')} id="registration">
            <div className="pl-12 pr-5 ">
              <div className="flex flex-col items-end gap-y-5 w-6/12">
                {props.dealerOptions && session.identity?.traits.isAdmin && (
                  <Multiselect
                    options={props.dealerOptions ? props.dealerOptions : []}
                    values={formValues.owner ? formValues.owner : []}
                    label={{ name: t('user-edit.dealer'), position: 'left' }}
                    width={20}
                    unit={'rem'}
                    displayToken={'name'}
                    onSelect={(selectedProps) =>
                      handleDealerChange(selectedProps)
                    }
                    onRemove={(selectedProps) =>
                      handleDealerChange(selectedProps)
                    }
                    required
                  />
                )}
                <BasicInput
                  onChange={(e) => handleChange('vin', e.target.value)}
                  width={inputWidth}
                  label={{ name: t('contact-form.vin'), position: 'left' }}
                  value={formValues.vin}
                />
              </div>
            </div>
          </BasicOutput>
        )}
        <div className="text-right flex flex-row gap-x-4 justify-end">
          <Button
            className="bg-white"
            buttonStyle={ButtonStyle.OUTLINE}
            onClick={props.handleCancel}
            px="5"
            py="3"
          >
            {t('contact-form.cancel')}
          </Button>
          <Button onClick={() => props.handleSubmit(formValues)} px="5" py="3">
            {t('contact-form.submit')}
          </Button>
        </div>
      </div>
    </SideNavigation>
  );
};

export default ContactForm;

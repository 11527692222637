import {useTranslation} from "react-i18next";
import {ResponseState} from "../../models/test-drive";
import {BasicInput, RadioGroup} from "@sde/basic-ui-library";
import React, {useEffect} from "react";

export interface TestDriveResponseEditableProps {
  boughtVehicleVinState: string;
  setBoughtVehicleVinState: (state: string) => void;
  testDrivePerformedState: string;
  setTestDrivePerformedState: (state: string) => void;
  interestedState: string;
  setInterestedState: (state: string) => void;
  purchaseState: string;
  setPurchaseState: (state: string) => void;
}

const TestDriveResponseEditableBody = ({testDrivePerformedState, setTestDrivePerformedState, purchaseState, setPurchaseState, interestedState, setInterestedState}: TestDriveResponseEditableProps) => {
  const {t} = useTranslation();
  const radioYes = {value: ResponseState.TRUE, label: t('communication.test-drive.report.modals.radio.TRUE')};
  const radioNo = {value: ResponseState.FALSE, label: t('communication.test-drive.report.modals.radio.FALSE')};
  const radioUnknown = {value: ResponseState.UNKNOWN, label: t('communication.test-drive.report.modals.radio.UNKNOWN')};

  return (
    <div className="flex flex-col gap-y-2">
      <div className="flex flex-row gap-x-6">
        <RadioGroup
          size="6"
          values={[radioYes, radioNo]}
          value={testDrivePerformedState}
          name={"testDrivePerformed"}
          radioChange={setTestDrivePerformedState}
        />
      </div>
      {testDrivePerformedState === ResponseState.TRUE && <div className="flex flex-row gap-x-6">
        <RadioGroup
          size="6"
          values={[radioYes, radioNo, radioUnknown]}
          value={purchaseState}
          name={"purchase"}
          radioChange={setPurchaseState}
        />
      </div>}
      {testDrivePerformedState === ResponseState.TRUE && purchaseState === ResponseState.UNKNOWN && <div className="flex flex-row gap-x-6">
        <RadioGroup
          size="6"
          values={[radioYes, radioNo, radioUnknown]}
          value={interestedState}
          name={"interested"}
          radioChange={setInterestedState}
        />
      </div>}
    </div>
  );
}

export const TestDriveResponseEditable = (props: TestDriveResponseEditableProps) => {
  const {t} = useTranslation();
  const {
    boughtVehicleVinState,
    setBoughtVehicleVinState,
    testDrivePerformedState,
    purchaseState,
  } = props;

  useEffect(() => {
    if (purchaseState !== ResponseState.TRUE && setBoughtVehicleVinState) {
      setBoughtVehicleVinState('');
    }
  }, [purchaseState]);

  return (
    <div className="flex flex-col pt-3">
          <span className="text-base text-dark font-suzuki-bold whitespace-nowrap uppercase ps-5">
              {t('communication.test-drive.report.modals.response-label')}
          </span>
      <div className="flex flex-row px-5 py-2 gap-x-8">
        <div className="flex flex-col gap-y-2">
          <span>{t('communication.test-drive.report.modals.response.performed')}</span>
          {testDrivePerformedState === ResponseState.TRUE && <span>{t('communication.test-drive.report.modals.response.purchase')}</span>}
          {testDrivePerformedState === ResponseState.TRUE && purchaseState === ResponseState.UNKNOWN && <span>{t('communication.test-drive.report.modals.response.interested')}</span>}
        </div>
        <TestDriveResponseEditableBody {...props} />
      </div>
      {testDrivePerformedState === ResponseState.TRUE && purchaseState === ResponseState.TRUE && <div className="flex flex-row px-5 py-2 gap-x-6">
        <span>{t('communication.test-drive.report.modals.response.purchase-vin')}</span>
        <BasicInput
          onChange={(e) => setBoughtVehicleVinState && setBoughtVehicleVinState(e.target.value)}
          width="18rem"
          placeholder={t('communication.test-drive.report.modals.response.purchase-vin-placeholder')}
          value={boughtVehicleVinState}
        />
      </div>}
    </div>
  );
}

export const environment = {
  production: true,
  apiKey: 'p0zLmdPds51XKPtTTc05z0L0',
  publicAuthURL: "https://new-crm-test.mip-consult.de/.ory/kratos/public",
  restEndpoint: 'https://new-crm-test.mip-consult.de/api/v1/rest',
  dpd: {
    crmApiKey: '1ebb62fe-222b-4b1b-8c77-417dc9060664',
    username: 'mcrm',
    token: '78d7cd9f-63a0-4a37-a006-f1bebb0b3191',
    baseUrl: 'https://new-crm-test.mip-consult.de/api/v1/dpd'
  },
  systemTitle: 'testing',
  assets: {
    // PDF_1 : '/assets/pdfs/Andruck_Salesmailing.pdf',
    // PDF_2 : '/assets/pdfs/Andruck_Sales_Reminder[2].pdf',
    manual : '/assets/pdfs/manual.pdf',
  },
  newsletter_redirections: {
    moto: {
      verify: 'https://prod2-motorrad.suzuki.de/newsletter/anmeldung',
      unsubscribe: 'https://prod2-motorrad.suzuki.de/newsletter/abmeldung'
    }
  }
};

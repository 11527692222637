import React from 'react';
import { MouseEventHandler } from 'react';

export interface BadgeProps {
  /**
   * Text = Content of the Badge
   */
  name: string;
  /**
   * it showed when you hover over the Badge
   */
  title?: string;
  onClick?: MouseEventHandler<HTMLButtonElement> | undefined;
}

export function Badge(props: BadgeProps) {
  return (
    <div
      className="h-12 text-white flex items-center cursor-pointer"
      title={props.title}
    >
      <span
        className="font-suzuki-regular bg-dark-blue-gray pb-1 pt-2 px-2 text-sm opacity-90 "
        onClick={props.onClick}
      >
        {props.name}
      </span>
    </div>
  );
}

export default Badge;

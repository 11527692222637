import { useTranslation } from 'react-i18next';
import {
  Button,
  Headline,
  Icon,
  Spinner,
  Table,
} from '@suzuki-frontend-framework-update/ui';
import { Filter } from "@sde/basic-ui-library";
import { useApiGetData } from '../../components/useApiGetData/useApiGetData';
import { environment } from '../../../environments/environment';
import { Company, Contact, PoolDealer } from '../../models/contact';
import { useCallback, useEffect, useState } from 'react';
import { sendApiRequest } from '../../components/send-api-request/send-api-request';
import {
  AttributeData,
  AttributeResponse,
  DpdReportRequest,
  DpeReportEntry,
} from '../../models/dpd';
import { Dealer } from '../../models/dealer';
import { selectAllUserManagement } from '../../store/slices/user-management.slice';
import { Session } from '@ory/client';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { DataFetchProps } from '../../models/others';
import { Row } from 'react-table';
import download from 'downloadjs';
import { toast } from 'react-toastify';

export interface FilterDealer {
  id: string;
  dealercode: string;
  name: string;
}

export function PrivacyDataReport() {
  const validDealerNumbersPrefix = ['02', '04'];
  const { t } = useTranslation();
  const entities = useSelector(selectAllUserManagement);
  const [session, setSession] = useState({} as Session);
  const { type } = useParams();
  const navigate = useNavigate();
  const [availableASM, setAvailableASM] = useState<string[]>([]);
  const [selectedASM, setSelectedASM] = useState<string[]>();
  const [availableDSM, setAvailableDSM] = useState<string[]>([]);
  const [selectedDSM, setSelectedDSM] = useState<string[]>();
  const [availableDealers, setAvailableDealers] = useState<FilterDealer[]>([]);
  const [allAvailableDealers, setAllAvailableDealers] = useState<
    FilterDealer[]
  >([]);
  const [selectedPool, setSelectedPool] = useState<string[]>([]);
  const [loadingCSV, setLoadingCSV] = useState<boolean>(false);

  useEffect(() => {
    const lastEntity = entities[entities.length - 1];
    if (lastEntity && lastEntity.currentUser) {
      setSession(lastEntity.currentUser);
    }
  }, [entities]);

  const dealersWithPools = useApiGetData<PoolDealer[]>(
    `${environment.restEndpoint}/contact/contact-service/pools-with-dealer`,
    'get',
    'pooldealer'
  );

  const allDealers = useApiGetData<Dealer[]>(
    `${environment.restEndpoint}/dealer/dealer-service/dealers`,
    'post',
    'dealers'
  );

  useEffect(() => {
    if (dealersWithPools.data && allDealers.data) {
      const dealers: FilterDealer[] = [];
      const pools: string[] = [];

      if (session?.identity?.traits?.isAdmin) {
        const dsmAreas: string[] = [];
        const asmAreas: string[] = [];
        for (const dealer of allDealers.data) {
          if (
            validDealerNumbersPrefix.some((p) =>
              dealer.dealercode?.startsWith(p)
            ) &&
            !dealer.deleted
          ) {
            const dealerPools: PoolDealer[] = dealersWithPools.data.filter(
              (d) => d.dealerCode === dealer.dealercode
            );
            const poolsArray: string[] = dealerPools.map((d) => {
              return d.pool?.objectId;
            });
            const entry: FilterDealer = {
              id: dealer.objectID,
              dealercode: dealer.dealercode,
              name: dealer.name + ' (' + dealer.dealercode + ')',
            };
            dealers.push(entry);
            pools.push(...poolsArray);
            if (dealer.salesArea && !asmAreas.includes(dealer.salesArea)) {
              asmAreas.push(dealer.salesArea);
            }
            if (
              dealer.aftersalesArea &&
              !dsmAreas.includes(dealer.aftersalesArea)
            ) {
              dsmAreas.push(dealer.aftersalesArea);
            }
          }
        }

        asmAreas.sort((a, b) => a.localeCompare(b));
        setAvailableASM(asmAreas);

        dsmAreas.sort((a, b) => a.localeCompare(b));
        setAvailableDSM(dsmAreas);
      } else if (session?.identity?.traits?.isAreaAdmin) {
        const dealerIds: string[] = session?.identity?.traits?.dealers?.map(
          (d: any) => d.id
        );
        for (const dealer of allDealers.data) {
          if (
            dealerIds.includes(dealer?.objectID) &&
            validDealerNumbersPrefix.some((p) =>
              dealer.dealercode?.startsWith(p)
            ) &&
            !dealer.deleted
          ) {
            const dealerPools: PoolDealer[] = dealersWithPools.data.filter(
              (d) => d.dealerCode === dealer.dealercode
            );
            const poolsArray: string[] = dealerPools.map((d) => {
              return d.pool?.objectId;
            });
            const entry: FilterDealer = {
              id: dealer.objectID,
              dealercode: dealer.dealercode,
              name: dealer.name + ' (' + dealer.dealercode + ')',
            };
            dealers.push(entry);
            pools.push(...poolsArray);
          }
        }
      }
      dealers.sort((a, b) => (a.dealercode > b.dealercode ? 1 : -1));
      setAvailableDealers(dealers);
      setAllAvailableDealers(dealers);
      setSelectedPool(pools);
    }
  }, [dealersWithPools.data, allDealers.data]);

  const [reportRequestBody, setReportRequestBody] = useState<DpdReportRequest>({
    isAdmin: session.identity?.traits.isAdmin,
    dealercode: session.identity?.traits.isAdmin
      ? undefined
      : session.identity?.traits?.dealers?.map((d: Dealer) => d.dealercode),
    apiKey: environment.apiKey,
    authentication: {
      ssotoken: btoa(environment.dpd.token),
      applicationkey: environment.dpd.crmApiKey,
      username: environment.dpd.username,
    },
    isCompany: type === 'company',
    filter: {
      pageIndex: 0,
      pageSize: 50,
      poolIds: selectedPool,
    },
  });

  const reportData = useApiGetData<Contact[] | Company[]>(
    `${environment.dpd.baseUrl}/rest/dpe-report`,
    'post',
    '',
    reportRequestBody,
    false,
    'application/json'
  );

  const reportCountData = useApiGetData<number>(
    `${environment.dpd.baseUrl}/rest/dpe-report-count`,
    'post',
    '',
    reportRequestBody,
    false,
    'application/json'
  );

  const errorToast = (errorText: string) => {
    toast.error(errorText, {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      toastId: 'report-error',
    });
  };

  useEffect(() => {
    if (selectedASM && selectedASM.length > 0) {
      const dealers: FilterDealer[] = [];
      allDealers.data?.forEach(function (dealer) {
        if (
          selectedASM.includes(dealer.salesArea) &&
          validDealerNumbersPrefix.some((p) =>
            dealer.dealercode?.startsWith(p)
          ) &&
          !dealer.deleted
        ) {
          console.log(dealer);
          console.log(selectedASM);
          console.log(dealer.salesArea);
          const entry: FilterDealer = {
            id: dealer.objectID,
            dealercode: dealer.dealercode,
            name: dealer.name + ' (' + dealer.dealercode + ')',
          };
          dealers.push(entry);
        }
      });
      dealers.sort((a, b) => (a.dealercode > b.dealercode ? 1 : -1));
      setAvailableDealers(dealers);
      setupSelectedPools(dealers);
    } else {
      reset();
    }
  }, [selectedASM]);

  useEffect(() => {
    if (selectedDSM && selectedDSM.length > 0) {
      const dealers: FilterDealer[] = [];
      allDealers.data?.forEach(function (dealer) {
        if (
          selectedDSM.includes(dealer.aftersalesArea) &&
          validDealerNumbersPrefix.some((p) =>
            dealer.dealercode?.startsWith(p)
          ) &&
          !dealer.deleted
        ) {
          const entry: FilterDealer = {
            id: dealer.objectID,
            dealercode: dealer.dealercode,
            name: dealer.name + ' (' + dealer.dealercode + ')',
          };
          dealers.push(entry);
        }
      });
      dealers.sort((a, b) => (a.dealercode > b.dealercode ? 1 : -1));
      setAvailableDealers(dealers);
      setupSelectedPools(dealers);
    } else {
      reset();
    }
  }, [selectedDSM]);

  const reset = () => {
    setAvailableDealers(allAvailableDealers);
    setupSelectedPools(allAvailableDealers);
  };

  const setupSelectedPools = (dealers: FilterDealer[]) => {
    const pools: string[] = [];
    dealers.forEach(function (dealer) {
      const pool: PoolDealer | undefined = dealersWithPools.data?.find(
        (d) => d.dealerId === dealer.id
      );
      if (pool) {
        pools.push(pool.pool.objectId);
      }
    });
    setSelectedPool(pools);
  };

  const fetchReportData = useCallback(
    async ({ pageSize, pageIndex, pageSortBy }: DataFetchProps) => {
      if (selectedPool) {
        setReportRequestBody({
          isAdmin: session.identity?.traits.isAdmin,
          dealercode: session.identity?.traits.isAdmin
            ? undefined
            : session.identity?.traits?.dealers?.map(
                (d: Dealer) => d.dealercode
              ),
          apiKey: environment.apiKey,
          authentication: {
            ssotoken: btoa(environment.dpd.token),
            applicationkey: environment.dpd.crmApiKey,
            username: environment.dpd.username,
          },
          isCompany: type === 'company',
          filter: {
            pageIndex: pageIndex,
            pageSize: 50,
            poolIds: selectedPool,
          },
        });
      }
    },
    [selectedPool]
  );

  const contactColumns = [
    {
      Header: t('contacts.dpd-report.customer'),
      id: 'name',
      disableSortBy: true,
      Cell: ({ row }: any) => {
        return (
          <div className="flex flex-col">
            {type === 'contact' ? (
              <>
                <span>
                  {row.original.customerData.firstName}{' '}
                  {row.original.customerData.lastName}
                </span>
                <span className="text-blue-gray">
                  {row.original.customerData.customerNumber}
                </span>
              </>
            ) : null}
            {type === 'company' ? (
              <span>{row.original.customerData.company}</span>
            ) : null}
          </div>
        );
      },
    },
    {
      Header: '',
      id: 'attributes',
      Cell: ({ row }: any) => {
        return (
          <div className="flex flex-col font-suzuki-bold">
            <span>{t('contacts.dpd-report.value')}</span>
            <span>{t('contacts.dpd-report.declaration')}</span>
            <span>{t('contacts.dpd-report.document-status')}</span>
          </div>
        );
      },
    },
    {
      Header: t('contacts.dpd-report.post'),
      id: 'post',
      Cell: ({ row }: any) => {
        return (
          <div className="flex flex-col">
            <span>
              {getConsentData(row.original.attributes, 'post')?.value}
            </span>
            <span>
              {getConsentData(row.original.attributes, 'post')?.declaration}
            </span>
            <span>
              {getConsentData(row.original.attributes, 'post')?.documentStatus}
            </span>
          </div>
        );
      },
    },
    {
      Header: t('contacts.dpd-report.email'),
      id: 'email',
      Cell: ({ row }: any) => {
        return (
          <div className="flex flex-col">
            <span>
              {getConsentData(row.original.attributes, 'email')?.value}
            </span>
            <span>
              {getConsentData(row.original.attributes, 'email')?.declaration}
            </span>
            <span>
              {getConsentData(row.original.attributes, 'email')?.documentStatus}
            </span>
          </div>
        );
      },
    },
    {
      Header: t('contacts.dpd-report.telephone'),
      id: 'telephone',
      Cell: ({ row }: any) => {
        return (
          <div className="flex flex-col">
            <span>
              {getConsentData(row.original.attributes, 'telephone')?.value}
            </span>
            <span>
              {
                getConsentData(row.original.attributes, 'telephone')
                  ?.declaration
              }
            </span>
            <span>
              {
                getConsentData(row.original.attributes, 'telephone')
                  ?.documentStatus
              }
            </span>
          </div>
        );
      },
    },
    {
      Header: t('contacts.dpd-report.mobilephone'),
      id: 'mobilephone',
      Cell: ({ row }: any) => {
        return (
          <div className="flex flex-col">
            <span>
              {getConsentData(row.original.attributes, 'mobilephone')?.value}
            </span>
            <span>
              {
                getConsentData(row.original.attributes, 'mobilephone')
                  ?.declaration
              }
            </span>
            <span>
              {
                getConsentData(row.original.attributes, 'mobilephone')
                  ?.documentStatus
              }
            </span>
          </div>
        );
      },
    },
    {
      Header: t('contacts.dpd-report.sms'),
      id: 'sms',
      Cell: ({ row }: any) => {
        return (
          <div className="flex flex-col">
            <span>{getConsentData(row.original.attributes, 'sms')?.value}</span>
            <span>
              {getConsentData(row.original.attributes, 'sms')?.declaration}
            </span>
            <span>
              {getConsentData(row.original.attributes, 'sms')?.documentStatus}
            </span>
          </div>
        );
      },
    },
    {
      Header: t('contacts.dpd-report.fax'),
      id: 'fax',
      Cell: ({ row }: any) => {
        return (
          <div className="flex flex-col">
            <span>{getConsentData(row.original.attributes, 'fax')?.value}</span>
            <span>
              {getConsentData(row.original.attributes, 'fax')?.declaration}
            </span>
            <span>
              {getConsentData(row.original.attributes, 'fax')?.documentStatus}
            </span>
          </div>
        );
      },
    },
    {
      Header: t('contacts.dpd-report.messenger'),
      id: 'messenger',
      Cell: ({ row }: any) => {
        return (
          <div className="flex flex-col">
            <span>
              {getConsentData(row.original.attributes, 'messenger')?.value}
            </span>
            <span>
              {
                getConsentData(row.original.attributes, 'messenger')
                  ?.declaration
              }
            </span>
            <span>
              {
                getConsentData(row.original.attributes, 'messenger')
                  ?.documentStatus
              }
            </span>
          </div>
        );
      },
    },
    {
      Header: t('contacts.dpd-report.maFo'),
      id: 'mafo',
      Cell: ({ row }: any) => {
        return (
          <div className="flex flex-col">
            <span>
              {getConsentData(row.original.attributes, 'mare')?.value}
            </span>
            <span>
              {getConsentData(row.original.attributes, 'mare')?.declaration}
            </span>
            <span>
              {getConsentData(row.original.attributes, 'mare')?.documentStatus}
            </span>
          </div>
        );
      },
    },
    {
      Header: t('contacts.dpd-report.daten-weitergabe'),
      id: 'data-transfer',
      Cell: ({ row }: any) => {
        return (
          <div className="flex flex-col">
            <span>
              {
                getConsentData(row.original.attributes, 'further_data_transfer')
                  ?.value
              }
            </span>
            <span>
              {
                getConsentData(row.original.attributes, 'further_data_transfer')
                  ?.declaration
              }
            </span>
            <span>
              {
                getConsentData(row.original.attributes, 'further_data_transfer')
                  ?.documentStatus
              }
            </span>
          </div>
        );
      },
    },
  ];

  const getConsentData = (
    attributesData: AttributeResponse[],
    attributeName: string
  ): AttributeData => {
    if (attributesData === undefined) {
      return { value: '-', declaration: '-', documentStatus: '-' };
    }
    const attr = attributesData.find(
      (a) => a.name === attributeName + getSuffix()
    );
    if (attr === undefined || attr.value === undefined) {
      return {
        value: t('contacts.dpd-report.unknown'),
        declaration: '-',
        documentStatus: '-',
      };
    }
    const value =
      attr?.value === 'true' || attr?.value === 'false'
        ? t('contacts.dpd-report.' + attr?.value)
        : t('contacts.dpd-report.unknown');
    const documentStatusData = attr.additionalAttributes?.attribute.find(
      (a) => a.key === 'documentStatus'
    );
    const documentStatusValue =
      documentStatusData?.value === 'available'
        ? t('contacts.dpd-report.available')
        : attr?.value
        ? t('contacts.dpd-report.required')
        : '-';
    const declarationValue: string =
      attr.declaration !== (undefined || null)
        ? moment(attr.declaration).format('YYYY-MM-DD')
        : '-';
    return {
      value: value,
      declaration: declarationValue,
      documentStatus: documentStatusValue,
    };
  };

  const getSuffix = (): string => {
    return type === 'contact' ? '-private' : '-business';
  };

  const handleFilterASM = (id: string, selected: Array<string>) => {
    if (selected && allDealers) {
      setSelectedASM(selected);
    }
  };

  const handleFilterDSM = (id: string, selected: Array<string>) => {
    if (selected && allDealers) {
      setSelectedDSM(selected);
    }
  };

  const handleFilterDealerNumber = (id: string, selected: Array<string>) => {
    if (selected && selected.length > 0) {
      const pools: string[] = [];
      dealersWithPools.data?.forEach((d) => {
        if (selected.includes(d.dealerId)) {
          pools.push(d.pool.objectId);
        }
      });
      setSelectedPool(pools);
    } else {
      reset();
    }
  };

  const handleContactRowClick = (row: Row<DpeReportEntry>) => {
    if (type === 'contact') {
      navigate(`/contacts/view/${row.original.customerData.id}`);
    } else {
      navigate(`/companies/view/${row.original.customerData.id}`);
    }
  };

  const downloadCSV = async () => {
    setLoadingCSV(true);

    const dpdRequest = reportRequestBody;
    dpdRequest.filter = {
      pageIndex: undefined,
      pageSize: undefined,
      poolIds: selectedPool,
    };
    sendApiRequest(
      `${environment.dpd.baseUrl}/rest/reportCSV`,
      'post',
      dpdRequest,
      new Map([['Content-type', 'application/json']])
    )
      .then((response) => {
        if (response.ok) {
          return response.text();
        } else {
          setLoadingCSV(false);
          throw new Error(response.status + ' ' + response.statusText);
        }
      })
      .then((data) => {
        setLoadingCSV(false);
        download(data, 'report.csv', 'text/csv;charset=utf-8');
      })
      .catch((e) => {
        setLoadingCSV(false);
        errorToast(t('error') + e);
      });
  };

  return (
    <div id="dpe-report" className="grow p-8 bg-gray">
      <div className="h-fit w-fit">
        <Button py="4" className="w-full mb-1" onClick={() => navigate(-1)}>
          <Icon iconName={'arrow_back'} className="mr-1"></Icon>
          {t('contact-details.back')}
        </Button>
      </div>
      <div>
        <Headline
          title={
            type === 'contact'
              ? t('contacts.dpd-report.title-contact')
              : t('contacts.dpd-report.title-company')
          }
        />
        <div className="flex justify-end">
          {loadingCSV ? (
            <div className="my-2 w-fit">
              <Spinner />
            </div>
          ) : (
            <Button py="1" className="h-fit w-fit" onClick={downloadCSV}>
              {t('contacts.dpd-report.export-csv')}
            </Button>
          )}
        </div>

        <div className="py-4">
          {session.identity?.traits?.isAdmin && (
            <div className="flex flex-row items-center gap-x-8 py-1">
              <Filter
                key={'asm-filter'}
                id={'asm-filter'}
                name={t('contacts.dpd-report.asm')}
                items={availableASM.map((asm) => {
                  return { id: asm, name: asm };
                })}
                onChange={handleFilterASM}
                width="w-32"
              />
              <Filter
                key={'dsm-filter'}
                id={'dsm-filter'}
                name={t('contacts.dpd-report.dsm')}
                items={availableDSM.map((dsm) => {
                  return { id: dsm, name: dsm };
                })}
                onChange={handleFilterDSM}
                width="w-32"
              />
            </div>
          )}
          {(session.identity?.traits?.isAdmin ||
            session.identity?.traits?.isAreaAdmin) && (
            <div className="flex flex-row items-center gap-x-8 py-1    ">
              <Filter
                key={'dealer-filter'}
                id={'dealer-filter'}
                name={t('contacts.dpd-report.dealernumber')}
                items={availableDealers.map((dn) => {
                  return { id: dn.id, name: dn.name };
                })}
                onChange={handleFilterDealerNumber}
                width="w-[30rem]"
              />
            </div>
          )}
        </div>

        <Table
          key="dpe-report-table"
          columns={contactColumns}
          data={reportData.data ? reportData.data : []}
          loading={reportData.loading}
          initPageSize={50}
          rowCount={reportCountData.data}
          fetchData={fetchReportData}
          pagination
          basic
          resetOnChange={selectedPool}
          handleRowClick={handleContactRowClick}
        />
      </div>
    </div>
  );
}

export default PrivacyDataReport;

import './footer.module.scss';
import { useTranslation } from 'react-i18next';
export type FooterProps = {
  isLoginPage?: boolean;
};
export function Footer(props: FooterProps) {
  const { t } = useTranslation();

  return (
    <footer
      className={
        props.isLoginPage
          ? 'h-16 w-full bg-dark text-white flex flex-row justify-between'
          : 'h-16 w-full bg-white text-dark flex flex-row justify-between'
      }
    >
      <span className="flex items-center mx-8">
        <span className="text-2xl mr-2">&copy;</span>Suzuki Deutschland GmbH
      </span>
      <span className="flex flex-row justify-between items-center">
        {/* <a href="#" className="h-fit mx-8">
          {t('footer.downloads')}
        </a>
 */}
        <a href="/support" className="h-fit mx-8">
          {t('footer.support')}
        </a>
      </span>
    </footer>
  );
}

export default Footer;

import { CheckboxStatus, Headline, Icon, Tab, Table, TableFiltersProps, Tabs, Textarea, Tooltip } from '@suzuki-frontend-framework-update/ui';
import * as Popper from '@popperjs/core';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Column, Row } from 'react-table';
import { useApiGetData } from '../../components/useApiGetData/useApiGetData';
import { Field, Invitation, InvitationsRange, InvitationsRequest, SendInvitationRequest, State, Template, } from '../../models/invitation';
import SendConfirmationPopup from './send-confirmation-popup';
import SendPopup from './send-popup';
import { environment } from '../../../environments/environment';
import { sendApiRequest } from '../../components/send-api-request/send-api-request';
import { DataFetchProps } from '../../models/others';
import { inspectionsInvitationsFilterActions, inspectionsInvitationsFilterSelector, } from '../../store/slices/inspections-invitations.slice';
import { AppDispatch } from '../../store/setup';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'react-string-format';
import { selectAllUserManagement } from '../../store/slices/user-management.slice';
import { Session } from '@ory/client';
import { isAreaAdmin, isAutoDealerOrAdmin, isMotoDealerOrAdmin } from '../../helpers/session-utils';
import EditInvitationPopup from '../../components/edit-invitation-popup/edit-invitation-popup';
import RemoveInvitationPopup from '../../components/remove-invitation-popup/remove-invitation-popup';
import { DatePickerRange } from "@sde/basic-ui-library";
import ReportFilters from "../../components/report-filter/report-filter";
import { dashboardFilterSelector } from "../../store/slices/dashboard.slice";

export const InspectionsInvitations = () => {
  const {t} = useTranslation();
  const {tabParam} = useParams();
  const navigate = useNavigate();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const getInspectionNumbers = (): number[] | undefined => {
    if (inspectionsInvitationsFilterState.inspectionsInvitationsFilter.filter.inspectionNumbers) {
      return inspectionsInvitationsFilterState.inspectionsInvitationsFilter.filter.inspectionNumbers
    }
    const inspectionNumber = queryParams.get('inspectionNumber');
    const range = queryParams.get('range');
    if (range) {
      const numbers: number[] = []
      for (let i = 1; i <= Number(range); i++)
        numbers.push(i)
      return numbers
    }
    return inspectionNumber ? [Number(inspectionNumber)] : undefined
  }
  const getStartDispatchDate = (): Date | undefined => {
    if (inspectionsInvitationsFilterState.inspectionsInvitationsFilter.filter.startDispatchDate) {
      return inspectionsInvitationsFilterState.inspectionsInvitationsFilter.filter.startDispatchDate
    }
    const date = queryParams.get('dispatchDate');
    return date ? new Date(Number(date.split('-')[1]), Number(date.split('-')[0]) - 1, 1, 12) : undefined
  }
  const getEndDispatchDate = (): Date | undefined => {
    if (inspectionsInvitationsFilterState.inspectionsInvitationsFilter.filter.endDispatchDate) {
      return inspectionsInvitationsFilterState.inspectionsInvitationsFilter.filter.endDispatchDate
    }
    const date = queryParams.get('dispatchDate');
    return date ? new Date(Number(date.split('-')[1]), Number(date.split('-')[0]), 0, 12) : undefined
  }

  const [modalState, setModalState] = useState(false);
  const [modalConfState, setModalConfState] = useState(false);
  const [editModalState, setEditModalState] = useState(false);
  const [removeModalState, setRemoveModalState] = useState(false);
  const [editInvitation, setEditInvitation] = useState<Invitation | undefined>(
    undefined
  );
  const entities = useSelector(selectAllUserManagement);
  const [session, setSession] = useState({} as Session);

  useEffect(() => {
    const lastEntity = entities[entities.length - 1];
    if (lastEntity && lastEntity.currentUser) {
      setSession(lastEntity.currentUser);
    }
  }, [entities]);

  const DEFAULT_PLACEHOLDER_VALUE = `Schauen Sie doch gleich mal in Ihren Kalender und vereinbaren Sie
direkt einen Servicetermin. Rufen Sie uns einfach an:
{{.DealerPhone}}. Oder nutzen Sie unser Terminformular auf
unserer Webseite: <a href="{{.DealerWebsite}}">{{.DealerWebsite}}</a>`;
  const [motorcyclePlaceholder, setMotorcyclePlaceholder] =
    useState<string>('');
  const [scooterPlaceholder, setScooterPlaceholder] = useState<string>('');

  const inspectionsInvitationsFilterState = useSelector(
    inspectionsInvitationsFilterSelector
  );
  const dashboardFilterState = useSelector(dashboardFilterSelector);
  const dispatch: AppDispatch = useDispatch();
  if (
    inspectionsInvitationsFilterState.key === '' ||
    location.key !== inspectionsInvitationsFilterState.key
  ) {
    dispatch(inspectionsInvitationsFilterActions.init(location.key));
  }

  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [selectedRowIds, setSelectedRowIds] = useState<Record<string, boolean>>(
    {}
  );
  const [allSelectedStatus, setAllSelectedStatus] = useState<CheckboxStatus>({
    checked: false,
    indeterminate: false,
  });

  const [selectedRow, setSelectedRow] = useState<any>(null);

  const getStatesForFilters = (onlyForSend: boolean) => {
    if (tabParam === 'toSend') {
      return [State.NOT_SENT];
    }
    return onlyForSend
      ? [State.SENT]
      : [
        State.SENT,
        State.WAITING_TO_BE_SENT,
        State.EXPIRED_WAITING_TO_BE_SENT,
        State.EXPIRED_SENT,
        State.COMPLETED,
      ];
  };

  const getDefaultRanges = (): InvitationsRange[] => {
    return tabParam === 'sent' ? [] : [
      {
        daysInPast: '-14',
        daysInFuture: '42',
      },
      {
        daysInPast: '1',
        daysInFuture: '14',
      },
      {
        daysInPast: '14',
        daysInFuture: '-1',
      }
    ];
  };

  const [invitationsRequest, setInvitationsRequest] =
    useState<InvitationsRequest>({
      pageSize: 100,
      pageIndex: 0,
      filter: {
        ...inspectionsInvitationsFilterState.inspectionsInvitationsFilter
          .filter,
        state: getStatesForFilters(false),
        startDispatchDate: getStartDispatchDate(),
        endDispatchDate: getEndDispatchDate(),
        inspectionNumbers: getInspectionNumbers(),
      },
    });
  const invitations = useApiGetData<Invitation[]>(
    `${environment.restEndpoint}/invitation/invitation/getInvitations`,
    'post',
    'invitations',
    invitationsRequest
  );

  const [idsRequest, setIdsRequest] = useState<InvitationsRequest>({
    filter: {
      state: getStatesForFilters(true),
      inspectionDateRanges: getDefaultRanges(),
    },
  });

  const allInvitationsIdsForSendout = useApiGetData<number[]>(
    `${environment.restEndpoint}/invitation/invitation/getInvitationsIds`,
    'post',
    'ids',
    idsRequest
  );

  const displayedInvitationsIds = useApiGetData<number[]>(
    `${environment.restEndpoint}/invitation/invitation/getInvitationsIds`,
    'post',
    'ids',
    invitationsRequest
  );

  useEffect(() => {
    allInvitationsIdsForSendout.refreshData();
  }, [tabParam]);

  useEffect(() => {
    const checked = allInvitationsIdsForSendout.data?.every((id) => selectedIds.includes(id)) ?? false
    setAllSelectedStatus({
      checked: checked,
      indeterminate: !checked && selectedIds.length > 0,
    })
  }, [selectedIds]);

  const invitationsCount = useApiGetData<number>(
    `${environment.restEndpoint}/invitation/invitation/getInvitationsCount`,
    'post',
    'count',
    invitationsRequest
  );

  const handleFilterSelect = (id: string, selected: Array<string>) => {
    if (id === 'inspectionDate') {
      const result = selected.map((filter) => {
        let from = '';
        let to = '';
        switch (filter) {
          case 'not-marked': {
            // form two weeks in to the future
            from = '-14';
            to = '42';
            break;
          }
          case 'marked-yellow': {
            // form today to two weeks in to the future
            from = '1';
            to = '14';
            break;
          }
          case 'marked-red': {
            // for dispatch list: form two weeks in the past to now, for response list: all till now
            from = tabParam === 'sent' ? '' : '14';
            to = '-1';
            break;
          }
        }
        return {
          daysInPast: from,
          daysInFuture: to,
        } as InvitationsRange;
      });
      dispatch(
        inspectionsInvitationsFilterActions.updateInspectionsInvitationsFilter({
          ...inspectionsInvitationsFilterState.inspectionsInvitationsFilter
            .filter,
          inspectionDateRanges: result,
        })
      );
    } else if (id === 'inspectionNumber') {
      dispatch(
        inspectionsInvitationsFilterActions.updateInspectionsInvitationsFilter({
          ...inspectionsInvitationsFilterState.inspectionsInvitationsFilter
            .filter,
          inspectionNumbers: selected.map(Number),
        })
      );
    }
  };

  const getSelectedDateFilters = () => {
    return inspectionsInvitationsFilterState.inspectionsInvitationsFilter.filter
      .inspectionDateRanges
      ? inspectionsInvitationsFilterState.inspectionsInvitationsFilter.filter.inspectionDateRanges
        .map((r) => {
          if (r.daysInFuture === '42') return 'not-marked';
          else if (r.daysInFuture === '14') return 'marked-yellow';
          else if (r.daysInFuture === '-1') return 'marked-red';
          else return '';
        })
        .filter((s) => s !== '')
      : tabParam === 'sent'
        ? []
        : ['not-marked', 'marked-yellow', 'marked-red'];
  };

  const handleFilterSearch = (value: string) => {
    dispatch(
      inspectionsInvitationsFilterActions.updateInspectionsInvitationsFilter({
        ...inspectionsInvitationsFilterState.inspectionsInvitationsFilter
          .filter,
        search: value,
      })
    );
    setSelectedRow(null); // Reset selectedRow when filtering the table
  };

  const handleSend = () => {
    setModalState(false);
    sendApiRequest(
      `${environment.restEndpoint}/invitation/invitation/send`,
      'post',
      {
        invitationsIDs: selectedIds,
        placeholders: [
          {template: Template.MOTORCYCLE, value: motorcyclePlaceholder},
          {template: Template.SCOOTER, value: scooterPlaceholder},
        ],
      } as SendInvitationRequest
    ).then(() => {
      setModalConfState(true);
      resetSelections();
      setInvitationsRequest({
        ...invitationsRequest,
        pageIndex: 0,
      });
    });
  };

  useEffect(() => {
    if (!modalState) {
      setMotorcyclePlaceholder(DEFAULT_PLACEHOLDER_VALUE);
      setScooterPlaceholder(DEFAULT_PLACEHOLDER_VALUE);
    }
  }, [modalState]);

  const handleTabChange = (tabIndex: number) => {
    resetSelections();
    const sentTab = tabIndex === 1;
    navigate(
      `/communication/inspections-invitations/${sentTab ? 'sent' : 'toSend'}`
    );
  };

  const getTabIndex = () => {
    switch (tabParam) {
      case 'toSend':
        return 0;
      case 'sent':
        return 1;
      default:
        return 0;
    }
  };

  const toSendFilters = {
    id: 'invitationsToSendFilters',
    key: 'invitationsToSendFilters',
    placeholder: t(
      'communication.inspections-invitations.filters.search-placeholder'
    ),
    onSearchUpdate: handleFilterSearch,
    initialSearchValue:
    inspectionsInvitationsFilterState.inspectionsInvitationsFilter.filter
      .search,
    searchDelay: 500,
    // onMoreFilters: handleMoreFilters,
    filters: [
      {
        id: 'inspectionDate',
        name: t(
          'communication.inspections-invitations.filters.inspection-date.title'
        ),
        items: [
          {
            id: 'not-marked',
            name: t(
              'communication.inspections-invitations.filters.inspection-date.not-marked'
            ),
          },
          {
            id: 'marked-yellow',
            name: t(
              'communication.inspections-invitations.filters.inspection-date.marked-yellow'
            ),
          },
          {
            id: 'marked-red',
            name: t(
              'communication.inspections-invitations.filters.inspection-date.marked-red'
            ),
          },
        ],
        onChange: handleFilterSelect,
        selected: getSelectedDateFilters(),
      },
    ],
  };

    const handleStartDispatchDate = (from: Date) => {
        from.setHours(12)
        dispatch(inspectionsInvitationsFilterActions.updateInspectionsInvitationsFilter({
            ...inspectionsInvitationsFilterState.inspectionsInvitationsFilter.filter,
            startDispatchDate: from,
        }));
    };

    const handleEndDispatchDate = (to: Date) => {
        to.setHours(12)
        dispatch(inspectionsInvitationsFilterActions.updateInspectionsInvitationsFilter({
            ...inspectionsInvitationsFilterState.inspectionsInvitationsFilter.filter,
            endDispatchDate: to,
        }));
    };


  const sentFilters = {
    id: 'ivitationsSentFilters',
    key: 'ivitationsSentFilters',
    placeholder: t(
      'communication.inspections-invitations.filters.search-placeholder'
    ),
    onSearchUpdate: handleFilterSearch,
    initialSearchValue:
    inspectionsInvitationsFilterState.inspectionsInvitationsFilter.filter
      .search,
    searchDelay: 500,
    // onMoreFilters: handleMoreFilters,
    filters: [
      {
        id: 'inspectionDate',
        name: t(
          'communication.inspections-invitations.filters.inspection-date.title'
        ),
        items: [
          {
            id: 'marked-red',
            name: t(
              'communication.inspections-invitations.filters.inspection-date.marked-red-sent'
            ),
          },
        ],
        onChange: handleFilterSelect,
        selected: getSelectedDateFilters(),
      },
        isAutoDealerOrAdmin(session) ? {
          id: 'inspectionNumber',
          name: t('communication.inspections-invitations.filters.insepection-number.title'),
          items: [
            {
              id: '1',
              name: t('reports.crr.inspection-number.title.1'),
            },
            {
              id: '2',
              name: t('reports.crr.inspection-number.title.2'),
            },
            {
              id: '3',
              name: t('reports.crr.inspection-number.title.3'),
            },
            {
              id: '4',
              name: t('reports.crr.inspection-number.title.4'),
            },
            {
              id: '5',
              name: t('reports.crr.inspection-number.title.5'),
            },
            {
              id: '6',
              name: t('reports.crr.inspection-number.title.6'),
            },
            {
              id: '7',
              name: t('reports.crr.inspection-number.title.7'),
            },
            {
              id: '8',
              name: t('reports.crr.inspection-number.title.8'),
            },
            {
              id: '9',
              name: t('reports.crr.inspection-number.title.9'),
            },
            {
              id: '10',
              name: t('reports.crr.inspection-number.title.10'),
            },
          ],
            onChange: handleFilterSelect,
            selected: getInspectionNumbers()?.map(id => id.toString()),
        } : null
      ].filter(Boolean),
    } as TableFiltersProps;

  const columns = [
    {
      Header: t('communication.inspections-invitations.columns.contact'),
      accessor: 'contact',
      Cell: ({row}: any) => {
        return (
          <Link
            className="flex flex-col"
            to={
              row.original.vehicle.contact.isCompany
                ? `/companies/view/${row.original.vehicle.contact.contactObjectId}`
                : `/contacts/view/${row.original.vehicle.contact.contactObjectId}`
            }
            onClick={(e) => {
              dispatch(
                inspectionsInvitationsFilterActions.updateInspectionsInvitationsCurrentRow(
                  row.id
                )
              );
              e.stopPropagation();
            }}
          >
            <div className={'underline'}>
              {[
                row.original.vehicle.contact.firstname,
                row.original.vehicle.contact.name,
              ]
                .filter((n) => n)
                .join(' ')}
            </div>
            <span className="text-[#5f717c] text-opacity-40">
              {[
                row.original.vehicle.contact.street,
                row.original.vehicle.contact.zip,
                row.original.vehicle.contact.city,
                row.original.vehicle.contact.countryCode,
              ]
                .filter((n) => n)
                .join(', ')}
            </span>
          </Link>
        );
      },
    },
    {
      Header: t('communication.inspections-invitations.columns.email'),
      accessor: 'vehicle.contact.email',
    },
    {
      Header: t('communication.inspections-invitations.columns.model'),
      accessor: 'vehicle.model',
      Cell: ({row}: any) => {
        return (
          <span>
            {row.original.vehicle.model ? row.original.vehicle.model : '-'}
          </span>
        );
      },
    },
    {
      Header: t('communication.inspections-invitations.columns.license-plate'),
      accessor: 'vehicle.licensenumber',
      disableSortBy: true,
      Cell: ({row}: any) => {
        return (
          <span>
            {row.original.vehicle.licensenumber
              ? row.original.vehicle.licensenumber
              : '-'}
          </span>
        );
      },
    },
    {
      Header: t('communication.inspections-invitations.columns.vin'),
      accessor: 'vehicle.vin',
      disableSortBy: true,
      Cell: ({row}: any) => {
        return (
          <Link
            className="flex flex-col"
            to={`/vehicles/view/${row.original.vehicle.vehicleObjectId}`}
            onClick={(e) => {
              dispatch(
                inspectionsInvitationsFilterActions.updateInspectionsInvitationsCurrentRow(
                  row.id
                )
              );
              e.stopPropagation();
            }}
          >
            <div className={'underline'}>{row.original.vehicle.vin}</div>
          </Link>
        );
      },
    },
    {
      Header: t(
        'communication.inspections-invitations.columns.first-registration'
      ),
      accessor: 'vehicle.firstRegistrationDate',
      Cell: ({row}: any) => {
        return (
          <span>
            {row.original.vehicle.firstRegistrationDate
              ? new Date(
                row.original.vehicle.firstRegistrationDate
              ).toLocaleDateString()
              : '-'}
          </span>
        );
      },
    },
    tabParam === 'toSend' ? {
      Header: t(
        'communication.inspections-invitations.columns.last-inspection'
      ),
      accessor: 'lastInspectionDate',
      disableSortBy: true,
      Cell: ({ row }: any) => {
        return (
          <span>
            {row.original.lastInspectionDate
              ? new Date(row.original.lastInspectionDate).toLocaleDateString()
              : '-'}
          </span>
        );
      },
    } : null,
    {
      Header: t(
        'communication.inspections-invitations.columns.expected-inspection'
      ),
      accessor: 'calculatedInspectionDate',
      Cell: ({row}: any) => {
        return (
          <span>
            {row.original.calculatedInspectionDate
              ? new Date(
                row.original.calculatedInspectionDate
              ).toLocaleDateString()
              : '-'}
          </span>
        );
      },
    },
    {
      Header: t(
        'communication.inspections-invitations.columns.inspection-number'
      ),
      accessor: 'inspectionNumber',
    },
  ].filter(Boolean) as Column<Invitation>[];

  const buttonColumn = {
    Header: '',
    accessor: 'buttons',
    disableSortBy: true,
    Cell: ({row}: any) => {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const disabledEdit =
        new Date(row.original.calculatedInspectionDate) < today;
      return !isAreaAdmin(session) ? (
        <div className="flex flex-row gap-x-2">
          <Tooltip
            content={t('communication.inspections-invitations.edit.title')}
          >
            <button
              disabled={disabledEdit}
              onClick={(e) => {
                e.preventDefault();
                setSelectedRow(row.original); // Set the selected row
                setEditInvitation(row.original);
                setEditModalState(true);
              }}
            >
              <Icon
                iconName="edit_calendar"
                className={`${
                  disabledEdit ? 'text-opacity-40' : ''
                } text-black`}
              />
            </button>
          </Tooltip>
          <Tooltip
            content={t('communication.inspections-invitations.delete.hint')}
          >
            <button
              onClick={(e) => {
                e.preventDefault();
                setSelectedRow(row.original); // Set the selected row
                setEditInvitation(row.original);
                setRemoveModalState(true);
              }}
            >
              <Icon iconName="delete" className="text-black"/>
            </button>
          </Tooltip>
        </div>
      ) : null;
    },
  } as Column<Invitation>;

  const sentColumn = [{
    Header: t('communication.inspections-invitations.columns.invitation-sent'),
    accessor: 'invitationSent',
    Cell: ({row}: any) => getLastSendoutContent(row),
  },
    {
      Header: t(
        'communication.inspections-invitations.columns.done-inspection'
      ),
      accessor: 'completedDate',
      disableSortBy: true,
      Cell: ({ row }: any) => {
        return (
          <span>
            {row.original.completedDate
              ? new Date(row.original.completedDate).toLocaleDateString()
              : '-'}
          </span>
        );
      },
    }] as Column<Invitation>[];

  const getErrorMessage = (sendoutErrors: string): React.ReactNode => {
    return (
      <span className="leading-normal">
        {format(
          t(
            'communication.inspections-invitations.columns.sendout-errors.result'
          ),
          sendoutErrors
            .split(',')
            .map((err) =>
              t(
                'communication.inspections-invitations.columns.sendout-errors.' +
                err
              )
            )
            .join(', ')
        )}
      </span>
    );
  };

  function getLastSendoutContent(row: Row<Invitation>) {
    if (row.original.sendoutErrors) {
      return (
        <span>
          <Tooltip
            content={getErrorMessage(row.original.sendoutErrors)}
            placement={Popper.top}
          >
            <span>
              {t(
                'communication.inspections-invitations.columns.invitation-sendout-failed'
              )}
            </span>
          </Tooltip>
        </span>
      );
    }
    if (row.original.state == State.WAITING_TO_BE_SENT) {
      return (
        <span>
          {t(
            'communication.inspections-invitations.columns.invitation-sent-pending'
          )}
        </span>
      );
    }

    if (row.original.lastSendoutDate) {
      return (
        <span>
          {new Date(row.original.lastSendoutDate).toLocaleDateString()}
        </span>
      );
    }
    return <span>-</span>;
  }

  const handlePreviousRow = useCallback(
    async ({itemsRef}: any) => {
      if (invitations.data && inspectionsInvitationsFilterState.currentRow) {
        itemsRef.current
          .get(inspectionsInvitationsFilterState.currentRow)
          ?.scrollIntoView({behavior: 'smooth', block: 'center'});
        dispatch(
          inspectionsInvitationsFilterActions.updateInspectionsInvitationsCurrentRow(
            undefined
          )
        );
      }
    },
    [invitations.data, invitations.loading]
  );

  const handleRowClick = (row: Row<Invitation>) => {
    handleRowSelect(row);
  };

  const sortFieldMapper = (uistring: string): Field => {
    switch (uistring) {
      case 'contact':
        return Field.CONTACT_NAME;
      case 'vehicle.contact.email':
        return Field.CONTACT_EMAIL;
      case 'vehicle.model':
        return Field.VEHICLE_MODEL;
      case 'vehicle.firstRegistrationDate':
        return Field.VEHICLE_FIRST_REGISTRATION;
      case 'calculatedInspectionDate':
        return Field.CALCULATED_INSPECTION_DATE;
      case 'inspectionNumber':
        return Field.INSPECTION_NUMBER;
      case 'invitationSent':
        return Field.LAST_SENDOUT_DATE;
      default:
        return Field.NONE;
    }
  };

  const fetchData = useCallback(
    async ({pageSize, pageIndex, pageSortBy}: DataFetchProps) => {
      dispatch(
        inspectionsInvitationsFilterActions.updateInspectionsInvitationsPageIndex(
          pageIndex
        )
      );
      // Set CALCULATED_INSPECTION_DATE as default value for sortByField
      let sortByField = Field.CALCULATED_INSPECTION_DATE;
      let sortDesc = tabParam === 'sent';

      if (pageSortBy && pageSortBy.length > 0) {
        const sortParams = pageSortBy[0];
        sortDesc = sortParams.desc;
        sortByField = sortFieldMapper(sortParams.id);
      }
      const filter = inspectionsInvitationsFilterState.inspectionsInvitationsFilter
        .filter
      setInvitationsRequest({
        pageSize: pageSize,
        pageIndex: pageIndex,
        filter: {
          ...filter,
          inspectionDateRanges: filter.inspectionDateRanges && filter.inspectionDateRanges.length > 0 ?
            filter.inspectionDateRanges : getDefaultRanges(),
          inspectionNumbers: getInspectionNumbers(),
          startDispatchDate: getStartDispatchDate(),
          endDispatchDate: getEndDispatchDate(),
          state: getStatesForFilters(false),
          dealerCodes: dashboardFilterState.dealerFilter.dealers,
        },
        sortField: sortByField,
        sortDesc: sortDesc,
      });
    },
    [inspectionsInvitationsFilterState.inspectionsInvitationsFilter.filter]
  );

  const handleRowSelect = (row: Row<Invitation>) => {
    if (disableSelection(row)) {
      return;
    }
    let result = [];
    const id = row.original.id;
    if (id && allInvitationsIdsForSendout.data) {
      if (selectedIds.includes(id)) {
        result = selectedIds.filter((v) => v !== id);
      } else {
        result = [...selectedIds, id];
      }
      setSelectedRowIds({...selectedRowIds, [row.id]: result.includes(id)});
      setSelectedIds(result);
    }
  };

  const disableSelection = (row: Row<Invitation>): boolean => {
    const state = row.original.state;
    return state != State.NOT_SENT && state != State.SENT;
  };

  const handleAllRowsSelect = () => {
    const allIds =  displayedInvitationsIds.data?.
    filter(id => allInvitationsIdsForSendout.data?.includes(id))
    if (allIds?.every((id) => selectedIds.includes(id))) {
      resetSelections();
    } else {
      const result = [...selectedIds.filter(id => !allIds?.includes(id)), ...allIds ?? []]
      setSelectedIds(result);
      updateSelectedRowIds(result);
    }
  };

  const resetSelections = () => {
    const result = selectedIds.filter(id => !displayedInvitationsIds?.data?.includes(id))
    setSelectedIds(result);
    updateSelectedRowIds(result)
  };

  useEffect(() => {
    updateSelectedRowIds(selectedIds);
  }, [invitations.data]);

  const updateSelectedRowIds = (selected: number[]) => {
    if (invitations.data) {
      let result: Record<string, boolean> = {};
      invitations.data.forEach((inv, index) => {
        if (inv.id) {
          result = {
            ...result,
            [index.toString()]: selected.includes(inv.id),
          };
        }
      });
      setSelectedRowIds(result);
    }
  };

  const selectionHandler = {
    handleRowSelect: handleRowSelect,
    handleAllRowsSelect: handleAllRowsSelect,
    customSelectedRowIds: selectedRowIds,
    allSelectedStatus: allSelectedStatus,
    disableSelection: disableSelection,
  };

  const getPopupTemplates = () => {
    return (
      <Tabs defaultActiveTabIndex={0} disableStyling>
        <Tab
          title={t('communication.inspections-invitations.motorcycles')}
          tabIndex={0}
        >
          {getMotorcycleTemplate()}
        </Tab>
        <Tab
          title={t('communication.inspections-invitations.scooters')}
          tabIndex={1}
        >
          {getScooterTemplate()}
        </Tab>
      </Tabs>
    );
  };

  const getMotorcycleTemplate = () => {
    return (
      <div className="mt-8">
        <div
          style={{
            margin: 'auto',
            maxWidth: '600px',
            minWidth: '440px',
            backgroundColor: '#ebebeb',
            fontFamily: 'sans-serif',
            lineHeight: '1.5rem',
            fontSize: '0.9rem',
          }}
        >
          <img
            style={{width: '100%', maxWidth: '600px', minWidth: '440px'}}
            src="/assets/images/inspection-banner.jpg"
            alt="banner"
          />
          <div style={{padding: '3rem', paddingBottom: '1rem'}}>
            <span className="text-2xl font-suzuki-bold">
              Unser Werkstattmeister lädt ein
            </span>
            <br/>
            <br/>
            <span style={{marginTop: '1rem'}}>
              <span className="text-xl font-suzuki-bold">
                Sehr{' '}
                <span className="text-pink-600">
                  geehrte/r Frau/Herr Musterkunde,
                </span>
              </span>
              <p style={{marginTop: '1rem', marginBottom: '1rem'}}>
                wir hoffen, Sie genießen den unbeschwerten Fahrspaß mit Ihrer
                Suzuki <span className="text-pink-600">GSX-S1000GT</span>. Damit
                sich daran nichts ändert, laden wir Sie herzlich zur anstehenden
                Inspektion ein. So setzen Sie auch die besten Voraussetzungen,
                dass Sie später bei der Hauptuntersuchung (HU) grünes Licht für
                die entsprechende Plakette bekommen.
              </p>
              <p style={{marginTop: '1rem', marginBottom: '1rem'}}>
                Eine Inspektion in unserem Haus gibt Ihnen die Gewissheit, nach
                den anspruchsvollen Suzuki Qualitätsstandards betreut zu werden.
                Darüber hinaus sichern Sie die Werterhaltung Ihres Suzuki
                Motorrads und sparen damit bares Geld. Denn ein
                scheckheftgepflegtes Fahrzeug gibt Ihnen nicht nur heute mehr
                Sicherheit, sondern zahlt sich auch morgen beim Wiederverkauf
                für Sie aus.
              </p>
              <div
                className="text-pink-600"
                dangerouslySetInnerHTML={{__html: motorcyclePlaceholder}}
              />
              <p style={{marginTop: '1rem', marginBottom: '3rem'}}>
                In diesem Sinne freuen wir uns, Sie in Kürze bei uns begrüßen zu
                dürfen.
              </p>
              <p style={{marginTop: '1rem', marginBottom: '1rem'}}>
                Mit freundlichen Grüßen
              </p>
              <p
                style={{marginTop: '1rem', marginBottom: '1rem'}}
                className="text-pink-600"
              >
                <span className="font-suzuki-bold text-black">
                  Ihr Suzuki Vertragshändler
                </span>
                <br/>
                Max Mustermann, Musterstraße 123, 12345 Musterstadt
                <br/>
                Telefon: +49 1234 5678-90
                <br/>
                <a href="https://mustermann-bikes.de">
                  www.mustermann-bikes.de
                </a>
              </p>
              <p
                style={{
                  marginTop: '1rem',
                  fontSize: 'x-small',
                  lineHeight: 'normal',
                }}
              >
                Der Verwendung meiner Daten für Werksanliegen kann ich jederzeit
                schriftlich per Post (Suzuki Deutschland GmbH, Suzuki-Allee 7,
                64625 Bensheim oder an den genannten Suzuki Vertragshändler)
                oder per E-Mail an widerruf@suzuki.de widersprechen, jeweils
                auch mit Wirkung gegenüber dem Suzuki Vertragshändler bzw. der
                Suzuki Deutschland GmbH.
              </p>
            </span>
          </div>
        </div>
        <span
          className="max-w-[600px] min-w-[440px] flex flex-row items-center h-24 bg-[#ebebeb] mt-4 mx-auto justify-between px-8">
          <a href="https://www.youtube.com/SuzukiMotorradDeutschland">
            <img
              className="h-8"
              src="/assets/images/youtube.png"
              alt="youtube"
            />
          </a>
          <a href="https://www.instagram.com/suzukimotorrad/">
            <img
              className="h-8 pl-8"
              src="/assets/images/instagram.png"
              alt="instagram"
            />
          </a>
          <a href="https://www.facebook.com/SuzukiMotorradDeutschland/">
            <img
              className="h-8 pl-8"
              src="/assets/images/facebook.png"
              alt="facebook"
            />
          </a>
          <img
            className="h-8 pl-8"
            src="/assets/images/suzuki-original-ersatzteile.png"
            alt="suzuki original ersatzteile"
          />
          <a href="https://motorrad.suzuki.de/">
            <img
              style={{height: '0.8rem'}}
              src="/assets/images/motorrad-suzuki.png"
              alt="motorrad.suzuki.de"
            />
          </a>
        </span>
        <div className="max-w-[600px] min-w-[440px] mt-8 mx-auto">
          <Textarea
            value={motorcyclePlaceholder}
            onChange={(value) => setMotorcyclePlaceholder(value)}
            label={{
              name: t('communication.inspections-invitations.placeholder'),
              position: 'top',
            }}
            rows={5}
            resizeDisabled
          />
        </div>
      </div>
    );
  };

  const getScooterTemplate = () => {
    return (
      <div className="mt-8">
        <div
          style={{
            margin: 'auto',
            maxWidth: '600px',
            minWidth: '440px',
            backgroundColor: '#ebebeb',
            fontFamily: 'sans-serif',
            lineHeight: '1.5rem',
            fontSize: '0.9rem',
          }}
        >
          <img
            style={{width: '100%', maxWidth: '600px', minWidth: '440px'}}
            src="/assets/images/inspection-banner-scooter.jpg"
            alt="banner"
          />
          <div style={{padding: '3rem', paddingBottom: '1rem'}}>
            <span className="text-2xl font-suzuki-bold">
              Unser Werkstattmeister lädt ein
            </span>
            <br/>
            <br/>
            <span style={{marginTop: '1rem'}}>
              <span className="text-xl font-suzuki-bold">
                Sehr{' '}
                <span className="text-pink-600">
                  geehrte/r Frau/Herr Musterkunde,
                </span>
              </span>
              <p style={{marginTop: '1rem', marginBottom: '1rem'}}>
                wir hoffen, Sie genießen den unbeschwerten Fahrspaß mit Ihrem
                Suzuki <span className="text-pink-600">BURGMAN 400</span>. Damit
                sich daran nichts ändert, laden wir Sie herzlich zur anstehenden
                Inspektion ein. So setzen Sie auch die besten Voraussetzungen,
                dass Sie später bei der Hauptuntersuchung (HU) grünes Licht für
                die entsprechende Plakette bekommen.
              </p>
              <p style={{marginTop: '1rem', marginBottom: '1rem'}}>
                Eine Inspektion in unserem Haus gibt Ihnen die Gewissheit, nach
                den anspruchsvollen Suzuki Qualitätsstandards betreut zu werden.
                Darüber hinaus sichern Sie die Werterhaltung Ihres Suzuki
                Scooters und sparen damit bares Geld. Denn ein
                scheckheftgepflegtes Fahrzeug gibt Ihnen nicht nur heute mehr
                Sicherheit, sondern zahlt sich auch morgen beim Wiederverkauf
                für Sie aus.
              </p>
              <div
                className="text-pink-600"
                dangerouslySetInnerHTML={{__html: scooterPlaceholder}}
              />
              <p style={{marginTop: '1rem', marginBottom: '3rem'}}>
                In diesem Sinne freuen wir uns, Sie in Kürze bei uns begrüßen zu
                dürfen.
              </p>
              <p style={{marginTop: '1rem', marginBottom: '1rem'}}>
                Mit freundlichen Grüßen
              </p>
              <p
                style={{marginTop: '1rem', marginBottom: '1rem'}}
                className="text-pink-600"
              >
                <span className="font-suzuki-bold text-black">
                  Ihr Suzuki Vertragshändler
                </span>
                <br/>
                Max Mustermann, Musterstraße 123, 12345 Musterstadt
                <br/>
                Telefon: +49 1234 5678-90
                <br/>
                <a href="https://mustermann-bikes.de">
                  www.mustermann-bikes.de
                </a>
              </p>
              <p
                style={{
                  marginTop: '1rem',
                  fontSize: 'x-small',
                  lineHeight: 'normal',
                }}
              >
                Der Verwendung meiner Daten zu Werksanliegen kann ich jederzeit
                schriftlich per Post (Suzuki Deutschland GmbH, Suzuki-Allee 7,
                64625 Bensheim oder an den genannten Suzuki Vertragshändler)
                oder per E-Mail an widerruf@suzuki.de widersprechen, jeweils
                auch mit Wirkung gegenüber dem Suzuki Vertragshändler bzw. der
                Suzuki Deutschland GmbH.
              </p>
            </span>
          </div>
        </div>
        <span
          className="max-w-[600px] min-w-[440px] flex flex-row items-center h-24 bg-[#ebebeb] mt-4 mx-auto justify-between px-8">
          <a href="https://www.youtube.com/SuzukiMotorradDeutschland">
            <img
              className="h-8"
              src="/assets/images/youtube.png"
              alt="youtube"
            />
          </a>
          <a href="https://www.instagram.com/suzukimotorrad/">
            <img
              className="h-8 pl-8"
              src="/assets/images/instagram.png"
              alt="instagram"
            />
          </a>
          <a href="https://www.facebook.com/SuzukiMotorradDeutschland/">
            <img
              className="h-8 pl-8"
              src="/assets/images/facebook.png"
              alt="facebook"
            />
          </a>
          <img
            className="h-8 pl-8"
            src="/assets/images/suzuki-original-ersatzteile.png"
            alt="suzuki original ersatzteile"
          />
          <a href="https://motorrad.suzuki.de/">
            <img
              style={{height: '0.8rem'}}
              src="/assets/images/motorrad-suzuki.png"
              alt="motorrad.suzuki.de"
            />
          </a>
        </span>
        <div className="max-w-[600px] min-w-[440px] mt-8 mx-auto">
          <Textarea
            value={scooterPlaceholder}
            onChange={(value) => setScooterPlaceholder(value)}
            label={{
              name: t('communication.inspections-invitations.placeholder'),
              position: 'top',
            }}
            rows={5}
            resizeDisabled
          />
        </div>
      </div>
    );
  };

  const getCustomRowStyleInvitationsToSend = (row: Row<any>): string => {
    if (row.original?.calculatedInspectionDate) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const twoWeeksInFuture = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() + 14,
        0,
        0,
        0,
        0
      );
      const calcDate = new Date(row.original.calculatedInspectionDate);
      calcDate.setHours(0, 0, 0, 0);
      if (calcDate < today) {
        return 'py-2 border-b last:border-b-0 border-black bg-[#ff9f9f]';
      } else if (calcDate >= today && calcDate <= twoWeeksInFuture) {
        return 'py-2 border-b last:border-b-0 border-black bg-[#fcde92]';
      }
    }
    return 'py-2 border-b last:border-b-0 border-black';
  };

  const getCustomRowStyleSentInvitations = (row: Row<any>): string => {
    if (row.original?.calculatedInspectionDate) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const calcDate = new Date(row.original.calculatedInspectionDate);
      calcDate.setHours(0, 0, 0, 0);
      if (calcDate < today) {
        return 'py-2 border-b last:border-b-0 border-black bg-[#ff9f9f]';
      }
    }
    return 'py-2 border-b last:border-b-0 border-black';
  };

  useEffect(() => {
    dispatch(
      inspectionsInvitationsFilterActions.updateInspectionsInvitationsFilter({
        ...inspectionsInvitationsFilterState.inspectionsInvitationsFilter.filter,
        dealerCodes: dashboardFilterState.dealerFilter.dealers,
      }),
    );
  }, [dashboardFilterState.dealerFilter.dealers]);

  useEffect(() => {
    setIdsRequest({
      filter: {
        state: getStatesForFilters(true),
        inspectionDateRanges: getDefaultRanges(),
        dealerCodes: dashboardFilterState.dealerFilter.dealers,
      }
    })
  }, [tabParam, dashboardFilterState.dealerFilter.dealers]);


  return (
    <div className="w-full h-full">
      <Headline
        className="p-8"
        title={t('communication.inspections-invitations.title')}
      />
      <div className="ml-8 mb-2 -mt-9">
        <ReportFilters />
      </div>
        <div className="mt-7">
        <Tabs defaultActiveTabIndex={getTabIndex()} onTabChange={handleTabChange}>
          <Tab
            title={t('communication.inspections-invitations.send-invitations')}
            tabIndex={0}
          >
            <Table
              key="toSendTable"
              filter={toSendFilters}
              columns={[...columns, buttonColumn]}
              data={invitations.data ? invitations.data : []}
              loading={invitations.loading}
              initPageSize={100}
              rowCount={invitationsCount.data}
              countLabel={
                invitationsCount.data && invitationsCount.data === 1
                  ? t('common.list.count.entry')
                  : t('common.list.count.entries')
              }
              fetchData={fetchData}
              customElement={
                <>
                  {!isAreaAdmin(session) && (
                    <div>
                      {isMotoDealerOrAdmin(session) ? (
                        <>
                          <SendPopup
                            selectedCount={selectedIds.length}
                            countConfirmationTranslationKey="communication.inspections-invitations.confirm-count"
                            sendOne={t(
                              'communication.inspections-invitations.send-email'
                            )}
                            sendMany={t(
                              'communication.inspections-invitations.send-emails'
                            )}
                            buttonTitle={t(
                              'communication.inspections-invitations.send-invitation'
                            )}
                            modalTitle={t(
                              'communication.inspections-invitations.send-invitation-title'
                            )}
                            handleSend={handleSend}
                            setSendModalState={setModalState}
                            sendModalState={modalState}
                            disabled={session?.identity?.traits?.isAdmin}
                          >
                            {getPopupTemplates()}
                          </SendPopup>
                          <SendConfirmationPopup
                            modalTitle={t(
                              'communication.inspections-invitations.send-invitation-title'
                            )}
                            description={t(
                              'communication.inspections-invitations.invitation-confirmation-description'
                            )}
                            setSendModalConfState={setModalConfState}
                            sendModalConfState={modalConfState}
                          ></SendConfirmationPopup>
                        </>
                      ) : (
                        <>
                          <SendPopup
                            selectedCount={selectedIds.length}
                            countConfirmationTranslationKey="communication.inspections-invitations.confirm-count-cars"
                            sendOne={t(
                              'communication.inspections-invitations.send-letter'
                            )}
                            sendMany={t(
                              'communication.inspections-invitations.send-letters'
                            )}
                            buttonTitle={t(
                              'communication.inspections-invitations.send-invitation'
                            )}
                            modalTitle={t(
                              'communication.inspections-invitations.send-invitation-title'
                            )}
                            handleSend={handleSend}
                            setSendModalState={setModalState}
                            sendModalState={modalState}
                            disabled={session?.identity?.traits?.isAdmin}
                          />
                          <SendConfirmationPopup
                            modalTitle={t(
                              'communication.inspections-invitations.send-invitation-title'
                            )}
                            description={t(
                              'communication.inspections-invitations.invitation-confirmation-description-cars'
                            )}
                            expectedInspectionDate={
                              selectedRow && selectedRow.calculatedInspectionDate
                                ? t(
                                  'communication.inspections-invitations.invitation-confirmation-send-date',
                                  new Date(
                                    selectedRow.calculatedInspectionDate
                                  ).toLocaleDateString()
                                )
                                : ''
                            }
                            setSendModalConfState={setModalConfState}
                            sendModalConfState={modalConfState}
                          ></SendConfirmationPopup>
                        </>
                      )}
                    </div>
                  )}
                </>
              }
              pagination
              handleRowClick={handleRowClick}
              disabledClickForColumns={['buttons']}
              customSelectionHendler={selectionHandler}
              initialPage={
                inspectionsInvitationsFilterState.inspectionsInvitationsFilter
                  .pageIndex
              }
              resetOnChange={
                inspectionsInvitationsFilterState.inspectionsInvitationsFilter
                  .filter
              }
              handleScrollToRow={handlePreviousRow}
              getCustomRowStyle={getCustomRowStyleInvitationsToSend}
            />
          </Tab>
          <Tab
            title={t('communication.inspections-invitations.sent-invitations')}
            tabIndex={1}
          ><div className="flex flex-row gap-x-8 pr-8">
            <Table
              key="sentTable"
              filter={sentFilters}
              columns={[...columns, ...sentColumn]}
              data={invitations.data ? invitations.data : []}
              loading={invitations.loading}
              initPageSize={100}
              rowCount={invitationsCount.data}
              countLabel={
                invitationsCount.data && invitationsCount.data === 1
                  ? t('common.list.count.entry')
                  : t('common.list.count.entries')
              }
              fetchData={fetchData}
              customElement={
                <>
                  {isAutoDealerOrAdmin(session) && <DatePickerRange
                    id="dispatchDate"
                    label={t('communication.inspections-invitations.columns.invitation-sent')}
                    from={getStartDispatchDate()}
                    to={getEndDispatchDate()}
                    changeFrom={handleStartDispatchDate}
                    changeTo={handleEndDispatchDate}
                  />}
                  {!isAreaAdmin(session) && (
                    <div>
                      {isMotoDealerOrAdmin(session) ? (
                        <>
                          <SendPopup
                            selectedCount={selectedIds.length}
                            countConfirmationTranslationKey="communication.inspections-invitations.confirm-count"
                            sendOne={t(
                              'communication.inspections-invitations.send-email'
                            )}
                            sendMany={t(
                              'communication.inspections-invitations.send-emails'
                            )}
                            buttonTitle={t(
                              'communication.inspections-invitations.resend-invitation'
                            )}
                            modalTitle={t(
                              'communication.inspections-invitations.resend-invitation-title'
                            )}
                            handleSend={handleSend}
                            setSendModalState={setModalState}
                            sendModalState={modalState}
                            disabled={session?.identity?.traits?.isAdmin}
                          >
                            {getPopupTemplates()}
                          </SendPopup>
                          <SendConfirmationPopup
                            modalTitle={t(
                              'communication.inspections-invitations.resend-invitation-title'
                            )}
                            description={t(
                              'communication.inspections-invitations.invitation-confirmation-description'
                            )}
                            setSendModalConfState={setModalConfState}
                            sendModalConfState={modalConfState}
                          ></SendConfirmationPopup>
                        </>
                      ) : (
                        <>
                          <SendPopup
                            selectedCount={selectedIds.length}
                            countConfirmationTranslationKey="communication.inspections-invitations.confirm-count-cars"
                            sendOne={t(
                              'communication.inspections-invitations.send-letter'
                            )}
                            sendMany={t(
                              'communication.inspections-invitations.send-letters'
                            )}
                            buttonTitle={t(
                              'communication.inspections-invitations.resend-invitation'
                            )}
                            modalTitle={t(
                              'communication.inspections-invitations.resend-invitation-title'
                            )}
                            handleSend={handleSend}
                            setSendModalState={setModalState}
                            sendModalState={modalState}
                            disabled={session?.identity?.traits?.isAdmin}
                          />
                          <SendConfirmationPopup
                            modalTitle={t(
                              'communication.inspections-invitations.send-invitation-title'
                            )}
                            description={t(
                              'communication.inspections-invitations.invitation-confirmation-description-cars'
                            )}
                            expectedInspectionDate={
                              selectedRow && selectedRow.calculatedInspectionDate
                                ? t(
                                  'communication.inspections-invitations.invitation-confirmation-send-date',
                                  new Date(
                                    selectedRow.calculatedInspectionDate
                                  ).toLocaleDateString()
                                )
                                : ''
                            }
                            setSendModalConfState={setModalConfState}
                            sendModalConfState={modalConfState}
                          ></SendConfirmationPopup>
                        </>
                      )}
                    </div>
                  )}
                </>
              }
              pagination
              handleRowClick={handleRowClick}
              customSelectionHendler={selectionHandler}
              initialPage={
                inspectionsInvitationsFilterState.inspectionsInvitationsFilter
                  .pageIndex
              }
              resetOnChange={
                inspectionsInvitationsFilterState.inspectionsInvitationsFilter
                  .filter
              }
              handleScrollToRow={handlePreviousRow}
              getCustomRowStyle={getCustomRowStyleSentInvitations}
            />
              </div>
          </Tab>
        </Tabs>
        {!isAreaAdmin(session) ? (
          <>
            <EditInvitationPopup
              modalState={editModalState}
              setModalState={setEditModalState}
              invitation={editInvitation}
              refreshData={() => {
                invitations.refreshData();
                invitationsCount.refreshData();
                allInvitationsIdsForSendout.refreshData();
              }}
            />
            <RemoveInvitationPopup
              modalState={removeModalState}
              setModalState={setRemoveModalState}
              invitation={editInvitation}
              refreshData={() => {
                invitations.refreshData();
                invitationsCount.refreshData();
                allInvitationsIdsForSendout.refreshData();
              }}
            />
          </>
        ) : null}
      </div>
    </div>
  );
};

export default InspectionsInvitations;

/* eslint-disable @typescript-eslint/no-this-alias */
import { environment } from '../../../environments/environment';

var SuzukiDPD = {};
(function(){

var resourceBase = 'res';

var base = environment.dpd.baseUrl + '/';

function extendClass(baseClass, newClass) {
	if (typeof baseClass !== 'function') {
		throw new Error('baseClass is not defined');
	}
	var newPrototype = Object.create(baseClass.prototype);
	var p = newClass.prototype;
	for ( var i in p) {
		newPrototype[i] = p[i];
	}
	newPrototype.constructor = newClass;
	newClass.prototype = newPrototype;
	return newClass;
}
let $ ;
(function() {

	$ = function $(selector, element) {
		return new QuerySelector((element || document).querySelectorAll(selector));
	}

	function QuerySelector(elements) {
		this.elements = elements;
		this.length = elements.length;
		for (var i = 0; i < elements.length; i++) {
			this[i] = elements[i];
		}
	}

	QuerySelector.prototype.forEach = function(func) {
		for (var e = this.elements, i = 0; i < e.length; i++) {
			func(e[i]);
		}
	}

	QuerySelector.prototype.click = function(func) {
		this.forEach(function(element) {
			element.addEventListener('click', func);
		});
	}

})();

/**
 * @param options
 * @constructor
 * @class
 */
var View = function View(options, closeCallback) {
	this.options = options;
	this.options.base = options.base || base;
	this.buildCoreStructure();
	this.initialize(closeCallback);
}

/**
 * @param options
 *            ajax options
 * @memberof View
 */
View.prototype.ajax = function(options) {
	var request = new XMLHttpRequest();
	request.addEventListener('load', options.success);
	request.open(options.method || 'GET', options.url);
	request.setRequestHeader('apikey', environment.apiKey);
	request.send();
}

View.prototype.buildCoreStructure = function() {
	var content = document.getElementById("contact-details");
	var coreRootNode = this._coreRootNode = document.createElement('div');
	coreRootNode.className = 'suzuki-dpd-main fixed top-16 left-0 right-0 ';
	content.appendChild(coreRootNode);

	var mainDiv = this.root = document.createElement('div');
	mainDiv.className = 'suzuki-dpd-root';
	coreRootNode.appendChild(mainDiv);

	/*
	this.loadTemplate('dpd.css', function(text) {
		var div = document.createElement('style');
		div.setAttribute('type', 'text/css')
		div.innerHTML = text;
		mainDiv.appendChild(div);
	});
	*/
	var link = document.createElement('link');
	link.setAttribute('type', 'text/css');
	link.setAttribute('rel', 'stylesheet');
	link.setAttribute('href', base + resourceBase + '/dpd.css');
	coreRootNode.appendChild(link);
}

View.prototype.loadTemplate = function(name, callback) {
	/*
	 * entry point to load a template. Later you have a variable "templates". If requested template is containted there you invoke callback directly else do ajax request.
	 */
	this.ajax({
		url : base + resourceBase + name,
		success : function() {
			var request = this;
			callback(request.responseText);
		}
	});
}

View.prototype.close = function() {
	this._coreRootNode.parentNode.removeChild(this._coreRootNode);
}

/**
 * Class to handle the WHOLE DPD creation view
 *
 * @returns
 * @extends View
 */
function ViewCreate(options, closeCallback) {
	View.call(this, options, closeCallback); // invoke parent constructor
}

ViewCreate.prototype.initialize = function(closeCallback) {
	// eslint-disable-next-line @typescript-eslint/no-this-alias
	var me = this;

	var div = me.myroot = document.createElement('div');
	div.className = 'background';
	div.id = 'dpd-main'
	me.root.appendChild(div);

	var switchWindow = me.myroot = document.createElement('div');
	switchWindow.className = 'switchWindow';
	me.root.appendChild(switchWindow);

	var iframe = me.myroot = document.createElement('iframe');
	iframe.src = me.options.iframeSrc;
	iframe.id = "frame"
	iframe.addEventListener('load', function() {
		this.contentWindow.postMessage(me.options, '*');
	}, true);
	me.root.appendChild(iframe);

	var dpdViewListener = function(e) {
		if (e.data === 'close') {
			me.close();
			window.removeEventListener("message", dpdViewListener, false);
			closeCallback(me.options.closeCallbackParams);
		} else if (e.data === 'switchOverviewWindow') {
			SuzukiDPD.viewOverview(me.options);
		} else if (e.data === 'reload') {
			window.location.reload();
		}
	}
	window.addEventListener("message", dpdViewListener , false);
}

SuzukiDPD.viewCreateEntry = function viewCreateEntry(options, closeCallback) {
	options.iframeSrc = base + resourceBase  + '/createEntry/createEntry.html';
	options.operationName = 'createEntry';
	return new ViewCreate(options || {}, closeCallback);
}

SuzukiDPD.viewOverview = function viewOverview(options, closeCallback) {
	options.iframeSrc = base + resourceBase + '/overview/overview.html';
	options.operationName = 'overview';
	return new ViewCreate(options || {}, closeCallback);
}

SuzukiDPD.returnOverview = function returnOverview(options) {
	options.iframeSrc = base + resourceBase + '/overview/overview.html';

	var iframe = document.createElement('iframe');
	iframe.src = options.iframeSrc;
	iframe.addEventListener('load', function() {
		this.style.height = Math.ceil(this.contentWindow.document.body.scrollHeight) + 'px';
		this.contentWindow.postMessage(options, '*');
	}, true);

	return iframe;
}

// now "inherit" all parts from class View into class ViewCreate, must be last line!
extendClass(View, ViewCreate);
})();

export function showCustomerDpeHistory(paramMap) {
	SuzukiDPD.viewOverview(paramMap, closeDpdDialog);
}

export function showCreateCustomerDpeEntry(paramMap) {
	SuzukiDPD.viewCreateEntry(paramMap, closeDpdDialog);
}

function closeDpdDialog(params) {
	console.log("Closing dpd frame");
}

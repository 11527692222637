import React from 'react';

import DefaultTable from './default-table';
import PaginationTable from './pagination-table';
import ControlledPaginationTable from './controlled-pagination-table';
import { Column, Row } from 'react-table';
import TableFilters, {
  TableFiltersProps,
} from './table-filters';

export interface CheckboxStatus {
  checked: boolean;
  indeterminate: boolean;
}

export interface TableProps {
  /**
   * columns definitions
   */
  columns: Column<any>[];
  /**
   * the rows of the table
   */
  data: any[];
  /**
   * shows pagination
   */
  pagination?: boolean;
  /**
   * custom function to fetch Data
   * @param pageSize  the amount of rows of any given page
   * @param pageIndex the table Index of of any given page
   * @param pageSortBy an array that holds as first object the current sortOrder
   */
  fetchData?: ({ pageSize, pageIndex, pageSortBy }: any) => void;
  /**
   *  shows loading animation
   */
  loading?: boolean;
  /**
   *  determine the amount of rows available
   */
  rowCount?: number;
  /**
   * sets the Label for the rowCount; Useful for i18n. Defaults to 'Entries'
   */
  countLabel?: string;
  /**
   *  set the initial page
   */
  initialPage?: number;
  /**
   *  callback method to handle back to previous user row
   */
  handleScrollToRow?: ({ itemsRef }: any) => void;
  /**
   *  Determines the amount of rows on any given page
   */
  pageSize?: Array<number>;
  /**
   *  how many records should be displayed
   */
  initPageSize?: number;
  /**
   *  Add filter Input
   */
  filter?: TableFiltersProps;
  /**
   *  Show table rows without checkboxes (see Basic Example story)
   */
  basic?: boolean;
  /**
   *  will add custom Element to the component
   */
  customElement?: React.ReactNode;
  /**
   * custom function to handle responding to row clicks
   * @param row  the row in which the clicks are to be handled
   */
  handleRowClick?: (row: Row<any>) => void;
  /**
   * list of columns ids for which handleRowClick should be disabled
   */
  disabledClickForColumns?: string[]
  /**
   * custom handler for selections
   */
  customSelectionHendler?: {
    /**
     * custom function to handle row selection
     * @param row the selected row
     */
    handleRowSelect: (row: Row<any>) => void;
    /**
     * custom function to handle all rows selection
     */
    handleAllRowsSelect: () => void;
    /**
     * custom selectedRowIds state for table selection
     */
    customSelectedRowIds: Record<string, boolean>;
    /**
     * status of 'all-selected' checkbox
     */
    allSelectedStatus: CheckboxStatus;
    /**
     * custom function to check if selection for row should be disabled
     * @param row
     */
    disableSelection?: (row: Row<any>) => boolean;
  };
  /**
   * when this element is change table will reset
   */
  resetOnChange?: any;
  selectTitle?: string;
  allSelectTitle?: string;
  /**
   * custom cell class name
   */
  customCellClassName?: string;
  /**
   * custom header class name
   */
  customHeaderClassName?: string;
  /**
   * custom component class name
   */
  customComponentClassName?: string;
  /**
   * id
   */
  id?: string;
  /**
   * get custom row style for given row
   */
  getCustomRowStyle?: (row: Row<any>) => string;
}

export const Table = (props: TableProps) => {
  const cols = React.useMemo(() => props.columns, [props.columns]);
  const dta = React.useMemo(() => props.data, [props.data]);

  return (
    <div id={props.id} className={props.customComponentClassName ? props.customComponentClassName : "flex flex-col gap-y-8"}>
      {props.filter && <TableFilters {...props.filter} />}

      {props.customElement}
      {props.pagination && props.fetchData ? (
        <ControlledPaginationTable
          selectTitle={props.selectTitle}
          columns={cols}
          data={props.data}
          fetchData={props.fetchData}
          loading={props.loading}
          rowCount={props.rowCount ? props.rowCount : 0}
          countLabel={props.countLabel}
          initialPage={props.initialPage ? props.initialPage : 0}
          handleScrollToRow={props.handleScrollToRow}
          initPageSize={props.initPageSize}
          basic={props.basic}
          handleRowClick={props.handleRowClick}
          disabledClickForColumns={props.disabledClickForColumns}
          handleRowSelect={props.customSelectionHendler?.handleRowSelect}
          handleAllRowsSelect={
            props.customSelectionHendler?.handleAllRowsSelect
          }
          customSelectedRowIds={
            props.customSelectionHendler?.customSelectedRowIds
          }
          allSelectedStatus={props.customSelectionHendler?.allSelectedStatus}
          disableSelection={props.customSelectionHendler?.disableSelection}
          resetOnChange={props.resetOnChange}
          getCustomRowStyle={props.getCustomRowStyle}
        />
      ) : props.pagination && !props.fetchData ? (
        <PaginationTable
          selectTitle={props.selectTitle}
          columns={cols}
          data={dta}
          loading={props.loading}
          initialPage={props.initialPage ? props.initialPage : 0}
          initPageSize={props.initPageSize}
          basic={props.basic}
          rowCount={props.rowCount ? props.rowCount : 0}
          countLabel={props.countLabel}
          handleRowClick={props.handleRowClick}
          disabledClickForColumns={props.disabledClickForColumns}
          handleRowSelect={props.customSelectionHendler?.handleRowSelect}
          handleAllRowsSelect={
            props.customSelectionHendler?.handleAllRowsSelect
          }
          customSelectedRowIds={
            props.customSelectionHendler?.customSelectedRowIds
          }
          allSelectedStatus={props.customSelectionHendler?.allSelectedStatus}
          disableSelection={props.customSelectionHendler?.disableSelection}
        />
      ) : (
        <DefaultTable
          selectTitle={props.selectTitle}
          columns={cols}
          data={dta}
          loading={props.loading}
          basic={props.basic}
          handleRowSelect={props.customSelectionHendler?.handleRowSelect}
          handleAllRowsSelect={
            props.customSelectionHendler?.handleAllRowsSelect
          }
          customSelectedRowIds={
            props.customSelectionHendler?.customSelectedRowIds
          }
          allSelectedStatus={props.customSelectionHendler?.allSelectedStatus}
          disableSelection={props.customSelectionHendler?.disableSelection}
          customCellClassName={props.customCellClassName}
          customHeaderClassName={props.customHeaderClassName}
        />
      )}
    </div>
  );
};

export default Table;

import {Company, Contact} from "../models/contact";

export const getFirstDefaultEmail = (contact: Contact | Company): string => {
  let result = '';
  if (contact.email === undefined) {
    return result;
  }
  const emails = contact.email;
  for (let iterator = 0; iterator < emails.length; iterator++) {
    if (emails[iterator] && emails[iterator].default) {
      const foundEmail = emails[iterator].email;
      result = foundEmail !== undefined ? foundEmail : '';
      break;
    }
  }
  return result;
}

export const getFirstDefaultPhone = (contact: Contact | Company) => {
  let result = '';
  if (contact.phone === undefined) {
    return result;
  }
  const phones = contact.phone;
  for (let iterator = 0; iterator < phones.length; iterator++) {
    if (phones[iterator] && phones[iterator].default) {
      const foundPhone = phones[iterator].phoneNumber;
      result = foundPhone !== undefined ? foundPhone : '';
      break;
    }
  }
  return result;
}

export const isValidStreet = (street: string): boolean => {
  const streetRegex = /^(?=.*[0-9])(?=.*[a-zA-ZäöüßÄÖÜ]{3,}).*$/;
  return streetRegex.test(street);
};

import { useTranslation } from 'react-i18next';
import {
  FileBase64Input,
  Button,
  Headline,
  FileBase64Info,
  ButtonStyle,
  BasicOutput,
} from '@suzuki-frontend-framework-update/ui';
import { useState } from 'react';
import { sendApiRequest } from '../../components/send-api-request/send-api-request';
import { environment } from '../../../environments/environment';
import { toast } from 'react-toastify';
import {
  ExcelImportRequest,
  ExcelImportResponseRow,
} from '../../models/contact';
import { base64StringToBlob } from 'blob-util';
import download from 'downloadjs';
import ConstactsImportPopup from './constacts-import-result';

export function ContactsImport() {
  const { t } = useTranslation();

  const [selectedFile, setSelectedFile] = useState<FileBase64Info>();
  const [modalState, setModalState] = useState<boolean>(false);
  const [resultLoading, setResultLoading] = useState<boolean>(false);
  const [excelImportResponse, setExcelImportResponse] = useState<
    ExcelImportResponseRow[]
  >([]);

  const successToast = (successText: string) => {
    toast.success(successText, {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      toastId: 'excel-import-success',
    });
  };

  const errorToast = (errorText: string) => {
    toast.error(errorText, {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      toastId: 'excel-import-error',
    });
  };

  const handleImport = () => {
    if (
      selectedFile?.type &&
      [
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.ms-excel.sheet.macroEnabled.12',
        'application/vnd.ms-excel.template.macroEnabled.12',
        'application/zip',
        'multipart/x-zip',
        'application/zip-compressed',
        'application/x-zip-compressed',
        'application/octet-stream',
      ].includes(selectedFile.type)
    ) {
      setResultLoading(true);
      setModalState(true);
      sendApiRequest(
        `${environment.restEndpoint}/contact/contact-service/contact/ImportExcel`,
        'POST',
        {
          content: selectedFile?.base64,
          filename: selectedFile?.name,
          distributor: '', //TODO: Get from looged user
        } as ExcelImportRequest
      )
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error(response.status + ' ' + response.statusText);
          }
        })
        .then((data) => {
          const rows: ExcelImportResponseRow[] = data['rows'];
          setExcelImportResponse(rows);
          setResultLoading(false);
          setModalState(true);
          successToast(t('contacts.import.excel-import-success'));
        })
        .catch((e) => {
          errorToast(t('error') + e);
          setResultLoading(false);
          setModalState(false);
        });
    } else {
      console.log("Wrong file format: " + selectedFile?.type)
      errorToast(t('contacts.import.excel-import-wrong-format'));
    }
  };

  const handleDownload = () => {
    sendApiRequest(
      `${environment.restEndpoint}/contact/contact-service/contact/GetExcelTemplate`,
      'GET'
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error(response.status + ' ' + response.statusText);
        }
      })
      .then((data) => {
        const file = base64StringToBlob(
          data.content,
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        );
        download(
          file,
          'Suzuki_Vorlage_Interessentenimport_6.xlsx',
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        );
      })
      .catch((e) => errorToast(t('error') + e));
  };

  return (
    <div className="w-full h-full grow">
      <div className="flex flex-row justify-between items-center">
        <Headline className="p-8" title={t('contacts.import-item')} />
      </div>
      <div className="flex flex-row p-8 gap-x-8">
        <div className="w-1/4 flex flex-col min-w-[20rem]">
          <BasicOutput title={t('contacts.import.manual-data-import')}>
            <Button
              className="mb-16"
              buttonStyle={ButtonStyle.OUTLINE}
              onClick={handleDownload}
            >
              {t('contacts.import.download-template')}
            </Button>
            <FileBase64Input
              multiple={false}
              onDone={(files) => setSelectedFile(files[0])}
            />
            <Button className="mt-4" onClick={handleImport}>
              {t('contacts.import-item')}
            </Button>
            <ConstactsImportPopup
              rows={excelImportResponse}
              setModalState={setModalState}
              modalState={modalState}
              loading={resultLoading}
            />
          </BasicOutput>
        </div>
        <div></div>
      </div>
    </div>
  );
}

export default ContactsImport;

import {ModifyTestDriveData, TestDriveData, TestDriveOrigin, TestDriveStatus} from "../../models/test-drive";
import {useTranslation} from "react-i18next";
import {toast, ToastOptions} from "react-toastify";
import {sendApiRequest} from "../send-api-request/send-api-request";
import React, {useEffect, useState} from "react";
import {VehicleModel, VehicleModelType} from "../../models/vehicle-model";
import {Badge, Button, ButtonStyle, DatePickerInput, Modal, SelectInput, Textarea} from "@sde/basic-ui-library";
import {CustomerType} from "../../models/contact";
import {format} from "react-string-format";
import {useNavigate} from "react-router-dom";
import {environment} from "../../../environments/environment";
import {TestDriveInformation} from "../test-drive-information/test-drive-information";
import {Session} from "@ory/client";
import {useSelector} from "react-redux";
import {selectAllUserManagement} from "../../store/slices/user-management.slice";
import {isAdmin, isMotoDealer} from "../../helpers/session-utils";
import {isMotoDealerCode} from "../../helpers/dealer-helper";
import {determineModelType} from "../../helpers/model-helper";

export interface CreateTestDrivePopupProps {
  setModalState: (state: boolean) => void;
  modalState: boolean;
  testDrive: TestDriveData;
  refreshData?: () => void;
}

interface SelectModelOption {
  key: number;
  name: string;
  value: string;
}

export const CreateTestDrivePopup = (props: CreateTestDrivePopupProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const entities = useSelector(selectAllUserManagement);
  const [session, setSession] = useState({} as Session);
  const notSpecifiedText = t('communication.test-drive.report.modals.not-specified');
  const [selectedDateTime, setSelectedDateTime] = useState<Date | undefined>(undefined);
  const [comment, setComment] = useState('');
  const [modelsOptions, setModelsOptions] = useState<SelectModelOption[]>([]);
  const [vehicleModelsList, setVehicleModelsList] = useState<string[]>([]);

  const [modelType, setModelType] = useState<VehicleModelType>();

  const initializeSelectedVehicleModel = (): string => {
    if (vehicleModelsList !== undefined && vehicleModelsList.length > 0) {
      return vehicleModelsList[0];
    }
    return '';
  }
  const [selectedVehicleModel, setSelectedVehicleModel] = useState(initializeSelectedVehicleModel());
  const [modelsData, setModelsData] = useState<VehicleModel[]>();
  const defaultToastOptions: ToastOptions = {
    position: 'top-center',
    autoClose: 5000,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  }

  useEffect(() => {
    if (!props.testDrive) return;

    const { isMotorcycle, dealercode } = props.testDrive;
    const modelType = determineModelType(isMotorcycle, dealercode);

    setModelType(modelType);
  }, [props.testDrive]);

  useEffect(() => {
    if (modelType) {
      sendApiRequest(
        `${environment.restEndpoint}/contact/newsletter/model?scope=${modelType}`,
        'get'
      ).then(response => {
        if (response.ok) {
          response.json().then(data => {
            const vehicleModelsListData = data.data as VehicleModel[];
            setModelsData(vehicleModelsListData);
          })
        }
      })
    }
  }, [modelType]);

  useEffect(() => {
    const lastEntity = entities[entities.length - 1];
    if (lastEntity && lastEntity.currentUser) {
      setSession(lastEntity.currentUser);
    }
  }, [entities]);

  useEffect(() => {
    let modelNames: string[] = [];
    if (modelsData) {
      modelNames = modelsData.map<string>((model) => model.model as string);
    }
    setVehicleModelsList(modelNames);
  }, [modelsData]);

  useEffect(() => {
    let resultArray: SelectModelOption[] = [];
    for (let iterator = 0; iterator < vehicleModelsList.length; iterator++) {
      resultArray = resultArray.concat(
        {
          key: iterator,
          name: vehicleModelsList[iterator],
          value: vehicleModelsList[iterator],
        } as SelectModelOption
      )
    }
    setSelectedVehicleModel(initializeSelectedVehicleModel());
    setModelsOptions(resultArray);
  }, [vehicleModelsList]);

  const successToast = (successText: string) => {
    toast.success(successText, {
      ...defaultToastOptions,
      toastId: 'create-test-drive-success-toast'
    });
  };

  const errorToast = (errorText: string) => {
    toast.error(errorText, {
      ...defaultToastOptions,
      toastId: 'create-test-drive-error-toast'
    });
  };

  const warningToast = (warningText: string) => {
    toast.warning(warningText, {
      ...defaultToastOptions,
      toastId: 'create-test-drive-warning-toast'
    });
  };

  const resetPopupStates = () => {
    setSelectedDateTime(undefined);
    setComment('');
  }

  const handleCancel = () => {
    props.setModalState(false);
    resetPopupStates();
  };

  const handleDateChange = (date: Date) => {
    if (date.getTime() < new Date().getTime()) {
      warningToast(t('communication.test-drive.report.modals.planned-date-in-the-past'));
    }
    setSelectedDateTime(date);
  }

  const obtainProperStatus = (): string => {
    if (selectedDateTime != undefined) {
      return TestDriveStatus.SCHEDULED;
    }
    return TestDriveStatus.NEW_OPEN;
  }

  const buildCreateTestDriveBody = () => {
    return {
      testDrive: {
        contactId: props.testDrive.contactId,
        companyId: props.testDrive.companyId,
        contactSalutation: props.testDrive.contactSalutation,
        contactFirstName: props.testDrive.contactFirstName,
        contactLastName: props.testDrive.contactLastName,
        companyName: props.testDrive.companyName,
        contactEmail: props.testDrive.contactEmail,
        contactPhone: props.testDrive.contactPhone,
        modelName: selectedVehicleModel,
        wishDate: props.testDrive.wishDate,
        dealercode: props.testDrive.dealercode,
        notice: props.testDrive.notice,
        origin: TestDriveOrigin.SCRM,
        status: obtainProperStatus(),
        dealerComment: comment,
        plannedDate: selectedDateTime,
        createAt: new Date(),
      } as TestDriveData
    }
  }

  const handleSubmit = () => {
    if (selectedVehicleModel === undefined || selectedVehicleModel === '') {
      errorToast(t('communication.test-drive.report.modals.create-test-drive.fill-required-fields'));
      return;
    }

    const requestUrl = `${environment.restEndpoint}/action/testdrives/CreateTestDrive`;
    const requestBody = buildCreateTestDriveBody();

    sendApiRequest(requestUrl, 'post', requestBody).then((response) => {
      if (response.ok) {
        handleSuccessResponse();
      } else {
        response.json().then((data) => {
          errorToast(t('error') + data.message);
        });
      }
    });
  }

  const handleSuccessResponse = () => {
    successToast(t('communication.test-drive.report.modals.create-test-drive.successfully-created'));
    handleCancel();
    if (props.refreshData) {
      props.refreshData();
    } else {
      navigateToAppropriatePage();
    }
  };

  const navigateToAppropriatePage = () => {
    const navigateTo = getNavigationPath();
    navigate(`/communication/test-drives-report/${navigateTo}`);
  };

  const getNavigationPath = (): string => {
    const moto = "moto";
    const auto = "auto";

    if (isAdmin(session) && props.testDrive.dealercode) {
      return isMotoDealerCode(props.testDrive.dealercode) ? moto : auto;
    }
    return isMotoDealer(session) ? moto : auto;
  };

  return (
    <div>
      <Modal
        key="create-test-drive-modal"
        title={t('communication.test-drive.report.modals.create-test-drive.modal-title')}
        show={props.modalState}
        handleClose={() => handleCancel()}
        size='min-w-[440px]'
      >
        <div className="flex flex-col">
          <div className="flex flex-row items-start ps-5 gap-x-8 align-middle">
            <span className="font-suzuki-headline text-dark" style={{fontSize: '30px'}}>{props.testDrive.companyId ? `${props.testDrive?.contactSalutation} ${props.testDrive?.companyName}` : `${props.testDrive?.contactSalutation} ${props.testDrive?.contactFirstName} ${props.testDrive?.contactLastName}`}</span>
            <Badge
              name={
                props.testDrive?.customerState === CustomerType.CUSTOMER
                  ? t(
                    'contact-details.customer'
                  ).toLocaleUpperCase()
                  : props.testDrive?.customerState === CustomerType.PROSPECT
                    ? t(
                      'contact-details.prospect'
                    ).toLocaleUpperCase()
                    : props.testDrive?.customerState === CustomerType.LC
                      ? t(
                        'contact-details.lost-customer'
                      ).toLocaleUpperCase()
                      : notSpecifiedText.toLocaleUpperCase()
              }
              title={
                props.testDrive?.customerState === CustomerType.CUSTOMER
                  ? t('contact-details.customer')
                  : props.testDrive?.customerState === CustomerType.PROSPECT
                    ? t('contact-details.prospect')
                    : props.testDrive?.customerState === CustomerType.LC
                      ? t('contact-details.lost-customer')
                      : notSpecifiedText.toLocaleUpperCase()
              }
            />
          </div>
          <div className="flex items-start ps-5 pt-2">
            <span className="text-dark-gray font-suzuki-regular">
              {
                `${format(
                  t('communication.test-drive.report.modals.created-at'),
                  new Date().toLocaleDateString('de-DE', {year: 'numeric', month: '2-digit', day: '2-digit'}
                  ), t(`communication.test-drive.report.modals.origin.SCRM`))}`
              }
            </span>
          </div>
          <TestDriveInformation
            testDrive={props.testDrive}
            modelNameEditable={
              <SelectInput
                options={modelsOptions}
                onSelect={(value) => setSelectedVehicleModel(value)}
                value={selectedVehicleModel}
                width="100%"
                label={{
                  name: t('communication.test-drive.report.modals.model'),
                  position: 'top',
                }}
              />
            }
            plannedDateEditable={
              <DatePickerInput
                label={{
                  name: t('communication.test-drive.report.modals.selected-date'),
                  position: 'top',
                }}
                width={'w-[15rem]'}
                selectedDay={selectedDateTime}
                handleDateChange={handleDateChange}
                showTimeInput
              />
            }
            dealerCommentEditable={
              <Textarea
                value={comment}
                onChange={(value) => setComment(value)}
                width="100%"
                label={{
                  name: t('communication.test-drive.report.modals.comment'),
                  position: 'top',
                }}
                rows={8}
                resizeDisabled
              />
            }
          />
        </div>
        <div className="flex flex-row gap-x-6 justify-end">
          <Button
            className="bg-white"
            buttonStyle={ButtonStyle.OUTLINE}
            onClick={handleCancel}
            px="5"
            py="3"
          >
            {t('communication.test-drive.report.modals.close')}
          </Button>
          <Button onClick={handleSubmit} px="5" py="3">
            {t('communication.test-drive.report.modals.save')}
          </Button>
        </div>
      </Modal>
    </div>
  );
}

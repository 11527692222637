export interface WebRequestEntityContactView {
  id: string;
  contactId?: string;
  dealercode?: string;
  createdAt?: Date;
  models?: string[];
  model?: string;
  origin?: string;
  completed?: boolean;
  contactSalutation?: string;
  contactFirstName?: string;
  contactLastName?: string;
  contactNumber?: string;
  contactEmail?: string;
}

export interface WebRequest {
    filter?: WebRequestFilter;
    pageSize?: number;
    pageIndex?: number;
    sortField?: string;
    sortDir?: number;
}

export interface WebRequestFilter {
    dateFrom?: Date;
    dateTo?: Date;
    search?: string;
    contactId?: string;
    dealers?: string[];
    models?: ModelFilter[];
    notCompleted?: boolean;
}

export interface ModelFilter {
    model?: string;
    from?: Date;
}

export enum WebRequestType {
    OFFER = 'OFFER',
    CAR_CONFIGURATION_OFFER = 'CAR_CONFIGURATION_OFFER',
    CONSULTING_APPOINTMENT = 'CONSULTING_APPOINTMENT',
    SERVICE_APPOINTMENT = 'SERVICE_APPOINTMENT',
}

export interface WebRequestStatusMap {
  [id: string]: boolean;
}

import { Badge, Button, DataInfoContainer, DataInfoItemValue, Headline, Icon, Spinner, Tooltip, } from '@suzuki-frontend-framework-update/ui';
import * as Popper from '@popperjs/core';
import { useNavigate, useParams } from 'react-router-dom';
import { format } from 'react-string-format';
import { useEffect, useState } from 'react';
import AddActionPopup from '../../components/add-action-popup/add-action-popup';
import ChangeHistoryPopup from "../../components/change-history-popup/change-history-popup";
import { useApiGetData } from '../../components/useApiGetData/useApiGetData';
import { environment } from '../../../environments/environment';
import { useTranslation } from 'react-i18next';
import { Contact } from '../../models/contact';
import { Action, ActionsToActionsExtendingTimelineItems, ContextType, deleteAction, RemoveActionData, } from '../../models/action';
import { sendApiRequest } from '../../components/send-api-request/send-api-request';
import { Vehicle } from '../../models/vehicle';
import { JobEntry } from '../../models/job-entry';
import { showCustomerDpeHistory } from '../../components/dpd/dpd';
import EditActionPopup from '../../components/edit-action-popup/edit-action-popup';
import { RemoveActionPopup } from '../../components/remove-action-popup/remove-action-popup';
import { useDispatch, useSelector } from 'react-redux';
import { selectAllUserManagement } from '../../store/slices/user-management.slice';
import { Session } from '@ory/client';
import { getDpdParams, PrivacyDataReleases, } from '../contacts/privacy-data-releases';
import { AppDispatch } from '../../store/setup';
import { getTranslatedHidingReasonOfContact } from "../../components/hide-contact-popup/hiding-reasons";
import { CONTACT_TRANSLATION_ATTRIBUTES, CONTACT_TRANSLATION_KEY } from "../../models/history-entry";
import { toast } from "react-toastify";
import { CreateTestDrivePopup } from "../../components/create-test-drive-popup/create-test-drive-popup";
import { ModifyTestDriveData } from "../../models/test-drive";
import { getFirstDefaultEmail, getFirstDefaultPhone } from "../../helpers/contact-helper";
import { Dealer } from "../../models/dealer";
import { isAreaAdmin } from "../../helpers/session-utils";
import { Timeline, TimelineItem } from "@sde/basic-ui-library"
import {isMotoDealerCode} from "../../helpers/dealer-helper";

const ContactDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();

  const [addActionModalState, setAddActionModalState] = useState(false);
  const [changeHistoryModalState, setChangeHistoryModalState] = useState(false);
  const [editActionModalState, setEditActionModalState] = useState(false);
  const [editAction, setEditAction] = useState<Action>();
  const [removeActionModalState, setRemoveActionModalState] = useState(false);
  const [removeActionData, setRemoveActionData] = useState<RemoveActionData>();
  const [createTestDriveModalState, setCreateTestDriveModalState] = useState(false);
  const [testDriveCreationData, setTestDriveCreationData] = useState<ModifyTestDriveData>({id: '', isCanceled: false});
  const [dealerData, setDealerData] = useState<Dealer>();
  const [isMotoTenant, setIsMotoTenant] = useState<boolean>(false);

  const [requestBody, setRequestBody] = useState({
    id: id,
    details: 'FULL',
  });
  const [requestBodyActionsAppointments, setRequestBodyActionsAppointments] = useState({
    contactId: id,
    details: 'FULL',
  })
  const [jobData, setJobData] = useState<JobEntry>({ text: '-' });
  const [familyStateData, setFamilyStateData] = useState<JobEntry>({
    text: '-',
  });
  const [timelineItems, setTimelineItems] = useState<TimelineItem[]>([])

  const contactData = useApiGetData<Contact>(
    `${environment.restEndpoint}/contact/contact-service/contact/GetContact`,
    'post',
    undefined,
    requestBody
  );

  const contactActionsData = useApiGetData<Action[]>(
    `${environment.restEndpoint}/action/action?contextId=${requestBodyActionsAppointments.contactId}&contextType=CONTACT`,
    'get',
    'actions',
  );

  const vehiclesData = useApiGetData<Vehicle[]>(
    `${environment.restEndpoint}/vehicle/vehicle-service/vehicle/GetVehiclesByDriverId?contact_id=${id}`,
    'get',
    'vehicles'
  );

  const entities = useSelector(selectAllUserManagement);
  const [session, setSession] = useState({} as Session);

  const [vehicleMappingtData, setVehicleMappingtData] = useState<string>();

  useEffect(() => {
    if (contactData && contactData?.data && contactData?.data?.accessibillity && contactData?.data?.accessibillity?.disablingReasonType) {
      const reason = getTranslatedHidingReasonOfContact(contactData.data)
      if (reason) {
        toast.error(`${t('contacts.hidden-contact.hidden-for-a-reason')} ${reason}. ${t('contacts.hidden-contact.questions')}`, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          toastId: 'toast-contact-hidden-for-reason',
        });
        navigate(`/contacts/list/people`)
      }

    }
  }, [contactData.loading])

  useEffect(() => {
    const lastEntity = entities[entities.length - 1];
    if (lastEntity && lastEntity.currentUser) {
      setSession(lastEntity.currentUser);
    }
  }, [entities]);

  useEffect(() => {
    if (
      contactData.data &&
      contactData.data?.householdData?.jobId &&
      contactData.data?.householdData?.jobId !== ''
    ) {
      const job_id = contactData.data?.householdData?.jobId;
      sendApiRequest(
        `${environment.restEndpoint}/contact/contact-service/jobs/entry?code=${job_id}`,
        'get'
      )
        .then((response) => response.json())
        .then((data) => {
          setJobData(data as JobEntry);
        });
    }
    if (
      contactData.data &&
      contactData.data?.householdData?.familystatusId &&
      contactData.data?.householdData?.familystatusId !== ''
    ) {
      const family_id = contactData.data?.householdData?.familystatusId;
      sendApiRequest(
        `${environment.restEndpoint}/contact/contact-service/familystatus/entry?code=${family_id}`,
        'get'
      )
        .then((response) => response.json())
        .then((data) => {
          setFamilyStateData(data as JobEntry);
        });
    }
  }, [contactData.data, contactData.loading]);

  useEffect(() => {
    if (contactActionsData.data) {
      setTimelineItems(
        ActionsToActionsExtendingTimelineItems({
          actions: contactActionsData.data,
          onEdit: onEditAction,
          onDelete: onDeleteAction
        })
      );
    }
  }, [contactActionsData.data, contactActionsData.loading])

  useEffect(() => {
    const dealerData = async () => {
      if (contactData.data && contactData.data.poolId) {
        sendApiRequest(
          `${environment.restEndpoint}/dealer/dealer-service/dealerByPoolID?poolId=${contactData.data.poolId}`,
          'get',
        ).then(async (response) => {
          const data = await response.json();
          setDealerData(data.dealer);
          setIsMotoTenant(isMotoDealerCode(data.dealer.dealercode));
        });
      }
    };
    dealerData();
  }, [contactData.loading]);

  useEffect(() => {
    if (contactData.data && dealerData) {
      const contact = contactData.data;
      const preparedCreateTestDrivePopupData = {
        id: '',
        isCanceled: false,
        contactId: contact.objectId,
        contactSalutation: contact.salutation,
        contactFirstName: contact.firstname,
        contactLastName: contact.name,
        contactEmail: getFirstDefaultEmail(contact),
        contactPhone: getFirstDefaultPhone(contact),
        customerState: contact.customerstate,
        dealercode: dealerData.dealercode,
      } as ModifyTestDriveData
      setTestDriveCreationData(preparedCreateTestDrivePopupData);
    }
  }, [contactData.loading, dealerData])

  const getPhones = (): DataInfoItemValue[] => {
    const result: DataInfoItemValue[] = [];
    if (contactData.data?.phone) {
      contactData.data?.phone
        .sort((x, y) => (x.default === y.default ? 0 : x.default ? -1 : 1))
        .forEach((phone) => {
          if (phone.phoneNumber)
            result.push({
              value: phone.default ? (
                <span className="font-suzuki-bold">{phone.phoneNumber}</span>
              ) : (
                phone.phoneNumber
              ),
              iconName: phone.type === 'privat' ? 'call' : 'smartphone',
              value2: t(
                format('device-type.{0}', phone.type ? phone.type.toLowerCase() : 'private')
              ),
            });
        });
    }
    if (contactData.data?.fax) {
      contactData.data?.fax
        .sort((x, y) => (x.default === y.default ? 0 : x.default ? -1 : 1))
        .forEach((fax) => {
          if (fax.faxNumber)
            result.push({
              value: fax.default ? (
                <span className="font-suzuki-bold">{fax.faxNumber}</span>
              ) : (
                fax.faxNumber
              ),
              iconName: 'print',
              value2: t(
                format('device-type.{0}', fax.type ? fax.type.toLowerCase() : 'private')
              ),
            });
        });
    }
    return result.length > 0
      ? result
      : [
        {
          value: t('contact-details.not-specified'),
          iconName: '',
          value2: '',
        },
      ];
  };

  const getEmails = (): DataInfoItemValue[] => {
    const result: DataInfoItemValue[] = [];
    if (contactData.data?.email) {
      contactData.data?.email
        .sort((x, y) => (x.default === y.default ? 0 : x.default ? -1 : 1))
        .forEach((email) => {
          if (email.email)
            result.push({
              value: email.default ? (
                <span className="font-suzuki-bold break-words">
                  {email.email}
                </span>
              ) : (
                email.email
              ),
              iconName: 'email',
              value2: t(
                format('device-type.{0}', email.type ? email.type.toLowerCase() : 'private')
              ),
            });
        });
    }
    return result.length > 0
      ? result
      : [
        {
          value: t('contact-details.not-specified'),
        },
      ];
  };

  const calculateDaysToBirthday = (birthdate: Date) => {
    const today = new Date();
    const bday = new Date(birthdate);
    bday.setHours(0, 0, 0, 0);
    today.setHours(0, 0, 0, 0);
    bday.setFullYear(today.getFullYear());
    if (today > bday) {
      bday.setFullYear(bday.getFullYear() + 1);
    }
    return Math.round((bday.getTime() - today.getTime()) / 8.64e7);
  };

  const checkBirthday = (birthdate: Date) => {
    if (calculateDaysToBirthday(birthdate) === 0) {
      return t('contact-details.birthday-today');
    }
    return format(
      t('contact-details.days-to-birthday'),
      calculateDaysToBirthday(birthdate)
    );
  };

  const onEditAction = (action: Action) => {
    setEditAction(action);
    setEditActionModalState(true);
  };

  const onDeleteAction = (action: Action) => {
    setRemoveActionData({ id: action.objectId, context: { id: contactData?.data?.objectId || "", type: ContextType.CONTACT } });
    setRemoveActionModalState(true);
  };

  const aboutContact = {
    items: [
      {
        name: t('contact-details.number'),
        values: [
          {
            value: contactData.data?.customerNumber
              ? contactData.data?.customerNumber
              : '-',
          },
        ],
      },
      {
        name: t('contact-details.address'),
        values: [
          {
            value: [
              contactData.data?.address?.street + ',',
              contactData.data?.address?.zip,
              contactData.data?.address?.city,
              contactData.data?.address?.countrycode,
            ]
              .filter((n) => n)
              .join(' '),
          },
        ],
      },
      {
        name: t('contact-details.phone'),
        values: getPhones(),
      },
      {
        name: t('contact-details.email'),
        values: getEmails(),
      },
      {
        name: t('contact-details.birthday'),
        values: [
          {
            value: contactData.data?.householdData?.birthdate
              ? new Date(
                contactData.data?.householdData?.birthdate
              ).toLocaleDateString()
              : t('contact-details.not-specified'),
            iconName: 'event',
          },
          {
            value: contactData.data?.householdData?.birthdate
              ? checkBirthday(contactData.data?.householdData?.birthdate)
              : '',
          },
        ],
      },
    ],
    title: t('contact-details.about-contact'),
  };

  const householdData = {
    items: [
      {
        name: t('contact-details.household-data.persons-at-home'),
        values: [
          {
            value: contactData.data?.householdData?.personsathome
              ? contactData.data?.householdData?.personsathome.toString()
              : t('contact-details.not-specified'),
          },
        ],
      },
      {
        name: t('contact-details.household-data.job'),
        values: [
          {
            value: jobData.text,
          },
        ],
      },
      {
        name: t('contact-details.household-data.marital-status'),
        values: [
          {
            value: contactData.data?.householdData?.familystatusId
              ? familyStateData.text
              : t('contact-details.not-specified'),
          },
        ],
      },
      {
        name: t('contact-details.household-data.sports'),
        values: [
          {
            value: contactData.data?.householdData?.sport
              ? t(
                format(
                  'sports-list.{0}',
                  contactData.data?.householdData?.sport
                )
              )
              : t('contact-details.not-specified'),
          },
        ],
      },
      {
        name: t('contact-details.household-data.leisureaction'),
        values: [
          {
            value: contactData.data?.householdData?.leisureaction
              ? t(
                format(
                  'activities-list.{0}',
                  contactData.data?.householdData?.leisureaction
                )
              )
              : t('contact-details.not-specified'),
          },
        ],
      },
    ],
    title: t('contact-details.household-data.title'),
  };

  return (
    <div id="contact-details" className="grow p-8 bg-gray">
      <div className="h-fit w-fit">
        <Button
          py="4"
          className="w-full mb-1"
          onClick={() => {
            navigate(-1);
          }}
        >
          <Icon iconName={'arrow_back'} className="mr-1"></Icon>
          {t('contact-details.back')}
        </Button>
      </div>
      {contactData.loading ? (
        <Spinner />
      ) : contactData.status !== 200 ? (
        <div>{contactData.statusText}</div>
      ) : (
        <>
          <div className="w-full">
            <div className="flex w-full">
              <div id="leftInfoHeader" className="flex space-x-4 w-8/12">
                <div className="flex flex-col">
                  <div className="flex flex-row">
                    <div className="h-12">
                      <Headline
                        title={`${contactData.data?.salutation} ${contactData.data?.firstname} ${contactData.data?.name}`}
                      />
                    </div>
                    <div className="ml-6">
                      {contactData.data?.customerstate ? (
                        <Badge
                          name={
                            contactData.data?.customerstate === 'CUSTOMER'
                              ? t(
                                'contact-details.customer'
                              ).toLocaleUpperCase()
                              : contactData.data?.customerstate ===
                              'PROSPECT_CUSTOMER'
                                ? t(
                                  'contact-details.prospect'
                                ).toLocaleUpperCase()
                                : contactData.data?.customerstate ===
                                'LOST_CUSTOMER'
                                  ? t(
                                    'contact-details.lost-customer'
                                  ).toLocaleUpperCase()
                                  : ''
                          }
                          title={
                            contactData.data?.customerstate === 'CUSTOMER'
                              ? t('contact-details.customer')
                              : contactData.data?.customerstate ===
                              'PROSPECT_CUSTOMER'
                                ? t('contact-details.prospect')
                                : contactData.data?.customerstate ===
                                'LOST_CUSTOMER'
                                  ? t('contact-details.lost-customer')
                                  : ''
                          }
                        />
                      ) : (
                        <div className="h-12" />
                      )}
                    </div>
                  </div>

                  <div className="flex flex-row ">
                    {contactData.data?.created && (
                      <span className="text-dark-gray  font-suzuki-regular">
                        {format(
                          t('contact-details.created-at'),
                          new Date(
                            contactData.data?.created
                          ).toLocaleDateString('de-DE', {
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit',
                          }),
                          contactData.data?.createdBy
                            ? contactData.data?.createdBy
                            : '-'
                        )}
                      </span>
                    )}
                    {contactData.data?.modified && (
                      <span className="text-dark-gray  font-suzuki-regular ml-3">
                        {format(
                          t('contact-details.modified-at'),
                          new Date(
                            contactData.data?.modified
                          ).toLocaleDateString('de-DE', {
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit',
                          }),
                          contactData.data?.modifiedBy
                            ? contactData.data?.modifiedBy
                            : '-'
                        )}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div id="rightInfoHeader" className="w-4/12">
                <div className="flex flex-row gap-x-4 justify-end">
                  <Tooltip
                    content={t('contact-details.buttons.tooltip.edit-contact')}
                    placement={Popper.top}
                  >
                    <Button
                      onClick={() => navigate(`/contacts/edit/${id}`)}
                      className="w-fit h-fit"
                      py="1"
                      px="1"
                    >
                      <Icon iconName="edit" className="text-xl w-7" />
                    </Button>
                  </Tooltip>

                  <Tooltip
                    content={t('contact-details.buttons.tooltip.dpd')}
                    placement={Popper.top}
                  >
                    <Button
                      className="w-fit h-fit"
                      py="1"
                      px="1"
                      onClick={() =>
                        showCustomerDpeHistory(
                          getDpdParams(session, contactData.data, false)
                        )
                      }
                    >
                      <Icon
                        iconName="strikethrough_s"
                        className="text-xl w-7"
                      />
                    </Button>
                  </Tooltip>

                  <Tooltip
                    content={t('contact-details.buttons.tooltip.add-action')}
                    placement={Popper.top}
                  >
                    <AddActionPopup
                      setModalState={setAddActionModalState}
                      modalState={addActionModalState}
                      contactId={id}
                      refreshData={contactActionsData.refreshData}
                      contexts={[{ id: id || "", type: "CONTACT" }]}
                    />
                  </Tooltip>
                  <Tooltip
                    content={t('contact-details.history.title')}
                    placement={Popper.top}
                  >
                    <ChangeHistoryPopup
                      setModalState={setChangeHistoryModalState}
                      modalState={changeHistoryModalState}
                      translationKey={CONTACT_TRANSLATION_KEY}
                      translationAttributes={CONTACT_TRANSLATION_ATTRIBUTES}
                      entries={contactData.data?.changeHistory}
                    />
                  </Tooltip>

                  {!isAreaAdmin(session) && <Tooltip
                    content={t('communication.test-drive.report.modals.create-test-drive.button-tooltip')}
                    placement={Popper.top}
                  >
                    <div>
                      <Button
                        className="h-fit w-fit"
                        py="1"
                        px="1"
                        onClick={() => setCreateTestDriveModalState(true)}
                      >
                        <Icon iconName={ isMotoTenant ? 'sports_motorsports' : 'directions_car' } className="text-xl w-7" />
                      </Button>
                      <CreateTestDrivePopup
                        setModalState={setCreateTestDriveModalState}
                        modalState={createTestDriveModalState}
                        testDrive={testDriveCreationData}
                      />
                    </div>
                  </Tooltip>}

                </div>
              </div>
            </div>
          </div>

          <div className="w-full flex mt-3 space-x-4">
            <div className="w-3/12">
              <div className="flex flex-col">
                <DataInfoContainer {...aboutContact} />

                <PrivacyDataReleases
                  session={session}
                  contactData={contactData.data}
                  isCompany={false}
                />
              </div>
            </div>
            <div className="w-6/12">
              {contactActionsData.loading ? (
                <Spinner />
              ) : (
                <Timeline
                  title={t('contact-details.actions')}
                  httpConfig={{baseUrl: environment.restEndpoint, apiKey: environment.apiKey}}
                  className="w-full"
                  items={timelineItems}
                  key={"history_timeline_contact_details"}
                  timelineItemsContainerId={"timeline_history_container_contact_details"}
                />
              )}
            </div>
            <div className="w-3/12">
              <div className="flex flex-col">
                {vehiclesData.data?.map((vehicle, index) => {
                  if (!vehicle.deactivation?.deactivationValid || contactData.data?.customerstate !=='LOST_CUSTOMER' ) {
                  return (
                    <DataInfoContainer
                      key={vehicle.objectId}
                      items={[
                        {
                          name: t('contact-details.vehicles.vin'),
                          values: [{ value: vehicle.vin ? vehicle.vin : '-' }],
                        },
                        {
                          name: t(
                            'contact-details.vehicles.first-registration'
                          ),
                          values: [
                            {
                              value: vehicle.firstRegistraion
                                ? new Date(
                                  vehicle.firstRegistraion
                                ).toLocaleDateString()
                                : '-',
                            },
                          ],
                        },
                        {
                          name: t('contact-details.vehicles.ownership'),
                          values: [{ value: 'Fahrer und Halter' }], //TODO: get this value from vehicle
                        },
                      ]}
                      title={
                        index === 0 ? t('contact-details.vehicles.title') : ''
                      }
                      textBeforeValues={[vehicle.brand, vehicle.model]
                        .filter((n) => n)
                        .join(' ')}
                      linksAfterValues={[
                        {
                          name: t('contact-details.vehicles.more-information'),
                          onClick: () =>
                            navigate(`/vehicles/view/${vehicle.objectId}`),
                        },
                      ]}
                    />
                  );
                } else {
                  return null;
                }
                })}

                <DataInfoContainer {...householdData} />
              </div>
            </div>
          </div>
        </>
      )}
      <EditActionPopup
        action={editAction}
        setActionState={setEditAction}
        modalState={editActionModalState}
        setModalState={setEditActionModalState}
        refreshData={contactActionsData.refreshData}
      />
      <RemoveActionPopup
        onHandleCancel={() => setRemoveActionModalState(false)}
        onHandleRemove={() =>
          deleteAction(removeActionData, contactActionsData.refreshData, () =>
            setRemoveActionModalState(false)
          )
        }
        modalState={removeActionModalState}
        type="action"
        valueNameToDelete={''}
      />
    </div>
  );
};

export default ContactDetails;

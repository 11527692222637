import './company-create.module.scss';

import { useState } from 'react';
import { Headline } from '@suzuki-frontend-framework-update/ui';
import {
  Company,
  ContactEmail,
  ContactFax,
  ContactPhone,
} from '../../models/contact';
import { environment } from '../../../environments/environment';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { sendApiRequest } from '../../components/send-api-request/send-api-request';
import CompanyForm, { CompanyValue } from '../company-form/company-form';
import { toast } from 'react-toastify';
import { validateEmailOrEmpty } from '../../helpers/email-validation-helper';

export function CompanyCreate() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [formValues, setFormValues] = useState<CompanyValue>({
    email: [],
    phone: [],
    fax: [],
  });

  const customIdSuccess = 'custom-id-success';
  const customIdFailed = 'custom-id-Failed';
  const successToast = (successText: string) => {
    toast.success(successText, {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      toastId: customIdSuccess,
    });
  };

  const errorToast = (errorText: string) => {
    toast.error(errorText, {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      toastId: customIdFailed,
    });
  };

  const handleSubmit = (formValues: CompanyValue) => {
    const emails: ContactEmail[] = [];
    const phones: ContactPhone[] = [];
    const faxes: ContactFax[] = [];
    let mailValid = true;
    formValues.email.forEach((email) => {
      if (!validateEmailOrEmpty(email.inputValue.toLowerCase())) {
        mailValid = false;
      }
      emails.push({
        email: email.inputValue,
        default: email.default,
        type: email.selectValue,
      });
    });
    formValues.phone.forEach((phone) => {
      phones.push({
        phoneNumber: phone.inputValue,
        default: phone.default,
        type: phone.selectValue,
      });
    });
    formValues.fax.forEach((fax) => {
      faxes.push({
        faxNumber: fax.inputValue,
        default: fax.default,
        type: fax.selectValue,
      });
    });
    if (!mailValid) {
      errorToast(t('error-wrong-email'));

      return;
    }
    if (!formValues.name) {
      errorToast(t('error-required'));

      return;
    } else if (formValues.name) {
      if (!formValues.name.trim()) {
        errorToast(t('error-required'));
        return;
      }
    }
    sendApiRequest(
      `${environment.restEndpoint}/contact/contact-service/company/CreateCompany`,
      'POST',
      {
        distributor: '', //TODO: Get from looged user
        company: {
          name: formValues.name,
          isDealer: formValues.isDealer,
          address: {
            addressline1: formValues.address,
            street: formValues.street,
            zip: formValues.postcode,
            city: formValues.city,
            countrycode: formValues.countrycode,
          },
          email: emails,
          phone: phones,
          fax: faxes,
          foundingYear: formValues.foundingYear,
        } as Company,
      }
    )
      .then((response) => response.json())
      .then((data) => {
        successToast(t('success'));
        navigate(`/companies/view/${data.ID}`);
      })
      .catch((e) => errorToast(t('error') + e));
  };

  const handleCancel = () => {
    navigate(`/contacts/list/companies`);
  };

  return (
    <div className="grow bg-gray">
      <Headline className="p-8 pb-0" title={t('contact-create')} />
      <CompanyForm
        handleCancel={handleCancel}
        handleSubmit={handleSubmit}
        formValues={formValues}
      />
    </div>
  );
}

export default CompanyCreate;

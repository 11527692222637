import React, { FunctionComponent, LegacyRef, MouseEventHandler } from 'react';

interface TableHeaderProps {
  colSpan?: number;
  children?: React.PropsWithChildren<any>;
  customClassName?: string;
}

export const TableHeader: FunctionComponent<TableHeaderProps> = ({
  colSpan,
  children,
  customClassName,
}) => {
  return (
    <th
      className={customClassName ? customClassName : "m-0 first:px-2 px-6 py-4  text-left text-dark bg-blue-gray font-suzuki-bold"}
      colSpan={colSpan}
    >
      {children}
    </th>
  );
};

// const TableCell = styled.td`
//   &&& {
//     ${tw`m-0 p-1 text-left border-b border-lightgray`} :last-child {
//       border-right: 0;
//     }
//   }
// `;

export type TableCellProps = {
  children?: React.PropsWithChildren<any>;
  onClick?: () => void;
  customClassName?: string;
};

export const TableCell: FunctionComponent<TableCellProps> = ({
  children,
  onClick,
  customClassName,
}) => {
  return (
    <td
      onClick={onClick}
      className={customClassName ? customClassName : `m-0  first:px-2  px-6 py-5  text-left ${
        onClick ? 'cursor-pointer' : ''
      }`}
    >
      {children}
    </td>
  );
};

// const Pagination = styled.div`
//   ${tw`flex flex-row-reverse items-baseline pt-4`}
// `;

export const Pagination: FunctionComponent<any> = ({ children }: any) => {
  return <div className="flex flex-row-reverse items-baseline">{children}</div>;
};

// const ButtonArea = styled.div`
//   ${tw`flex items-baseline`}
// `;

export const ButtonArea: FunctionComponent<any> = ({ children }: any) => {
  return <div className="flex items-baseline">{children}</div>;
};

// const NavArea = styled.div`
//   ${tw`flex items-baseline mr-2`}
// `;

export const NavArea: FunctionComponent<any> = ({ children }: any) => {
  return <div className="flex items-baseline mr-2 pb-4">{children}</div>;
};

// const LabelArea = styled.div`
//   ${tw`text-base text-dark mb-2 font-suzuki-bold block`};
//   font-family: 'Arial', sans-serif;
// `;

export const LabelArea: FunctionComponent<any> = ({ children }: any) => {
  return (
    <div className="text-base text-dark mb-2 font-suzuki-bold block w-full">
      {children}
    </div>
  );
};

// const Loading = styled.div`
//   ${tw`w-full h-full bg-dark-gray absolute z-50 opacity-50 flex items-center justify-center`}
// `;

export const Loading: FunctionComponent<any> = ({ children }: any) => {
  return (
    <div className="w-full h-full bg-dark-gray absolute z-50 opacity-50 flex items-center justify-center">
      {children}
    </div>
  );
};

// const TableContentContainer = styled.div`
//   ${tw`relative`}
// `;
export type TableContentContainerProps = {
  children?: React.ReactNode;
};
export const TableContentContainer: FunctionComponent<
  TableContentContainerProps
> = ({ children }) => {
  return (
    <div className="relative bg-white shadow-md border-b border-dark-blue-gray">
      {children}
    </div>
  );
};

// const TableRow = styled.tr`
//   :nth-child(even) {
//     ${tw`bg-lightgray`}
//   }
//   :nth-child(odd) {
//     ${tw`bg-gray`}
//   }
//   :last-child {
//     td {
//       border-bottom: 0;
//     }
//   }
//   ${tw`py-2`}
// `;
export type TableRowProps = {
  children?: React.ReactNode;
  reference?: LegacyRef<HTMLTableRowElement> | undefined;
  customStyle?: string;
};

export const TableRow: FunctionComponent<TableRowProps> = ({
  children,
  reference,
  customStyle
}) => {
  return (
    <tr
      ref={reference}
      className={customStyle ? customStyle : "py-2 border-b last:border-b-0 border-black"}
    >
      {children}
    </tr>
  );
};

// const TTable = styled.table`
//   &&& {
//     font-family: 'Arial', sans-serif;
//     ${tw`w-full text-base`}
//     border-spacing: 0;
//   }
// `;

export interface TTableProps {
  children?: React.ReactNode;
}
export const TTable: FunctionComponent<TTableProps> = ({ children }) => {
  return <table className="w-full text-base border-collapse">{children}</table>;
};

// const TableHeader = styled.th`
//   &&& {
//     ${tw`m-0 p-2 text-left border-b-2 border-lightgray text-white bg-cyan`}
//     :last-child {
//       border-right: 0;
//     }
//   }
// `;

// const BasicField = styled('input')`
//   ${tw`border border-solid px-4 py-2 text-base text-dark outline-none mx-2 border-mediumgray`};
//   font-family: 'Arial', sans-serif;
//   box-sizing: border-box;
//   width: 6rem;
// `;
export type BasicFieldProps = {
  id?: string;
  type?: string;
  className?: string;
  value?: string | number | undefined;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLElement>) => void;
  onKeyUp?: (e: React.KeyboardEvent<HTMLElement>) => void;
  style?: string;
  width?: string;
};
export const BasicField: FunctionComponent<BasicFieldProps> = (props) => {
  return (
    <input
      className="w-24 box-border border border-solid px-4 py-2 text-base text-dark outline-none mx-2 border-mediumgray"
      type={props.type ? props.type : 'text'}
      id={props.id}
      value={props.value}
      onChange={props.onChange}
      placeholder={props.placeholder}
      onBlur={props.onBlur}
      onKeyDown={props.onKeyDown}
      onKeyUp={props.onKeyUp}
      style={props.width ? { width: props.width } : { width: '100%' }}
    />
  );
};

// const NavButton = styled('button')`
//   ${tw`text-base font-suzuki-bold p-3 text-white outline-none border-none p-2 text-white`};
//   font-size: 1.1rem;
//   font-family: 'Arial', sans-serif;
//   ${tw`active:bg-darkblueactive`};
//   ${(props) => (props.disabled ? tw`bg-darkgray` : tw`bg-darkblue`)}
// `;

export interface NavButtonProps {
  disabled?: boolean;
  styled?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement> | undefined;
  children?: React.ReactNode;
}

export const NavButton: FunctionComponent<NavButtonProps> = ({
  disabled,
  styled,
  onClick,
  children,
}) => {
  return (
    <button
      className={`outline-none border border-blue-gray w-10 h-10 text-xl ${
        styled ? 'text-white bg-blue-gray' : 'text-dark bg-white'
      }`}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

'use client';
import React, { useState } from 'react';
import { BasicTable, Button, ButtonStyle, Icon, Spinner } from '@sde/basic-ui-library';
import { useLocation, useNavigate } from 'react-router-dom';
import { ColumnDef } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { Invitation, InvitationsRequest } from '../../../models/invitation';
import { DataFetchProps } from "../../../models/others";
import { useSelector } from "react-redux";
import { dashboardFilterSelector } from "../../../store/slices/dashboard.slice";
import { monthNames } from "../crr-report-parser";
import { ServiceRecord, ServiceRecordsRequest } from '../../../models/service-record';
import { getDateFromQuery, getInspectionNumbersFromQuery, getRangeFromQuery } from "./query-utils";
import { toast } from "react-toastify";
import { sendApiRequest } from "../../../components/send-api-request/send-api-request";
import { base64StringToBlob } from "blob-util";
import download from "downloadjs";

export interface InspectionListProps<T extends Invitation | ServiceRecord> {
  data?: T[]
  count?: number
  columns: ColumnDef<T>[]
  fetchListData: ({pageSize, pageIndex, pageSortBy}: DataFetchProps) => Promise<void>
  csvUrl: string
  csvRequest: InvitationsRequest | ServiceRecordsRequest
}

function InspectionList<T extends Invitation | ServiceRecord>(props: InspectionListProps<T>) {
  const pageSize = 100;
  const {t} = useTranslation();
  const navigate = useNavigate();
  const dashboardFilterState = useSelector(dashboardFilterSelector);
  const location = useLocation();

  const date = getDateFromQuery(location)
  const range = getRangeFromQuery(location)
  const inspectionNumber = getInspectionNumbersFromQuery(location)
  const [loadingCSV, setLoadingCSV] = useState<boolean>(false)

  const errorToast = (errorText: string) => {
    toast.error(errorText, {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      toastId: 'excel-import-error',
    });
  };

  const downloadCSV = async () => {
    setLoadingCSV(true);
    sendApiRequest(
      props.csvUrl,
      'post',
      props.csvRequest
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error(response.status + ' ' + response.statusText);
        }
      })
      .then((data) => {
        const file = base64StringToBlob(
          data.content,
          'text/csv;charset=utf-8'
        );
        download(
          file,
          `report.csv`,
          'text/csv;charset=utf-8'
        );
      })
      .catch((e) => {
        errorToast(t('error') + e);
      })
      .finally(() => setLoadingCSV(false));
  };

  return (
    <div className="grow bg-gray w-full h-full p-6">
      <div className="ml-2">
        <div className="h-fit w-fit mb-4">
          <Button py="4" className="w-full mb-1" onClick={() => navigate(-1)}>
            <Icon iconName={'arrow_back'} className="mr-1"></Icon>
            {t('contact-details.back')}
          </Button>
        </div>
        <div className="flex justify-between">
          <div className={"flex flex-col"}>
            <span>
              {`${t('reports.crr.dealerCodes')} ${dashboardFilterState.dealerFilter.dealers.slice(0, 9).join(', ')}${dashboardFilterState.dealerFilter.dealers.length > 10 ? '...' : ''}`}
            </span>
            <span className='text-l font-suzuki-bold'>
            {`${t('reports.crr.planned')} ${range ? t('reports.crr.inspection-number.range.' + range) : t('reports.crr.inspection-number.title.' + inspectionNumber)}`}
              {date && `, ${monthNames[Number(date.split('-')[0]) - 1]} ${date.split('-')[1]}`}
            </span>
          </div>
          <div className={'flex flex-col gap-y-2 mb-4'}>
            <span>
            {`${t('reports.crr.dealer-count')} ${dashboardFilterState.dealerFilter.dealers.length}`}
            </span>
            <span className={'flex flex-row justify-end'}>
              {loadingCSV ? <Spinner/> : <Button
                buttonStyle={ButtonStyle.DEFAULT}
                onClick={downloadCSV}
              >
                {t('reports.crr.download.csv')}
              </Button>}
            </span>
          </div>
        </div>
      </div>
      <BasicTable<T>
        basic
        key="evaluation-list-table"
        initPageSize={pageSize}
        columns={props.columns}
        data={props.data ?? []}
        fetchData={props.fetchListData}
        rowCount={props.count ?? 0}
        countLabel={
          props.count && props.count === 1
            ? t('common.list.count.entry')
            : t('common.list.count.entries')
        }
      />
    </div>
  );
};

export default InspectionList;

import {
  BasicInput,
  BasicOutput,
  Button,
  ButtonStyle,
  DatePickerInput,
  Multiselect,
  MultiselectOptionsProps,
  SideNavigation,
} from '@suzuki-frontend-framework-update/ui';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectAllUserManagement } from '../../store/slices/user-management.slice';
import { Session } from '@ory/client';

export interface VehicleFormValue {
  vin?: string;
  make?: string;
  model?: string;
  type?: string;
  registrationDate?: Date;
  equipment?: string;
  registrationNumber?: string;
  color?: string;
  engineVolume?: number;
  fuel?: string;
  owner?: MultiselectOptionsProps[];
  modifiedBy?: string;
}

export interface DisabledInputs {
  vin?: boolean;
  make?: boolean;
  model?: boolean;
  type?: boolean;
  registrationDate?: boolean;
  equipment?: boolean;
  registrationNumber?: boolean;
  color?: boolean;
  engineVolume?: boolean;
  fuel?: boolean;
}

export type VehicleFormProps = {
  handleCancel: () => void;
  handleSubmit: (formValues: VehicleFormValue) => void;
  handleChange?: (prop: string, value: string) => void;
  handleDayChange?: (date: Date) => void;
  formValues: VehicleFormValue;
  disabledInputs?: DisabledInputs;
  dealerOptions?: MultiselectOptionsProps[];
};

export const VehicleForm = (props: VehicleFormProps) => {
  const { t } = useTranslation();
  const [formValues, setFormValues] = useState<VehicleFormValue>(
    props.formValues
  );

  useEffect(() => {
    setFormValues(props.formValues);
  }, [props.formValues]);

  const rowWidth = '70rem';
  const inputWidth = '20rem';
  const rightOffset = '34.5rem';

  const entities = useSelector(selectAllUserManagement);
  const [session, setSession] = useState({} as Session);

  useEffect(() => {
    const lastEntity = entities[entities.length - 1];
    if (lastEntity && lastEntity.currentUser) {
      setSession(lastEntity.currentUser);
    }
  }, [entities]);

  const handleChange = (prop: string, value: string) => {
    setFormValues({ ...formValues, [prop]: value });
  };

  const handleDayChange = (date: Date) => {
    setFormValues({ ...formValues, registrationDate: date });
  };

  const handleDealerChange = (selected: MultiselectOptionsProps[]) => {
    setFormValues({ ...formValues, owner: selected });
  };

  /* const showDealerList = (): JSX.Element => {
    let list = '';
    formValues.owner?.forEach((o) => {
      list += o.name + ' ';
    });
    return (
      <div>
        <label
          className={
            'text-base text-dark font-suzuki-bold block mr-4  whitespace-nowrap'
          }
        >
          {t('crm.dealer')}
        </label>
        <span>{list}</span>
      </div>
    );
  }; */

  return (
    <SideNavigation
      values={
        session?.identity?.traits?.isAdmin
          ? [
              { id: 'basic-data', title: t('vehicle-edit.basic-data.title') },
              {
                id: 'technical-data',
                title: t('vehicle-edit.technical-data.title'),
              },
              {
                id: 'dealer',
                title: t('crm.dealer'),
              },
            ]
          : [
              { id: 'basic-data', title: t('vehicle-edit.basic-data.title') },
              {
                id: 'technical-data',
                title: t('vehicle-edit.technical-data.title'),
              },
            ]
      }
      topOffset="13rem"
    >
      <div className="w-full flex flex-col gap-y-10 pr-4 pb-4">
        <div className="text-right flex flex-row gap-x-4 justify-end">
          <Button
            className="bg-white"
            buttonStyle={ButtonStyle.OUTLINE}
            onClick={props.handleCancel}
            px="5"
            py="3"
          >
            {t('vehicle-edit.cancel')}
          </Button>
          <Button onClick={() => props.handleSubmit(formValues)} px="5" py="3">
            {t('vehicle-edit.submit')}
          </Button>
        </div>
        <BasicOutput title={t('vehicle-edit.basic-data.title')} id="basic-data">
          <div
            className="flex flex-col items-end gap-y-10 p-5"
            style={{ width: rowWidth, paddingRight: rightOffset }}
          >
            <BasicInput
              readOnly={props.disabledInputs?.vin}
              onChange={(e) => handleChange('vin', e.target.value)}
              width={inputWidth}
              label={{
                name: t('vehicle-edit.basic-data.vin'),
                position: 'left',
              }}
              value={formValues.vin}
              required
            />
            <BasicInput
              readOnly={props.disabledInputs?.make}
              onChange={(e) => handleChange('make', e.target.value)}
              width={inputWidth}
              label={{
                name: t('vehicle-edit.basic-data.make'),
                position: 'left',
              }}
              value={formValues.make}
            />
            <BasicInput
              readOnly={props.disabledInputs?.model}
              onChange={(e) => handleChange('model', e.target.value)}
              width={inputWidth}
              label={{
                name: t('vehicle-edit.basic-data.model'),
                position: 'left',
              }}
              value={formValues.model}
            />
            <BasicInput
              readOnly={props.disabledInputs?.type}
              onChange={(e) => handleChange('type', e.target.value)}
              width={inputWidth}
              label={{
                name: t('vehicle-edit.basic-data.type'),
                position: 'left',
              }}
              value={formValues.type}
            />
            <DatePickerInput
              disabled={props.disabledInputs?.registrationDate}
              selectedDay={formValues.registrationDate}
              placeholder={
                formValues.registrationDate
                  ? formValues.registrationDate?.toDateString()
                  : t('contact-form.dropdownEmpty')
              }
              handleDateChange={handleDayChange}
              width={'w-80'}
              label={{
                name: t('vehicle-edit.basic-data.first-registration'),
                position: 'left',
              }}
            />
            <BasicInput
              readOnly={props.disabledInputs?.equipment}
              onChange={(e) => handleChange('equipment', e.target.value)}
              width={inputWidth}
              label={{
                name: t('vehicle-edit.basic-data.equipment'),
                position: 'left',
              }}
              value={formValues.equipment}
            />
            <BasicInput
              readOnly={props.disabledInputs?.registrationNumber}
              onChange={(e) =>
                handleChange('registrationNumber', e.target.value)
              }
              width={inputWidth}
              label={{
                name: t('vehicle-edit.basic-data.registration-number'),
                position: 'left',
              }}
              value={formValues.registrationNumber}
            />
          </div>
        </BasicOutput>
        <BasicOutput
          title={t('vehicle-edit.technical-data.title')}
          id="technical-data"
        >
          <div
            className="flex flex-col items-end gap-y-10 p-5"
            style={{ width: rowWidth, paddingRight: rightOffset }}
          >
            {/*<BasicInput*/}
            {/*  readOnly*/}
            {/*  onChange={(e) => props.handleChange('doors', e.target.value)}*/}
            {/*  width={inputWidth}*/}
            {/*  label={{*/}
            {/*    name: t('vehicle-edit.technical-data.doors'),*/}
            {/*    position: 'left',*/}
            {/*  }}*/}
            {/*  type="number"*/}
            {/*  value={props.formValues.doors}*/}
            {/*/>*/}
            <BasicInput
              readOnly={props.disabledInputs?.color}
              onChange={(e) => handleChange('color', e.target.value)}
              width={inputWidth}
              label={{
                name: t('vehicle-edit.technical-data.color'),
                position: 'left',
              }}
              value={formValues.color}
            />
            <BasicInput
              readOnly={props.disabledInputs?.engineVolume}
              onChange={(e) => handleChange('engineVolume', e.target.value)}
              width={inputWidth}
              label={{
                name: t('vehicle-edit.technical-data.engine-volume'),
                position: 'left',
              }}
              value={formValues.engineVolume}
            />
            <BasicInput
              readOnly={props.disabledInputs?.fuel}
              onChange={(e) => handleChange('fuel', e.target.value)}
              width={inputWidth}
              label={{
                name: t('vehicle-edit.technical-data.fuel'),
                position: 'left',
              }}
              value={formValues.fuel}
            />
          </div>
        </BasicOutput>
        {session?.identity?.traits?.isAdmin && (
          <BasicOutput title={t('crm.dealer')} id="dealer">
            <div
              className="flex flex-col items-end gap-y-10 p-5"
              style={{ width: rowWidth, paddingRight: rightOffset }}
            >
              {props.dealerOptions && session.identity?.traits.isAdmin && (
                <Multiselect
                  options={props.dealerOptions ? props.dealerOptions : []}
                  values={formValues.owner ? formValues.owner : []}
                  label={t('user-edit.dealer')}
                  width={20}
                  unit={'rem'}
                  displayToken={'name'}
                  onSelect={(selectedProps) =>
                    handleDealerChange(selectedProps)
                  }
                  onRemove={(selectedProps) =>
                    handleDealerChange(selectedProps)
                  }
                />
              )}
            </div>
          </BasicOutput>
        )}
        <div className="text-right flex flex-row gap-x-4 justify-end">
          <Button
            className="bg-white"
            buttonStyle={ButtonStyle.OUTLINE}
            onClick={props.handleCancel}
            px="5"
            py="3"
          >
            {t('vehicle-edit.cancel')}
          </Button>
          <Button onClick={() => props.handleSubmit(formValues)} px="5" py="3">
            {t('vehicle-edit.submit')}
          </Button>
        </div>
      </div>
    </SideNavigation>
  );
};

import Icon from '../icon/icon';
import BasicOutput from '../basic-output/basic-output';
import { ReactElement } from 'react';

export interface DataInfoItemValue {
  value: string | ReactElement;
  iconName?: string;
  value2?: string;
}

export interface DataInfoItem {
  name: string;
  name2?: string;
  values: DataInfoItemValue[];
}

interface DataInfoLinkItem {
  name: string;
  onClick: () => void;
}

export interface DataInfoContainerProps {
  items: DataInfoItem[];
  title?: string;
  textBeforeValues?: string | ReactElement;
  linksAfterValues?: DataInfoLinkItem[];
  className?: string;
  disableDefaultPadding?: boolean;
}

export const DataInfoContainer = (props: DataInfoContainerProps) => {
  return (
    <div className={`${props.className ? props.className : ''} ${props.disableDefaultPadding ? '' : 'pt-5'}`}>
      <BasicOutput styled title={props.title}>
        <div>
          {props.textBeforeValues && (
            <div className="mb-4">
              <span className="font-suzuki-bold">{props.textBeforeValues}</span>
            </div>
          )}
          {props.items.map((item, index) => {
            const itemKey = index + item.name + item.name2;
            return (
              <div key={itemKey} className={`${index !== 0 ? 'mt-4' : ''}`}>
                <div className="flex ">
                  <div className={`${item.name2 ? ' w-7/12' : 'w-full'}`}>
                    <div className="text-dark-gray mb-1">{item.name}</div>
                  </div>
                  {item.name2 && (
                    <div className="w-9/12">
                      <div className="text-dark-gray mb-1">{item.name2}</div>
                    </div>
                  )}
                </div>
                {item.values.map((v, i) => {
                  return (
                    <div className="flex" key={itemKey + i}>
                      <div className={`${v.value2 ? 'w-9/12' : 'w-full'}`}>
                        <div className="flex">
                          {v.iconName && (
                            <Icon iconName={v.iconName} className="mr-2" />
                          )}
                          <div className="w-[calc(100%-2.25rem)] break-words">
                            {v.value}
                          </div>
                        </div>
                      </div>
                      {v.value2 && (
                        <div className={item.name2 ? 'w-9/12' : 'w-3/12'}>
                          <div>{v.value2}</div>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            );
          })}
          {props.linksAfterValues && props.linksAfterValues.length > 0 && (
            <div className="flex mt-4">
              {props.linksAfterValues.map((link, i) => (
                <div
                  key={link.name + i}
                  className="uppercase underline cursor-pointer mr-4"
                  onClick={link.onClick}
                >
                  {link.name}
                </div>
              ))}
            </div>
          )}
        </div>
      </BasicOutput>
    </div>
  );
};

export default DataInfoContainer;

import {TestDriveData, TestDriveStatus} from "../../models/test-drive";
import React from "react";
import {useTranslation} from "react-i18next";
import {TextLine} from "../text-line/text-line";

export interface TestDriveResponseReadOnlyProps {
  testDrive: TestDriveData;
}

const TestDriveResponseReadOnlyBody = ({testDrive}: TestDriveResponseReadOnlyProps) => {
  const {t} = useTranslation();
  const {status, response} = testDrive;

  return (
    <div className="flex flex-col gap-y-2">
      <span>{response?.testDrivePerformed ? t('communication.test-drive.report.modals.radio.TRUE') : t('communication.test-drive.report.modals.radio.FALSE')}</span>
      {status !== TestDriveStatus.FINISHED_NOT_CARRIED_OUT &&
        <>
          <span>{t(`communication.test-drive.report.modals.radio.${response?.purchaseOfVehicle.purchasedVehicle}`)}</span>
          {status !== TestDriveStatus.FINISHED_CARRIED_OUT_NOT_BOUGHT && <span>{t(`communication.test-drive.report.modals.radio.${response?.interestedInVehicle}`)}</span>}
        </>
      }
    </div>
  );
}

export const TestDriveResponseReadOnly = ({testDrive}: TestDriveResponseReadOnlyProps) => {
  const {t} = useTranslation();
  const {status} = testDrive;
  const mainComponentAutoClass = "grid grid-cols-[1fr_2fr] ps-5";
  const mainComponentMotoClass = "grid grid-cols-4 ps-5";

  return (
    <div className={testDrive.isMotorcycle ? mainComponentMotoClass : mainComponentAutoClass}>
      <div className="flex flex-col">
        <span className="text-base text-dark font-suzuki-bold mr-2 whitespace-nowrap uppercase">
            {t('communication.test-drive.report.modals.response_label_not_required')}
        </span>
        <div className="flex flex-row py-2 gap-x-4">
          <div className="flex flex-col gap-y-2">
            <span>{t('communication.test-drive.report.modals.response.performed')}</span>
            {status !== TestDriveStatus.FINISHED_NOT_CARRIED_OUT &&
              <>
                <span>{t('communication.test-drive.report.modals.response.purchase')}</span>
                {status !== TestDriveStatus.FINISHED_CARRIED_OUT_NOT_BOUGHT && <span>{t('communication.test-drive.report.modals.response.interested')}</span>}
              </>
            }
          </div>
          <TestDriveResponseReadOnlyBody testDrive={testDrive} />
        </div>
      </div>
      {status === TestDriveStatus.FINISHED_CARRIED_OUT_BOUGHT &&
        <div className="-ml-2">
          <TextLine
            labelText={t('communication.test-drive.report.modals.response.purchase-vin')}
            value={testDrive?.response?.purchaseOfVehicle.purchasedVehicleVin}
          />
        </div>
      }
    </div>

  );
}

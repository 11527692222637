import { Button, Icon } from "@suzuki-frontend-framework-update/ui";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export function ForgetPasswordSuccess() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (<>
    <div className="text-black text-center w-11/12 pt-8">
      <Icon iconName="task_alt" className="text-green-500" style={{ fontSize: '5rem' }} />
      <div>
      {t('forget-password.success')}
      </div>
    </div>
    <div className="w-11/12">
      <Button
        py='4'
        className="w-full mb-1"
        onClick={() => navigate('/login')}
      >{t('forget-password.back-to-login')}</Button>
    </div>
  </>);
}

export default ForgetPasswordSuccess;
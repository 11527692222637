export const de = {
  menu: {
    contacts: 'KONTAKTE',
    vehicles: 'FAHRZEUGE',
    communication: 'KOMMUNIKATION',
    reports: 'REPORTS',
    help: 'HILFE',
    'survey-tool': 'SURVEY TOOL',
    settings: 'Einstellungen',
    logout: 'Ausloggen',
  },
  footer: {
    downloads: 'DOWNLOADS',
    support: 'SUPPORT',
  },
  common: {
    list: {
      count: {
        entry: 'Eintrag',
        entries: 'Einträge',
      },
    },
  },
  login: {
    headline: 'CRM by',
    information:
      'Geben Sie Ihren Benzutzernamen und Ihr Passwort ein um sich anzumelden.',
    error: {
      empty: {
        username: 'Bitte füllen Sie dieses Feld aus.',
        password: 'Bitte füllen Sie dieses Feld aus.',
      },
      expired:
        'Der Anmeldevorgang hat zu lange gedauert. Bitte laden Sie die Seite neu.',
      sso: {
        malformed:
          'Das System hat inkorrekte Daten gesenedet bitte versuchen sie es erneut.',
        network:
          'Es wurden Netzwerk-Probleme festgestellt. Bitte versuchen sie den Vorgang erneut.',
        ssoproblems:
          'Es gab einen Fehler bei der Authentifizierung. error code suz-sso-01',
        expired:
          'Der Anmeldevorgang hat zu lange gedauert. Bitte laden Sie die Seite erneut.',
        someoneelseloggedin:
          'Eine andere Person ist auf diesem Gerät eingeloggt. Sie werden nun ausgelogged und auf die Startseite weitergeleitet.',
        ssoTicketInvalid:
          'Das Ticket mit dem Sie versuchen sich einzuloggen kann vom DCS nicht validiert werden.',
      },
    },
    username: 'BENUTZERNAME / E-MAIL',
    password: 'PASSWORT',
    signIn: 'ANMELDEN',
    forgotPassword: 'PASSWORT VERGESSEN?',
    'The provided credentials are invalid, check for spelling mistakes in your password or username, email address, or phone number.':
      'Die Zugangsdaten sind nicht gültig. Bitte prüfen Sie Benutzername / E-Mail oder Passwort auf Schreibfehler. Alternativ können Sie Ihr Passwort über den "Passwort vergessen?" Link zurücksetzen.',
  },
  'set-password': {
    'missing-field': 'Bitte füllen Sie alle Felder aus!',
    'not-identical': 'Das Passwort ist nicht identisch in beiden Feldern!',
    information: 'Bitte setzen Sie ein neues Passwort',
    password: 'Passwort',
    'repeated-password': 'Passwort wiederholen',
    send: 'Neues Passwort setzen',
    'policy-rules':
      'Bitte geben Sie ein Passwort mit mindestens 8 Zeichen ein.',
    'password-length-not-enough':
      'Das Passwort kann nicht verwendet werden, es muss mindestens 8 Zeichen enthalten.',
    'The password can not be used because password length must be at least 8 characters but only got 1.':
      'Das Passwort kann nicht verwendet werden, es muss mindestens 8 Zeichen enthalten.',
    'The password can not be used because password length must be at least 8 characters but only got 2.':
      'Das Passwort kann nicht verwendet werden, es muss mindestens 8 Zeichen enthalten.',
    'The password can not be used because password length must be at least 8 characters but only got 3.':
      'Das Passwort kann nicht verwendet werden, es muss mindestens 8 Zeichen enthalten.',
    'The password can not be used because password length must be at least 8 characters but only got 4.':
      'Das Passwort kann nicht verwendet werden, es muss mindestens 8 Zeichen enthalten.',
    'The password can not be used because password length must be at least 8 characters but only got 5.':
      'Das Passwort kann nicht verwendet werden, es muss mindestens 8 Zeichen enthalten.',
    'The password can not be used because password length must be at least 8 characters but only got 6.':
      'Das Passwort kann nicht verwendet werden, es muss mindestens 8 Zeichen enthalten.',
    'The password can not be used because password length must be at least 8 characters but only got 7.':
      'Das Passwort kann nicht verwendet werden, es muss mindestens 8 Zeichen enthalten.',
    'The password can not be used because the password has been found in data breaches and must no longer be used.':
      'Das Passwort kann nicht verwendet werden, da es bei Datenverstößen gefunden wurde und nicht mehr verwendet werden darf.',
  },
  'forget-password': {
    information:
      'Bitte geben Sie Ihre E-Mail Adresse ein und klicken Sie auf "Passwort Zurücksetzen". Sie erhalten dann weitere Anweisungen zum Zurücksetzen des Passworts an Ihre E-Mail Adresse.',
    username: 'E-MAIL Adresse',
    empty: 'Bitte füllen Sie dieses Feld aus.',
    'invalid-email': 'Email ist ungültig.',
    reset: 'PASSWORT ZURÜCKSETZEN',
    'back-to-login': 'ZURÜCK ZUM LOGIN',
    success:
      'Wenn ein Nutzer mit der angegebenen E-Mail existiert, wurden Ihnen Instruktionen zum Zurücksetzen des Passwort zugeschickt. Bitte folgen Sie den Anweisungen in der E-Mail.',
  },
  '2fa': {
    information: 'Bitte geben Sie den Code aus Ihrer Authenticator App ein',
    code: 'Code',
    empty: 'Bitte füllen Sie dieses Feld aus.',
    'invalid-code': 'Code ist ungültig.',
    confirm: 'BESTÄTIGEN',
    cancel: 'ABBRECHEN',
  },
  'user-config': {
    'users-groups': 'Nutzer und Gruppen',
    'registered-users': 'Registrierte Nutzer',
    'logged-users': 'Angemeldete Nutzer',
    groups: 'Gruppen',
    policies: 'Policies',
    dealers: 'Händler',
    'imported-dealers': 'Importierte Händler',
    'contact-pools': 'Kontakt Pools',
    'pools-list': 'Liste der Kontaktpools',
  },
  'user-edit': {
    title: 'Nutzer bearbeiten',
    'basic-data': 'Basisdaten',
    registration: 'Registrierung',
    username: 'Benutzername',
    firstname: 'Vorname',
    lastname: 'Nachname',
    email: 'E-Mail',
    isAdmin: 'Admin',
    isAreaAdmin: 'ASM',
    dealer: 'Händler',
    cancel: 'Abbrechen',
    submit: 'Absenden',
  },
  'user-list': {
    title: 'Registrierte Nutzer',
    'add-user': 'Neuen Nutzer registrieren',
    edit: 'Nutzer bearbeiten',
    delete: 'Nutzer löschen',
    column: {
      username: 'Benutzername',
      name: 'Vollständiger Name',
      email: 'E-Mail',
      groups: 'Zugeordnete Gruppen',
      actions: 'Aktionen',
      edit: 'Bearbeiten',
      delete: 'Löschen',
      isAdmin: {
        column: 'Admin',
        yes: 'Nutzer ist Administrator',
        no: 'Nutzer ist kein Administrator',
      },
      isAreaAdmin: {
        column: 'ASM',
        yes: 'Der Nutzer ist ein ASM/DSM',
        no: 'Der Nutzer ist kein ASM/DSM',
      },
    },
    filter: {
      search: 'Username, Name, Email...',
    },
  },
  contacts: {
    contacts: 'Kontakte',
    create: 'Hinzufügen',
    hide: 'Kontakt ausblenden',
    'people-companies-item': 'Personen / Firmen',
    'import-item': 'Import',
    import: {
      'excel-import-success': 'Kontakte erfolgreich importiert',
      'excel-import-wrong-format':
        'Falsches Dateiformat. Unterstützte Formate: xlsx, xlsm, xltm, zip.',
      'download-template': 'Vorlage herunterladen',
      'manual-data-import': 'Manueller Import',
      'excel-import-result': 'Ergebnis des manuellen Datenimports',
      row: 'Reihe',
      salutation: 'Anrede',
      dealerNumber: 'Händlernummer',
      consentDate: 'Einwilligungsdatum',
      result: 'Ergebnis',
      error: {
        'consent-date': 'Ungültiges Einwilligungsdatum',
        dealer: 'Ungültige Händlernummer',
        'empty-required-field': 'Leeres Pflichtfeld',
        'contact-saving': 'Kontakt konnte nicht gespeichert werden',
        dpd: 'Einverständniseintrag konnte nicht hinzugefügt werden',
      },
      failed: 'Importieren fehlgeschlagen: ',
      imported: 'Reihe importiert',
      'with-errors': ' mit Fehlern: ',
      'without-errors': ' ohne Fehler',
      'open-saved': 'Gespeicherten Kontakt öffnen',
    },
    'people-companies': {
      title: 'Personen und Firmen',
      people: 'Personen',
      companies: 'Firmen',
      list: 'Liste',
      name: 'Name',
      additional: 'Ansprechpartner: ',
      address: 'Adresse',
      email: 'E-Mail',
      phone: 'Telefon',
      vehicle: 'Fahrzeuge',
      filter: {
        search: 'Name, Vorname, Adresse...',
        origin: {
          title: 'Herkunft',
          salesImport: 'Sales Import',
          guaranteeImport: 'Garantie Import',
          serviceMessage: 'Servicemeldung',
          newsletterReg: 'Newsletter Registrierung',
          testDrive: 'Probefahrt Anfrage',
          contactRequest: 'Kontaktanfrage',
        },
        consent: {
          title: 'Datenfreigabe',
          postYes: 'Post Ja',
          postNo: 'Post Nein',
          postUnknown: 'Post Unbekannt',
          emailYes: 'E-Mail Ja',
          emailNo: 'E-Mail Nein',
          emailUnknown: 'E-Mail Unbekannt',
          phoneYes: 'Telefon/Mobil Ja',
          phoneNo: 'Telefon/Mobil Nein',
          phoneUnknown: 'Telefon/Mobil Unbekannt',
          maFoYes: 'MaFo Ja',
          maFoNo: 'MaFo Nein',
          maFoUnknown: 'MaFo Unbekannt',
          messengerYes: 'Messenger Ja',
          messengerNo: 'Messenger Nein',
          messengerUnknown: 'Messenger Unbekannt',
        },
        status: {
          title: 'Status',
          client: '"S-K" (Kunde)',
          prospect: '"S-I" (Interessent)',
          'lost-customer': '"S-LC" (Lost Customer)',
        },
        deliverability: {
          title: 'Zustellbarkeit',
          yes: 'Ja',
          no: 'Nein',
          unknown: 'Unbekannt',
        },
        'vehicle-type': 'Fahrzeugtyp',
        address: {
          title: 'Adresse',
          'with-address': 'Kontakt(e) mit vollständiger Adresse',
          'without-address': 'Kontakt(e) ohne (vollständige) Adresse',
        },
        'hidden-contacts': {
          title: 'Ausgeblendete Kontakte',
          'show-contacts': 'Ausgeblendete(n) Kontakt(e) anzeigen',
        },
      },
      'unhide-button': {
        title: 'Kontakt(e) nicht mehr ausblenden',
        'no-contacts-selected': 'Kein(e) Kontakt(e) ausgewählt',
        'contacts-unhiding-success':
          'Alle ausgewählten Kontakte werden jetzt eingeblendet.',
        'contacts-unhiding-partial-success':
          'Es konnten nicht alle Kontakte eingeblendet werden. Anzahl der nicht eingeblendeten Kontakte:',
        'contacts-unhiding-failure':
          'Das Einblenden der Kontakte ist fehlgeschlagen.',
      },
    },
    'dpd-report': {
      title: 'Einwilligungsbericht',
      'title-contact': 'Personen Einwilligungsbericht',
      'title-company': 'Firmen Einwilligungsbericht',
      customer: 'Kunde',
      post: 'Post',
      email: 'E-Mail',
      telephone: 'Telefon',
      mobilephone: 'Mobiltelefon',
      sms: 'SMS',
      fax: 'Fax',
      messenger: 'Messenger',
      maFo: 'MaFo',
      'daten-weitergabe': 'Daten-Weitergabe',
      unknown: 'Unbekannt',
      true: 'Ja',
      false: 'Nein',
      available: 'Verfügbar',
      required: 'Erforderlich',
      value: 'Freigabe',
      declaration: 'Erklärung',
      'document-status': 'Dokumentstatus',
      dealernumber: 'Händlernummer',
      asm: 'ASM/AMA',
      dsm: 'DSM/AMA',
      'export-csv': 'CSV-Export',
    },
    'hidden-contact': {
      'hidden-for-a-reason':
        'Der gesuchte Datensatz wurde mit Angabe des folgenden Grundes gelöscht:',
      questions: 'Bei Fragen wenden Sie sich bitte an die CRM Hotline.',
    },
  },
  'contact-details': {
    'not-specified': 'keine Angabe',
    'created-at': 'Angelegt am {0} von {1}',
    'modified-at': 'Zuletzt bearbeitet am {0} von {1}',
    back: 'ZURÜCK',
    'about-contact': 'Über diesen Kontakt',
    isDealer: 'Kontakt ist Händler',
    number: 'Kundennummer',
    address: 'Adresse',
    phone: 'Telefon',
    email: 'E-Mail',
    birthday: 'Geburtstag',
    company: 'Firma',
    customer: 'Kunde',
    customerMale: 'Kunde',
    customerFeamle: 'Kundin',
    prospect: 'Interessent',
    'lost-customer': 'ehem. Kunde',
    'founding-year': 'Gründungsjahr',
    'days-to-birthday': 'Der Kontakt hat in {0} Tagen Geburtstag',
    'birthday-today': 'Der Kontakt hat heute Geburtstag',

    'household-data': {
      title: 'Haushaltsdaten',
      'persons-at-home': 'Personen im Haushalt',
      job: 'Beruf',
      'marital-status': 'Familienstand',
      leisureaction: 'Freizeitaktivitäten',
      sports: 'Sportarten',
    },
    vehicles: {
      title: 'Fahrzeuge',
      hide: 'Fahrzeug ausblenden',
      vin: 'Fahrgestellnummer',
      'first-registration': 'Registrierungsdatum',
      ownership: 'Eigentumsverhältnis',
      'more-information': 'MEHR INFORMATIONEN',
    },
    'company-contact': {
      title: 'Ansprechpartner',
      'more-information': 'MEHR INFORMATIONEN',
    },
    actions: 'Aktionen',
    origin: 'Herkunft:',
    buttons: {
      tooltip: {
        'edit-contact': 'Kontakt bearbeiten',
        dpd: 'Datenschutzrechtliche Einwilligungserklärung',
        'add-action': 'Aktion zu Kontakt hinzufügen',
      },
    },
    dpd: {
      post: 'Post',
      email: 'E-Mail',
      sms: 'SMS/MMS',
      telephone: 'Telefon',
      mobilephone: 'Mobiltel',
      fax: 'Fax',
      mare: 'MaFo',
      'no-releases': 'keine Freigabe',
      private: 'Privat',
      business: 'Dienstlich',
      title: 'Datenfreigabe',
      'new-entry': 'Freigabe Erfassen',
      'show-history': 'Historie anzeigen',
    },
    history: {
      title: 'Kontakthistorie',
      editor: 'Bearbeitet von {0}',
      value: '{0} geändert von {1} zu {2}',
      'no-data': 'Keine Änderungshistorie',
      attribute: {
        salutation: 'Kunden-Anrede',
        title: 'Titel',
        firstname: 'Vorname',
        name: 'Nachname',
        deliverable: 'Zustellbar',
        street: 'Straße',
        zip: 'PLZ',
        city: 'Ort',
        countrycode: 'Landesvorwahl',
        email: 'E-mail',
        slc_email: 'SLC E-mail',
        slc_sms_business: 'SLC SMS-Geschäfte',
        slc_fax_business: 'SLC Faxgeschäft',
        slc_mobile: 'SLC Mobil',
        modified: 'geändert',
        slc_fax: 'SLC FAX',
        securitylawcode: 'Sicherheitsgesetzbuch',
        channel: 'Kanal',
        import_module: 'Modul importieren',
        slc_last_update: 'SLC Letztes Update',
        slc_post_business: 'SLC Postgeschäft',
        dms_xml: 'DMS-xml',
        mcid: 'MC-ID',
        id: 'ID',
        mvg_owner: 'MVG-Eigentümer',
        bookable_rules_version: 'Buchbare Regelversion',
        customerstate: 'Kundenstatus',
        created: 'erstellt',
        active: 'aktiv',
        dealercode: 'Händlercode',
        classification_id: 'Klassifizierungs-ID',
        origin: 'Herkunft',
        bookable: 'buchbar',
        duplicate_group_id: 'Doppelte Gruppen-ID',
        companybranch_id: 'Firmenzweig-ID',
        new_dms_created: 'Neues DMS erstellt',
        districtareacode: 'Ortsvorwahl des Bezirks',
        slc_editor: 'SLC-Editor',
        initial_import_module: 'Erstimportmodul',
        initial_origin: 'Anfänglicher Ursprung',
        initial_channel: 'Anfangskanal',
        new_dms_modified: 'Neues DMS modifiziert',
        phone: 'Telefon',
        index_update_uid: 'UID für Indexaktualisierung',
        slc_sms: 'SLC SMS',
        slc_phone: 'SLC Telefon',
        slc_mobile_business: 'SLC Mobile-Geschäft',
        securitylawcodelocked: 'Code des Sicherheitsgesetzes gesperrt',
        slc_phone_business: 'SLC Telefongeschäft',
        slc_email_business: 'SLC E-Mail-Geschäft',
        slc_mare_business: 'SLC Mare-Geschäft',
        slc_mare: 'SLC Mare',
        birthyear: 'Geburtsjahr',
        familystatus_id: 'Familienstatus-ID',
        title_id: 'Titel-ID',
        function_id: 'Funktions-ID',
        person_id: 'Personen-ID',
        childsathome1318: 'Kinderzuhause1318',
        childsathome612: 'Kinderzuhause612',
        childsathome: 'Kinderzuhause',
        childsathome05: 'Kinderzuhause05',
        personsathome: 'Personenzuhause',
        company_id: 'Firmen-ID',
        disabling_reason_description: 'Beschreibung des Deaktivierungsgrunds',
        disabling_reason_type: 'Art des Deaktivierungsgrunds',
        bookable_reason: 'Buchbarer Grund',
        disabling_editor: 'Editor deaktivieren',
        disabling_date: 'Datum der Deaktivierung',
        fax: 'Fax',
        web: 'Webseite',
        birthdate: 'Geburtstag',
      },
    },
    'hidden-vehicles': {
      title: 'Ausgeblendete Fahrzeuge',
      'show-vehicles': 'Ausgeblendete(s) Fahrzeug(e) anzeigen',
    },
    'unhide-button': {
      title: 'Fahrzeug(e) nicht mehr ausblenden',
      'no-contacts-selected': 'Kein(e) Fahrzeug(e) ausgewählt',
      'vehicles-unhiding-success':
        'Alle ausgewählten Fahrzeuge werden jetzt eingeblendet.',
      'vehicles-unhiding-partial-success':
        'Es konnten nicht alle Fahrzeuge eingeblendet werden. Anzahl der nicht eingeblendeten Fahrzeuge:',
      'vehicles-unhiding-failure':
        'Das Einblenden der Fahrzeug ist fehlgeschlagen.',
    },
  },
  'contact-form': {
    'edit-contact': 'Kontakt bearbeiten',
    'personal-data': 'Personenbezogende Daten',
    'kontakt-data': 'Kontaktmöglichkeiten',
    'household-data': 'Haushaltsdaten',
    registration: 'Registrierung',
    gender: 'GESCHLECHT',
    title: 'TITEL',
    'title-placeholder': 'Dr., Prof., Dipl.',
    firstname: 'VORNAME',
    surname: 'NACHNAME',
    name: 'NAME',
    birthday: 'GEBURSTSDATUM',
    street: 'STRASSE/HAUSNUMMER',
    address: 'ADRESSZUSATZ',
    postcode: 'PLZ',
    city: 'ORT',
    country: 'LAND',
    email: 'E-MAIL',
    phone: 'TELEFON',
    fax: 'FAX',
    personsInHousehold: 'PERSONEN IM HAUSHALT',
    job: 'BERUF',
    maritalStatus: 'FAMILIENSTAND',
    sports: 'SPORTARTEN',
    activities: 'FREIZEITAKTIVITÄTEN',
    vin: 'VIN',
    company: 'FIRMA',
    cancel: 'ABBRECHEN',
    submit: 'KONTAKT SPEICHERN',
    dropdownEmpty: 'bitte wählen',
    errors:{
      'error-vin': 'VIN konnte nicht im System gefunden werden!',
      'wrong-email': 'Bitte eine gültige E-Mail-Adresse eingeben!',
      'error-required': 'Bitte füllen Sie alle erforderlichen Felder aus',
      'name-required': 'Bitte den Vor- und Nachnamen eingeben.',
      'dealer-required': 'Bitte einen Händler auswählen.',
      'phone-required': 'Bitte eine Telefonnummer eingeben.',
      'email-or-adress-required': 'Bitte entweder eine E-Mail Adresse oder eine vollständige Adresse eingeben.',
      'invalid-street': 'Bitte eine vollständige Straße eingeben!',
    },
    success: 'erfolgreich gespeichert',
    error: 'Es ist ein Fehler aufgetreten. Bitte kontaktieren Sie den Support:  ',
  },
  gender: {
    Herr: 'Herr',
    Frau: 'Frau',
    Divers: 'Divers',
  },
  'device-type': {
    private: 'privat',
    mobile: 'mobil',
    work: 'geschäftlich',
    business: 'geschäftlich',
  },
  'marital-status': {
    NOCOMMENT: 'keine Angabe',
    MARRIED: 'verheiratet',
    SINGLE: 'ledig/allein lebend',
    LIVINGTOGETHER: 'zusammen lebend',
    DIVORCED: 'geschieden/getrennt lebend',
    WIDOWED: 'verwitwet',
  },

  'sports-list': {
    NOCOMMENT: 'keine Angabe',
    SOCCER: 'Fußball',
    'SAILING-WINDSURFING': 'Segeln/Windsurfen',
    'SKIING-SNOWBOARDING': 'Skilaufen/Snowboarden',
    TENNIS: 'Tennis',
    SWIMMING: 'Schwimmen',
    SQUASH: 'Squash',
    GYM: 'Fitness-Studio',
    'INLINE-SKATING': 'Inlineskating',
    'JOGGING-WALKING': 'Jogging/Walking',
    'CYCLING-MOUNTAINBIKING': 'Fahrrad fahren/Mountainbike',
    GOLF: 'Golf',
    MOTORSPORTS: 'Motorsport',
    OTHER: 'Sonstiges',
  },
  'activities-list': {
    NOCOMMENT: 'keine Angabe',
    DIY: 'Heimwerken',
    'VISITING-CULTURAL-EVENTS': 'Besuch kultureller Veranstaltungen',
    GARDENING: 'Gartenarbeit',
    'VISITING-THE-CINEMA': 'Kinobesuche',
    TRAVEL: 'Reisen',
    TELEVISION: 'Fernsehen',
    READING: 'Lesen',
    'ARTISTIC-WORK': 'Künstlerische Arbeiten',
    MUSIC: 'Musik',
    OTHER: 'Sonstiges',
  },
  'contact-create': 'Kontakt hinzufügen',
  'vehicle-create': 'Fahrzeug hinzufügen',
  'vehicle-create-action': {
    title: 'SCRM - manuelle Fahrzeuganlage ({{dealerCode}})',
    'title-without-dealerCode': 'SCRM - manuelle Fahrzeuganlage',
  },
  'vehicle-edit': {
    title: 'Fahrzeug bearbeiten',
    'basic-data': {
      title: 'Basisdaten',
      vin: 'VIN / FIN',
      make: 'MARKE',
      model: 'MODEL',
      type: 'TYP',
      'first-registration': 'ERSTZULASSUNGSDATUM',
      equipment: 'AUSTATTUNGSLINIE',
      'registration-number': 'KENNZEICHEN',
    },
    'technical-data': {
      title: 'Technische Daten',
      doors: 'ANZAHL DER TÜREN',
      color: 'Fahrzeugfarbe',
      'engine-volume': 'HUBRAUM',
      fuel: 'KRAFTSTOFF',
    },
    cancel: 'ABBRECHEN',
    submit: 'FAHRZEUG SPEICHERN',
  },
  'vehicle-details': {
    'created-at': 'Angelegt am {0}',
    back: 'Zurück',
    'about-section': {
      title: 'Über dieses Fahrzeug',
      vin: 'Fahrgestellnummer',
      make: 'Marke',
      model: 'Modell',
      modelcode: 'Sales Modell Code',
      type: 'Typ',
      equipment: 'Ausstattungslinie',
      'first-registration': 'Erstzulassung',
      sign: 'Kennzeichen ',
      visibility: 'Sichtbarkeit',
    },
    'mileage-section': {
      title: 'Kilometerstände',
      state: 'Stand',
      date: 'Datum',
      origin: 'Herkunft',
    },
    actions: 'Aktionen',
    'registrations-section': {
      title: 'Fahrzeughalter Registrierungen',
      number: 'Kundennummer',
      'more-information': 'MEHR INFORMATIONEN',
    },
    'more-data-section': {
      title: 'Weitere Daten',
      doors: 'Anzahl Türen',
      'body-color': 'Fahrzeugfarbe',
      capacity: 'Hubraum',
      fuel: 'Kraftstoffart/Motor',
    },
    buttons: {
      tooltip: {
        'edit-vehicle': 'Fahrzeug bearbeiten',
        'add-action': 'Aktion zu Kontakt hinzufügen',
      },
    },
    history: {
      title: 'Fahrzeughistorie',
      editor: 'Bearbeitet von {0}',
      value: '{0} geändert von {1} zu {2}',
      'no-data': 'Keine Änderungshistorie',
      attribute: {
        vin: 'VIN',
        brand: 'Marke',
        'model-name': 'Modellname',
        'model-type': 'Modelltyp',
        'model-code': 'Sales Modell Code',
        'first-registration': 'Erstzulassungsdatum',
        'registration-date': 'Registrierungsdatum',
        'license-number': 'Kennzeichen',
        // mileage: 'Kilometerstände',
        // 'date-mileage-update': 'Datum der Kilometerstände Aktualisieren',
        origin: 'Herkunft',
        owner: 'Halter',
        'vehicle-type': 'Fahrzeugtyp',
        'vehicle-status': 'Fahrzeugstatus',
      },
    },
  },
  vehicles: {
    title: 'Fahrzeuge',
    brand: 'Marke',
    model: 'Modellname ',
    modelcode: 'Sales Modell Code ',
    vin: 'VIN/FIN',
    first_registration_date: 'Erstzulassungsdatum',
    owner: 'Halter',
    hide: 'Fahrzeug ausblenden',
    deactivate: 'Fahrzeug löschen',
    filter: {
      search: 'VIN, Marke, Modell',
      'register-date': 'Registrierungsdatum',
      brand: 'Marke',
      model: 'Modell',
      type: 'Fahrzeugtyp',
      'deactivated-vehicles': {
        title: 'Gelöschte Fahrzeuge',
        name: 'Gelöschte(s) Fahrzeug(e) anzeigen',
      },
      registration_date: {
        title: 'Erstzulassungsdatum',
      },
      models: 'Modelle',
      model_other_option: 'Andere Modelle'
    },
    export_csv: {
      button: 'CSV-Export',
      toast: {
        unexpected_error: 'Es gab einen unerwarteten Fehler beim Export der Daten. Bitte versuchen Sie es später erneut. Sollte der Fehler wiederholt auftreten, wenden Sie sich bitte an die Händler-Hotline. Wir entschuldigen uns für den Fehler.',
      },
      popup: {
        title: 'Export-Begrenzung',
        description: 'Der Kontaktexport ist auf 5.000 Kontakte beschränkt. Die aktuelle Auswahl überschreitet die maximale Datensatzanzahl. Möchten Sie fortfahren und nur die ersten 5.000 Kontakte exportieren?',
        no: 'Export abbrechen',
        yes: 'Export durchführen'
      }
    }
  },
  communication: {
    title: 'Kommunikation',
    'inspections-invitations': {
      title: 'Einladungen versenden',
      sideTitle: 'Inspektionseinladung versenden',
      'send-invitations': 'Einladungen versenden',
      'no-contacts-selected': 'Kein(e) Kontakt(e) ausgewählt',
      'confirm-count':
        'Wenn Sie bestätigen werden {0} Kontakte per E-Mail zur Inspektion eingeladen.',
      'confirm-count-cars':
        'Sie haben {0} Kontakt(e) für die Inspektionseinladung per Brief ausgewählt.',
      'email-preview': 'Die E-Mail an die Kontakte sieht wie folgt aus:',
      'confirm-continue': 'Möchten Sie fortfahren?',
      'send-email': 'Ja, eine E-Mail verschicken',
      'send-emails': 'Ja, {0} E-Mails verschicken',
      'send-letter': 'Ja, eine Inspektionseinladung per Brief bestellen',
      'send-letters': 'Ja, {0} Inspektionseinladung(en) per Brief bestellen',
      'sent-invitations': 'Gesendete Einladungen',
      'send-invitation': 'Einladung versenden',
      'resend-invitation': 'Einladung erneut versenden',
      'send-invitation-title': 'Inspektionseinladung versenden',
      'resend-invitation-title': 'Inspektionseinladung versenden',
      'invitation-confirmation-description':
        'Die Kontakte werden in den nächsten Minuten per E-Mail benachrichtigt!',
      'invitation-confirmation-description-cars':
        'Die Inspektionseinladung(en) wurde(n) erfolgreich bestellt.',
      'invitation-confirmation-send-date':
        'Der nächste Versand erfolgt am {0}. ',
      'invitation-confirmation-close': 'OK',
      motorcycles: 'Motorrad',
      scooters: 'Scooter',
      placeholder: 'Platzhalterwert',
      columns: {
        contact: 'Kontakt',
        email: 'E-Mail ',
        model: 'Modell',
        vin: 'VIN / FIN',
        'license-plate': 'Kennzeichen ',
        'first-registration': 'Erstzulassung',
        'last-inspection': 'Letzte Inspektion',
        'done-inspection': 'Inspektionsdatum',
        'expected-inspection': 'Voraussichtl. Inspektion',
        'inspection-number': 'Inspektionsintervall',
        'invitation-sent': 'Einladung versendet am',
        'invitation-sent-pending': 'Wartet auf Versand',
        'invitation-sendout-failed': 'Warten auf Versand, letzter Versand fehlgeschlagen',
        'estimated-milage' :'Voraussichtlicher KM-Stand',
        'inspection-date': 'Inspektionsdatum',
        'sendout-errors': {
          result: 'Validierung fehlgeschlagen für Felder: {0}',
          'customer-number': 'Kundennummer',
          salutation: 'Kunden-Anrede',
          name: 'Kundenname',
          street: 'Kundenstraße',
          zip: 'Kunde-PLZ',
          city: 'Kundenstadt',
          'country-code': 'Kunden-Ländercode',
          'dealer-name': 'Händlername',
          'dealer-code': 'Händlercode',
          'dealer-zip': 'Händler-PLZ',
          'dealer-city': 'Händlerstadt',
          'dealer-phone': 'Händler-Telefon',
          'dealer-url': 'Händler-Homepage',
          vin: 'vin',
          'first-reg-date': 'Erstzulassungsdatum',
          brand: 'Marke',
          model: 'Modell',
          'serial-test-number': 'Seriennummer',
          'license-number': 'Kennzeichen',
        },
      },
      filters: {
        'search-placeholder': 'VIN, Modell, Name',
        'inspection-date': {
          title: 'Fälligkeit',
          'not-marked':
            'Inspektionen, die in mehr als 2 Wochen fällig sind (ohne Markierung)',
          'marked-yellow':
            'Inspektionen, die in den nächsten 2 Wochen fällig sind (gelb markiert)',
          'marked-red':
            'Inspektionen, die bis zu 2 Wochen überfällig sind (rot markiert)',
          'marked-red-sent': 'Inspektionen, die überfällig sind (rot markiert)',
        },
          'insepection-number' :{
              title :'Inspektion'
          },
        model: 'Modell',
        mark: 'Kennzeichen',
        'first-registration': 'Erstzulassung',
        'last-inspection': 'Letzte Inspektion',
        'expected-inspection': 'Voraussichtl. Inspektion',
      },
      edit: {
        title: 'Inspektionstermin bearbeiten',
        date: 'neues Inspektionsdatum',
        cancel: 'Abbrechen',
        submit: 'Speichern & Schliessen',
        reset: 'Zurücksetzen',
        confirmation: {
          yes: 'Ja',
          no: 'Nein',
          title:
            'Durch die Änderung des voraussichtlichen Inspektionsdatums wird die Inspektion nicht mehr auf der Versandliste angezeigt. Möchten Sie die Datumsänderung übernehmen?',
        },
      },
      delete: {
        title: 'Inspektionseinladung entfernen',
        description:
          'Bitte wählen Sie einen Grund für das Entfernen der Inspektionseinladung aus.',
        hint: 'Inspektionseinladung entfernen',
        date: 'Inspektionsdatum',
        cancel: 'Abbrechen',
        submit: 'Speichern und Schließen',
        options: {
          ALREADY_COMPLETED_SUZUKI:
            'Fahrzeug war bereits bei anderem Suzuki-Händler zur Inspektion',
          ALREADY_COMPLETED_NON_SUZUKI:
            'Fahrzeug war bei nicht Suzuki-Händler / offener Werkstatt zur Inspektion',
          VEHICLE_SOLD_TO_UNKNOWN:
            'Kunde besitzt das Fahrzeug nicht mehr / Fahrzeug an unbekannt verkauft',
          VEHICLE_SCRAPPED: 'Fahrzeug wurde verschrottet',
          VEHICLE_EXPORTED: 'Fahrzeug wurde exportiert',
          VEHICLE_STOLEN: 'Fahrzeug wurde gestohlen',
          DEACTIVATE_INVITATIONS:
            'Fahrzeug nicht mehr für Inspektionseinladungen berücksichtigen',
        },
      },
    },
    'completed-inspections' : {
      'columns' : {
        'inspection-date': 'geplantes Inspektionsdatum',
        'milage': 'KM-Stand',
        'inspectionReason': 'Inspektionsgrund',
      }
    },
    'extended-warranty-reminder': {
      title: 'Erinnerung versenden',
      'to-send': 'Erinnerungen versenden',
      sent: 'Gesendete Erinnerungen',
      sideTitle: 'Erinnerung an Suzuki Pro Anschlussgarantie',
      'send-reminder': 'Erinnerung an Suzuki Pro Anschlussgarantie versenden',
      'resend-reminder':
        'Erinnerung an Suzuki Pro Anschlussgarantie erneut versenden',
      'send-reminder-title':
        'Erinnerung an Suzuki Pro Anschlussgarantie abschicken',
      'send-reminder-confirmation':
        'Erinnerung an Suzuki Pro Anschlussgarantie erfolgreich',
      'confirm-count':
        'Wenn Sie bestätigen, werden {0} Kontakte per E-Mail an die Anschlussgarantie erinnert.',
      columns: {
        contact: 'Kontakt',
        email: 'E-Mail',
        model: 'Modell',
        'license-plate': 'Kennzeichen ',
        'first-registration': 'Registrierung',
        vin: 'VIN / FIN',
        'warranty-expiry-date': 'Garantieende',
        vehicle: 'Fahrzeug',
        'last-sendout': 'Erinnerung versendet am',
      },
      filters: {
        'search-placeholder': 'VIN, Name, E-Mail',
        'date-of-registration': 'Registrierungsdatum',
        'show-contacts': 'Kontaktliste filtern',
        'show-selected': 'nur ausgewählte Kontakte anzeigen ',
        'show-unselected': 'nur abgewählte Kontakte anzeigen',
      },
    },
    'ccp-export': {
      title: 'KKP - Export',
      'service-questionnaire': 'Service-Fragebogen',
      'service-questionnaire-header': 'KKP - Service-Fragebogen',
      'service-questionnaire-sample-cover-letter':
        'Musteranschreiben herunterladen',
      'service-questionnaire-sample-questionnaire':
        'Muster Service-Fragebogen herunterladen',
      'service-questionnaire-popup': {
        'resend-button': 'Erneut versenden',
        title: 'Service-Fragebogen erneut senden',
        confirmation: 'Service-Fragebögen werden erneut versendet?',
        success: 'Der Service-Fragebogen wurde erneut ausgesendet',
        'success-description':
          'Service-Fragebögen werden nach dem nächsten CCP-Export per Brief verschickt.',
      },
      'exported-at': 'Versanddatum',
      'sales-questionnaire': 'Sales-Fragebogen',
      'sales-questionnaire-header': 'KKP - Sales-Fragebogen',
      'sales-questionnaire-sample-cover-letter':
        'Musteranschreiben herunterladen',
      'sales-questionnaire-sample-questionnaire':
        'Muster Sales-Fragebogen herunterladen',
      'sales-questionnaire-sample-mobility-card':
        'Muster Mobilitätskarte herunterladen',
      'sales-questionnaire-sample-cover-letter-resending':
        'Musteranschreiben Erinnerung herunterladen',
      'sales-questionnaire-popup': {
        'resend-button': 'Erneut versenden',
        title: 'Sales-Fragebogen erneut senden',
        confirmation: 'Sales-Fragebögen werden erneut versendet?',
        success: 'Der Sales-Fragebogen wurde erneut ausgesendet',
        'success-description':
          'Sales-Fragebögen werden nach dem nächsten CCP-Export per Brief verschickt.',
      },
    },
    newsletter: {
      title: 'Newsletter',
      list: {
        sideTitle: 'Newsletter-Anmeldungen',
      },
      export: {
        sideTitle: 'Export-Historie',
      },
      verification: {
        sideTitle: 'Unbestätigte Abonnenten',
      },
      definition: {
        side_title: 'Übersicht',
        page_header: 'Newsletters',
        table_header_name: 'Name',
        table_header_code: 'Code',
        table_header_tenant: 'Tenant',
        table_header_activation_status: 'Status',
        tooltip_create: 'Erstellen',
        tooltip_edit: 'Bearbeiten',
        tooltip_delete: 'Löschen',
        tooltip_deactivate: 'Deaktivieren',
        form: {
          label_name: 'Name',
          label_code: 'Code',
          label_newsletter: 'Newsletter',
          label_tenant: 'Tenant',
          tenant_auto:'AUTO',
          tenant_moto:'MOTO',
          validation_name: 'Name darf nicht leer sein!',
          validation_code: 'Code darf nicht leer sein!',
          validation_tenant: 'Tenant muss ausgewählt werden!',
        },
        popup: {
          success_create: 'Newsletter erfolgreich erstellt.',
          success_edit: 'Newsletter erfolgreich aktualisiert.',
          success_delete: 'Newsletter erfolgreich gelöscht.',
          success_deactivate: 'Der Newsletter wurde erfolgreich deaktiviert!',
          error_not_found: 'Newsletter wurde nicht gefunden',
          error_invalid_id: 'Das angegebene Newsletter ist nicht gültig',
          error_invalid_newsletter: 'Der ausgewählte Newsletter ist ungültig',
          error_newsletter_not_modifiable: 'Ausgewählter Newsletter kann nicht geändert werden, da er bereits abonniert ist.',
          error_resource_already_exists: 'Der Newsletter mit dem angegebenen Code existiert bereits.',
        },
        filter:{
          label: 'Newsletter-Status',
          all: 'Alle Newsletters anzeigen',
          active:'Aktiv',
          deactivated:'Inaktiv',
        },
        modal: {
          title_create: 'Newsletter erstellen',
          title_edit: 'Newsletter bearbeiten',
          title_delete: 'Newsletter löschen',
          title_deactivate: 'Newsletter deaktivieren',
          button_save: 'SPEICHERN',
          button_delete: 'LÖSCHEN',
          button_deactivate: 'DEAKTIVIEREN',
          button_cancel: 'ABBRECHEN',
          text_delete: 'Sind Sie sicher, dass Sie den Newsletter löschen möchten?',
          text_deactivate: 'Sind Sie sicher, dass Sie diesen Newsletter deaktivieren wollen?',
        },
      },
    },
    sweepstake: {
      title: 'Gewinnspiel',
      subpages: {
        overview: 'Übersicht',
      },
      overview: {
        title: 'Gewinnspiele',
        table_header_name: 'Name',
        table_header_code: 'Code',
        table_header_newsletter: 'Newsletter',
        tooltip_create: 'Erstellen',
        tooltip_edit: 'Bearbeiten',
        tooltip_delete: 'Löschen',
        form: {
          label_name: 'Name',
          label_code: 'Code',
          label_newsletter: 'Newsletter',
          validation_name: 'Name darf nicht leer sein!',
          validation_code: 'Code darf nicht leer sein!',
          validation_newsletter: 'Newsletter muss ausgewählt werden!',
        },
        popup: {
          success_create: 'Gewinnspiel erfolgreich erstellt.',
          success_edit: 'Gewinnspiel erfolgreich aktualisiert.',
          success_delete: 'Gewinnspiel erfolgreich gelöscht.',
          error_not_found: 'Gewinnspiel wurde nicht gefunden',
          error_code_exists: 'Die Verlosung mit dem angegebenen Code existiert bereits',
          error_invalid_id: 'Das angegebene Gewinnspiel ist nicht gültig',
          error_invalid_newsletter: 'Der ausgewählte Newsletter ist ungültig',
          error_already_in_use: 'Das Gewinnspiel ist in Gebrauch und kann nicht aktualisiert/gelöscht werden',
        },
        modal: {
          title_create: 'Gewinnspiel erstellen',
          title_edit: 'Gewinnspiel bearbeiten',
          title_delete: 'Gewinnspiel löschen',
          button_save: 'SPEICHERN',
          button_delete: 'LÖSCHEN',
          text_delete: 'Sind Sie sicher, dass Sie das Gewinnspiel löschen wollen?',
        },
      },
    },

    surveys: {
      receivers: "Empfängerliste",
      invited: "Versandliste",
      title: 'Umfragen',
      'moto-sales': 'Sales-Fragebogen',
      'moto-service': 'Service-Fragebogen',
      'moto-roadshow': 'Roadshow-Fragebogen',
      'moto-product': 'Produkt-Fragebogen',
      'auto-sales': 'Sales-Fragebogen',
      'auto-service': 'Service-Fragebogen',
      'auto-product': 'Produkt-Fragebogen',
      'moto-sales-prefix': 'Motorrad Sales-Fragebogen',
      'moto-service-prefix': 'Motorrad Service-Fragebogen',
      'moto-roadshow-prefix': 'Motorrad Roadshow-Fragebogen',
      'moto-product-prefix': 'Motorrad Produkt-Fragebogen',
      'auto-sales-prefix': 'Auto Sales-Fragebogen',
      'auto-service-prefix': 'Auto Service-Fragebogen',
      'auto-product-prefix': 'Auto Produkt-Fragebogen',
      'sendoutMethod': {
        'email': 'Email',
        'post': 'Post',
      },
      'sendoutErrors': {
        'result': 'Versand nicht möglich, da {0}',
// Major errors
        "registration-nil-registration": 'Registrierung nicht gefunden',
        "vehicle-nil-vehicle": 'Fahrzeug nicht gefunden',
        "dealer-nil-dealer": 'Händler nicht gefunden',
        "service-record-nil-service-record": 'service-record nicht gefunden',
        "invitation-nil-invitation": 'Einladung nicht gefunden',
        "ccp-export-nil-ccp-export": 'ccp-export nicht gefunden',
        "ccp-export-ccp-export-exist": 'wartet schon auf den Versand',
        "contact-nil-contact": 'Kontakt nicht gefunden',
        "dpd-nil-dpd": 'Einwilligungserklärungen nicht gefunden',

// Vehicle
        "vehicle-missing-vehicle": 'Fahrzeug nicht gefunden',
        "vehicle-brand": 'ungültige Marke',
        "vehicle-model-type": 'ungültiges Modell Typ',
        "vehicle-first-registration": 'Fahrzeug ist zu alt',
        "vehicle-not-active": 'Fahrzeug ist nicht aktiv',
        "vehicle-deactivated": 'Fahrzeug ist nicht aktiv',
        "vehicle-hidden": 'Fahrzeug ist versteckt',
        "vehicle-vin": 'ungültige VIN',
        "vehicle-model": 'ungültige Modell',

// Contact
        "contact-contact-not-found": 'Kontakt nicht gefunden',
        "contact-securitylawcode-locked": 'Kontakt ist durch den Administrator gesperrt',
        "contact-customer-number": 'ungültige Kundennummer',
        "contact-salutation": 'ungültige Kunden Anrede',
        "contact-name": 'ungültiger Kundenname',
        "contact-bookable": ' Kontakt ist nicht zustellbar',
        "contact-street": 'ungültige Kundenadresse',
        "contact-zip": 'ungültige Kundenadresse',
        "contact-city": 'ungültige Kundenadresse',
        "contact-countrycode": 'Ungültiger Kunden-Ländercode',
        "contact-email": 'Ungültige Kunden email',
        "contact-company-is-dealer": 'Kontakt ist ein Händler',

// Dealer
        "dealer-name": 'ungültiger Händlername',
        "dealer-dealercode": 'ungültiger Händlercode',
        "dealer-address-line": 'ungültige Händleradresse',
        "dealer-zip": 'ungültige Händleradresse',
        "dealer-city": 'ungültige Händleradresse',
        "dealer-phone": 'ungültige Händler-Telefonnummer',
        "dealer-email": 'ungültige Händler-Email',
        "dealer-homepage": 'ungültige Händler Homepage',

// DPD
        "dpd-dpd-email": 'fehlende E-Mail-Zustimmung',
        "dpd-dpd-post": 'fehlende Zustimmung zum postalischen Kontakt',
        "dpd-dpd-mare": 'fehlende Zustimmung zur Marktforschung',
        "dpd-dpd-admin-lock": 'vom Admin gesperrt',

// Service_Record
        "service-record-record-is-not-inspection": 'record ist keine Inspektion',
        "service-record-reference-id": 'ungültige service record Referenz',
        "service-record-not-first-inspection": 'service record nicht zur Erstinspektion',
        "service-record-nil-visit-date": ' leeres service datum',
        "service-record-visit-date": ' ungültiges service datum',
        "service-record-first-registration-date": 'ungültiges Datum der ersten Registrierung',
        "service-record-last-sendout": 'ungültiger letzter Versand',
        "service-record-creation-date": 'Nachricht über die Inspektion wurde zu spät empfangen.',
        "service-record-last-sendout-nill": 'ungültiger letzter Versand',
        "service-record-dealer-deleted": 'Händler ist gelöscht',

// Registration
        "registration-missing-vin": 'ungültige VIN',
        "registration-contact": 'ungültiger Kontakt',
        "registration-company": 'ungültiges Unternehmen',
        "registration-not-active": 'inaktive Registrierung',
        "registration-not-firstregistration": 'nicht die erstmalige Registrierung',
        "registration-not-new-registration": 'keine neue Registrierung',
        "registration-days-after-first-registration": 'Registrierung zu alt',
        "registration-dealer": 'ungültiger Händler',
        "registration-license-number": 'ungültige Kennzeichen',
        "registration-customer-type": 'ungültiger Kundentyp',
        "registration-email": "der Kontakt hat keine Emailadresse",
        "registration-company-is-dealer": "der Kontakt ist ein Suzuki-Händler",
      },
      columns: {
        vehicle: 'Fahrzeug',
        'sendoutDate': 'Berechnetes Versanddatum',
        'sendoutMethod': 'Versandmethode',
        'sendoutFailed': 'Versand nicht möglich',
        'progress': 'Status',
      },
      progressState: {
        NOT_STARTED: "Nicht begonnen",
        IN_PROGRESS: "Begonnen",
        COMPLETED: "Ausgefüllt",
      },
      filters: {
        'search-placeholder': 'VIN, Name, E-Mail...',
        progressState: "Status"
      },
    },
    'test-drive': {
      title: 'Probefahrten',
      origin: {
        NOT_SET: 'keine Angabe',
        NATIONAL_WEBSITE: 'Nationale Webseite',
        DEALER_WEBSITE: 'Händler-Webseite / Walk-In',
        SCRM: 'SCRM',
        TEST_DRIVE_WEEK: 'Probefahrtwochen',
        DATA_COLLECTION_TOOL: 'Data Collection Tool',
        IMOT_2024_MUNICH: 'IMOT 2024 München',
        MOTORRAD_DORTMUND: 'Motorrad Dortmund',
        ROADSHOW: 'Roadshow',
      },
      status: {
        NEW_OPEN: 'Neu/Offen',
        SCHEDULED: 'Probefahrt terminiert',
        FINISHED_CARRIED_OUT_BOUGHT: 'Probefahrt abgeschlossen (Fahrzeugkauf erfolgt)',
        FINISHED_CARRIED_OUT_NOT_BOUGHT: 'Probefahrt abgeschlossen (kein Fahrzeugkauf)',
        FINISHED_CARRIED_OUT_PURCHASE_OPEN: 'Probefahrt abgeschlossen (Fahrzeugkauf offen)',
        FINISHED_CARRIED_OUT_PURCHASE_OPEN_HOT_LEAD: 'Probefahrt abgeschlossen (Fahrzeugkauf offen (Hot Lead))',
        FINISHED_NOT_CARRIED_OUT: 'Probefahrt abgeschlossen (nicht durchgeführt)',
        CANCELED: 'Probefahrt abgeschlossen (storniert)',
        'no-data': 'keine Angabe',
      },
      report: {
        title: 'Übersicht',
        auto: "Auto",
        moto: "Motorrad",
        date: 'Datum',
        plannedDate: 'Probefahrtdatum (geplant)',
        model: 'Modell',
        dealercode: 'Händlernummer',
        dealerName: 'Händlername',
        'contact-name': 'Kontaktname',
        email: 'E-Mail',
        phone: 'Telefon',
        origin: 'Probefahrtherkunft',
        'status-header': 'Probefahrtstatus',
        search: 'Datum, Modell, Name...',
        'asm-area': 'ASM',
        dealer: 'Händler',
        'main-dealer': 'Haupthändler',
        'date-from-to': 'Zeitraum von/bis',
        back: 'ZURÜCK',
        interested_in_vehicle: 'Interesse am Fahrzeug',
        not_interested_in_vehicle: 'kein Interesse',
        modals: {
          'created-at': 'Angelegt am {0} über {1}.',
          'modified-at': 'Zuletzt bearbeitet am {0} von {1}.',
          'not-specified': 'keine Angabe',
          model: 'Probefahrtmodell: ',
          'wish-date': 'Probefahrttermin (Wunsch): ',
          prospect: {
            name: 'Vorname: ',
            surname: 'Nachname: ',
            'company-name': 'Firmenname: ',
            phone: 'Telefon: ',
            'e-mail': 'E-Mail-Adresse: ',
          },
          comment: 'Probefahrtbemerkung (Händler): ',
          'prospect-notice': 'Probefahrtbemerkung: ',
          'selected-date': 'Probefahrttermin (vereinbart): ',
          close: 'Abbrechen',
          save: 'Speichern',
          'download-agreement': 'Überlassungsvertrag',
          radio: {
            UNKNOWN: 'Unbekannt',
            TRUE: 'Ja',
            FALSE: 'Nein'
          },
          yes: 'Ja',
          no: 'Nein',
          'response-label': 'Probefahrtresponse (*): ',
          response_label_not_required: 'Probefahrtresponse: ',
          response: {
            performed: 'Probefahrt durchgeführt: ',
            interested: 'Interesse an dem Fahrzeug: ',
            purchase: 'Kauf eines/des Fahrzeuges: ',
            'purchase-vin': 'VIN: ',
            'purchase-vin-placeholder': 'VIN des gekauften Fahrzeuges',
            'vin-empty': 'Bitte geben Sie die Fahrgestellnummer des gekauften Fahrzeugs ein, bevor Sie die Probefahrtanfrage stornieren.',
          },
          'schedule-test-drive': {
            'button-tooltip': 'Probefahrtanfrage terminieren',
            'modal-title': 'Probefahrtanfrage bearbeiten/terminieren',
            'date-selection-tooltip': 'Probefahrttermin auswählen',
            'successfully-scheduled': 'Die Probefahrtanfrage wurde erfolgreich aktualisiert.',
            'scheduled-date-not-set': 'Bitte tragen Sie ein Datum und eine Uhrzeit ein, um die Probefahrt zu speichern.',
          },
          'cancel-test-drive': {
            'button-tooltip': 'Probefahrtanfrage stornieren',
            'modal-title': 'Probefahrtanfrage stornieren',
            'reason-for-canceling': 'Begründung für Stornierung (*): ',
            'successfully-canceled': 'Die Probefahrtanfrage wurde erfolgreich storniert.',
            'fill-required-fields': 'Bitte füllen Sie alle erforderlichen Felder aus, bevor Sie die Probefahrtanfrage stornieren.',
          },
          'finish-test-drive': {
            'button-tooltip': 'Probefahrtanfrage abschließen',
            'modal-title': 'Probefahrtanfrage abschliessen',
            'successfully-finished': 'Die Probefahrt wurde erfolgreich abgeschlossen.',
            'fill-required-fields': 'Bitte beantworten Sie alle Responsefragen, um die Probefahrt abzuschließen.',
          },
          show_test_drive_details: {
            button_tooltip: 'Probefahrt ansehen',
            modal_title: 'Probefahrt ansehen',
            reason_for_canceling_not_required: 'Begründung für Stornierung: ',
          },
          'question-test-drive-title': 'Neue Probefahrt anlegen?',
          'question-test-drive-creation': 'Möchten Sie eine neue Probefahrt für diesen Kontakt anlegen?',
          'create-test-drive': {
            'button-tooltip': 'Probefahrt anlegen',
            'modal-title': 'Probefahrtanfrage anlegen',
            'successfully-created': 'Die Probefahrt wurde erfolgreich angelegt.',
            'fill-required-fields': 'Bitte füllen Sie alle Pflichtfelder (*) aus, um die Probefahrt anzulegen.',
          },
          'planned-date-in-the-past': 'Das Probefahrtdurchführungsdatum liegt in der Vergangenheit. Ist das korrekt?',
        },
        'filter-option': {
          from: 'Von',
          to: 'Bis',
          title: 'Datumsart',
          createAt: 'Probefahrtanlagedatum',
          plannedDate: 'Probefahrtdatum (geplant)',
        },
        origin_filter: {
          name: 'Probefahrtherkunft',
        },
        status_filter: {
          name: 'Probefahrtstatus'
        }
      },
      import: {
        title: 'Import durchgeführter Probefahrten',
        button: 'Import',
        'excel-import-success': 'Der Probefahrtimport war erfolgreich.',
        'excel-import-failed': 'Import fehlgeschlagen, die Datei enthält ungültige Daten.',
        'excel-import-wrong-format':
          'Falsches Dateiformat. Unterstützte Formate: xlsx, xlsm, xltm.',
      },
    },
    'web-request': {
      title: 'Leads',
      dealercode: 'Händlernummer',
      models: 'Modell',
      'contact-number': 'Kundennummer',
      'contact-email': 'E-Mail',
      'contact-name': 'Name',
      'created-at': 'Anlagedatum SCRM',
      'completed': 'Status',
      subpages: {
        offers: 'Angebotsanfragen',
        'car-configuration-offers': 'Angebotsanfragen aus dem Konfigurator',
        'brochure-downloads': 'Broschürendownloads',
        'consulting-appointments': 'Beratungsanfragen',
        'service-appointments': 'Service-Anfragen',
        'sweepstake-subscriptions': 'Gewinnspielteilnahme inkl. Kaufabsicht',
      },
      'search-placeholder': 'Kundennummer, E-Mail, Name...',
      'completion-failed': 'Es ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.',
      'sweepstake-name': ' Gewinnspielname',
      'subscription-handled': ' Kontaktiert',
      'sweepstake-created-at': 'Anlagedatum',
      'subscription-purchase-intent': {
        title: "Fahrzeugwechsel",
        UNKNOWN: "Weiß ich noch nicht",
        LEASING: "Ja, mit Leasing",
        FINANCING: "Ja, mit Finanzierung",
        CASH_PURCHASE: "Ja, als Barkauf",
        NOT_BUY: "Nein",
      },
      'subscription-vehicle-class': {
        title: "Fahrzeugart",
        OTHER: "Andere",
        SMALL_CAR: "Kleinwagen",
        SUV: "SUV",
        COMBI: "Kombi",
        LIMOUSINE: "Limousine",
        VAN: "VAN",
        UNKNOWN_VC: "Weiß ich noch nicht",
      },
      'subscription-vehicle-engine': {
        title: "Motoart",
        UNKNOWN_VE: "Weiß ich noch nicht",
        PETROL: "Benzinmotor",
        DIESEL: "Dieselmotor",
        ELECTRO: "Elektroantrieb",
        HYBRID: "Hybridantrieb (Plug-In, Vollhybrid)",
        OTHER_VE: "Andere",
      },
    },
  },
  register: {
    title: 'Neuen Nutzer anlegen',
    'basic-data': 'Basisdaten',
    active: 'NUTZER IST AKTIV',
    username: 'NUTZERNAME',
    firstname: 'VORNAME',
    lastname: 'NACHNAME',
    email: 'E-Mail ',
    cancel: 'ABBRECHEN',
    submit: 'NUTZER SPEICHERN',
    placeholder: 'Hier kann auch Ihr Platzhalter stehen',
    password: 'Passwort',
    isAdmin: 'Ist der Nutzer ein Admin?',
    isAreaAdmin: 'Ist der Nutzer ein ASM oder DSM?',
    dealer: 'Händler',
    error: {
      'email-required': 'E-Mail ist ein Pflichtfeld.',
      'email-invalid': 'E-Mail ist ungültig.',
      'username-required': 'Nutzername ist ein Pflichtfeld.',
      'password-required': 'Passwort ist ein Pflichtfeld.',
    },
  },
  'edit-user': {
    title: 'Nutzer bearbeiten',
    'basic-data': 'Basisdaten',
    username: 'NUTZERNAME',
    firstname: 'VORNAME',
    lastname: 'NACHNAME',
    email: 'E-MAIL ',
    isAdmin: 'Ist der Nutzer Admin?',
    isAreaAdmin: 'Ist der Nutzer ASM/DSM?',
    cancel: 'ABBRECHEN',
    submit: 'NUTZER SPEICHERN',
  },
  'dealer-information': {
    title: 'Händler {{name}}',
    'basic-data': {
      title: 'Basisdaten',
      createdAt: 'Angelegt am ',
      number: 'Händlernummer',
      name: 'Händlername',
      'name-addition': 'Händlername Zusatz',
    },
    'contact-data': {
      title: 'Kontaktdaten',
      phone: 'Telefon',
      fax: 'Telefax',
      'dealer-email': 'Händler E-Mail',
      'suzuki-email': 'Suzuki E-Mail',
      homepage: 'Homepage',
      homepage2: 'Homepage2',
    },
    'address-data': {
      title: 'Adresse',
      street: 'Straße',
      postcode: 'PLZ',
      city: 'Ort',
    },
    'opening-hours': {
      title: 'Öffnungszeiten',
      sale: 'Verkauf',
      service: 'Service',
      monday: 'Montag:',
      tuesday: 'Dienstag:',
      wednesday: 'Mittwoch:',
      thursday: 'Donnerstag:',
      friday: 'Freitag:',
      saturday: 'Samstag:',
      sunday: 'Sonntag:',
    },
    amrDsmArea: {
      title: 'ASM / DSM  Gebiet',
      amr: 'ASM Gebiet',
      dsm: 'DSM Gebiet',
    },
    'contract-data': {
      title: 'Vertragsdaten',
      'distribution-contract': 'Vertriebsvertrag',
      'distribution-contract-end': 'Vertriebsvertrag ausgeschieden',
      'service-contract': 'Servicevertrag',
      'service-contract-end': 'Servicevertrag ausgeschieden',
    },
    back: 'ZURÜCK',
    'data-reimported': 'Import erfolgreich',
    'data-reimported-error': 'Es gab einen Fehler beim Import ',
    'data-reimported-msg': 'Daten werden importiert',
  },
  'contact-pools': {
    title: 'Contact pools',
    'pool-id': 'Pool ID',
    'dealer-name': 'Dealer name',
    'dealer-number': 'Dealer number',
    'number-of-contacts': 'Number of contacts',
    actions: 'Actions',
  },
  'bar-chart': {
    avg: 'Average',
    legend: {
      keys: {
        plannedInspections: 'Geplante Inspektionen',
        performedInspections: 'Durchgeführte Inspektionen',
      },
    },
  },
  crm: {
    'asm-range': 'ASM-Gebiet',
    'dsm-range': 'DSM-Gebiet',
    'main-dealer': 'Haupthändler',
    dealer: 'Händler',
    'customer-retention-rate': 'Kundenbindungsrate',
    'customer-retention-rate-desc-1-5':
      'Kundenbindungsrate (KBR) - 1.-5. Inspektion',
    'customer-retention-rate-desc-1-10':
      'Kundenbindungsrate (KBR) - 1.-10. Inspektion',
    shop: 'Shop',
    'shop-cart-items': 'Warenkorbeinträge',
    'website-metrics': {
      title: 'Webseiten Kennzahlen',
      'survey-tool': {
        'kpi': 'CSI Kennzahlen',
        'sent-auto-service-prefix': 'Auto Service-Fragebogen - Ausgesendete Umfrageeinladungen',
        'finished-auto-service-prefix': 'Auto Service-Fragebogen - Beantwortete Fragebögen',
        'percentage-auto-service-prefix': 'Auto Service-Fragebogen - Beantwortungsquote (in %)',
        'sent-auto-sales-prefix': 'Auto Sales-Fragebogen - Ausgesendete Umfrageeinladungen',
        'finished-auto-sales-prefix': 'Auto Sales-Fragebogen - Beantwortete Fragebögen',
        'percentage-auto-sales-prefix': 'Auto Sales-Fragebogen - Beantwortungsquote (in %)',
        'sent-auto-product-prefix': 'Auto Produkt-Fragebogen - Ausgesendete Umfrageeinladungen',
        'finished-auto-product-prefix': 'Auto Produkt-Fragebogen - Beantwortete Fragebögen',
        'percentage-auto-product-prefix': 'Auto Produkt-Fragebogen - Beantwortungsquote (in %)',
        'sent-moto-service-prefix': 'Motorrad Service-Fragebogen - Ausgesendete Umfrageeinladungen',
        'finished-moto-service-prefix': 'Motorrad Service-Fragebogen - Beantwortete Fragebögen',
        'percentage-moto-service-prefix': 'Motorrad Service-Fragebogen - Beantwortungsquote (in %)',
        'sent-moto-sales-prefix': 'Motorrad Sales-Fragebogen - Ausgesendete Umfrageeinladungen',
        'finished-moto-sales-prefix': 'Motorrad Sales-Fragebogen - Beantwortete Fragebögen',
        'percentage-moto-sales-prefix': 'Motorrad Sales-Fragebogen - Beantwortungsquote (in %)',
        'sent-moto-product-prefix': 'Motorrad Produkt-Fragebogen - Ausgesendete Umfrageeinladungen',
        'finished-moto-product-prefix': 'Motorrad Produkt-Fragebogen - Beantwortete Fragebögen',
        'percentage-moto-product-prefix': 'Motorrad Produkt-Fragebogen - Beantwortungsquote (in %)',

        'sent-auto-service': 'Service-Fragebogen - Ausgesendete Umfrageeinladungen',
        'finished-auto-service': 'Service-Fragebogen - Beantwortete Fragebögen',
        'percentage-auto-service': 'Service-Fragebogen - Beantwortungsquote (in %)',
        'sent-auto-sales': 'Sales-Fragebogen - Ausgesendete Umfrageeinladungen',
        'finished-auto-sales': 'Sales-Fragebogen - Beantwortete Fragebögen',
        'percentage-auto-sales': 'Sales-Fragebogen - Beantwortungsquote (in %)',
        'sent-auto-product': 'Produkt-Fragebogen - Ausgesendete Umfrageeinladungen',
        'finished-auto-product': 'Produkt-Fragebogen - Beantwortete Fragebögen',
        'percentage-auto-product': 'Produkt-Fragebogen - Beantwortungsquote (in %)',
        'sent-moto-service': 'Service-Fragebogen - Ausgesendete Umfrageeinladungen',
        'finished-moto-service': 'Service-Fragebogen - Beantwortete Fragebögen',
        'percentage-moto-service': 'Service-Fragebogen - Beantwortungsquote (in %)',
        'sent-moto-sales': 'Sales-Fragebogen - Ausgesendete Umfrageeinladungen',
        'finished-moto-sales': 'Sales-Fragebogen - Beantwortete Fragebögen',
        'percentage-moto-sales': 'Sales-Fragebogen - Beantwortungsquote (in %)',
        'sent-moto-product': 'Produkt-Fragebogen - Ausgesendete Umfrageeinladungen',
        'finished-moto-product': 'Produkt-Fragebogen - Beantwortete Fragebögen',
        'percentage-moto-product': 'Produkt-Fragebogen - Beantwortungsquote (in %)',
      },
      'web-requests': {
        'test-drive': {
          'new-test-drive-request' : 'Probefahrtanfragen der letzten 7 Tage',
          'open-test-drive-request': 'Neue/Offene Probefahrtanfragen',
        },
        'offers': 'Neue Angebotsanfragen von den Webseiten',
        'car-configuration-offers': 'Neue Angebotsanfragen aus dem Konfigurator',
        'digital-brochures': 'Neue Broschürendownloads',
        'consulting-appointments': 'Neue Beratungsanfragen',
        'service-appointments': 'Neue Service-Anfragen',
      }
    },
  },
  'add-action': {
    title: 'Aktion zu Kontakt hinzufügen',
    'action-type': 'ART DER AKTION',
    note: 'NOTIZ',

    cancel: 'Abbrechen',
    submit: 'Aktion speichern',
    options: {
      'conversation-log': 'Gesprächsprotokoll',
      note: 'Notiz',
    },
  },
  'hide-contact': {
    title: 'Kontakt ausblenden',
    reason: 'Grund des Ausblendens',
    cancel: 'Abbrechen',
    submit: 'Ok',
    options: {
      'no-longer-serviced-by-me':
        'Kontakt möchte nicht mehr von mir betreut werden',
      'deceased-closed': 'Verstorben / Geschäftsaufgabe',
      'fake-data-set': 'Unechter Datensatz (Fake)',
      gdpr: 'DSGVO',
      'no-data-release': 'Keine Datenfreigabe',
      other: 'Sonstiges',
    },
    note: 'Notiz',
    'other-reason-message-empty': 'Die Notiz darf nicht leer sein.',
    'successfully-hidden': 'Der Kontakt wurde erfolgreich ausgeblendet',
  },
  'hide-vehicle': {
    title: 'Fahrzeug ausblenden',
    reason: 'Grund des Ausblendens',
    cancel: 'Abbrechen',
    submit: 'Ok',
    options: {
      SOLD_TO_UNKNOWN: 'Nicht sichtbar (verkauft an unbekannt)',
      OTHER: 'Nicht sichtbar (sonstiges)',
    },
    note: 'Notiz',
    'other-reason-message-empty': 'Die Notiz darf nicht leer sein.',
    'successfully-hidden': 'Das Fahrzeug wurde erfolgreich ausgeblendet.',
    visible: 'Das Fahrzeug ist sichtbar',
  },
  'deactivate-vehicle': {
    title: 'Fahrzeug löschen',
    reason: 'Grund des Löschens',
    cancel: 'Abbrechen',
    submit: 'Ok',
    options: {
      EXPORTED: 'Gelöscht (exportiert)',
      STOLEN: 'Gelöscht (gestohlen)',
      SCRAPPED: 'Gelöscht (verschrottet)',
    },
    note: 'Notiz',
    'successfully-deactivated': 'Fahrzeug erfolgreich gelöscht.',
    'deactivating-confirmation':
      'Sind Sie sicher, dass Sie dieses Fahrzeug löschen möchten?',
  },
  appointment: {
    consulting_appointment: 'Beratungstermin',
    test_drive_appointment: 'Probefahrtanfrage',
    service_appointment: 'Servicetermin',
    service_appointment_reason: {
      regular: 'Regelmäßiger Servicetermin',
      other: 'Andere',
    },
    preferred_date: 'Bevorzugtes Datum',
    daytime: {
      forenoon: 'Vormittags',
      afternoon: 'Nachmittags',
    },
    gearbox: {
      preferred_gearbox: 'Bevorzugtes Getriebe',
      manual: 'Manuell',
      automatic: 'Automatik',
      no_preference: 'Keine Präferenz',
    },
    license_number: 'Kennzeichen',
    vehicle_note: 'Bemerkung zum Fahrzeug',
  },
  appointmentsChart: {
    title: 'Anfragen über Online-Formulare',
    appointments: 'Beratungsanfrage',
    testDriveAppointments: 'Probefahrtanfrage',
    serviceAppointments: 'Serviceanfrage',
    offers: 'Angebotsanfrage',
    week: 'KW',
    filter: {
      scale: {
        label: 'Zeiteinheit',
        week: 'Kalenderwoche',
        month: 'Monat',
      },
      time: {
        from: 'Von',
        to: 'Bis',
      },
      forms: {
        label: 'Online-Formulare',
        all: 'Alle Formulare',
        consulting: 'Beratungsanfrage',
        testDrive: 'Probefahrtanfrage',
        service: 'Serviceanfrage',
        offer: 'Angebotsanfrage',
      },
    },
  },
  'dealer-list': {
    title: 'Händler',
    reimport: 'Händler neu importieren',
    number: 'Händlernummer',
    name: 'Name',
    address: 'Adresse',
    active: 'Aktiv',
    actions: 'Aktionen',
    filter: {
      search: 'Händlernummer, Name, Adresse...',
      status: {
        title: 'Status',
        deleted: 'nur inaktive Händler anzeigen',
        acitve: 'nur aktive Händler anzeigen',
      },
    },
    delete: {
      success: 'Händler {{id}} wurde erfolgreich gelöscht',
      fail: ' Es ist ein Problem beim Löschen des Händler {{id}} aufgetreten ',
      existed: 'Händler {{id}} ist breits gelöscht ',
    },
    description: {
      reimport: 'Händler neu importieren',
      reimporting: 'Händler wird neu importiert',
      active: ' Händler ist aktiv ',
      inactive: 'Händler ist inaktiv ',
      show: 'Händler anzeigen ',
      delete: 'Händler löschen ',
    },
  },
  'remove-action-popup': {
    cancel: 'Abbrechen',
    user: {
      title: 'Nutzer wirklich löschen?',
      desc: 'Wollen Sie den Nutzer {0} wirklich endgültig löschen?',
      remove: 'Nutzer löschen',
    },
    dealer: {
      title: 'Händler wirklich löschen?',
      desc: 'Wollen Sie den Händler {0} wirklich endgültig löschen?',
      remove: 'Händler löschen',
    },
    'contact-pool': {
      title: 'Contact Pool wirklich löschen?',
      desc: 'Wollen Sie den Contact Pool {0} wirklich endgültig löschen?',
      remove: 'Contact Pool löschen',
    },
    action: {
      title: 'Aktion wirklich löschen?',
      desc: 'Wollen Sie die Aktion wirklich endgültig löschen?{0}',
      remove: 'Aktion löschen',
    },
  },
  soldVehiclesChart: {
    title: "Verkaufte Fahrzeuge nach Terminen",
    consultingAppointment: "Verkauft nach Beratungstermin",
    testDriveAppointment: "Verkauft nach Probefahrttermin",
    testDrive: "Verkauft nach Probefahrt",
    groupBy: "Gruppierung nach",
    week: "Woche",
    month: "Monat",
  },

  reports: {
    title: 'Reports',
    month: 'Monat',
    year: 'Jahr',
    'please-select': 'bitte wählen',
    freeText: {
      viewDetails: 'Details anzeigen',
    },
    details: {
      prev: 'zurück',
      next: 'weiter',
      total_count: 'Gesamtanzahl:',
      value: 'Wert',
      percentage: 'Prozentsatz',
      title: 'Einzelheiten'
    },
    survey: {
      title: 'Umfrageberichte',
      report: 'Bericht',
      types: {
        'moto-sales': 'Motorrad Sales-Fragebogen',
        'moto-service': 'Motorrad Service-Fragebogen',
        'moto-roadshow': 'Motorrad Roadshow-Fragebogen',
        'moto-product': 'Motorrad Produkt-Fragebogen',
        'auto-sales': 'Auto Sales-Fragebogen',
        'auto-service': 'Auto Service-Fragebogen',
        'auto-product': 'Auto Product-Fragebogen',
      },
      no_data: 'Keine Daten verfügbar',
      monthly: 'Monatlich',
      yearly: 'Jährlich',
      weekly: 'Wöchentlich',
      week: 'KW',
      time_unit_filter: 'Zeiteinheit',
      from: 'Von',
      to: 'Bis',
      no_reports: "keine Angabe",
      export_excel: 'Excel exportieren',
      export_png: 'Exportieren',
      send_out_per: 'Versendet',
      no_send_out_per: 'Nicht versendet',
      fully_completed_percentage: 'Beantwortung abgeschlossen',
      not_completed_percentage: 'Beantwortung nicht abgeschlossen',
      partly_completed_percentage: 'Beantwortung nicht begonnen',
      surveys_input: 'Umfragen',
      model_filter: 'Modell',
      date_type: 'Datumsart',
      send_out_date: 'Versanddatum',
      response_date: 'Beantwortungsdatum',
      send_out_rate: 'Aussendungsquote',
      response_rate: 'Beantwortungsquote',
      fail_reason: 'Top 5 Gründe für fehlgeschlagene Aussendungen',
      no_report_available:'Es wurde noch kein Bericht erstellt. Bitte kontaktieren Sie den Support, um einen neuen Bericht erstellen zu lassen.',

    },
    crr: {
      title: 'Kundenbindungsrate (KBR)',
      filter: {
        year: 'Jahrart',
        'select-a-year': 'Eine Jahrart auswählen',
        'calendar-year': 'Kalenderjahr',
        'fiscal-year': 'Fiskaljahr',
        'fiscal-year-bonus': 'Fiskaljahr (Qualitätsbonus)',
        'fiscal-year-inspection': '1. - 10. Inspektion',
        'fiscal-year-bonus-inspection': '1. - 5. Inspektion',
        period: 'Zeitraum',
        'calendar-year-select': 'Kalenderjahr',
        'fiscal-year-select': 'Fiskaljahr',
        from: 'VON',
        to: 'BIS',
        'main-dealer': 'Haupthändler',
        dealer: 'Händler',
        'all-dealers': 'Alle Händler',
        asm: 'ASM',
        dsm: 'DSM',
        'asm-area': 'ASM',
      },
      'inspection-number': {
        title: {
          '0': '1.000km Inspektion',
          '1': '1. Inspektion',
          '2': '2. Inspektion',
          '3': '3. Inspektion',
          '4': '4. Inspektion',
          '5': '5. Inspektion',
          '6': '6. Inspektion',
          '7': '7. Inspektion',
          '8': '8. Inspektion',
          '9': '9. Inspektion',
          '10': '10. Inspektion',
          '-1': 'Total',
        },
        range: {
          '5' : 'Inspektionen  1. - 5.',
          '10' : 'Inspektionen  1. - 10.'
        },
        description: {
          '0': '',
          '1': '12 Monate / 15.000km oder 20 000km',
          '2': '24 Monate / 30.000 km oder 40.000 km',
          '3': '36 Monate / 45.000 km oder 60.000 km',
          '4': '48 Monate / 60.000 km oder 80.000 km',
          '5': '60 Monate / 75.000 km oder 100.000 km',
          '6': '72 Monate / 90.000 km oder 120.000 km',
          '7': '84 Monate / 105.000 km oder 140.000 km',
          '8': '96 Monate / 120.000 km oder 160.000 km',
          '9': '108 Monate / 135.000 km oder 180.000 km',
          '10': '120 Monate / 150.000 km oder 200.000 km',
          '-1': '{0} Inspektion',
        },
      },
      metrics: {
        title: 'Kennzahlen',
        planned: 'Anzahl der geplanten {0}. Inspektionen in dem Monat',
        invited: 'Anzahl der Einladungen zu einer {0}. Inspektion in dem Monat',
        completed: 'Anzahl der durchgeführten {0}. Inspektionen',
        rate: 'Inspektions-durchführungsrate (%) in dem Monat',
        'cumulated-rate':
          'Inspektionsdurchführungsrate / Kundenbindungsrate (%) kumuliert',
        zero: 'Anzahl der durchgeführten 1.000km-Inspektionen in dem Monat',
      },
      sum: 'Summe',
      average: 'Durchschnitt',
      'dealer-count': 'Berücksichtigte Händler: ',
      date: 'Stand: ',
      period: 'Zeitraum: ',
      download: {
        summary: 'Management-Summary (nur Excel-Export)',
        excel: 'Excel-Export',
        csv: 'CSV-Export',
        pdf: 'PDF-Export',
      },
      planned: 'Voraussichtliche',
      dealerCodes: 'Händlernummer: '
    },
    inspection: {
      title: 'Inspektionen',
      inspection: 'Inspektion',
      'inspections-per-month': 'Durchgeführte Inspektionen je Monat',
      'planned-inspections':
        'Vergleich geplanter Inspektionen zu durchgeführte Inspektionen',
    },
  },

  help: {
    title : 'Hilfe',
    'manual': {
      title: 'Handbuch',
      path : 'Manual',
    },
    'sample-letter': {
      title: 'Musteranschreiben',
    },
    'PDF_1': {
      title: 'Inspektion',
      path : 'PDF_1',
    },
    'PDF_2': {
      title: 'Fragebogen',
      path :'PDF_2',
    },
    'zoom' : 'Größe',
    'previous' : 'Vorherige',
    'next' : 'Nächste',
    'page' : 'Seite',
    'download' : 'Herunterladen',
    'of':'von'
  },


  months: {
    january: 'Januar',
    february: 'Februar',
    march: 'März',
    april: 'April',
    may: 'Mai',
    june: 'Juni',
    july: 'Juli',
    august: 'August',
    september: 'September',
    october: 'Oktober',
    november: 'November',
    december: 'Dezember',
  },
  table: {
    'sort-title': 'Sortieren',
    'select-title': 'auswählen',
    'all-select-title': 'alle auswählen',
  },
  error: 'Es ist ein Fehler aufgetreten. Bitte kontaktieren Sie den Support:  ',
  'error-vin': 'VIN konnte nicht im System gefunden werden!',
  'error-wrong-email': 'bitte eine richtige E-Mail eintragen!',
  'error-required': 'Bitte füllen Sie alle erforderlichen Felder aus',
  success: 'erfolgreich gespeichert',

  support: {
    title:
      'Für Hilfe bei der Nutzung des CRMs stehen Ihnen folgende Wege zur Verfügung:',
    'phone-hotline': 'Telefonhotline',
    'support-email': 'Support-E-Mail:',
    'support-fax': 'Support-Fax:',
    'back-to-site': 'ZURÜCK ZUR SEITE',
  },
  datePicker: {
    from: 'Von',
    to: 'Bis',
    time: 'Uhrzeit',
  },
  'text-line': {
    'not-specified': 'keine Angabe',
  },
  dashboard: {
    title: 'Fahrzeugverkäufe (Erstzulassung) nach Modellen',
    timeUnitFilter: 'Zeiteinheit',
    weekly: 'Kalenderwoche',
    monthly: 'Monat',
    modelByFilter: 'Modelle',
    allModels: 'Alle Modelle',
    dashboardTitle: 'Alle Verkaufsanfragen pro Monat',
    defaultModels: 'Aktuelle Modelle',
    from: 'Von',
    to: 'Bis',
    search: 'Suchen',
    selectAll: 'Alle auswählen',
  },
  'brochure-download-redirect': {
    'redirecting-message': 'Weiterleitung zu der gewünschten Broschüre...',
    'redirecting-error': 'Die Weiterleitung zu der von Ihnen gewünschten Broschüre ist fehlgeschlagen. Bitte wenden Sie sich an den <1>Support</1>, um weitere Informationen zu erhalten.'
  },
  activate_newsletter_subscription: {
    sweepstake: {
      header: 'Vielen Dank! Und viel Glück!',
      body: 'Ihre Anmeldung war erfolgreich. Damit nehmen Sie an unserem Gewinnspiel teil.',
    },
    success: {
      headline: 'Ihre Newsletter-Anmeldung',
      body: `...war erfolgreich! Vielen Dank für Ihre Newsletter-Anmeldung. Sie erhalten in Kürze alle Neuigkeiten rund um Suzuki.`,
      ending: 'Danke und bis bald!'
    },
    failure: {
      headline: 'Etwas ist schief gelaufen',
      body_moto: 'Ihre Newsletter-Anmeldebestätigung konnte nicht durchgeführt werden. Bitte versuchen Sie es zu einem späteren Zeitpunkt noch einmal. Ihre Anfrage kann leider nicht bearbeitet werden - Es wurde kein gültiger Eintrag gefunden. Bitte melden Sie sich erneut am Newsletter an unter <1>https://motorrad.suzuki.de/newsletter</1>',
      body_auto: 'Ihre Newsletter-Anmeldebestätigung konnte nicht durchgeführt werden. Bitte versuchen Sie es zu einem späteren Zeitpunkt noch einmal. Ihre Anfrage kann leider nicht bearbeitet werden - Es wurde kein gültiger Eintrag gefunden. Bitte melden Sie sich erneut am Newsletter an unter <1>https://auto.suzuki.de/ueber-suzuki/newsletter</1>',
      ending: 'Vielen Dank! Ihr Suzuki-Team'
    }
  },
  unsubscribe_newsletter: {
    success: {
      headline: 'Ihre Newsletter-Abmeldung',
      body: `...war erfolgreich! Schade, dass Sie uns verlassen. Wenn Sie sich wieder anmelden möchten, können Sie das <1>hier</1> tun!`,
      ending: 'Danke und bis bald!'
    },
    failure: {
      headline: 'Etwas ist schief gelaufen.',
      body_moto: 'Ihre Newsletter-Abmeldung konnte nicht durchgeführt werden. Es wurde kein gültiger Eintrag gefunden. Bitte melden Sie sich erneut am Newsletter an unter <1>https://motorrad.suzuki.de/newsletter</1>',
      body_auto: 'Ihre Newsletter-Abmeldung konnte nicht durchgeführt werden. Es wurde kein gültiger Eintrag gefunden. Bitte melden Sie sich erneut am Newsletter an unter <1>https://auto.suzuki.de/ueber-suzuki/newsletter</1>',

      ending: 'Vielen Dank! Ihr Suzuki-Team'
    }
  },
  'newsletter-search': {
    headline: 'Newsletter-Anmeldungen',
    date_for_newsletter_registration:"Anlage in CRM",
    tenant: {
      auto: 'Auto',
      moto: 'Motorrad',
    }
  },
  'newsletter-export': {
    headline: 'Export-Historie'
  },
  newsletter_definition: {
    headline: 'Newsletter-Definition',
  },
  standard_controls: {
    cancel_uppercase: 'ABBRECHEN',
    cancel_lowercase: 'Abbrechen',
    submit_ok: 'Ok',
    delete: 'löschen',
    back_uppercase: 'ZURÜCK',
    back_lowercase: 'Zurück',
  },
  error_popup: {
    unknown: 'Unbekannter Fehler.',
  },
  test_drive_action: {
    actionName: "Probefahrtanfrage über {0} ({1})",
    model: "Modell",
    date: "Probefahrttermin",
    unknown: "Unbekannt",
    origin: {
      NATIONAL_WEBSITE: "Nationale Webseite",
      DEALER_WEBSITE: "Händler-Webseite / Walk-In",
      SCRM: "SCRM",
      TEST_DRIVE_WEEK: "Probefahrtwochen",
      DATA_COLLECTION_TOOL: "Data Collection Tool",
      IMOT_2024_MUNICH: "IMOT 2024 München",
      MOTORRAD_DORTMUND: "Motorrad Dortmund",
      ROADSHOW: "Roadshow",
      NOT_SET: "-",
    },
    status: {
      name: "Status",
      values: {
        NEW_OPEN: "Neu/Offen",
        SCHEDULED: "Probefahrt terminiert",
        FINISHED_CARRIED_OUT_BOUGHT:
          "Probefahrt abgeschlossen (Fahrzeugkauf erfolgt)",
        FINISHED_CARRIED_OUT_NOT_BOUGHT:
          "Probefahrt abgeschlossen (kein Fahrzeugkauf)",
        FINISHED_CARRIED_OUT_PURCHASE_OPEN:
          "Probefahrt abgeschlossen (Fahrzeugkauf offen)",
        FINISHED_CARRIED_OUT_PURCHASE_OPEN_HOT_LEAD:
          "Probefahrt abgeschlossen (Fahrzeugkauf offen (Hot Lead))",
        FINISHED_NOT_CARRIED_OUT:
          "Probefahrt abgeschlossen (nicht durchgeführt)",
        CANCELED: "Probefahrt abgeschlossen (storniert)",
      },
    },
  },



};

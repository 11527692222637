import { Button, ButtonStyle, Modal, Spinner, } from '@suzuki-frontend-framework-update/ui';
import { environment } from '../../../environments/environment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { sendApiRequest } from '../send-api-request/send-api-request';
import { toast } from 'react-toastify';
import { EditInvitationPopupProps } from '../edit-invitation-popup/edit-invitation-popup';
import { DeletionReason } from '../../models/invitation';
import { SelectInput, SelectOptionsProps } from "@sde/basic-ui-library";

export const RemoveInvitationPopup = (props: EditInvitationPopupProps) => {
  const { t } = useTranslation();
  const [isWaitingForResponse, setIsWaitingForResponse] = useState<boolean>(false);

  const [reason, setReason] = useState<DeletionReason>(
    DeletionReason.ALREADY_COMPLETED_SUZUKI
  );
  const customIdFailed = 'custom-id-Failed';
  const customIdSuccess = 'custom-id-success';

  const successToast = (successText: string) => {
    toast.success(successText, {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      toastId: customIdSuccess,
    });
  };

  const errorToast = (errorText: string) => {
    toast.error(errorText, {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      toastId: customIdFailed,
    });
  };

  const handleCancel = () => {
    setReason(DeletionReason.ALREADY_COMPLETED_SUZUKI);
    props.setModalState(false);
  };

  const handleSubmit = () => {
    if (props.invitation?.id) {
      setIsWaitingForResponse(true);
      sendApiRequest(
        `${environment.restEndpoint}/invitation/invitation/removeInvitation`,
        'post',
        {
          id: props.invitation?.id,
          reason: reason,
        }
      ).then((response) => {
          setIsWaitingForResponse(false);
          if (response.ok) {
            successToast(t('success'));
            handleCancel();
            if (props.refreshData) props.refreshData();
          } else {
            errorToast(t('error') + response.statusText);
          }
        });
    }
  };

  return (
    <div>
      <Modal
        show={props.modalState}
        handleClose={() => {
          if (isWaitingForResponse) {
            return;
          } else {
            handleCancel();
          }
        }}
        title={t('communication.inspections-invitations.delete.title')}
        clip
      >
        <div className="flex flex-col gap-y-6 px-5 pb-5">
          {isWaitingForResponse ? (
            <Spinner text={" "}/>
          ) : (
            <div className="flex flex-col items-center z-50">
              <span className="block">
                {t('communication.inspections-invitations.delete.description')}
              </span>
              <SelectInput
                options={[
                  DeletionReason.ALREADY_COMPLETED_SUZUKI,
                  DeletionReason.ALREADY_COMPLETED_NON_SUZUKI,
                  DeletionReason.VEHICLE_SOLD_TO_UNKNOWN,
                  DeletionReason.VEHICLE_SCRAPPED,
                  DeletionReason.VEHICLE_EXPORTED,
                  DeletionReason.VEHICLE_STOLEN,
                  DeletionReason.DEACTIVATE_INVITATIONS,
                ].map((value) => {
                  return {
                    key: value,
                    name: t(
                      'communication.inspections-invitations.delete.options.' +
                      value
                    ),
                    value: value,
                  } as SelectOptionsProps;
                })}
                onSelect={(value) => setReason(value as DeletionReason)}
              />
            </div>
          )}
          <div className="flex flex-row gap-x-6 justify-end">
            <Button
              disabled={isWaitingForResponse}
              className="bg-white"
              buttonStyle={ButtonStyle.OUTLINE}
              onClick={handleCancel}
              px="5"
              py="3"
            >
              {t('communication.inspections-invitations.delete.cancel')}
            </Button>
            <Button
              disabled={isWaitingForResponse}
              onClick={() => {
                if (!reason) return;
                handleSubmit();
              }}
              px="5"
              py="3"
            >
              {t('communication.inspections-invitations.delete.submit')}
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default RemoveInvitationPopup;

import { BasicInput, Button } from '@suzuki-frontend-framework-update/ui';
import { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { environment } from '../../../environments/environment';
import { GetAuthTrait } from '../../models/user';
import { JsonError, SelfServiceLoginFlow, Session, SuccessfulSelfServiceLoginWithoutBrowser, UiText, } from '@ory/client';
import { SubmitSelfServiceLoginFlowWithPasswordMethodBody } from '@ory/client/api';
import { useDispatch } from 'react-redux';
import { userManagementActions } from '../../store/slices/user-management.slice';
import { AppDispatch } from '../../store/setup';
import './login.css'
import { toast } from 'react-toastify';
export interface LoginFormValues {
  // active: boolean,
  username: string;
  csrf_token: string;
  password: string;
}

export const Login: FunctionComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [usernameError, setUsernameError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [searchParams] = useSearchParams();
  const [flowID, setFlowID] = useState('');
  const [flow, setFlow] = useState({} as SelfServiceLoginFlow);
  const [formValues, setFormValues] = useState({
    // active: false,
    username: '',
    csrf_token: '',
    password: '',
  } as LoginFormValues);
  const [errorMessages, setErrorMessages] = useState([] as Array<UiText>);

  const isFlowExpired = (): boolean => {
    return flow && new Date(flow.expires_at).valueOf() < Date.now();
  };

  const customIdFailed = 'login-failed';

  const errorToast = (errorText: string) => {
    toast.error(errorText, {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      toastId: customIdFailed,
    });
  };
  
  useEffect(() => {
    const flowID = searchParams.get('flow');
    if (!flowID || isFlowExpired()) {
      window.open(
        environment.publicAuthURL + '/self-service/login/browser',
        '_self'
      );
    } else {
      setFlowID(flowID);
      const fetchAuthData = async () => {
        const res = await fetch(
          environment.publicAuthURL + `/self-service/login/flows?id=${flowID}`,
          { credentials: 'include' }
        );
        const data: SelfServiceLoginFlow | JsonError = await res.json();
        if ('ui' in data) {
          setFlow(data);
          if (data && data.ui && data.ui.nodes && data.ui.nodes.length > 0) {
            const csrf_token = GetAuthTrait(data.ui.nodes, 'csrf_token');
            setFormValues({
              // active: false,
              username: '',
              csrf_token: csrf_token?.value,
              password: '',
            });
          }
        }
        if ('error' in data) {
          window.open(
            environment.publicAuthURL + '/self-service/login/browser',
            '_self'
          );
        }
      };
      fetchAuthData().catch((err) => {
        console.error(err);
      });
    }
  }, [searchParams]);

  const handleSubmit = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    setUsernameError('');
    setPasswordError('');
    setErrorMessages([]);
    let valid = true;
    if (!username) {
      setUsernameError(t('login.error.empty.username'));
      valid = false;
    }
    if (!password) {
      setPasswordError(t('login.error.empty.password'));
      valid = false;
    }
    if (isFlowExpired()) {
      const msg = 'error.expired';
      const errorMsg: UiText = {
        text: msg,
        id: 0,
        type: 'Gone',
      };
      const errors = [...errorMessages];
      errors.push(errorMsg);
      setErrorMessages(errors);
      valid = false;
    }
    if (valid) {
      formValues.username = username;
      formValues.password = password;
      const sendData: SubmitSelfServiceLoginFlowWithPasswordMethodBody = {
        csrf_token: formValues.csrf_token,
        method: 'password',
        identifier: formValues.username,
        password: formValues.password,
      };
      try {
        const fetchURL =
          environment.publicAuthURL + `/self-service/login?flow=${flowID}`;
        const fetchOptions = {
          method: 'POST',
          credentials: 'include',
          // mode: "cors",
          headers: {
            'content-type': 'application/json',
          },
          body: JSON.stringify(sendData),
        } as RequestInit;
        const resp = await fetch(fetchURL, fetchOptions);

        const body:
          | SelfServiceLoginFlow
          | SuccessfulSelfServiceLoginWithoutBrowser = await resp.json();
        if ('ui' in body) {
          if (
            body &&
            body.ui &&
            body.ui.messages &&
            body.ui.messages.length > 0
          ) {
            // error happens
            setErrorMessages(body.ui.messages);
            errorMessages.map((err: UiText) => (
              errorToast(t('login.' + err.text))
            ))

            
          }
        } else if ('session' in body) {
          const session: Session = body.session;
          dispatch(userManagementActions.add({ currentUser: session }));
          navigate("/dashboard");
        }
      } catch (err) {
        console.error(err);
      }
    }
  };

  return (
    <>
      <div className="text-dark-gray text-center">{t('login.information')}</div>
      <div className="w-full">
        {flow && flow.ui && flow.ui.action && (
          <form className="w-full flex flex-col items-center justify-center gap-y-10">
            <input
              type={'hidden'}
              defaultValue={formValues.csrf_token}
              name={'csrf_token'}
            />
            <BasicInput
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              error={usernameError}
              label={{
                name: t('login.username'),
                position: 'top',
                hide: false,
              }}
              type="text"
              icon="person"
              width="22rem"
            />
            <BasicInput
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              error={passwordError}
              label={
                {
                  name: t('login.password'),
                  position: 'top',
                  hide: false,
                }
              }
              type="password"
              icon="password"
              width="22rem"
            />
            <div className="w-[22rem]">
              <Button py="4" className="w-full mb-1" onClick={handleSubmit}>
                {t('login.signIn')}
              </Button>
              <Link
                to="/forget-password"
                className="underline text-dark decoration-dark"
              >
                {t('login.forgotPassword')}
              </Link>
            </div>
          </form>
        )}
      </div>
    </>
  );
};

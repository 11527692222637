import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'moment/locale/de';
import './datepicker.scss';
import Icon from '../icon/icon';
import { useTranslation } from 'react-i18next';
export interface DatePickerProps {
  label?: {
    name?: string;
    position: 'top' | 'right' | 'left'; //only left, top and right
  };
  error?: string;
  width?: string;
  placeholder?: string;
  required?: boolean;
  id?: string | undefined;
  className?: string;
  selectedDay?: Date | null;
  handleDateChange: (date: Date) => void;
  isYear?: boolean;
  minDate?: Date;
  maxDate?: Date;
  disabled?: boolean;
  showTimeInput?: boolean;
}

export const DatePickerInput = (props: DatePickerProps) => {
  const { t } = useTranslation();
  const widthClass = props.width ? props.width : 'w-32';

  const [open, setOpen] = useState(false);

  const labler = props.label?.name ? (
    <label
      className="text-base text-dark font-suzuki-bold block mr-4 whitespace-nowrap"
      htmlFor={props.id}
    >
      {props.label.name.toUpperCase()}
      {props.required ? '*' : null}
    </label>
  ) : null;

  const error = (
    <p
      className="text-xs text-red-500 font-medium block mt-1 absolute"
      style={{ visibility: props.error ? 'visible' : 'hidden' }}
    >
      {props.error}
    </p>
  );

  return (
    <div>
      {props.label?.position === 'top' ? labler : ''}
      <div className="flex flex-nowrap items-center justify-between">
        {props.label?.position === 'left' ? labler : ''}
        <div
          className={`relative ${
            props.disabled ? 'text-dark-gray' : 'text-dark'
          }`}
        >
          {props.isYear ? (
            <DatePicker
              className={
                props.className
                  ? props.className
                  : 'border pl-4 pr-4 pt-2 pb-2 text-base text-black outline-none box-border border-gray-500 w-48'
              }
              selected={props.selectedDay}
              onChange={props.handleDateChange}
              dateFormat="yyyy"
              locale="de"
              showYearPicker
              minDate={props.minDate}
              maxDate={props.maxDate}
            />
          ) : (
            <>
              <Icon
                className="absolute z-10 inset-y-2 right-3 pointer-events-none"
                iconName="today"
              />
              {props.showTimeInput ? (
                <DatePicker
                  className={
                    props.disabled
                      ? `${widthClass} border pl-4 pr-4 pt-2 pb-2 text-base text-coolGray-200 outline-none box-border coolGray-200  `
                      : `${widthClass} border pl-4 pr-4 pt-2 pb-2 text-base text-black outline-none box-border trueGray-800 z-50`
                  }
                  selected={props.selectedDay}
                  onChange={props.handleDateChange}
                  placeholderText={props.placeholder}
                  disabled={props.disabled ? props.disabled : false}
                  dateFormat="dd.MM.yyyy HH:mm"
                  locale="de"
                  minDate={props.minDate}
                  maxDate={props.maxDate}
                  showTimeInput
                  timeInputLabel={t('datePicker.time')}
                  shouldCloseOnSelect={!open}
                  onCalendarOpen={() => setOpen(true)}
                />
              ) : (
                <DatePicker
                  className={
                    props.disabled
                      ? `${widthClass} border pl-4 pr-4 pt-2 pb-2 text-base text-coolGray-200 outline-none box-border coolGray-200  `
                      : `${widthClass} border pl-4 pr-4 pt-2 pb-2 text-base text-black outline-none box-border trueGray-800 z-50`
                  }
                  selected={props.selectedDay}
                  startDate={props.selectedDay}
                  placeholderText={props.placeholder}
                  disabled={props.disabled ? props.disabled : false}
                  dateFormat={'dd.MM.yyyy'}
                  locale="de"
                  onChange={props.handleDateChange}
                  minDate={props.minDate}
                  maxDate={props.maxDate}
                />
              )}
            </>
          )}
          {error}
        </div>
        {props.label?.position === 'right' ? labler : ''}
      </div>
    </div>
  );
};
export default DatePickerInput;

import {
  Button,
  ButtonStyle,
  Modal,
} from '@suzuki-frontend-framework-update/ui';
import { useTranslation } from 'react-i18next';
import { format } from 'react-string-format';

export interface RemoveActionPopupProps {
  onHandleCancel: () => void;
  onHandleRemove: () => void;
  modalState: boolean;
  type: 'user' | 'dealer' | 'contact-pool' | 'action';
  valueNameToDelete: string;
}

export const RemoveActionPopup = (props: RemoveActionPopupProps) => {
  const { t } = useTranslation();
  const prefixTextKey = `remove-action-popup.${props.type}`;

  const handleCancel = () => {
    props.onHandleCancel();
  };

  const handleRemove = () => {
    props.onHandleRemove();
  };

  return (
    <div className="flex justify-center items-center 	 ">
      <Modal
        key="remove-action-modal"
        show={props.modalState}
        title={t(`${prefixTextKey}.title`)}
        handleClose={() => props.onHandleCancel()}
      >
        <span className="text-dark-blue-gray px-8">
          {format(t(`${prefixTextKey}.desc`), props.valueNameToDelete)}
        </span>

        <div className="flex flex-row gap-x-6 justify-end pt-24 pr-4 pb-8">
          <Button
            className="bg-white  "
            buttonStyle={ButtonStyle.OUTLINE}
            onClick={handleCancel}
          >
            {t('remove-action-popup.cancel')}
          </Button>
          <Button onClick={handleRemove} px="5" py="3">
            {t(`${prefixTextKey}.remove`)}
          </Button>
        </div>
      </Modal>
    </div>
  );
};

import React, {FunctionComponent, useEffect, useState} from "react";
import {Navigate, Outlet, useLocation} from "react-router-dom";
import Footer from "../../components/footer/footer";
import {Header} from "@sde/navigation-widget";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../store/setup";
import {fetchUserManagement, selectAllUserManagement} from "../../store/slices/user-management.slice";
import {Session} from "@ory/client";
import {environment} from "../../../environments/environment";
import {isAutoDealer} from "../../helpers/session-utils";

function RequireAuth({children}: { children: JSX.Element }) {
  const location = useLocation();
  const entities = useSelector(selectAllUserManagement);
  const loading = useSelector((state: RootState) => state.userManagement.loadingStatus);
  if (entities.length === 0 && (loading === "loaded" || loading === "error")) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{from: location}} replace/>;
  }

  return children;
}

export const Protected: FunctionComponent = () => {
  const dispatch: AppDispatch = useDispatch();
  const entities = useSelector(selectAllUserManagement);
  const [session, setSession] = useState({} as Session);
  dispatch(fetchUserManagement());
  useEffect(() => {
    const id = setInterval(() => {
      dispatch(fetchUserManagement());
    }, 30000);
    return () => clearInterval(id);
  }, [dispatch]);

  useEffect(() => {
    const lastEntity = entities[entities.length - 1];
    if (lastEntity && lastEntity.currentUser) {
      setSession(lastEntity.currentUser);
    }
  }, [entities]);

  return (
    <RequireAuth>
      <div className="min-h-screen flex flex-col justify-between bg-gray">
        <Header
          firstName={session?.identity?.traits?.name?.first ?? ''}
          lastName={session?.identity?.traits?.name?.last ?? ''}
          isAdmin={session?.identity?.traits?.isAdmin ?? false}
          isAutoDealer={isAutoDealer(session)}
          publicAuthURL={environment.publicAuthURL}
          systemTitle={environment.systemTitle}
        />
        <Outlet/>
        <Footer/>
      </div>
    </RequireAuth>
  );
};

import React, { CSSProperties } from 'react';

import './icon.module.scss';

export interface IconProps {
  iconName: string;
  className?: string;
  style?: CSSProperties;
}

export function Icon(props: IconProps) {
  return (
    <span
      className={`${props.className ? props.className : ''} material-icons`}
      style={props.style}
    >
      {props.iconName}
    </span>
  );
}

export default Icon;

import { BasicInput, Button } from '@suzuki-frontend-framework-update/ui';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export function TwoFa() {
  const { t } = useTranslation();
  const [code, setCode] = useState('');
  const [codeError, setCodeError] = useState('');
  const [formValues, setFormValues] = useState({
    code: '',
  });

  const handleSubmit = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    setCodeError('');
    if (!code) {
      setCodeError(t('2fa.empty'));
      return;
    }
    formValues.code = code;
  };

  return (
    <>
      <div className="  text-dark-gray text-center ">
        {t('2fa.information')}
      </div>
      <div className="w-11/12 flex flex-col items-center">
        <form className="flex flex-col gap-y-10">
          <BasicInput
            value={code}
            onChange={(e) => setCode(e.target.value)}
            error={codeError}
            label={{ name: t('2fa.code'), position: 'left' }}
            type="text"
            icon="lock"
            width="22rem"
            placeholder="ZD999999"
          />
          <div className="w-full">
            <Button py="4" className="w-full mb-1" onClick={handleSubmit}>
              {t('2fa.confirm')}
            </Button>
            <Link to="/login" className="underline text-dark decoration-dark">
              {t('2fa.cancel')}
            </Link>
          </div>
        </form>
      </div>
    </>
  );
}

export default TwoFa;

export interface Invitation {
  id?: number;
  reasonForInspection?: string;
  calculatedMilleage?: number;
  calculatedInspectionDate?: Date;
  referenceDate?: Date;
  vehicleId?: number;
  dealerId?: string;
  creationDate?: Date;
  inspectionNumber?: number;
  lastSendoutDate?: Date;
  vehicle: Vehicle;
  sendoutErrors?: string;
  state?: State;
  reasonForDeletion?: DeletionReason;
  lastInspectionDate?: Date;
  completedDate?: Date;
}

export interface Vehicle {
  id?: number;
  vehicleObjectId?: string;
  firstRegistrationDate?: Date;
  model?: string;
  brand?: string;
  vin?: string;
  lastService?: Date;
  licensenumber?: string;
  contact: Contact;
}

export interface Contact {
  id?: number;
  contactObjectId?: string;
  firstname?: string;
  name?: string;
  salutation?: string;
  isCompany?: boolean;
  email?: string;
  addressLine1?: string;
  addressLine2?: string;
  zip?: string;
  city?: string;
  street?: string;
  countryCode?: string;
  districtAreaCode?: string;
}

export interface InvitationsRequest {
  filter?: InvitationsFilters;
  pageSize?: number;
  pageIndex?: number;
  sortField?: Field;
  sortDesc?: boolean;
}

export interface InvitationsFilters {
  search?: string;
  emailCanBeEmpty?: boolean;
  state?: State[];
  inspectionDateRanges?: InvitationsRange[];
  startCalculatedDate?: Date;
  endCalculatedDate?: Date;
  startDispatchDate?: Date;
  endDispatchDate?: Date;
  inspectionNumbers?: number[];
  dealerCodes?: string[];
}

export interface InvitationsRange {
  daysInPast?: string;
  daysInFuture?: string;
}

export enum Field {
  NONE,
  CALCULATED_INSPECTION_DATE,
  CALCULATED_MILEAGE,
  LAST_SENDOUT_DATE,
  CONTACT_NAME,
  CONTACT_FIRSTNAME,
  CONTACT_ADDRESS_LINE_1,
  CONTACT_ADDRESS_LINE_2,
  CONTACT_ZIP,
  CONTACT_CITY,
  CONTACT_STREET,
  CONTACT_COUNTRY,
  CONTACT_EMAIL,
  CONTACT_DISTRICT,
  VEHICLE_MODEL,
  VEHICLE_BRAND,
  VEHICLE_VIN,
  VEHICLE_FIRST_REGISTRATION,
  VEHICLE_LAST_SERVICE,
  INSPECTION_NUMBER,
}

export interface SendInvitationRequest {
  invitationsIDs: number[];
  placeholders: Placeholder[];
}

export interface Placeholder {
  template: Template;
  value: string;
}

export enum Template {
  MOTORCYCLE,
  SCOOTER,
}

export enum State {
  NOT_SENT = "NOT_SENT",
  WAITING_TO_BE_SENT= "WAITING_TO_BE_SENT",
  SENT= "SENT",
  EXPIRED_WAITING_TO_BE_SENT= "EXPIRED_WAITING_TO_BE_SENT",
  EXPIRED_SENT= "EXPIRED_SENT",
  COMPLETED= "COMPLETED",
  HIDDEN= "HIDDEN",
  DELETED= "DELETED",
  EXPIRED= "EXPIRED",
}

export enum DeletionReason {
  ALREADY_COMPLETED_SUZUKI = "ALREADY_COMPLETED_SUZUKI",
  ALREADY_COMPLETED_NON_SUZUKI= "ALREADY_COMPLETED_NON_SUZUKI",
  VEHICLE_SOLD_TO_UNKNOWN= "VEHICLE_SOLD_TO_UNKNOWN",
  VEHICLE_SCRAPPED= "VEHICLE_SCRAPPED",
  VEHICLE_EXPORTED= "VEHICLE_EXPORTED",
  VEHICLE_STOLEN= "VEHICLE_STOLEN",
  DEACTIVATE_INVITATIONS= "DEACTIVATE_INVITATIONS",
}

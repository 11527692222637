import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../setup'
import { ContactAddressFilter, ContactFilter } from "../../models/contact";

export const CONTACT_FILTER_FEATURE_KEY = 'contact-filter';

export interface ContactFilterState {
  key: string;
  peopleCurrentRow: string | undefined;
  companiesCurrentRow: string | undefined;
  contactPeopleFilter: ContactFilterData;
  contactCompaniesFilter: ContactFilterData;
  selectedContactPeopleAddressFilter: ContactAddressFilter;
  selectedCompanyContactAddressFilter: ContactAddressFilter;

}

export interface ContactFilterData {
  filter: ContactFilter;
  pageIndex: number;
}

export const initialContactFilterState: ContactFilterState = {
  key: "",
  peopleCurrentRow: undefined,
  contactPeopleFilter: {
    filter: { contactAddressFilter: ContactAddressFilter.All, showHiddenContacts: false , dealers: []  },
    pageIndex: 0
  },
  companiesCurrentRow: undefined,
  contactCompaniesFilter: {
    filter: { contactAddressFilter: ContactAddressFilter.All, showHiddenContacts: false , dealers: [] },
    pageIndex: 0
  },
  selectedContactPeopleAddressFilter: ContactAddressFilter.All,
  selectedCompanyContactAddressFilter: ContactAddressFilter.All,

};

const contactFilterSlice = createSlice({
  name: CONTACT_FILTER_FEATURE_KEY,
  initialState: initialContactFilterState,
  reducers: {
    init: (state, { payload: key }: PayloadAction<string>) => {
      state.key = key;
      state.peopleCurrentRow = undefined;
      state.contactPeopleFilter = {
        filter: { contactAddressFilter: ContactAddressFilter.All, showHiddenContacts: false , dealers: []  },
        pageIndex: 0
      };
      state.companiesCurrentRow = undefined;
      state.contactCompaniesFilter = {
        filter: { contactAddressFilter: ContactAddressFilter.All, showHiddenContacts: false , dealers: [] },
        pageIndex: 0
      };
    },
    updatePeopleFilter: (state, { payload: contactFilter }: PayloadAction<ContactFilter>) => {
      state.contactPeopleFilter.filter = contactFilter;
    },
    updateCompaniesFilter: (state, { payload: contactFilter }: PayloadAction<ContactFilter>) => {
      state.contactCompaniesFilter.filter = contactFilter;
    },
    updatePeoplePageIndex: (state, { payload: pageIndex }: PayloadAction<number>) => {
      state.contactPeopleFilter.pageIndex = pageIndex;
    },
    updateCompaniesPageIndex: (state, { payload: pageIndex }: PayloadAction<number>) => {
      state.contactCompaniesFilter.pageIndex = pageIndex;
    },
    updateCompaniesCurrentRow: (state, { payload: currentRow }: PayloadAction<string | undefined>) => {
      state.companiesCurrentRow = currentRow;
    },
    updatePeopleCurrentRow: (state, { payload: currentRow }: PayloadAction<string | undefined>) => {
      state.peopleCurrentRow = currentRow;
    },
    updateSelectedContactPeopleAddressFilter: (state, { payload }: PayloadAction<ContactAddressFilter>) => {
      state.selectedContactPeopleAddressFilter = payload;
    },
    updateSelectedCompanyContacAddressFilter: (state, { payload }: PayloadAction<ContactAddressFilter>) => {
      state.selectedCompanyContactAddressFilter = payload;
    },
  }
});

export const contactFilterActions = contactFilterSlice.actions;

export const contactFilterSelector = (state: RootState) => state[CONTACT_FILTER_FEATURE_KEY]

export const contactFilterReducer = contactFilterSlice.reducer;

import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
  PayloadAction
} from "@reduxjs/toolkit";
import { Session } from "@ory/client";
import { environment } from "../../../environments/environment";
import { RootState } from "../setup";

export const USER_MANAGEMENT_FEATURE_KEY = "userManagement";

/*
 * Update these interfaces according to your requirements.
 */
export interface UserManagementEntity {
  currentUser: Session | undefined;
}

export interface UserManagementState extends EntityState<UserManagementEntity> {
  loadingStatus: "not loaded" | "loading" | "loaded" | "error";
  ssoLoadingStatus: "not loaded" | "loading" | "loaded" | "error";
  error: string;
}

export const userManagementAdapter =
  createEntityAdapter<UserManagementEntity>();

/**
 * Export an effect using createAsyncThunk from
 * the Redux Toolkit: https://redux-toolkit.js.org/api/createAsyncThunk
 *
 * e.g.
 * ```
 * import React, { useEffect } from 'react';
 * import { useDispatch } from 'react-redux';
 *
 * // ...
 *
 * const dispatch = useDispatch();
 * useEffect(() => {
 *   dispatch(fetchUserManagement())
 * }, [dispatch]);
 * ```
 */

export const fetchUserManagement = createAsyncThunk(
  "userManagement/fetchStatus",
  async (_, thunkAPI) => {
    const GoToLogin = () => {
      window.open("/login", "_self");
    };

    try {
      const resp = await fetch(
        environment.publicAuthURL + `/sessions/whoami`,
        {
          credentials: "include"
        }
      );
      if (!resp.ok) {
        GoToLogin();
      }
      return await resp.json();
    } catch (err) {
      GoToLogin();
    }
  }
);

export const fetchUserForSSO = createAsyncThunk(
  "ssoUser/fetchStatus",
  async (_, thunkAPI) => {
    try {
      const resp = await fetch(
        environment.publicAuthURL + `/sessions/whoami`,
        {
          credentials: "include"
        }
      );
      if (!resp.ok) {
        return null
      }
      return await resp.json();
    } catch (err) {
      return null
    }
  }
);

export const initialUserManagementState: UserManagementState =
  userManagementAdapter.getInitialState({
    loadingStatus: "not loaded",
    ssoLoadingStatus: "not loaded",
    error: ""
  });

export const userManagementSlice = createSlice({
  name: USER_MANAGEMENT_FEATURE_KEY,
  initialState: initialUserManagementState,
  reducers: {
    add: userManagementAdapter.addOne,
    remove: userManagementAdapter.removeOne
    // ...
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserManagement.pending, (state: UserManagementState) => {
        state.loadingStatus = "loading";
      })
      .addCase(
        fetchUserManagement.fulfilled,
        (
          state: UserManagementState,
          action: PayloadAction<Session>
        ) => {
          if ("error" in action.payload) {
            state.loadingStatus = "error";
            state.error = "cannot get data";
          }
          if ("identity" in action.payload) {
            userManagementAdapter.addOne(state, { currentUser: action.payload });
            state.loadingStatus = "loaded";
          }

        }
      )
      .addCase(
        fetchUserManagement.rejected,
        (state: UserManagementState, action) => {
          state.loadingStatus = "error";
          state.error = action.error.message ? action.error.message : "";
        }
      )
      .addCase(fetchUserForSSO.pending, (state: UserManagementState) => {
        state.ssoLoadingStatus = "loading";
      })
      .addCase(
        fetchUserForSSO.fulfilled,
        (
          state: UserManagementState,
          action: PayloadAction<Session>
        ) => {
          if (action.payload === null) {
            state.ssoLoadingStatus = "error";
            state.error = "cannot get data";
            return
          }
          if ("error" in action.payload) {
            state.ssoLoadingStatus = "error";
            state.error = "cannot get data";
          }
          if ("identity" in action.payload) {
            userManagementAdapter.addOne(state, { currentUser: action.payload });
            state.ssoLoadingStatus = "loaded";
          }

        }
      )
      .addCase(
        fetchUserForSSO.rejected,
        (state: UserManagementState, action) => {
          if (action.payload == null) {
            return
          }
          state.ssoLoadingStatus = "error";
          state.error = action.error.message ? action.error.message : "";
        }
      );
  }
});

/*
 * Export reducer for store configuration.
 */
export const userManagementReducer = userManagementSlice.reducer;

/*
 * Export action creators to be dispatched. For use with the `useDispatch` hook.
 *
 * e.g.
 * ```
 * import React, { useEffect } from 'react';
 * import { useDispatch } from 'react-redux';
 *
 * // ...
 *
 * const dispatch = useDispatch();
 * useEffect(() => {
 *   dispatch(userManagementActions.add({ id: 1 }))
 * }, [dispatch]);
 * ```
 *
 * See: https://react-redux.js.org/next/api/hooks#usedispatch
 */
export const userManagementActions = userManagementSlice.actions;

/*
 * Export selectors to query state. For use with the `useSelector` hook.
 *
 * e.g.
 * ```
 * import { useSelector } from 'react-redux';
 *
 * // ...
 *
 * const entities = useSelector(selectAllUserManagement);
 * ```
 *
 * See: https://react-redux.js.org/next/api/hooks#useselector
 */
const { selectAll, selectEntities, selectById } = userManagementAdapter.getSelectors();

export const getUserManagementState = (
  rootState: RootState
): UserManagementState => rootState[USER_MANAGEMENT_FEATURE_KEY];

export const selectAllUserManagement = createSelector(
  getUserManagementState,
  selectAll
);

export const selectUserManagementEntities = createSelector(
  getUserManagementState,
  selectEntities
);

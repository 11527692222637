import React, { Component } from 'react';
import Tab, { TabProps } from '../tab/tab';

export interface TabsState {
  activeTabIndex: number;
}

export interface TabsProps {
  defaultActiveTabIndex: number;
  onTabChange?: (index: number) => void;
  children: React.ReactElement<TabProps> | React.ReactElement<TabProps>[];
  disableStyling?: boolean;
}
/**
 * A basic tabs component.
 * Please handle error checking yourself.
 * You can change the active tab from outside with a ref and the function handleTabChange
 */
export class Tabs extends Component<TabsProps, TabsState> {
  constructor(props: TabsProps) {
    super(props);
    this.state = {
      activeTabIndex: props.defaultActiveTabIndex,
    };
    const tabTypeError = new Error('Error: type of child is not Tab!');
    if (Array.isArray(props.children)) {
      props.children.forEach((c) => {
        if (c.type !== Tab) {
          throw tabTypeError;
        }
      });
    } else {
      if (props.children.type !== Tab) {
        throw tabTypeError;
      }
    }
  }

  // Toggle currently active tab
  handleTabClick = (tabIndex: number) => {
    this.setState({ activeTabIndex: tabIndex });
    if (this.props.onTabChange) this.props.onTabChange(tabIndex);
  };

  // Toggle currently active tab
  handleTabChange = (tabIndex: number) => {
    this.setState({ activeTabIndex: tabIndex });
  };

  // Encapsulate <Tabs/> component API as props for <Tab/> children
  renderChildrenWithTabsApiAsProps = () => {
    return React.Children.map(this.props.children, (child, index) => {
      return React.cloneElement(child as React.ReactElement<TabProps>, {
        onClick: this.handleTabClick,
        tabIndex: index,
        isActive: index === this.state.activeTabIndex,
      });
    });
  };

  // Render current active tab content
  renderActiveTabContent = () => {
    const { children } = this.props;
    const { activeTabIndex } = this.state;
    if (children && Array.isArray(children) && children[activeTabIndex]) {
      if (
        Object.prototype.hasOwnProperty.call(children[activeTabIndex], 'props')
      ) {
        return children[activeTabIndex].props.children;
      }
    }
    return;
  };
  render() {
    return (
      <div>
        <ul className="pl-8 text-base text-dark border-dark flex list-none border-b ">
          {this.renderChildrenWithTabsApiAsProps()}
        </ul>
        <div className={this.props.disableStyling ? '' : 'p-8'}>
          {this.renderActiveTabContent()}
        </div>
      </div>
    );
  }
}

export default Tabs;

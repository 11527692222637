import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Button,
  Headline,
  Icon,
  Table,
  Tooltip,
} from '@suzuki-frontend-framework-update/ui';
import * as Popper from '@popperjs/core';
import './dealer-list.module.scss';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RemoveActionPopup } from '../../components/remove-action-popup/remove-action-popup';
import { useApiGetData } from '../../components/useApiGetData/useApiGetData';
import { useLocation } from 'react-router-dom';
import { environment } from '../../../environments/environment';
import { Dealer, DealersRequestBody } from '../../models/dealer';
import { sendApiRequest } from '../../components/send-api-request/send-api-request';
import {
  dealerFilterActions,
  dealerFilterSelector,
} from '../../store/slices/dealer-filter.slice';
import { AppDispatch } from '../../store/setup';
import { toast } from 'react-toastify';
import { DataFetchProps } from '../../models/others';

export function DealerList() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const pageSize = 100;

  const [removeActionState, setRemoveActionState] = useState<boolean>(false);
  const [selectedDealer, setSelectedDealer] = useState<string | null>();
  const [loadingReimport, setLoadingReimport] = useState(false);

  const customIdSuccess = 'custom-id-success';
  const customIdFailed = 'custom-id-Failed';
  const customIdWarn = 'custom-id-Warn';

  const successToast = (successText: string) => {
    toast.success(successText, {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      toastId: customIdSuccess,
    });
  };

  const errorToast = (errorText: string) => {
    toast.error(errorText, {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      toastId: customIdFailed,
    });
  };
  const waringToast = (warmText: string) => {
    toast.warn(warmText, {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      toastId: customIdWarn,
    });
  };

  const dealerFilterState = useSelector(dealerFilterSelector);
  const dispatch: AppDispatch = useDispatch();
  if (dealerFilterState.key === '' || location.key !== dealerFilterState.key) {
    dispatch(dealerFilterActions.init(location.key));
  }

  const [dealersRequestBody, setDealersRequestBody] =
    useState<DealersRequestBody>({
      pageSize: pageSize,
      pageIndex: dealerFilterState.dealerFilter.pageIndex,
      filter: dealerFilterState.dealerFilter.filter,
    });

  const dealerData = useApiGetData<Dealer[]>(
    `${environment.restEndpoint}/dealer/dealer-service/dealers/filter`,
    'post',
    'dealers',
    dealersRequestBody
  );

  const dealerCountData = useApiGetData<number>(
    `${environment.restEndpoint}/dealer/dealer-service/dealers/count`,
    'post',
    'count',
    dealersRequestBody
  );

  // TODO change remove hardcoded file name and make it variable
  const handleReimport = async () => {
    setLoadingReimport(true);
    sendApiRequest(
      `${environment.restEndpoint}/dealer/dealer-service/import?importFile=daten_crm_mc_hit.txt`,
      'post'
    )
      .then((response) => {
        if (response.ok) {
          successToast(t('dealer-information.data-reimported'));
          setTimeout(function () {
            window.location.reload();
          }, 3000);
        } else {
          errorToast(t('dealer-information.data-reimported-error'));
        }
        setLoadingReimport(false);
      })
      .catch((error) => {
        errorToast(t('dealer-information.data-reimported-error') + ' ' + error);
      });
  };

  const handleShow = (id: string, rowId: string) => {
    dispatch(dealerFilterActions.updateDealerCurrentRow(rowId));
    navigate('../../dealer/' + id);
  };

  const handlePreviousRow = useCallback(
    async ({ itemsRef }: any) => {
      if (dealerData.data && dealerFilterState.currentRow) {
        itemsRef.current
          .get(dealerFilterState.currentRow)
          ?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        dispatch(dealerFilterActions.updateDealerCurrentRow(undefined));
      }
    },
    [dealerData.data, dealerData.loading]
  );

  const handleDelete = async (id: string) => {
    sendApiRequest(
      `${environment.restEndpoint}/dealer/dealer-service/dealer?dealercode=${id}`,
      'get'
    ).then(async (response) => {
      const data = await response.json();

      if (data.dealer.deleted) {
        waringToast(t('dealer-list.delete.existed', { id }));
      } else {
        await sendApiRequest(
          `${environment.restEndpoint}/dealer/dealer-service/dealer?dealercode=${data.dealer.dealercode}&primaryKey=${data.dealer.objectID}`,
          'delete'
        )
          .then((response) => {
            if (response.ok) {
              successToast(t('dealer-list.delete.success', { id }));
              setTimeout(function () {
                window.location.reload();
              }, 3000);
            } else {
              errorToast(t('dealer-list.delete.fail', { id }));
            }
          })
          .catch((error) => {
            errorToast(t('dealer-list.delete.fail', { id }) + ' ' + error);
          });
      }
    });

    clearRemoveActionState();
  };

  const clearRemoveActionState = () => {
    setRemoveActionState(false);
    setSelectedDealer(null);
  };

  const handleDealerFiltersSearch = (value: string) => {
    dispatch(
      dealerFilterActions.updateDealerFilter({
        search: value,
      })
    );
  };

  const handleDealerFilter = (id: string, selected: Array<string>) => {
    const filters = [];
    //console.log(id, selected);
    for (const value of selected) {
      filters.push({
        field: id,
        value: value,
      });
    }
    dispatch(dealerFilterActions.updateDealerFilter({ filter: filters }));
  };

  const fetchDealersData = useCallback(
    async ({ pageSize, pageIndex, pageSortBy }: DataFetchProps) => {
      dispatch(dealerFilterActions.updateDealerPageIndex(pageIndex));
      let sortByDir = 1;
      let sortByField = '';
      if (pageSortBy && pageSortBy.length > 0) {
        const sortParams = pageSortBy[0];
        sortByDir = sortParams.desc ? -1 : 1;
        sortByField = sortFieldMapper(sortParams.id);
      }
      setDealersRequestBody({
        pageSize: pageSize,
        pageIndex: pageIndex,
        filter: dealerFilterState.dealerFilter.filter,
        sortField: sortByField,
        sortDir: sortByDir,
      });
    },
    [dealerFilterState.dealerFilter.filter]
  );

  const sortFieldMapper = (uistring: string): string => {
    switch (uistring) {
      case 'dealercode':
        return 'dealercode';
      case 'name1':
        return 'name';
      case 'address':
        return 'addressline1';
      case 'active':
        return 'deleted';
      default:
        return 'dealercode';
    }
  };

  const dealerFilters = {
    id: 'dealerFilters',
    key: 'dealerFilters',
    placeholder: t('dealer-list.filter.search'),
    onSearchUpdate: handleDealerFiltersSearch,
    searchDelay: 500,
    initialSearchValue: dealerFilterState.dealerFilter.filter.search,
    filters: [
      {
        id: 'deleted',
        name: t('dealer-list.filter.status.title'),
        items: [
          { id: 'true', name: t('dealer-list.filter.status.deleted') },
          { id: 'false', name: t('dealer-list.filter.status.acitve') },
        ],
        onChange: handleDealerFilter,
      },
    ],
  };

  const columns = [
    {
      Header: t('dealer-list.number'),
      accessor: 'dealercode',
    },
    {
      Header: t('dealer-list.name'),
      accessor: 'name1',
      Cell: ({ row }: any) => {
        return (
          <span>
            {row.original.name} {row.original.nameAppendum}
          </span>
        );
      },
    },
    {
      Header: t('dealer-list.address'),
      accessor: 'address',
      Cell: ({ row }: any) => {
        return (
          <div className="flex flex-col">
            <span>{row.original.addressLine1}</span>
            <span>
              {row.original.zipCode} {row.original.city}
            </span>
          </div>
        );
      },
    },
    {
      Header: t('dealer-list.active'),
      accessor: 'active',
      Cell: ({ row }: any) => {
        return (
          <span>
            {row.original.deleted ? (
              <Tooltip
                content={t('dealer-list.description.inactive')}
                placement={Popper.top}
              >
                <Icon className="text-black" iconName="close" />
              </Tooltip>
            ) : (
              <Tooltip
                content={t('dealer-list.description.active')}
                placement={Popper.top}
              >
                <Icon iconName="done" className="text-black" />
              </Tooltip>
            )}
          </span>
        );
      },
    },
    {
      Header: t('dealer-list.actions'),
      accessor: 'actions',
      Cell: ({ row }: any) => {
        return (
          <div className="flex flex-row gap-x-6">
            <Tooltip
              content={t('dealer-list.description.show')}
              placement={Popper.top}
            >
              <button
                onClick={() => handleShow(row.original.dealercode, row.id)}
              >
                <Icon iconName="visibility" className="text-black" />
              </button>
            </Tooltip>
            <Tooltip
              content={t('dealer-list.description.delete')}
              placement={Popper.top}
            >
              <button
                onClick={() => {
                  setSelectedDealer(row.original.dealercode);
                  setRemoveActionState(true);
                }}
              >
                <Icon iconName={'delete'} className="text-black" />
              </button>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  return (
    <div className={loadingReimport ? 'p-8 cursor-wait ' : 'p-8 '}>
      {selectedDealer && (
        <RemoveActionPopup
          onHandleCancel={() => clearRemoveActionState()}
          onHandleRemove={() => handleDelete(selectedDealer)}
          modalState={removeActionState}
          type="dealer"
          valueNameToDelete={selectedDealer}
        />
      )}
      <Headline title={t('dealer-list.title')} className="mb-8 mt-2 " />
      {loadingReimport ? (
        <Tooltip
          content={t('dealer-list.description.reimporting')}
          placement={Popper.right}
        >
          <Button
            onClick={handleReimport}
            className="gap-x-2 mb-4 inline "
            px="5"
            py="3"
          >
            <Icon iconName="autorenew " className="animate-spin" />
            {t('dealer-information.data-reimported-msg')}
          </Button>
        </Tooltip>
      ) : (
        <Tooltip
          content={t('dealer-list.description.reimport')}
          placement={Popper.right}
        >
          <Button
            onClick={handleReimport}
            className="gap-x-2 mb-4"
            px="5"
            py="3"
          >
            <Icon iconName="autorenew" />
            {t('dealer-list.reimport')}
          </Button>
        </Tooltip>
      )}

      <Table
        filter={dealerFilters}
        pagination
        columns={columns}
        rowCount={dealerCountData.data}
        countLabel={
          dealerCountData.data && dealerCountData.data === 1
            ? t('common.list.count.entry')
            : t('common.list.count.entries')
        }
        data={dealerData.data ? dealerData.data : []}
        fetchData={fetchDealersData}
        loading={dealerData.loading}
        initPageSize={pageSize}
        initialPage={dealerFilterState.dealerFilter.pageIndex}
        resetOnChange={dealerFilterState.dealerFilter.filter}
        handleScrollToRow={handlePreviousRow}
      />
    </div>
  );
}

export default DealerList;

import {sendApiRequest} from "../../components/send-api-request/send-api-request";
import {environment} from "../../../environments/environment";
import {Sweepstake} from "../../models/sweepstake";

export async function fetchSweepstakesData() {
  const { sweepstakes }: { sweepstakes: Sweepstake[] } = await sendApiRequest(`${environment.restEndpoint}/action/sweepstake`, 'GET')
    .then(response => response.json());
  return sweepstakes;
}

export async function createSweepstake(sweepstake: Sweepstake) {
  return await sendApiRequest(`${environment.restEndpoint}/action/sweepstake`, 'POST', sweepstake);
}

export async function updateSweepstake(sweepstake: Sweepstake) {
  return await sendApiRequest(`${environment.restEndpoint}/action/sweepstake`, 'PUT', sweepstake);
}

export async function removeSweepstake(id: string) {
  return await sendApiRequest(`${environment.restEndpoint}/action/sweepstake/${id}`, 'DELETE');
}

import { BasicInput, Button } from '@suzuki-frontend-framework-update/ui';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { environment } from '../../../environments/environment';
import {
  SelfServiceRecoveryFlow,
  SubmitSelfServiceRecoveryFlowBody,
  UiText,
} from '@ory/client';
import { GetAuthTrait } from '../../models/user';
import { validateEmail } from '../../helpers/email-validation-helper';

export function ForgetPassword() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [searchParams] = useSearchParams();
  const [flowID, setFlowID] = useState('');
  const [formValues, setFormValues] = useState({
    csrf_token: '',
    email: '',
  });
  const [errorMessages, setErrorMessages] = useState([] as Array<UiText>);

  useEffect(() => {
    const flowID = searchParams.get('flow');
    if (!flowID) {
      window.open(
        environment.publicAuthURL + '/self-service/recovery/browser',
        '_self'
      );
    } else {
      setFlowID(flowID);
      const fetchAuthData = async () => {
        const res = await fetch(
          environment.publicAuthURL +
            `/self-service/recovery/flows?id=${flowID}`,
          { credentials: 'include' }
        );
        const data: SelfServiceRecoveryFlow = await res.json();
        if (data && data.ui && data.ui.nodes && data.ui.nodes.length > 0) {
          const csrf_token = GetAuthTrait(data.ui.nodes, 'csrf_token');
          setFormValues({
            // active: false,
            csrf_token: csrf_token?.value,
            email: '',
          });
        }
      };
      fetchAuthData().catch((err) => {
        console.error(err);
      });
    }
  }, [searchParams]);

  const handleSubmit = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    setEmailError('');
    if (!email) {
      setEmailError(t('forget-password.empty'));
      return;
    }
    if (!validateEmail(email)) {
      setEmailError(t('forget-password.invalid-email'));
      return;
    }
    formValues.email = email;
    const sendData: SubmitSelfServiceRecoveryFlowBody = {
      csrf_token: formValues.csrf_token,
      method: 'link',
      email: formValues.email,
    };
    try {
      const fetchURL =
        environment.publicAuthURL + `/self-service/recovery?flow=${flowID}`;
      const fetchOptions = {
        method: 'POST',
        credentials: 'include',
        // mode: "cors",
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(sendData),
      } as RequestInit;
      await fetch(fetchURL, fetchOptions);
      navigate('/forget-password-success');
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <div className="text-dark-gray text-center">
        {t('forget-password.information')}
      </div>
      <div className="w-11/12 flex flex-col items-center">
        {errorMessages && errorMessages.length > 0 && (
          <div className={'text-red-500'}>
            {errorMessages.map((err: UiText) => (
              <p>{err.text}</p>
            ))}
          </div>
        )}
        <form className="flex flex-col gap-y-10">
          <BasicInput
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            error={emailError}
            label={t('forget-password.email')}
            type="email"
            icon="person"
            width="22rem"
          />
          <div className="w-full">
            <Button py="4" className="w-full mb-1" onClick={handleSubmit}>
              {t('forget-password.reset')}
            </Button>
            <Link to="/login" className="underline text-dark decoration-dark">
              {t('forget-password.back-to-login')}
            </Link>
          </div>
        </form>
      </div>
    </>
  );
}

export default ForgetPassword;

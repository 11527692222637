import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import { de } from './assets/i18n/de';
import { en } from './assets/i18n/en';

const resources = {
  de: {
    translation: de
  },
  en: {
    translation: en
  }
};

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        debug: true,
        fallbackLng: 'de',
        interpolation: {
            escapeValue: false,
        }
    });

export default i18n;
'use client';

import React, { useEffect, useState } from 'react';
import { BasicTable, Button, Headline, Icon, Tooltip } from '@sde/basic-ui-library';
import { useTranslation } from 'react-i18next';
import { Sweepstake } from '../../models/sweepstake';
import { ColumnDef, createColumnHelper, Row } from '@tanstack/react-table';
import {Newsletter, NewsletterActivationStatus} from '../../models/newsletter';
import { Tenant } from '../../models/tenant';
import EditSweepstakePopup from '../../components/edit-sweepstake-popup/edit-sweepstake-popup';
import RemoveSweepstakePopup from '../../components/remove-sweepstake-popup/remove-sweepstake-popup';
import { toast } from 'react-toastify';
import {fetchNewslettersData} from "../../store/slices/newsletter.slice";
import {fetchSweepstakesData} from "../../store/slices/sweepstake.slice";

export default function Sweepstakes() {
  const { t } = useTranslation();
  const [sweepstakesData, setSweepstakesData] = useState<Sweepstake[]>([]);
  const [newsletterData, setNewsletterData] = useState<Newsletter[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [editSweepstakeModalOpen, setEditSweepstakeModalOpen] = useState<boolean>(false);
  const [removeSweepstakeModalOpen, setRemoveSweepstakeModalOpen] = useState<boolean>(false);
  const [selectedSweepstake, setSelectedSweepstake] = useState<Sweepstake | undefined>();

  const getNewsletters = async () => {
    const response = await fetchNewslettersData(Tenant.UNDEFINED, NewsletterActivationStatus.UNDEFINED)
    setNewsletterData(response);
  };

  const getSweepstakes = async () => {
    const response = await fetchSweepstakesData();
    setSweepstakesData(response);
  };

  const loadData = async () => {
    setIsLoading(true);
    try {
      await getNewsletters();
      await getSweepstakes();
    } catch (e) {
      console.error(e);
      toast.error(t('error_popup.loading_data').toString());
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadData().catch(e => console.error(e));
  }, []);

  const openCreateSweepstake = () => {
    setSelectedSweepstake(undefined);
    setEditSweepstakeModalOpen(true);
  };

  const openEditSweepstake = (sweepstake: Sweepstake) => {
    setSelectedSweepstake(sweepstake);
    setEditSweepstakeModalOpen(true);
  };

  const openRemoveSweepstake = (sweepstake: Sweepstake) => {
    setSelectedSweepstake(sweepstake);
    setRemoveSweepstakeModalOpen(true);
  };

  useEffect(() => {
    loadData().catch(e => console.error(e));
  }, []);

  const sweepstakeColumnHelper = createColumnHelper<Sweepstake>();
  const columns: ColumnDef<Sweepstake>[] = [
    sweepstakeColumnHelper.accessor((row: Sweepstake) => row, {
      id: 'name',
      enableSorting: false,
      header: () => <span>{t('communication.sweepstake.overview.table_header_name')}</span>,
      cell: (cell) => {
        const { row } = cell as { row: Row<Sweepstake> };
        return <span>{row.original.name}</span>;
      },
    }),
    sweepstakeColumnHelper.accessor((row: Sweepstake) => row, {
      id: 'code',
      enableSorting: false,
      header: () => <span>{t('communication.sweepstake.overview.table_header_code')}</span>,
      cell: (cell: unknown) => {
        const { row } = cell as { row: Row<Sweepstake> };
        return <span>{row.original.code}</span>;
      },
    }),
    sweepstakeColumnHelper.accessor((row: Sweepstake) => row, {
      id: 'newsletter',
      enableSorting: false,
      header: () => <span>{t('communication.sweepstake.overview.table_header_newsletter')}</span>,
      cell: (cell: unknown) => {
        const { row } = cell as { row: Row<Sweepstake> };
        return <span>{newsletterData.find(n => n.objectId === row.original.newsletterId)?.name ?? ''}</span>;
      },
    }),
    sweepstakeColumnHelper.accessor((row: Sweepstake) => row, {
      id: 'actions',
      enableSorting: false,
      header: () => <span></span>,
      cell: (cell: unknown) => {
        const { row } = cell as { row: Row<Sweepstake> };
        return (
          <div className="flex gap-4 justify-end">
            <Tooltip content={t('communication.sweepstake.overview.tooltip_edit')}>
              <button onClick={() => openEditSweepstake(row.original)}>
                <Icon iconName="edit"/>
              </button>
            </Tooltip>
            <Tooltip content={t('communication.sweepstake.overview.tooltip_delete')}>
              <button onClick={() => openRemoveSweepstake(row.original)}>
                <Icon iconName="delete"/>
              </button>
            </Tooltip>
          </div>
        );
      },
    }),
  ].filter((item) => item) as ColumnDef<Sweepstake>[];

  return (
    <>
      <div className="p-6">
        <div className="mb-8 mt-2 flex flex-row justify-between items-center">
          <Headline title={t('communication.sweepstake.overview.title')}/>

          <div className="justify-end">
            <Tooltip content={t('communication.sweepstake.overview.tooltip_create')}>
              <Button
                onClick={() => openCreateSweepstake()}
                className="w-fit h-fit"
                px="1"
                py="1"
              >
                <Icon iconName="add" className="!text-2xl !w-7"/>
              </Button>
            </Tooltip>
          </div>
        </div>
        <BasicTable<Sweepstake>
          key="sweepstakes-table"
          basic
          columns={columns}
          data={sweepstakesData}
          loading={isLoading}
          countLabel={
            sweepstakesData?.length === 1
              ? t('common.list.count.entry')
              : t('common.list.count.entries')
          }
          fetchData={() => undefined}
          rowCount={sweepstakesData.length}
        />
      </div>
      <EditSweepstakePopup isOpen={editSweepstakeModalOpen}
                           sweepstake={selectedSweepstake}
                           newsletters={newsletterData}
                           onClose={() => setEditSweepstakeModalOpen(false)}
                           onUpdate={() => loadData()}/>
      <RemoveSweepstakePopup isOpen={removeSweepstakeModalOpen}
                             sweepstake={selectedSweepstake}
                             onClose={() => setRemoveSweepstakeModalOpen(false)}
                             onDelete={() => loadData()}/>
    </>
  );
}

type ResponseFormatType = {
  id: number;
  name: string;
}

export default ResponseFormatType;

export enum ResponseFormatTypeEnum {
  NUMERIC = 'numeric',
  BOOLEAN = 'boolean',
  STRING = 'string',
}

export const mapResponseFormatTypesId = (legacyId: number) => ({
  1: ResponseFormatTypeEnum.NUMERIC,
  2: ResponseFormatTypeEnum.BOOLEAN,
  3: ResponseFormatTypeEnum.STRING,
}[legacyId] || ResponseFormatTypeEnum.NUMERIC);

export const mapResponseFormatTypes = (name: string) => ({
  [ResponseFormatTypeEnum.NUMERIC]: 1,
  [ResponseFormatTypeEnum.BOOLEAN]: 2,
  [ResponseFormatTypeEnum.STRING]: 3,
}[name] || 1);

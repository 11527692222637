export interface ChangeHistoryEntry {
  dateOfChange: Date;
  editor: string;
  values: ChangeHistoryValue[];
}

export interface ChangeHistoryValue {
  attributeName: string;
  previousValue: string;
  newValue: string;
}

export const CONTACT_TRANSLATION_KEY = "contact-details.history";
export const CONTACT_TRANSLATION_ATTRIBUTES = ["salutation","title","firstname","name", "deliverable", "street", "zip",
                                           "city", "countrycode", "email"];

export const VEHICLE_TRANSLATION_KEY = "vehicle-details.history";
export const VEHICLE_TRANSLATION_ATTRIBUTES = ["vin", "brand", "model-name", "model-type", "model-code", "first-registration",
                                           "registration-date", "license-number",
                                           "origin", "owner", "vehicle-type", "vehicle-status"];

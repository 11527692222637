'use client';
import React, { useCallback, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { Field, Invitation, InvitationsRequest, State } from '../../../models/invitation';
import { useApiGetData } from '../../../components/useApiGetData/useApiGetData';
import { environment } from '../../../../environments/environment';
import { DataFetchProps } from "../../../models/others";
import { useSelector } from "react-redux";
import { dashboardFilterSelector } from "../../../store/slices/dashboard.slice";

import InspectionList from "./inspection-list";
import { getDateFromQuery, getInspectionNumbersFromQuery } from "./query-utils";


const PlannedInspectionList = () => {
  const {t} = useTranslation();
  const dashboardFilterState = useSelector(dashboardFilterSelector);
  const location = useLocation();

  const date = getDateFromQuery(location)
  const inspectionNumber = getInspectionNumbersFromQuery(location)

  const [invitationsRequest, setInvitationsRequest] =
    useState<InvitationsRequest>({
      pageSize: 0,
      pageIndex: 0,
      sortDesc: false,
      sortField: Field.CALCULATED_INSPECTION_DATE,
      filter: {
        startCalculatedDate: date ? new Date(Number(date.split('-')[1]), Number(date.split('-')[0]) - 1, 1, 12) : undefined,
        endCalculatedDate: date ? new Date(Number(date.split('-')[1]), Number(date.split('-')[0]), 0, 12) : undefined,
        inspectionNumbers: inspectionNumber,
        dealerCodes: dashboardFilterState.dealerFilter.dealers,
        emailCanBeEmpty: true,
        state: [
          State.NOT_SENT,
          State.WAITING_TO_BE_SENT,
          State.SENT,
          State.EXPIRED_WAITING_TO_BE_SENT,
          State.EXPIRED_SENT,
          State.COMPLETED,
          State.HIDDEN,
          State.EXPIRED,
        ],
      },
    });
  const invitations = useApiGetData<Invitation[]>(
    `${environment.restEndpoint}/invitation/invitation/getInvitations`,
    'post',
    'invitations',
    invitationsRequest
  );

  const invitationsCount = useApiGetData<number>(
    `${environment.restEndpoint}/invitation/invitation/getInvitationsCount`,
    'post',
    'count',
    invitationsRequest
  );

  const columnHelper = createColumnHelper<Invitation>();
  const columns: ColumnDef<Invitation>[] = [
    columnHelper.accessor((row) => row.vehicle, {
      id: 'vin',
      enableSorting: false,
      header: () => <span>{t('communication.inspections-invitations.columns.vin')}</span>,
      cell: (info) => {
        return (
          <Link
            className="flex flex-col"
            to={`/vehicles/view/${info.getValue().vehicleObjectId}`}
          >
            <div className={'underline'}>{info.getValue().vin ?? '-'}</div>
          </Link>
        );
      },
    }),
    columnHelper.accessor((row) => row.vehicle.model, {
      id: 'model',
      enableSorting: false,
      header: () => <span>{t('communication.inspections-invitations.columns.model')}</span>,
      cell: (info) => {
        return (
          <div>{info.getValue() ?? '-'}</div>
        );
      },
    }),
    columnHelper.accessor((row) => row.vehicle.firstRegistrationDate, {
      id: 'first-registration',
      enableSorting: false,
      header: () => <span>{t('communication.inspections-invitations.columns.first-registration')}</span>,
      cell: (info) => {
        const firstRegistrationDate = info.getValue();
        return (<div>
            {firstRegistrationDate ?
              new Date(firstRegistrationDate).toLocaleDateString()
              : '-'}
          </div>
        );
      },
    }),
    columnHelper.accessor((row) => row.vehicle.licensenumber, {
      id: 'license-plate',
      enableSorting: false,
      header: () => <span>{t('communication.inspections-invitations.columns.license-plate')}</span>,
      cell: (info) => {
        return (
          <div>{info.getValue() ?? '-'}</div>
        );
      },
    }),
    columnHelper.accessor((row) => row.vehicle.contact, {
      id: 'name',
      enableSorting: false,
      header: () => <span>{t('communication.inspections-invitations.columns.contact')}</span>,
      cell: (info) => {
        const contact = info.getValue();
        return (
          <Link
            className="flex flex-col"
            to={
              contact.isCompany
                ? `/companies/view/${contact.contactObjectId}`
                : `/contacts/view/${contact.contactObjectId}`
            }
          >
            <div className={'underline'}>
              {[
                contact.firstname,
                contact.name,
              ]
                .filter((n) => n)
                .join(' ')}
            </div>
            <span className="text-[#5f717c] text-opacity-40">
              {[
                contact.street,
                contact.zip,
                contact.city,
                contact.countryCode,
              ]
                .filter((n) => n)
                .join(', ')}
            </span>
          </Link>
        );
      },
    }),
    columnHelper.accessor((row) => row.vehicle.contact.email, {
      id: 'contact',
      enableSorting: false,
      header: () => <span>{t('communication.inspections-invitations.columns.email')}</span>,
      cell: (info) => {
        return (
          <div>{info.getValue() ?? '-'}</div>
        );
      },
    }),
    columnHelper.accessor((row) => row.lastSendoutDate, {
      id: 'invitation-sent',
      enableSorting: false,
      header: () => <span>{t('communication.inspections-invitations.columns.invitation-sent')}</span>,
      cell: (info) => {
        const sendoutDate = info.getValue();
        return (<div>
            {sendoutDate ?
              new Date(sendoutDate).toLocaleDateString()
              : '-'}
          </div>
        );
      },
    }),
    columnHelper.accessor((row) => row.calculatedInspectionDate, {
      id: 'expected-inspection',
      enableSorting: false,
      header: () => <span>{t('communication.inspections-invitations.columns.expected-inspection')}</span>,
      cell: (info) => {
        const expectedInspection = info.getValue();
        return (<div>
            {expectedInspection ?
              new Date(expectedInspection).toLocaleDateString()
              : '-'}
          </div>
        );
      },
    }),
    columnHelper.accessor((row) => row.calculatedMilleage, {
      id: 'estimated-milage',
      enableSorting: false,
      header: () => <span>{t('communication.inspections-invitations.columns.estimated-milage')}</span>,
      cell: (info) => {
        const calculatedMilleage = info.getValue() ?? 0
        return (
          <div>{calculatedMilleage > 0 ? calculatedMilleage : '-'}</div>
        );
      },
    }),
    columnHelper.accessor((row) => row.completedDate, {
      id: 'inspection-date',
      enableSorting: false,
      header: () => <span>{t('communication.inspections-invitations.columns.inspection-date')}</span>,
      cell: (info) => {
        const inspectionDate = info.getValue()
        return (
          <div>{inspectionDate
            ? new Date(inspectionDate).toLocaleDateString()
            : '-'}</div>
        );
      },
    }),
  ].filter(Boolean) as ColumnDef<Invitation>[];

  const fetchListData = useCallback(
    async ({pageSize, pageIndex, pageSortBy}: DataFetchProps) => {
      setInvitationsRequest({
        ...invitationsRequest,
        pageIndex: pageIndex,
        pageSize: pageSize,
      });
    },
    []
  );

  return (
    <InspectionList
      data={invitations.data}
      count={invitationsCount.data}
      columns={columns}
      fetchListData={fetchListData}
      csvUrl={`${environment.restEndpoint}/invitation/invitation/export`}
      csvRequest={invitationsRequest}
    />
  );
};

export default PlannedInspectionList;

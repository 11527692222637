import {Button, ButtonStyle, Modal} from "@suzuki-frontend-framework-update/ui";

export interface ChooseBetweenPopupProps {
  modalTitle: string;
  description: string;
  firstChooseText: string;
  secondChooseText: string;
  modalState: boolean;
  setModalState: (state: boolean) => void;
  doOnFirstChoose: () => void;
  doOnSecondChoose: () => void;
  doOnClose: () => void;
}

export const ChooseBetweenPopup = (props: ChooseBetweenPopupProps) => {
  return (
    <div>
      <Modal
        key="choose-between-modal"
        title={props.modalTitle}
        show={props.modalState}
        handleClose={() => props.doOnClose()}
        size='min-w-[440px]'
      >
        <div className="flex flex-col p-8">
          <span className="text-justify">{props.description}</span>
        </div>
        <div className="flex flex-row gap-x-6 justify-end">
          <Button
            className="bg-white"
            buttonStyle={ButtonStyle.OUTLINE}
            onClick={() => props.doOnFirstChoose()}
            px="5"
            py="3"
          >
            {props.firstChooseText}
          </Button>
          <Button
            onClick={() => props.doOnSecondChoose()}
            px="5"
            py="3">
            {props.secondChooseText}
          </Button>
        </div>
      </Modal>
    </div>
  )
}

import { Button, ButtonStyle, DatePickerInput, Modal, } from '@suzuki-frontend-framework-update/ui';
import { environment } from '../../../environments/environment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { sendApiRequest } from '../send-api-request/send-api-request';
import { toast } from 'react-toastify';
import { Invitation } from '../../models/invitation';

export interface EditInvitationPopupProps {
  setModalState: (state: boolean) => void;
  modalState: boolean;
  invitation?: Invitation;
  refreshData?: () => void;
}

export const EditInvitationPopup = (props: EditInvitationPopupProps) => {
  const { t } = useTranslation();

  const [confirmationModalState, setConfirmationModalState] = useState(false);
  const [newDate, setNewDate] = useState<Date | undefined>(undefined);

  const customIdFailed = 'custom-id-Failed';
  const customIdSuccess = 'custom-id-success';

  const successToast = (successText: string) => {
    toast.success(successText, {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      toastId: customIdSuccess,
    });
  };

  const errorToast = (errorText: string) => {
    toast.error(errorText, {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      toastId: customIdFailed,
    });
  };

  const getBaseDate = () => {
   if (props.invitation?.referenceDate) {
     return new Date(props.invitation?.referenceDate)
   } else if (props.invitation?.calculatedInspectionDate) {
     return new Date(props.invitation?.calculatedInspectionDate)
   }
   return undefined
  }

  const getMaxDate = () => {
    const baseDate = getBaseDate()
    return baseDate ? new Date(
      baseDate.getFullYear() + 1,
      baseDate.getMonth(),
      baseDate.getDate()
    ) : undefined
  }

  const handleCancel = () => {
    setConfirmationModalState(false);
    props.setModalState(false);
  };

  const handleSubmit = () => {
    if (props.invitation?.id && newDate) {
      sendApiRequest(
        `${environment.restEndpoint}/invitation/invitation/changeInvitationDate`,
        'post',
        {
          id: props.invitation?.id,
          date: newDate,
        }
      ).then((response) => {
        if (response.ok) {
          successToast(t('success'));
          handleCancel();
          if (props.refreshData) props.refreshData();
        } else {
          errorToast(t('error') + response.statusText);
        }
      });
    }
  };

  useEffect(() => {
    setNewDate(props.invitation?.calculatedInspectionDate ? new Date(props.invitation?.calculatedInspectionDate) : undefined);
  }, [props.invitation]);

  return (
    <div>
      <Modal
        show={props.modalState}
        handleClose={() => handleCancel()}
        title={t('communication.inspections-invitations.edit.title')}
        clip
      >
        {props.invitation && (
          <div className="flex flex-col gap-y-6 px-5 pb-5">
            <div className="flex flex-col items-center z-50">
              <DatePickerInput
                label={{
                  name: t('communication.inspections-invitations.edit.date'),
                  position: 'left',
                }}
                width={'w-[10.25rem]'}
                selectedDay={newDate}
                handleDateChange={(date) => setNewDate(date)}
                maxDate={getMaxDate()}
                minDate={new Date()}
              />
              {getBaseDate()?.toString() !== newDate?.toString() && (
                <div className="flex justify-center mt-2 mb-2  ml-56 mr-3 ">
                  <Button
                    buttonStyle={ButtonStyle.DEFAULT}
                    onClick={() => setNewDate(getBaseDate())}
                    className="w-[10.25rem]"
                  >
                    {t('communication.inspections-invitations.edit.reset')}
                  </Button>
                </div>
              )}
            </div>
            <div className="flex flex-row gap-x-6 justify-end">
              <Button
                className="bg-white"
                buttonStyle={ButtonStyle.OUTLINE}
                onClick={handleCancel}
                px="5"
                py="3"
              >
                {t('communication.inspections-invitations.edit.cancel')}
              </Button>
              <Button
                onClick={() => {
                  if (!newDate) {
                    return;
                  }
                  const today = new Date();
                  if (
                    newDate >
                    new Date(
                      today.getFullYear(),
                      today.getMonth(),
                      today.getDate() + 6 * 7,
                      23,
                      59,
                      59
                    )
                  ) {
                    setConfirmationModalState(true);
                  } else {
                    handleSubmit();
                  }
                }}
                px="5"
                py="3"
              >
                {t('communication.inspections-invitations.edit.submit')}
              </Button>
            </div>
          </div>
        )}
      </Modal>

      <Modal
        show={confirmationModalState}
        handleClose={() => setConfirmationModalState(false)}
        title={''}
        handleCloseOnBg
      >
        <div className="flex flex-col gap-y-6 justify-center text-center">
          {t('communication.inspections-invitations.edit.confirmation.title')}
          <div className="flex flex-row gap-x-6 justify-center">
            <Button
              className="bg-white"
              buttonStyle={ButtonStyle.OUTLINE}
              onClick={() => setConfirmationModalState(false)}
              px="5"
              py="3"
            >
              {t('communication.inspections-invitations.edit.confirmation.no')}
            </Button>
            <Button onClick={handleSubmit} px="5" py="3">
              {t('communication.inspections-invitations.edit.confirmation.yes')}
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default EditInvitationPopup;

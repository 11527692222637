import { useTranslation } from 'react-i18next';
import {
  Button,
  ButtonStyle,
  Icon,
  Modal, Tooltip,
} from '@suzuki-frontend-framework-update/ui';
import { format } from 'react-string-format';
import * as Popper from "@popperjs/core";

export interface SendPopupProps {
  buttonTitle: string;
  modalTitle: string;
  sendOne: string;
  sendMany: string;
  handleSend: () => void;
  setSendModalState: (state: boolean) => void;
  sendModalState: boolean;
  children?: React.ReactNode;
  selectedCount: number;
  countConfirmationTranslationKey?: string;
  disabled?: boolean;
  buttonTooltip?: string;
}

export const SendPopup = (props: SendPopupProps) => {
  const { t } = useTranslation();

  return (
    <div>
      <Tooltip
        content={props.buttonTooltip}
        placement={Popper.bottom}
        disabledProps={!props.buttonTooltip}
      >
        <Button
          disabled={props.disabled || props.selectedCount <= 0}
          buttonStyle={ButtonStyle.OUTLINE}
          className={`bg-white w-fit ${
            props.disabled ? 'text-border-gray border-border-gray' : ''
          }`}
          onClick={() => props.setSendModalState(true)}
        >
          <Icon iconName="send" />
          <span className=" pl-[5px]">
            {props.selectedCount <= 0
              ? t('communication.inspections-invitations.no-contacts-selected')
              : props.buttonTitle}
          </span>
        </Button>
      </Tooltip>
      <Modal
        key="send-modal"
        show={props.sendModalState}
        handleClose={() => props.setSendModalState(false)}
        title={props.modalTitle}
        handleCloseOnBg
      >
        <div className="flex flex-col gap-y-6 pt-8 px-5">
          {props.countConfirmationTranslationKey && (
            <span>
              {format(
                t(props.countConfirmationTranslationKey),
                <span className="font-suzuki-bold">{props.selectedCount}</span>
              )}
            </span>
          )}
          {props.children && (
            <>
              <span>
                {t('communication.inspections-invitations.email-preview')}
              </span>

              {props.children}
            </>
          )}
          <span>
            {t('communication.inspections-invitations.confirm-continue')}
          </span>
          <span className="w-full flex flex-row justify-end gap-x-4">
            <Button
              buttonStyle={ButtonStyle.OUTLINE}
              px="4"
              py="3"
              onClick={() => props.setSendModalState(false)}
            >
              {t('remove-action-popup.cancel')}
            </Button>

            <Button
              px="4"
              py="3"
              onClick={props.handleSend}
              disabled={props.selectedCount <= 0 ? true : false}
            >
              {props.selectedCount === 1
                ? props.sendOne
                : format(props.sendMany, props.selectedCount)}
            </Button>
          </span>
        </div>
      </Modal>
    </div>
  );
};
SendPopup.defaultProps = {
  emailCount: 0,
};

export default SendPopup;

import React, { useEffect, useState } from 'react';
import BasicInput from '../basic-input/basic-input';
import Icon from '../icon/icon';
import Radiobutton from '../radiobutton/radiobutton';
import './multiple-value-input.module.scss';
import { SelectInput, SelectOptionsProps } from "@sde/basic-ui-library";

export interface MultipleValueInputRow {
  key: number;
  inputValue: string;
  selectValue: string | undefined;
  default: boolean;
}

export interface MultipleValueInputProps {
  label?: string;
  id?: string;
  error?: string;
  type?: string;
  className?: string;
  value: Array<MultipleValueInputRow>;
  options: Array<SelectOptionsProps>;
  required?: boolean;
  onChange?: (e: Array<MultipleValueInputRow>) => void;
  style?: string;
  width?: string;
  regex?: RegExp;
  showStandardLabel?: boolean;
}

export const MultipleValueInput = (props: MultipleValueInputProps) => {
  const [inputValue, setInputValue] = useState<MultipleValueInputRow[]>([
    {
      key: 0,
      default: true,
      inputValue: '',
      selectValue: props.options[0].value?.toString(),
    },
  ]);

  useEffect(() => {
    if (props.value.length > 0) {
      setInputValue(props.value);
    }
  }, [props.value]);

  const createRow = (isDefault: boolean) => {
    const key = inputValue[inputValue.length - 1].key + 1;
    return {
      key: key,
      default: isDefault,
      inputValue: '',
      selectValue: props.options[0].value?.toString(),
    };
  };

  const handleChange = (key: number, prop: string, value: string) => {
    const result = inputValue.map((row) =>
      row.key === key ? { ...row, [prop]: value } : row
    );
    setInputValue(result);
    if (props.onChange) {
      props.onChange(result);
    }
  };

  const onDefaultChange = (key: number) => {
    const result = inputValue.map((row) =>
      row.key === key ? { ...row, default: true } : { ...row, default: false }
    );
    setInputValue(result);
    if (props.onChange) {
      props.onChange(result);
    }
  };

  const handleDeleteRow = (key: number) => {
    const lastIndex = inputValue.length - 1;
    if (lastIndex > 0) {
      const result = inputValue.filter(function (row) {
        return row.key !== key;
      });
      if (!result.find((row) => row.default)) {
        result[0].default = true;
      }
      setInputValue(result);
      if (props.onChange) {
        props.onChange(result);
      }
    } else if (props.onChange) {
      const result = [
        {
          key: 0,
          default: true,
          inputValue: '',
          selectValue: props.options[0].value?.toString(),
        },
      ];
      setInputValue(result);
      props.onChange(result);
    }
  };

  const handleAddRow = () => {
    const result = [...inputValue, createRow(false)];
    setInputValue(result);
    if (props.onChange) {
      props.onChange(result);
    }
  };

  return (
    <div className="flex flex-col gap-y-5 w-full">
      {inputValue.map((item, index) => (
        <div
          key={props.label + item.key.toString()}
          className="flex items-baseline "
        >
          <div className="w-6/12 flex flex-col items-end">
            <BasicInput
              value={item.inputValue}
              type={props.type}
              width={props.width}
              label={{
                name: props.label,
                position: 'left',
                hide: index !== 0,
              }}
              onChange={(e) => {
                if (!props.regex || props.regex.test(e.target.value)) {
                  handleChange(item.key, 'inputValue', e.target.value);
                }
              }}  
              required={props.required}
              error={props.error}
            />
          </div>

          <div className="w-4/12">
            <div className="mx-3">
              <SelectInput
                value={item.selectValue}
                options={props.options}
                width={props.width}
                onSelect={(e) => handleChange(item.key, 'selectValue', e)}
              />
            </div>
          </div>

          <div className="w-2/12 flex justify-around">
            <div className="w-7/12 flex justify-center items-center relative">
              {props.showStandardLabel && index === 0 && (
                <span className="absolute -top-10 text-base text-black font-suzuki-bold">
                  STANDARD
                </span>
              )}
              <Radiobutton
                value={item.key}
                onChange={(e) => onDefaultChange(parseInt(e.target.value))}
                checked={item.default}
                className="mx-6 my-0"
              />
            </div>
            <div className="w-5/12 flex justify-start gap-x-5">
              <button onClick={(e) => handleDeleteRow(item.key)}>
                <Icon className="text-red-500 my-0" iconName="delete" />
              </button>
              {index === inputValue.length - 1 && (
                <button onClick={handleAddRow}>
                  <Icon iconName="add_circle_outline" />
                </button>
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default MultipleValueInput;

import React from 'react';

export type FileBase64InputProps = {
  multiple?: boolean;
  onDone: (e: FileBase64Info[]) => void;
};

export type FileBase64Info = {
  name: string;
  type: string;
  size: string;
  base64: string;
  file: File;
};

export class FileBase64Input extends React.Component<FileBase64InputProps> {
  constructor(props: FileBase64InputProps) {
    super(props);
    this.state = {
      files: [],
    };
  }

  handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    const files = e.target.files;
    if (files === null) {
      return;
    }

    const allFiles: FileBase64Info[] = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      const reader = new FileReader();

      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64 = reader.result
          ? reader.result
              .toString()
              .substring(reader.result.toString().indexOf(',') + 1)
          : '';
        const fileInfo = {
          name: file.name,
          type: file.type,
          size: Math.round(file.size / 1000) + ' kB',
          base64: base64,
          file: file,
        } as FileBase64Info;

        allFiles.push(fileInfo);

        if (allFiles.length === files.length) {
          this.props.onDone(allFiles);
        }
      };
    }
  }

  render() {
    return (
      <input
        type="file"
        onChange={this.handleChange.bind(this)}
        multiple={this.props.multiple}
      />
    );
  }
}

export default FileBase64Input;

import { Sidebar } from '@suzuki-frontend-framework-update/ui';
import './report.module.scss';
import { useTranslation } from 'react-i18next';
import {Link, Outlet} from 'react-router-dom';
import {compareQuestionnaireTypes} from "../../helpers/questionnaire-helper";
import {SidebarLink} from "@sde/basic-ui-library";
import {useEffect, useState} from "react";
import {useApiGetData} from "../../components/useApiGetData/useApiGetData";
import {QuestionnairesType} from "../../models/questionnaires-types";
import {environment} from "../../../environments/environment";
import {Session} from "@ory/client";
import {useSelector} from "react-redux";
import {selectAllUserManagement} from "../../store/slices/user-management.slice";
import { isAutoDealerOrAdmin, isMotoDealerOrAdmin} from "../../helpers/session-utils";

export const Report = () => {
  const { t } = useTranslation();
  const [session, setSession] = useState({} as Session);
  const entities = useSelector(selectAllUserManagement);
  const questionnairesTypes = useApiGetData<QuestionnairesType[]>(
    `${environment.restEndpoint}/survey-service/questionnaire-types`,
    'get',
    'questionnaireTypes'
  );

  useEffect(() => {
    const lastEntity = entities[entities.length - 1];
    if (lastEntity && lastEntity.currentUser) {
      setSession(lastEntity.currentUser);
    }
  }, [entities]);

  function getSurveysItems() {
    const tenants: string[] = [];
    
    if (isMotoDealerOrAdmin(session))
      tenants.push('moto');
    if (isAutoDealerOrAdmin(session))
      tenants.push('auto');
    const tempItems = questionnairesTypes?.data?.sort((a, b) => compareQuestionnaireTypes(a, b)).filter((type) => tenants.includes(type.tenant)).map((type) =>
      ({
        name: t('reports.survey.types.' + type.name),
        path: `/reports/survey/` + type.id,
      } as SidebarLink));

    return tempItems ?? [];
  }

  return (
    <Sidebar
      groups={[
        {
          title: t('reports.title'),
          items: [
            {
              name: t('reports.crr.title'),
              path: 'crr',
            },
          ],
        },
        {
          title: t('reports.survey.title'),
          items: getSurveysItems(),
        },
      ]}
    >
      <Outlet />
    </Sidebar>
  );
};

export default Report;

import * as React from "react";

function SvgBxDownload(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
      <path d="M12 16L16 11 13 11 13 4 11 4 11 11 8 11z" />
      <path d="M20,18H4v-7H2v7c0,1.103,0.897,2,2,2h16c1.103,0,2-0.897,2-2v-7h-2V18z" />
    </svg>
  );
}

export default SvgBxDownload;

import { HidingReasonType, Vehicle } from "../../models/vehicle";
import { Session } from "@ory/client";

export const getHidingReasonOfVehicle = (session: Session, vehicle: Vehicle): HidingReasonType | undefined => {

  if (!vehicle.accessibillity || vehicle.accessibillity.length === 0) {
    return undefined
  }
  for (const acc of vehicle.accessibillity) {
    const userDealerCodes: string[] = session?.identity?.traits?.dealers?.map(
      (d: any) => d.dealercode
    );
    if (acc?.dealerCodes?.some(code =>  session.identity?.traits?.isAdmin || userDealerCodes.includes(code))) {
      return acc.disablingReasonType
    }
  }
  return undefined
}


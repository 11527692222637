import { Headline, LogoIcon } from '@suzuki-frontend-framework-update/ui';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import Footer from '../../components/footer/footer';

export const Public: FunctionComponent = () => {
  const { t } = useTranslation();
  return (
    <div className="min-h-screen w-full flex flex-col justify-between bg-dark">
      <div className="m-auto">
        <div className="flex items-center justify-center">
          <Headline title={t('login.headline')} color="white" />
          <LogoIcon color="white" />
        </div>
        <div
          className="text-dark bg-white px-20 py-10 flex flex-col items-center gap-y-10 mb-40"
          style={{ minHeight: '26rem', width: '43rem' }}
        >
          <Outlet />
        </div>
      </div>
      <Footer isLoginPage />
    </div>
  );
};

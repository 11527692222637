export interface TestDriveData {
  id: string;
  contactId?: string;
  contactSalutation?: string;
  contactFirstName?: string;
  contactLastName?: string;
  contactEmail?: string;
  contactPhone?: string;
  companyId?: string;
  companyName?: string;
  dealercode?: string;
  modelName?: string;
  wishDate?: Date;
  plannedDate?: Date;
  response?: TestDriveResponseOptions;
  responseLink?: string;
  status?: string;
  createAt?: Date;
  notice?: string;
  dealerComment?: string;
  origin?: TestDriveOrigin;
  lastChangeDate?: Date;
  lastChangeEditor?: string;
  customerState?: string;
  cancelingReason?: string;
  isMotorcycle?: boolean;
  additionalWishDate1?: Date;
  additionalWishDate2?: Date;
  dealerName?: string;
}

export interface TestDriveRequestBody {
  filter?: TestDriveFilter;
  search?: string;
  pageSize?: number;
  pageIndex?: number;
  sortField?: string;
  sortDir?: number;
}

export interface TestDriveFilter {
  dateFrom?: Date;
  dateTo?: Date;
  plannedDateFrom?: Date;
  plannedDateTo?: Date;
  dealers?: string[];
  search?: string;
  poolIds?: string[];
  origins?: string[];
  statuses?: string[];
}

export interface ModifyTestDriveData {
  id: string;
  isCanceled: boolean;
  contactId?: string;
  companyId?: string;
  isContactCompany?: boolean;
  contactSalutation?: string;
  contactFirstName?: string;
  contactLastName?: string;
  companyName?: string;
  contactEmail?: string;
  contactPhone?: string;
  modelName?: string;
  wishDate?: Date;
  prospectNotice?: string;
  origin?: TestDriveOrigin;
  lastChangeDate?: Date;
  lastChangeEditor?: string;
  createdAt?: Date;
  customerState?: string;
  plannedDate?: Date;
  status?: string;
  dealerComment?: string;
  dealercode?: string;
  isMotorcycle?: boolean;
  additionalWishDate1?: Date;
  additionalWishDate2?: Date;
  response?: TestDriveResponseOptions;
}

export interface ScheduleTestDriveRequest {
  id: string;
  plannedDate?: Date;
  dealerComment?: string;
}

export interface PurchasedVehicleOption {
  purchasedVehicle: ResponseState;
  purchasedVehicleVin?: string;
}

export interface TestDriveResponseOptions {
  testDrivePerformed: boolean;
  interestedInVehicle: ResponseState;
  purchaseOfVehicle: PurchasedVehicleOption;
}

export interface CancelTestDriveRequest {
  id: string;
  cancelingReason: string;
}

export interface FinishTestDriveRequest {
  id: string;
  response: TestDriveResponseOptions;
  dealerComment: string;
}

export enum TestDriveStatus {
  NEW_OPEN = 'NEW_OPEN',
  SCHEDULED = 'SCHEDULED',
  FINISHED_CARRIED_OUT_BOUGHT = 'FINISHED_CARRIED_OUT_BOUGHT',
  FINISHED_CARRIED_OUT_NOT_BOUGHT = 'FINISHED_CARRIED_OUT_NOT_BOUGHT',
  FINISHED_CARRIED_OUT_PURCHASE_OPEN = 'FINISHED_CARRIED_OUT_PURCHASE_OPEN',
  FINISHED_CARRIED_OUT_PURCHASE_OPEN_HOT_LEAD = 'FINISHED_CARRIED_OUT_PURCHASE_OPEN_HOT_LEAD',
  FINISHED_NOT_CARRIED_OUT = 'FINISHED_NOT_CARRIED_OUT',
  CANCELED = 'CANCELED',
}

export enum TestDriveOrigin {
  NOT_SET = 'NOT_SET',
  NATIONAL_WEBSITE = 'NATIONAL_WEBSITE',
  DEALER_WEBSITE = 'DEALER_WEBSITE',
  SCRM = 'SCRM',
  TEST_DRIVE_WEEK = 'TEST_DRIVE_WEEK',
  DATA_COLLECTION_TOOL = 'DATA_COLLECTION_TOOL',
  ROADSHOW = 'ROADSHOW',
}

export enum ResponseState {
  UNKNOWN = 'UNKNOWN',
  TRUE = 'TRUE',
  FALSE = 'FALSE'
}

export interface ExcelImportRequest {
  content: string;
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../setup';

export const TEST_DRIVE_REPORT_FEATURE_KEY = 'test-drive-report-filter';

export interface TestDriveReportFilterState {
  key: string;
  currentRow: string | undefined;
  data: TestDriveReportFilterData;
}

export interface TestDriveReportFilterData {
  filter: TestDriveFilterSlice;
  pageIndex: number;
}

export interface TestDriveFilterSlice {
  dateFrom?: string;
  dateTo?: string;
  plannedDateFrom?: string;
  plannedDateTo?: string;
  dealers?: string[];
  search?: string;
  poolIds?: string[];
  origins?: string[];
  statuses?: string[];
}

export const initialTestDriveReportFilterState: TestDriveReportFilterState = {
  key: '',
  currentRow: undefined,
  data: {
    filter: {
      search: '',
      dealers: [],
    },
    pageIndex: 0,
  },
};

const testDriveReportFilterSlice = createSlice({
  name: TEST_DRIVE_REPORT_FEATURE_KEY,
  initialState: initialTestDriveReportFilterState,
  reducers: {
    init: (state, { payload: key }: PayloadAction<string>) => {
      const today = new Date();
      state.key = key;
      state.currentRow = undefined;
      state.data = {
        filter: {},
        pageIndex: 0,
      };
    },
    resetState: (state, action: PayloadAction<null>) => {
      return initialTestDriveReportFilterState;
    },
    updateFilter: (
      state,
      { payload: testDriveReportFilter }: PayloadAction<TestDriveFilterSlice>
    ) => {
      state.data.filter = {
          ...state.data.filter,
          ...testDriveReportFilter
      }
    },
    updatePageIndex: (state, { payload: pageIndex }: PayloadAction<number>) => {
      state.data.pageIndex = pageIndex;
    },
    updateCurrentRow: (
      state,
      { payload: currentRow }: PayloadAction<string | undefined>
    ) => {
      state.currentRow = currentRow;
    },
  },
});

export const testDriveReportFilterActions = testDriveReportFilterSlice.actions;

export const testDriveReportFilterSelector = (state: RootState) =>
  state[TEST_DRIVE_REPORT_FEATURE_KEY];

export const testDriveReportFilterReducer = testDriveReportFilterSlice.reducer;

import Tippy from '@tippyjs/react';
import * as Popper from '@popperjs/core';
import 'tippy.js/dist/tippy.css';
import './tooltip.scss';
import React from 'react';

export interface TooltipProps {
  content: React.ReactNode;
  children: React.ReactElement;
  placement?: Popper.Placement;
  delay?: number;
  interactive?: boolean;
  disabledProps?: boolean;
}

export function Tooltip(props: TooltipProps) {
  return (
    <Tippy
      content={props.content}
      placement={props.placement ? props.placement : Popper.bottom}
      delay={props.delay}
      interactive={props.interactive}
      animation="fade"
      theme="suzuki"
      allowHTML
      arrow
      disabled={props.disabledProps}
    >
      <span>{props.children}</span>
    </Tippy>
  );
}

export default Tooltip;

import './react-table-config.d.ts';
import React, { FunctionComponent, useEffect, useRef } from 'react';

import { Spinner } from '../spinner/spinner';
import {
  Column,
  Row,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table';

import {
  ButtonArea,
  NavButton,
  Pagination,
  TableCell,
  TableContentContainer,
  TableRow,
  TTable,
} from './default-components';
import IndeterminateCheckbox from './IndeterminateCheckbox';
import { CheckboxStatus } from './table';
import { t } from 'i18next';
import Icon from '../icon/icon';
import { DataFetchProps } from './react-table-config';

export interface ControlledPaginationTableProps {
  columns: Array<Column<any>>;
  data: any;
  fetchData: ({ pageSize, pageIndex, pageSortBy }: DataFetchProps) => void;
  loading?: boolean;
  rowCount: number;
  countLabel?: string;
  initialPage?: number;
  handleScrollToRow?: ({ itemsRef }: any) => void;
  initPageSize?: number;
  basic?: boolean;
  handleRowClick?: (row: Row<any>) => void;
  disabledClickForColumns?: string[];
  handleRowSelect?: (row: Row<any>) => void;
  handleAllRowsSelect?: () => void;
  customSelectedRowIds?: Record<string, boolean>;
  allSelectedStatus?: CheckboxStatus;
  disableSelection?: (row: Row<any>) => boolean;
  resetOnChange?: any;
  selectTitle?: string;
  allSelectTitle?: string;
  getCustomRowStyle?: (row: Row<any>) => string;
}

export const ControlledPaginationTable: FunctionComponent<
  ControlledPaginationTableProps
> = ({
  columns,
  data,
  fetchData,
  loading,
  rowCount,

  countLabel = 'Entries',
  initPageSize = 100,
  initialPage = 0,
  handleScrollToRow,
  basic = false,
  handleRowClick,
  disabledClickForColumns,
  handleRowSelect,
  customSelectedRowIds,
  handleAllRowsSelect,
  allSelectedStatus,
  disableSelection,
  resetOnChange,
  selectTitle = t('table.select-title'),
  allSelectTitle = t('table.all-select-title'),
  getCustomRowStyle,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex, pageSize, sortBy },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: initialPage,
        pageSize: initPageSize,
        selectedRowIds: customSelectedRowIds ? customSelectedRowIds : {},
        sortBy: [],
      }, // Pass our hoisted table state
      manualSortBy: true,
      manualPagination: true,
      pageCount: Math.ceil(rowCount / initPageSize),
      basic,
      customSelectedRowIds,
      allSelectedStatus,
      resetOnChange,
    },
    (hooks) => {
      hooks.visibleColumns.push((columns) =>
        basic
          ? [
              {
                Header: '',
              },
              ...columns,
            ]
          : [
              // Let's make a column for selection
              {
                id: 'selection',
                // The header can use the table's getToggleAllRowsSelectedProps method
                // to render a checkbox
                Header: ({ getToggleAllRowsSelectedProps }) => (
                  <div>
                    <IndeterminateCheckbox
                      {...{
                        ...getToggleAllRowsSelectedProps(),
                        title: allSelectTitle,

                        checked: allSelectedStatus
                          ? allSelectedStatus.checked
                          : getToggleAllRowsSelectedProps().checked,
                        indeterminate: allSelectedStatus
                          ? allSelectedStatus.indeterminate
                          : getToggleAllRowsSelectedProps().indeterminate,
                        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                          const props = getToggleAllRowsSelectedProps();
                          if (props && props.onChange) {
                            props.onChange(e);
                          }
                          if (handleAllRowsSelect) {
                            handleAllRowsSelect();
                          }
                        },
                      }}
                    />
                  </div>
                ),
                Cell: ({ row }) => {
                  return (
                    <div>
                      <IndeterminateCheckbox
                        {...{
                          ...row.getToggleRowSelectedProps(),
                          title: selectTitle,
                          checked: customSelectedRowIds
                            ? customSelectedRowIds[row.id] === true
                            : row.getToggleRowSelectedProps().checked,
                          onChange: (
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            const props = row.getToggleRowSelectedProps();
                            if (props && props.onChange) {
                              props.onChange(e);
                            }
                            if (handleRowSelect) {
                              handleRowSelect(row);
                            }
                          },
                          disabled: disableSelection
                            ? disableSelection(row)
                            : false,
                        }}
                      />
                    </div>
                  );
                },
              },
              ...columns,
            ]
      );
    },
    useSortBy,
    usePagination,
    useRowSelect
  );

  const itemsRef = useRef<Map<string, HTMLDivElement | null>>(
    new Map<string, HTMLDivElement | null>()
  );

  useEffect(() => {
    const pageSortBy = sortBy;
    fetchData({ pageSize, pageIndex, pageSortBy });
  }, [fetchData, pageIndex, pageSize, sortBy]);

  useEffect(() => {
    return () => gotoPage(0);
  }, [resetOnChange]);

  useEffect(() => {
    if (handleScrollToRow) {
      handleScrollToRow({ itemsRef });
    }
  }, [loading]);

  const getPaginationElement = (i: number) => {
    if (
      i === 0 ||
      i === pageOptions.length - 1 ||
      (i - pageIndex > -3 && i - pageIndex < 2) ||
      (pageIndex < 4 && i < 4)
    )
      return (
        <NavButton
          key={i}
          onClick={() => gotoPage(i)}
          styled={i === pageIndex}
          disabled={i === pageIndex}
        >
          {i + 1}
        </NavButton>
      );
    else if (i === 1 || i === pageOptions.length - 2)
      return (
        <NavButton key={i} disabled>
          {'...'}
        </NavButton>
      );
    else return null;
  };

  return (
    <div>
      {rowCount > 0 ? (
        <div className="flex justify-between sticky top-16 z-50 bg-gray">
          <div className={'flex justify-items-center items-center pl-2'}>
            {rowCount ? `${rowCount} ${countLabel}` : ''}
          </div>
          <Pagination>
            <ButtonArea>
              <NavButton
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
                styled
              >
                {'<'}
              </NavButton>
              {Array.from({ length: pageOptions.length }, (_, i) =>
                getPaginationElement(i)
              )}
              <NavButton
                onClick={() => nextPage()}
                disabled={!canNextPage}
                styled
              >
                {'>'}
              </NavButton>
            </ButtonArea>
          </Pagination>
        </div>
      ) : (
        <div className="h-10" />
      )}
      <TableContentContainer>
        <TTable {...getTableProps()}>
          <thead className="sticky top-[6.5rem]">
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className="m-0 first:px-2 px-6 py-4  text-left text-dark bg-blue-gray font-suzuki-bold"
                    title={column.canSort ? t('table.sort-title') : ''}
                  >
                    <>
                      {column.render('Header')}
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <Icon iconName="keyboard_arrow_down" />
                        ) : (
                          <Icon iconName="keyboard_arrow_up" />
                        )
                      ) : column.canSort ? (
                        <Icon iconName="unfold_more" />
                      ) : (
                        ''
                      )}
                    </>
                  </th>
                ))}
              </TableRow>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <TableRow
                  {...row.getRowProps()}
                  customStyle={getCustomRowStyle ? getCustomRowStyle(row) : undefined}
                  reference={(input) => itemsRef.current?.set(row.id, input)}
                >
                  {row.cells.map((cell) => {
                    if (cell.column.id !== 'selection' &&
                      (!disabledClickForColumns ||
                        !disabledClickForColumns.includes(cell.column.id)) &&
                      handleRowClick) {
                      return (
                        <TableCell
                          onClick={() => handleRowClick(row)}
                          {...cell.getCellProps()}
                        >
                          {cell.render('Cell')}
                        </TableCell>
                      );
                    } else {
                      return (
                        <TableCell {...cell.getCellProps()}>
                          {cell.render('Cell')}
                        </TableCell>
                      );
                    }
                  })}
                </TableRow>
              );
            })}
          </tbody>
        </TTable>
        {loading && (
          <div className="my-4 w-full">
            <Spinner />
          </div>
        )}
      </TableContentContainer>
      {rowCount > 0 ? (
        <Pagination>
          <ButtonArea>
            <NavButton
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
              styled
            >
              {'<'}
            </NavButton>
            {Array.from({ length: pageOptions.length }, (_, i) =>
              getPaginationElement(i)
            )}
            <NavButton
              onClick={() => nextPage()}
              disabled={!canNextPage}
              styled
            >
              {'>'}
            </NavButton>
          </ButtonArea>
        </Pagination>
      ) : (
        <div className="h-10" />
      )}
    </div>
  );
};

export default ControlledPaginationTable;

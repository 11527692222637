export interface Dealer {
  objectID: string;
  dealercode: string;
  name: string;
  nameAppendum: string;
  zipCode: string;
  city: string;
  addressLine1: string;
  addressLine2: string;
  phone: string;
  fax: string;
  email: string;
  alternativeEmail: string;
  subsidiaryIndex: number;
  dealerdomain: string;
  dealertype: string;
  deleted: boolean;
  deletedAt: string;
  salesArea: string;
  aftersalesArea: string;
  salesContractStart: string;
  salesContractEnd: string;
  serviceContractStart: string;
  serviceContractEnd: string;
  mainDealerDealercode: string;
  openingtimesSales: {
    monday: [
      {
        start: string;
        end: string;
      },
      {
        start: string;
        end: string;
      }
    ];

    tuesday: [
      {
        start: string;
        end: string;
      },
      {
        start: string;
        end: string;
      }
    ];
    wednesday: [
      {
        start: string;
        end: string;
      },
      {
        start: string;
        end: string;
      }
    ];
    thursday: [
      {
        start: string;
        end: string;
      },
      {
        start: string;
        end: string;
      }
    ];
    friday: [
      {
        start: string;
        end: string;
      },
      {
        start: string;
        end: string;
      }
    ];
    saturday: [
      {
        start: string;
        end: string;
      },
      {
        start: string;
        end: string;
      }
    ];
    sunday: [
      {
        start: string;
        end: string;
      },
      {
        start: string;
        end: string;
      }
    ];
  };
  openingtimesService: {
    monday: [
      {
        start: string;
        end: string;
      },
      {
        start: string;
        end: string;
      }
    ];

    tuesday: [
      {
        start: string;
        end: string;
      },
      {
        start: string;
        end: string;
      }
    ];
    wednesday: [
      {
        start: string;
        end: string;
      },
      {
        start: string;
        end: string;
      }
    ];
    thursday: [
      {
        start: string;
        end: string;
      },
      {
        start: string;
        end: string;
      }
    ];
    friday: [
      {
        start: string;
        end: string;
      },
      {
        start: string;
        end: string;
      }
    ];
    saturday: [
      {
        start: string;
        end: string;
      },
      {
        start: string;
        end: string;
      }
    ];
    sunday: [
      {
        start: string;
        end: string;
      },
      {
        start: string;
        end: string;
      }
    ];
  };
  latitude: number;
  longitude: number;
  homepage: string;
  homepage2: string;
  skl: string;
  vkl: string;
  createdAt: string;
  modifiedAt: string;
  branches: string[];
  isMainDealer: boolean;
}

export interface DealersRequestBody {
  pageSize?: number;
  pageIndex?: number;
  filter?: DealerFilter;
  sortField?: string;
  sortDir?: number;
  details?: string;
}

export interface DealerFilter {
  search?: string;
  filter?: {
    field?: string;
    value?: string;
  }[];
}

export interface DealerBasic {
  name: string;
  dealercode: string;
  isMainDealer: boolean;
  salesArea: string;
  deleted: boolean;
  aftersalesArea: string;
  salesContractStart: string;
  salesContractEnd: string;
  serviceContractStart: string;
  serviceContractEnd: string;
}

export interface AvailableAreas {
  availableASM: string[];
  availableDSM: string[];
}

export enum DealersScope {
  MOTO = "MOTO",
  AUTO = "AUTO"
}

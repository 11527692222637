import {FunctionComponent, useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import {environment} from "../../../environments/environment";
import {Spinner} from "@sde/basic-ui-library";
import {Trans, useTranslation} from "react-i18next";
import {sendUnauthorizedApiRequest} from "../../components/send-api-request/send-api-request";

interface MarkBrochureAsDownloadedResponse {
    decodedLink?: string;
}

export const BrochureDownloadRedirect: FunctionComponent = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const [displayError, setDisplayError] = useState<boolean>(false);
    const [requestBody, setRequestBody] = useState({
        id: searchParams.get('id'),
        encodedLink: searchParams.get('link'),
    });

    useEffect(() => {
        sendUnauthorizedApiRequest(
            `${environment.restEndpoint}/action/appointments/brochure-download/mark-as-downloaded`,
            'post',
            requestBody
        ).then((response) => {
            if (response.ok) {
                response.json().then((data) => {
                    const responseData = data as MarkBrochureAsDownloadedResponse
                    if (responseData.decodedLink) {
                        window.location.replace(responseData.decodedLink);
                    } else {
                        setDisplayError(true);
                    }
                });
            } else {
                setDisplayError(true);
            }
        });
    }, []);

    return (
        <>
            {!displayError ?
                <Spinner text={t('brochure-download-redirect.redirecting-message')} />
                :
                <span>
                    <Trans i18nKey="brochure-download-redirect.redirecting-error">
                        support<a className="underline" href="https://auto.suzuki.de/ueber-suzuki/kontakt" />
                    </Trans>
                </span>
            }
        </>
    );
}

import { useTranslation } from 'react-i18next';
import { DatePickerInput } from '@suzuki-frontend-framework-update/ui';
import React, { useEffect, useState } from 'react';
import { useApiGetData } from '../useApiGetData/useApiGetData';
import { Dealer, DealerBasic, DealersRequestBody, DealersScope } from '../../models/dealer';
import { environment } from '../../../environments/environment';
import { useDispatch, useSelector } from 'react-redux';
import { selectAllUserManagement } from '../../store/slices/user-management.slice';
import { Session } from '@ory/client';
import { dashboardFilterActions, dashboardFilterSelector, } from '../../store/slices/dashboard.slice';
import { AppDispatch } from '../../store/setup';
import { DashboardDealerFilter } from '../../models/dashboard';
import { getCurrentFiscalYear } from '../../pages/report/crr-report-parser';
import { DatePickerRange, Filter, FilterItem, SelectInput } from "@sde/basic-ui-library";
import { isAdmin } from "../../helpers/session-utils";
import { isAutoDealerCode, isMotoDealerCode } from "../../helpers/dealer-helper";

export interface DateFiltersProps {
  setSelectedDateOption: (state: string) => void;
  selectedDateOption: string;
  setStartDate: (state: Date | null) => void;
  startDate: Date | null;
  setEndDate: (state: Date | null) => void;
  endDate: Date | null;
  defaultDate?: Date | null;
}

export interface ReportFiltersProps {
  dateFilters?: DateFiltersProps;
  showOnlyDealersScope?: DealersScope;
  resetOnPageReload?: boolean;
}

const ReportFilters = (props: ReportFiltersProps) => {
  const {t} = useTranslation();
  const entities = useSelector(selectAllUserManagement);
  const [session, setSession] = useState({} as Session);
  const [date, setDate] = useState<Date | null>(null);
  const [availableASM, setAvailableASM] = useState<string[]>([]);
  const [availableDSM, setAvailableDSM] = useState<string[]>([]);
  const [availableDealers, setAvailableDealers] = useState<FilterItem[]>([]);
  const [allAvailableDealers, setAllAvailableDealers] = useState<FilterItem[]>(
    []
  );

  const dashboardFilterState = useSelector(dashboardFilterSelector);
  const dispatch: AppDispatch = useDispatch();

  const allDealers = useApiGetData<Dealer[]>(
    `${environment.restEndpoint}/dealer/dealer-service/dealers`,
    'post',
    'dealers'
  );
  const [dealersRequestBody, setDealersRequestBody] =
    useState<DealersRequestBody>({
      details: "DEFAULT",
      filter: {
        filter: [
          {
            field: "deleted",
            value: "false"
          }
        ]
      },
      pageIndex: 0,
      pageSize: 0,
    });
  const basicDealers = useApiGetData<DealerBasic[]>(
    `${environment.restEndpoint}/dealer/dealer-service/dealers/filter`,
    'post',
    'dealers',
    dealersRequestBody
  );

  useEffect(() => {
    const lastEntity = entities[entities.length - 1];
    if (lastEntity && lastEntity.currentUser) {
      setSession(lastEntity.currentUser);
    }
  }, [entities]);

  function getAvailableDealers(all: FilterItem[]) {
    if (allDealers.data) {
      let filteredDealers = allDealers.data;

      if (isAdmin(session) && props.showOnlyDealersScope) {
        if (props.showOnlyDealersScope === DealersScope.MOTO) {
          filteredDealers = filteredDealers.filter(d => isMotoDealerCode(d.dealercode));
        } else if (props.showOnlyDealersScope === DealersScope.AUTO) {
          filteredDealers = filteredDealers.filter(d => isAutoDealerCode(d.dealercode));
        }
      }

      return filteredDealers
        .filter((dealer) => all.some((d) => d.id === dealer.dealercode))
        .filter((dealer) => validateDsm(dealer.aftersalesArea))
        .filter((dealer) => validateAsm(dealer.salesArea))
        .filter((dealer) => validateMainDealer(dealer))
        .map((d) => {
          return {
            id: d.dealercode,
            name: buildDealerFilterItemName(d.dealercode, d.name),
          } as FilterItem;
        })
        .sort((a, b) => a.name.localeCompare(b.name));
    }
    return [];
  }

  const canDealerBePushedForAdmin = (dealerCode: string) => {
    const showOnly = props.showOnlyDealersScope;
    let result = true;

    if (showOnly) {
      result = (showOnly === DealersScope.MOTO && isMotoDealerCode(dealerCode)) || (showOnly === DealersScope.AUTO && isAutoDealerCode(dealerCode));
    }

    return result;
  }

  useEffect(() => {
    if (allDealers.data) {
      const dealers: FilterItem[] = [];

      if (session?.identity?.traits?.isAdmin) {
        const dsmAreas: string[] = [];
        const asmAreas: string[] = [];
        for (const dealer of allDealers.data) {
          if (!dealer.deleted) {
            const entry: FilterItem = {
              id: dealer.dealercode,
              name: buildDealerFilterItemName(dealer.dealercode, dealer.name),
            };

            if (canDealerBePushedForAdmin(dealer.dealercode)) {
              dealers.push(entry);
            }

            if (dealer.salesArea && !asmAreas.includes(dealer.salesArea)) {
              asmAreas.push(dealer.salesArea);
            }
            if (
              dealer.aftersalesArea &&
              !dsmAreas.includes(dealer.aftersalesArea)
            ) {
              dsmAreas.push(dealer.aftersalesArea);
            }
          }
        }

        asmAreas.sort((a, b) => a.localeCompare(b));
        setAvailableASM(asmAreas);

        dsmAreas.sort((a, b) => a.localeCompare(b));
        setAvailableDSM(dsmAreas);
      } else {
        const dealerIds: string[] = session?.identity?.traits?.dealers?.map(
          (d: any) => d.id
        );
        if (dealerIds) {
          for (const dealer of allDealers.data) {
            if (
              dealerIds.includes(dealer.objectID) &&
              (!dealer.isMainDealer || (dealer.isMainDealer && checkMainDealerDate(dealer))) &&
              !dealer.deleted
            ) {
              const entry: FilterItem = {
                id: dealer.dealercode,
                name: buildDealerFilterItemName(dealer.dealercode, dealer.name),
              };
              dealers.push(entry);
            }
          }
        }
      }
      dealers.sort((a, b) => a.name.localeCompare(b.name));
      setAllAvailableDealers(dealers);

      setAvailableDealers(getAvailableDealers(dealers));
      if (dashboardFilterState.dealerFilter.dealers.length === 0 || props.resetOnPageReload) {
        dispatch(
          dashboardFilterActions.updateDealerFilter({
            ...dashboardFilterState.dealerFilter,
            dealers: dealers.map((d) => d.id),
          })
        );
      }
      if (props.dateFilters?.defaultDate) {
        handleYearChange(props.dateFilters?.defaultDate);
      }
    }
  }, [allDealers.data, props.showOnlyDealersScope]);

  function validateDsm(aftersalesArea: string) {
    return (
      !dashboardFilterState.dealerFilter.dsm ||
      dashboardFilterState.dealerFilter.dsm.length === 0 ||
      dashboardFilterState.dealerFilter.dsm.includes(aftersalesArea)
    );
  }

  function validateAsm(salesArea: string) {
    return (
      !dashboardFilterState.dealerFilter.asm ||
      dashboardFilterState.dealerFilter.asm.length === 0 ||
      dashboardFilterState.dealerFilter.asm.includes(salesArea)
    );
  }

  function validateMainDealer(dealer: Dealer) {
    return (
      !dashboardFilterState.dealerFilter.mainDealers ||
      dashboardFilterState.dealerFilter.mainDealers.length === 0 ||
      dashboardFilterState.dealerFilter.mainDealers.includes(
        dealer.dealercode
      ) ||
      dashboardFilterState.dealerFilter.mainDealers.includes(
        dealer.mainDealerDealercode
      ) ||
      dashboardFilterState.dealerFilter.mainDealers.includes(
        '0' + dealer.mainDealerDealercode
      )
    );
  }

  function buildDealerFilterItemName(dealerCode: string, dealerName: string) {
    return `${dealerCode} - ${dealerName}`;
  }

  useEffect(() => {
    if (allDealers.data) {
      const dealers = getAvailableDealers(allAvailableDealers);
      setAvailableDealers(dealers);
      setupSelectedDealersCodes(dealers);
    }
  }, [
    dashboardFilterState.dealerFilter.asm,
    dashboardFilterState.dealerFilter.dsm,
    dashboardFilterState.dealerFilter.mainDealers,
  ]);

  const handleOptionChange = (option: string) => {
    if (props.dateFilters) {
      const today = new Date();
      props.dateFilters.setSelectedDateOption(option);
      if (option === 'fiscal-year') {
        const date = props.dateFilters.defaultDate
          ? props.dateFilters.defaultDate
          : new Date(getCurrentFiscalYear(), 0, 1, 12);
        setDate(date);
        props.dateFilters.setStartDate(new Date(date.getFullYear(), 3, 1, 12));
        props.dateFilters.setEndDate(
          new Date(date.getFullYear() + 1, 2, 31, 12)
        );
      } else if (option === 'calendar-year') {
        const date = new Date(today.getFullYear(), 0, 1, 12);
        setDate(date);
        props.dateFilters.setStartDate(new Date(date.getFullYear(), 0, 1, 12));
        props.dateFilters.setEndDate(new Date(date.getFullYear(), 11, 31, 12));
      } else if (option === 'period') {
        props.dateFilters.setStartDate(new Date(today.getFullYear(), 0, 1, 12));
        props.dateFilters.setEndDate(today);
      }
    }
  };

  const handleYearChange = (date: Date | null) => {
    if (props.dateFilters) {
      setDate(date);
      if (!date) {
        return;
      }
      const year = date.getFullYear();
      if (props.dateFilters.selectedDateOption === 'fiscal-year') {
        props.dateFilters.setStartDate(new Date(year, 3, 1, 12));
        props.dateFilters.setEndDate(new Date(year + 1, 2, 31, 12));
      } else if (props.dateFilters.selectedDateOption === 'calendar-year') {
        props.dateFilters.setStartDate(new Date(year, 0, 1, 12));
        props.dateFilters.setEndDate(new Date(year, 11, 31, 12));
      }
    }
  };

  const handleStartDateChange = (date: Date) => {
    if (props.dateFilters) {
      props.dateFilters.setStartDate(date);
    }
  };

  const handleEndDateChange = (date: Date) => {
    if (props.dateFilters) {
      props.dateFilters.setEndDate(date);
    }
  };

  const handleFilterASM = (id: string, selected: Array<string>) => {
    const dealerFilter: DashboardDealerFilter = {
      ...dashboardFilterState.dealerFilter,
      asm: selected,
    };
    dispatch(dashboardFilterActions.updateDealerFilter(dealerFilter));
  };

  const handleFilterDSM = (id: string, selected: Array<string>) => {
    const dealerFilter: DashboardDealerFilter = {
      ...dashboardFilterState.dealerFilter,
      dsm: selected,
    };
    dispatch(dashboardFilterActions.updateDealerFilter(dealerFilter));
  };

  const handleFilterMainDealer = (id: string, selected: Array<string>) => {
    const dealerFilter: DashboardDealerFilter = {
      ...dashboardFilterState.dealerFilter,
      mainDealers: selected,
    };
    dispatch(dashboardFilterActions.updateDealerFilter(dealerFilter));
  };

  const setupSelectedDealersCodes = (dealers: FilterItem[]) => {
    const codes: string[] = [];
    dealers.forEach(function (dealer) {
      codes.push(dealer.id);
    });
    const dealerFilter: DashboardDealerFilter = {
      ...dashboardFilterState.dealerFilter,
      dealers: codes,
    };
    dispatch(dashboardFilterActions.updateDealerFilter(dealerFilter));
  };

  const handleFilterDealerNumber = (id: string, selected: Array<string>) => {
    const dealerFilter: DashboardDealerFilter = {
      ...dashboardFilterState.dealerFilter,
      dealers: selected,
    };
    dispatch(dashboardFilterActions.updateDealerFilter(dealerFilter));
  };

  useEffect(() => {
    if (props.showOnlyDealersScope) {
      setupSelectedDealersCodes(availableDealers);
    }
  }, [props.showOnlyDealersScope, availableDealers]);

  const checkMainDealerDate = (d: DealerBasic | Dealer) => {
    if (!(props && props.dateFilters)) {
      return true;
    }

    let result = true;

    if (props.dateFilters.endDate) {
      if (d.salesContractStart && d.salesContractStart != null && new Date(d.salesContractStart) > props.dateFilters.endDate) {
        result = false;
      } else if (d.serviceContractStart && d.serviceContractStart != null && new Date(d.serviceContractStart) > props.dateFilters.endDate) {
        result = false;
      }
    }

    if (props.dateFilters.startDate) {
      if (d.salesContractEnd && d.salesContractEnd != null && new Date(d.salesContractEnd) < props.dateFilters.startDate) {
        result = false;
      } else if (d.serviceContractEnd && d.serviceContractEnd != null && new Date(d.serviceContractEnd) < props.dateFilters.startDate) {
        result = false;
      }
    }

    return result;
  }

  const shouldMainDealerBeDisplayed = (dealer: DealerBasic): boolean => {
    const dealerCode = dealer.dealercode;

    if (props.showOnlyDealersScope && dealer.isMainDealer) {
      const scope = props.showOnlyDealersScope;
      if (scope === DealersScope.MOTO) {
        return isMotoDealerCode(dealerCode);
      } else if (scope === DealersScope.AUTO) {
        return isAutoDealerCode(dealerCode);
      }
    }
    return dealer.isMainDealer;
  }

  const shouldAreaAdminBeDisplayed = (code: string, motoCodeStartsWith: string): boolean => {
    const isMoto = code.startsWith(motoCodeStartsWith);

    if (!props.showOnlyDealersScope) return true;

    const scope = props.showOnlyDealersScope;
    return (scope === DealersScope.MOTO) ? isMoto : (scope === DealersScope.AUTO) ? !isMoto : true;
  }

  function getMainDealers() {
    if (session?.identity?.traits?.isAdmin) {
      return basicDealers.data ? basicDealers.data
        .filter(d => !d.deleted)
        .filter(d => shouldMainDealerBeDisplayed(d))
        .filter(d => checkMainDealerDate(d))
        .filter(d => ((!dashboardFilterState.dealerFilter.dsm || dashboardFilterState.dealerFilter.dsm.length === 0)
            && (!dashboardFilterState.dealerFilter.asm || dashboardFilterState.dealerFilter.asm.length === 0))
          || (dashboardFilterState.dealerFilter.dsm.includes(d.aftersalesArea) || dashboardFilterState.dealerFilter.asm.includes(d.salesArea)))
        .map((dn) => {
          return {
            id: dn.dealercode,
            name: buildDealerFilterItemName(dn.dealercode, dn.name),
          };
        })
        .sort((a, b) => a.name.localeCompare(b.name)) : [];
    }
    if (session?.identity?.traits?.isAreaAdmin) {
      if (!allDealers.data) {
        return [];
      }
      const dealerIds: string[] = session?.identity?.traits?.dealers?.map(
        (d: any) => d.id
      );
      return dealerIds ? allDealers.data
        .filter(d => dealerIds.includes(d.objectID))
        .filter(d => !d.deleted)
        .filter(d => shouldMainDealerBeDisplayed(d))
        .filter(d => checkMainDealerDate(d))
        .map((dn) => {
          return {
            id: dn.dealercode,
            name: buildDealerFilterItemName(dn.dealercode, dn.name),
          };
        })
        .sort((a, b) => a.name.localeCompare(b.name)) : [];
    }
    return [];
  }

  return (
    <>
      <div className="flex flex-row items-center -ml-8 gap-x-8 py-2 pr-8 px-8 mt-0">
      {session.identity?.traits?.isAdmin && (
          <div className="flex flex-row items-center ml-0 gap-x-8 py-1">
            <Filter
              key={'asm-filter'}
              id={'asm-filter'}
              name={t('contacts.dpd-report.asm')}
              items={availableASM.filter(asm => shouldAreaAdminBeDisplayed(asm, 'M')).map((asm) => {
                return {id: asm, name: asm};
              })}
              onChange={handleFilterASM}
              selected={dashboardFilterState.dealerFilter.asm}
              width="w-[15rem]"
            />
            <Filter
              key={'dsm-filter'}
              id={'dsm-filter'}
              name={t('contacts.dpd-report.dsm')}
              items={availableDSM.filter(dsm => shouldAreaAdminBeDisplayed(dsm, 'S')).map((dsm) => {
                return {id: dsm, name: dsm};
              })}
              onChange={handleFilterDSM}
              selected={dashboardFilterState.dealerFilter.dsm}
              width="w-[15rem]"
            />
          </div>
        )}
        {(session.identity?.traits?.isAdmin || session.identity?.traits?.isAreaAdmin) && (
          <div className="flex flex-row items-center ml-0 gap-x-8 py-1">
            <Filter
              key={'main-dealer-filter'}
              id={'main-dealer-filter'}
              name={t('communication.test-drive.report.main-dealer')}
              items={getMainDealers()}
              onChange={handleFilterMainDealer}
              selected={dashboardFilterState.dealerFilter.mainDealers}
              width="w-[30rem]"
            />
          </div>
        )}
        {allAvailableDealers.length > 1 && (
          <div className="flex flex-row items-center gap-x-8 py-1">
            <Filter
              key={'dealer-filter'}
              id={'dealer-filter'}
              name={t('contacts.dpd-report.dealernumber')}
              items={availableDealers}
              onChange={handleFilterDealerNumber}
              width="w-[30rem]"
              selected={dashboardFilterState.dealerFilter.dealers}
            />
          </div>
        )}
      </div>

      {props.dateFilters && (
        <div className="flex flex-row items-center -ml-8 gap-x-8 py-2 pr-8 px-8 mt-5">
          <SelectInput
            options={[
              {
                key: 'fiscal-year',
                value: 'fiscal-year',
                name: t('reports.crr.filter.fiscal-year'),
              },
              {
                key: 'calendar-year',
                value: 'calendar-year',
                name: t('reports.crr.filter.calendar-year'),
              },
              {
                key: 'period',
                value: 'period',
                name: t('reports.crr.filter.period'),
              },
            ]}
            label={{name: t('reports.crr.filter.year'), position: 'top'}}
            onSelect={handleOptionChange}
          />
          {props.dateFilters.selectedDateOption === 'calendar-year' ? (
            <DatePickerInput
              label={{
                name: t('reports.crr.filter.calendar-year-select'),
                position: 'top',
              }}
              isYear
              selectedDay={date}
              handleDateChange={(date) => handleYearChange(date)}
              minDate={new Date(2006, 0, 1)}
            />
          ) : props.dateFilters.selectedDateOption === 'fiscal-year' ? (
            <DatePickerInput
              label={{
                name: t('reports.crr.filter.fiscal-year-select'),
                position: 'top',
              }}
              isYear
              selectedDay={date}
              handleDateChange={(date) => handleYearChange(date)}
              minDate={new Date(2012, 0, 1)}
            />
          ) : (
            <DatePickerRange
              id="t"
              labelFrom={t(`reports.crr.filter.from`)}
              labelTo={t(`reports.crr.filter.to`)}
              from={props.dateFilters.startDate}
              to={props.dateFilters.endDate}
              changeFrom={handleStartDateChange}
              changeTo={handleEndDateChange}
            />
          )}
        </div>
      )}
    </>
  );
};

export default ReportFilters;

import { VehicleRegistration } from './vehicle-registration';
import { ChangeHistoryEntry } from "./history-entry";

export interface VehiclesData {
  vehicle: Vehicle;
  currentVehicleRegistration?: VehicleRegistration;
  owner: ContactData;
}

export interface ContactData {
  addressline1?: string;
  addressline2?: string;
  zip?: string;
  city?: string;
  street?: string;
  countrycode?: string;
  districtareacode?: string;
  firstname?: string;
  name?: string;
  customerNumber?: string;
  objectId?: string;
}

export interface Vehicle {
  objectId?: string;
  id?: number;
  domain_vehicle_model_id?: number;
  active?: boolean;
  modeltype?: number;
  vin?: string;
  firstRegistraion?: Date;
  origin?: number;
  mileage?: number;
  vehicletype?: number;
  vehiclestatus?: number;
  owner?: string[];
  VehicleSpecification?: VehicleSpecification;
  VehicleInformation?: VehicleInformation;
  created?: Date;
  modified?: Date;
  createdBy?: string;
  modifiedBy?: string;
  brand?: string;
  model?: string;
  datemileageupdate?: Date;
  modeldescription?: string;
  last_origin?: string;
  modelcode?: string;
  configurationtype?: string;
  type?: string;
  specialmodel?: boolean;
  category?: string;
  visible4dealersonly?: boolean;
  referencenumber?: string;
  notice?: string;
  hu?: Date;
  au?: Date;
  locosoftnumber?: string;
  crashnumber?: number;
  dateinspectionnotice1?: Date;
  dateinspectionnotice2?: Date;
  countinspectionnotice?: number;
  mileageupdatenotice?: string;
  envkvcompliant?: boolean;
  deliverydate?: Date;
  deliveryperiod?: number;
  huaunew?: boolean;
  export?: boolean;
  attentiongetter?: boolean;
  previousholders?: string;
  warrantyinmonths?: number;
  dmsxml?: string;
  description?: string;
  lastservice?: Date;
  locosoftid?: string;
  accessibillity?: Accessibillity[];
  deactivation?: Deactivation;
  changeHistory?: ChangeHistoryEntry[];
  //TODO temporary field. To remove.
  mcId?: string;
  drivers?: Driver[];
}

export interface VehicleSpecification {
  gearbox?: number;
  drivingmode?: number;
  emissionclass?: number;
  emissionsticker?: number;
  alarm?: boolean;
  trailerhitch?: boolean;
  abs?: boolean;
  esp?: boolean;
  tractioncontrol?: boolean;
  phone?: boolean;
  onboardcomputer?: boolean;
  navi?: boolean;
  tv?: boolean;
  handicappedaccessible?: boolean;
  airbagpassenger?: boolean;
  airbag?: boolean;
  airbagside?: boolean;
  lateralprotectionstructure?: boolean;
  colorglas?: boolean;
  roofrails?: boolean;
  electricwindows?: boolean;
  electricseats?: boolean;
  belttensioner?: boolean;
  catalyticconverter?: boolean;
  aircondition?: boolean;
  airconditionauto?: boolean;
  leather?: boolean;
  alloywheelrim?: boolean;
  foglamp?: boolean;
  parkdistancecontrol?: boolean;
  particlefilter?: boolean;
  cdplayer?: boolean;
  radiocassette?: boolean;
  sunroof?: boolean;
  powersteering?: boolean;
  seatsheated?: boolean;
  auxiliaryheating?: boolean;
  taxi?: boolean;
  tempomat?: boolean;
  tuning?: boolean;
  immobilizerelectronic?: boolean;
  headlightxeon?: boolean;
  centrallocking?: boolean;
  engine?: string;
  doorcount?: number;
  fueltype?: string;
  cylinders?: number;
  cylindercapacity?: number;
  structure?: string;
  kw?: number;
  checkbook?: boolean;
  seatcount?: number;
  enginereplacement?: boolean;
  hsn?: string;
  tsn?: string;
  color?: string;
  colorcode?: string;
  colorgroup?: string;
  series?: string;
  gearcount?: number;
  powertransmission?: string;
  weightempty?: number;
  vehicleloadcapacity?: number;
  fuelconsumptionintown?: number;
  fuelconsumptionofftown?: number;
  fuelconsumptioncombined?: number;
  emmissionco2?: number;
  colorinterior?: string;
  complementarycolorcode?: string;
  complementarycolordescription?: string;
  statusswapkilometerindicator?: boolean;
  nonsmokercar?: boolean;
  steelrim?: boolean;
  biodieselsuitable?: boolean;
  kickstarter?: boolean;
  electricstarter?: boolean;
  constructionyear?: number;
  e10?: boolean;
  box?: boolean;
  rolloverbar?: boolean;
  windshield?: boolean;
  vegetableoilfuelsuitable?: boolean;
  oldtimer?: boolean;
  manufacturercolorname?: string;
  airbagtype?: number;
  climatisationtype?: number;
  basecolor?: string;
  firstpic?: string;
  interiorcolor?: number;
  interiortype?: number;
  topad?: boolean;
  countryversion?: string;
  bikedrivingmode?: number;
  cladding?: boolean;
  heatedhold?: boolean;
  fueltypes?: string;
  mostemissionefficientfueltype?: string;
  co2efficiencyclass?: string;
  powerconsumptioncombined?: number;
  sportspackage?: boolean;
  automaticignition?: boolean;
  multifunctional_wheel?: boolean;
  daytimerunninglamp?: boolean;
  sportssuspension?: boolean;
  sportsseats?: boolean;
  adaptiveheadlights?: boolean;
  skisack?: boolean;
  alloywheelsize?: number;
  panoramicroof?: boolean;
  envkvfuel?: string;
  existingenergylabel?: boolean;
  discbrake?: boolean;
  varnishmetallic?: boolean;
}

export interface VehicleInformation {
  owner?: string;
  accidentalcar?: boolean;
  previousholdercount?: number;
  sellingprice?: number;
  originalprice?: number;
  valueaddedtax?: boolean;
  bonus?: boolean;
  newpurchaseplanned?: boolean;
  authorizedrepaired?: boolean;
  signetdekra?: boolean;
  manufacturerwarrantyseal?: boolean;
  dailyoffer?: boolean;
  currency?: string;
  vatrate?: number;
  dealerprice?: number;
  warranty?: boolean;
  reserved?: boolean;
  schwackecode?: number;
  pageonead?: boolean;
  includeddeliverycosts?: number;
  renewad?: boolean;
  bovagseal?: boolean;
  napseal?: boolean;
  usedcarwarranty?: boolean;
  webmobilid?: string;
  negotiatableprice?: boolean;
  lastcambeltchange?: Date;
  autoscoutid?: string;
}

interface Accessibillity {
  disablingConfirmationDate?: Date;
  disablingConfirmationState?: string;
  disablingDate?: Date;
  disablingEditor?: string;
  disablingReasonDescription?: string;
  disablingReasonType?: HidingReasonType;
  dealerCodes?: string[];
}

interface Deactivation {
  deactivationValid?: boolean;
  deactivationReason?: DeactivatingReasonType;
  deactivationDate?: Date;
  editor?: string;
}

export interface VehicleRequestBody {
  excludeSubDocuments: boolean;
  detail: number;
  pageSize?: number;
  pageIndex?: number;
  filter?: VehicleFilter;
  sortField?: string;
  sortDir?: number;
}

export interface UnhideVehiclesRequest {
  vehicleIds: string[];
}

export interface UnhideVehiclesResponse {
  notUnhiddenVehiclesIds: string[];
}


export interface VehicleFilter {
  search?: string;
  filter?: {
    field?: string;
    value?: string;
  }[];
  showHiddenVehicles?: boolean;
  showDeactivatedVehicles?: boolean;
  dealercodes?: string[];
  vehicleModels?: string[];
  registrationDateFrom?: Date;
  registrationDateTo?: Date;
}

export interface Driver {
    contactId?: string;
    companyId?: string;
    dealerCode?: string;
    origin?: string;
    startConnectionDate?: Date;
    endConnectionDate?: Date;
    endReason?: string;
    active?: boolean;
}

export enum HidingReasonType {
  SOLD_TO_UNKNOWN = "SOLD_TO_UNKNOWN",
  OTHER = "OTHER",
}

export enum DeactivatingReasonType {
  EXPORTED = "EXPORTED",
  STOLEN = "STOLEN",
  SCRAPPED = "SCRAPPED",
}

import './company-edit.module.scss';

import {useEffect, useState} from 'react';
import {Headline, MultipleValueInputRow,} from '@suzuki-frontend-framework-update/ui';
import {useApiGetData} from '../../components/useApiGetData/useApiGetData';
import {Company, ContactEmail, ContactFax, ContactPhone,} from '../../models/contact';
import {environment} from '../../../environments/environment';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';
import {sendApiRequest} from '../../components/send-api-request/send-api-request';
import {format} from 'react-string-format';
import CompanyForm, {CompanyValue} from '../company-form/company-form';
import {toast} from 'react-toastify';
import {validateEmailOrEmpty} from '../../helpers/email-validation-helper';

export function CompanyEdit() {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {id} = useParams();

  const [requestBody] = useState({
    id: id,
    details: 'FULL',
  });

  const customIdSuccess = 'custom-id-success';
  const customIdFailed = 'custom-id-Failed';
  const successToast = (successText: string) => {
    toast.success(successText, {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      toastId: customIdSuccess,
    });
  };

  const errorToast = (errorText: string) => {
    toast.error(errorText, {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      toastId: customIdFailed,
    });
  };

  const contactData = useApiGetData<Company>(
    `${environment.restEndpoint}/contact/contact-service/company/GetCompany`,
    'post',
    undefined,
    requestBody
  );

  const [formValues, setFormValues] = useState<CompanyValue>({
    email: [],
    phone: [],
    fax: [],
  });

  useEffect(() => {
    const getEmails = (): MultipleValueInputRow[] => {
      const result: MultipleValueInputRow[] = [];
      if (contactData.data?.email) {
        let key = 0;
        contactData.data?.email.forEach((email) => {
          if (email.email)
            result.push({
              key: key,
              inputValue: email.email,
              selectValue: email.type ? email.type : '',
              default: email.default ? email.default : false,
            });
          key++;
        });
      }
      return result;
    };

    const getPhones = (): MultipleValueInputRow[] => {
      const result: MultipleValueInputRow[] = [];
      if (contactData.data?.phone) {
        let key = 0;
        contactData.data?.phone.forEach((phone) => {
          if (phone.phoneNumber)
            result.push({
              key: key,
              inputValue: phone.phoneNumber,
              selectValue: phone.type ? phone.type : '',
              default: phone.default ? phone.default : false,
            });
          key++;
        });
      }
      return result;
    };

    const getFaxes = (): MultipleValueInputRow[] => {
      const result: MultipleValueInputRow[] = [];
      if (contactData.data?.fax) {
        let key = 0;
        contactData.data?.fax.forEach((fax) => {
          if (fax.faxNumber)
            result.push({
              key: key,
              inputValue: fax.faxNumber,
              selectValue: fax.type ? fax.type : '',
              default: fax.default ? fax.default : false,
            });
          key++;
        });
      }
      return result;
    };

    if (!contactData.loading) {
      setFormValues({
        name: contactData.data?.name,
        isDealer: contactData.data?.isDealer,
        foundingYear: contactData.data?.foundingYear,
        street: contactData.data?.address?.street,
        address: contactData.data?.address?.addressline1,
        postcode: contactData.data?.address?.zip,
        city: contactData.data?.address?.city,
        countrycode: contactData.data?.address?.countrycode,
        email: getEmails(),
        phone: getPhones(),
        fax: getFaxes(),
      });
    }
  }, [contactData.data, contactData.loading]);

  const handleSubmit = (formValues: CompanyValue) => {
    const emails: ContactEmail[] = [];
    const phones: ContactPhone[] = [];
    const faxes: ContactFax[] = [];
    let mailValid = true;
    formValues.email.forEach((email) => {
      if (!validateEmailOrEmpty(email.inputValue.toLowerCase())) {
        mailValid = false;
      }
      emails.push({
        email: email.inputValue,
        default: email.default,
        type: email.selectValue,
      });
    });
    formValues.phone.forEach((phone) => {
      phones.push({
        phoneNumber: phone.inputValue,
        default: phone.default,
        type: phone.selectValue,
      });
    });
    formValues.fax.forEach((fax) => {
      faxes.push({
        faxNumber: fax.inputValue,
        default: fax.default,
        type: fax.selectValue,
      });
    });
    if (!mailValid) {
      errorToast(t('error-wrong-email'));

      return;
    }
    if (!formValues.name) {
      errorToast(t('error-required'));
      return;
    } else if (formValues.name) {
      if (!formValues.name.trim()) {
        errorToast(t('error-required'));
        return;
      }
    }
    sendApiRequest(
      `${environment.restEndpoint}/contact/contact-service/company/UpdateCompany`,
      'PUT',
      {
        id: id,
        company: {
          ...contactData.data,
          name: formValues.name,
          isDealer: formValues.isDealer,
          address: {
            addressline1: formValues.address,
            street: formValues.street,
            zip: formValues.postcode,
            city: formValues.city,
            countrycode: formValues.countrycode,
          },
          email: emails,
          phone: phones,
          fax: faxes,
          foundingYear: formValues.foundingYear,
          modified: undefined,
          modifiedBy: undefined,
        } as Company,
      }
    ).then((response) => {
      if (response.ok) {
        successToast(t('success'));
        navigate(-1);
      } else {
        errorToast(t('error') + response.statusText);
      }
    });
  };

  const handleCancel = () => {
    navigate(-1);
  };

  return (
    <div className="grow bg-gray">
      <Headline className="p-8 pb-0" title={t('contact-form.edit-contact')}/>
      <div className="text-dark-gray p-8 pt-0 -mt-2 ">
        {contactData.data?.created && (
          <span className="text-dark-gray  font-suzuki-regular">
            {format(
              t('contact-details.created-at'),
              new Date(contactData.data?.created).toLocaleDateString('de-DE', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }),
              contactData.data?.createdBy
                ? contactData.data?.createdBy
                : '-'
            )}
          </span>
        )}
        {contactData.data?.modified && (
          <span className="text-dark-gray  font-suzuki-regular ml-3">
            {format(
              t('contact-details.modified-at'),
              new Date(contactData.data?.modified).toLocaleDateString('de-DE', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }),
              contactData.data?.modifiedBy
                ? contactData.data?.modifiedBy
                : '-'
            )}
          </span>
        )}
      </div>

      <CompanyForm
        handleCancel={handleCancel}
        handleSubmit={handleSubmit}
        formValues={formValues}
      />
    </div>
  );
}

export default CompanyEdit;

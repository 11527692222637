import {FinishTestDriveRequest, ResponseState, TestDriveData, TestDriveResponseOptions} from "../../models/test-drive";
import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import {toast, ToastOptions} from "react-toastify";
import {sendApiRequest} from "../send-api-request/send-api-request";
import {Badge, Button, ButtonStyle, Modal, Textarea} from "@sde/basic-ui-library";
import {CustomerType} from "../../models/contact";
import {format} from "react-string-format";
import {environment} from "../../../environments/environment";
import {TestDriveInformation} from "../test-drive-information/test-drive-information";
import {TestDriveResponseEditable} from "../test-drive-response/test-drive-response-editable";

export interface FinishTestDrivePopupProps {
  setModalState: (state: boolean) => void;
  modalState: boolean;
  testDrive: TestDriveData;
  refreshData: () => void;
}

export const FinishTestDrivePopup = ({testDrive, modalState, setModalState, refreshData}: FinishTestDrivePopupProps) => {
  const initializeDealerComment = (): string => {
    if (testDrive.dealerComment && testDrive.dealerComment.trim() !== '') {
      return testDrive.dealerComment;
    }
    return '';
  }

  const {response} = testDrive;

  const { t } = useTranslation();
  const [dealerComment, setDealerComment] = useState<string>(initializeDealerComment());
  const [boughtVehicleVin, setBoughtVehicleVin] = useState<string>('');
  const [responseTestDrivePerformed, setResponseTestDrivePerformed] = useState<string>(ResponseState.UNKNOWN);
  const [responseInterested, setResponseInterested] = useState<string>(ResponseState.UNKNOWN);
  const [responsePurchase, setResponsePurchase] = useState<string>(ResponseState.UNKNOWN);

  const notSpecifiedText = t('communication.test-drive.report.modals.not-specified');

  useEffect(() => {
    setDealerComment(initializeDealerComment());
  }, [testDrive.dealerComment]);

  useEffect(() => {
    if (responsePurchase !== ResponseState.TRUE) {
      setBoughtVehicleVin('');
    }
  }, [responsePurchase]);

  useEffect(() => {
    if (modalState && response) {
      setBoughtVehicleVin(response.purchaseOfVehicle?.purchasedVehicleVin ?? '');
      setResponseTestDrivePerformed(response.testDrivePerformed ? ResponseState.TRUE : ResponseState.FALSE);
      setResponseInterested(response.interestedInVehicle ?? ResponseState.UNKNOWN);
      setResponsePurchase(response.purchaseOfVehicle?.purchasedVehicle ?? ResponseState.UNKNOWN);
    }
  }, [response, modalState]);

  const defaultToastOptions: ToastOptions = {
      position: 'top-center',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
  }

  const successToast = (successText: string) => {
    toast.success(successText, {
      ...defaultToastOptions,
      toastId: 'finish-test-drive-success-toast',
    });
  };

  const errorToast = (errorText: string) => {
    toast.error(errorText, {
      ...defaultToastOptions,
      toastId: 'finish-test-drive-error-toast',
    });
  };

  const resetPopupStates = () => {
    setDealerComment(initializeDealerComment());
    setBoughtVehicleVin('');
    setResponseTestDrivePerformed(ResponseState.UNKNOWN);
    setResponseInterested(ResponseState.UNKNOWN);
    setResponsePurchase(ResponseState.UNKNOWN);
  }

  const areRequiredFieldsSet = (): boolean => {
    const isResponseTestDrivePerformedSet = responseTestDrivePerformed !== undefined && responseTestDrivePerformed.trim() !== '';
    const isResponseInterestedSet = responseInterested !== undefined && responseInterested.trim() !== '';
    const isResponsePurchaseSet = responsePurchase !== undefined && responsePurchase.trim() !== '';
    return isResponseTestDrivePerformedSet && isResponseInterestedSet && isResponsePurchaseSet;
  }

  const constructDealerResponse = (): TestDriveResponseOptions => {
    const wasTestDrivePerformed = responseTestDrivePerformed === ResponseState.TRUE;

    if (wasTestDrivePerformed) {
      return {
        testDrivePerformed: true,
        interestedInVehicle: responsePurchase === ResponseState.UNKNOWN ? responseInterested : responsePurchase,
        purchaseOfVehicle: {
          purchasedVehicle: responsePurchase,
          purchasedVehicleVin: responsePurchase === ResponseState.TRUE ? boughtVehicleVin : '',
        }
      } as TestDriveResponseOptions
    }

    return {
      testDrivePerformed: false,
      interestedInVehicle: ResponseState.UNKNOWN,
      purchaseOfVehicle: {
        purchasedVehicle: ResponseState.UNKNOWN,
        purchasedVehicleVin: '',
      }
    } as TestDriveResponseOptions
  }

  const handleCancel = () => {
    setModalState(false);
    resetPopupStates();
  };

  const handleSubmit = () => {
    if(!areRequiredFieldsSet()) {
      errorToast(t('communication.test-drive.report.modals.finish-test-drive.fill-required-fields'));
      return;
    }
    const requestUrl = `${environment.restEndpoint}/action/testdrives/FinishTestDrive`;
    const dealerResponse = constructDealerResponse();

    const requestBody: FinishTestDriveRequest = {
      id: testDrive.id,
      response: dealerResponse,
      dealerComment: dealerComment,
    }

    sendApiRequest(requestUrl, 'post', requestBody).then((response) => {
      if (response.ok) {
        successToast(t('communication.test-drive.report.modals.finish-test-drive.successfully-finished'));
        handleCancel();
        if (refreshData) refreshData();
      } else {
        response.json().then((data) => {
          errorToast(t('error') + data.message);
        });
      }
    });
  }

  return (
    <div>
      <Modal
        key="schedule-test-drive-modal"
        title={t('communication.test-drive.report.modals.finish-test-drive.modal-title')}
        show={modalState}
        handleClose={() => handleCancel()}
        size='min-w-[440px]'
      >
        <div className="flex flex-col">
          <div className="flex flex-row items-start ps-5 gap-x-8 align-middle">
            <span className="font-suzuki-headline text-dark" style={{fontSize: '30px'}}>{testDrive.companyId ? `${testDrive?.contactSalutation} ${testDrive?.companyName}` : `${testDrive?.contactSalutation} ${testDrive?.contactFirstName} ${testDrive?.contactLastName}`}</span>
            <Badge
              name={
                testDrive?.customerState === CustomerType.CUSTOMER
                  ? t(
                    'contact-details.customer'
                  ).toLocaleUpperCase()
                  : testDrive?.customerState === CustomerType.PROSPECT
                    ? t(
                      'contact-details.prospect'
                    ).toLocaleUpperCase()
                    : testDrive?.customerState === CustomerType.LC
                      ? t(
                        'contact-details.lost-customer'
                      ).toLocaleUpperCase()
                      : notSpecifiedText.toLocaleUpperCase()
              }
              title={
                testDrive?.customerState === CustomerType.CUSTOMER
                  ? t('contact-details.customer')
                  : testDrive?.customerState === CustomerType.PROSPECT
                    ? t('contact-details.prospect')
                    : testDrive?.customerState === CustomerType.LC
                      ? t('contact-details.lost-customer')
                      : notSpecifiedText.toLocaleUpperCase()
              }
            />
          </div>
          <div className="flex items-start ps-5 pt-2">
            <span className="text-dark-gray font-suzuki-regular">
              {
                `${format(
                  t('communication.test-drive.report.modals.created-at'),
                  testDrive.createAt ? new Date(testDrive.createAt).toLocaleDateString(
                    'de-DE', {year: 'numeric', month: '2-digit', day: '2-digit'}
                  ) : notSpecifiedText,
                  testDrive.origin ? t(`communication.test-drive.origin.${testDrive.origin}`) : notSpecifiedText,
                )} ${testDrive.lastChangeDate ? format(
                  t('communication.test-drive.report.modals.modified-at'),
                  new Date(testDrive.lastChangeDate).toLocaleDateString(
                    'de-DE', {year: 'numeric', month: '2-digit', day: '2-digit'}
                  ),
                  testDrive.lastChangeEditor ? testDrive.lastChangeEditor : notSpecifiedText,
                ) : ''}`
              }
            </span>
          </div>
          <TestDriveInformation
              testDrive={testDrive}
              dealerCommentEditable={
                <Textarea
                    value={dealerComment}
                    onChange={(value) => setDealerComment(value)}
                    width="100%"
                    label={{
                      name: t('communication.test-drive.report.modals.comment'),
                      position: 'top',
                    }}
                    rows={4}
                    resizeDisabled
                />
              }
          />
          <TestDriveResponseEditable
              boughtVehicleVinState={boughtVehicleVin}
              setBoughtVehicleVinState={setBoughtVehicleVin}
              testDrivePerformedState={responseTestDrivePerformed}
              setTestDrivePerformedState={setResponseTestDrivePerformed}
              interestedState={responseInterested}
              setInterestedState={setResponseInterested}
              purchaseState={responsePurchase}
              setPurchaseState={setResponsePurchase}
          />
        </div>
        <div className="flex flex-row gap-x-6 justify-end">
          <Button
            className="bg-white"
            buttonStyle={ButtonStyle.OUTLINE}
            onClick={handleCancel}
            px="5"
            py="3"
          >
            {t('communication.test-drive.report.modals.close')}
          </Button>
          <Button onClick={handleSubmit} px="5" py="3">
            {t('communication.test-drive.report.modals.save')}
          </Button>
        </div>
      </Modal>
    </div>
  )

}

import React, { useEffect, useState } from 'react';
import moment from 'moment';
import download from 'downloadjs';
import { Headline, Table } from '@suzuki-frontend-framework-update/ui';
import { BxCheck, BxDownload, BxsHourglassTop, BxX, } from '@suzuki-frontend-framework-update/icons';
import { IGroup } from '../../../../models/newsletter';
import { environment } from '../../../../../environments/environment';
import { sendApiRequest } from '../../../../components/send-api-request/send-api-request';
import axios from 'axios';
import { useSelector } from "react-redux";
import { selectAllUserManagement } from "../../../../store/slices/user-management.slice";
import { isAdmin } from "../../../../helpers/session-utils";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export interface NewsletterHistoryProps {
  onDataChange?: (arg0: unknown) => void;
  parentRef?: React.RefObject<unknown>;
}

export interface NewsletterHistoryResponse {
  cleverreachGroupId?: number | string;
  exportFileId?: number | string;
  result?: string;
  category?: string;
  createdAt?: Date | string;
  objectId?: string;
  exportType?: string;
  finishedAt?: Date | string;
  numberOfSubscriber?: number;
  downloadable?: boolean;
}

const NewsletterHistory = ({
  onDataChange,
  parentRef,
}: NewsletterHistoryProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate()
  const entities = useSelector(selectAllUserManagement);
  useEffect(() => {
    const lastEntity = entities[entities.length - 1];
    if (!lastEntity?.currentUser || !isAdmin(lastEntity.currentUser)) {
      navigate('/communication')
    }
  }, [entities]);

  const [historyList, setHistoryList] = useState(
    [] as NewsletterHistoryResponse[]
  );
  const [lastListUpdate, setLastListUpdate] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setLastListUpdate((lastVal) => lastVal + 1000);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const [cleverreachGroups, setCleverreachGroups] = useState<IGroup[]>([]);

  useEffect(() => {
    if (!cleverreachGroups || cleverreachGroups.length === 0) {
      sendApiRequest(`${environment.restEndpoint}/cleverreach/groups`, 'get')
        .then((res) => res.json())
        .then((gres) => {
          setCleverreachGroups(gres.groups);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [cleverreachGroups, setCleverreachGroups]);

  useEffect(() => {
    if (historyList.length > 0) {
      const iD = historyList.findIndex((n: NewsletterHistoryResponse) => {
        return !!(n.exportType === 'CSV' && n.finishedAt);
      });
      const fill = historyList.map(
        (element: NewsletterHistoryResponse, index: number) => {
          index !== iD
            ? (element.downloadable = false)
            : (element.downloadable = true);
          return element;
        }
      );
      setHistoryList(fill);
    }
  }, []);

  useEffect(() => {
    if ((historyList && historyList.length == 0) || lastListUpdate > 5000) {
      sendApiRequest(`${environment.restEndpoint}/contact/newsletter/exports`, 'get')
        .then((res) => res.json())
        .then((exports) => {
          //console.log("exports found:", exports)
          setHistoryList(exports.entries);
          setLastListUpdate(0);
        });
    }
  }, [historyList, lastListUpdate]);

  const roundTwoDecimals = (n: number): number => {
    return Math.round((n + Number.EPSILON) * 100) / 100;
  };

  const calcDuration = (from: string, to: string): string => {
    const res = moment.duration(moment.utc(to).diff(moment.utc(from)));
    if (res.asHours() > 24) {
      return `${roundTwoDecimals(res.asDays())} d`;
    }
    if (res.asMinutes() > 59) {
      return `${roundTwoDecimals(res.asHours())} h`;
    }
    if (res.asSeconds() > 59) {
      return `${roundTwoDecimals(res.asMinutes())} min`;
    }
    if (res.asMilliseconds() > 999) {
      return `${roundTwoDecimals(res.asSeconds())} s`;
    }
    return `${roundTwoDecimals(res.milliseconds())} ms`;
  };

  const mapGroupName = (id: number | string): string => {
    if (cleverreachGroups) {
      const gitem = cleverreachGroups.find((i: IGroup) => {
        return `${i.id}` === `${id}`;
      });
      if (gitem) {
        return gitem.name;
      } else if (id == 0) {
        return '-';
      } else {
        return `Unbekannt (ID:${id})`;
      }
    }
    return `${id}`;
  };

  const constructStatus = (val: NewsletterHistoryResponse) => {
    if (
      (val.createdAt && !val.finishedAt) ||
      val.finishedAt == '0001-01-01T00:00:00Z'
    ) {
      return (
        <p className="text-darkblueactive">
          <BxsHourglassTop className="fill-current" />
        </p>
      );
    }
    if (val.createdAt && val.finishedAt && val.result) {
      return (
        <p className="text-takumi">
          <BxCheck className="fill-current" />
        </p>
      );
    }
    return (
      <p className="text-red">
        <BxX className="fill-current" />
      </p>
    );
  };

  const newsletterTypeToContext = (row: any, cell: any): React.ReactNode => {
    if (row && row.cells) {
      const entry = row.cells.find((cell: any) => {
        if (cell.column.id === 'exportType') {
          return true;
        }
        return false;
      });
      if (entry && entry.value === 'CSV') {
        return (
          <div
            className="min-w-full min-h-full flex items-center justify-center"
            onClick={() => downloadFile(cell.value, row)}
          >
            <div className="m-2">
              <BxDownload />
            </div>
          </div>
        );
      }
      if (
        entry &&
        entry.value === 'CLEVERREACH' &&
        entry.row.original.result !== 'finished'
      ) {
        return (
          <div className="min-w-full min-h-full flex items-center justify-center">
            {entry.row.original.result}
          </div>
        );
      } else if (
        entry &&
        entry.value === 'CLEVERREACH' &&
        entry.row.original.result === 'finished'
      ) {
        return (
          <div className="min-w-full min-h-full flex items-center justify-center">
            Erfolgreich übertragen
          </div>
        );
      }
    }
    return null;
  };

  const downloadFile = async (path: string, row: any) => {
    try {
      const response = await axios
        .create({ baseURL: `${environment.restEndpoint}/files` })
        .get(path, {
          headers: {
            'apikey': environment.apiKey,
          }
        });
      const fmtDate = moment(row.original.finished_at).format(
        'DD_MM_YYYY_hh_mm_ss'
      );
      const fileName = `Export_Newsletter_${fmtDate}.csv`;
      download(response.data, fileName);
    } catch (error) {
      console.error(error);
    }
  };

  const columns = [
    {
      Header: 'Status',
      accessor: 'export_status',
      Cell: ({ row }: any) => <span>{constructStatus(row.values)}</span>,
    },
    {
      Header: 'Export Start',
      accessor: 'createdAt',
      Cell: ({ cell }: any) => (
        <span>
          {cell.value ? (
            <p>{moment(cell.value).format('DD.MM.YYYY HH:mm:ss')}</p>
          ) : null}
        </span>
      ),
    },
    {
      Header: 'Export Ende',
      accessor: 'finishedAt',
      Cell: ({ cell }: any) => (
        <span>
          {cell.value && cell.value !== '0001-01-01T00:00:00Z' ? (
            <p>{moment(cell.value).format('DD.MM.YYYY HH:mm:ss')}</p>
          ) : (
            '-'
          )}
        </span>
      ),
    },
    {
      Header: 'Dauer',
      accessor: 'export_duration',
      Cell: ({ row }: any) => (
        <span>
          {row.values && row.values.createdAt && row.values.finishedAt ? (
            <p>
              {`${calcDuration(row.values.createdAt, row.values.finishedAt)}`}
            </p>
          ) : (
            <p>-</p>
          )}
        </span>
      ),
    },
    {
      Header: 'Anzahl der exportierten Datensätze',
      accessor: 'numberOfSubscriber',
    },
    {
      Header: 'Export Art',
      accessor: 'exportType',
    },
    {
      Header: 'Cleverreach Gruppe',
      accessor: 'cleverreachGroupId',
      Cell: ({ cell }: any) => (
        <span>{cell.value ? <p>{mapGroupName(cell.value)}</p> : null}</span>
      ),
    },
    {
      Header: 'Ergebnis',
      accessor: 'result',
      Cell: ({ row, cell }: any) => (
        <div>
          <span>{newsletterTypeToContext(row, cell)}</span>
        </div>
      ),
    },
  ];

  return (
    <div className="p-6 max-w-full">
      <div className="mb-8 mt-2 items-center">
        <Headline title={t('newsletter-export.headline')}/>
      </div>
      {historyList.length > 0 && (
        <Table
          columns={columns}
          data={historyList}
          pagination
          initialPage={0}
          basic={true}
        />
      )}
    </div>
  );
};
export default NewsletterHistory;

import {DashboardFilterState} from "../store/slices/dashboard.slice";

export type DateFormatOptions = {
  year: 'numeric' | '2-digit';
  month: 'numeric' | '2-digit' | 'long' | 'short' | 'narrow';
  day: 'numeric' | '2-digit';
  hour?: 'numeric' | '2-digit';
  minute?: 'numeric' | '2-digit';
};

export const dateFormat: DateFormatOptions = { year: 'numeric', month: '2-digit', day: '2-digit' };
export const dateTimeFormat: DateFormatOptions = { ...dateFormat, hour: '2-digit', minute: '2-digit' };

export function stringToDate(dateString: string | undefined): Date | undefined {

    if (!dateString) {
      return undefined;
    }

    const [day, month, year] = dateString.split('.');

    if (day && month && year) {
      const formattedDate = new Date(`${year}-${month}-${day}`);

      if (!isNaN(formattedDate.getTime())) {
        return formattedDate;
      }
    }

    return undefined;
  }

export const formatDate = (options: DateFormatOptions, date?: Date) => {
  return date ? new Date(date).toLocaleString('de-DE', options) : '';
}

export const prepareInitialStartDate = (fromDashboard: boolean, dashboardFilterState: DashboardFilterState, initialDate: Date) => {
  return fromDashboard && dashboardFilterState.filter ? stringToDate(dashboardFilterState.filter.dateFrom) : initialDate;
}

export const prepareInitialEndDate = (fromDashboard: boolean, dashboardFilterState: DashboardFilterState, initialDate: Date) => {
  return fromDashboard && dashboardFilterState.filter ? stringToDate(dashboardFilterState.filter.dateTo) : initialDate;
}

import { useLocation } from 'react-router-dom';
import { Headline } from '@suzuki-frontend-framework-update/ui';
import './page-not-found.module.scss';

export function PageNotFound() {
  const location = useLocation();
  return (
    <div className="m-8">
      <Headline title={'404 - page not found'}></Headline>{' '}
      <span>Path : {location.pathname}</span>
    </div>
  );
}

export default PageNotFound;

import React, { useState } from 'react';
import { Filter } from '@sde/basic-ui-library';
import { useTranslation } from 'react-i18next';
import { NewsletterActivationStatus } from "../../models/newsletter";

interface NewsletterFilterProps {
  selectedOption: string;
  setSelectedOption: (option: string) => void;
}

const NewsletterFilter: React.FC<NewsletterFilterProps> = ({ selectedOption, setSelectedOption }) => {
  const { t } = useTranslation();
  const [showAllFiltersSelectedOnInit, setShowAllFiltersSelectedOnInit] = useState<boolean>(true);

  const handleOptionChange = (_id: string, selected: string[]) => {
    let optionToSet = NewsletterActivationStatus.UNDEFINED;

    if (selected.includes(NewsletterActivationStatus.ACTIVE) && !selected.includes(NewsletterActivationStatus.INACTIVE)) {
      optionToSet = NewsletterActivationStatus.ACTIVE;
    } else if (!selected.includes(NewsletterActivationStatus.ACTIVE) && selected.includes(NewsletterActivationStatus.INACTIVE)) {
      optionToSet = NewsletterActivationStatus.INACTIVE;
    }

    setSelectedOption(optionToSet);
    setShowAllFiltersSelectedOnInit(false);
  };

  const filterItems = [
    {
      id: NewsletterActivationStatus.ACTIVE as string,
      key: NewsletterActivationStatus.ACTIVE,
      value: NewsletterActivationStatus.ACTIVE,
      name: t('communication.newsletter.definition.filter.active'),
    },
    {
      id: NewsletterActivationStatus.INACTIVE as string,
      key: NewsletterActivationStatus.INACTIVE,
      value: NewsletterActivationStatus.INACTIVE,
      name: t('communication.newsletter.definition.filter.deactivated'),
    },
  ];

  return (
        <Filter
            key={'newsletterFilter'}
            id={'newsletterFilter'}
            name={t('communication.newsletter.definition.filter.label')}
            items={filterItems}
            onChange={handleOptionChange}
            selected={showAllFiltersSelectedOnInit ? filterItems.map(item => item.id) : [selectedOption]}
            width="w-[30rem]"
        />
  );
};

export default NewsletterFilter;

import { Button, ButtonStyle, Modal, Textarea, } from '@suzuki-frontend-framework-update/ui';
import { environment } from '../../../environments/environment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { sendApiRequest } from '../send-api-request/send-api-request';
import { toast } from 'react-toastify';
import { getHidingReasons } from "./hiding-reasons";
import { SelectInput } from "@sde/basic-ui-library";


export interface HideContactPopupProps {
  setModalState: (state: boolean) => void;
  modalState: boolean;
  contactId?: string | 'undefinded';
  companyId?: string | 'undefinded';
  refreshData?: () => void;
}

export const HideContactPopup = (props: HideContactPopupProps) => {
  const reasons = getHidingReasons()
  const { t } = useTranslation();
  const [reason, setReason] = useState(reasons.noLongerServicedByMe.value);
  const [otherReasonDescription, setOtherReasonDescription] = useState('');
  const customIdFailed = 'custom-id-Failed';
  const customIdSuccess = 'custom-id-success';

  const successToast = (successText: string) => {
    toast.success(successText, {
      position: 'top-center',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      toastId: customIdSuccess,
    });
  };

  const errorToast = (errorText: string) => {
    toast.error(errorText, {
      position: 'top-center',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      toastId: customIdFailed,
    });
  };

  const handleCancel = () => {
    setReason(reasons.noLongerServicedByMe.value);
    setOtherReasonDescription('');
    props.setModalState(false);
  };

  const handleSubmit = () => {
    if (reason === reasons.other.value && otherReasonDescription.trim() === "") {
      errorToast(t('hide-contact.other-reason-message-empty') )
    }
    const requestUrl = `${environment.restEndpoint}/contact/contact-service/hide`

    sendApiRequest(requestUrl, 'post', {
      contactId: props.contactId,
      companyId: props.companyId,
      hidingDate: new Date(),
      reason: reason,
      otherReasonMessage: otherReasonDescription,
    }).then((response) => {
      if (response.ok) {
        successToast(t('hide-contact.successfully-hidden'));
        handleCancel();
        if (props.refreshData) props.refreshData();
      } else {
        response.json().then((data) => {
          errorToast(t('error') + data.message);
        });
      }
    });
  };

  return (
    <div>
      <Modal
        key="hide-contact-modal"
        show={props.modalState}
        handleClose={() => handleCancel()}
        title={t('hide-contact.title')}
        size="80%"
      >
        <div className="flex flex-col gap-y-6 pt-8 p-5">
          <div className="flex flex-col gap-y-6 items-end pr-28">
            <SelectInput
              options={[
                {
                  key: 0,
                  name: reasons.noLongerServicedByMe.translation,
                  value: reasons.noLongerServicedByMe.value,
                },
                {
                  key: 1,
                  name: reasons.deceasedClosed.translation,
                  value: reasons.deceasedClosed.value,
                },
                {
                  key: 2,
                  name: reasons.fakeDataSet.translation,
                  value: reasons.fakeDataSet.value,
                },
                {
                  key: 3,
                  name: reasons.gdpr.translation,
                  value: reasons.gdpr.value,
                },
                {
                  key: 4,
                  name: reasons.noDataRelease.translation,
                  value: reasons.noDataRelease.value,
                },
                {
                  key: 5,
                  name: reasons.other.translation,
                  value: reasons.other.value,
                },
              ]}
              value={reason}
              width="25rem"
              onSelect={(value) => setReason(value)}
              label={{
                name: t('hide-contact.reason'),
                position: 'left',
              }}
            />
            {(reason === 'MISCELLANEOUS') && (
              <Textarea
                value={otherReasonDescription}
                onChange={(value) => setOtherReasonDescription(value)}
                label={{
                  name: t('hide-contact.note'),
                  position: 'left',
                }}
                rows={8}
                resizeDisabled
              />
            )}
          </div>
          <div className="flex flex-row gap-x-6 justify-end">
            <Button
              className="bg-white"
              buttonStyle={ButtonStyle.OUTLINE}
              onClick={handleCancel}
              px="5"
              py="3"
            >
              {t('hide-contact.cancel')}
            </Button>
            <Button onClick={handleSubmit} px="5" py="3">
              {t('hide-contact.submit')}
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default HideContactPopup;

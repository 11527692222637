export interface Sweepstake {
  id?: string,
  name?: string,
  code?: string,
  newsletterId?: string,
}

export interface SweepstakeSubscriptionContactView {
  id: string;
  contactId?: string;
  dealerCode?: string;
  createdAt?: Date;
  sweepstakeName?: string;
  contactFirstName?: string;
  contactLastName?: string;
  contactEmail?: string;
  subscriptionPurchaseIntent?: PurchaseIntent;
  subscriptionVehicleClass?: VehicleClass;
  subscriptionVehicleEngine?: VehicleEngine;
  handled?: boolean;
}

export enum PurchaseIntent {
  UNKNOWN,
  LEASING,
  FINANCING,
  CASH_PURCHASE,
  NOT_BUY,
}

enum VehicleClass {
  OTHER,
  SMALL_CAR,
  SUV,
  COMBI,
  LIMOUSINE,
  VAN,
  UNKNOWN_VC,
}

enum VehicleEngine {
  UNKNOWN_VE,
  PETROL,
  DIESEL,
  ELECTRO,
  HYBRID,
  OTHER_VE,
}

export interface SweepstakeSubscriptionRequest {
  filter?: SweepstakeSubscriptionRequestFilter;
  pageSize?: number;
  pageIndex?: number;
  sortField?: string;
  sortDir?: number;
}

export interface SweepstakeSubscriptionRequestFilter {
  dateFrom?: Date;
  dateTo?: Date;
  search?: string;
  dealers?: string[];
}

export const SweepstakeErrorMessageByCode = new Map<string, string>([
  ['sweepstake_not_found', 'communication.sweepstake.overview.popup.error_not_found'],
  ['sweepstake_code_exists', 'communication.sweepstake.overview.popup.error_code_exists'],
  ['sweepstake_invalid_id', 'communication.sweepstake.overview.popup.error_invalid_id'],
  ['sweepstake_invalid_newsletter_id', 'communication.sweepstake.overview.popup.error_invalid_newsletter'],
  ['sweepstake_already_in_use', 'communication.sweepstake.overview.popup.error_already_in_use'],
  ['internal_error', 'error_popup.unknown'],
]);

import React from 'react';

export interface SideNavigationItem {
  title: string;
  id: string;
}

export type SideNavigationProps = {
  values: Array<SideNavigationItem>;
  topOffset?: string;
  children?: React.ReactNode;
};
export const scrollTo = (elementId: string) => {
  const element = document.getElementById(elementId);
  element?.scrollIntoView({
    behavior: 'smooth',
    block: 'center',
    inline: 'center',
  });
};
export const SideNavigation = (props: SideNavigationProps) => {
  return (
    <div className="w-full flex flex-row">
      <div className="min-w-[340px] w-80 px-8">
        <div
          className="flex flex-col pr-5 w-80 sticky left-0"
          style={{ top: props.topOffset ? props.topOffset : '0' }}
        >
          {props.values.map((item) => (
            <span
              className="w-full pl-2 py-2 hover:bg-blue-gray cursor-pointer"
              onClick={() => scrollTo(item.id)}
              key={item.id}
            >
              {item.title}
            </span>
          ))}
        </div>
      </div>
      <div className="grow">{props.children}</div>
    </div>
  );
};

export default SideNavigation;

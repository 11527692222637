import React from 'react';
import { Button, ButtonStyle, Modal } from '@sde/basic-ui-library';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { DataUpdateError, Newsletter } from "../../models/newsletter";
import { deactivateNewsletter } from "../../store/slices/newsletter.slice";

export interface DeactivateNewsletterPopupProps {
  isOpen: boolean,
  newsletter?: Newsletter,
  onClose: () => void,
  onDeactivate: () => void,
}

export default function DeactivateNewsletterPopup(props: Readonly<DeactivateNewsletterPopupProps>) {
  const { t } = useTranslation();

  const successToast = (successText: string) => {
    toast.success(successText, {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      toastId: 'custom-id-success',
    });
  };
  const errorToast = (errorText: string) => {
    toast.error(errorText, {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      toastId: 'excel-import-error',
    });
  };

  const handleDeactivate = async () => {
    if (!props.newsletter?.objectId) return;

    try {
      const response = await deactivateNewsletter({ id: props.newsletter.objectId });
      if (response.success) {
        successToast(t('communication.newsletter.definition.popup.success_deactivate'));
        props.onDeactivate();
        props.onClose();
      } else {
        if (response.error === DataUpdateError.NOT_FOUND) {
          errorToast(t('communication.newsletter.definition.popup.error_not_found'));
        } else {
          errorToast(t('error')+ response.error);
        }
      }
    } catch (e) {
      errorToast(t('error')+ e);
    }
  };

  return (
        <Modal
            title={t('communication.newsletter.definition.modal.title_deactivate')}
            size={'w-[640px]'}
            show={props.isOpen}
            handleClose={() => props.onClose()}
        >
            <div className="px-20 pb-3">
                <div className="mb-10">
                    <span className="block font-bold whitespace-nowrap">{props.newsletter?.name}</span>
                    <span className="block">{t('communication.newsletter.definition.modal.text_deactivate')}</span>
                </div>
                <div className="flex justify-end gap-2">
                    <Button
                        className="bg-white"
                        buttonStyle={ButtonStyle.OUTLINE}
                        onClick={() => props.onClose()}
                    >
                        {t('communication.newsletter.definition.modal.button_cancel')}
                    </Button>
                    <Button
                        px="5"
                        py="3"
                        onClick={() => handleDeactivate()}
                    >
                        {t('communication.newsletter.definition.modal.button_deactivate')}
                    </Button>
                </div>
            </div>
        </Modal>
  );
}

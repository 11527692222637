import { Button, CheckboxStatus, Headline, Icon, Table, Tooltip, } from '@suzuki-frontend-framework-update/ui';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Column, Row } from 'react-table';
import './vehicles.module.scss';
import { useApiGetData } from '../../components/useApiGetData/useApiGetData';
import { environment } from '../../../environments/environment';
import { ContactData, VehicleRequestBody, VehiclesData, } from '../../models/vehicle';
import { vehicleFilterActions, vehicleFilterSelector, } from '../../store/slices/vehicle-filter.slice';
import { AppDispatch } from '../../store/setup';
import { DataFetchProps } from '../../models/others';
import HideVehiclePopup from "../../components/hide-vehicle-popup/hide-vehicle-popup";
import { toast, ToastOptions } from "react-toastify";
import { Session } from "@ory/client";
import { getHidingReasonOfVehicle } from "../../components/hide-vehicle-popup/hiding-deactivating-reasons";
import UnhideVehicleButton from "../../components/unhide-vehicle-button/unhide-vehicle-button";
import { selectAllUserManagement } from "../../store/slices/user-management.slice";
import { DeactivateVehiclePopup } from "../../components/hide-vehicle-popup/deactivate-vehicle-popup";
import ReportFilters from "../../components/report-filter/report-filter";
import { dashboardFilterSelector } from "../../store/slices/dashboard.slice";
import { isAdmin, isAreaAdmin, isAutoDealerOrAdmin, isMotoDealer } from "../../helpers/session-utils";
import { sendApiRequest } from "../../components/send-api-request/send-api-request";
import download from "downloadjs";
import { base64StringToBlob } from "blob-util";
import { DatePickerRange, FilterItem, Spinner } from "@sde/basic-ui-library";
import { OTHER_MODEL_ID, VehicleModel, VehicleModelType } from "../../models/vehicle-model";
import { ChooseBetweenPopup } from "../../components/choose-between-popup/choose-between-popup";

export function Vehicles() {
  const pageSize = 25;
  const {t} = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const entities = useSelector(selectAllUserManagement);
  const [session, setSession] = useState({} as Session);
  const [hideVehicleModalState, setHideVehicleModalState] = useState(false);
  const [deactivateVehicleModalState, setDeactivateVehicleModalState] = useState(false);
  const [vehicleId, setVehicleId] = useState("");
  const [isCsvLoading, setIsCsvLoading] = useState<boolean>();
  const vehicleFilterState = useSelector(vehicleFilterSelector);
  const dashboardFilterState = useSelector(dashboardFilterSelector);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [selectedRowIds, setSelectedRowIds] = useState<Record<string, boolean>>({});
  const [allSelectedStatus, setAllSelectedStatus] = useState<CheckboxStatus>({
    checked: false,
    indeterminate: false,
  });
  const [vehiclesIds, setVehiclesIds] = useState<string[]>([]);
  const [vehicleModelsData, setVehicleModelsData] = useState<VehicleModel[]>();
  const [modelsFilterItems, setModelsFilterItems] = useState<FilterItem[]>([]);
  const [confirmationModalState, setConfirmationModalState] = useState<boolean>(false);

  const defaultToastOptions: ToastOptions = {
    position: 'top-center',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  }

  const dispatch: AppDispatch = useDispatch();
  if (
    vehicleFilterState.key === '' ||
    location.key !== vehicleFilterState.key
  ) {
    dispatch(vehicleFilterActions.init(location.key));
  }

  const [vehicleRequestBody, setVehicleRequestBody] =
    useState<VehicleRequestBody>({
      excludeSubDocuments: true,
      detail: 1,
      pageSize: pageSize,
      pageIndex: vehicleFilterState.vehicleFilter.pageIndex,
      filter: vehicleFilterState.vehicleFilter.filter,
    });

  const vehiclesData = useApiGetData<VehiclesData[]>(
    `${environment.restEndpoint}/vehicle/vehicle-service/vehicles`,
    'post',
    'vehiclesData',
    vehicleRequestBody
  );

  const vehiclesCountData = useApiGetData<number>(
    `${environment.restEndpoint}/vehicle/vehicle-service/vehicles/GetVehiclesCount`,
    'post',
    'count',
    vehicleRequestBody
  );

  useEffect(() => {
    if (!isMotoDealer(session)) {
      sendApiRequest(
        `${environment.restEndpoint}/contact/newsletter/model?scope=${isAdmin(session) ? VehicleModelType.UNDEFINED : VehicleModelType.AUTO}&withLegacy=true`,
        'get'
      ).then(response => {
        if (response.ok) {
          response.json().then(data => {
            const vehicleModelsListData = data.data as VehicleModel[];
            setVehicleModelsData(vehicleModelsListData);
          })
        }
      })
    }
  }, [session]);

  useEffect(() => {
    if (vehicleModelsData && vehicleModelsData.length > 0) {
      const sortedModels = vehicleModelsData.sort((a, b) => {
        if (a.type === VehicleModelType.AUTO && b.type !== VehicleModelType.AUTO) {
          return -1;
        } else if (a.type !== VehicleModelType.AUTO && b.type === VehicleModelType.AUTO) {
          return 1;
        } else {
          return a.model.localeCompare(b.model);
        }
      }).map(({model}) => ({
        id: model,
        name: model,
      }))
      sortedModels.push(
        {
          id: OTHER_MODEL_ID,
          name: t('vehicles.filter.model_other_option'),
        }
      )
      setModelsFilterItems(sortedModels);
    }
  }, [vehicleModelsData]);

  useEffect(() => {
    if (!modelsFilterItems) return;
    dispatch(
        vehicleFilterActions.updateVehicleFilter({
          ...vehicleFilterState.vehicleFilter.filter,
          vehicleModels: modelsFilterItems.map(item => item.id),
        })
    );
  }, [modelsFilterItems]);

  useEffect(() => {
    let ids: string[] = [];
    if (vehiclesData?.data) {
      ids = vehiclesData.data.map<string>((v => v.vehicle.objectId as string))
    }
    setVehiclesIds(ids)
  }, [vehiclesData.loading])

  interface StateData {
    vehicleHidingReason: string,
  }

  useEffect(() => {
    const lastEntity = entities[entities.length - 1];
    if (lastEntity && lastEntity.currentUser) {
      setSession(lastEntity.currentUser);
    }
  }, [entities]);

  useEffect(() => {
    const {state} = location
    const data = state as StateData
    if (data && data.vehicleHidingReason) {
      const reason = data.vehicleHidingReason
      const message = `${t('contacts.hidden-contact.hidden-for-a-reason')} ${reason}. ${t('contacts.hidden-contact.questions')}`;
      toast.error(message, {
        ...defaultToastOptions,
        toastId: 'toast-contact-hidden-for-reason',
      });
    }
  }, [vehiclesData.loading])

  const handleVehicleModelsSelection = (_id: string, selected: string[], changedId: string) => {
    let selectedModelNames: string[];
    const allId = 'all';
    const allModelsSelected = modelsFilterItems.map(item => item.id).filter(id => id !== allId);

    if (changedId === allId) {
      selectedModelNames = selected.includes(allId) ? allModelsSelected : [];
    } else {
      selectedModelNames = selected.filter(model => model !== allId);
    }

    dispatch(
      vehicleFilterActions.updateVehicleFilter({
        ...vehicleFilterState.vehicleFilter.filter,
        vehicleModels: selectedModelNames,
      })
    );
  };

  const handleFilterRegistrationDateFrom = (from: Date) => {
    dispatch(vehicleFilterActions.updateVehicleFilter({
      ...vehicleFilterState.vehicleFilter.filter,
      registrationDateFrom: from,
    }));
  };

  const handleFilterRegistrationDateTo = (to: Date) => {
    dispatch(vehicleFilterActions.updateVehicleFilter({
      ...vehicleFilterState.vehicleFilter.filter,
      registrationDateTo: to,
    }));
  };

  const handleVehicleFilterSearch = (value: string) => {
    dispatch(
      vehicleFilterActions.updateVehicleFilter({
        ...vehicleFilterState.vehicleFilter.filter,
        search: value,
      })
    );
  };

  const handleShowHiddenVehicleFilterSelect = (id: string, selected: Array<string>) => {
    dispatch(
      vehicleFilterActions.updateVehicleFilter({
        ...vehicleFilterState.vehicleFilter.filter,
        showHiddenVehicles: selected.includes('showHiddenVehicles'),
        showDeactivatedVehicles: false
      })
    );
  };

  const handleShowDeactivatedVehiclesFilterSelect = (id: string, selected: Array<string>) => {
    dispatch(
      vehicleFilterActions.updateVehicleFilter({
        ...vehicleFilterState.vehicleFilter.filter,
        showDeactivatedVehicles: selected.includes('showDeactivatedVehicles'),
        showHiddenVehicles: false
      })
    );
  };

  const handleCsvExport = () => {
    const vehicleCount = vehiclesCountData?.data
    if (!vehicleCount) return;
    const safeExportVolume = 5000
    return vehicleCount > safeExportVolume ? setConfirmationModalState(true) : exportVehiclesToCsv();
  }

  const canHidingButtonsBeVisible = (session: Session): boolean => {
    return !session.identity?.traits?.isAreaAdmin && !vehicleFilterState.vehicleFilter.filter.showHiddenVehicles;
  }

  const canCheckboxesAndUnhidingButtonBeVisible = (session: Session): boolean => {
    if (!session.identity?.traits?.isAreaAdmin && vehicleFilterState.vehicleFilter.filter.showHiddenVehicles) {
      return true
    } else {
      return false
    }
  }

  const columns = [
    vehicleFilterState.vehicleFilter.filter.showDeactivatedVehicles || vehicleFilterState.vehicleFilter.filter.showHiddenVehicles ? ({
      accessor: 'vehicle-visibility-icon',
      disableSortBy: true,
      Cell: ({row}: any) => {
        const deactivationReason = row.original.vehicle?.deactivation?.deactivationReason
        const translatedHidingReason = getHidingReasonOfVehicle(session, row.original.vehicle)
        return deactivationReason ? (
          <div className="flex flex-col">
            <Tooltip content={t('deactivate-vehicle.options.' + deactivationReason)}>
              <Icon iconName={'cancel'} className="text-blue-gray"/>
            </Tooltip>
          </div>
        ) : translatedHidingReason ? (
          <div className="flex flex-col">
            <Tooltip content={t('hide-vehicle.options.' + translatedHidingReason)}>
              <Icon iconName={'visibility_off'} className="text-blue-gray"/>
            </Tooltip>
          </div>
        ) : <div/>;
      },
    }) : null,

    {
      Header: t('vehicles.model'),
      accessor: 'vehicle.model',
      Cell: ({row}: any) => {
        return (
          <div>
            <span>
              {row.original.vehicle?.model ? row.original.vehicle?.model : '-'}
            </span>
          </div>
        );
      },
    },
    {
      Header: t('vehicles.modelcode'),
      accessor: 'vehicle.modelcode',
      Cell: ({row}: any) => {
        return (
          <div>
            <span>
              {row.original.vehicle?.modelcode
                ? row.original.vehicle?.modelcode
                : '-'}
            </span>
          </div>
        );
      },
    },
    {
      Header: t('vehicles.vin'),
      accessor: 'vehicle.vin',
      Cell: ({row}: any) => {
        return (
          <div>
            <span>
              {row.original.vehicle?.vin ? row.original.vehicle?.vin : '-'}
            </span>
          </div>
        );
      },
    },
    {
      Header: t('vehicles.first_registration_date'),
      accessor: 'vehicle.firstRegistraion',
      disableSortBy: true,
      Cell: ({row}: any) => {
        return (
          <div>
            <span>
              {row.original.vehicle.firstRegistraion
                ? new Date(
                      row.original.vehicle.firstRegistraion
                ).toLocaleDateString('de-DE', {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                })
                : '-'}
            </span>
          </div>
        );
      },
    },

    {
      Header: t('vehicles.owner'),
      accessor: 'vehicle.vehicleInformation.owner',
      disableSortBy: true,
      Cell: ({row}: any) => {
        const owner = row.original.owner as ContactData;
        return owner ? (
          <div className="flex flex-col">
            <span className="underline underline-offset-2">
              {[
                [owner.firstname, owner.name].join(' '),
                owner.street,
                [owner.zip, owner.city].join(' '),
              ].join(', ')}
            </span>
            <span className="text-blue-gray">{owner.customerNumber}</span>
          </div>
        ) : (
          <div>-</div>
        );
      },
    },
    canHidingButtonsBeVisible(session) ? {
      accessor: 'actions',
      disableSortBy: true,
      Cell: ({row}: any) => {
        return (
          <div>
            {!row.original?.vehicle?.deactivation?.deactivationValid ?
              <div className="flex gap-4 justify-end">
                <Tooltip content={t('vehicles.hide')}>
                  <button onClick={(e) => {
                    setHideVehicleModalState(true);
                    setVehicleId(row.original.vehicle.objectId)
                    e.stopPropagation();
                  }
                  }>
                    <Icon iconName={'visibility_off'} className="text-black"/>
                  </button>
                </Tooltip>
                <Tooltip content={t('vehicles.deactivate')}>
                  <button onClick={(e) => {
                    setDeactivateVehicleModalState(true);
                    setVehicleId(row.original.vehicle.objectId)
                    e.stopPropagation();
                  }
                  }>
                    <Icon iconName={'cancel'} className="text-black"/>
                  </button>
                </Tooltip>
              </div>
              : <div/>}
          </div>
        );
      },
    } : null,
  ].filter(Boolean) as Column<any>[];

  const vehiclesFilter = {
    id: 'vehiclesFilters',
    key: 'vehiclesFilters',
    placeholder: t('vehicles.filter.search'),
    onSearchUpdate: handleVehicleFilterSearch,
    searchDelay: 500,
    initialSearchValue: vehicleFilterState.vehicleFilter.filter.search,
    filters: [
      {
        id: 'deactivatedVehicles',
        name: t('vehicles.filter.deactivated-vehicles.title'),
        items: [
          {
            id: 'showDeactivatedVehicles',
            name: t('vehicles.filter.deactivated-vehicles.name'),
          }
        ],
        onChange: handleShowDeactivatedVehiclesFilterSelect,
        selected: vehicleFilterState.vehicleFilter.filter.showDeactivatedVehicles ? ['showDeactivatedVehicles'] : []
      },
      {
        id: 'hiddenVehicles',
        name: t('contact-details.hidden-vehicles.title'),
        items: [
          {
            id: 'showHiddenVehicles',
            name: t('contact-details.hidden-vehicles.show-vehicles'),
          }
        ],
        onChange: handleShowHiddenVehicleFilterSelect,
        selected: vehicleFilterState.vehicleFilter.filter.showHiddenVehicles ? ['showHiddenVehicles'] : []
      },
      ...(!isMotoDealer(session) ? [{
        id: 'vehicleModels',
        name: t('vehicles.filter.models'),
        items: modelsFilterItems,
        onChange: handleVehicleModelsSelection,
        selected: vehicleFilterState.vehicleFilter.filter.vehicleModels
      }] : [])
    ]
  };

  const handleRowSelect = (row: Row<VehiclesData>) => {
    let result = selectedIds;
    const id = row.original.vehicle.objectId;
    console.log(id);
    if (id) {
      if (result.includes(id)) {
        result = result.filter((v) => v !== id);
      } else {
        result.push(id);
      }
      setSelectedRowIds({...selectedRowIds, [row.id]: result.includes(id)});
      setSelectedIds(result);
      const checked = vehiclesIds.every((id) => result.includes(id));
      const indeterminate =
        !checked && vehiclesIds.some((id) => result.includes(id));
      setAllSelectedStatus({
        checked: checked,
        indeterminate: indeterminate,
      });
    }
  }

  const resetSelections = () => {
    setSelectedIds([]);
    setSelectedRowIds({});
    setAllSelectedStatus({checked: false, indeterminate: false});
  };

  const updateSelectedRowIdsAndSelectedIds = (selected: string[]) => {
    if (vehiclesData.data) {
      let resultSelectedRowIds: Record<string, boolean> = {};
      let resultSelectedIds: string[] = [];
      vehiclesData.data.forEach((v, index) => {
        if (v.vehicle.objectId) {
          resultSelectedRowIds = {
            ...resultSelectedRowIds,
            [index.toString()]: selected.includes(v.vehicle.objectId),
          };
          resultSelectedIds = resultSelectedIds.concat(v.vehicle.objectId);
        }
      });
      setSelectedRowIds(resultSelectedRowIds);
      setSelectedIds(resultSelectedIds);
    }
  };

  const handleAllRowsSelect = () => {
    if (allSelectedStatus.checked) {
      setAllSelectedStatus({checked: false, indeterminate: false})
      resetSelections();
    } else {
      updateSelectedRowIdsAndSelectedIds(vehiclesIds);
      setAllSelectedStatus({checked: true, indeterminate: false});
    }
  }

  const selectionHandler = {
    handleRowSelect: handleRowSelect,
    handleAllRowsSelect: handleAllRowsSelect,
    customSelectedRowIds: selectedRowIds,
    allSelectedStatus: allSelectedStatus,
  };

  const handleRowClick = (row: Row<VehiclesData>) => {
    dispatch(vehicleFilterActions.updateVehicleCurrentRow(row.id));
    navigate(`/vehicles/view/${row.original.vehicle.objectId}`);
  };

  const handlePreviousRow = useCallback(
    async ({itemsRef}: any) => {
      if (vehiclesData.data && vehicleFilterState.currentRow) {
        itemsRef.current
          .get(vehicleFilterState.currentRow)
          ?.scrollIntoView({behavior: 'smooth', block: 'center'});
        dispatch(vehicleFilterActions.updateVehicleCurrentRow(undefined));
      }
    },
    [vehiclesData.data, vehiclesData.loading]
  );

  const sortFieldMapper = (uifield: string): string => {
    switch (uifield) {
      case 'vehicle.vin':
        return "vin"
      case 'vehicle.modelcode':
        return "modelcode"
      default:
        return "model"
    }
  };

  const fetchVehicleData = useCallback(
    async ({pageSize, pageIndex, pageSortBy}: DataFetchProps) => {
      dispatch(vehicleFilterActions.updateVehiclePageIndex(pageIndex));
      let sortByDir = 1;
      let sortByField = '';
      if (pageSortBy && pageSortBy.length > 0) {
        const sortParams = pageSortBy[0];
        sortByDir = sortParams.desc ? -1 : 1;
        sortByField = sortFieldMapper(sortParams.id);
      }
      setVehicleRequestBody({
        excludeSubDocuments: true,
        detail: 1,
        pageSize: pageSize,
        pageIndex: pageIndex,
        filter: vehicleFilterState.vehicleFilter.filter,
        sortField: sortByField,
        sortDir: sortByDir,
      });
    },
    [vehicleFilterState.vehicleFilter.filter]
  );

  useEffect(() => {
    dispatch(
      vehicleFilterActions.updateVehicleFilter({
        ...vehicleFilterState.vehicleFilter.filter,
        dealercodes: dashboardFilterState.dealerFilter.dealers,
      }),
    );
  }, [dashboardFilterState.dealerFilter.dealers]);

  const exportVehiclesToCsv = () => {
    setIsCsvLoading(true);
    sendApiRequest(
      `${environment.restEndpoint}/vehicle/vehicle-service/csv/export`,
        'post',
        vehicleRequestBody
    ).then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(`${response.status} ${response.statusText}`)
      }
    }).then(data => {
      const mimeType = 'text/csv;charset=utf-8';
      const file = base64StringToBlob(data.content, mimeType);
      download(file, 'Export_Fahrzeuge.csv', mimeType);
    }).catch(error => {
      toast.error(t('vehicles.export_csv.toast.unexpected_error').toString(), {
        ...defaultToastOptions,
        toastId: 'toast-csv-export-error',
      });
      console.error(error);
    }).finally(() => setIsCsvLoading(false));
  };

  return (
    <div className="grow bg-gray w-full h-full p-6">
      <div className="mb-8 mt-2 flex flex-row justify-between items-center">
        <Headline title={t('vehicles.title')}/>
        <div className="flex flex-row gap-x-4 justify-end">
          {isAutoDealerOrAdmin(session) && !isAreaAdmin(session) &&
            <>
              {isCsvLoading || vehiclesCountData.loading ?
                <Spinner/>
                :
                <Button onClick={handleCsvExport} py="5">
                  {t('vehicles.export_csv.button')}
                </Button>
              }
            </>
          }
          {isAdmin(session) &&
              <Tooltip content={t('contacts.create')}>
                <Button
                    onClick={() => navigate('/vehicles/add')}
                    className="w-fit h-fit"
                    py="1"
                    px="1"
                >
                  <Icon iconName="add" className="text-2xl w-7"/>
                </Button>
              </Tooltip>
          }
        </div>
      </div>

      <div className="ml-0 mb-2 -mt-8">
        {
          !isMotoDealer(session) ?
              <div className="flex flex-row">
                <div className='py-8'>
                  <ReportFilters />
                </div>
                <DatePickerRange
                    id="registrationDate"
                    label={t('vehicles.filter.registration_date.title')}
                    from={vehicleFilterState.vehicleFilter.filter.registrationDateFrom}
                    to={vehicleFilterState.vehicleFilter.filter.registrationDateTo}
                    changeFrom={handleFilterRegistrationDateFrom}
                    changeTo={handleFilterRegistrationDateTo}
                    customWidth='w-36'
                />
              </div>
              :
              <ReportFilters  />
        }

        <Table
          key={'vehicle-table'}
          filter={vehiclesFilter}
          columns={columns}
          data={vehiclesData.data ? vehiclesData.data : []}
          initPageSize={pageSize}
          loading={vehiclesData.loading}
          rowCount={vehiclesCountData.data}
          countLabel={
            vehiclesData.data && vehiclesData.data.length === 1
              ? t('common.list.count.entry')
              : t('common.list.count.entries')
          }
          fetchData={fetchVehicleData}
          initialPage={vehicleFilterState.vehicleFilter.pageIndex}
          pagination
          handleRowClick={handleRowClick}
          resetOnChange={vehicleFilterState.vehicleFilter.filter}
          handleScrollToRow={handlePreviousRow}
          customSelectionHendler={selectionHandler}
          basic={!canCheckboxesAndUnhidingButtonBeVisible(session)}
          customElement={
            <div>
              {canCheckboxesAndUnhidingButtonBeVisible(session) ? (
                <UnhideVehicleButton
                  selectedCount={selectedIds.length}
                  selectedVehicleIds={selectedIds}
                  handleResetSelectionsAfterUnhide={resetSelections}
                  refreshData={vehiclesData.refreshData}
                />
              ) : null}
            </div>
          }
        />

        <HideVehiclePopup
          setModalState={setHideVehicleModalState}
          modalState={hideVehicleModalState}
          vehicleId={vehicleId}
          refreshData={vehiclesData.refreshData}
        />
        <DeactivateVehiclePopup
          setModalState={setDeactivateVehicleModalState}
          modalState={deactivateVehicleModalState}
          vehicleId={vehicleId}
          refreshData={vehiclesData.refreshData}
        />
        <ChooseBetweenPopup
          modalTitle={t('vehicles.export_csv.popup.title')}
          description={t('vehicles.export_csv.popup.description')}
          firstChooseText={t('vehicles.export_csv.popup.no')}
          secondChooseText={t('vehicles.export_csv.popup.yes')}
          modalState={confirmationModalState}
          setModalState={setConfirmationModalState}
          doOnFirstChoose={() => {setConfirmationModalState(false);}}
          doOnSecondChoose={() => {{
            setConfirmationModalState(false);
            exportVehiclesToCsv();
          }}}
          doOnClose={() => {setConfirmationModalState(false);}}
        />
      </div>
    </div>
  );
}

export default Vehicles;

import './contact-create.module.scss';
import { useEffect, useState } from 'react';
import {
  Headline,
  MultiselectOptionsProps,
} from '@suzuki-frontend-framework-update/ui';
import {
  CustomerType,
  Contact,
  ContactEmail,
  ContactFax,
  ContactPhone,
} from '../../models/contact';
import { environment } from '../../../environments/environment';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { sendApiRequest } from '../../components/send-api-request/send-api-request';
import ContactForm, { ContactValue } from '../contact-form/contact-form';
import { toast } from 'react-toastify';
import { validateEmailOrEmpty } from '../../helpers/email-validation-helper';
import { useApiGetData } from '../../components/useApiGetData/useApiGetData';
import { Dealer } from '../../models/dealer';
import { useSelector } from 'react-redux';
import { selectAllUserManagement } from '../../store/slices/user-management.slice';
import { Session } from '@ory/client';
import { isValidStreet } from '../../helpers/contact-helper';

export function ContactCreate() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const entities = useSelector(selectAllUserManagement);
  const [session, setSession] = useState({} as Session);

  useEffect(() => {
    const lastEntity = entities[entities.length - 1];
    if (lastEntity && lastEntity.currentUser) {
      setSession(lastEntity.currentUser);
    }
  }, [entities]);

  const customIdSuccess = 'custom-id-success';
  const customIdFailed = 'custom-id-Failed';
  const successToast = (successText: string) => {
    toast.success(successText, {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      toastId: customIdSuccess,
    });
  };

  const errorToast = (errorText: string) => {
    toast.error(errorText, {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      toastId: customIdFailed,
    });
  };

  const [formValues, setFormValues] = useState<ContactValue>({
    gender: 'Herr',
    email: [],
    phone: [],
    fax: [],
  });

  const [dealerOptions, setDealerOptions] = useState<MultiselectOptionsProps[]>(
    []
  );

  const [owner, setOwner] = useState<string[]>([]);

  const dealerData = useApiGetData<Dealer[]>(
    `${environment.restEndpoint}/dealer/dealer-service/dealers`,
    'post',
    'dealers'
  );

  useEffect(() => {
    if (dealerData.status !== 200) {
      return;
    }

    if (dealerOptions.length <= 0) {
      const toSelectOptions = (
        dealers: Dealer[] | undefined
      ): MultiselectOptionsProps[] => {
        if (dealers && dealers.length > 0) {
          return dealers.map((d) => {
            return {
              key: d.objectID,
              name: `${d.name} ${d.nameAppendum} (${d.dealercode})`,
              value: d.dealercode,
            } as MultiselectOptionsProps;
          });
        } else {
          return [];
        }
      };
      const options = toSelectOptions(dealerData.data);
      setDealerOptions(options);
      if (!session?.identity?.traits?.isAdmin) {
        setOwner(
          session.identity?.traits?.dealers?.map((d: any) => d.dealercode)
        );
      }
    }
  }, [dealerData]);

  const handleSubmit = (formValues: ContactValue) => {
    const emails: ContactEmail[] = [];
    const phones: ContactPhone[] = [];
    const faxes: ContactFax[] = [];

    const continueFunction = () => {
      const dealerCode =
        session.identity?.traits?.isAdmin && formValues?.owner
          ? formValues.owner[0].value
          : owner[0];
      sendApiRequest(
        session.identity?.traits?.isAdmin
          ? `${environment.restEndpoint}/contact/contact-service/contact/CreateContactWithRegistration`
          : `${environment.restEndpoint}/contact/contact-service/contact/CreateContact`,
        'POST',
        {
          distributor: '', //TODO: Get from logged user
          dealerCode: dealerCode,
          vehicleVin: formValues.vin ? formValues.vin : '',
          contact: {
            customerstate:
              formValues.vin &&
              formValues.vin?.length > 0 &&
              session.identity?.traits?.isAdmin
                ? CustomerType.CUSTOMER
                : CustomerType.PROSPECT,

            title: formValues.title,
            salutation: formValues.gender,
            firstname: formValues.firstname,
            name: formValues.surname,
            householdData: {
              birthdate: formValues.birthday,
              personsathome:
                formValues.personsInHousehold === '' ||
                !formValues.personsInHousehold
                  ? undefined
                  : parseInt(formValues.personsInHousehold),
              jobId: formValues.job,
              familystatusId: formValues.maritalStatus,
              sport: formValues.sports,
              leisureaction: formValues.activities,
            },
            address: {
              addressline1: formValues.address,
              street: formValues.street,
              zip: formValues.postcode,
              city: formValues.city,
              countrycode: formValues.countrycode,
            },
            email: emails,
            phone: phones,
            fax: faxes,
          } as Contact,
        }
      )
        .then((response) => response.json())
        .then((data) => {
          successToast(t('contact-form.success'));
          navigate(`/contacts/view/${data.ID}`);
        })
        .catch((e) => errorToast(t('contact-form.error') + e));
    };

    console.table(formValues);
    let mailValid = true;
    formValues.email.forEach((email) => {
      if (!validateEmailOrEmpty(email.inputValue.toLowerCase())) {
        mailValid = false;
      }
      emails.push({
        email: email.inputValue,
        default: email.default,
        type: email.selectValue,
      });
    });
    formValues.phone.forEach((phone) => {
      phones.push({
        phoneNumber: phone.inputValue,
        default: phone.default,
        type: phone.selectValue,
      });
    });
    formValues.fax.forEach((fax) => {
      faxes.push({
        faxNumber: fax.inputValue,
        default: fax.default,
        type: fax.selectValue,
      });
    });
    if (!mailValid) {
      errorToast(t('contact-form.errors.wrong-email'));
      return;
    }
    if (!formValues.firstname || !formValues.surname) {
      errorToast(t('contact-form.errors.name-required'));
      return;
    } else if (formValues.firstname || formValues.surname) {
      if (!formValues.firstname.trim() || !formValues.surname.trim()) {
        errorToast(t('contact-form.errors.name-required'));
        return;
      }
    }
    console.log(
      (!formValues.owner || formValues.owner.length === 0) &&
        (!owner || owner.length === 0)
    );
    if (
      (!formValues.owner || formValues.owner.length === 0) &&
      (!owner || owner.length === 0)
    ) {
      errorToast(t('contact-form.errors.dealer-required'));

      return;
    }

    const isPostalAddressComplete =
    formValues.street?.trim() &&
    formValues.postcode?.trim() &&
    formValues.city?.trim();

    const isEmailAddressPresent = formValues.email.some(email => email.inputValue?.trim());
    
    if (!isPostalAddressComplete && !isEmailAddressPresent) {
      errorToast(t('contact-form.errors.email-or-adress-required'));
      return;
    }
  
    if (isPostalAddressComplete) {
      if (!formValues.street || !isValidStreet(formValues.street.trim())) {
        errorToast(t('contact-form.errors.invalid-street'));
        return;
      }
      if (!formValues.postcode?.trim() || !formValues.city?.trim()) {
        errorToast(t('contact-form.errors.email-or-adress-required'));
        return;
      }
    }
    if (!formValues.phone[0]?.inputValue) {
      errorToast(t('contact-form.errors.phone-required'));
      return;
    }
    if (
      session.identity?.traits?.isAdmin &&
      formValues.vin &&
      formValues.vin.length != 0
    ) {
      const vehicle = sendApiRequest(
        `${environment.restEndpoint}/vehicle/vehicle-service/vehicle/vin?VIN=${formValues.vin}`,
        'GET'
      );
      vehicle
        .then((data) => {
          if (data.status === 200) {
            continueFunction();
          } else if (data.status >= 400) {
            errorToast(t('contact-form.errors.error-vin'));
            console.error('User entered wrong vin');
            return;
          }
        })
        .catch((error) => {
          errorToast(t('contact-form.errors.error-vin'));
          console.error('User entered wrong vin');
          return;
        });
    } else {
      continueFunction();
    }
  };

  const handleCancel = () => {
    navigate(`/contacts/list/people`);
  };

  return (
    <div className="grow bg-gray">
      <Headline className="p-8 pb-0" title={t('contact-create')} />
      <ContactForm
        handleCancel={handleCancel}
        handleSubmit={handleSubmit}
        formValues={formValues}
        dealerOptions={dealerOptions}
      />
    </div>
  );
}

export default ContactCreate;

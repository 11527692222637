import { useApiGetData } from '../../components/useApiGetData/useApiGetData';
import { environment } from '../../../environments/environment';
import { Headline, Table } from '@suzuki-frontend-framework-update/ui';
import { useTranslation } from 'react-i18next';
import React, { useEffect } from 'react';
import { Row } from 'react-table';
import defaultSort from './newsletter/utils/defaultSorter';
import { Subscriber } from '../../models/newsletter';
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectAllUserManagement } from "../../store/slices/user-management.slice";
import { isAdmin } from "../../helpers/session-utils";

export const NewsletterVerification = () => {
  const navigate = useNavigate()
  const entities = useSelector(selectAllUserManagement);
  useEffect(() => {
    const lastEntity = entities[entities.length - 1];
    if (!lastEntity?.currentUser || !isAdmin(lastEntity.currentUser)) {
      navigate('/communication')
    }
  }, [entities]);

  const { t } = useTranslation();
  const subscribersData = useApiGetData<Subscriber[]>(
    `${environment.restEndpoint}/contact/newsletter/subscriber/getUnverifiedSubscribers`,
    'get',
    'data'
  );

  const customerStateMapper = (customerstate: string): JSX.Element => {
    switch (customerstate) {
      case 'POTENTIAL_CUSTOMER':
        return <>I</>;
      case 'LOST_CUSTOMER':
        return <>LC</>;
      case 'CUSTOMER':
        return <>C</>;
      default:
        return <>Unknown</>;
    }
  };

  const columns = [
    {
      Header: 'Status',
      accessor: 'customerState',
      Cell: ({ cell }: any) => (
        <React.Fragment>{customerStateMapper(cell.value)}</React.Fragment>
      ),
    },
    {
      Header: 'Kundennummer',
      accessor: 'customerNumber',
    },
    {
      Header: 'E-Mail',
      accessor: 'email',
      filter: 'fuzzyText',
      sortType: (rowA: Row<Subscriber>, rowB: Row<Subscriber>) =>
        defaultSort(rowA.values['email'], rowB.values['email']),
    },
    {
      Header: 'Name',
      accessor: 'fullname',
      Cell: ({ row }: any) => (
        <span>
          {`${row.original.salutation ? row.original.salutation : '...'} ${
            row.original.firstname ? row.original.firstname : ''
          } ${row.original.name ? row.original.name : ''}`}
        </span>
      ),
    },
    {
      Header: 'Newsletter',
      accessor: 'newsletter',
      Cell: ({ row }: any) => (
        <span>
          <p>{row.original.newsletter ? row.original.newsletter.name : '-'}</p>
        </span>
      ),
    },
    {
      Header: 'Angelegt am',
      accessor: 'createdAt',
      Cell: ({ row }: any) => (
        <span>
          <p>
            {row.original.createdAt
              ? new Date(row.original.createdAt).toLocaleDateString()
              : '-'}
          </p>
        </span>
      ),
    },
  ];

  return (
    <div className="p-6">
      <Headline
        className="pb-8"
        title={t('communication.newsletter.verification.sideTitle')}
      />
      <Table
        columns={columns}
        data={subscribersData.data ? subscribersData.data : []}
        loading={subscribersData.loading}
        rowCount={subscribersData.data?.length}
        countLabel={
          subscribersData.data?.length === 1
            ? t('common.list.count.entry')
            : t('common.list.count.entries')
        }
        pagination
        initialPage={0}
        basic={true}
      />
    </div>
  );
};

export default NewsletterVerification;

import { Multiselect as Ms } from 'multiselect-react-dropdown';
import './multiselect.module.scss';
import React from 'react';

export interface MultiselectOptionsProps {
  key: string | number;
  name: string;
  value: string | number;
  group?: string | number;
}

export interface MultiselectProps {
  options: Array<MultiselectOptionsProps>;
  values: Array<MultiselectOptionsProps>;
  displayToken: string;
  label?: {
    name?: string;
    position?: 'top' | 'left'; //only left and top
  };
  id?: string;
  required?: boolean;
  groupBy?: string;
  error?: string;
  width?: number;
  unit?: string;
  placeholder?: string;
  showCheckbox?: boolean;
  onSelect?: (
    selectedList: Array<MultiselectOptionsProps>,
    selectedItem: Record<string, string>
  ) => void;
  onRemove?: (
    selectedList: Array<MultiselectOptionsProps>,
    removedItem: Record<string, string>
  ) => void;
  selectionLimit?: number;
}

export const Multiselect = ({
  label,
  unit = 'px',
  placeholder = '',
  groupBy = '',
  id,
  required,
  displayToken,
  options,
  values,
  error,
  width,
  showCheckbox = true,
  onRemove,
  onSelect,
  selectionLimit,
}: MultiselectProps) => {
  const labler = label && label.name ? (
    <label
      className={`text-base text-dark font-suzuki-bold block whitespace-nowrap mr-4
        ${!label.position || label.position ==='top' ? 'mb-2' : ''}`}
      htmlFor={id}
    >
      {label.name.toUpperCase()}
      {required ? '*' : null}
    </label>
  ) : null;

  const err = (
    <p
      className="text-xs text-red block mt-0"
      style={{ visibility: error ? 'visible' : 'hidden' }}
    >
      {error}
    </p>
  );

  const widthMs = width ? width + unit : '100%';

  const suzstyle = {
    multiselectContainer: {
      fontSize: '1rem !important',
      fontFamily: 'Arial, sans-serif',
      width: widthMs,
    },
    chips: {
      background: '#0098DB',
      borderRadius: '0',
      paddingLeft: '0.25rem',
      paddingRight: '0.25rem',
      marginRight: '0.5rem',
      fontSize: '1rem',
      fontFamily: 'Arial, sans-serif',
      whiteSpace: 'break-spaces',
    },
    searchBox: {
      border: 'none',
      borderBottom: '1px solid #0098DB',
      borderRadius: '0px',
      fontSize: '1rem',
      fontFamily: 'Arial, sans-serif',
    },
    inputField: {
      fontSize: '1rem',
      fontFamily: 'Arial, sans-serif',
    },
    optionContainer: {
      // To change css for option container
      borderRadius: '0px',
      fontSize: '1rem',
      fontFamily: 'Arial, sans-serif',
      listStyle: 'none',
    },
  };

  return (
    <div>
      {!label || label?.position === 'top' ? labler : ''}
      <div className="flex flex-nowrap items-center justify-between">
        {label && label?.position === 'left' ? labler : ''}
        <div
          className={`inline-block relative bg-white border border-solid p-0 ${
            error ? 'border-red' : 'border-mediumgray'
          }`}
          style={width ? { width: width + unit } : { width: '100%' }}
        >
          <Ms
            options={options} // Options to display in the dropdown
            selectedValues={values} // Preselected value to persist in dropdown
            onSelect={onSelect} // Function will trigger on select event
            onRemove={onRemove} // Function will trigger on remove event
            displayValue={displayToken} // Property name to display in the dropdown options
            closeIcon="close"
            style={suzstyle}
            showCheckbox={showCheckbox}
            placeholder={placeholder}
            emptyRecordMsg="Keine Einträge vorhanden."
            groupBy={groupBy}
            isObject={true}
            selectionLimit={selectionLimit}
          />
        </div>
      </div>

      {err}
    </div>
  );
};

export default Multiselect;

import React from 'react';
import { Sweepstake, SweepstakeErrorMessageByCode } from '../../models/sweepstake';
import { Button, ButtonStyle, Modal } from '@sde/basic-ui-library';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { ApiError } from '../../models/error';
import {removeSweepstake} from "../../store/slices/sweepstake.slice";

export interface RemoveSweepstakePopupProps {
  isOpen: boolean,
  sweepstake?: Sweepstake,
  onClose: () => void,
  onDelete: () => void,
}

export default function RemoveSweepstakePopup(props: Readonly<RemoveSweepstakePopupProps>) {
  const { t } = useTranslation();

  const handleRemove = async () => {
    if (!props.sweepstake?.id) return;

    try {
      const response = await removeSweepstake(props.sweepstake.id);
      if (response.ok) {
        toast.success(t('communication.sweepstake.overview.popup.success_delete').toString());
        props.onDelete();
        props.onClose();
        return;
      }
      const apiError = (await response.json()) as ApiError;
      if (apiError?.message && SweepstakeErrorMessageByCode.has(apiError.message)) {
        toast.error(t(SweepstakeErrorMessageByCode.get(apiError.message)!).toString());
        return;
      }
    } catch (e) {
      console.error(e);
      toast.error(t('error_popup.unknown').toString());
    }
  };

  return (
    <Modal
      title={t('communication.sweepstake.overview.modal.title_delete')}
      size={'w-[640px]'}
      show={props.isOpen}
      handleClose={() => props.onClose()}>
      <div className="px-20 pb-3">
        <div className="mb-10">
          <span className="block font-bold whitespace-nowrap">{props.sweepstake?.name}</span>
          <span className="block">{t('communication.sweepstake.overview.modal.text_delete')}</span>
        </div>
        <div className="flex justify-end gap-2">
          <Button className="bg-white"
                  buttonStyle={ButtonStyle.OUTLINE}
                  onClick={() => props.onClose()}>
            {t('standard_controls.cancel_uppercase')}
          </Button>
          <Button px="5"
                  py="3"
                  onClick={() => handleRemove()}>
            {t('communication.sweepstake.overview.modal.button_delete')}
          </Button>
        </div>
      </div>
    </Modal>
  );
}

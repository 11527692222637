import React from 'react';

export type LogoIconProps = {
  color?: string;
  colorLogo?: string;
  title?: string;
};

export const LogoIcon = (props: LogoIconProps) => {
  return (
    <svg
      fill={props.color ? props.color : '#000000'}
      xmlns="http://www.w3.org/2000/svg"
      height={!props.title ? '90px' : '110px'}
      width="180px"
    >
      <g fill={props.colorLogo ? props.colorLogo : props.color}>
        <title>Suzuki {props.title && <span>{props.title}</span>}</title>
        <path
          id="svg_3"
          d="m34.4,38l5.5,3.8c1.1,0.8 2.3,1.4 4.3,1.4c3,0 5.8,-2 5.8,-2l-12.5,-8.6c0,0 -1.4,1.5 -5.6,4.2c-4.5,2.8 -6.8,3.6 -6.8,3.6l16.1,11.1l-0.7,0.5l-5.5,-3.8c-1.1,-0.8 -2.3,-1.4 -4.3,-1.4c-3,0 -5.8,2 -5.8,2l12.5,8.6c0,0 1.4,-1.5 5.6,-4.2c4.5,-2.8 6.8,-3.6 6.8,-3.6l-16.2,-11.1l0.8,-0.5z"
        />
      </g>
      <g>
        <title>Suzuki {props.title && <span>{props.title}</span>}</title>

        <path
          id="svg_2"
          d="m34.4,38l5.5,-0.5zm31.9,5.2c-2.4,-0.3 -4.5,-0.4 -4.5,-1.2c-0.1,-1.1 1.7,-1.2 2.2,-1.2c0.9,0 2.5,0.2 2.8,1.6l6,0c0,-2.7 -2.6,-4.9 -8.8,-4.9c-7,0 -8.2,3.1 -8.2,4.8c0,3.2 2.5,4.2 8,4.8c2,0.2 3.3,0.2 3.3,1.2s-1.5,1.1 -2.2,1.1c-0.6,0 -2.3,0 -2.8,-1.7l-6.6,0c0.3,3.2 2.3,5.1 9.4,5c6.7,0 8.4,-2.7 8.4,-4.8c0.1,-1.9 -1.3,-4 -7,-4.7zm26.8,-1.6l8.1,0l-8.3,7.7l0,3.1l16.8,0l0,-3.7l-8.4,0l8.4,-7.7l0,-3.1l-16.6,0l0,3.7zm56.5,-3.7l5.5,0l0,14.5l-5.5,0l0,-14.5zm-8.3,0l-5.8,5l0,-5l-5.5,0l0,14.5l5.5,0l0,-5.1l5.4,5.1l7.3,0l-8,-7.4l8.3,-7.1l-7.2,0zm-55.2,6.1c0,0.2 0,0.5 0,2.3s-0.7,3 -3.1,3s-3,-1.3 -3,-3c0,-1.8 0,-2.1 0,-2.3s0,-6.1 0,-6.1l-5.2,0c0,0 0,4.6 0,5.3s0,3 0,4.3c0,1.9 0.5,5.4 8.3,5.4s8.3,-3.6 8.3,-5.4c0,-1.3 0,-3.6 0,-4.3s0,-5.3 0,-5.3l-5.2,0c-0.1,0 -0.1,5.9 -0.1,6.1zm36.8,0c0,0.2 0,0.5 0,2.3s-0.7,3 -3.1,3s-3.1,-1.2 -3.1,-3s0,-2.1 0,-2.3s0,-6.1 0,-6.1l-5.2,0c0,0 0,4.6 0,5.3s0,3 0,4.3c0,1.9 0.5,5.4 8.3,5.4s8.3,-3.6 8.3,-5.4c0,-1.3 0,-3.6 0,-4.3s0,-5.3 0,-5.3l-5.2,0c0,0 0,5.9 0,6.1z"
        />

        {props.title && (
          <text
            textAnchor="start"
            fontSize="22"
            id="svg_3"
            y="68.14814"
            x="50%"
            dominant-baseline="middle"
            text-anchor="middle"
            fill={props.color ? props.color : '#000000'}
          >
            {props.title}
          </text>
        )}
      </g>
    </svg>
  );
};

export default LogoIcon;

import { Company, Contact } from '../../models/contact';
import { t } from 'i18next';

export const getHidingReasons = () => {
  return ({
    noLongerServicedByMe: {
      value: "NO_ATTENDANCE",
      translation: t('hide-contact.options.no-longer-serviced-by-me'),
    },
    deceasedClosed: {
      value: "DECEASED_BANKRUPT",
      translation: t('hide-contact.options.deceased-closed'),
    },
    fakeDataSet: {
      value: "FAKE",
      translation: t('hide-contact.options.fake-data-set'),
    },
    gdpr: {
      value: "DS_GVO",
      translation: t('hide-contact.options.gdpr'),
    },
    noDataRelease: {
      value: "DATA_PRIVACY",
      translation: t('hide-contact.options.no-data-release'),
    },
    other: {
      value: "MISCELLANEOUS",
      translation: t('hide-contact.options.other'),
    }
  })
}

export const getTranslatedHidingReasonOfContact = (contact: Contact | Company): string | undefined => {
  const reasons = getHidingReasons()
  const translations = new Map<string, string>([
    [reasons.noLongerServicedByMe.value, reasons.noLongerServicedByMe.translation],
    [reasons.deceasedClosed.value, reasons.deceasedClosed.translation],
    [reasons.fakeDataSet.value, reasons.fakeDataSet.translation],
    [reasons.gdpr.value, reasons.gdpr.translation],
    [reasons.noDataRelease.value, reasons.noDataRelease.translation],
    [reasons.other.value, reasons.other.translation],
  ]);

  if (!contact.accessibillity) {
    return undefined
  }
  const access = contact.accessibillity
  if (!access.disablingReasonType) {
    return undefined
  }

  if (access.disablingReasonType === reasons.other.value && access.disablingReasonDescription) {
    return `${reasons.other.translation}: ${access.disablingReasonDescription}`
  }

  return translations.get(access.disablingReasonType) ? translations.get(access.disablingReasonType) : reasons.other.translation
}



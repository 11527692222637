import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../setup'
import { DealerFilter } from "../../models/dealer";

export const DEALER_FILTER_FEATURE_KEY = 'dealer-filter';

export interface DealerFilterState {
  key: string;
  currentRow: string | undefined;
  dealerFilter: DealerFilterData
}

export interface DealerFilterData {
  filter: DealerFilter;
  pageIndex: number;
}

export const initialDealerFilterState: DealerFilterState = {
  key: "",
  currentRow: undefined,
  dealerFilter: {
    filter: {},
    pageIndex: 0
  }
};

const dealerFilterSlice = createSlice({
  name: DEALER_FILTER_FEATURE_KEY,
  initialState: initialDealerFilterState,
  reducers: {
    init: (state, { payload: key }: PayloadAction<string>) => {
      state.key = key;
      state.currentRow = undefined;
      state.dealerFilter = {
        filter: {},
        pageIndex: 0
      };
    },
    updateDealerFilter: (state, { payload: dealerFilter }: PayloadAction<DealerFilter>) => {
      state.dealerFilter.filter = dealerFilter;
    },
    updateDealerPageIndex: (state, { payload: pageIndex }: PayloadAction<number>) => {
      state.dealerFilter.pageIndex = pageIndex;
    },
    updateDealerCurrentRow: (state, { payload: currentRow }: PayloadAction<string | undefined>) => {
      state.currentRow = currentRow;
    },
  }
});

export const dealerFilterActions = dealerFilterSlice.actions;

export const dealerFilterSelector = (state: RootState) => state[DEALER_FILTER_FEATURE_KEY]

export const dealerFilterReducer = dealerFilterSlice.reducer;

import React, { useState } from 'react';
import BasicRadioButton from '../radiobutton/radiobutton';
import './radio-group.module.scss';

export interface ElementItems {
  value: string;
  label?: string;
  isNotClickable?: boolean;
}

export interface RadioGroupProps {
  values: Array<ElementItems>;
  value: string;
  name: string;
  error?: string;
  label?: string;
  size?: string;
  radioChange: (selectedValue: string) => void;
}

export function RadioGroup(props: RadioGroupProps) {
  const [value, setValue] = useState(props.value);
  const handleChange = (selectedValue: string, isHandlingPermitted?: boolean) => {
    if (isHandlingPermitted && isHandlingPermitted === true) {
      return;
    }
    setValue(selectedValue);
    props.radioChange(selectedValue);
  };

  return (
    <>
      {props.values.map((val) => (
        <BasicRadioButton
          size={props.size}
          onChange={() => handleChange(val.value, val.isNotClickable)}
          name={props.name}
          key={val.value}
          value={val.value}
          label={{
            name: val.label,
            position: 'right',
          }}
          checked={value === val.value}
        />
      ))}
    </>
  );
}

export default RadioGroup;

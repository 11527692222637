import React from "react";
import {useTranslation} from "react-i18next";

export interface TextLineProps {
  labelText: string,
  value?: string | number,
  labelClass?: string,
  valueClass?: string,
  labelOnLeft?: boolean,
  width?: string,
}

export const TextLine = (props: TextLineProps) => {
  const { t } = useTranslation();
  const defaultLabelClass = 'text-base text-dark font-suzuki-bold mr-2 whitespace-nowrap uppercase';
  const defaultValueClass = "text-justify pe-5";
  const mainComponentClass = props.labelOnLeft ? "flex flex-row gap-x-2 max-w-sm" : "flex flex-col gap-y-2 max-w-sm";

  return (
    <div className={mainComponentClass} style={props.width ? { width: props.width } : { width: '100%' }}>
      <span className={props.labelClass ? props.labelClass : defaultLabelClass}>
        {props.labelText}
      </span>
      <span className={props.valueClass ? props.valueClass : defaultValueClass}>
        {props.value ? props.value : t('text-line.not-specified')}
      </span>
    </div>
  );
}

export interface VehicleModel {
  objectId: string;
  model: string;
  type: VehicleModelType;
}

export enum VehicleModelType {
  UNDEFINED = "UNDEFINED",
  AUTO = "AUTO",
  MOTO = "MOTO"
}

export const OTHER_MODEL_ID = 'DKCUU4J7A5TCmQnu9v3E'

import {
  Button,
  DataInfoContainer,
  Headline,
  Icon,
  Spinner,
} from '@suzuki-frontend-framework-update/ui';
import { useNavigate, useParams } from 'react-router-dom';
import './dealer-information.module.scss';
import { useEffect, useState } from 'react';
import PageNotFound from '../page-not-found/page-not-found';
import { useTranslation } from 'react-i18next';
import { useApiGetData } from '../../components/useApiGetData/useApiGetData';
import { environment } from '../../../environments/environment';
import { Dealer } from '../../models/dealer';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../store/setup';
import { dealerFilterActions } from '../../store/slices/dealer-filter.slice';

export function DealerInformation() {
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch: AppDispatch = useDispatch();
  const dealerData = useApiGetData<Dealer>(
    `${environment.restEndpoint}/dealer/dealer-service/dealer?dealercode=${id}`,
    'get',
    'dealer'
  );
  const [dealer, setDealer] = useState<Dealer>();
  const navigate = useNavigate();

  useEffect(() => {
    if (!dealerData.loading && id !== undefined) {
      setDealer(dealerData.data);
    }
  }, [dealerData.data, dealerData.loading, id]);

  const handleClick = () => {
    dispatch(dealerFilterActions.updateDealerFilter({}));
    navigate(-1);
  };
  return dealer || dealerData.loading ? (
    <div className="grow p-8 bg-gray">
      {!dealer ? (
        <Spinner />
      ) : (
        <>
          <div className="h-fit w-fit">
            <Button py="4" className="w-full mb-1" onClick={handleClick}>
              <Icon iconName={'arrow_back'} className="mr-1 "></Icon>
              {t('dealer-information.back')}
            </Button>
            {/* </Link> */}
          </div>

          <Headline
            title={t('dealer-information.title', {
              name: dealer.name,
            })}
          />
          <span className="text-dark-gray">
            {dealer.createdAt
              ? t('dealer-information.basic-data.createdAt') +
                new Date(dealer.createdAt).toLocaleDateString('de-DE', {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                })
              : t('dealer-information.basic-data.createdAt')}
          </span>
          <div className="flex flex-row gap-x-8">
            <div className="flex flex-col gap-y-4 w-1/3">
              <DataInfoContainer
                items={[
                  {
                    name: t('dealer-information.basic-data.number'),
                    values: [
                      {
                        value: dealer.dealercode ? dealer.dealercode : '',
                      },
                    ],
                  },
                  {
                    name: t('dealer-information.basic-data.name'),
                    values: [{ value: dealer.name ? dealer.name : '' }],
                  },
                  {
                    name: t('dealer-information.basic-data.name-addition'),
                    values: [
                      {
                        value: dealer.nameAppendum ? dealer.nameAppendum : '',
                      },
                    ],
                  },
                ]}
                title={t('dealer-information.basic-data.title')}
                className="w-full"
              />
              <DataInfoContainer
                items={[
                  {
                    name: t('dealer-information.contact-data.phone'),
                    values: [{ value: dealer.phone ? dealer.phone : '' }],
                  },
                  {
                    name: t('dealer-information.contact-data.fax'),
                    values: [{ value: dealer.fax ? dealer.fax : '' }],
                  },
                  {
                    name: t('dealer-information.contact-data.dealer-email'),
                    values: [
                      {
                        value: dealer.email ? (
                          <a href={'mailto:' + dealer.email}>{dealer.email}</a>
                        ) : (
                          ''
                        ),
                      },
                    ],
                  },
                  {
                    name: t('dealer-information.contact-data.suzuki-email'),
                    values: [
                      {
                        value: dealer.alternativeEmail ? (
                          <a href={'mailto:' + dealer.alternativeEmail}>
                            {dealer.alternativeEmail}
                          </a>
                        ) : (
                          ''
                        ),
                      },
                    ],
                  },
                  {
                    name: t('dealer-information.contact-data.homepage'),
                    values: [
                      {
                        value: dealer.homepage ? (
                          <a
                            href={
                              dealer.homepage.startsWith('https://')
                                ? dealer.homepage
                                : 'https://' + dealer.homepage
                            }
                            target="_blank"
                            rel="noreferrer"
                          >
                            {dealer.homepage}
                          </a>
                        ) : (
                          ''
                        ),
                      },
                    ],
                  },
                  {
                    name: t('dealer-information.contact-data.homepage2'),
                    values: [
                      {
                        value: dealer.homepage2 ? (
                          <a
                            href={
                              dealer.homepage2.startsWith('http')
                                ? dealer.homepage2
                                : 'https://' + dealer.homepage2
                            }
                            target="_blank"
                            rel="noreferrer"
                          >
                            {dealer.homepage2}
                          </a>
                        ) : (
                          ''
                        ),
                      },
                    ],
                  },
                ]}
                title={t('dealer-information.contact-data.title')}
                className="w-full"
              />
            </div>
            <div className="flex flex-col gap-y-4 w-1/3">
              <DataInfoContainer
                items={[
                  {
                    name: t('dealer-information.address-data.street'),
                    values: [
                      {
                        value: dealer.addressLine1 ? dealer.addressLine1 : '',
                      },
                    ],
                  },
                  {
                    name: t('dealer-information.address-data.postcode'),
                    values: [{ value: dealer.zipCode ? dealer.zipCode : '' }],
                  },
                  {
                    name: t('dealer-information.address-data.city'),
                    values: [{ value: dealer.city ? dealer.city : '' }],
                  },
                ]}
                title={t('dealer-information.address-data.title')}
                className="w-full"
              />
              <DataInfoContainer
                items={[
                  {
                    name: t('dealer-information.opening-hours.sale'),
                    values: [
                      {
                        value: t('dealer-information.opening-hours.monday'),
                        value2:
                          dealer.openingtimesSales &&
                          dealer.openingtimesSales.monday
                            ? dealer.openingtimesSales.monday.map(
                                (monday) =>
                                  ' ' + monday.start + ' - ' + monday.end
                              ) + ''
                            : '-',
                      },
                      {
                        value: t('dealer-information.opening-hours.tuesday'),
                        value2:
                          dealer.openingtimesSales &&
                          dealer.openingtimesSales.tuesday
                            ? dealer.openingtimesSales.tuesday.map(
                                (tuesday) =>
                                  ' ' + tuesday.start + ' - ' + tuesday.end
                              ) + ''
                            : ' - ',
                      },
                      {
                        value: t('dealer-information.opening-hours.wednesday'),
                        value2:
                          dealer.openingtimesSales &&
                          dealer.openingtimesSales.wednesday
                            ? dealer.openingtimesSales.wednesday.map(
                                (wednesday) =>
                                  ' ' + wednesday.start + ' - ' + wednesday.end
                              ) + ''
                            : '-',
                      },
                      {
                        value: t('dealer-information.opening-hours.thursday'),
                        value2:
                          dealer.openingtimesSales &&
                          dealer.openingtimesSales.thursday
                            ? dealer.openingtimesSales.thursday.map(
                                (thursday) =>
                                  ' ' + thursday.start + ' - ' + thursday.end
                              ) + ''
                            : '-',
                      },
                      {
                        value: t('dealer-information.opening-hours.friday'),
                        value2:
                          dealer.openingtimesSales &&
                          dealer.openingtimesSales.friday
                            ? dealer.openingtimesSales.friday.map(
                                (friday) =>
                                  ' ' + friday.start + ' - ' + friday.end
                              ) + ''
                            : '-',
                      },
                      {
                        value: t('dealer-information.opening-hours.saturday'),
                        value2:
                          dealer.openingtimesSales &&
                          dealer.openingtimesSales.saturday
                            ? dealer.openingtimesSales.saturday.map(
                                (saturday) =>
                                  ' ' + saturday.start + ' - ' + saturday.end
                              ) + ''
                            : '-',
                      },
                      {
                        value: t('dealer-information.opening-hours.sunday'),
                        value2:
                          dealer.openingtimesSales &&
                          dealer.openingtimesSales.sunday
                            ? dealer.openingtimesSales.sunday.map(
                                (sunday) =>
                                  ' ' + sunday.start + ' - ' + sunday.end
                              ) + ''
                            : '-',
                      },
                    ],
                  },
                  {
                    name: t('dealer-information.opening-hours.service'),
                    values: [
                      {
                        value: t('dealer-information.opening-hours.monday'),
                        value2:
                          dealer.openingtimesService &&
                          dealer.openingtimesService.monday
                            ? dealer.openingtimesService.monday.map(
                                (monday) =>
                                  ' ' + monday.start + ' - ' + monday.end
                              ) + ''
                            : '-',
                      },
                      {
                        value: t('dealer-information.opening-hours.tuesday'),
                        value2:
                          dealer.openingtimesService &&
                          dealer.openingtimesService.tuesday
                            ? dealer.openingtimesService.tuesday.map(
                                (tuesday) =>
                                  ' ' + tuesday.start + ' - ' + tuesday.end
                              ) + ''
                            : '-',
                      },
                      {
                        value: t('dealer-information.opening-hours.wednesday'),
                        value2:
                          dealer.openingtimesService &&
                          dealer.openingtimesService.wednesday
                            ? dealer.openingtimesService.wednesday.map(
                                (wednesday) =>
                                  ' ' + wednesday.start + ' - ' + wednesday.end
                              ) + ''
                            : '-',
                      },
                      {
                        value: t('dealer-information.opening-hours.thursday'),
                        value2:
                          dealer.openingtimesService &&
                          dealer.openingtimesService.thursday
                            ? dealer.openingtimesService.thursday.map(
                                (thursday) =>
                                  ' ' + thursday.start + ' - ' + thursday.end
                              ) + ''
                            : '-',
                      },
                      {
                        value: t('dealer-information.opening-hours.friday'),
                        value2:
                          dealer.openingtimesService &&
                          dealer.openingtimesService.friday
                            ? dealer.openingtimesService.friday.map(
                                (friday) =>
                                  ' ' + friday.start + ' - ' + friday.end
                              ) + ''
                            : '-',
                      },
                      {
                        value: t('dealer-information.opening-hours.saturday'),
                        value2:
                          dealer.openingtimesService &&
                          dealer.openingtimesService.saturday
                            ? dealer.openingtimesService.saturday.map(
                                (saturday) =>
                                  ' ' + saturday.start + ' - ' + saturday.end
                              ) + ''
                            : '-',
                      },
                      {
                        value: t('dealer-information.opening-hours.sunday'),
                        value2:
                          dealer.openingtimesService &&
                          dealer.openingtimesService.sunday
                            ? dealer.openingtimesService.sunday.map(
                                (sunday) =>
                                  ' ' + sunday.start + ' - ' + sunday.end
                              ) + ''
                            : '-',
                      },
                    ],
                  },
                ]}
                title={t('dealer-information.opening-hours.title')}
                className="w-full"
              />
            </div>
            <div className="flex flex-col gap-y-4 w-1/3">
              <DataInfoContainer
                items={[
                  {
                    name: t(
                      'dealer-information.contract-data.distribution-contract'
                    ),
                    name2: t(
                      'dealer-information.contract-data.distribution-contract-end'
                    ),

                    values: [
                      {
                        value: dealer.salesContractStart
                          ? new Date(
                              dealer.salesContractStart
                            ).toLocaleDateString('de-DE', {
                              year: 'numeric',
                              month: '2-digit',
                              day: '2-digit',
                            })
                          : '',
                        value2: dealer.salesContractEnd
                          ? new Date(
                              dealer.salesContractEnd
                            ).toLocaleDateString('de-DE', {
                              year: 'numeric',
                              month: '2-digit',
                              day: '2-digit',
                            })
                          : '',
                      },
                    ],
                  },

                  {
                    name: t(
                      'dealer-information.contract-data.service-contract'
                    ),
                    name2: t(
                      'dealer-information.contract-data.service-contract-end'
                    ),
                    values: [
                      {
                        value: dealer.serviceContractStart
                          ? new Date(
                              dealer.serviceContractStart
                            ).toLocaleDateString('de-DE', {
                              year: 'numeric',
                              month: '2-digit',
                              day: '2-digit',
                            })
                          : '',
                        value2: dealer.serviceContractEnd
                          ? new Date(
                              dealer.serviceContractEnd
                            ).toLocaleDateString('de-DE', {
                              year: 'numeric',
                              month: '2-digit',
                              day: '2-digit',
                            })
                          : '',
                      },
                    ],
                  },
                ]}
                title={t('dealer-information.contract-data.title')}
                className="w-full"
              />

              <DataInfoContainer
                items={[
                  {
                    name: t('dealer-information.amrDsmArea.amr'),
                    values: [
                      {
                        value: dealer.salesArea ? dealer.salesArea : '',
                      },
                    ],
                  },
                  {
                    name: t('dealer-information.amrDsmArea.dsm'),
                    values: [
                      {
                        value: dealer.aftersalesArea
                          ? dealer.aftersalesArea
                          : '',
                      },
                    ],
                  },
                ]}
                title={t('dealer-information.amrDsmArea.title')}
                className="w-full"
              />
            </div>
          </div>
        </>
      )}
    </div>
  ) : (
    <div className="grow bg-gray">
      <PageNotFound />
    </div>
  );
}

export default DealerInformation;

import {Navigate, useLocation} from "react-router-dom";
import React, {useEffect} from "react";

export interface RedirectWithQueryParamsProps {
    to: string;
}

export const InternalRedirectWithQueryParams = ({ to }: RedirectWithQueryParamsProps) => {
    const location = useLocation();
    const searchParams = location.search;

    return <Navigate replace to={`${to}${searchParams}`} />;
};

export const ExternalRedirectWithQueryParams = ({ to }: RedirectWithQueryParamsProps) => {
  const location = useLocation();
  const searchParams = location.search;
  const externalUrl = `${to}${searchParams}`;

  useEffect(() => {
    window.location.href = externalUrl;
  }, [externalUrl]);

  return null;
};

import { Component } from 'react';
import BasicInput from '../basic-input/basic-input';
import Icon from '../icon/icon';
import { Filter, FilterProps } from "@sde/basic-ui-library";

export interface TableFiltersState {
  searchValue: string;
}

export interface TableFiltersProps {
  id?: string;
  filters?: Array<FilterProps>;
  placeholder?: string;
  onSearchUpdate?: (e: string) => void;
  searchDelay?: number;
  onMoreFilters?: (e: any) => void;
  initialSearchValue?: string;
}

export class TableFilters extends Component<
  TableFiltersProps,
  TableFiltersState
> {
  timer: any;
  constructor(props: TableFiltersProps) {
    super(props);
    this.state = {
      searchValue: props.initialSearchValue ? props.initialSearchValue : '',
    };
  }

  componentWillMount() {
    clearTimeout(this.timer);
  }

  handleSearchUpdate = (value: string) => {
    clearTimeout(this.timer);
    this.setState({ searchValue: value });

    this.timer = setTimeout(() => {
      if (this.props.onSearchUpdate) this.props.onSearchUpdate(value);
    }, this.props.searchDelay);
  };

  render() {
    return (
      <div className="flex flex-row justify-between">
        <div className="flex flex-row items-center gap-x-8">
          <BasicInput
            key={this.props.id}
            icon="search"
            placeholder={this.props.placeholder}
            value={this.state.searchValue}
            onChange={(e) => this.handleSearchUpdate(e.target.value)}
            width="20rem"
          />
          {this.props.filters &&
            this.props.filters.map((filter) => (
              <Filter
                key={filter.id}
                id={filter.id}
                name={filter.name}
                items={filter.items}
                selected={filter.selected}
                onChange={filter.onChange}
              />
            ))}
        </div>
        {this.props.onMoreFilters && (
          <button
            onClick={this.props.onMoreFilters}
            className="flex flex-row gap-x-2 items-center"
          >
            <Icon iconName="tune" className="rotate-90" />
            <div className="underline">Weitere Filter</div>
          </button>
        )}
      </div>
    );
  }
}

export default TableFilters;

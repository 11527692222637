import React from 'react';
// import tw from 'twin.macro';
// import styled from 'styled-components';
import { t } from 'i18next';

import {
  useTable,
  useSortBy,
  useRowSelect,
  Column,
  UseSortByColumnProps,
  UseFiltersColumnProps,
  ColumnInstance,
  Row,
} from 'react-table';
import {
  TableCell,
  TableHeader,
  TableRow,
  TTable,
  TableContentContainer,
} from './default-components';
import IndeterminateCheckbox from './IndeterminateCheckbox';
import { CheckboxStatus } from './table';
import Spinner from '../spinner/spinner';

interface TableColumn<D extends object>
  extends ColumnInstance<D>,
    UseSortByColumnProps<D>,
    UseFiltersColumnProps<D> {}

export interface TableProps {
  columns: Array<Column<any>>;
  data: any;
  basic?: boolean;
  loading?: boolean;
  handleRowClick?: (row: Row<any>) => void;
  handleRowSelect?: (row: Row<any>) => void;
  handleAllRowsSelect?: () => void;
  customSelectedRowIds?: Record<string, boolean>;
  allSelectedStatus?: CheckboxStatus;
  disableSelection?: (row: Row<any>) => boolean;
  selectTitle?: string;
  allSelectTitle?: string;
  customCellClassName?: string;
  customHeaderClassName?: string;
}

export const DefaultTable = ({
  columns,
  data,
  basic = false,
  loading,
  handleRowClick,
  handleRowSelect,
  customSelectedRowIds,
  handleAllRowsSelect,
  allSelectedStatus,
  disableSelection,
  selectTitle = t('table.select-title'),
  allSelectTitle = t('table.all-select-title'),
  customCellClassName,
  customHeaderClassName
}: TableProps) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        initialState: {
          selectedRowIds: customSelectedRowIds ? customSelectedRowIds : {},
        },
        columns,
        data,
        basic,
        customSelectedRowIds,
        allSelectedStatus,
      },
      (hooks) => {
        hooks.visibleColumns.push((columns) =>
          basic
            ? [
                {
                  Header: '',
                },
                ...columns,
              ]
            : [
                // Let's make a column for selection
                {
                  id: 'selection',
                  // The header can use the table's getToggleAllRowsSelectedProps method
                  // to render a checkbox
                  Header: ({ getToggleAllRowsSelectedProps }) => (
                    <div>
                      <IndeterminateCheckbox
                        {...{
                          ...getToggleAllRowsSelectedProps(),
                          title: allSelectTitle,
                          checked: allSelectedStatus
                            ? allSelectedStatus.checked
                            : getToggleAllRowsSelectedProps().checked,
                          indeterminate: allSelectedStatus
                            ? allSelectedStatus.indeterminate
                            : getToggleAllRowsSelectedProps().indeterminate,
                          onChange: (
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            const props = getToggleAllRowsSelectedProps();
                            if (props && props.onChange) {
                              props.onChange(e);
                            }
                            if (handleAllRowsSelect) {
                              handleAllRowsSelect();
                            }
                          },
                        }}
                      />
                    </div>
                  ),
                  Cell: ({ row }) => {
                    return (
                      <div>
                        <IndeterminateCheckbox
                          {...{
                            ...row.getToggleRowSelectedProps(),
                            title: selectTitle,
                            checked: customSelectedRowIds
                              ? customSelectedRowIds[row.id] === true
                              : row.getToggleRowSelectedProps().checked,
                            onChange: (
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              const props = row.getToggleRowSelectedProps();
                              if (props && props.onChange) {
                                props.onChange(e);
                              }
                              if (handleRowSelect) {
                                handleRowSelect(row);
                              }
                            },
                            disabled: disableSelection
                              ? disableSelection(row)
                              : false,
                          }}
                        />
                      </div>
                    );
                  },
                },
                ...columns,
              ]
        );
      },
      useSortBy,
      useRowSelect
    );

  return (
    <div className="shadow-md">
      <TableContentContainer>
        <TTable {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <TableHeader
                    customClassName={customHeaderClassName}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render('Header')}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? ' 🔽'
                          : ' 🔼'
                        : ''}
                    </span>
                  </TableHeader>
                ))}
              </TableRow>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <TableRow {...row.getRowProps()} key={row.id}>
                  {row.cells.map((cell) => {
                    if (cell.column.id !== 'selection' && handleRowClick) {
                      return (
                        <TableCell
                          customClassName={customCellClassName}
                          onClick={() => handleRowClick(row)}
                          {...cell.getCellProps()}
                        >
                          {cell.render('Cell')}
                        </TableCell>
                      );
                    } else {
                      return (
                        <TableCell customClassName={customCellClassName}
                          {...cell.getCellProps()}>
                          {cell.render('Cell')}
                        </TableCell>
                      );
                    }
                  })}
                </TableRow>
              );
            })}
          </tbody>
        </TTable>
        {loading ? (
          <div className="my-4 w-full">
            <Spinner />
          </div>
        ) : null}
      </TableContentContainer>
    </div>
  );
};

export default DefaultTable;

import React, { useEffect, useState } from 'react';
import { BasicTable, Button, Headline, Icon, Tooltip } from '@sde/basic-ui-library';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { Newsletter, NewsletterActivationStatus } from "../../../../models/newsletter";
import { Tenant } from "../../../../models/tenant";
import NewsletterFilter from "../../../../components/newsletter/newsletter-filter";
import EditNewsletterPopup from "../../../../components/newsletter/edit-newsletter-popup";
import DeactivateNewsletterPopup from "../../../../components/newsletter/deactivate-newsletter-popup";
import DeleteNewsletterPopup from "../../../../components/newsletter/delete-newsletter-popup";
import { fetchNewslettersData } from "../../../../store/slices/newsletter.slice";
import {isAdmin} from "../../../../helpers/session-utils";
import {useSelector} from "react-redux";
import {selectAllUserManagement} from "../../../../store/slices/user-management.slice";
import {useNavigate} from "react-router-dom";

export default function NewsletterDefinition() {
  const { t } = useTranslation();
  const [newsletterData, setNewsletterData] = useState<Newsletter[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [editNewsletterPopupOpen, setEditNewsletterPopupOpen] = useState<boolean>(false);
  const [deletePopupNewsletterOpen, setDeletePopupNewsletterOpen] = useState<boolean>(false);
  const [deactivateNewsletterPopupOpen, setDeactivateNewsletterPopupOpen] = useState<boolean>(false);
  const [selectedNewsletter, setSelectedNewsletter] = useState<Newsletter>();
  const [filter, setFilter] = useState<string>(NewsletterActivationStatus.UNDEFINED);
  const entities = useSelector(selectAllUserManagement);
  const navigate = useNavigate()

  useEffect(() => {
    const lastEntity = entities[entities.length - 1];
    if (lastEntity && lastEntity.currentUser) {
      if (!isAdmin(lastEntity.currentUser)) {
        navigate('/communication')
      }
    }

  }, [entities]);

  const errorToast = (errorText: string) => {
    toast.error(errorText, {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      toastId: 'excel-import-error',
    });
  };

  const loadData = async () => {
    setIsLoading(true);
    try {
      const newsletters = await fetchNewslettersData(Tenant.UNDEFINED, filter);
      setNewsletterData(newsletters);
    } catch (e) {
      console.error(e);
      errorToast(t('error_popup.loading_data'));
    } finally {
      setIsLoading(false);
    }
  };

  const openCreateNewsletter = () => {
    setSelectedNewsletter(undefined);
    setEditNewsletterPopupOpen(true);
  };

  const openEditNewsletter = (newsletter: Newsletter) => {
    setSelectedNewsletter(newsletter);
    setEditNewsletterPopupOpen(true);
  };

  const openDeleteNewsletter = (newsletter: Newsletter) => {
    setSelectedNewsletter(newsletter);
    setDeletePopupNewsletterOpen(true);
  };

  const openDeactivateNewsletter = (newsletter: Newsletter) => {
    setSelectedNewsletter(newsletter);
    setDeactivateNewsletterPopupOpen(true);
  };

  useEffect(() => {
    loadData().catch(e => console.error(e));
  }, [filter]);

  const columnNewsletterHelper = createColumnHelper<Newsletter>();
  const columns: ColumnDef<Newsletter>[] = [
    columnNewsletterHelper.accessor((row: Newsletter) => row.name, {
      id: 'name',
      enableSorting: false,
      header: () => <span>{t('communication.newsletter.definition.table_header_name')}</span>,
      cell: (info) => {
        return <span>{info.getValue()}</span>;
      },
    }),
    columnNewsletterHelper.accessor((row: Newsletter) => row.code, {
      id: 'code',
      enableSorting: false,
      header: () => <span>{t('communication.newsletter.definition.table_header_code')}</span>,
      cell: (info) => {
        return <span>{info.getValue()}</span>;
      },
    }),
    columnNewsletterHelper.accessor((row: Newsletter) => row.isMoto, {
      id: 'tenant',
      enableSorting: false,
      header: () => <span>{t('communication.newsletter.definition.table_header_tenant')}</span>,
      cell: (info) => {
        return <span>{info.getValue() ? Tenant.MOTO : Tenant.AUTO}</span>;
      },
    }),
    columnNewsletterHelper.accessor((row: Newsletter) => row.active, {
      id: 'activation_status',
      enableSorting: false,
      header: () => <span>{t('communication.newsletter.definition.table_header_activation_status')}</span>,
      cell: (info) => {
        return <span>{info.getValue() ? t('communication.newsletter.definition.filter.active') : t('communication.newsletter.definition.filter.deactivated')}</span>;
      },
    }),
    columnNewsletterHelper.accessor((row: Newsletter) => row, {
      id: 'actions',
      enableSorting: false,
      header: () => <span></span>,
      cell: (info) => {
        const newsletter = info.getValue();
        return (
          <div className="flex gap-4 justify-end">
            {newsletter.active && (
              <>
                <Tooltip content={t('communication.newsletter.definition.tooltip_edit')}>
                  <button onClick={() => openEditNewsletter(newsletter)}>
                    <Icon iconName="edit" />
                  </button>
                </Tooltip>
                <Tooltip content={t('communication.newsletter.definition.tooltip_deactivate')}>
                  <button onClick={() => openDeactivateNewsletter(newsletter)}>
                    <Icon iconName="cancel" />
                  </button>
                </Tooltip>
              </>
            )}
            <Tooltip content={t('communication.newsletter.definition.tooltip_delete')}>
              <button onClick={() => openDeleteNewsletter(newsletter)}>
                <Icon iconName="delete" />
              </button>
            </Tooltip>
          </div>
        );
      },
    }),
  ].filter((item) => item) as ColumnDef<Newsletter>[];

  return (
    <>
      <div className="p-8">
        <div className="mb-8 mt-2 flex flex-row justify-between items-center">
          <Headline title={t('communication.newsletter.definition.page_header')}/>

          <div className="justify-end">
            <Tooltip content={t('communication.newsletter.definition.tooltip_create')}>
              <Button
                onClick={() => openCreateNewsletter()}
                className="w-fit h-fit"
                px="1"
                py="1"
              >
                <Icon iconName="add" className="!text-2xl !w-7"/>
              </Button>
            </Tooltip>
          </div>
        </div>
        <div className="flex flex-row gap-x-4 pb-5">
          <NewsletterFilter selectedOption={filter} setSelectedOption={setFilter}/>
        </div>

        <BasicTable<Newsletter>
          key="definition-table"
          basic
          columns={columns}
          data={newsletterData}
          loading={isLoading}
          countLabel={
            newsletterData?.length === 1
              ? t('common.list.count.entry')
              : t('common.list.count.entries')
          }
          fetchData={() => {return;}}
          rowCount={newsletterData.length}
        />
      </div>
      <EditNewsletterPopup
        isOpen={editNewsletterPopupOpen}
        newsletter={selectedNewsletter}
        onClose={() => setEditNewsletterPopupOpen(false)}
        onUpdate={() => loadData()}
      />
      <DeactivateNewsletterPopup
        isOpen={deactivateNewsletterPopupOpen}
        newsletter={selectedNewsletter}
        onClose={() => setDeactivateNewsletterPopupOpen(false)}
        onDeactivate={() => loadData()}
      />
      <DeleteNewsletterPopup
        isOpen={deletePopupNewsletterOpen}
        newsletter={selectedNewsletter}
        onClose={() => setDeletePopupNewsletterOpen(false)}
        onDelete={() => loadData()}
      />
    </>
  );
}

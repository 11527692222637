import { useTranslation } from 'react-i18next';
import { Button, Icon, Modal } from '@suzuki-frontend-framework-update/ui';

export interface SendConfirmationPopupProps {
  modalTitle: string;
  description: string;
  expectedInspectionDate?: string;
  setSendModalConfState: (state: boolean) => void;
  sendModalConfState: boolean;
  children?: React.ReactNode;
}

export const SendConfirmationPopup = (props: SendConfirmationPopupProps) => {
  const { t } = useTranslation();

  return (
    <div>
      <Modal
        key="send-modal"
        show={props.sendModalConfState}
        handleClose={() => props.setSendModalConfState(false)}
        title={props.modalTitle}
        handleCloseOnBg
      >
        <div className="flex flex-col items-center gap-y-4 py-5 px-12">
          <Icon
            iconName={'check_circle_outline'}
            className="text-green-500 text-7xl"
          ></Icon>
          <span>{props.description}</span>
          {props.expectedInspectionDate ? (
            <span className="block">{props.expectedInspectionDate}</span>
          ) : (
            ''
          )}
          <Button
            className="w-full flex flex-col items-center"
            py="4"
            onClick={() => props.setSendModalConfState(false)}
          >
            {t(
              'communication.inspections-invitations.invitation-confirmation-close'
            )}
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default SendConfirmationPopup;

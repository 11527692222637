import { Location } from "react-router-dom";

export const getDateFromQuery = (location: Location) => {
  return new URLSearchParams(location.search).get('date')
}

export const getRangeFromQuery = (location: Location) => {
  return new URLSearchParams(location.search).get('range')
}

export const getInspectionNumbersFromQuery = (location: Location): number[] => {
  const inspectionNumber = new URLSearchParams(location.search).get('inspectionNumber');
  const range = new URLSearchParams(location.search).get('range');
  if (range) {
    const numbers: number[] = []
    for (let i = 1; i <= Number(range); i++)
      numbers.push(i)
    return numbers
  }
  return [Number(inspectionNumber)]
}

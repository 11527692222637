import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../setup'
import { InvitationsFilters } from '../../models/invitation';

export const INSPECTIONS_INVITATIONS_FILTER_FEATURE_KEY = 'inspections-invitations-filter';

export interface InspectionsInvitationsFilterState {
  key: string;
  currentRow: string | undefined;
  inspectionsInvitationsFilter: InspectionsInvitationsData;
}

export interface InspectionsInvitationsData {
  filter: InvitationsFilters;
  pageIndex: number;
}

export const initialInspectionsInvitationsFilterState: InspectionsInvitationsFilterState = {
  key: "",
  currentRow: undefined,
  inspectionsInvitationsFilter: {
    filter: { },
    pageIndex: 0
  }
};

const inspectionsInvitationsFilterSlice = createSlice({
  name: INSPECTIONS_INVITATIONS_FILTER_FEATURE_KEY,
  initialState: initialInspectionsInvitationsFilterState,
  reducers: {
    init: (state, { payload: key }: PayloadAction<string>) => {
      state.key = key;
      state.currentRow = undefined;
      state.inspectionsInvitationsFilter = {
        filter: { },
        pageIndex: 0
      };
    },
    updateInspectionsInvitationsFilter: (state, { payload: inspectionsInvitationsFilter }: PayloadAction<InvitationsFilters>) => {
      state.inspectionsInvitationsFilter.filter = inspectionsInvitationsFilter;
    },
    updateInspectionsInvitationsPageIndex: (state, { payload: pageIndex }: PayloadAction<number>) => {
      state.inspectionsInvitationsFilter.pageIndex = pageIndex;
    },
    updateInspectionsInvitationsCurrentRow: (state, { payload: currentRow }: PayloadAction<string | undefined>) => {
      state.currentRow = currentRow;
    },
  }
});

export const inspectionsInvitationsFilterActions = inspectionsInvitationsFilterSlice.actions;

export const inspectionsInvitationsFilterSelector = (state: RootState) => state[INSPECTIONS_INVITATIONS_FILTER_FEATURE_KEY]

export const inspectionsInvitationsFilterReducer = inspectionsInvitationsFilterSlice.reducer;

import { environment } from "../../../environments/environment";

export interface NameTraits {
  first: string;
  last: string;
}

export interface UserTraits {
  email: string;
  name: NameTraits;
  username: string;
}

export interface UserData {
  data: User[];
  countAll: number;
}

export interface User {
  id: number;
  traits: UserTraits;
  createdAt: string;
  updatedAt: string;
  state: string;
}

export async function deleteUser(userId: string): Promise<boolean> {
  try {
    const resp = await fetch(`${environment.restEndpoint}/user/users/${userId}`,{
      method: "DELETE",
      headers: {
        "apikey": "p0zLmdPds51XKPtTTc05z0L0"
      }
    });
    return true;
  } catch (err) {
    console.error(err);
    return false
  }
}

import React, { useEffect, useState } from "react";
import styles from './sales-chart.module.scss';
import { Area, AreaChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { Headline } from "@suzuki-frontend-framework-update/ui";
import { Filter } from "@sde/basic-ui-library";
import { useTranslation } from "react-i18next";
import { environment } from "../../../environments/environment";
import { SelectInput, DatePickerRange } from "@sde/basic-ui-library";
import { sendApiRequest } from "../send-api-request/send-api-request";
import { getColor } from "./sales-chart-helper";
import { isAutoDealerOrAdmin } from "../../helpers/session-utils";
import {Session} from "@ory/client";

export const SalesChart = ({ dealers, session }: { dealers: string[], session: Session }) => {
  const defaultModels = isAutoDealerOrAdmin(session) ? ["IGNIS", "SWIFT", "SWIFT SPORT", "VITARA", "S-CROSS", "SWACE", "ACROSS", "JIMNY"] : [];

  const { t } = useTranslation();
  const [selectedOption, setSelectedOption] = useState('weekly');
  const [modelNames, setModelNames] = useState<string[]>([]);
  const [selectedModels, setSelectedModels] = useState<string[]>([...defaultModels]);
  const [fromDate, setFromDate] = useState<Date | null>(new Date(new Date().setMonth(new Date().getMonth() - 12)));
  const [toDate, setToDate] = useState<Date | null>(new Date());
  const [isFetching, setIsFetching] = useState(false);

  const [data, setData] = useState<any[]>([]);
  const fillData = async () => {
    try {
      setIsFetching(true);
      setData([]);
      await sendApiRequest(
        `${environment.restEndpoint}/vehicle/vehicle-service/sales-data`,
        'post',
        {
          from: fromDate,
          to: toDate,
          dealerCodes: dealers,
          groupBy: selectedOption === 'weekly' ? 0 : 1,
        })
        .then(response => response.json())
        .then(response => JSON.parse(response.vehicles))
        .then(resp => resp.map((item: any) => {
          const year = item.name.slice(0,4);
          const lang = localStorage.getItem('i18nextLng') as string;
          const date = new Date(Number(item.name.split('-')[0]), Number(item.name.split('-')[1]) - 1);
          const monthYear = date.toLocaleString(lang ?? 'en-US', { month: 'short', year: 'numeric' });
          const weekYear = `${t('appointmentsChart.week')} ${item.name.split('-')[1].padStart(2, '0')}`;

          return {
            ...item,
            name: selectedOption === 'weekly' ? weekYear : monthYear,
            year,
          };
        }))
        .then((resp: any) => resp.map((item: any) => {
          const mergedObject: any = {};
          for (const key in item) {
            const upperCase = key.toUpperCase();
            if (!(upperCase in mergedObject)) {
              mergedObject[upperCase] = item[key];
            } else {
              mergedObject[upperCase] += item[key];
            }
          }

          return mergedObject;
        }))
        .then((resp: any) => {
          setModelNames([
            ...defaultModels,
            ...new Set([...resp.map((item: any) => Object.keys(item)).flat().filter((item: any) => !defaultModels.includes(item))]),
          ].filter(item => item !== 'NAME' && item !== 'YEAR'));
          setData(resp);
          setIsFetching(false);
        })
      ;
    } catch (error) {
      setIsFetching(false);
      console.error(error);
    }
  }

  useEffect(() => {
    fillData().catch(e => console.error(e));
  }, [fromDate, toDate, dealers, selectedOption])


  function handleModelChange(id: string, selected: Array<string>, changedId: string) {
    const allModelsArray = ['allModels', ...modelNames].sort();
    const selectedArray = selected.slice().sort();
    if (changedId === 'allModels') {
      selected.includes('allModels') ? setSelectedModels(allModelsArray) : setSelectedModels([]);
    } else if (JSON.stringify(allModelsArray) === JSON.stringify(['allModels', ...selectedArray].sort())) {
      setSelectedModels(allModelsArray);
    } else {
      setSelectedModels(selected.filter(model => model !== 'allModels'));
    }
  }

  return (
    <section className={ isFetching ? styles.loading : ''}>
      <div className={ isFetching ? styles.loader : ''}></div>
      <Headline className="text-dark-gray" title={t(`dashboard.title`)} />
      <div className="flex flex-row gap-x-8 pr-8 px-4">
        <SelectInput
          options={[
            {
              key: 'weekly',
              name: t('dashboard.weekly'),
              value: 'weekly',
            },
            {
              key: 'monthly',
              name: t('dashboard.monthly'),
              value: 'monthly',
            },
          ]}
          width="10rem"
          onSelect={(value: string) => setSelectedOption(value)}
          label={{
            name: t('dashboard.timeUnitFilter'),
            position: 'top',
          }}
        />
        <DatePickerRange
          id="t"
          labelFrom={t(`dashboard.from`)}
          labelTo={t(`dashboard.to`)}
          from={fromDate}
          to={toDate}
          changeFrom={(value) => setFromDate(value)}
          changeTo={(value) => setToDate(value)}
        />
        <div className="flex items-end gap-6 pb-2">
          <Filter
            key={'model'}
            id={'model'}
            name={t('dashboard.modelByFilter')}
            items={[
              { id: 'allModels', name: t('dashboard.allModels') },
              ...modelNames.map((modelName) => ({
                id: modelName,
                name: modelName,
              })),
            ]}
            selected={selectedModels}
            onChange={handleModelChange}
            width="7rem"
          />
        </div>
      </div>
      <ResponsiveContainer  width="100%" height="100%" minHeight={400}>
        <AreaChart
          width={1000}
          height={450}
          data={data.map(item => {
            return {
              ...Object.assign({}, ...selectedModels.map(item => ({ [item]: 0 }))),
              ...item,
            };
          })}
          margin={{
            top: 10,
            right: 35,
            bottom: 0,
            left: 0,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" vertical={false}/>
          <YAxis allowDecimals={false} domain={[0, 100]}/>
          <XAxis dataKey="NAME" interval={Math.floor(data.length / 20)} domain={['',`${t('appointmentsChart.week')}` + ' 14', `${t('appointmentsChart.week')}` + ' 02']}/>
          {selectedOption === 'weekly' &&
            <XAxis dataKey="YEAR" domain={['', '2023', '2024']}
                   interval={Math.floor(data.length / 20)}
                   height={20}
                   xAxisId="year"
                   axisLine={false}
                   tickLine={false}
                   dy={-10}
            />
          }

          {selectedModels.map(item => {
            return item !== 'allModels' && <Area
              key={item}
              connectNulls
              stackId="1"
              dataKey={item}
              stroke={getColor(item)}
              fill={getColor(item) + '88'}
            />
          })}

          <Tooltip />
          <Legend verticalAlign="bottom" align="left" iconType="circle" />
        </AreaChart>
      </ResponsiveContainer>
    </section>
  );
};

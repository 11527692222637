import { configureStore } from "@reduxjs/toolkit";
import logger from "redux-logger";
import { userManagementReducer } from "./slices/user-management.slice";
import { contactFilterReducer } from "./slices/contact-filter.slice";
import { vehicleFilterReducer } from "./slices/vehicle-filter.slice";
import { dealerFilterReducer } from "./slices/dealer-filter.slice";
import { userFilterReducer } from "./slices/user-filter.slice";
import { extendedWarrantyFilterReducer } from "./slices/extended-warranty.slice";
import { inspectionsInvitationsFilterReducer } from "./slices/inspections-invitations.slice";
import { serviceCcpExportFilterReducer } from "./slices/service-ccp-export.slice";
import { salesCcpExportFilterReducer } from "./slices/sales-ccp-export.slice";
import { testDriveReportFilterReducer } from "./slices/test-drive-report.slice";
import { dashboardFilterReducer } from "./slices/dashboard.slice";
import { surveysFilterReducer } from "./slices/surveys.slice";
import { webRequestFilterReducer } from "./slices/web-request-filter.slice";

export const store = configureStore({
  reducer: {
    userManagement: userManagementReducer,
    'contact-filter': contactFilterReducer,
    'vehicle-filter': vehicleFilterReducer,
    'dealer-filter': dealerFilterReducer,
    'user-filter': userFilterReducer,
    'extenede-warranty-filter': extendedWarrantyFilterReducer,
    'inspections-invitations-filter': inspectionsInvitationsFilterReducer,
    'service-ccp-export-filter': serviceCcpExportFilterReducer,
    'sales-ccp-export-filter': salesCcpExportFilterReducer,
    'test-drive-report-filter': testDriveReportFilterReducer,
    'dashboard-filter': dashboardFilterReducer,
    'surveys-filter': surveysFilterReducer,
    'web-request-filter': webRequestFilterReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger)
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

import {
  Badge,
  Button,
  Headline,
  Icon,
  Table,
  Tooltip,
} from '@suzuki-frontend-framework-update/ui';
import * as Popper from '@popperjs/core';
import { useCallback, useState } from 'react';
import { deleteUser, UserData } from './user-data';
import './user-list.module.scss';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { RemoveActionPopup } from '../../components/remove-action-popup/remove-action-popup';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../store/setup';
import {
  userFilterActions,
  userFilterSelector,
} from '../../store/slices/user-filter.slice';
import { UserRequestBody } from '../../models/user';
import { useApiGetData } from '../../components/useApiGetData/useApiGetData';
import { environment } from '../../../environments/environment';
import { vehicleFilterActions } from '../../store/slices/vehicle-filter.slice';
import { DataFetchProps } from '../../models/others';
import { Column } from "react-table";

export function UserList() {
  const pageSize = 100;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const userFilterState = useSelector(userFilterSelector);
  const dispatch: AppDispatch = useDispatch();
  if (userFilterState.key === '' || location.key !== userFilterState.key) {
    dispatch(userFilterActions.init(location.key));
  }

  const [userRequestBody, setUserRequestBody] = useState<UserRequestBody>({
    pageSize: pageSize,
    pageIndex: userFilterState.userFilter.pageIndex,
    filter: userFilterState.userFilter.filter,
  });

  const [removeActionState, setRemoveActionState] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<any>();

  const usersData = useApiGetData<UserData>(
    `${environment.restEndpoint}/user/users/GetUsers`,
    'post',
    '',
    userRequestBody
  );

  const handleFilterSearch = (value: string) => {
    dispatch(
      userFilterActions.updateUserFilter({
        search: value,
      })
    );
  };

  const columns: Column<any>[] = [
    {
      Header: t('user-list.column.username'),
      accessor: 'traits.username',
    },
    {
      Header: t('user-list.column.name'),
      accessor: 'name',
      Cell: ({ row }: any) => {
        return (
          <div>
            <span>
              {row.original.traits.name.first} {row.original.traits.name.last}
            </span>
          </div>
        );
      },
    },
    {
      Header: t('user-list.column.email'),
      accessor: 'traits.email',
    },
    {
      Header: t('user-list.column.groups'),
      accessor: 'group',
      Cell: ({ row }: any) => {
        return row.original.group ? (
            <Badge name={row.original.group} title={row.original.group} />
        ) : (
          <span>-</span>
        );
      },
    },
    {
      Header: t('user-list.column.isAdmin.column'),
      accessor: 'traits.isAdmin',
      Cell: ({ row }: any) => {
        return (
          <span>
            {row.original.traits.isAdmin ? (
              <Tooltip
                content={t('user-list.column.isAdmin.yes')}
                placement={Popper.top}
              >
                <Icon iconName="done" className="text-black" />
              </Tooltip>
            ) : (
              <Tooltip
                content={t('user-list.column.isAdmin.no')}
                placement={Popper.top}
              >
                <Icon className="text-black" iconName="close" />
              </Tooltip>
            )}
          </span>
        );
      },
    },
    {
      Header: t('user-list.column.isAreaAdmin.column'),
      accessor: 'traits.isAreaAdmin',
      Cell: ({ row }: any) => {
        return (
          <span>
            {row.original.traits.isAreaAdmin ? (
              <Tooltip
                content={t('user-list.column.isAreaAdmin.yes')}
                placement={Popper.top}
              >
                <Icon iconName="done" className="text-black" />
              </Tooltip>
            ) : (
              <Tooltip
                content={t('user-list.column.isAreaAdmin.no')}
                placement={Popper.top}
              >
                <Icon className="text-black" iconName="close" />
              </Tooltip>
            )}
          </span>
        );
      },
    },
    {
      Header: t('user-list.column.actions'),
      accessor: 'actions',
      Cell: ({ row }: any) => {
        return (
          <div className="flex justify-between">
            <Tooltip content={t('user-list.edit')}>
              <button onClick={() => handleEdit(row.original.id)}>
                <Icon iconName={'create'} className="text-black" />
              </button>
            </Tooltip>
            <Tooltip content={t('user-list.delete')}>
              <button
                onClick={() => {
                  setSelectedUser(row.original);
                  setRemoveActionState(true);
                }}
              >
                <Icon iconName={'delete'} className="text-black" />
              </button>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  function handleEdit(id: string) {
    navigate(`/admin/users/edit/${id}`);
  }

  async function handleDelete(id: string) {
    clearRemoveActionState();
    await deleteUser(id);
    setUserRequestBody({
      ...userRequestBody,
      pageSize: pageSize,
      pageIndex: userFilterState.userFilter.pageIndex,
      filter: userFilterState.userFilter.filter,
    });
  }

  const clearRemoveActionState = () => {
    setRemoveActionState(false);
    setSelectedUser(null);
  };
  const userFilters = {
    id: 'UserListFilters',
    key: 'UserListFilters',
    placeholder: t('user-list.filter.search'),
    onSearchUpdate: handleFilterSearch,
    initialSearchValue: userFilterState.userFilter.filter.search,
  };

  const handlePreviousRow = useCallback(
    async ({ itemsRef }: any) => {
      if (usersData.data?.data && userFilterState.currentRow) {
        itemsRef.current
          .get(userFilterState.currentRow)
          ?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        dispatch(vehicleFilterActions.updateVehicleCurrentRow(undefined));
      }
    },
    [usersData.data?.data, usersData.loading]
  );

  const sortFieldMapper = (uifield: string): string => {
    switch (uifield) {
      case 'name':
        return 'name';
      case 'traits.email':
        return 'traits.email';
      default:
        return 'traits.username';
    }
  };

  const fetchUserData = useCallback(
    async ({ pageSize, pageIndex, pageSortBy }: DataFetchProps) => {
      dispatch(userFilterActions.updateUserPageIndex(pageIndex));
      let sortByDir = 1;
      let sortByField = '';
      if (pageSortBy && pageSortBy.length > 0) {
        const sortParams = pageSortBy[0];
        sortByDir = sortParams.desc ? -1 : 1;
        sortByField = sortFieldMapper(sortParams.id);
      }
      setUserRequestBody({
        pageSize: pageSize,
        pageIndex: pageIndex,
        filter: userFilterState.userFilter.filter,
        sortField: sortByField,
        sortDir: sortByDir,
      });
    },
    [userFilterState.userFilter.filter]
  );

  return (
    <div className="p-8 relative">
      {selectedUser && (
        <RemoveActionPopup
          onHandleCancel={() => clearRemoveActionState()}
          onHandleRemove={() => handleDelete(selectedUser.id)}
          modalState={removeActionState}
          type="user"
          valueNameToDelete={selectedUser.username}
        />
      )}
      <Headline title={t('user-list.title')} className="mb-8 mt-2" />
      <Button
        onClick={() => navigate('/admin/users/register')}
        className="pr-7 mb-4"
      >
        <div className="flex flex-row justify-between gap-4">
          <Icon iconName={'add'} />
          <span>{t('user-list.add-user')}</span>
        </div>
      </Button>
      <Table
        key={'user-table'}
        filter={userFilters}
        columns={columns}
        data={usersData.data?.data ? usersData.data?.data : []}
        initPageSize={pageSize}
        loading={usersData.loading}
        rowCount={usersData.data?.countAll}
        countLabel={
          usersData.data?.data && usersData.data?.data.length === 1
            ? t('common.list.count.entry')
            : t('common.list.count.entries')
        }
        fetchData={fetchUserData}
        initialPage={userFilterState.userFilter.pageIndex}
        basic
        pagination
        handleScrollToRow={handlePreviousRow}
      />
    </div>
  );
}

export default UserList;

import './basic-output.module.scss';
import React from 'react';

export type BasicOutputProps = {
  title?: string;
  className?: string;
  styled?: boolean;
  id?: string;
  children?: React.ReactNode;
  table?: boolean;
};

export const BasicOutput = (props: BasicOutputProps) => {
  return (
    <div className={props.className} id={props.id}>
      <span className="font-suzuki-bold">{props.title}</span>
      <div
        className={`${props.table ? '' : 'p-4'} ${
          props.styled ? 'border-l-4' : ''
        } border-dark text-dark border flex flex-col bg-white`}
      >
        {props.children}
      </div>
    </div>
  );
};

export default BasicOutput;

import {
  CancelTestDriveRequest, TestDriveData,
} from "../../models/test-drive";
import {toast, ToastOptions} from "react-toastify";
import { useTranslation } from "react-i18next";
import { environment } from "../../../environments/environment";
import { sendApiRequest } from "../send-api-request/send-api-request";
import { Badge, BasicInput, Button, ButtonStyle, Modal, RadioGroup, Textarea } from "@sde/basic-ui-library";
import { CustomerType } from "../../models/contact";
import React, { useEffect, useState } from "react";
import { format } from "react-string-format";
import { ChooseBetweenPopup } from "../choose-between-popup/choose-between-popup";
import { TestDriveInformation } from "../test-drive-information/test-drive-information";

export interface CancelTestDrivePopupProps {
  setModalState: (state: boolean) => void;
  modalState: boolean;
  setCreateTestDriveModalState: (state: boolean) => void;
  testDrive: TestDriveData;
  refreshData: () => void;
}

export const CancelTestDrivePopup = (props: CancelTestDrivePopupProps) => {
  const { t } = useTranslation();
  const [cancelingReason, setCancelingReason] = useState<string>('');
  const [confirmationModalState, setConfirmationModalState] = useState<boolean>(false);
  const notSpecifiedText = t('communication.test-drive.report.modals.not-specified');

  const defaultToastOptions: ToastOptions = {
    position: 'top-center',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  }

  const successToast = (successText: string) => {
    toast.success(successText, {
      ...defaultToastOptions,
      toastId: 'cancel-test-drive-success-toast',
    });
  };

  const errorToast = (errorText: string) => {
    toast.error(errorText, {
      ...defaultToastOptions,
      toastId: 'cancel-test-drive-error-toast',
    });
  };

  const resetPopupStates = () => {
    setCancelingReason('');
  }

  const handleCancel = () => {
    props.setModalState(false);
    resetPopupStates();
  };

  const areRequiredFieldsSet = (): boolean => {
    return cancelingReason !== undefined && cancelingReason.trim() !== '';
  }

  const handleSubmit = () => {
    if(!areRequiredFieldsSet()) {
      errorToast(t('communication.test-drive.report.modals.cancel-test-drive.fill-required-fields'));
      return;
    }
    const requestUrl = `${environment.restEndpoint}/action/testdrives/CancelTestDrive`;

    const requestBody: CancelTestDriveRequest = {
      id: props.testDrive.id,
      cancelingReason: cancelingReason,
    }

    sendApiRequest(requestUrl, 'post', requestBody).then((response) => {
      if (response.ok) {
        successToast(t('communication.test-drive.report.modals.cancel-test-drive.successfully-canceled'));
        handleCancel();
        setConfirmationModalState(true);
        if (props.refreshData) props.refreshData();
      } else {
        response.json().then((data) => {
          errorToast(t('error') + data.message);
        });
      }
    });
  }

  return (
    <div>
      <Modal
        key="schedule-test-drive-modal"
        title={t('communication.test-drive.report.modals.cancel-test-drive.modal-title')}
        show={props.modalState}
        handleClose={() => handleCancel()}
        size='min-w-[440px]'
      >
        <div className="flex flex-col pb-10">
          <div className="flex flex-row items-start ps-5 gap-x-8 align-middle">
            <span className="font-suzuki-headline text-dark" style={{fontSize: '30px'}}>{props.testDrive.companyId ? `${props.testDrive?.contactSalutation} ${props.testDrive?.companyName}` : `${props.testDrive?.contactSalutation} ${props.testDrive?.contactFirstName} ${props.testDrive?.contactLastName}`}</span>
            <Badge
              name={
                props.testDrive?.customerState === CustomerType.CUSTOMER
                  ? t(
                    'contact-details.customer'
                  ).toLocaleUpperCase()
                  : props.testDrive?.customerState === CustomerType.PROSPECT
                    ? t(
                      'contact-details.prospect'
                    ).toLocaleUpperCase()
                    : props.testDrive?.customerState === CustomerType.LC
                      ? t(
                        'contact-details.lost-customer'
                      ).toLocaleUpperCase()
                      : notSpecifiedText.toLocaleUpperCase()
              }
              title={
                props.testDrive?.customerState === CustomerType.CUSTOMER
                  ? t('contact-details.customer')
                  : props.testDrive?.customerState === CustomerType.PROSPECT
                    ? t('contact-details.prospect')
                    : props.testDrive?.customerState === CustomerType.LC
                      ? t('contact-details.lost-customer')
                      : notSpecifiedText.toLocaleUpperCase()
              }
            />
          </div>
          <div className="flex items-start ps-5 pt-2">
            <span className="text-dark-gray font-suzuki-regular">
              {
                `${format(
                  t('communication.test-drive.report.modals.created-at'),
                  props.testDrive.createAt ? new Date(props.testDrive.createAt).toLocaleDateString(
                    'de-DE', {year: 'numeric', month: '2-digit', day: '2-digit'}
                  ) : notSpecifiedText,
                  props.testDrive.origin ? t(`communication.test-drive.origin.${props.testDrive.origin}`) : notSpecifiedText,
                )} ${props.testDrive.lastChangeDate ? format(
                  t('communication.test-drive.report.modals.modified-at'),
                  new Date(props.testDrive.lastChangeDate).toLocaleDateString(
                    'de-DE', {year: 'numeric', month: '2-digit', day: '2-digit'}
                  ),
                  props.testDrive.lastChangeEditor ? props.testDrive.lastChangeEditor : notSpecifiedText,
                ) : ''}`
              }
            </span>
          </div>
          <TestDriveInformation testDrive={props.testDrive}/>
          <div className="px-5">
            <Textarea
              value={cancelingReason}
              onChange={(value) => setCancelingReason(value)}
              width="100%"
              label={{
                name: t('communication.test-drive.report.modals.cancel-test-drive.reason-for-canceling'),
                position: 'top',
              }}
              rows={3}
              resizeDisabled
            />
          </div>
        </div>
        <div className="flex flex-row gap-x-6 justify-end">
          <Button
            className="bg-white"
            buttonStyle={ButtonStyle.OUTLINE}
            onClick={handleCancel}
            px="5"
            py="3"
          >
            {t('communication.test-drive.report.modals.close')}
          </Button>
          <Button onClick={handleSubmit} px="5" py="3">
            {t('communication.test-drive.report.modals.save')}
          </Button>
        </div>
      </Modal>
      <ChooseBetweenPopup
        modalTitle={t('communication.test-drive.report.modals.question-test-drive-title')}
        description={t('communication.test-drive.report.modals.question-test-drive-creation')}
        firstChooseText={t('communication.test-drive.report.modals.no')}
        secondChooseText={t('communication.test-drive.report.modals.yes')}
        modalState={confirmationModalState}
        setModalState={setConfirmationModalState}
        doOnFirstChoose={() => {setConfirmationModalState(false);}}
        doOnSecondChoose={() => {{
          setConfirmationModalState(false);
          props.setCreateTestDriveModalState(true);
        }}}
        doOnClose={() => {setConfirmationModalState(false);}}
      />
    </div>
  )
}

import React, { FunctionComponent } from 'react';

export interface RadioButtonProps {
  checked?: boolean;
  value?: string | number;
  label?: {
    name?: string;
    position: 'top' | 'right' | 'left'; //only left, top and right
  };
  error?: string;
  id?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  name?: string;
  size?: string;
  disabled?: boolean;
}
export const RadioButton: FunctionComponent<RadioButtonProps> = (props) => {
  const error = (
    <p
      className={`text-xs text-red-500 font-medium block mt-0 ${
        props.error ? '' : 'hidden'
      }`}
      style={{ visibility: props.error ? 'visible' : 'hidden' }}
    >
      {props.error}
    </p>
  );

  return (
    <React.Fragment>
      {props.label?.position === 'top' && <div> {props.label.name} </div>}
      <label className={props.disabled ? 'text-gray' : ''}>
        {props.label?.position === 'left' ? props.label.name : ''}

        <div
          className={`${props.className} flex align-middle text-base whitespace-nowrap`}
        >
          <input
            value={props.value}
            type="radio"
            className={`appearance-none w-${props.size ? props.size : '7'} h-${props.size ? props.size : '7'} rounded-full border border-black cursor-pointer p-1 bg-white checked:shadow-[0_0_0_5px_rgba(255,255,255,1.0)_inset] checked:bg-black ${props.disabled ? 'bg-gray opacity-75' : ''}`}
            checked={props.checked}
            onChange={props.onChange}
            name={props.name}
            disabled={props.disabled ?? false}
          />

        {props.label?.position === 'right' && <span className="inline-block align-middle text-base  ml-2">
          {props.label.name}
        </span> }
        </div>
      </label>
      {error}
    </React.Fragment>
  );
};

export default RadioButton;

import styles from './ui.module.scss';
import React from 'react';

export function Ui() {
  return (
    <div>
      <h1 className="text-rose-600 text-4xl underline p-4 font-bold cursor-pointer">
        Welcome to Ui!
      </h1>
      <h2 className={styles['subheadline']}>My Subheadline</h2>
      <h3 className="text-lime-800 text-xl underline font-mono">
        My 3rd headline
      </h3>
    </div>
  );
}

export default Ui;

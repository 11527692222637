import React, { FC, useEffect, useState } from 'react';
import { Modal } from '@sde/basic-ui-library';
import { useTranslation } from 'react-i18next';
import { mapSelectionMechanismId, SelectionMechanismEnum } from '../../models/selection-mechanism';
import {
  GroupItem,
  ReportItemDetails,
  ReportResponseItemPeriod
} from '../../models/survey-report';
import { environment } from '../../../environments/environment';
import {sendApiRequest} from "../send-api-request/send-api-request";

type Props = {
  isDetailModalOpen: boolean
  setIsDetailModalOpen: (value: React.SetStateAction<boolean>) => void
  item: GroupItem
  period: ReportResponseItemPeriod
  reportId: string
  modelNames: string[],
  dateType: string,
  dealerCodes: string[],
}

const SurveyReportTableDetailPopup: FC<Props> = (props) => {
  const {t} = useTranslation();
  const [itemDetails, setItemDetails] = useState<ReportItemDetails>({
    count: 0,
    responseValues: [],
    textResponses: [],
  });

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [maxPage, setMaxPage] = useState<number>(1);
  const pageSize = 8;

  const changePage = (num: number) => {
    if (currentPage > maxPage - 1 && num > 0) return;
    if (currentPage <= 1 && num < 0) return;
    setCurrentPage(currentPage + num);
  }

  const fetchDetails = async () => {
    const additionalQuery = [
      SelectionMechanismEnum.TEXT_SINGLE_LINE,
      SelectionMechanismEnum.TEXT_MULTIPLE_LINE,
    ].includes(mapSelectionMechanismId(props.item.selectionMechanismId)) ? `&pageSize=${pageSize}&pageIndex=${currentPage - 1}` : '';

    if (props.reportId === "" || !props.item || props.item.id === 0) {
      return {
        count: 0,
        responseValues: [],
        textResponses: [],
      }
    }

    const url = `${environment.restEndpoint}/survey-service/reports/${props.reportId}/items/${props.item.id}/details?from=${new Date(props.period.startDate).toISOString().split('T')[0]}&to=${new Date(props.period.endDate).toISOString().split('T')[0]}${additionalQuery}&dateType=${props.dateType}`
    const data = await sendApiRequest(
      url,
      'post',
      {
        modelNames: props.modelNames,
        dealerCodes: props.dealerCodes,
      }
    ).then(async (response) => {
      const data = await response.json();
      setItemDetails(data);
      setMaxPage(Math.ceil(data.count / pageSize));
    });
    return data;
  }

  const getColor = (value: number) => {
    if (value > props.item.threshold2) return 'rgba(117,255,86,0.4)';
    if (value > props.item.threshold1) return 'rgba(255, 205, 86, 0.4)';
    return 'rgba(255, 99, 132, 0.4)';
  };

  useEffect(() => {
    fetchDetails().catch(e => console.error(e));
  }, [props.item, props.period, props.reportId, currentPage]);

  useEffect(() => {
    if (props.isDetailModalOpen) {
      fetchDetails().catch(e => console.error(e));
      return;
    }

    setCurrentPage(1);
    setMaxPage(1);
    setItemDetails({
      responseValues: [],
      textResponses: [],
      count: 0,
    });

  }, [props.isDetailModalOpen]);

  return (
    <Modal
      key="reportDetail"
      show={props.isDetailModalOpen}
      handleClose={() => props.setIsDetailModalOpen(false)}
      title={t('reports.details.title')}
      size="min-w-[640px]"
    >
      <div className={"pb-3"} >{t('reports.details.total_count')} {itemDetails.count}</div>
      {(itemDetails && itemDetails.responseValues && itemDetails.responseValues?.length > 0)
        ? <ul className={"border-b"}>
          {itemDetails?.responseValues?.map((item, index) =>
            <li key={index} className={"flex justify-between px-10 py-3 border border-b-0"}
              style={{  ...props.item.calculationType === 'likert'
                  ? {}
                  : { backgroundColor: getColor(Math.round(item.percentage * 100)/100)}
              }}
            >
              <div>{item.value}</div>
              <div className={"w-10 text-center"}>
                {Math.round(item.percentage * 100)/100}
                {props.item.calculationType === 'likert' ? '' : '%'}
              </div>
            </li>
          )}
        </ul>
        : <>
          <ul className={"border-b"}>
            {itemDetails?.textResponses?.map(item =>
              <li className={"px-2 py-2 border border-b-0"}>
                <b><a href={"/contacts/view/" + item.objectId}> {item.customerName}</a></b>
                <div>{item.value}</div>
              </li>
            )}
          </ul>
          <div className={"flex gap-2 float-right pt-4 px-1"}>
            <button
              onClick={() => changePage(-1)}
              style={{
                color: currentPage > 1 ? 'black': 'grey'
              }}
              disabled={currentPage <= 1}
            >
              <b>{t('reports.details.prev')}</b>
            </button>
            <div> {currentPage} </div>
            <button
              onClick={() => changePage(1)}
              style={{
                color: currentPage < maxPage ? 'black': 'grey'
              }}
              disabled={currentPage >= maxPage}
            >
              <b>{t('reports.details.next')}</b>
            </button>
          </div>
        </>
      }
    </Modal>
  )
}

export default SurveyReportTableDetailPopup;

import { Button, ButtonStyle, Modal, Textarea, } from '@suzuki-frontend-framework-update/ui';
import { environment } from '../../../environments/environment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { sendApiRequest } from '../send-api-request/send-api-request';
import { toast } from 'react-toastify';
import { DeactivatingReasonType } from "../../models/vehicle";
import { SelectInput } from "@sde/basic-ui-library";

export interface DeactivateVehiclePopupProps {
  setModalState: (state: boolean) => void;
  modalState: boolean;
  vehicleId: string;
  refreshData: () => void;
}

export const DeactivateVehiclePopup = (props: DeactivateVehiclePopupProps) => {
  const { t } = useTranslation();
  const [reason, setReason] = useState<string>(DeactivatingReasonType.EXPORTED)
  const [note, setNote] = useState("")
  const customIdFailed = 'custom-id-Failed';
  const customIdSuccess = 'custom-id-success';

  const successToast = (successText: string) => {
    toast.success(successText, {
      position: 'top-center',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      toastId: customIdSuccess,
    });
  };

  const errorToast = (errorText: string) => {
    toast.error(errorText, {
      position: 'top-center',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      toastId: customIdFailed,
    });
  };

  const handleCancel = () => {
    setReason(DeactivatingReasonType.EXPORTED);
    setNote('');
    props.setModalState(false);
  };

  const handleSubmit = () => {
    const requestUrl = `${environment.restEndpoint}/vehicle/vehicle-service/deactivate`;
    sendApiRequest(requestUrl, 'post', {
      vehicleId: props.vehicleId,
      deactivationDate: new Date(),
      reason: reason,
      note: note,
    }).then((response) => {
      if (response.ok) {
        successToast(t('deactivate-vehicle.successfully-deactivated'));
        handleCancel();
        if (props.refreshData) props.refreshData();
      } else {
        response.json().then((data) => {
          errorToast(t('error') + data.message);
        });
      }
    });
  }

  return (
    <div>
      <Modal
        key="deactivate-vehicle-modal"
        show={props.modalState}
        handleClose={() => handleCancel()}
        title={t('deactivate-vehicle.title')}
      >
        <div className="flex flex-col gap-y-6 pt-8 p-5">
          <div className="flex flex-col gap-y-6 items-end pr-28">
            <SelectInput
              options={[
                {
                  key: 0,
                  name: t('deactivate-vehicle.options.'+DeactivatingReasonType.EXPORTED),
                  value: DeactivatingReasonType.EXPORTED,
                },
                {
                  key: 1,
                  name: t('deactivate-vehicle.options.'+DeactivatingReasonType.STOLEN),
                  value: DeactivatingReasonType.STOLEN,
                },
                {
                  key: 2,
                  name: t('deactivate-vehicle.options.'+DeactivatingReasonType.SCRAPPED),
                  value: DeactivatingReasonType.SCRAPPED,
                },
              ]}
              value={reason}
              width="25rem"
              onSelect={(value) => setReason(value)}
              label={{
                name: t('deactivate-vehicle.reason'),
                position: 'left',
              }}
            />
              <Textarea
                value={note}
                onChange={(value) => setNote(value)}
                width="25rem"
                label={{
                  name: t('deactivate-vehicle.note'),
                  position: 'left',
                }}
                rows={8}
                resizeDisabled
              />
          </div>
          <div className="flex flex-row gap-x-6 justify-end">
            <Button
              className="bg-white"
              buttonStyle={ButtonStyle.OUTLINE}
              onClick={handleCancel}
              px="5"
              py="3"
            >
              {t('deactivate-vehicle.cancel')}
            </Button>
            <Button onClick={handleSubmit} px="5" py="3">
              {t('deactivate-vehicle.submit')}
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  )
}

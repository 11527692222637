import React from 'react';

import './headline.module.scss';

export type HeadlineProps = {
  title: string;
  className?: string;
  color?: string;
};

export const Headline = (props: HeadlineProps) => {
  return (
    <h1
      className={`${props.className} font-suzuki-headline text-${
        props.color ? props.color : 'dark'
      }`}
      style={{ fontSize: '36px' }}
    >
      {props.title}
    </h1>
  );
};

export default Headline;

import {
  BasicInput,
  BasicOutput,
  Button,
  ButtonStyle,
  Checkbox,
  Headline,
  Multiselect,
  MultiselectOptionsProps,
} from '@suzuki-frontend-framework-update/ui';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import './register.module.scss';
import { environment } from '../../../environments/environment';
import { useApiGetData } from '../../components/useApiGetData/useApiGetData';
import { Dealer } from '../../models/dealer';

export interface RegisterValue {
  username: string;
  firstname: string;
  lastname: string;
  email: string;
  csrf_token: string;
  password: string;
  isAdmin: boolean;
  isAreaAdmin: boolean;
  dealerIDs: Array<MultiselectOptionsProps>;
}

export interface RegisterRequestBody {
  user: RegisterRequestUser;
}

export interface RegisterRequestUser {
  state: string;
  traits: RegisterUserTraits;
  password: string;
  id?: string;
}

export interface AuthDealer {
  id: string;
  dealercode: string;
}

export interface RegisterUserTraits {
  email: string;
  name: RegisterUserTraitsName;
  username: string;
  isAdmin: boolean;
  isAreaAdmin: boolean;
  dealers: AuthDealer[];
}

export interface RegisterUserTraitsName {
  first: string;
  last: string;
}

export interface FormError {
  [index: string]: string;
}

export function Register() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [formValues, setFormValues] = useState({
    // active: false,
    username: '',
    firstname: '',
    lastname: '',
    email: '',
    password: '',
    isAdmin: false,
    isAreaAdmin: false,
  } as RegisterValue);
  const dealerData = useApiGetData<Dealer[]>(
    `${environment.restEndpoint}/dealer/dealer-service/dealers`,
    'post',
    'dealers'
  );
  const [dealers, setDealers] = useState<Dealer[]>();
  const [formDealers, setFormDealers] = useState<MultiselectOptionsProps[]>();
  const [formErrors, setFormErrors] = useState({} as FormError);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (dealerData.status !== 200) {
      return;
    }
    if (!dealers) {
      setDealers(dealerData.data);
      const toSelectOptions = (
        dealers: Dealer[] | undefined
      ): MultiselectOptionsProps[] => {
        if (dealers && dealers.length > 0) {
          return dealers.map((d, i) => {
            return {
              key: d.objectID,
              name: `${d.name} ${d.nameAppendum} (${d.dealercode})`,
              value: d.dealercode,
            } as MultiselectOptionsProps;
          });
        } else {
          return [];
        }
      };
      const options = toSelectOptions(dealerData.data);
      setFormDealers(options);
    }
  }, [dealerData]);

  const handleChange = (prop: string, value: string) => {
    setFormValues({ ...formValues, [prop]: value });
  };

  const handleCheckboxChange = (prop: string) => {
    setFormValues((prev: RegisterValue) => {
      return { ...formValues, [prop]: !prev[prop as keyof RegisterValue] };
    });
  };

  const handleDealerChange = (dealerList: MultiselectOptionsProps[]) => {
    setFormValues({ ...formValues, dealerIDs: dealerList });
  };

  const handleCancel = () => {
    navigate('/admin/users');
  };

  const handleSubmit = async () => {
    setFormErrors({} as FormError);
    if (!formValues.email) {
      setFormErrors({ email: t('register.error.email-required') });
      return;
    }
    if (!validateEmail(formValues.email)) {
      setFormErrors({ email: t('register.error.email-invalid') });
      return;
    }
    if (!formValues.username) {
      setFormErrors({ username: t('register.error.username-required') });
      return;
    }
    if (!formValues.password) {
      setFormErrors({ password: t('register.error.password-required') });
      return;
    }
    let dealers = [] as Array<AuthDealer>;
    if (formValues && formValues.dealerIDs && formValues.dealerIDs.length > 0) {
      dealers = formValues.dealerIDs.map((d) => {
        return {
          id: d.key,
          dealercode: d.value,
        } as AuthDealer;
      });
    }
    const sendData = {
      user: {
        state: 'active',
        password: formValues.password,
        traits: {
          email: formValues.email,
          name: {
            first: formValues.firstname,
            last: formValues.lastname,
          },
          username: formValues.username,
          isAdmin: formValues.isAdmin,
          isAreaAdmin: formValues.isAreaAdmin,
          dealers: dealers,
        },
      },
    } as RegisterRequestBody;
    //console.log(sendData);
    try {
      const resp = await fetch(`${environment.restEndpoint}/user/users`, {
        method: 'POST',
        // credentials: "include",
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          apikey: 'p0zLmdPds51XKPtTTc05z0L0',
        },
        body: JSON.stringify(sendData),
      });
      const body = await resp.json();
      if (!resp.ok) {
        // it returned an error
        setErrorMessage(`Error ${body.code}: ${body.message}`);
        return;
      }
      navigate('/admin/users');
    } catch (e) {
      console.error(e);
    }
  };

  const validateEmail = (email: string): RegExpMatchArray | null => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const rowWidth = '70rem';
  const rightOffset = '32.75rem';
  return (
    <div className="grow bg-gray p-8 flex flex-col gap-y-8 h-full">
      <Headline title={t('register.title')} />
      <BasicOutput title={t('register.basic-data')}>
        <form>
          <div className={'text-red-500 p-4 text-center'}>{errorMessage}</div>
          <div
            className={`flex flex-col gap-y-10 pb-5`}
            style={{ width: rowWidth, paddingRight: rightOffset }}
          >
            {/*<span style={{ paddingRight: "22.875rem" }}>*/}
            {/*  <Checkbox*/}
            {/*    width="8"*/}
            {/*    label={t("register.active")}*/}
            {/*    checked={formValues.active}*/}
            {/*    onChange={handleActivChange} />*/}
            {/*</span>*/}
            <BasicInput
              width="25rem"
              label={{ name: t('register.username'), position: 'top' }}
              onChange={(e) => handleChange('username', e.target.value)}
              error={formErrors['username']}
              required={true}
            />
          </div>
          <div
            className="flex flex-row gap-x-8 py-5"
            style={{ width: rowWidth }}
          >
            <BasicInput
              width="25rem"
              label={{ name: t('register.firstname'), position: 'top' }}
              onChange={(e) => handleChange('firstname', e.target.value)}
            />
            <BasicInput
              width="25rem"
              label={{ name: t('register.lastname'), position: 'top' }}
              onChange={(e) => handleChange('lastname', e.target.value)}
            />
          </div>
          <div
            className={`flex flex-col gap-y-10 pt-5`}
            style={{ width: rowWidth, paddingRight: rightOffset }}
          >
            <BasicInput
              width="25rem"
              label={{ name: t('register.email'), position: 'top' }}
              onChange={(e) => handleChange('email', e.target.value)}
              type="email"
              required={true}
              error={formErrors['email']}
            />
            <BasicInput
              width="25rem"
              label={{ name: t('register.password'), position: 'top' }}
              onChange={(e) => handleChange('password', e.target.value)}
              type="password"
              error={formErrors['password']}
              required={true}
            />
            <Checkbox
              label={t('register.isAdmin')}
              rightLabel={true}
              checked={formValues.isAdmin}
              onChange={(e) => handleCheckboxChange('isAdmin')}
            />
            <Checkbox
              label={t('register.isAreaAdmin')}
              rightLabel={true}
              checked={formValues.isAreaAdmin}
              onChange={(e) => handleCheckboxChange('isAreaAdmin')}
            />
            {formDealers && (
              <Multiselect
                options={formDealers}
                values={[]}
                label={{name: t('register.dealer')}}
                displayToken={'name'}
                onSelect={(selectedProps) => handleDealerChange(selectedProps)}
              />
            )}
          </div>
        </form>
      </BasicOutput>
      <div className="w-full flex flex-row justify-end gap-x-5">
        <Button
          buttonStyle={ButtonStyle.OUTLINE}
          onClick={handleCancel}
          px="5"
          py="4"
          className="bg-white"
        >
          {t('register.cancel')}
        </Button>
        <Button
          buttonStyle={ButtonStyle.DEFAULT}
          onClick={handleSubmit}
          px="5"
          py="4"
        >
          {t('register.submit')}
        </Button>
      </div>
    </div>
  );
}

export default Register;

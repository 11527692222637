
export interface Receiver {
  id?: number;
  questionnaireType?: number;
  eventDate?: Date;
  eventType?: number;
  eventId?: string;
  dealerCode?: string;
  status?: number;
  sendoutDate?: Date;
  sendoutErrors?: string;
  sendoutMethod?: string;
  contact: IntermediateContact;
  vehicle?: IntermediateVehicle;
  progressState: ProgressState;
}

export enum ProgressState {
  NOT_SENT = 'NOT_SENT',
  NOT_STARTED = 'NOT_STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
}
export enum SendoutStatusFilter {
  SENDOUT_NOT_POSSIBLE = 'SENDOUT_NOT_POSSIBLE',
  EMAIL = 'EMAIL',
  POST = 'POST',
}
export enum SendoutStatus {
  SEND = 2,
  FAILED = 3,
  VALIDATION = 4,
  INVITED = 5,
  QUEUED_EMAIL = 6,
  QUEUED_POST = 7,
  INVALID = 8,
}

export interface IntermediateVehicle {
  id?: number;
  objectId?: string;
  vin?: string;
  firstRegistrationDate?: Date;
  brand?: string;
  model?: string;
  licenseNumber?: string;
}

export interface IntermediateContact {
  id?: number;
  objectId?: string;
  isCompany?: boolean;
  salutation?: string;
  name?: string;
  firstname?: string;
  email?: string;
  phone?: string;
  countryCode?: string;
  zip?: string;
  city?: string;
  street?: string;
}

export interface SurveysRequest {
  filter?: SurveysFilters;
  pageSize?: number;
  pageIndex?: number;
  // sortField?: Field;
  // sortDesc?: boolean;
}

export interface SurveysFilters {
  search?: string;
  typeId?: string;
  progressStates?: ProgressState[];
  sendOutStatus?: SendoutStatusFilter[];
  dealerCodes?: string[];
}

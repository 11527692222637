import React, { FunctionComponent } from 'react';
import { Outlet } from 'react-router-dom';
import Footer from '../../components/footer/footer';

export const RedirectionLayout: FunctionComponent = () => {
  return (
    <div className="min-h-screen w-full flex flex-col justify-between bg-white">
      <div className='ml-10 mt-10'>
        <img alt='Suzuki logo' src='https://signatur.suzuki.de/logos/suzuki-logo.jpg' width='175px'/>
      </div>
      <div className="m-auto">
        <div
          className="text-dark bg-white p-15 flex flex-col items-center gap-y-10 mb-40"
          style={{minHeight: '26rem', width: '43rem'}}
        >
          <Outlet/>
        </div>
      </div>
      <Footer />
    </div>
  );
};

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export const Support = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="w-full md:w-96 md:max-w-full mx-auto  font-suzuki-regular">
        <div className="p-6 border  sm:rounded-md">
          <div className="block mb-4 font-suzuki-bold">
            <span className="block  ">{t('support.title')}</span>
          </div>
          <div className="block mb-2">
            <span className="block font-suzuki-bold">
              {t('support.phone-hotline')}:
            </span>
            <span>030 - 44 35 19 49 - 99 (Mo. - Fr.: 09:00 - 16:00 Uhr)</span>
          </div>
          <div className="block mb-2">
            <span className="block font-suzuki-bold">
              {t('support.support-email')}
            </span>
            <div>
              <span>Moto: </span>
              <span>suzuki-motorrad@media-impuls.com</span>
            </div>
            <div>
              <span>Auto: </span>
              <span>suzuki-service@media-impuls.com</span>
            </div>
          </div>
          <div className="block mb-2">
            <span className="block  font-suzuki-bold">
              {t('support.support-fax')}
            </span>
            <span>030 - 44 35 19 49 - 50</span>
          </div>
          <Link to="/" className="underline text-dark decoration-dark">
            {t('support.back-to-site')}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Support;

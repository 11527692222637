import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../setup'
import { VehicleFilter } from "../../models/vehicle";

export const VEHICLE_FILTER_FEATURE_KEY = 'vehicle-filter';

export interface VehicleFilterState {
  key: string;
  currentRow: string | undefined;
  vehicleFilter: VehicleFilterData;
}

export interface VehicleFilterData {
  filter: VehicleFilter;
  pageIndex: number;
}

export const initialVehicleFilterState: VehicleFilterState = {
  key: "",
  currentRow: undefined,
  vehicleFilter: {
    filter: { showDeactivatedVehicles: false, showHiddenVehicles: false, dealercodes: [], vehicleModels: [] },
    pageIndex: 0
  }
};

const vehicleFilterSlice = createSlice({
  name: VEHICLE_FILTER_FEATURE_KEY,
  initialState: initialVehicleFilterState,
  reducers: {
    init: (state, { payload: key }: PayloadAction<string>) => {
      state.key = key;
      state.currentRow = undefined;
      state.vehicleFilter = {
        filter: { showDeactivatedVehicles: false, showHiddenVehicles: false, dealercodes:[], vehicleModels: []},
        pageIndex: 0
      };
    },
    updateVehicleFilter: (state, { payload: vehicleFilter }: PayloadAction<VehicleFilter>) => {
      state.vehicleFilter.filter = vehicleFilter;
    },
    updateVehiclePageIndex: (state, { payload: pageIndex }: PayloadAction<number>) => {
      state.vehicleFilter.pageIndex = pageIndex;
    },
    updateVehicleCurrentRow: (state, { payload: currentRow }: PayloadAction<string | undefined>) => {
      state.currentRow = currentRow;
    },
  }
});

export const vehicleFilterActions = vehicleFilterSlice.actions;

export const vehicleFilterSelector = (state: RootState) => state[VEHICLE_FILTER_FEATURE_KEY]

export const vehicleFilterReducer = vehicleFilterSlice.reducer;

import { Sidebar } from '@suzuki-frontend-framework-update/ui';
import './users-config.module.scss';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

export function UsersConfig() {
  const { t } = useTranslation();

  return (
    <Sidebar
      groups={[
        {
          title: t('user-config.users-groups'),
          items: [
            {
              name: t('user-config.registered-users'),
              path: 'users/registered',
            },
            // {
            //   name: t('user-config.logged-users'),
            //   path: 'users/logged',
            // },
            // {
            //   name: t('user-config.groups'),
            //   path: 'users/groups',
            // },
            /*  {
              name: t('user-config.policies'),
              path: 'users/policies',
            }, */
          ],
        },
        {
          title: t('user-config.dealers'),
          items: [
            {
              name: t('user-config.imported-dealers'),
              path: 'dealers/imported',
            },
          ],
        },
        // {
        //   title: t('user-config.contact-pools'),
        //   items: [
        //     {
        //       name: t('user-config.pools-list'),
        //       path: 'contacts/pools',
        //     },
        //   ],
        // },
      ]}
    >
      <Outlet />
    </Sidebar>
  );
}

export default UsersConfig;

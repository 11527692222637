import './react-table-config.d.ts';
import React from 'react';
import { t } from 'i18next';

// import tw from 'twin.macro';
// import styled from 'styled-components';
import {
  Column,
  ColumnInstance,
  Row,
  UseColumnOrderState,
  UseExpandedState,
  useFilters,
  UseFiltersColumnProps,
  UseFiltersState,
  useGlobalFilter,
  UseGlobalFiltersState,
  UseGroupByState,
  usePagination,
  UsePaginationState,
  UseResizeColumnsState,
  useRowSelect,
  UseRowSelectState,
  UseRowStateState,
  useSortBy,
  UseSortByColumnProps,
  UseSortByState,
  useTable,
} from 'react-table';
import { matchSorter } from 'match-sorter';
import IndeterminateCheckbox from './IndeterminateCheckbox';
import {
  ButtonArea,
  NavButton,
  Pagination,
  TableCell,
  TableContentContainer,
  TableHeader,
  TableRow,
  TTable,
} from './default-components';
import Spinner from '../spinner/spinner';
import { CheckboxStatus } from './table';

// import './table.css';

/*
  debounce function for search, to relive stress on cpu
*/
export const debounce = <F extends (...args: any[]) => any>(
  func: F,
  waitFor: number
) => {
  let timeout: ReturnType<typeof setTimeout> | null = null;

  const debounced = (...args: Parameters<F>) => {
    if (timeout !== null) {
      clearTimeout(timeout);
      timeout = null;
    }
    timeout = setTimeout(() => func(...args), waitFor);
  };

  return debounced as (...args: Parameters<F>) => ReturnType<F>;
};

// eslint-disable-next-line @typescript-eslint/ban-types
interface TableColumn<D extends object = {}>
  extends ColumnInstance<D>,
    UseSortByColumnProps<D>,
    UseFiltersColumnProps<D> {}

export interface TableState<D extends object>
  extends UseColumnOrderState<D>,
    UseExpandedState<D>,
    UseFiltersState<D>,
    UseGlobalFiltersState<D>,
    UseGroupByState<D>,
    UsePaginationState<D>,
    UseResizeColumnsState<D>,
    UseRowSelectState<D>,
    UseRowStateState<D>,
    UseSortByState<D> {}

export interface GlobFilter {
  preGlobalFilteredRows: Array<Row>;
  globalFilter: string;
  setGlobalFilter: (filterValue: string) => void;
}

export interface ColGlobFilter {
  column: {
    filterValue: string;
    preFilteredRows: Array<Row>;
    setFilter: (filterValue: string | undefined) => void;
  };
}

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}: GlobFilter) {
  const countuseAsyncDebounce = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = debounce((value) => {
    setGlobalFilter(value || undefined);
  }, 1500);

  return (
    <span>
      Suche:{' '}
      <input
        value={value || ''}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`Bitte Suchbegriff eingeben`}
        style={{
          fontSize: '1.1rem',
          border: '0',
        }}
      />
    </span>
  );
}

// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}: ColGlobFilter) {
  const count = preFilteredRows.length;

  return (
    <input
      value={filterValue || ''}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      placeholder={`Suche in ${count} Einträgen...`}
    />
  );
}

function fuzzyTextFilterFn(
  rows: Array<Row>,
  columnIds: Array<string>,
  filterValue: string
) {
  /* eslint-disable-next-line */
  return matchSorter(rows, filterValue, {
    keys: [(row: Row) => row.values[columnIds[0]]],
  });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val: string) => !val;

/* eslint-disable-next-line */
export interface PaginationTableProps {
  columns: Array<Column<any>>;
  data: any;
  initialPage?: number;
  initPageSize?: number;
  basic?: boolean;
  loading?: boolean;
  handleRowClick?: (row: Row<any>) => void;
  disabledClickForColumns?: string[];
  handleRowSelect?: (row: Row<any>) => void;
  handleAllRowsSelect?: () => void;
  customSelectedRowIds?: Record<string, boolean>;
  allSelectedStatus?: CheckboxStatus;
  disableSelection?: (row: Row<any>) => boolean;
  /**
   *  determine the amount of rows available
   */
  rowCount?: number;
  countLabel?: string;
  selectTitle?: string;
  allSelectTitle?: string;
}

export const PaginationTable = ({
  columns,
  data,
  initialPage = 0,
  initPageSize = 100,
  basic = false,
  loading,
  handleRowClick,
  disabledClickForColumns,
  rowCount,
  countLabel = 'Entries',
  handleRowSelect,
  customSelectedRowIds,
  handleAllRowsSelect,
  allSelectedStatus,
  disableSelection,
  selectTitle = t('table.select-title'),
  allSelectTitle = t('table.all-select-title'),
}: PaginationTableProps) => {
  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
      data,
      initialState: {
        pageIndex: initialPage,
        pageSize: initPageSize,
        selectedRowIds: customSelectedRowIds ? customSelectedRowIds : {},
      },
      basic,
      customSelectedRowIds,
      allSelectedStatus,
    },
    (hooks) => {
      hooks.visibleColumns.push((columns) =>
        basic
          ? [
              {
                Header: '',
              },
              ...columns,
            ]
          : [
              // Let's make a column for selection
              {
                id: 'selection',
                // The header can use the table's getToggleAllRowsSelectedProps method
                // to render a checkbox
                Header: ({ getToggleAllRowsSelectedProps }) => (
                  <div>
                    <IndeterminateCheckbox
                      {...{
                        ...getToggleAllRowsSelectedProps(),
                        title: allSelectTitle,

                        checked: allSelectedStatus
                          ? allSelectedStatus.checked
                          : getToggleAllRowsSelectedProps().checked,

                        indeterminate: allSelectedStatus
                          ? allSelectedStatus.indeterminate
                          : getToggleAllRowsSelectedProps().indeterminate,
                        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                          const props = getToggleAllRowsSelectedProps();
                          if (props && props.onChange) {
                            props.onChange(e);
                          }
                          if (handleAllRowsSelect) {
                            handleAllRowsSelect();
                          }
                        },
                      }}
                    />
                  </div>
                ),
                Cell: ({ row }) => {
                  return (
                    <div>
                      <IndeterminateCheckbox
                        {...{
                          ...row.getToggleRowSelectedProps(),
                          title: selectTitle,

                          checked: customSelectedRowIds
                            ? customSelectedRowIds[row.id] === true
                            : row.getToggleRowSelectedProps().checked,
                          onChange: (
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            const props = row.getToggleRowSelectedProps();
                            if (props && props.onChange) {
                              props.onChange(e);
                            }
                            if (handleRowSelect) {
                              handleRowSelect(row);
                            }
                          },
                          disabled: disableSelection
                            ? disableSelection(row)
                            : false,
                        }}
                      />
                    </div>
                  );
                },
              },
              ...columns,
            ]
      );
    },
    useFilters, // useFilters!
    useGlobalFilter, // useGlobalFilter!
    useSortBy,
    usePagination,
    useRowSelect
  );

  const getPaginationElement = (i: number, position: string) => {
    if (
      i === 0 ||
      i === pageOptions.length - 1 ||
      (i - pageIndex > -3 && i - pageIndex < 2) ||
      (pageIndex < 4 && i < 4)
    )
      return (
        <NavButton
          key={i + position}
          onClick={() => gotoPage(i)}
          styled={i === pageIndex}
          disabled={i === pageIndex}
        >
          {i + 1}
        </NavButton>
      );
    else if (i === 1 || i === pageOptions.length - 2)
      return (
        <NavButton key={i + position} disabled>
          {'...'}
        </NavButton>
      );
    else return null;
  };

  return (
    <div>
      <div className="flex justify-between sticky top-16 z-50 bg-gray">
        <div className={'flex justify-items-center items-center pl-2'}>
          {rowCount ? `${rowCount} ${countLabel}` : ''}
        </div>
        <Pagination>
          <ButtonArea>
            <NavButton
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
              styled
            >
              {'<'}
            </NavButton>
            {Array.from({ length: pageOptions.length }, (_, i) =>
              getPaginationElement(i, 'top')
            )}
            <NavButton
              onClick={() => nextPage()}
              disabled={!canNextPage}
              styled
            >
              {'>'}
            </NavButton>
          </ButtonArea>
        </Pagination>
      </div>
      <TableContentContainer>
        <TTable {...getTableProps()}>
          <thead className="sticky top-[6.5rem]">
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <TableHeader
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render('Header')}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? ' 🔽'
                          : ' 🔼'
                        : ''}
                    </span>
                  </TableHeader>
                ))}
              </TableRow>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <TableRow {...row.getRowProps()} key={row.id}>
                  {row.cells.map((cell) => {
                    if (cell.column.id !== 'selection' &&
                      (!disabledClickForColumns ||
                        !disabledClickForColumns.includes(cell.column.id)) &&
                      handleRowClick) {
                      return (
                        <TableCell
                          onClick={() => handleRowClick(row)}
                          {...cell.getCellProps()}
                        >
                          {cell.render('Cell')}
                        </TableCell>
                      );
                    } else {
                      return (
                        <TableCell {...cell.getCellProps()}>
                          {cell.render('Cell')}
                        </TableCell>
                      );
                    }
                  })}
                </TableRow>
              );
            })}
          </tbody>
        </TTable>
        {loading ? (
          <div className="my-4 w-full">
            <Spinner />
          </div>
        ) : null}
      </TableContentContainer>
      <Pagination>
        <ButtonArea>
          <NavButton
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
            styled
          >
            {'<'}
          </NavButton>
          {Array.from({ length: pageOptions.length }, (_, i) =>
            getPaginationElement(i, 'bootom')
          )}
          <NavButton onClick={() => nextPage()} disabled={!canNextPage} styled>
            {'>'}
          </NavButton>
        </ButtonArea>
      </Pagination>
    </div>
  );
};

export default PaginationTable;

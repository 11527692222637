import ReactSpeedometer from 'react-d3-speedometer';

export interface WidgetGaugeProps {
  /**
   * The text above the Guage Widget
   */
  text: string;
  /**
   * The current value of the Guage
   */
  value: number;
  /**
   * An array specifying the length of each individual arc.
   * eg [0, 20, 50, 100] = 0-20,20-50,50-100
   */
  valueRange: number[];
  className?: string;
  minValue?: number;
  maxValue?: number;
  /**
   * If true takes the width of the parent component.
   * @param {boolean} [fluidWidth=false] default is true
   */
  fluidWidth?: boolean;
}

const Gauge = (props: WidgetGaugeProps) => {
  const colors = ['#F7403A', '#FFA101', '#34B233'];
  function getNeedleColor() {
    if (props.valueRange[1] >= props.value) {
      return colors[0];
    } else if (props.valueRange[2] >= props.value) {
      return colors[1];
    } else if (props.valueRange[3] >= props.value) {
      return colors[2];
    } else {
      return colors[2];
    }
  }

  return (
    <ReactSpeedometer
      width={300}
      paddingHorizontal={10}
      minValue={props.minValue ? props.minValue : 0}
      maxValue={props.maxValue ? props.maxValue : 100}
      value={props.value}
      needleColor={getNeedleColor()}
      segmentColors={colors}
      needleHeightRatio={0.6}
      ringWidth={40}
      customSegmentStops={props.valueRange}
      fluidWidth={props.fluidWidth ? props.fluidWidth : false}
    />
  );
};

export const WidgetGauge = (props: WidgetGaugeProps) => {
  return (
    <div className={`${props.className ? props.className : ''}`}>
      <div className={`bg-gray`}>
        <div className="p-4">
          <span className="font-suzuki-regular text-dark text-sm ">
            {props.text}
          </span>
        </div>
        <div
          className="p-4 my-0 flex justify-center"
          style={{ minHeight: '210px', maxHeight: '210px' }}
        >
          <Gauge {...props}/>
        </div>
      </div>
    </div>
  );
};

export default WidgetGauge;

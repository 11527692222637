import { Button, ButtonStyle, Icon, Modal, Textarea, } from '@suzuki-frontend-framework-update/ui';
import { environment } from '../../../environments/environment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { sendApiRequest } from '../send-api-request/send-api-request';
import { toast } from 'react-toastify';
import {Collections, SelectInput} from "@sde/basic-ui-library";

export interface AddActionPopupProps {
  setModalState: (state: boolean) => void;
  modalState: boolean;
  contactId?: string | 'undefinded';
  vehicleId?: string | 'undefinded';
  refreshData?: () => void;
  contexts: { id: string, type: string }[];
}

export const AddActionPopup = (props: AddActionPopupProps) => {
  const { t } = useTranslation();
  const [type, setType] = useState('conversationLog');

  const [note, setNote] = useState('');
  const customIdFailed = 'custom-id-Failed';
  const customIdSuccess = 'custom-id-success';

  type ActionType = 'note' | 'conversationLog';

  const typeTranslations: Record<ActionType, string> = {
    note: t('add-action.options.note'),
    conversationLog: t('add-action.options.conversation-log'),
  };

  const successToast = (successText: string) => {
    toast.success(successText, {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      toastId: customIdSuccess,
    });
  };

  const errorToast = (errorText: string) => {
    toast.error(errorText, {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      toastId: customIdFailed,
    });
  };

  const handleCancel = () => {
    setType('conversationLog');
    setNote('');
    props.setModalState(false);
  };

  const handleSubmit = () => {
    if (note !== '') {
      const translatedType = typeTranslations[type as ActionType];
      sendApiRequest(`${environment.restEndpoint}/action/action`, 'post', {
        title: translatedType,
        message: note,
        actionDate: new Date(),
        contactId: props.contactId,
        vehicleId: props.vehicleId,
        contexts: props.contexts,
        referenceCollection: Collections.UNDEFINED,
      }).then((response) => {
        if (response.ok) {
          successToast(t('success'));
          handleCancel();
          if (props.refreshData) props.refreshData();
        } else {
          errorToast(t('error') + response.statusText);
        }
      });
    } else {
      errorToast('Notiz fehlt');
    }
  };

  return (
    <div>
      <Button
        className="h-fit w-fit"
        py="1"
        px="1"
        onClick={() => props.setModalState(true)}
      >
        <Icon iconName="event_note" className="text-xl w-7" />
      </Button>
      <Modal
        key="add-action-modal"
        show={props.modalState}
        handleClose={() => handleCancel()}
        title={t('add-action.title')}
      >
        <div className="flex flex-col gap-y-6 pt-8 p-5">
          <div className="flex flex-col gap-y-6 items-end pr-28">
            <SelectInput
              options={[
                {
                  key: 0,
                  name: t('add-action.options.conversation-log'),
                  value: 'conversationLog',
                },
                {
                  key: 1,
                  name: t('add-action.options.note'),
                  value: 'note',
                },
              ]}
              value={type}
              width="20rem"
              onSelect={(value) => setType(value)}
              label={{
                name: t('add-action.action-type'),
                position: 'left',
              }}
            />
            <Textarea
              value={note}
              onChange={(value) => setNote(value)}
              width="20rem"
              label={{
                name: t('add-action.note'),
                position: 'left',
              }}
              rows={8}
              resizeDisabled
            />
          </div>
          <div className="flex flex-row gap-x-6 justify-end">
            <Button
              className="bg-white"
              buttonStyle={ButtonStyle.OUTLINE}
              onClick={handleCancel}
              px="5"
              py="3"
            >
              {t('add-action.cancel')}
            </Button>
            <Button onClick={handleSubmit} px="5" py="3">
              {t('add-action.submit')}
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AddActionPopup;

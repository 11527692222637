import React, { Children, MouseEventHandler } from 'react';

/*
 * Basic Definitions
 * Here are all Basic Conponents and their Styles defined
 * All Components are based on tailwind css
 */

export enum ButtonStyle {
  DEFAULT,
  OUTLINE,
  TEXT,
}

export type ButtonProps = {
  primary?: boolean;
  buttonStyle?: ButtonStyle;
  disabled?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement> | undefined;
  children?: React.ReactNode;
  className?: string;
  px?: string;
  py?: string;
};

/**
 * Displays a button with pre-defined type (primary, outline)
 * if primary or outline prop is ommited a secondary button is rendered
 */
export const Button = (props: ButtonProps) => {
  const defaultButton = (
    <button
      className={`${props.className} flex ${
        Children.count(props.children) === 1
          ? 'justify-center'
          : 'justify-between'
      } h-10 items-center text-base text-white outline-none border-2 cursor-pointer
      ${props.primary ? 'bg-blue-900 border-blue-900' : 'bg-dark border-dark'}
      px-${props.px ? props.px : '4'} py-${props.py ? props.py : '2'}`}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.children}
    </button>
  );
  switch (props.buttonStyle) {
    case ButtonStyle.DEFAULT:
      return defaultButton;
    case ButtonStyle.OUTLINE:
      return (
        <button
          className={`${props.className} flex ${
            Children.count(props.children) === 1
              ? 'justify-center'
              : 'justify-between'
          } h-10 items-center text-base text-dark outline-none border-2 border-solid cursor-pointer ${
            props.primary ? 'border-dark' : 'border-dark'
          }
            px-${props.px ? props.px : '4'} py-${props.py ? props.py : '2'}`}
          onClick={props.onClick}
          disabled={props.disabled}
        >
          {props.children}
        </button>
      );
    case ButtonStyle.TEXT:
      return (
        <button
          className={`${props.className} flex ${
            Children.count(props.children) === 1
              ? 'justify-center'
              : 'justify-between'
          } h-10 items-center text-base outline-none border-none cursor-pointer bg-white hover:underline ${
            props.primary ? 'text-blue-900' : 'text-dark'
          }
            px-${props.px ? props.px : '4'} py-${props.py ? props.py : '2'}`}
          onClick={props.onClick}
          disabled={props.disabled}
        >
          {props.children}
        </button>
      );
    default:
      return defaultButton;
  }
};

export default Button;

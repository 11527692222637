import {
  DataInfoContainer,
  DataInfoItemValue,
} from '@suzuki-frontend-framework-update/ui';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import {
  showCreateCustomerDpeEntry,
  showCustomerDpeHistory,
} from '../../components/dpd/dpd';
import { sendApiRequest } from '../../components/send-api-request/send-api-request';
import { AttributeResponse, displayableConsents } from '../../models/dpd';
import { Session } from '@ory/client';
import { environment } from '../../../environments/environment';
import { Company, Contact } from '../../models/contact';
import { ApiResponse } from '../../components/useApiGetData/useApiGetData';
import { Dealer } from '../../models/dealer';

export interface PrivacyDataReleasesProps {
  contactData?: Company | Contact;
  session: Session;
  isCompany: boolean;
}

export const PrivacyDataReleases = (props: PrivacyDataReleasesProps) => {
  const [privacyData, setPrivacyData] = useState<AttributeResponse[]>([]);

  useEffect(() => {
    if (props.contactData) {
      const dpdRequest = getDpdParams(props.session, props.contactData, props.isCompany);
      sendApiRequest(
        `${environment.dpd.baseUrl}/rest/get-customer-attributes`,
        'post',
        dpdRequest,
        new Map([['Content-type', 'application/json']])
      )
        .then((response) => response.json())
        .then((data) => {
          setPrivacyData(data);
        });
    }
    return () => setPrivacyData([]);
  }, [props.contactData]);

  const getPrivacyValues = (
    isPrivate: boolean,
    isAdmin: boolean
  ): DataInfoItemValue[] => {
    const result = [];
    const prefix = isAdmin ? 'suzuki-' : '';
    const sufix = isPrivate ? '-private' : '-business';
    if (privacyData && privacyData.length > 0) {
      for (const item of privacyData) {
        if (
          item.name.startsWith(prefix) &&
          item.name.endsWith(sufix) &&
          item.value === 'true'
        ) {
          const searchingName = item.name
            .replace(prefix, '')
            .replace(sufix, '');
          if (isDisplayable(searchingName)) {
            result.push(t('contact-details.dpd.' + searchingName));
          }
        }
      }
      result.sort();
      return [
        {
          value:
            result.length > 0
              ? result.join(', ')
              : t('contact-details.dpd.no-releases'),
        },
      ];
    } else {
      return [{ value: t('contact-details.dpd.no-releases') }];
    }
  };

  const isDisplayable = (name: string): boolean => {
    return displayableConsents.includes(name);
  };

  return (
    <DataInfoContainer
      title={t('contact-details.dpd.title')}
      items={[
        {
          name: t('contact-details.dpd.private'),
          values: getPrivacyValues(
            true,
            props.session.identity?.traits.isAdmin
          ),
        },
        {
          name: t('contact-details.dpd.business'),
          values: getPrivacyValues(
            false,
            props.session.identity?.traits.isAdmin
          ),
        },
      ]}
      linksAfterValues={[
        {
          name: t('contact-details.dpd.new-entry'),
          onClick: () => {
            showCreateCustomerDpeEntry(
              getDpdParams(props.session, props.contactData, props.isCompany)
            );
          },
        },
        {
          name: t('contact-details.dpd.show-history'),
          onClick: () => {
            showCustomerDpeHistory(
              getDpdParams(props.session, props.contactData, props.isCompany)
            );
          },
        },
      ]}
    />
  );
};

export const getDpdParams = (
  session: Session,
  contactData: Company | Contact | undefined,
  isCompany: boolean
) => {
  return {
    isAdmin: session.identity?.traits.isAdmin,
    dealercode: session.identity?.traits.isAdmin
      ? null
      : session.identity?.traits?.dealers?.map((d: Dealer) => d.dealercode),
    base: environment.dpd.baseUrl + '/',
    origin: 'CRM',
    apiKey: environment.apiKey,
    authentication: {
      ssotoken: btoa(environment.dpd.token),
      applicationkey: environment.dpd.crmApiKey,
      username: environment.dpd.username,
    },
    customer: {
      id: contactData?.objectId,
      salutation: contactData?.salutation,
      firstName: !isCompany ? (contactData as Contact)?.firstname : undefined,
      lastName: !isCompany ? contactData?.name : undefined,
      company: isCompany ? contactData?.name : undefined,
      state: contactData?.customerstate,
      customerNumber: contactData?.customerNumber,
      street: contactData?.address?.street,
      city: contactData?.address?.city,
      zip: contactData?.address?.zip,
      phone: contactData?.phone?.find((el) => el.default === true)
        ?.phoneNumber,
      email: contactData?.email?.find((el) => el.default === true)?.email,
    },
  };
};

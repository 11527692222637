import { toast, ToastOptions } from "react-toastify";
import { Badge, Button, ButtonStyle, DatePickerInput, Modal, Textarea } from "@sde/basic-ui-library";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { ScheduleTestDriveRequest, TestDriveData } from "../../models/test-drive";
import { environment } from "../../../environments/environment";
import { sendApiRequest } from "../send-api-request/send-api-request";
import { format } from "react-string-format";
import { CustomerType } from "../../models/contact";
import { base64StringToBlob } from "blob-util";
import download from "downloadjs";
import {TestDriveInformation} from "../test-drive-information/test-drive-information";

export interface ScheduleTestDrivePopupProps {
  setModalState: (state: boolean) => void;
  modalState: boolean;
  testDrive: TestDriveData;
  refreshData: () => void;
}

export const ScheduleTestDrivePopup = (props: ScheduleTestDrivePopupProps) => {
  const initializePlannedDate = (): Date | undefined => {
    if (props.testDrive && props.testDrive.plannedDate) {
      return new Date(props.testDrive.plannedDate);
    }
    return undefined;
  }
  const initializeDealerComment = (): string => {
    if (props.testDrive.dealerComment && props.testDrive.dealerComment.trim() !== '') {
      return props.testDrive.dealerComment;
    }
    return '';
  }
  const { t } = useTranslation();
  const [selectedDateTime, setSelectedDateTime] = useState<Date | undefined>(initializePlannedDate());
  const [comment, setComment] = useState('');
  const notSpecifiedText = t('communication.test-drive.report.modals.not-specified');

  useEffect(() => {
    setSelectedDateTime(initializePlannedDate());
  }, [props.testDrive.plannedDate]);

  useEffect(() => {
    setComment(initializeDealerComment());
  }, [props.testDrive.dealerComment]);

  const defaultToastOptions: ToastOptions = {
    position: 'top-center',
    autoClose: 5000,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  }

  const successToast = (successText: string) => {
    toast.success(successText, {
      ...defaultToastOptions,
      toastId: 'schedule-test-drive-success-toast'
    });
  };

  const errorToast = (errorText: string) => {
    toast.error(errorText, {
      ...defaultToastOptions,
      toastId: 'schedule-test-drive-error-toast'
    });
  };

  const warningToast = (warningText: string) => {
    toast.warning(warningText, {
      ...defaultToastOptions,
      toastId: 'schedule-test-drive-warning-toast'
    });
  };

  const handleCancel = () => {
    setComment(initializeDealerComment());
    setSelectedDateTime(initializePlannedDate());
    props.setModalState(false);
  };

  const handleDateChange = (date: Date) => {
    if (date.getTime() < new Date().getTime()) {
      warningToast(t('communication.test-drive.report.modals.planned-date-in-the-past'));
    }
    setSelectedDateTime(date);
  }

  const handleSubmit = () => {
    const requestUrl = `${environment.restEndpoint}/action/testdrives/ScheduleTestDrive`;

    const requestBody: ScheduleTestDriveRequest = {
      id: props.testDrive.id,
      plannedDate: selectedDateTime,
      dealerComment: comment
    }

    sendApiRequest(requestUrl, 'post', requestBody).then((response) => {
      if (response.ok) {
        successToast(t('communication.test-drive.report.modals.schedule-test-drive.successfully-scheduled'));
        handleCancel();
        if (props.refreshData) props.refreshData();
      } else {
        response.json().then((data) => {
          errorToast(t('error') + data.message);
        });
      }
    });
  }

  const handleDownloadAgreement = () => {
    if (!props.testDrive?.id) {
      return
    }
    sendApiRequest(
      `${environment.restEndpoint}/action/testdrives/agreement?testDriveId=${props.testDrive?.id}`,
      'GET'
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error(response.status + ' ' + response.statusText);
        }
      })
      .then((data) => {
        const file = base64StringToBlob(
          data.content,
          'application/pdf'
        );
        download(
          file,
          'Ueberlassungserklaerung.pdf',
          'application/pdf'
        );
      })
      .catch((e) => errorToast(t('error') + e));
  };

  return (
    <div>
      <Modal
        key="schedule-test-drive-modal"
        title={t('communication.test-drive.report.modals.schedule-test-drive.modal-title')}
        show={props.modalState}
        handleClose={() => handleCancel()}
        size='min-w-[440px]'
      >
        <div className="flex flex-col">
          <div className={'flex justify-between'}>
          <div className="flex flex-row items-start ps-5 gap-x-8 align-middle">
            <span className="font-suzuki-headline text-dark" style={{fontSize: '30px'}}>{props.testDrive.companyId ? `${props.testDrive?.contactSalutation} ${props.testDrive?.companyName}` : `${props.testDrive?.contactSalutation} ${props.testDrive?.contactFirstName} ${props.testDrive?.contactLastName}`}</span>
            <Badge
              name={
                props.testDrive?.customerState === CustomerType.CUSTOMER
                  ? t(
                    'contact-details.customer'
                  ).toLocaleUpperCase()
                  : props.testDrive?.customerState === CustomerType.PROSPECT
                    ? t(
                      'contact-details.prospect'
                    ).toLocaleUpperCase()
                    : props.testDrive?.customerState === CustomerType.LC
                      ? t(
                        'contact-details.lost-customer'
                      ).toLocaleUpperCase()
                      : notSpecifiedText.toLocaleUpperCase()
              }
              title={
                props.testDrive?.customerState === CustomerType.CUSTOMER
                  ? t('contact-details.customer')
                  : props.testDrive?.customerState === CustomerType.PROSPECT
                    ? t('contact-details.prospect')
                    : props.testDrive?.customerState === CustomerType.LC
                      ? t('contact-details.lost-customer')
                      : notSpecifiedText.toLocaleUpperCase()
              }
            />
          </div>
          {props.testDrive.isMotorcycle &&
            <Button onClick={handleDownloadAgreement} >
              {t('communication.test-drive.report.modals.download-agreement')}
            </Button>
          }
          </div>
          <div className="flex items-start ps-5 pt-2">
            <span className="text-dark-gray font-suzuki-regular">
              {
                `${format(
                  t('communication.test-drive.report.modals.created-at'),
                  props.testDrive.createAt ? new Date(props.testDrive.createAt).toLocaleDateString(
                    'de-DE', {year: 'numeric', month: '2-digit', day: '2-digit'}
                  ) : notSpecifiedText,
                  props.testDrive.origin ? t(`communication.test-drive.origin.${props.testDrive.origin}`) : notSpecifiedText,
                )} ${props.testDrive.lastChangeDate ? format(
                  t('communication.test-drive.report.modals.modified-at'),
                  new Date(props.testDrive.lastChangeDate).toLocaleDateString(
                    'de-DE', {year: 'numeric', month: '2-digit', day: '2-digit'}
                  ),
                  props.testDrive.lastChangeEditor ? props.testDrive.lastChangeEditor : notSpecifiedText,
                ) : ''}`
              }
            </span>
          </div>
          <TestDriveInformation
            testDrive={props.testDrive}
            plannedDateEditable={
              <DatePickerInput
                label={{
                  name: t('communication.test-drive.report.modals.selected-date'),
                  position: 'top',
                }}
                width={'w-[17rem]'}
                selectedDay={selectedDateTime}
                handleDateChange={handleDateChange}
                showTimeInput
              />
            }
            dealerCommentEditable={
              <Textarea
                value={comment}
                onChange={(value) => setComment(value)}
                width="100%"
                label={{
                  name: t('communication.test-drive.report.modals.comment'),
                  position: 'top',
                }}
                rows={8}
                resizeDisabled
              />
            }
          />
        </div>
        <div className="flex flex-row gap-x-6 justify-end">
          <Button
            className="bg-white"
            buttonStyle={ButtonStyle.OUTLINE}
            onClick={handleCancel}
            px="5"
            py="3"
          >
            {t('communication.test-drive.report.modals.close')}
          </Button>
          <Button onClick={handleSubmit} px="5" py="3">
            {t('communication.test-drive.report.modals.save')}
          </Button>
        </div>
      </Modal>
    </div>
  )
}

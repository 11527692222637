import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../setup'
import { UserFilter } from "../../models/user";

export const USER_FILTER_FEATURE_KEY = 'user-filter';

export interface UserFilterState {
  key: string;
  currentRow: string | undefined;
  userFilter: UserFilterData;
}

export interface UserFilterData {
  filter: UserFilter;
  pageIndex: number;
}

export const initialUserFilterState: UserFilterState = {
  key: "",
  currentRow: undefined,
  userFilter: {
    filter: {},
    pageIndex: 0
  }
};

const userFilterSlice = createSlice({
  name: USER_FILTER_FEATURE_KEY,
  initialState: initialUserFilterState,
  reducers: {
    init: (state, { payload: key }: PayloadAction<string>) => {
      state.key = key;
      state.currentRow = undefined;
      state.userFilter = {
        filter: {},
        pageIndex: 0
      };
    },
    updateUserFilter: (state, { payload: userFilter }: PayloadAction<UserFilter>) => {
      state.userFilter.filter = userFilter;
    },
    updateUserPageIndex: (state, { payload: pageIndex }: PayloadAction<number>) => {
      state.userFilter.pageIndex = pageIndex;
    },
    updateUserCurrentRow: (state, { payload: currentRow }: PayloadAction<string | undefined>) => {
      state.currentRow = currentRow;
    },
  }
});

export const userFilterActions = userFilterSlice.actions;

export const userFilterSelector = (state: RootState) => state[USER_FILTER_FEATURE_KEY]

export const userFilterReducer = userFilterSlice.reducer;

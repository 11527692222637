import {
  BasicInput,
  BasicOutput,
  Button,
  ButtonStyle,
  Checkbox,
  Headline,
  Multiselect,
  MultiselectOptionsProps,
} from '@suzuki-frontend-framework-update/ui';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import './user-edit.module.scss';
import { environment } from '../../../environments/environment';
import { useApiGetData } from '../../components/useApiGetData/useApiGetData';
import { Dealer } from '../../models/dealer';
import {
  AuthDealer,
  RegisterRequestBody,
  RegisterRequestUser,
  RegisterValue,
} from '../register/register';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../store/setup';
import { userFilterActions } from '../../store/slices/user-filter.slice';
export function UserEdit() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();

  const [formValues, setFormValues] = useState({
    // active: false,
    username: '',
    firstname: '',
    lastname: '',
    email: '',
    password: '',
    isAdmin: false,
    isAreaAdmin: false,
  } as RegisterValue);
  const dealerData = useApiGetData<Dealer[]>(
    `${environment.restEndpoint}/dealer/dealer-service/dealers`,
    'post',
    'dealers'
  );
  const [dealers, setDealers] = useState<Dealer[]>();
  const [formDealers, setFormDealers] = useState<MultiselectOptionsProps[]>();
  const [currentUser, setCurrentUser] = useState<RegisterRequestUser>();
  const { userId } = useParams();
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (dealerData.status !== 200) {
      return;
    }
    if (!dealers) {
      setDealers(dealerData.data);
      const toSelectOptions = (
        dealers: Dealer[] | undefined
      ): MultiselectOptionsProps[] => {
        if (dealers && dealers.length > 0) {
          return dealers.map((d, i) => {
            return {
              key: d.objectID,
              name: `${d.name} ${d.nameAppendum} (${d.dealercode})`,
              value: d.dealercode,
            } as MultiselectOptionsProps;
          });
        } else {
          return [];
        }
      };
      const options = toSelectOptions(dealerData.data);
      setFormDealers(options);
    }
  }, [dealerData]);

  useEffect(() => {
    if (userId) {
      const getUserById = async () => {
        const resp = await fetch(
          `${environment.restEndpoint}/user/users/${userId}`,
          {
            method: 'GET',
            // credentials: "include",
            mode: 'cors',
            headers: {
              'Content-Type': 'application/json',
              apikey: 'p0zLmdPds51XKPtTTc05z0L0',
            },
          }
        );
        const body = await resp.json();
        const userData: RegisterRequestUser = body.data;
        const formVal: RegisterValue = {
          isAdmin: userData.traits.isAdmin,
          isAreaAdmin: userData.traits.isAreaAdmin,
          username: userData.traits.username,
          password: userData.password,
          lastname: userData.traits.name.last,
          firstname: userData.traits.name.first,
          email: userData.traits.email,
          csrf_token: '',
          dealerIDs: [],
        };
        //console.log(userData);
        //console.log(formVal);
        setFormValues(formVal);
        setCurrentUser(userData);
      };
      getUserById().catch((err) => {
        console.error(err);
      });
    }
  }, []);

  useEffect(() => {
    if (
      dealers &&
      dealers.length > 0 &&
      currentUser &&
      (!formValues.dealerIDs || formValues.dealerIDs.length === 0)
    ) {
      const currentDealers = currentUser.traits.dealers;
      const currentDealerIDs = currentDealers.map((d) => {
        return d.id;
      });
      const relevantDealers = dealers.filter((d) =>
        currentDealerIDs.includes(d.objectID)
      );
      if (relevantDealers && relevantDealers.length > 0) {
        const selectedDealer: MultiselectOptionsProps[] = relevantDealers.map(
          (d) => {
            return {
              key: d.objectID,
              name: `${d.name} ${d.nameAppendum} (${d.dealercode})`,
              value: d.dealercode,
            } as MultiselectOptionsProps;
          }
        );
        setFormValues({ ...formValues, dealerIDs: selectedDealer });
      }
    }
  }, [dealers, currentUser]);

  const handleChange = (prop: string, value: string) => {
    setFormValues({ ...formValues, [prop]: value });
  };

  const handleCheckboxChange = (prop: string) => {
    setFormValues((prev: RegisterValue) => {
      return { ...formValues, [prop]: !prev[prop as keyof RegisterValue] };
    });
  };

  const handleDealerChange = (dealerList: MultiselectOptionsProps[]) => {
    setFormValues({ ...formValues, dealerIDs: dealerList });
  };

  const handleCancel = () => {
    navigate('/admin/users');
  };

  const handleSubmit = async () => {
    let dealers = [] as Array<AuthDealer>;
    if (formValues && formValues.dealerIDs && formValues.dealerIDs.length > 0) {
      dealers = formValues.dealerIDs.map((d) => {
        return {
          id: d.key,
          dealercode: d.value,
        } as AuthDealer;
      });
    }
    const sendData = {
      user: {
        id: userId,
        state: 'active',
        traits: {
          email: formValues.email,
          name: {
            first: formValues.firstname,
            last: formValues.lastname,
          },
          username: formValues.username,
          isAdmin: formValues.isAdmin,
          isAreaAdmin: formValues.isAreaAdmin,
          dealers: dealers,
        },
      },
    } as RegisterRequestBody;
    //console.log(sendData);
    try {
      const resp = await fetch(`${environment.restEndpoint}/user/users`, {
        method: 'PUT',
        // credentials: "include",
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          apikey: 'p0zLmdPds51XKPtTTc05z0L0',
        },
        body: JSON.stringify(sendData),
      });
      //console.log(resp);
      const body = await resp.json();
      if (!resp.ok) {
        // it returned an error
        setErrorMessage(`Error ${body.code}: ${body.message}`);
        return;
      }
      navigate('/admin/users');
    } catch (e) {
      console.error(e);
    }
  };

  const rowWidth = '70rem';
  const rightOffset = '32.75rem';

  if (!userId) {
    return <p>No user id found</p>;
  }

  return (
    <div className="grow bg-gray p-8 flex flex-col gap-y-8 h-full">
      <Headline title={t('user-edit.title')} />
      <BasicOutput title={t('user-edit.basic-data')}>
        <form>
          <div className={'text-red-500 p-4 text-center'}>{errorMessage}</div>
          <div
            className={`flex flex-col gap-y-10 pb-5`}
            style={{ width: rowWidth, paddingRight: rightOffset }}
          >
            {/*<span style={{ paddingRight: "22.875rem" }}>*/}
            {/*  <Checkbox*/}
            {/*    width="8"*/}
            {/*    label={t("user-edit.active")}*/}
            {/*    checked={formValues.active}*/}
            {/*    onChange={handleActivChange} />*/}
            {/*</span>*/}
            <BasicInput
              width="25rem"
              label={{ name: t('user-edit.username'), position: 'top' }}
              value={formValues.username}
              onChange={(e) => handleChange('username', e.target.value)}
            />
          </div>
          <div
            className="flex flex-row gap-x-8 py-5"
            style={{ width: rowWidth }}
          >
            <BasicInput
              width="25rem"
              label={{ name: t('user-edit.firstname'), position: 'top' }}
              value={formValues.firstname}
              onChange={(e) => handleChange('firstname', e.target.value)}
            />
            <BasicInput
              width="25rem"
              value={formValues.lastname}
              label={{ name: t('user-edit.lastname'), position: 'top' }}
              onChange={(e) => handleChange('lastname', e.target.value)}
            />
          </div>
          <div
            className={`flex flex-col gap-y-10 pt-5`}
            style={{ width: rowWidth, paddingRight: rightOffset }}
          >
            <BasicInput
              width="25rem"
              value={formValues.email}
              label={{ name: t('user-edit.email'), position: 'top' }}
              onChange={(e) => handleChange('email', e.target.value)}
              type="email"
            />
            <Checkbox
              label={t('user-edit.isAdmin')}
              rightLabel={true}
              checked={formValues.isAdmin}
              onChange={(e) => handleCheckboxChange('isAdmin')}
            />
            <Checkbox
              label={t('user-edit.isAreaAdmin')}
              rightLabel={true}
              checked={formValues.isAreaAdmin}
              onChange={(e) => handleCheckboxChange('isAreaAdmin')}
            />
            {formDealers && (
              <Multiselect
                options={formDealers}
                values={formValues.dealerIDs}
                label={{ name: t('user-edit.dealer') }}
                displayToken={'name'}
                onSelect={(selectedProps) => handleDealerChange(selectedProps)}
                onRemove={(selectedProps) => handleDealerChange(selectedProps)}
              />
            )}
          </div>
        </form>
      </BasicOutput>
      <div className="w-full flex flex-row justify-end gap-x-5">
        <Button
          buttonStyle={ButtonStyle.OUTLINE}
          onClick={() => {
            dispatch(userFilterActions.updateUserFilter({}));
            handleCancel();
          }}
          px="5"
          py="4"
          className="bg-white"
        >
          {t('user-edit.cancel')}
        </Button>
        <Button
          buttonStyle={ButtonStyle.DEFAULT}
          onClick={() => {
            dispatch(userFilterActions.updateUserFilter({}));
            handleSubmit();
          }}
          px="5"
          py="4"
        >
          {t('user-edit.submit')}
        </Button>
      </div>
    </div>
  );
}

export default UserEdit;

export interface CcpExport {
  id?: number;
  dealerObjectId?: string;
  vehicle: VehicleDetails;
  contact: ContactDetails;
  sendoutType: string;
  serialNumber?: number;
  referenceId: string;
  exported: boolean;
  createdAt?: Date;
  exportedAt?: Date;
}

export interface VehicleDetails {
  id?: number;
  vehicleObjectId?: string;
  firstRegistrationDate?: Date;
  model?: string;
  brand?: string;
  vin?: string;
  lastService?: Date;
  licensenumber?: string;
  isMotorcycle?: boolean;
}

export interface ContactDetails {
  id?: number;
  contactObjectId?: string;
  firstname?: string;
  name?: string;
  salutation?: string;
  isCompany?: boolean;
  email?: string;
  addressLine1?: string;
  addressLine2?: string;
  zip?: string;
  city?: string;
  street?: string;
  countryCode?: string;
  districtAreaCode?: string;
}

export interface CcpExportRequest {
  filter?: CcpExportFilter
  pageSize?: number;
  pageIndex?: number;
}

export interface CcpExportFilter {
  contactObjectId?: string;
  vehicleObjectId?: string;
  dealerObjectId?: string;
  referenceId?: string;
  sendoutTypes?: string[];
  exported?: ExportState;
}
export enum ExportState {
  ALL,
  NOT_EXPORTED,
  EXPORTED,
}

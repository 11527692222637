type SelectionMechanism = {
  id: number;
  name: string;
};

export default SelectionMechanism;

export enum SelectionMechanismEnum {
  SINGLE = 'single',
  MULTIPLE_CHECKBOXES = 'multiple-checkboxes',
  MULTIPLE_CHIPS = 'multiple-chips',
  SLIDER = 'slider',
  TEXT_SINGLE_LINE = 'text-single-line',
  TEXT_MULTIPLE_LINE = 'text-multiple-line',
}

export const mapSelectionMechanismId = (legacyId: number) => ({
  1: SelectionMechanismEnum.SINGLE,
  2: SelectionMechanismEnum.MULTIPLE_CHECKBOXES,
  3: SelectionMechanismEnum.MULTIPLE_CHIPS,
  4: SelectionMechanismEnum.SLIDER,
  5: SelectionMechanismEnum.TEXT_SINGLE_LINE,
  6: SelectionMechanismEnum.TEXT_MULTIPLE_LINE,
}[legacyId] || SelectionMechanismEnum.SINGLE);

export const mapSelectionMechanism = (name: string) => ({
  [SelectionMechanismEnum.SINGLE]: 1,
  [SelectionMechanismEnum.MULTIPLE_CHECKBOXES]: 2,
  [SelectionMechanismEnum.MULTIPLE_CHIPS]: 3,
  [SelectionMechanismEnum.SLIDER]: 4,
  [SelectionMechanismEnum.TEXT_SINGLE_LINE]: 5,
  [SelectionMechanismEnum.TEXT_MULTIPLE_LINE]: 6,
}[name] || 1);

import { Session } from "@ory/client";
import {Dealer} from "../models/dealer";
import {isMotoDealerCode} from "./dealer-helper";

export function isMotoDealer(session: Session): boolean {
  return session?.identity?.traits?.dealers?.some((d: any) => isMotoDealerCode(d.dealercode));
}

export function isAutoDealer(session: Session): boolean {
  return !isMotoDealer(session);
}

export function isAdmin(session: Session): boolean {
  return session.identity?.traits?.isAdmin;
}

export function isAreaAdmin(session: Session): boolean {
  return session.identity?.traits?.isAreaAdmin;
}

export function isAutoDealerOrAdmin(session: Session): boolean {
  return isAdmin(session) || isAutoDealer(session);
}

export function isMotoDealerOrAdmin(session: Session): boolean {
  return isAdmin(session) || isMotoDealer(session);
}

export function getDealerCodesFromSession(session: Session): string[] {
  let resultArray: string[] = [];

  if (!isAdmin(session) && session.identity?.traits?.dealers?.length > 0) {
    const dealers = session.identity.traits.dealers;
    dealers.map((d: Dealer) => {
      resultArray = resultArray.concat(d.dealercode);
    });
  }
  return resultArray;
}

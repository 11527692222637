import { Icon } from '../icon/icon';
import React from 'react';
import ReactDOM from 'react-dom';

export type ModalProps = {
  title: string;
  show: boolean;
  handleClose: () => void;
  handleCloseOnBg?: boolean;
  children: React.ReactNode;
  size?: string;
  clip?: boolean
};

export const Modal = (props: ModalProps) => {
  return (
    <>
      {props.show &&
        ReactDOM.createPortal(
          <div
            className="fixed bg-black bg-opacity-30 w-full h-full inset-0 z-50 top-0 left-0 overflow-auto"
            onClick={props.handleCloseOnBg ? props.handleClose : undefined}
          >
            <div className="flex justify-center md:pt-24 md:pb-28 p-5">
              <div
                className={`relative bg-white shadow-xl ${
                  props.size
                    ? props.size
                    : 'mobile:max-w-screen-sm w-4/5 max-w-screen-xl'
                }`}
                role="dialog"
                onClick={(e) => e.stopPropagation()}
              >
                <div className="relative font-suzuki-bold p-4 ">
                  <div className="relative">
                    <button
                      className="absolute top-0 right-0 p-sm"
                      onClick={props.handleClose}
                    >
                      <Icon iconName="close" />
                    </button>
                  </div>
                  <div className="flex justify-center p-sm text-dark-blue-gray pb-6">
                    {props.title}
                  </div>
                </div>
                <div className={`max-h-modal ${props.clip ? 'overflow-y-visible' : 'overflow-y-auto'} font-suzuki-regular px-sm md:px-xl px-4 pb-4`}>
                  {props.children}
                </div>
              </div>
            </div>
          </div>,
          document.body
        )}
    </>
  );
};

export default Modal;

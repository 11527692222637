const questionnaireMappings = [
    { id: 1, name: 'moto-sales' },
    { id: 2, name: 'moto-service' },
    { id: 3, name: 'moto-product' },
    { id: 4, name: 'auto-sales' },
    { id: 5, name: 'auto-service' },
    { id: 6, name: 'auto-product' },
  ];
    
  export function isMotoQuestionnaire(type: number): boolean {
    return questionnaireMappings.some(questionnaire =>
      ['moto-sales', 'moto-service', 'moto-product'].includes(questionnaire.name) &&
        questionnaire.id === type,
    );
  }
    
  export function isAutoQuestionnaire(type: number): boolean {
    return questionnaireMappings.some(questionnaire =>
      ['auto-sales', 'auto-service', 'auto-product'].includes(questionnaire.name) &&
        questionnaire.id === type,
    );
  }
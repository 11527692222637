import { Button, Icon, Modal } from '@suzuki-frontend-framework-update/ui';
import { Timeline, TimelineItem } from "@sde/basic-ui-library";
import { useTranslation } from 'react-i18next';
import { format } from "react-string-format";
import { ChangeHistoryEntry } from "../../models/history-entry";

export interface ChangeHistoryPopupProps {
  setModalState: (state: boolean) => void;
  modalState: boolean;
  translationKey: string;
  translationAttributes: string[];
  entries?: ChangeHistoryEntry[];
}

export const ChangeHistoryPopup = (props: ChangeHistoryPopupProps) => {
  const { t } = useTranslation();

  const changeHistoryEntriesToTimelineItems = (
    entries: ChangeHistoryEntry[]
  ): TimelineItem[] => {
    const result: TimelineItem[] = [];
    if (entries) {
      for (const entry of entries) {
        result.push(...entry.values.filter(v =>  props.translationAttributes.includes(v.attributeName)).map((value) => {
            const previousValue = value.previousValue;
            const newValue = value.newValue;
            return (
              {
                date: entry.dateOfChange,
                name: format(
                  t(props.translationKey + ".value"),
                  <span className="font-suzuki-bold">{t(props.translationKey + ".attribute." + value.attributeName)}</span>,
                  <span className="font-suzuki-bold">{previousValue}</span>,
                  <span className="font-suzuki-bold">{newValue}</span>,
                ),
                description: format(
                  t(props.translationKey + ".editor"),
                  <span className="font-suzuki-bold text-dark-gray">{entry.editor}</span>
                )
              } as TimelineItem
            )
          }
        ))
      }
    }

    return result;
  };

  const handleCancel = () => {
    props.setModalState(false);
  };

  return (
    <div>
      <Button
        className="h-fit w-fit"
        py="1"
        px="1"
        onClick={() => props.setModalState(true)}
      >
        <Icon iconName="history" className="text-xl w-7" />
      </Button>
      <Modal
        key="change-history"
        show={props.modalState}
        handleClose={() => handleCancel()}
        title={t(`${props.translationKey}.title`)}
      >
        {props.entries && props.entries.length > 0 ? (
          <div className="m-8">
            <Timeline
              title=" "
              className="w-full"
              items={changeHistoryEntriesToTimelineItems(props.entries)}
              key={"history_timeline_history_popup"}
              timelineItemsContainerId={"timeline_history_container_history_popup"}
              useBasicTimelineItem
            />
          </div>
        ) : (
          <div className="m-8 text-center">
            <span>{t(`${props.translationKey}.no-data`)}</span>
          </div>
        )}
      </Modal>
    </div>
  )
    ;
};

export default ChangeHistoryPopup;

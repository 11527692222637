import React, { useEffect, useState } from 'react';
import { BasicInput, Button, ButtonStyle, Modal, SelectInput, SelectOptionsProps } from '@sde/basic-ui-library';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { Newsletter, DataUpdateError } from "../../models/newsletter";
import { Tenant } from "../../models/tenant";
import { createNewsletter, updateNewsletter } from "../../store/slices/newsletter.slice";

export interface EditNewsletterPopupProps {
  isOpen: boolean;
  newsletter?: Newsletter;
  onClose: () => void;
  onUpdate: () => void;
}

export interface FormError {
  [index: string]: string;
}

export default function EditNewsletterPopup(props: Readonly<EditNewsletterPopupProps>) {
  const { t } = useTranslation();
  const [newsletterFormValues, setNewsletterFormValues] = useState<Newsletter>({
    name: '',
    code: '',
    active: true,
    isMoto: false,
  });
  const [formErrors, setFormErrors] = useState<FormError>({});
  const [newsletterOptions, setNewsletterOptions] = useState<SelectOptionsProps[]>([]);

  const prepareSelectOptions = (): SelectOptionsProps[] => {
    return [
      { key: Tenant.MOTO, name: 'Moto', value: Tenant.MOTO },
      { key: Tenant.AUTO, name: 'Auto', value: Tenant.AUTO },
    ];
  };

  const successToast = (successText: string) => {
    toast.success(successText, {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      toastId: 'custom-id-success',
    });
  };
  const errorToast = (errorText: string) => {
    toast.error(errorText, {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      toastId: 'excel-import-error',
    });
  };

  const validateForm = (): boolean => {
    setFormErrors({});
    let valid = true;
    const newFormErrors: FormError = {};
    if (!newsletterFormValues.name) {
      newFormErrors.name = t('communication.newsletter.definition.form.validation_name');
      valid = false;
    }
    if (!newsletterFormValues.code) {
      newFormErrors.code = t('communication.newsletter.definition.form.validation_code');
      valid = false;
    }
    setFormErrors(newFormErrors);
    return valid;
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;
      if (props.newsletter) {
        const response = await updateNewsletter(newsletterFormValues);
        if (response.success) {
          successToast(t('communication.newsletter.definition.popup.success_edit'));
          setFormErrors({});
          props.onUpdate();
          props.onClose();
        } else {
          switch (response.error) {
            case DataUpdateError.RESOURCE_ALREADY_EXISTS:
              errorToast(t('communication.newsletter.definition.popup.error_resource_already_exists'));
              break;
            case DataUpdateError.CONFLICT:
              errorToast(t('communication.newsletter.definition.popup.error_newsletter_not_modifiable'));
              break;
            case DataUpdateError.NOT_FOUND:
              errorToast(t('communication.newsletter.definition.popup.error_not_found'));
              break;
            default:
              errorToast(t('error') + response.error);
              break;
          }
        }
      } else {
        const response = await createNewsletter(newsletterFormValues);
        if (response.id.length > 0) {
          successToast(t('communication.newsletter.definition.popup.success_create'));
          setFormErrors({});
          props.onUpdate();
          props.onClose();
        } else {
          if (response.error === DataUpdateError.RESOURCE_ALREADY_EXISTS) {
            errorToast(t('communication.newsletter.definition.popup.error_resource_already_exists'));
          } else {
            errorToast(t('error') + response.error);
          }
        }
      }
  };

  useEffect(() => {
    if (props.newsletter) {
      setNewsletterFormValues(props.newsletter);
    } else {
      setNewsletterFormValues({
        name: '',
        code: '',
        active: true,
        isMoto: false,
      });
    }
    setNewsletterOptions(prepareSelectOptions());
  }, [props.isOpen]);

  return (
        <Modal
            title={props.newsletter ? t('communication.newsletter.definition.modal.title_edit') : t('communication.newsletter.definition.modal.title_create')}
            size={'w-[640px]'}
            show={props.isOpen}
            handleClose={() => {
              setFormErrors({});
              props.onClose();
            }}>
            <div className="px-20 pb-3">
                <div className="flex flex-col gap-6 mb-10">
                    <BasicInput
                        id="input-name"
                        label={{ name: t('communication.newsletter.definition.form.label_name'), position: 'top' }}
                        value={newsletterFormValues.name}
                        onChange={e => setNewsletterFormValues({ ...newsletterFormValues, name: e.target.value })}
                        error={formErrors.name}
                        required
                    />
                    <BasicInput
                        id="input-code"
                        label={{ name: t('communication.newsletter.definition.form.label_code'), position: 'top' }}
                        value={newsletterFormValues.code}
                        onChange={e => setNewsletterFormValues({ ...newsletterFormValues, code: e.target.value })}
                        error={formErrors.code}
                        required
                    />
                    <SelectInput
                        id="input-tenant"
                        label={{ name: t('communication.newsletter.definition.form.label_tenant'), position: 'top' }}
                        options={newsletterOptions}
                        value={newsletterFormValues.isMoto ? Tenant.MOTO : Tenant.AUTO}
                        onSelect={value => setNewsletterFormValues({ ...newsletterFormValues, isMoto: value === Tenant.MOTO })}
                        required
                    />
                </div>
                <div className="flex justify-end gap-2">
                   <Button
                       className="bg-white"
                       buttonStyle={ButtonStyle.OUTLINE}
                       onClick={props.onClose}>
                     {t('communication.newsletter.definition.modal.button_cancel')}
                   </Button>
                    <Button px="5" py="3" onClick={handleSubmit}>
                        {t('communication.newsletter.definition.modal.button_save')}
                    </Button>
                </div>
            </div>
        </Modal>
  );
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../setup';
import { DashboardDealerFilter, DashboardFilter } from "../../models/dashboard";
import moment from 'moment';


export const DASHBOARD_FEATURE_KEY = 'dashboard-filter';

export interface DashboardFilterState {
  key: string;
  currentRow: string | undefined;
  dealerFilter: DashboardDealerFilter;
  filter:DashboardFilter;
}

export interface DashboardFilterData {
  filter: DashboardFilter;
}

const prepareDateFrom = () => {
  const today = new Date();
  return moment(new Date(
    today.getFullYear(),
    today.getMonth() ,
    today.getDate()-7,
    today.getHours(),
    today.getMinutes(),
    today.getSeconds()
  )).format('DD.MM.YYYY');
}

const preprareDateTo = () => {
  const today = new Date();
  return moment(new Date(
    today.getFullYear(),
    today.getMonth() ,
    today.getDate(),
    today.getHours(),
    today.getMinutes(),
    today.getSeconds()
  )).format('DD.MM.YYYY');
}

export const initialDashboardFilterState: DashboardFilterState = {
  key: '',
  currentRow: undefined,
  dealerFilter: {
    dealers: [],
    mainDealers: [],
    asm: [],
    dsm: [],
  },
  filter:{
    dateFrom:prepareDateFrom(),
    dateTo : preprareDateTo(),
  }

};

const dashboardFilterSlice = createSlice({
  name: DASHBOARD_FEATURE_KEY,
  initialState: initialDashboardFilterState,
  reducers: {
    init: (state, { payload: key }: PayloadAction<string>) => {
      state.key = key;
      state.currentRow = undefined;
      state.dealerFilter = { dealers: [], mainDealers: [], asm: [], dsm: [] };
    },
    resetState: (state, action: PayloadAction<null>) => {
      return initialDashboardFilterState;
    },
    updateCurrentRow: (
      state,
      { payload: currentRow }: PayloadAction<string | undefined>
    ) => {
      state.currentRow = currentRow;
    },
    updateDealerFilter: (
      state,
      { payload: dealerFilter }: PayloadAction<DashboardDealerFilter>
    ) => {
      state.dealerFilter = dealerFilter;
    },
    updateDateFilter: (state, { payload: dateFilter }: PayloadAction<DashboardDealerFilter>) => {
      state.filter = dateFilter;
    },
  },
});

export const dashboardFilterActions = dashboardFilterSlice.actions;

export const dashboardFilterSelector = (state: RootState) =>
  state[DASHBOARD_FEATURE_KEY];

export const dashboardFilterReducer = dashboardFilterSlice.reducer;

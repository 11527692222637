'use client';
import React, { useCallback, useState } from 'react';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { useApiGetData } from '../../../components/useApiGetData/useApiGetData';
import { environment } from '../../../../environments/environment';
import { DataFetchProps } from "../../../models/others";
import { useSelector } from "react-redux";
import { dashboardFilterSelector } from "../../../store/slices/dashboard.slice";
import { ServiceRecord, ServiceRecordsRequest } from "../../../models/service-record";
import InspectionList from "./inspection-list";
import { getDateFromQuery, getInspectionNumbersFromQuery } from "./query-utils";
import { Link, useLocation } from "react-router-dom";


const CompletedInspectionList = () => {
  const {t} = useTranslation();
  const dashboardFilterState = useSelector(dashboardFilterSelector);
  const location = useLocation();

  const date = getDateFromQuery(location)
  const inspectionNumber = getInspectionNumbersFromQuery(location)


  const [inspectionsRequest, setInspectionsRequest] =
    useState<ServiceRecordsRequest>({
      pageSize: 0,
      pageIndex: 0,
      filter: {
        month: date ? Number(date.split('-')[0]) : undefined,
        year: date ? Number(date.split('-')[1]) : undefined,
        inspectionNumbers: inspectionNumber,
        dealerCodes: dashboardFilterState.dealerFilter.dealers,
      },
    });
  const serviceRecords = useApiGetData<ServiceRecord[]>(
    `${environment.restEndpoint}/service-record/service-record/service-record-vehicle-registration-view/get`,
    'post',
    'serviceRecordsVehicleRegistryRows',
    inspectionsRequest
  );

  const serviceRecordsCount = useApiGetData<number>(
    `${environment.restEndpoint}/service-record/service-record/service-record-vehicle-registration-view/count/get`,
    'post',
    'count',
    inspectionsRequest
  );

  const columnHelper = createColumnHelper<ServiceRecord>();
  const columns: ColumnDef<ServiceRecord>[] = [
    columnHelper.accessor((row) => row, {
      id: 'vin',
      enableSorting: false,
      header: () => <span>{t('communication.inspections-invitations.columns.vin')}</span>,
      cell: (info) => {
        return (
          <Link
            className="flex flex-col"
            to={`/vehicles/view/${info.getValue().vehicleObjectId}`}
          >
            <div className={'underline'}>{info.getValue().vin ?? '-'}</div>
          </Link>
        );
      },
    }),
    columnHelper.accessor((row) => row.model, {
      id: 'model',
      enableSorting: false,
      header: () => <span>{t('communication.inspections-invitations.columns.model')}</span>,
      cell: (info) => {
        return (
          <div>{info.getValue() ?? '-'}</div>
        );
      },
    }),
    columnHelper.accessor((row) => row.firstRegistration, {
      id: 'first_registration',
      enableSorting: false,
      header: () => <span>{t('communication.inspections-invitations.columns.first-registration')}</span>,
      cell: (info) => {
        const firstRegistrationDate = info.getValue();
        return (<div>
            {firstRegistrationDate ?
              new Date(firstRegistrationDate).toLocaleDateString()
              : '-'}
          </div>
        );
      },
    }),
    columnHelper.accessor((row) => row.license, {
      id: 'license',
      enableSorting: false,
      header: () => <span>{t('communication.inspections-invitations.columns.license-plate')}</span>,
      cell: (info) => {
        return (
          <div>{info.getValue() ?? '-'}</div>
        );
      },
    }),
    columnHelper.accessor((row) => row.inspectionDate, {
      id: 'inspection_date',
      enableSorting: true,
      header: () => <span>{t('communication.completed-inspections.columns.inspection-date')}</span>,
      cell: (info) => {
        const inspectionDate = info.getValue();
        return (<div>
            {inspectionDate ?
              new Date(inspectionDate).toLocaleDateString()
              : '-'}
          </div>
        );
      },
    }),
    columnHelper.accessor((row) => row.milage, {
      id: 'milage',
      enableSorting: false,
      header: () => <span>{t('communication.completed-inspections.columns.milage')}</span>,
      cell: (info) => {
        const milage = info.getValue() ?? 0
        return (
          <div>{milage > 0 ? milage : '-'}</div>
        );
      },
    }),
    columnHelper.accessor((row) => row.inspectionReason, {
      id: 'inspection_reason',
      enableSorting: true,
      header: () => <span>{t('communication.completed-inspections.columns.inspectionReason')}</span>,
      cell: (info) => {
        const inspectionReason = info.getValue()
        return (
          <div>{inspectionReason ?? '-'}</div>
        );
      },
    }),
  ].filter(Boolean) as ColumnDef<ServiceRecord>[];

  const fetchListData = useCallback(
    async ({pageSize, pageIndex, pageSortBy}: DataFetchProps) => {
      let sortByField = 'inspection_date';
      let sortDir = 1;
      if (pageSortBy && pageSortBy.length > 0) {
        const sortParams = pageSortBy[0];
        sortDir = sortParams.desc ? -1 : 1;
        sortByField = sortParams.id;
      }
      setInspectionsRequest({
        ...inspectionsRequest,
        pageIndex: pageIndex,
        pageSize: pageSize,
        sortField: sortByField,
        sortDir: sortDir,
      });
    },
    []
  );

  return (
    <InspectionList
      data={serviceRecords.data}
      count={serviceRecordsCount.data}
      columns={columns}
      fetchListData={fetchListData}
      csvUrl={`${environment.restEndpoint}/service-record/service-record/service-record-vehicle-registration-view/export`}
      csvRequest={inspectionsRequest}
    />
  );
};

export default CompletedInspectionList;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../setup';

export const WEB_REQUEST_FILTER_FEATURE_KEY = 'web-request-filter';

export interface WebRequestFilterState {
  key: string;
  currentRow: string | undefined;
  data: WebRequestFilterData;
}

export interface WebRequestFilterData {
  filter: WebRequestFilterSlice;
  pageIndex: number;
}

export interface WebRequestFilterSlice {
  dateFrom?: string;
  dateTo?: string;
  dealers?: string[];
  model?: string;
  search?: string;
}

export const initialWebRequestFilterState: WebRequestFilterState = {
  key: '',
  currentRow: undefined,
  data: {
    filter: {
      search: '',
      dealers: [],
    },
    pageIndex: 0,
  },
};

const webRequestFilterSlice = createSlice({
  name: WEB_REQUEST_FILTER_FEATURE_KEY,
  initialState: initialWebRequestFilterState,
  reducers: {
    init: (state, { payload: key }: PayloadAction<string>) => {
      state.key = key;
      state.currentRow = undefined;
      state.data = {
        filter: {},
        pageIndex: 0,
      };
    },
    resetState: (state, action: PayloadAction<null>) => {
      return initialWebRequestFilterState;
    },
    updateFilter: (
      state,
      { payload: webRequestFilter }: PayloadAction<WebRequestFilterSlice>
    ) => {
      state.data.filter = webRequestFilter;
    },
    updatePageIndex: (state, { payload: pageIndex }: PayloadAction<number>) => {
      state.data.pageIndex = pageIndex;
    },
    updateCurrentRow: (
      state,
      { payload: currentRow }: PayloadAction<string | undefined>
    ) => {
      state.currentRow = currentRow;
    },
  },
});

export const webRequestFilterActions = webRequestFilterSlice.actions;

export const webRequestFilterSelector = (state: RootState) =>
  state[WEB_REQUEST_FILTER_FEATURE_KEY];

export const webRequestFilterReducer = webRequestFilterSlice.reducer;

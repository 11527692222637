export const validateEmail = (email: string): boolean => {
  return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
    String(email).toLowerCase()
  );
};

export const validateEmailOrEmpty = (email: string): boolean => {
  return /^(?:[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})?$/.test(
    String(email).toLowerCase()
  );
};

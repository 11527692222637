import html2canvas from 'html2canvas';
import { ChartType } from '../models/report';
import { BubbleDataPoint, ChartData, Point } from 'chart.js/auto';

const downloadImage = (blob: string, fileName: string) => {
  const fakeLink = window.document.createElement('a');
  fakeLink.download = fileName;

  fakeLink.href = blob;

  document.body.appendChild(fakeLink);
  fakeLink.click();
  document.body.removeChild(fakeLink);

  fakeLink.remove();
};

const exportAsImage = async (element: HTMLElement, imageFileName: string) => {
  const canvas = await html2canvas(element);
  const image = canvas.toDataURL('image/png', 1.0);
  downloadImage(image, imageFileName);
};


export const exportChartsAsImage = async (
  chartData: ChartData<'pie' | 'bar', (number | Point | [number, number] | BubbleDataPoint | null)[]>,
  element: HTMLElement,
  chartName: string,
) => {
  const canvas = await html2canvas(element, { useCORS: true, allowTaint: true, onclone: (document) => {
      const chart = document.getElementById(chartName);
      if (!chart) {
        return;
      }

      const valueContainer = document.createElement('div');
      valueContainer.style.textAlign = 'left';
      valueContainer.style.padding = '5px';
      valueContainer.style.boxSizing = 'border-box';
      valueContainer.style.fontSize = '12px';
      valueContainer.style.color = '#666';

      let labels = chartData.labels;
      let values = chartData.datasets[0].data;
      if (chartName === ChartType.FAIL_REASONS) {
        labels = chartData.datasets.map(dataset => dataset.label);
        values = [];
        chartData.datasets.forEach(dataset => {
          values.push(dataset.data[0]);
        });
      }
      labels?.forEach((label, index) => {
        const valueDiv = document.createElement('div');
        valueDiv.textContent = `${label}: ${values[index]}%`;
        valueContainer.appendChild(valueDiv);
      });

      chart.appendChild(valueContainer);
      chart.style.height = 'auto';
    } });

  const image = canvas.toDataURL('image/png', 1.0);
  downloadImage(image, chartName);
};

export default exportAsImage;

import React, { FunctionComponent } from 'react';

const StyledCheckbox: FunctionComponent<CheckboxProps> = (props) => {
  return (
    <div
      className={`flex border border-solid outline-none select-none transition-all ${
        props.checked ? 'bg-dark' : 'bg-white'
      } ${props.error ? 'border-red-500' : 'border-dark'}`}
    >
      {props.children}
    </div>
  );
};

export interface CheckboxProps {
  checked?: boolean;
  label?: string;
  name?: string;
  error?: string;
  width?: string;
  id?: string;
  rightLabel?: boolean;
  children?: React.ReactNode;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const Checkbox: FunctionComponent<CheckboxProps> = (props) => {
  const error = (
    <p
      className={`text-sm text-red-500 font-medium block mt-0 ${
        props.error ? '' : 'hidden'
      }`}
      style={{ visibility: props.error ? 'visible' : 'hidden' }}
    >
      {props.error}
    </p>
  );

  return (
    <React.Fragment>
      <label>
        {!props.rightLabel && (
          <span className="inline-block align-middle text-base font-suzuki-bold mr-4 whitespace-nowrap">
            {props.label}
          </span>
        )}
        <div className="inline-block align-middle text-base">
          <input
            type="checkbox"
            name={props.name}
            className="border-0 -m-1 absolute p-0 overflow-hidden whitespace-no-wrap"
            style={{
              clip: 'rect(0 0 0 0)',
              clipPath: 'inset(50%)',
              height: '1px',
              width: '1px',
            }}
            checked={props.checked}
            id={props.id}
            onChange={props.onChange}
          />
          <StyledCheckbox error={props.error} checked={props.checked}>
            <svg
              className={`h-${props.width ? props.width : '6'} w-${
                props.width ? props.width : '6'
              } fill-none stroke-white stroke-[3px] fill-transparent`}
              viewBox="0 0 25 25"
            >
              <polyline points="20 6 9 17 4 12" />
            </svg>
          </StyledCheckbox>
        </div>
        {props.rightLabel && (
          <span className="inline-block align-middle text-base ml-2">
            {props.label}
          </span>
        )}
      </label>
      {error}
    </React.Fragment>
  );
};

export default Checkbox;

import './set-password.module.scss';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  SelfServiceSettingsFlow,
  SelfServiceSettingsFlowState,
  SubmitSelfServiceSettingsFlowWithPasswordMethodBody,
  UiText,
} from '@ory/client';
import { environment } from '../../../environments/environment';
import { GetAuthTrait } from '../../models/user';
import { BasicInput, Button } from '@suzuki-frontend-framework-update/ui';

export interface SetPasswordFormValues {
  repeatedPassword: string;
  csrf_token: string;
  password: string;
}

export function SetPassword() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [password, setPassword] = useState('');
  const [repeatedPassword, setRepeatedPassword] = useState('');

  const [searchParams] = useSearchParams();
  const [flowID, setFlowID] = useState('');
  const [formValues, setFormValues] = useState({
    password: '',
    csrf_token: '',
    repeatedPassword: '',
  } as SetPasswordFormValues);
  const [error, setError] = useState<string>();
  const [errorMessages, setErrorMessages] = useState([] as Array<UiText>);

  useEffect(() => {
    const flowID = searchParams.get('flow');
    if (!flowID) {
      console.error('no flow id');
    } else {
      setFlowID(flowID);
      const fetchAuthData = async () => {
        const res = await fetch(
          environment.publicAuthURL +
            `/self-service/settings/flows?id=${flowID}`,
          { credentials: 'include' }
        );
        const data: SelfServiceSettingsFlow = await res.json();
        if (data && data.ui && data.ui.nodes && data.ui.nodes.length > 0) {
          const csrf_token = GetAuthTrait(data.ui.nodes, 'csrf_token');
          setFormValues({
            csrf_token: csrf_token?.value,
            password: '',
            repeatedPassword: '',
          });
        }
      };
      fetchAuthData().catch((err) => {
        console.error(err);
      });
    }
  }, [searchParams]);

  const handleSubmit = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    setError(undefined);
    setErrorMessages([]);
    if (password !== repeatedPassword) {
      setError(t('set-password.not-identical'));
      return;
    } else if (!password || !repeatedPassword) {
      setError(t('set-password.missing-field'));
      return;
    }
    if (password.length < 8) {
      setError(t('set-password.password-length-not-enough'));
      return;
    }

    formValues.repeatedPassword = repeatedPassword;
    formValues.password = password;
    const sendData: SubmitSelfServiceSettingsFlowWithPasswordMethodBody = {
      csrf_token: formValues.csrf_token,
      method: 'password',
      password: formValues.password,
    };
    try {
      const fetchURL =
        environment.publicAuthURL + `/self-service/settings?flow=${flowID}`;
      const fetchOptions = {
        method: 'POST',
        credentials: 'include',
        // mode: "cors",
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(sendData),
      } as RequestInit;
      const resp = await fetch(fetchURL, fetchOptions);
      const body: SelfServiceSettingsFlow = await resp.json();
      if ('ui' in body) {
        if (body && body.ui && body.ui.nodes && body.ui.nodes.length > 0) {
          let messageFound = false;
          for (let i = 0; i < body.ui.nodes.length; i++) {
            const node = body.ui.nodes[i];
            if (node.messages && node.messages.length > 0) {
              messageFound = true;
              setErrorMessages(node.messages);
            }
          }
          if (!messageFound) {
            navigate('/login');
            return;
          }
        }
      } else {
        navigate('/login');
        return;
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className="w-11/12 flex flex-col items-center">
      <div className="text-dark-gray text-center">
        {t('set-password.information')}
      </div>
      <div className="flex flex-col justify-center items-center text-red-500 w-full h-14">
        {error && <p>{error}</p>}
      </div>
      {flowID && (
        <form className="flex flex-col gap-y-10">
          {errorMessages && errorMessages.length > 0 && (
            <div className={'text-red-500'}>
              {errorMessages.map((err: UiText) => (
                <p key={err.id}>{t('set-password.' + err.text)}</p>
              ))}
            </div>
          )}
          <p>{t('set-password.policy-rules')}</p>
          <input
            type={'hidden'}
            defaultValue={formValues.csrf_token}
            name={'csrf_token'}
          />
          <BasicInput
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            label={t('set-paswword.password')}
            type="password"
            icon="password"
            width="22rem"
          />
          <BasicInput
            value={repeatedPassword}
            onChange={(e) => setRepeatedPassword(e.target.value)}
            label={t('set-password.repeated-password')}
            type="password"
            icon="password"
            width="22rem"
          />
          <div className="w-full">
            <Button py="4" className="w-full mb-1" onClick={handleSubmit}>
              {t('set-password.send')}
            </Button>
          </div>
        </form>
      )}
    </div>
  );
}

export default SetPassword;

import { Button, ButtonStyle, Modal, Textarea, } from '@suzuki-frontend-framework-update/ui';
import { environment } from '../../../environments/environment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { sendApiRequest } from '../send-api-request/send-api-request';
import { toast } from 'react-toastify';
import { HidingReasonType } from '../../models/vehicle';
import { SelectInput } from "@sde/basic-ui-library";

export interface HideVehiclePopupProps {
  setModalState: (state: boolean) => void;
  modalState: boolean;
  vehicleId?: string | 'undefinded';
  refreshData?: () => void;
}

export const HideVehiclePopup = (props: HideVehiclePopupProps) => {
  const { t } = useTranslation();
  const [reason, setReason] = useState<HidingReasonType>(
    HidingReasonType.SOLD_TO_UNKNOWN
  );
  const [otherReasonDescription, setOtherReasonDescription] = useState('');
  const customIdFailed = 'custom-id-Failed';
  const customIdSuccess = 'custom-id-success';

  const successToast = (successText: string) => {
    toast.success(successText, {
      position: 'top-center',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      toastId: customIdSuccess,
    });
  };

  const errorToast = (errorText: string) => {
    toast.error(errorText, {
      position: 'top-center',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      toastId: customIdFailed,
    });
  };

  const handleCancel = () => {
    setReason(HidingReasonType.SOLD_TO_UNKNOWN);
    setOtherReasonDescription('');
    props.setModalState(false);
  };

  const handleSubmit = () => {
    if (
      reason === HidingReasonType.OTHER &&
      otherReasonDescription.trim() === ''
    ) {
      errorToast(t('hide-vehicle.other-reason-message-empty'));
    }
    sendApiRequest(
      `${environment.restEndpoint}/vehicle/vehicle-service/hide`,
      'post',
      {
        vehicleId: props.vehicleId,
        hidingDate: new Date(),
        reason: reason,
        otherReasonMessage: otherReasonDescription,
      }
    ).then((response) => {
      if (response.ok) {
        successToast(t('hide-vehicle.successfully-hidden'));
        handleCancel();
        if (props.refreshData) props.refreshData();
      } else {
        response.json().then((data) => {
          errorToast(t('error') + data.message);
        });
      }
    });
  };

  return (
    <div>
      <Modal
        key="hide-vehicle-modal"
        show={props.modalState}
        handleClose={() => handleCancel()}
        title={t('hide-vehicle.title')}
      >
        <div className="flex flex-col gap-y-6 pt-8 p-5">
          <div className="flex flex-col gap-y-6 items-end pr-28">
            <SelectInput
              options={[
                {
                  key: 0,
                  name: t(
                    'hide-vehicle.options.' +
                      HidingReasonType.SOLD_TO_UNKNOWN
                  ),
                  value: HidingReasonType.SOLD_TO_UNKNOWN,
                },
                {
                  key: 1,
                  name: t('hide-vehicle.options.' + HidingReasonType.OTHER),
                  value: HidingReasonType.OTHER,
                },
              ]}
              value={reason}
              width="25rem"
              onSelect={(value) => setReason(value as HidingReasonType)}
              label={{
                name: t('hide-vehicle.reason'),
                position: 'left',
              }}
            />
            {reason === 'OTHER' && (
              <Textarea
                value={otherReasonDescription}
                onChange={(value) => setOtherReasonDescription(value)}
                width="25rem"
                label={{
                  name: t('hide-vehicle.note'),
                  position: 'left',
                }}
                rows={8}
                resizeDisabled
              />
            )}
          </div>
          <div className="flex flex-row gap-x-6 justify-end">
            <Button
              className="bg-white"
              buttonStyle={ButtonStyle.OUTLINE}
              onClick={handleCancel}
              px="5"
              py="3"
            >
              {t('hide-vehicle.cancel')}
            </Button>
            <Button onClick={handleSubmit} px="5" py="3">
              {t('hide-vehicle.submit')}
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default HideVehiclePopup;

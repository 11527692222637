import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../setup'
import {SurveysFilters} from "../../models/receiver";

export const SURVEYS_FILTER_FEATURE_KEY = 'surveys-filter';

export interface SurveysFilterState {
  key: string;
  currentRow: string | undefined;
  surveysFilter: SurveysData;
}

export interface SurveysData {
  filter: SurveysFilters;
  pageIndex: number;
}

export const initialSurveysFilterState: SurveysFilterState = {
  key: "",
  currentRow: undefined,
  surveysFilter: {
    filter: {},
    pageIndex: 0
  }
};

const surveysFilterSlice = createSlice({
  name: SURVEYS_FILTER_FEATURE_KEY,
  initialState: initialSurveysFilterState,
  reducers: {
    init: (state, { payload: key }: PayloadAction<string>) => {
      state.key = key;
      state.currentRow = undefined;
      state.surveysFilter = {
        filter: {},
        pageIndex: 0
      };
    },
    updateSurveysFilter: (state, { payload: surveysFilter }: PayloadAction<SurveysFilters>) => {
      state.surveysFilter.filter = surveysFilter;
    },
    updateSurveysPageIndex: (state, { payload: pageIndex }: PayloadAction<number>) => {
      state.surveysFilter.pageIndex = pageIndex;
    },
    updateSurveysCurrentRow: (state, { payload: currentRow }: PayloadAction<string | undefined>) => {
      state.currentRow = currentRow;
    },
  }
});

export const surveysFilterActions = surveysFilterSlice.actions;

export const surveysFilterSelector = (state: RootState) => state[SURVEYS_FILTER_FEATURE_KEY]

export const surveysFilterReducer = surveysFilterSlice.reducer;

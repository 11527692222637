import { CrrEntry } from "../../models/crr-entry";
import { Column } from "react-table";
import { Link } from "react-router-dom";


export const monthNames = ["Jan", "Feb", "Mär", "Apr", "Mai", "Jun",
  "Jul", "Aug", "Sep", "Okt", "Nov", "Dez"
];

export interface CrrReportData {
  columns: Column<CrrReportRow>[];
  rows: CrrReportRow[]
}

export interface CrrReportRow {
  inspectionNumber: number;
  monthValues: Map<string, CrrMonthValue>;
  sum: CrrMonthValue;
  average: CrrMonthValue;
}

export interface CrrMonthValue {
  inspectionPlanned: number;
  inspectionInvited: number;
  inspectionCompleted: number;
  inspectionsRate: number;
  cumulatedInspectionsRate: number;
}


function getInspectionsRate(monthValue: CrrMonthValue) {
  return monthValue.inspectionPlanned > 0 && monthValue.inspectionCompleted > 0 ?
    monthValue.inspectionCompleted / monthValue.inspectionPlanned :
    monthValue.inspectionPlanned == 0 && monthValue.inspectionCompleted > 0 ? 1 : 0;
}

const getTotalRow = (rows: CrrReportRow[]) => {
  const totalMonthValues = new Map<string, CrrMonthValue>();
  const totalSum: CrrMonthValue = {
    inspectionPlanned: 0,
    inspectionInvited: 0,
    inspectionCompleted: 0,
    inspectionsRate: 0,
    cumulatedInspectionsRate: 0,
  }
  rows.filter(row => row.inspectionNumber >= 1 && row.inspectionNumber <= 10).forEach(row => {
    row.monthValues.forEach((value, index) => {
      const totalMonthValue = totalMonthValues.get(index)
      if (!totalMonthValue) {
        totalMonthValues.set(index, {
          inspectionPlanned: value.inspectionPlanned,
          inspectionInvited: value.inspectionInvited,
          inspectionCompleted: value.inspectionCompleted,
          inspectionsRate: 0,
          cumulatedInspectionsRate: 0,
        })
      } else {
        totalMonthValues.set(index, {
          inspectionPlanned: totalMonthValue.inspectionPlanned + value.inspectionPlanned,
          inspectionInvited: totalMonthValue.inspectionInvited + value.inspectionInvited,
          inspectionCompleted: totalMonthValue.inspectionCompleted + value.inspectionCompleted,
          inspectionsRate: 0,
          cumulatedInspectionsRate: 0,
        })
      }
    })
    totalSum.inspectionPlanned += row.sum.inspectionPlanned
    totalSum.inspectionCompleted += row.sum.inspectionCompleted
    totalSum.inspectionInvited += row.sum.inspectionInvited
  })
  totalSum.inspectionsRate = getInspectionsRate(totalSum)
  let plannedTillNow = 0
  let completedTillNow = 0
  totalMonthValues.forEach((value, index) => {
    plannedTillNow += value.inspectionPlanned
    completedTillNow += value.inspectionCompleted
    value.inspectionsRate = getInspectionsRate(value)
    value.cumulatedInspectionsRate = plannedTillNow > 0 && completedTillNow > 0 ?
      completedTillNow / plannedTillNow :
      plannedTillNow == 0 && completedTillNow > 0 ? 1 : 0;
  })

  return {
    monthValues: totalMonthValues,
    sum: totalSum,
    average: {
      inspectionPlanned: totalSum.inspectionPlanned / totalMonthValues.size,
      inspectionCompleted: totalSum.inspectionCompleted / totalMonthValues.size,
      inspectionInvited: totalSum.inspectionInvited / totalMonthValues.size,
      inspectionsRate: totalSum.inspectionsRate
    },
    inspectionNumber: -1,
  } as CrrReportRow;
};

export function getIdFromDate(date: Date) {
  return date.getMonth() + "_" + date.getFullYear()
}

export function getCurrentFiscalYear() {
  const today = new Date()
  let fiscalYear = today.getFullYear()
  if (today.getMonth() < 3) {
    fiscalYear--
  }
  return fiscalYear
}

function roundNumber(num: number) {
  return Math.round((num + Number.EPSILON) * 100) / 100
}

export function getCellContent(value: CrrMonthValue, showCumulated: boolean, zeroInspection: boolean, dateString?: string, inspectionNumber?: number) {
  return zeroInspection ? (
    <div className={"flex flex-col items-center w-full"}>
      <span className={"w-full text-center"}>
        {roundNumber(value.inspectionCompleted)}
      </span>
    </div>
  ) : (
    <div className={"flex flex-col items-center w-full"}>
      <span className={"py-1 border-b border-gray w-full text-center"}>
        {dateString ? <Link to={`/inspection-planned?date=${dateString}&inspectionNumber=${inspectionNumber}${inspectionNumber === -1 ? '&range='+(showCumulated ? 5 : 10) : ''}`}>
          <div className={'underline'}>
            {roundNumber(value.inspectionPlanned)}
          </div>
        </Link> : roundNumber(value.inspectionPlanned)}
      </span>

      <span className={"py-1 border-b border-gray w-full text-center"}>
        {dateString ? <Link
          to={`/communication/inspections-invitations/sent?dispatchDate=${dateString}&inspectionNumber=${inspectionNumber}${inspectionNumber === -1 ? '&range=' + (showCumulated ? 5 : 10) : ''}`}>
          <div className={'underline'}>
            {roundNumber(value.inspectionInvited)}
          </div>
        </Link> : roundNumber(value.inspectionInvited)}
      </span>
      <span className={"py-1 border-b border-gray w-full text-center"}>
         {dateString ? <Link to={`/inspection-completed?date=${dateString}&inspectionNumber=${inspectionNumber}${inspectionNumber === -1 ? '&range='+(showCumulated ? 5 : 10) : ''}`}>
           <div className={'underline'}>
             {roundNumber(value.inspectionCompleted)}
           </div>
         </Link> : roundNumber(value.inspectionCompleted)}
      </span>
      <span className={"pt-1"}>
        {(value.inspectionsRate * 100).toFixed(0)}%
      </span>
      {showCumulated &&
        <span className={"pt-1 mt-1 font-suzuki-bold border-t border-gray w-full text-center"}>
          {value.cumulatedInspectionsRate > 0 ? (value.cumulatedInspectionsRate * 100).toFixed(0) + '%' : '-'}
        </span>}
    </div>
  );
}

function getZeroKmInspection(startDate: Date, endDate: Date, entries: CrrEntry[]) {
  const zeroInspMonthValues = new Map<string, CrrMonthValue>();
  const zeroInspSum: CrrMonthValue = {
    inspectionPlanned: 0,
    inspectionInvited: 0,
    inspectionCompleted: 0,
    inspectionsRate: 0,
    cumulatedInspectionsRate: 0,
  }
  for (let date = new Date(startDate); date.getTime() < endDate.getTime(); date.setMonth(date.getMonth() + 1)) {
    const monthValue: CrrMonthValue = {
      inspectionPlanned: 0,
      inspectionInvited: 0,
      inspectionCompleted: 0,
      inspectionsRate: 0,
      cumulatedInspectionsRate: 0,
    }
    const month = date.getMonth() + 1
    const year = date.getFullYear()
    entries.filter(entry => entry.month === month.toString() && entry.year === year.toString()).forEach(entry => {
      monthValue.inspectionCompleted += entry.thousandKmInspections ? entry.thousandKmInspections : 0
    })

    zeroInspSum.inspectionCompleted += monthValue.inspectionCompleted
    zeroInspMonthValues.set(getIdFromDate(date), monthValue)
  }
  return {
    monthValues: zeroInspMonthValues,
    sum: zeroInspSum,
    average: {
      inspectionPlanned: 0,
      inspectionCompleted: zeroInspSum.inspectionCompleted / zeroInspMonthValues.size,
      inspectionInvited: 0,
      inspectionsRate: 0,
      cumulatedInspectionsRate: 0,
    },
    inspectionNumber: 0,
  } as CrrReportRow
}

export const processEntries = (entries: CrrEntry[], startDate: Date, endDate: Date, isFiscalWithBonus: boolean): CrrReportRow[] => {
  const rows: CrrReportRow[] = []
  const inspectionsRange = isFiscalWithBonus ? 5 : 10
  for (let inspectionNumber = 1; inspectionNumber <= inspectionsRange; inspectionNumber++) {
    const monthValues = new Map<string, CrrMonthValue>();
    const sum: CrrMonthValue = {
      inspectionPlanned: 0,
      inspectionInvited: 0,
      inspectionCompleted: 0,
      inspectionsRate: 0,
      cumulatedInspectionsRate: 0,
    }
    for (let date = new Date(startDate); date.getTime() < endDate.getTime(); date.setMonth(date.getMonth() + 1)) {
      const monthValue: CrrMonthValue = {
        inspectionPlanned: 0,
        inspectionInvited: 0,
        inspectionCompleted: 0,
        inspectionsRate: 0,
        cumulatedInspectionsRate: 0,
      }
      const month = date.getMonth() + 1
      const year = date.getFullYear()
      const value = entries.find(entry => entry.month === month.toString() && entry.year === year.toString())?.values
        .find(v => v.inspectionNumber === inspectionNumber)
      if (value !== undefined) {
        monthValue.inspectionPlanned = value.inspectionPlanned ? value.inspectionPlanned : 0
        monthValue.inspectionInvited = value.inspectionInvited ? value.inspectionInvited : 0
        monthValue.inspectionCompleted = value.inspectionCompleted ? value.inspectionCompleted : 0
      }

      sum.inspectionPlanned += monthValue.inspectionPlanned
      sum.inspectionInvited += monthValue.inspectionInvited
      sum.inspectionCompleted += monthValue.inspectionCompleted
      monthValue.inspectionsRate = getInspectionsRate(monthValue)
      monthValues.set(getIdFromDate(date), monthValue)
    }
    sum.inspectionsRate = getInspectionsRate(sum)
    rows.push({
      monthValues: monthValues,
      sum: sum,
      average: {
        inspectionPlanned: sum.inspectionPlanned / monthValues.size,
        inspectionCompleted: sum.inspectionCompleted / monthValues.size,
        inspectionInvited: sum.inspectionInvited / monthValues.size,
        inspectionsRate: sum.inspectionsRate
      },
      inspectionNumber: inspectionNumber,
    } as CrrReportRow)
  }
  rows.push(getTotalRow(rows))
  return [getZeroKmInspection(startDate, endDate, entries), ...rows]
}


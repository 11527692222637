export interface AttributeResponse {
    name: string;
    value: string;
    declaration: Date;
    additionalAttributes: AdditionalAttributes;
}

export interface AdditionalAttributes {
    attribute: Attribute[];
}

export interface Attribute {
    key: string;
    value: string;
}

export interface DpeReportEntry {
    customerData: CustomerInformation;
    attributes: AttributeResponse[];
}

export interface CustomerInformation {
    id: string|undefined;
    salutation: string|undefined;
    firstName: string|undefined;
    lastName: string|undefined;
    company: string|undefined;
    state: string|undefined;
    customerNumber: string|undefined;
    street: string|undefined;
    city: string|undefined;
    zip: string|undefined;
    phone: string|undefined;
    email: string|undefined;
}

export interface AttributeData {
    value: string;
    declaration: string;
    documentStatus: string;
}

export const displayableConsents = ["post", "email", "sms", "telephone", "mobilephone", "fax", "mare"]

export interface DpdReportRequest {
    isAdmin: boolean,
    dealercode: string[],
    apiKey: string,
    authentication: {
        ssotoken: string,
        applicationkey: string,
        username: string
    }
    isCompany: boolean,
    filter: CusReportFilterReq
}

export interface CusReportFilterReq {
    pageSize: number|undefined,
    pageIndex : number|undefined,
    poolIds: string[]
}

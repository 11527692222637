import {useEffect, useState} from 'react';
import {Headline, MultipleValueInputRow, MultiselectOptionsProps,} from '@suzuki-frontend-framework-update/ui';
import './contact-edit.module.scss';
import {useApiGetData} from '../../components/useApiGetData/useApiGetData';
import {Company, Contact, ContactEmail, ContactFax, ContactPhone, ContactRequestBody,} from '../../models/contact';
import {environment} from '../../../environments/environment';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';
import {sendApiRequest} from '../../components/send-api-request/send-api-request';
import {format} from 'react-string-format';
import ContactForm, {ContactValue} from '../contact-form/contact-form';
import {toast} from 'react-toastify';
import {validateEmailOrEmpty} from '../../helpers/email-validation-helper';
import { isValidStreet } from '../../helpers/contact-helper';

export function ContactEdit() {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {id} = useParams();

  const [requestBody] = useState({
    id: id,
    details: 'FULL',
  });
  const contactData = useApiGetData<Contact>(
    `${environment.restEndpoint}/contact/contact-service/contact/GetContact`,
    'post',
    undefined,
    requestBody
  );

  const [formValues, setFormValues] = useState<ContactValue>({
    email: [],
    phone: [],
    fax: [],
  });

  const [companiesOptions, setCompaniesOptions] = useState<MultiselectOptionsProps[]>(
    []
  );

  const [companiesRequestBody, setCompaniesRequestBody] =
    useState<ContactRequestBody>({
      details: 'DEFAULT',
      sortField: 'name',
      sortDir: 1,
      filter: {dealers: []}
    });

  const companiesData = useApiGetData<Company[]>(
    `${environment.restEndpoint}/contact/contact-service/company/GetCompanies`,
    'post',
    'companies',
    companiesRequestBody
  );

  useEffect(() => {
    if (companiesOptions.length <= 0 && companiesData.data && companiesData.data.length > 0) {
      const toSelectOptions = (
        companies: Company[]
      ): MultiselectOptionsProps[] => {
        if (companies && companies.length > 0) {
          return companies.filter((c) => c.name).map((c) => {
            return {
              key: c.objectId,
              name: `${c.name} (${c.customerNumber})`,
              value: c.objectId,
            } as MultiselectOptionsProps;
          });
        } else {
          return [];
        }
      };
      setCompaniesOptions(toSelectOptions(companiesData.data));
    }
  }, [companiesData.data, companiesData.loading]);

  const customIdSuccess = 'custom-id-success';
  const customIdFailed = 'custom-id-Failed';
  const successToast = (successText: string) => {
    toast.success(successText, {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      toastId: customIdSuccess,
    });
  };

  const errorToast = (errorText: string) => {
    toast.error(errorText, {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      toastId: customIdFailed,
    });
  };

  useEffect(() => {
    let key = 0;
    const getEmails = (): MultipleValueInputRow[] => {
      const result: MultipleValueInputRow[] = [];
      if (contactData.data?.email) {
        contactData.data?.email.forEach((email) => {
          if (email.email)
            result.push({
              key: key,
              inputValue: email.email,
              selectValue: email.type ? email.type : '',
              default: email.default ? email.default : false,
            });
          key++;
        });
      }
      return result;
    };

    const getPhones = (): MultipleValueInputRow[] => {
      const result: MultipleValueInputRow[] = [];
      if (contactData.data?.phone) {
        contactData.data?.phone.forEach((phone) => {
          if (phone.phoneNumber)
            result.push({
              key: key,
              inputValue: phone.phoneNumber,
              selectValue: phone.type ? phone.type : '',
              default: phone.default ? phone.default : false,
            });
          key++;
        });
      }
      return result;
    };

    const getFaxes = (): MultipleValueInputRow[] => {
      const result: MultipleValueInputRow[] = [];
      if (contactData.data?.fax) {
        contactData.data?.fax.forEach((fax) => {
          if (fax.faxNumber)
            result.push({
              key: key,
              inputValue: fax.faxNumber,
              selectValue: fax.type ? fax.type : '',
              default: fax.default ? fax.default : false,
            });
          key++;
        });
      }
      return result;
    };

    if (!contactData.loading) {
      setFormValues({
        gender: contactData.data?.salutation,
        title: contactData.data?.title,
        firstname: contactData.data?.firstname,
        surname: contactData.data?.name,
        birthday: contactData.data?.householdData?.birthdate
          ? new Date(contactData.data?.householdData?.birthdate)
          : undefined,
        street: contactData.data?.address?.street,
        address: contactData.data?.address?.addressline1,
        postcode: contactData.data?.address?.zip,
        city: contactData.data?.address?.city,
        countrycode: contactData.data?.address?.countrycode,
        email: getEmails(),
        phone: getPhones(),
        fax: getFaxes(),
        personsInHousehold: contactData.data?.householdData?.personsathome
          ? contactData.data?.householdData?.personsathome?.toString()
          : '',
        job: contactData.data?.householdData?.jobId,
        maritalStatus: contactData.data?.householdData?.familystatusId,
        sports: contactData.data?.householdData?.sport,
        activities: contactData.data?.householdData?.leisureaction,
        companyId: contactData.data?.companyId,
      });
    }
  }, [contactData.data, contactData.loading]);

  const handleSubmit = (formValues: ContactValue) => {
    const emails: ContactEmail[] = [];
    const phones: ContactPhone[] = [];
    const faxes: ContactFax[] = [];
    let mailValid = true;
    formValues.email.forEach((email) => {
      if (!validateEmailOrEmpty(email.inputValue.toLowerCase())) {
        mailValid = false;
      }
      emails.push({
        email: email.inputValue,
        default: email.default,
        type: email.selectValue,
      });
    });
    formValues.phone.forEach((phone) => {
      phones.push({
        phoneNumber: phone.inputValue,
        default: phone.default,
        type: phone.selectValue,
      });
    });
    formValues.fax.forEach((fax) => {
      faxes.push({
        faxNumber: fax.inputValue,
        default: fax.default,
        type: fax.selectValue,
      });
    });

    if (!mailValid) {
      errorToast(t('contact-form.errors.wrong-email'));
      return;
    }
    if (!formValues.firstname || !formValues.surname) {
      errorToast(t('contact-form.errors.name-required'));
      return;
    } else if (formValues.firstname || formValues.surname) {
      if (!formValues.firstname.trim() || !formValues.surname.trim()) {
        errorToast(t('contact-form.errors.name-required'));
        return;
      }
    }
    const isPostalAddressComplete =
    formValues.street?.trim() &&
    formValues.postcode?.trim() &&
    formValues.city?.trim();

    const isEmailAddressPresent = formValues.email.some(email => email.inputValue?.trim());



    if (!isPostalAddressComplete && !isEmailAddressPresent) {
      errorToast(t('contact-form.errors.email-or-adress-required'));
      return;
    }
  
    if (isPostalAddressComplete) {
      if (!formValues.street || !isValidStreet(formValues.street.trim())) {
        errorToast(t('contact-form.errors.invalid-street'));
        return;
      }
      if (!formValues.postcode?.trim() || !formValues.city?.trim()) {
        errorToast(t('contact-form.errors.email-or-adress-required'));
        return;
      }
    }
    if (!formValues.phone[0]?.inputValue) {
      errorToast(t('contact-form.errors.phone-required'));
      return;
    }

    sendApiRequest(
      `${environment.restEndpoint}/contact/contact-service/contact/UpdateContact`,
      'PUT',
      {
        id: id,
        contact: {
          ...contactData.data,
          title: formValues.title,
          salutation: formValues.gender,
          firstname: formValues.firstname,
          name: formValues.surname,
          householdData: {
            birthdate: formValues.birthday,
            personsathome:
              formValues.personsInHousehold === '' ||
              !formValues.personsInHousehold
                ? undefined
                : parseInt(formValues.personsInHousehold),
            jobId: formValues.job,
            familystatusId: formValues.maritalStatus,
            sport: formValues.sports,
            leisureaction: formValues.activities,
          },
          address: {
            addressline1: formValues.address,
            street: formValues.street,
            zip: formValues.postcode,
            city: formValues.city,
            countrycode: formValues.countrycode,
          },
          email: emails,
          phone: phones,
          fax: faxes,
          modified: undefined,
          modifiedBy: undefined,
          companyId: formValues.companyId,
        } as Contact,
      }
    ).then((response) => {
      if (response.ok) {
        successToast(t('contact-form.success'));
        navigate(-1);
      } else {
        errorToast(t('contact-form.error') + response.statusText);
      }
    });
  };

  const handleCancel = () => {
    navigate(-1);
  };

  return (
    <div className="grow bg-gray">
      <Headline className="p-8 pb-0 " title={t('contact-form.edit-contact')}/>
      <div className="text-dark-gray p-8 pt-0 -mt-2 ">
        {contactData.data?.created && (
          <span className="text-dark-gray  font-suzuki-regular">
            {format(
              t('contact-details.created-at'),
              new Date(contactData.data?.created).toLocaleDateString('de-DE', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }),
              contactData.data?.createdBy
                ? contactData.data?.createdBy
                : '-'
            )}
          </span>
        )}
        {contactData.data?.modified && (
          <span className="text-dark-gray  font-suzuki-regular ml-3">
            {format(
              t('contact-details.modified-at'),
              new Date(contactData.data?.modified).toLocaleDateString('de-DE', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }),
              contactData.data?.modifiedBy
                ? contactData.data?.modifiedBy
                : '-'
            )}
          </span>
        )}
      </div>

      <ContactForm
        handleCancel={handleCancel}
        handleSubmit={handleSubmit}
        formValues={formValues}
        companiesOptions={companiesOptions}
      />
    </div>
  );
}

export default ContactEdit;

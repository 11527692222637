import { useState, useEffect, useRef } from 'react';
import { sendApiRequest } from '../send-api-request/send-api-request';

export type ApiResponse<T> = {
  status: number;
  statusText: string;
  data?: T;
  error: any;
  loading: boolean;
  refreshData: () => void;
};

/**
 * Custon hook to fetch data from an api
 * @param url {string}  the url of the api
 * @param method {string}  the method that should be used a.g: POST
 * @param name {string}  which object name should be selected
 * @param bodyContent {any}  body content of request
 */
export function useApiGetData<T>(
  url: string,
  method: string,
  name?: string,
  bodyContent?: any,
  disabled?: boolean,
  contentType?: string
): ApiResponse<T> {
  const [status, setStatus] = useState<number>(0);
  const [statusText, setStatusText] = useState<string>('');
  const [data, setData] = useState<T>();
  const [error, setError] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);
  const fetchId = useRef(0);

  const getAPIData = async (id: number) => {
    setData(undefined);
    setLoading(true);
    try {
      const headers = contentType ? new Map([['Content-type', contentType]]) : new Map;
      const apiResponse = await sendApiRequest(url, method, bodyContent, headers);
      const json = await apiResponse.json();
      if (id === fetchId.current) {
        if (!apiResponse.ok) console.log(json);
        setStatus(apiResponse.status);
        setStatusText(apiResponse.statusText);
        if (name) {
          setData(json[name]);
        } else {
          setData(json);
        }
      }
    } catch (error) {
      if (id === fetchId.current) {
        setError(error);
      }
    }
    if (id === fetchId.current) {
      setLoading(false);
    }
  };

  const refreshData = () => {
    if (!disabled) {
      fetchId.current += 1;
      getAPIData(fetchId.current);
    }
  };

  useEffect(() => {
    refreshData();
    return () => {
      setStatus(0);
      setLoading(false);
      setStatusText('');
      setData(undefined);
      setError(undefined);
    };
  }, [url, bodyContent]);

  return { status, statusText, data, error, loading, refreshData };
}

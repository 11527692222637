export interface Pools {
    id: string,
    dealerName: string,
    dealerNumber: string,
    numberOfContacts: string,
}

const _pools: Pools[] = [
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '1561651325531',
    'dealerName': 'Autohaus Hachmann GmbH',
    'dealerNumber': '036687',
    'numberOfContacts': '698',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '1561651325531',
    'dealerName': 'Autohaus Hachmann GmbH',
    'dealerNumber': '036687',
    'numberOfContacts': '698',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '1561651325531',
    'dealerName': 'Autohaus Hachmann GmbH',
    'dealerNumber': '036687',
    'numberOfContacts': '698',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '1561651325531',
    'dealerName': 'Autohaus Hachmann GmbH',
    'dealerNumber': '036687',
    'numberOfContacts': '698',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '1561651325531',
    'dealerName': 'Autohaus Hachmann GmbH',
    'dealerNumber': '036687',
    'numberOfContacts': '698',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '1561651325531',
    'dealerName': 'Autohaus Hachmann GmbH',
    'dealerNumber': '036687',
    'numberOfContacts': '698',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '1561651325531',
    'dealerName': 'Autohaus Hachmann GmbH',
    'dealerNumber': '036687',
    'numberOfContacts': '698',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '1561651325531',
    'dealerName': 'Autohaus Hachmann GmbH',
    'dealerNumber': '036687',
    'numberOfContacts': '698',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '1561651325531',
    'dealerName': 'Autohaus Hachmann GmbH',
    'dealerNumber': '036687',
    'numberOfContacts': '698',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '1561651325531',
    'dealerName': 'Autohaus Hachmann GmbH',
    'dealerNumber': '036687',
    'numberOfContacts': '698',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '1561651325531',
    'dealerName': 'Autohaus Hachmann GmbH',
    'dealerNumber': '036687',
    'numberOfContacts': '698',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '1561651325531',
    'dealerName': 'Autohaus Hachmann GmbH',
    'dealerNumber': '036687',
    'numberOfContacts': '698',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '1561651325531',
    'dealerName': 'Autohaus Hachmann GmbH',
    'dealerNumber': '036687',
    'numberOfContacts': '698',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '1561651325531',
    'dealerName': 'Autohaus Hachmann GmbH',
    'dealerNumber': '036687',
    'numberOfContacts': '698',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '1561651325531',
    'dealerName': 'Autohaus Hachmann GmbH',
    'dealerNumber': '036687',
    'numberOfContacts': '698',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '1561651325531',
    'dealerName': 'Autohaus Hachmann GmbH',
    'dealerNumber': '036687',
    'numberOfContacts': '698',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '1561651325531',
    'dealerName': 'Autohaus Hachmann GmbH',
    'dealerNumber': '036687',
    'numberOfContacts': '698',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '1561651325531',
    'dealerName': 'Autohaus Hachmann GmbH',
    'dealerNumber': '036687',
    'numberOfContacts': '698',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '1561651325531',
    'dealerName': 'Autohaus Hachmann GmbH',
    'dealerNumber': '036687',
    'numberOfContacts': '698',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '1561651325531',
    'dealerName': 'Autohaus Hachmann GmbH',
    'dealerNumber': '036687',
    'numberOfContacts': '698',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '1561651325531',
    'dealerName': 'Autohaus Hachmann GmbH',
    'dealerNumber': '036687',
    'numberOfContacts': '698',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '1561651325531',
    'dealerName': 'Autohaus Hachmann GmbH',
    'dealerNumber': '036687',
    'numberOfContacts': '698',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '1561651325531',
    'dealerName': 'Autohaus Hachmann GmbH',
    'dealerNumber': '036687',
    'numberOfContacts': '698',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '1561651325531',
    'dealerName': 'Autohaus Hachmann GmbH',
    'dealerNumber': '036687',
    'numberOfContacts': '698',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '1561651325531',
    'dealerName': 'Autohaus Hachmann GmbH',
    'dealerNumber': '036687',
    'numberOfContacts': '698',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '1561651325531',
    'dealerName': 'Autohaus Hachmann GmbH',
    'dealerNumber': '036687',
    'numberOfContacts': '698',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '1561651325531',
    'dealerName': 'Autohaus Hachmann GmbH',
    'dealerNumber': '036687',
    'numberOfContacts': '698',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '1561651325531',
    'dealerName': 'Autohaus Hachmann GmbH',
    'dealerNumber': '036687',
    'numberOfContacts': '698',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '1561651325531',
    'dealerName': 'Autohaus Hachmann GmbH',
    'dealerNumber': '036687',
    'numberOfContacts': '698',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '1561651325531',
    'dealerName': 'Autohaus Hachmann GmbH',
    'dealerNumber': '036687',
    'numberOfContacts': '698',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '1561651325531',
    'dealerName': 'Autohaus Hachmann GmbH',
    'dealerNumber': '036687',
    'numberOfContacts': '698',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '1561651325531',
    'dealerName': 'Autohaus Hachmann GmbH',
    'dealerNumber': '036687',
    'numberOfContacts': '698',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '1561651325531',
    'dealerName': 'Autohaus Hachmann GmbH',
    'dealerNumber': '036687',
    'numberOfContacts': '698',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '1561651325531',
    'dealerName': 'Autohaus Hachmann GmbH',
    'dealerNumber': '036687',
    'numberOfContacts': '698',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
    {
    'id': '464568131322',
    'dealerName': 'Lewandowski Fzg.Handel Inh. Marco Lewandowski e.K.',
    'dealerNumber': '0355687',
    'numberOfContacts': '562',
    },
];

export function getPools(): Promise<Pools[]> {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(_pools);
      }, 500);
    });
  }
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../setup'
import {CcpExportRequest, CcpExportFilter, ExportState} from "../../models/ccp-export";

export const SERVICE_CCP_EXPORT_FILTER_FEATURE_KEY = 'service-ccp-export-filter';

export interface ServiceCcpExportFilterState {
  key: string;
  currentRow: string | undefined;
  serviceCcpExportFilter: ServiceCcpExportData;
}

export interface ServiceCcpExportData {
  filter: CcpExportFilter;
  pageIndex: number;
}

export const initialServiceCcpExportFilterState: ServiceCcpExportFilterState = {
  key: "",
  currentRow: undefined,
  serviceCcpExportFilter: {
    filter: {
      sendoutTypes: ["2", "2e"],
      exported: ExportState.ALL
    },
    pageIndex: 0
  }
};

const serviceCcpExportFilterSlice = createSlice({
  name: SERVICE_CCP_EXPORT_FILTER_FEATURE_KEY,
  initialState: initialServiceCcpExportFilterState,
  reducers: {
    init: (state, { payload: key }: PayloadAction<string>) => {
      state.key = key;
      state.currentRow = undefined;
      state.serviceCcpExportFilter = {
        filter: {
          sendoutTypes: ["2", "2e"],
          exported: ExportState.ALL
        },
        pageIndex: 0
      };
    },
    updateServiceCcpExportFilter: (state, { payload: serviceCcpExportFilter }: PayloadAction<CcpExportFilter>) => {
      state.serviceCcpExportFilter.filter = serviceCcpExportFilter;
    },
    updateServiceCcpExportPageIndex: (state, { payload: pageIndex }: PayloadAction<number>) => {
      state.serviceCcpExportFilter.pageIndex = pageIndex;
    },
    updateServiceCcpExportCurrentRow: (state, { payload: currentRow }: PayloadAction<string | undefined>) => {
      state.currentRow = currentRow;
    },
  }
});

export const serviceCcpExportFilterActions = serviceCcpExportFilterSlice.actions;

export const serviceCcpExportFilterSelector = (state: RootState) => state[SERVICE_CCP_EXPORT_FILTER_FEATURE_KEY]

export const serviceCcpExportFilterReducer = serviceCcpExportFilterSlice.reducer;

import {Headline, MultiselectOptionsProps,} from '@suzuki-frontend-framework-update/ui';
import {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './vehicle-edit.module.scss';
import {useApiGetData} from '../../components/useApiGetData/useApiGetData';
import {environment} from '../../../environments/environment';
import {Vehicle} from '../../models/vehicle';
import {sendApiRequest} from '../../components/send-api-request/send-api-request';
import {VehicleForm, VehicleFormValue} from '../vehicle-form/vehicle-form';
import {Dealer} from '../../models/dealer';
import {useSelector} from 'react-redux';
import {selectAllUserManagement} from '../../store/slices/user-management.slice';
import {Session} from '@ory/client';
import {format} from 'react-string-format';
import {VehicleRegistration} from "../../models/vehicle-registration";

export function VehicleEdit() {
  const navigate = useNavigate();
  const {t} = useTranslation();
  const {id} = useParams();
  const customIdSuccess = 'custom-id-success';
  const customIdFailed = 'custom-id-Failed';

  const successToast = (successText: string) => {
    toast.success(successText, {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      toastId: customIdSuccess,
    });
  };

  const errorToast = (errorText: string) => {
    toast.error(errorText, {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      toastId: customIdFailed,
    });
  };

  const entities = useSelector(selectAllUserManagement);
  const [session, setSession] = useState({} as Session);

  useEffect(() => {
    const lastEntity = entities[entities.length - 1];
    if (lastEntity && lastEntity.currentUser) {
      setSession(lastEntity.currentUser);
    }
  }, [entities]);

  const [formValues, setFormValues] = useState<VehicleFormValue>({
    vin: '',
    make: '',
    model: '',
    type: '',
    registrationDate: undefined,
    equipment: '',
    registrationNumber: '',
    color: '',
    engineVolume: 0,
    fuel: '',
    owner: [],
  });
  const [vehicle, setVehicle] = useState<Vehicle>();
  const [registration, setRegistration] =
    useState<VehicleRegistration>();
  const [dealerOptions, setDealerOptions] = useState<MultiselectOptionsProps[]>(
    []
  );
  const [owner, setOwner] = useState<string[]>([]);
  const vehicleData = useApiGetData<Vehicle>(
    `${environment.restEndpoint}/vehicle/vehicle-service/vehicle?ID=${id}`,
    'get'
  );

  const registrationsData = useApiGetData<VehicleRegistration[]>(
    `${environment.restEndpoint}/vehicle/vehicle-service/registration/GetByVehicleID?ID=${id}`,
    'get',
    'registrations'
  );

  const dealerData = useApiGetData<Dealer[]>(
    `${environment.restEndpoint}/dealer/dealer-service/dealers`,
    'post',
    'dealers'
  );

  useEffect(() => {
    if (dealerData.status !== 200) {
      return;
    }

    if (dealerOptions.length <= 0) {
      const toSelectOptions = (
        dealers: Dealer[] | undefined
      ): MultiselectOptionsProps[] => {
        if (dealers && dealers.length > 0) {
          return dealers.map((d) => {
            return {
              key: d.objectID,
              name: `${d.name} ${d.nameAppendum} (${d.dealercode})`,
              value: d.dealercode,
            } as MultiselectOptionsProps;
          });
        } else {
          return [];
        }
      };
      const options = toSelectOptions(dealerData.data);
      setDealerOptions(options);

      const assignedDealers = options.filter((dos) => {
        return vehicle?.owner?.includes(dos.value as string);
      });
      setFormValues({...formValues, owner: assignedDealers});
    }
  }, [dealerData]);

  useEffect(() => {
    if (vehicleData.loading || registrationsData.loading) return;

    let form = formValues;
    if (!(vehicleData.status >= 400) && vehicleData.data) {
      form = {
        ...form,
        vin: vehicleData.data?.vin,
        make: vehicleData.data?.brand,
        model: vehicleData.data?.model,
        type: vehicleData.data?.type,
        registrationDate: vehicleData.data?.firstRegistraion
          ? new Date(vehicleData.data?.firstRegistraion)
          : undefined,
        equipment: vehicleData.data?.configurationtype,
        color: vehicleData.data?.VehicleSpecification?.color,
        engineVolume:
        vehicleData.data?.VehicleSpecification?.vehicleloadcapacity,
        fuel: vehicleData.data?.VehicleSpecification?.fueltype,
      }
      setOwner(vehicleData.data?.owner ? vehicleData.data?.owner : []);
      setVehicle(vehicleData.data);
    }
    if (!(registrationsData.status >= 400) && registrationsData.data) {
      const activeReg = registrationsData.data?.filter((r) => r.active).shift()
      form = {
        ...form,
        registrationNumber: activeReg?.licensenumber,
      }
      setRegistration(activeReg)
    }
    setFormValues(form);
  }, [vehicleData.loading, registrationsData.loading]);

  const handleSubmit = async (formValues: VehicleFormValue) => {
    if (!formValues.vin) {
      errorToast(t('error-required'));

      return;
    } else if (formValues) {
      if (!formValues.vin.trim()) {
        errorToast(t('error-required'));
        return;
      }
    }
    const request = Object.assign(
      {},
      {
        ...vehicle,
        vin: formValues.vin,
        brand: formValues.make,
        model: formValues.model,
        type: formValues.type,
        firstRegistraion: formValues.registrationDate,
        configurationtype: formValues.equipment,
        VehicleSpecification: {
          ...vehicle?.VehicleSpecification,
          color: formValues.color,
          vehicleloadcapacity: formValues.engineVolume,
          fueltype: formValues.fuel,
        },
        owner: session.identity.traits.isAdmin
          ? createOwnerList(formValues.owner ? formValues.owner : [])
          : owner,
        modified: new Date(),
        modifiedBy: session?.identity?.traits?.username,
      }
    );

    try {
      const vehicleUpdateResponse = await sendApiRequest(
        `${environment.restEndpoint}/vehicle/vehicle-service/vehicle`,
        'PUT',
        {
          id: id,
          vehicle: request,
        }
      );
      if (!vehicleUpdateResponse.ok) {
        errorToast(t('error') + vehicleUpdateResponse.statusText);
        return;
      }

      if (registration) {
        const registrationUpdateResponse = await sendApiRequest(
          `${environment.restEndpoint}/vehicle/vehicle-service/registration`,
          'PUT',
          {
            id: registration.objectId,
            registraion: {
              ...registration,
              licensenumber: formValues.registrationNumber
            } as VehicleRegistration,
          }
        );
        if (!registrationUpdateResponse.ok) {
          errorToast(t('error') + registrationUpdateResponse.statusText);
          return;
        }
      }
      successToast(t('success'));
      navigate(-1);
    } catch (e) {
      errorToast(t('error'));
    }
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const createOwnerList = (os: MultiselectOptionsProps[]): string[] => {
    const ret: string[] = [];
    os.forEach((o) => ret.push(o.value as string));

    return ret;
  };

  return (
    <div className="grow">
      <Headline className="p-8 pb-0 " title={t('vehicle-edit.title')}/>

      <div className="text-dark-gray p-8 pt-0 -mt-2 ">
        {vehicleData.data?.created && (
          <span className="text-dark-gray  font-suzuki-regular">
            {format(
              t('contact-details.created-at'),
              new Date(vehicleData.data?.created).toLocaleDateString('de-DE', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }),
              vehicleData.data?.createdBy
                ? vehicleData.data?.createdBy
                : '-'
            )}
          </span>
        )}

        {vehicleData.data?.modified && (
          <span className="text-dark-gray  font-suzuki-regular ml-3">
            {format(
              t('contact-details.modified-at'),
              new Date(vehicleData.data?.modified).toLocaleDateString('de-DE', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }),
              vehicleData.data?.modifiedBy
                ? vehicleData.data?.modifiedBy
                : '-'
            )}
          </span>
        )}
      </div>
      <VehicleForm
        handleCancel={handleCancel}
        handleSubmit={handleSubmit}
        formValues={formValues}
        dealerOptions={dealerOptions}
        disabledInputs={{
          vin: true,
          make: true,
          model: true,
          type: true,
          registrationDate: true,
          registrationNumber: registration == undefined,
          equipment: true,
        }}
      />
    </div>
  );
}

export default VehicleEdit;

import { ChangeHistoryEntry } from "./history-entry";

export enum CustomerType {
  CUSTOMER = 'CUSTOMER',
  LC = 'LOST_CUSTOMER',
  PROSPECT = 'PROSPECT_CUSTOMER',
  UNKNOWN = 'UNKNOWN',
}

export interface Contact {
  objectId?: string;
  poolId?: string;
  customerNumber?: string;
  active?: boolean;
  bookable?: boolean;
  bookableReason?: string;
  bookableRulesVersion?: string;
  classificationId?: string;
  created?: Date;
  customerstate?: string;
  undeliverable?: boolean;
  duplicateGroupId?: string;
  firstname?: string;
  foreignCustomerstate?: number;
  oldId?: string;
  masterId?: string;
  mcid?: string;
  modified?: Date;
  createdBy?: string;
  modifiedBy?: string;
  name?: string;
  salutation?: string;
  title?: string;
  additionalInformation?: AdditionalInformation;
  finance?: Financing;
  dmsDoc?: Dms;
  origin?: Origin;
  company?: {
    coId?: string;
    functionId?: string;
    function?: string;
  };
  accessibillity?: Accessibillity;
  email?: ContactEmail[];
  fax?: ContactFax[];
  phone?: ContactPhone[];
  address?: Address;
  householdData?: HouseholdData;
  contactInformation?: ContactInformation;
  slc?: Slc;
  additionalCompanyname?: string;
  companyId?: string;
  vehicles?: Vehicle[];
  changeHistory?: ChangeHistoryEntry[];
}

export interface Company {
  objectId?: string;
  poolId?: string;
  customerNumber?: string;
  isDealer?: boolean;
  mcid?: string;
  active?: boolean;
  bookable?: boolean;
  bookableReason?: string;
  bookableRulesVersion?: string;
  additionalCompanyname?: string;
  companiesRegisterNo?: string;
  companyId?: string;
  companytypeFree?: string;
  companytypeId?: string;
  companybranchId?: string;
  customerstate?: string;
  created?: Date;
  createdBy?: string;
  modifiedBy?: string;
  duplicateGroupId?: string;
  foundingYear?: number;
  turnoverTaxId?: string;
  additionalInformation?: AdditionalInformation;
  slc?: Slc;
  contactInformation?: ContactInformation;
  email?: ContactEmail[];
  fax?: ContactFax[];
  phone?: ContactPhone[];
  address?: Address;
  legal?: Legal;
  dmsDoc?: Dms;
  origin?: Origin;
  salutation?: string;
  classificationId?: string;
  oldId?: string;
  masterId?: string;
  undeliverable?: boolean;
  finance?: Financing;
  name?: string;
  modified?: Date;
  foreignCustomerstate?: number;
  accessibillity?: Accessibillity;
  vehicles?: Vehicle[];
  changeHistory?: ChangeHistoryEntry[];
  companyContactPerson?: CompanyContactPerson[];
}

export interface CompanyContactPerson {
  firstname?: string;
  name?: string;
  salutation?: string;
}

export interface Vehicle {
  vin?: string;
  brand?: string;
  model?: string;
}

export interface ContactEmail {
  email?: string;
  type?: string;
  emailInvalid?: boolean;
  default?: boolean;
}

export interface ContactPhone {
  phoneNumber?: string;
  type?: string;
  default?: boolean;
}

export interface ContactFax {
  faxNumber?: string;
  type?: string;
  default?: boolean;
}

export interface ContactRequestBody {
  details?: string;
  pageSize?: number;
  pageIndex?: number;
  filter?: ContactFilter;
  exclude?: string[];
  include?: string[];
  sortField?: string;
  sortDir?: number;
}

export enum ContactAddressFilter {
  NOT_SET = 'NOT_SET',
  WITH_ADDRESS = 'WITH_ADDRESS',
  WITHOUT_ADDRESS = 'WITHOUT_ADDRESS',
  All = 'All',
}
export interface ContactFilter {
  search?: string;
  filter?: {
    field?: string;
    value?: string;
  }[];
  PoolIds?: string[];
  contactAddressFilter?: ContactAddressFilter ;
  showHiddenContacts?: boolean;
  dealers?: string[];
}

interface Financing {
  birthdate?: Date;
  birthname?: string;
  birthplace?: string;
  birthyear?: number;
  accountNo?: string;
  accountOwner?: string;
  addressUnchangedForTwoYears?: boolean;
  bankCodeNumber?: string;
  bankCustomerSince?: number;
  bankIdentifierCode?: string;
  citizenship?: string;
  employeeSinceMonth?: number;
  employeeSinceYear?: number;
  employerCity?: string;
  employerHouseNo?: string;
  employerName?: string;
  employerStreet?: string;
  employerZip?: string;
  identificationAdministration?: string;
  identificationIssueDate?: Date;
  identificationNo?: string;
  identificationType?: string;
  incomePerYear?: number;
  internationalBankAccountNumber?: string;
  jobfree?: string;
  jobId?: string;
  kosyfaCustomerstate?: number;
  livingType?: string;
  militaryServiceUpcoming?: boolean;
  monthlyAdditionalCosts?: number;
  monthlyAdditionalIncome?: number;
  monthlyRent?: number;
  taxCode?: string;
  yearOfIncomePerYear?: 0;
}

interface Dms {
  dmsId?: string;
  dmsCreated?: string;
  dmsModified?: string;
  dmsXml?: string;
  newDmsCreated?: string;
  newDmsModified?: string;
  newDmsId?: string[];
}

interface Slc {
  securitylawcode?: number;
  securitylawcodelocked?: boolean;
  slcEditor?: string;
  slcPost?: boolean;
  slcPostBusiness?: boolean;
  slcEmail?: boolean;
  slcEmailBusiness?: boolean;
  slcFaxBusiness?: boolean;
  slcLastUpdate?: string;
  slcLockedEditor?: string;
  slcMare?: boolean;
  slcMareBusiness?: boolean;
  slcMobile?: boolean;
  slcMobileBusiness?: boolean;
  slcPhone?: boolean;
  slcPhoneBusiness?: boolean;
  slcSms?: boolean;
  slcSmsBusiness?: boolean;
  slcFax?: boolean;
}

interface AdditionalInformation {
  customerDiscount?: number;
  indexUpdateUid?: string;
  lastLetterSalutation?: string;
  mvgOwner?: string;
  personId?: string;
  referencedCompanyname?: string;
  resellerNo?: number;
  uid?: string;
}

interface Origin {
  importModule?: string;
  initialImportModule?: string;
  initialChannel?: string;
  initialOrigin?: string;
  channel?: string;
  origin?: string;
}

interface Accessibillity {
  disablingConfirmationDate?: Date;
  disablingConfirmationState?: string;
  disablingDate?: Date;
  disablingEditor?: string;
  disablingReasonDescription?: string;
  disablingReasonType?: string;
}

interface Address {
  addressline1?: string;
  addressline2?: string;
  zip?: string;
  city?: string;
  street?: string;
  countrycode?: string;
  districtareacode?: string;
}

interface ContactInformation {
  web?: string;
  email2?: string;
  confirmedEmail?: string;
}

interface HouseholdData {
  ObjectID?: string;
  birthyear?: number;
  birthdate?: Date;
  childsathome05?: number;
  childsathome?: number;
  childsathome612?: number;
  childsathome1318?: number;
  childsathomeindexes?: string;
  familystatusId?: string;
  incomeId?: string;
  jobFreeform?: string;
  jobId?: string;
  leisureaction?: string;
  notice?: string;
  personsathome?: number;
  sport?: string;
}

interface Legal {
  districtCourtCity?: string;
  districtCourtName?: string;
  districtCourtZip?: string;
}

export interface ExcelImportRequest {
  content: string;
  filename: string;
  distributor: string;
}

export interface ExcelImportResponseRow {
  savedId?: string;
  error?: string;
  rowNumber?: number;
  dealerNumber?: string;
  consentDate?: Date;
  salutation?: string;
  name?: string;
  address?: Address;
}

export interface PoolDealer {
  pool: Pool;
  dealerId: string;
  dealerCode: string;
  name: string;
}

export interface Pool {
  objectId: string;
  dealer_id: string;
  comment: string;
}

export interface UnhideContactsRequest {
  contactIds: string[];
  companyIds: string[];
}

export interface UnhideContactsResponse {
  notUnhiddenContactsIds: string[];
}

import { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { environment } from '../../../environments/environment';
import { GetAuthTrait } from '../../models/user';
import {
  JsonError,
  SelfServiceLoginFlow,
  Session,
  SuccessfulSelfServiceLoginWithoutBrowser,
  UiText,
} from '@ory/client';
import { SubmitSelfServiceLoginFlowWithPasswordMethodBody } from '@ory/client/api';
import assert from 'assert';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { useEffectOnce } from '../../helpers/useEffectOnce';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store/setup';
import {
  fetchUserForSSO,
  selectAllUserManagement,
  userManagementActions,
} from '../../store/slices/user-management.slice';
export interface LoginFormValues {
  // active: boolean,
  username: string;
  csrf_token: string;
  password: string;
}

interface SSOResponse {
  username: string;
  password: string;
  error: string;
}

export const LoginSSO: FunctionComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const [ticketId, setTicketId] = useState('');
  const [userId, setUserId] = useState('');
  const [flow, setFlow] = useState({} as SelfServiceLoginFlow);
  const [errorMessages, setErrorMessages] = useState([] as Array<string>);

  const loading = useSelector(
    (state: RootState) => state.userManagement.ssoLoadingStatus
  );
  const entities = useSelector(selectAllUserManagement);
  const [session, setSession] = useState({} as Session);
  useEffectOnce(() => {
    dispatch(fetchUserForSSO());
  });
  const [response, setResponse] = useState<undefined | SSOResponse>(undefined);

  useEffect(() => {
    const lastEntity = entities[entities.length - 1];
    if (lastEntity && lastEntity.currentUser) {
      setSession(lastEntity.currentUser);
    }
  }, [entities]);

  const isFlowExpired = (): boolean => {
    return flow && new Date(flow.expires_at).valueOf() < Date.now();
  };

  const pushError = (errorMsg: string) => {
    const errors = [...errorMessages];
    errors.push(errorMsg);
    setErrorMessages(errors);
  };

  useEffectOnce(() => {
    // check for sso data in params
    const tId = searchParams.get('SSOTicketId');
    const uId = searchParams.get('userID');

    //if we get none just abort
    if (!tId || !uId) {
      const errorMsg = 'malformed';
      pushError(errorMsg);
      return;
    }
    // otherwise save the info temporarily
    assert(tId, 'tId must be set');
    setTicketId(tId);
    assert(uId, 'uId must be set');
    setUserId(uId);
    //and start the login flog
  });

  useEffect(() => {
    const fetchURL = `${environment.restEndpoint}/sso/verify?ticketId=${ticketId}&userId=${userId}`;
    if (userId && ticketId) {
      axios
        .get<SSOResponse>(fetchURL, {
          withCredentials: true,
          headers: {
            'Content-type': 'application/json',
            apikey: environment.apiKey,
          },
        })
        .then((resp: AxiosResponse<SSOResponse>) => {
          if (resp.data.error !== 'I0001' && resp.data.error !== 'I0002') {
            let msg = 'ssoproblems';
            if (resp.data.error === "E0005") {
              msg = 'ssoTicketInvalid'
            }
            
            pushError(msg);
          }
          setResponse(resp.data);
        })
        .catch((err: JsonError | AxiosError) => {
          if (axios.isAxiosError(err)) {
            // Access to config, request, and response
            pushError(err.message);
          } else {
            // Just a stock error
            pushError('network');
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, ticketId]);

  useEffect(() => {
    if (response) {
      if (
        entities.length === 0 &&
        (loading === 'loaded' || loading === 'error')
      ) {
        startLoginFlow(ticketId, userId, response);
      } else if (entities.length >= 1 && loading === 'loaded') {
        if (
          session &&
          session.identity?.traits?.username === response.username
        ) {
          navigate('/dashboard');
        } else {
          console.log('handle wrong session error here');
          pushError('someoneelseloggedin');
          axios
            .get(environment.publicAuthURL + `/self-service/logout/browser`, {
              withCredentials: true,
              headers: {
                'Content-type': 'application/json',
                apikey: environment.apiKey,
              },
            })
            .then((resp) => {
              if (resp.data.logout_url) {
                window.location.href = resp.data.logout_url;
              }
            });
        }
        //handleLogin(ticketId, userId, '', '');
      } else {
        //just wait
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticketId, userId, loading, session, entities, response]);

  /**
   *
   * @param ticketId string, the ticket that was attached to the request
   * @param userId string, the user that was provided in the request
   * @param response SSOResponse, the response of the request, used for evaluation and to login
   */
  const startLoginFlow = (
    ticketId: string,
    userId: string,
    response: SSOResponse
  ) => {
    axios
      .get<SelfServiceLoginFlow>(
        `${environment.publicAuthURL}/self-service/login/browser`,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          withCredentials: true,
        }
      )
      //.then((res: Response) => res.json())
      .then((res: AxiosResponse<SelfServiceLoginFlow>) => {
        console.log(res.data);
        const csrf_token = GetAuthTrait(res.data.ui.nodes, 'csrf_token');
        setFlow(res.data);

        //after we have what we need ask the backend if everything is okay
        handleLogin(ticketId, userId, res.data.id, csrf_token?.value);
      })
      .catch((err: JsonError | AxiosError) => {
        if (axios.isAxiosError(err)) {
          // Access to config, request, and response
          pushError(err.message);
        } else {
          // Just a stock error
          pushError('network');
        }
      });
  };

  /**
   * check if the supplied sso data can be verified by the backend
   */
  const handleLogin = async (
    tId: string,
    uId: string,
    flowId: string,
    csrf_token: string
  ) => {
    await handleSubmit(
      response?.username as string,
      response?.password as string,
      flowId,
      csrf_token
    );
  };

  const handleSubmit = async (
    username: string,
    password: string,
    flowId: string,
    csrf_token: string
  ) => {
    setErrorMessages([]);
    let valid = true;
    if (!username) {
      valid = false;
    }
    if (!password) {
      valid = false;
    }
    if (isFlowExpired()) {
      const msg = 'expired';
      pushError(msg);
      valid = false;
    }
    if (valid) {
      const sendData: SubmitSelfServiceLoginFlowWithPasswordMethodBody = {
        csrf_token: csrf_token,
        method: 'password',
        identifier: username,
        password: password,
      };
      try {
        const fetchURL =
          environment.publicAuthURL + `/self-service/login?flow=${flowId}`;
        const fetchOptions = {
          method: 'POST',
          credentials: 'include',
          // mode: "cors",
          headers: {
            'content-type': 'application/json',
            apiKey: environment.apiKey,
          },
          body: JSON.stringify(sendData),
        } as RequestInit;
        const resp = await fetch(fetchURL, fetchOptions);

        const body:
          | SelfServiceLoginFlow
          | SuccessfulSelfServiceLoginWithoutBrowser = await resp.json();
        if ('ui' in body) {
          if (
            body &&
            body.ui &&
            body.ui.messages &&
            body.ui.messages.length > 0
          ) {
            // error happens
            body.ui.messages?.forEach((msgBody: UiText) => {
              pushError(msgBody.text);
            });
          }
        } else if ('session' in body) {
          const session: Session = body.session;
          dispatch(userManagementActions.add({ currentUser: session }));
          navigate('/dashboard');
        }
      } catch (err) {
        console.error(err);
      }
    }
  };

  return (
    <>
      <div className="text-dark-gray text-center"></div>
      <div className="w-full">
        {' '}
        {errorMessages && errorMessages.length > 0 ? (
          <div className={'text-red-500'}>
            {errorMessages.map((err: string) => (
              <p key={err}>{t('login.error.sso.' + err)}</p>
            ))}
          </div>
        ) : (
          <div>Bitte warten...Sie werden eingelogged</div>
        )}
      </div>
    </>
  );
};

import {
  CheckboxStatus,
  Headline,
  Tab,
  Table,
  Tabs,
} from '@suzuki-frontend-framework-update/ui';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Row } from 'react-table';
import { sendApiRequest } from '../../components/send-api-request/send-api-request';
import { useApiGetData } from '../../components/useApiGetData/useApiGetData';
import {
  Field,
  RemindersRequest,
  WarrantyReminder,
} from '../../models/warranty-reminder';
import SendConfirmationPopup from './send-confirmation-popup';
import SendPopup from './send-popup';
import { environment } from '../../../environments/environment';
import { DataFetchProps } from '../../models/others';
import {
  extendedWarrantyFilterActions,
  extendedWarrantyFilterSelector,
} from '../../store/slices/extended-warranty.slice';
import { AppDispatch } from '../../store/setup';

export const ExtendedWarrantyReminder = () => {
  const { t } = useTranslation();
  const { tabParam } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [modalState, setModalState] = useState(false);
  const [modalConfState, setModalConfState] = useState(false);
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [selectedRowIds, setSelectedRowIds] = useState<Record<string, boolean>>(
    {}
  );
  const [allSelectedStatus, setAllSelectedStatus] = useState<CheckboxStatus>({
    checked: false,
    indeterminate: false,
  });

  const extendedWarrantyFilterState = useSelector(
    extendedWarrantyFilterSelector
  );
  const dispatch: AppDispatch = useDispatch();
  if (
    extendedWarrantyFilterState.key === '' ||
    location.key !== extendedWarrantyFilterState.key
  ) {
    dispatch(extendedWarrantyFilterActions.init(location.key));
  }

  const [remindersIds, setRemindersIds] = useState<number[]>([]);

  const [remindersRequest, setRemindersRequest] = useState<RemindersRequest>({
    //TODO: get dealer from logged user
    pageSize: 100,
    pageIndex: extendedWarrantyFilterState.extendedWarrantyFilter.pageIndex,
    filter: {
      ...extendedWarrantyFilterState.extendedWarrantyFilter.filter,
      sent: tabParam === 'sent',
      onlyActive: tabParam !== 'sent',
    },
  });

  const reminders = useApiGetData<WarrantyReminder[]>(
    `${environment.restEndpoint}/extended-warranty-reminder/extended-warranty-reminder/getReminders`,
    'post',
    'extendedWarrantyReminders',
    remindersRequest
  );

  useEffect(() => {
    sendApiRequest(
      `${environment.restEndpoint}/extended-warranty-reminder/extended-warranty-reminder/getRemindersIds`,
      'post',
      {
        filter: { sent: tabParam === 'sent', onlyActive: true },
      }
    )
      .then((response) => response.json())
      .then((data) => setRemindersIds(data['ids']));
  }, [tabParam]);

  const remindersCount = useApiGetData<number>(
    `${environment.restEndpoint}/extended-warranty-reminder/extended-warranty-reminder/getRemindersCount`,
    'post',
    'count',
    remindersRequest
  );

  /*   const handleFilterSelect = (id: string, selected: Array<string>) => {
    console.log(id + ' ' + selected.toString());
    //  TODO: implement filters
  }; */
  const handleFilterShowSelect = (id: string, selected: Array<string>) => {
    let toHide: number[] = [];
    let toShow: number[] = [];
    if (selected.includes('selected-only')) {
      toShow = selectedIds;
    }
    if (selected.includes('unselected-only')) {
      toHide = selectedIds;
    }
    dispatch(
      extendedWarrantyFilterActions.updateExtendedWarrantyFilter({
        ...extendedWarrantyFilterState.extendedWarrantyFilter.filter,
        showOnlyIds: toShow,
        hideIds: toHide,
      })
    );
  };

  const handleFilterSearch = (value: string) => {
    dispatch(
      extendedWarrantyFilterActions.updateExtendedWarrantyFilter({
        ...extendedWarrantyFilterState.extendedWarrantyFilter.filter,
        search: value,
      })
    );
  };

  const handleSend = () => {
    setModalState(false);
    sendApiRequest(
      `${environment.restEndpoint}/extended-warranty-reminder/extended-warranty-reminder/send`,
      'post',
      {
        extendedWarrantyRemindersIDs: selectedIds,
      }
    ).then(() => {
      setModalConfState(true);
      resetSelections();
      setRemindersRequest({
        ...remindersRequest,
        pageIndex: 0,
        filter: { sent: tabParam === 'sent', onlyActive: tabParam !== 'sent' },
      });
    });
  };

  const toSendFilters = {
    id: 'remindersToSendFilters',
    key: 'remindersToSendFilters',
    placeholder: t(
      'communication.extended-warranty-reminder.filters.search-placeholder'
    ),
    onSearchUpdate: handleFilterSearch,
    initialSearchValue:
      extendedWarrantyFilterState.extendedWarrantyFilter.filter.search,
    searchDelay: 500,
    filters: [
      /*  {
        id: 'dateOfRegistration',
        name: t(
          'communication.extended-warranty-reminder.filters.date-of-registration'
        ),
        items: [
          { id: 'f1', name: 'filter1' },
          { id: 'f2', name: 'filter2' },
          { id: 'f3', name: 'filter3' },
        ],
        onChange: handleFilterSelect,
      }, */
      {
        id: 'selected-filter',
        name: t(
          'communication.extended-warranty-reminder.filters.show-contacts'
        ),
        items: [
          {
            id: 'selected-only',
            name: t(
              'communication.extended-warranty-reminder.filters.show-selected'
            ),
          },
          {
            id: 'unselected-only',
            name: t(
              'communication.extended-warranty-reminder.filters.show-unselected'
            ),
          },
        ],
        onChange: handleFilterShowSelect,
      },
    ],
  };

  const sentFilters = {
    id: 'remindersToSendFilters',
    key: 'remindersToSendFilters',
    placeholder: t(
      'communication.extended-warranty-reminder.filters.search-placeholder'
    ),
    onSearchUpdate: handleFilterSearch,
    initialSearchValue:
      extendedWarrantyFilterState.extendedWarrantyFilter.filter.search,
    searchDelay: 500,
    filters: [
      /*  {
        id: 'dateOfRegistration',
        name: t(
          'communication.extended-warranty-reminder.filters.date-of-registration'
        ),
        items: [
          { id: 'f1', name: 'filter1' },
          { id: 'f2', name: 'filter2' },
          { id: 'f3', name: 'filter3' },
        ],
        onChange: handleFilterSelect,
      }, */
      {
        id: 'selected-filter',
        name: t(
          'communication.extended-warranty-reminder.filters.show-contacts'
        ),
        items: [
          {
            id: 'selected-only',
            name: t(
              'communication.extended-warranty-reminder.filters.show-selected'
            ),
          },
          {
            id: 'unselected-only',
            name: t(
              'communication.extended-warranty-reminder.filters.show-unselected'
            ),
          },
        ],
        onChange: handleFilterShowSelect,
      },
    ],
  };

  const columns = [
    {
      Header: t('communication.extended-warranty-reminder.columns.contact'),
      accessor: 'contact',
      Cell: ({ row }: any) => {
        return (
          <div className="flex flex-col">
            <Link
              className={'underline'}
              to={
                row.original.vehicle.contact.isCompany
                  ? `/companies/view/${row.original.vehicle.contact.contactObjectId}`
                  : `/contacts/view/${row.original.vehicle.contact.contactObjectId}`
              }
              onClick={(e) => {
                dispatch(
                  extendedWarrantyFilterActions.updateExtendedWarrantyCurrentRow(
                    row.id
                  )
                );
                e.stopPropagation();
              }}
            >
              {[
                row.original.vehicle.contact.firstname,
                row.original.vehicle.contact.name,
              ]
                .filter((n) => n)
                .join(' ')}
            </Link>
            <span className="text-blue-gray">
              {[
                row.original.vehicle.contact.street,
                row.original.vehicle.contact.zip,
                row.original.vehicle.contact.city,
                row.original.vehicle.contact.countryCode,
              ]
                .filter((n) => n)
                .join(', ')}
            </span>
          </div>
        );
      },
    },
    {
      Header: t('communication.extended-warranty-reminder.columns.email'),
      accessor: 'vehicle.contact.email',
    },
    {
      Header: t('communication.extended-warranty-reminder.columns.model'),
      accessor: 'vehicle.model',
      Cell: ({ row }: any) => {
        return (
          <span>
            {row.original.vehicle.model ? row.original.vehicle.model : '-'}
          </span>
        );
      },
    },
    {
      Header: t(
        'communication.extended-warranty-reminder.columns.license-plate'
      ),
      accessor: 'vehicle.licensenumber',
      disableSortBy: true,
      Cell: ({ row }: any) => {
        return (
          <span>
            {row.original.vehicle.licensenumber
              ? row.original.vehicle.licensenumber
              : '-'}
          </span>
        );
      },
    },

    {
      Header: t('communication.extended-warranty-reminder.columns.vin'),
      accessor: 'vehicle.vin',
      disableSortBy: true,
      Cell: ({ row }: any) => {
        return (
          <Link
            className="flex flex-col"
            to={`/vehicles/view/${row.original.vehicle.vehicleObjectId}`}
            onClick={(e) => {
              dispatch(
                extendedWarrantyFilterActions.updateExtendedWarrantyCurrentRow(
                  row.id
                )
              );
              e.stopPropagation();
            }}
          >
            <div className={'underline'}>{row.original.vehicle.vin}</div>
          </Link>
        );
      },
    },

    {
      Header: t(
        'communication.extended-warranty-reminder.columns.first-registration'
      ),
      accessor: 'vehicle.firstRegistrationDate',
      Cell: ({ row }: any) => {
        return (
          <span>
            {row.original.vehicle.firstRegistrationDate
              ? new Date(
                  row.original.vehicle.firstRegistrationDate
                ).toLocaleDateString()
              : '-'}
          </span>
        );
      },
    },
    {
      Header: t(
        'communication.extended-warranty-reminder.columns.warranty-expiry-date'
      ),
      accessor: 'vehicle.warrantyEndDate',
      Cell: ({ row }: any) => {
        return (
          <span>
            {row.original.warrantyEndDate
              ? new Date(row.original.warrantyEndDate).toLocaleDateString()
              : '-'}
          </span>
        );
      },
    },
  ];

  const sentColumn = {
    Header: t('communication.extended-warranty-reminder.columns.last-sendout'),
    accessor: 'lastSendoutDate',
    Cell: ({ row }: any) => {
      return (
        <span>
          {row.original.lastSendoutDate
            ? new Date(row.original.lastSendoutDate).toLocaleDateString()
            : '-'}
        </span>
      );
    },
  };

  const getPopupMessage = () => {
    return (
      <div>
        <div
          style={{
            margin: 'auto',
            maxWidth: '600px',
            minWidth: '440px',
            backgroundColor: '#ebebeb',
            fontFamily: 'sans-serif',
            lineHeight: '1.5rem',
            fontSize: '0.9rem',
          }}
        >
          <img
            alt="banner"
            style={{ width: '100%', maxWidth: '600px', minWidth: '440px' }}
            src="/assets/images/extended-warranty-reminder-banner.jpg"
          />
          <div style={{ padding: '3rem', paddingBottom: '1rem' }}>
            <span className="text-2xl font-suzuki-bold">
              Suzuki PRO Anschlussgarantie
            </span>
            <br />
            <br />
            <span style={{ marginTop: '1rem' }}>
              <span className="text-xl font-suzuki-bold">
                {' '}
                Sehr{' '}
                <span className="text-pink-600">
                  geehrte/r Frau/Herr Musterkunde,
                </span>
              </span>
              <p style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <span className="text-pink-600">
                  Ihre Suzuki Modellname (XXX XXXX)
                </span>{' '}
                ist seit der Erstzulassung vor mittlerweile knapp zwei Jahren
                mit einem umfassenden Garantie-Paket ausgestattet.
                <br />
                Mit der darin enthaltenen Suzuki Werksgarantie konnten Sie Ihren
                Fahrspaß bisher immer unbekümmert genießen. Und das mit der
                Gewissheit, auch dann abgesichert zu sein, wenn einmal etwas
                Unvorhergesehenes passiert.
              </p>
              <p style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                Um weiterhin mit diesem Gefühl der Sicherheit fahren zu können,
                bieten wir Ihnen die Suzuki PRO Anschlussgarantie an, damit Ihr
                Motorrad für weitere zwei Jahre abgesichert ist. Weitere
                Informationen zur Suzuki PRO Anschlussgarantie finden Sie auf
                unserer Webseite unter
                <a
                  className="text-pink-600"
                  href="https://motorrad.suzuki.de/warum-suzuki/neufahrzeug-vorteile"
                >
                  [Link]
                </a>
                .
              </p>
              <p style={{ marginTop: '1rem', marginBottom: '3rem' }}>
                Wir freuen uns jetzt schon, Sie in Kürze bei uns begrüßen zu
                dürfen.
              </p>
              <p style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                Mit freundlichen Grüßen
              </p>
              <p
                style={{ marginTop: '1rem', marginBottom: '1rem' }}
                className="text-pink-600"
              >
                <span className="font-suzuki-bold text-black">
                  Suzuki Vertragshändler
                </span>
                <br />
                Max Mustermann, Musterstraße 123, 12345 Musterstadt
                <br />
                Telefon: +49 1234 5678-90
                <br />
                <a href="https://mustermann-bikes.de">
                  www.mustermann-bikes.de
                </a>
              </p>
              <p style={{ marginTop: '1rem', marginBottom: '2rem' }}>
                PS: Denken Sie bitte auch daran, dass bei Ihrem Fahrzeug
                demnächst die Hauptuntersuchung (HU) ansteht. Auf Wunsch machen
                wir Ihre Suzuki im Rahmen der Inspektion gerne fit dafür.
                Vereinbaren Sie einfach einen Termin mit uns.
              </p>
              <p
                style={{
                  marginTop: '1rem',
                  fontSize: 'x-small',
                  lineHeight: 'normal',
                }}
              >
                Der Verwendung meiner Daten zu Werbezwecken kann ich jederzeit
                schriftlich per Post (Suzuki Deutschland GmbH, Suzuki-Allee 7,
                64625 Bensheim oder an den genannten Suzuki Vertragshändler)
                oder per E-Mail an widerruf@suzuki.de widersprechen, jeweils
                auch mit Wirkung gegenüber dem Suzuki Vertragshändler bzw. der
                Suzuki Deutschland GmbH.
              </p>
            </span>
          </div>
        </div>
        <div className="flex flex-row items-center h-24 bg-[#ebebeb] mt-4 justify-between px-8">
          <span className="flex flex-row items-center h-full">
            <a
              className="h-8"
              href="https://www.youtube.com/SuzukiMotorradDeutschland"
            >
              <img alt="youtube" src="/assets/images/youtube.png" />
            </a>
            <a
              className="h-8 pl-8"
              href="https://www.instagram.com/suzukimotorrad/"
            >
              <img alt="instagram" src="/assets/images/instagram.png" />
            </a>
            <a
              className="h-8 pl-8"
              href="https://www.facebook.com/SuzukiMotorradDeutschland/"
            >
              <img alt="facebook" src="/assets/images/facebook.png" />
            </a>
          </span>
          <a href="https://motorrad.suzuki.de/">
            <img
              alt="motorrad.suzuki.de"
              style={{ height: '0.8rem' }}
              src="/assets/images/motorrad-suzuki.png"
            />
          </a>
        </div>
      </div>
    );
  };

  const handlePreviousRow = useCallback(
    async ({ itemsRef }: any) => {
      if (reminders.data && extendedWarrantyFilterState.currentRow) {
        itemsRef.current
          .get(extendedWarrantyFilterState.currentRow)
          ?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        dispatch(
          extendedWarrantyFilterActions.updateExtendedWarrantyCurrentRow(
            undefined
          )
        );
      }
    },
    [reminders.data, reminders.loading]
  );

  const handleRowClick = (row: Row<WarrantyReminder>) => {
    handleRowSelect(row);
  };

  const sortFieldMapper = (uistring: string): Field => {
    switch (uistring) {
      case 'contact':
        return Field.CONTACT_NAME;
      case 'vehicle.contact.email':
        return Field.CONTACT_EMAIL;
      case 'vehicle.firstRegistrationDate':
        return Field.VEHICLE_FIRST_REGISTRATION;
      case 'vehicle.vin':
        return Field.VEHICLE_VIN;
      case 'lastSendoutDate':
        return Field.LAST_SENDOUT_DATE;
      default:
        return Field.NONE;
    }
  };

  const fetchData = useCallback(
    async ({ pageSize, pageIndex, pageSortBy }: DataFetchProps) => {
      dispatch(
        extendedWarrantyFilterActions.updateExtendedWarrantyPageIndex(pageIndex)
      );
      let sortByField = Field.NONE;
      let sortDesc = false;
      if (pageSortBy && pageSortBy.length > 0) {
        const sortParams = pageSortBy[0];
        sortDesc = sortParams.desc;
        sortByField = sortFieldMapper(sortParams.id);
      }
      setRemindersRequest({
        ...remindersRequest,
        pageSize: pageSize,
        pageIndex: pageIndex,
        filter: {
          ...extendedWarrantyFilterState.extendedWarrantyFilter.filter,
          sent: tabParam === 'sent',
          onlyActive: tabParam !== 'sent',
        },
        sortField: sortByField,
        sortDesc: sortDesc,
      });
    },
    [extendedWarrantyFilterState.extendedWarrantyFilter.filter]
  );

  const handleRowSelect = (row: Row<WarrantyReminder>) => {
    let result = [...selectedIds];
    const id = row.original.id;
    if (id) {
      if (result.includes(id)) {
        result = result.filter((v) => v !== id);
      } else {
        result.push(id);
      }
      setSelectedRowIds({ ...selectedRowIds, [row.id]: result.includes(id) });
      setSelectedIds(result);
      const checked = remindersIds.every((id) => result.includes(id));
      const indeterminate =
        !checked && remindersIds.some((id) => result.includes(id));
      setAllSelectedStatus({
        checked: checked,
        indeterminate: indeterminate,
      });
    }
  };

  const disableSelection = (row: Row<WarrantyReminder>): boolean =>
    row.original.expired !== undefined ? row.original.expired : false;

  const handleAllRowsSelect = () => {
    if (remindersIds.every((id) => selectedIds.includes(id))) {
      resetSelections();
    } else {
      setSelectedIds(remindersIds);
      updateSelectedRowIds(remindersIds);
      setAllSelectedStatus({ checked: true, indeterminate: false });
    }
  };

  const resetSelections = () => {
    setSelectedIds([]);
    setSelectedRowIds({});
    setAllSelectedStatus({ checked: false, indeterminate: false });
  };

  useEffect(() => {
    updateSelectedRowIds(selectedIds);
  }, [reminders.data]);

  const updateSelectedRowIds = (selected: number[]) => {
    if (reminders.data) {
      let result: Record<string, boolean> = {};
      reminders.data.forEach((inv, index) => {
        if (inv.id) {
          result = {
            ...result,
            [index.toString()]: selected.includes(inv.id),
          };
        }
      });
      setSelectedRowIds(result);
    }
  };

  const selectionHandler = {
    handleRowSelect: handleRowSelect,
    handleAllRowsSelect: handleAllRowsSelect,
    customSelectedRowIds: selectedRowIds,
    allSelectedStatus: allSelectedStatus,
    disableSelection: disableSelection,
  };

  const getTabIndex = () => {
    switch (tabParam) {
      case 'toSend':
        return 0;
      case 'sent':
        return 1;
      default:
        return 0;
    }
  };

  const handleTabChange = (tabIndex: number) => {
    resetSelections();
    const sentTab = tabIndex === 1;
    navigate(
      `/communication/extended-warranty-reminder/${sentTab ? 'sent' : 'toSend'}`
    );
  };

  return (
    <div className="w-full h-full">
      <Headline
        className="p-8"
        title={t('communication.extended-warranty-reminder.title')}
      />
      <Tabs defaultActiveTabIndex={getTabIndex()} onTabChange={handleTabChange}>
        <Tab
          title={t('communication.extended-warranty-reminder.to-send')}
          tabIndex={0}
        >
          <Table
            key="toSendTable"
            filter={toSendFilters}
            columns={columns}
            data={reminders.data ? reminders.data : []}
            loading={reminders.loading}
            initPageSize={100}
            rowCount={remindersCount.data}
            countLabel={
              remindersCount.data && remindersCount.data === 1
                ? t('common.list.count.entry')
                : t('common.list.count.entries')
            }
            fetchData={fetchData}
            customElement={
              <div>
                <SendPopup
                  disabled={true}
                  buttonTooltip={"Aufgrund rechtlicher Beschränkungen ist der Versand der Anschlussgarantieerinnerung temporär nicht verfügbar."}
                  selectedCount={selectedIds.length}
                  countConfirmationTranslationKey="communication.extended-warranty-reminder.confirm-count"                  sendOne={t(
                    'communication.inspections-invitations.send-email'
                  )}
                  sendMany={t(
                    'communication.inspections-invitations.send-emails'
                  )}
                  buttonTitle={t(
                    'communication.extended-warranty-reminder.send-reminder'
                  )}
                  modalTitle={t(
                    'communication.extended-warranty-reminder.send-reminder-title'
                  )}
                  handleSend={handleSend}
                  setSendModalState={setModalState}
                  sendModalState={modalState}
                >
                  {/* {getPopupMessage()} */}
                </SendPopup>
                <SendConfirmationPopup
                  modalTitle={t(
                    'communication.extended-warranty-reminder.send-reminder-confirmation'
                  )}
                  description={t(
                    'communication.inspections-invitations.invitation-confirmation-description'
                  )}
                  setSendModalConfState={setModalConfState}
                  sendModalConfState={modalConfState}
                ></SendConfirmationPopup>
              </div>
            }
            pagination
            handleRowClick={handleRowClick}
            customSelectionHendler={selectionHandler}
            initialPage={
              extendedWarrantyFilterState.extendedWarrantyFilter.pageIndex
            }
            resetOnChange={
              extendedWarrantyFilterState.extendedWarrantyFilter.filter
            }
            handleScrollToRow={handlePreviousRow}
          />
        </Tab>
        <Tab
          title={t('communication.extended-warranty-reminder.sent')}
          tabIndex={1}
        >
          <Table
            key="sentTable"
            filter={sentFilters}
            columns={[...columns, sentColumn]}
            data={reminders.data ? reminders.data : []}
            loading={reminders.loading}
            initPageSize={5}
            rowCount={remindersCount.data}
            countLabel={
              remindersCount.data && remindersCount.data === 1
                ? t('common.list.count.entry')
                : t('common.list.count.entries')
            }
            fetchData={fetchData}
            customElement={
              <div>
                <SendPopup
                  selectedCount={selectedIds.length}
                  countConfirmationTranslationKey="communication.extended-warranty-reminder.confirm-count"
                  sendOne={t(
                    'communication.inspections-invitations.send-email'
                  )}
                  sendMany={t(
                    'communication.inspections-invitations.send-emails'
                  )}
                  buttonTitle={t(
                    'communication.extended-warranty-reminder.resend-reminder'
                  )}
                  modalTitle={t(
                    'communication.extended-warranty-reminder.send-reminder-title'
                  )}
                  handleSend={handleSend}
                  setSendModalState={setModalState}
                  sendModalState={modalState}
                >
                  {/* {getPopupMessage()} */}
                </SendPopup>
                <SendConfirmationPopup
                  modalTitle={t(
                    'communication.extended-warranty-reminder.send-reminder-confirmation'
                  )}
                  description={t(
                    'communication.inspections-invitations.invitation-confirmation-description'
                  )}
                  setSendModalConfState={setModalConfState}
                  sendModalConfState={modalConfState}
                ></SendConfirmationPopup>
              </div>
            }
            pagination
            handleRowClick={handleRowClick}
            customSelectionHendler={selectionHandler}
            initialPage={
              extendedWarrantyFilterState.extendedWarrantyFilter.pageIndex
            }
            resetOnChange={
              extendedWarrantyFilterState.extendedWarrantyFilter.filter
            }
            handleScrollToRow={handlePreviousRow}
          />
        </Tab>
      </Tabs>
    </div>
  );
};

export default ExtendedWarrantyReminder;

import {useTranslation} from 'react-i18next';
import {Button, ButtonStyle, Icon,} from '@suzuki-frontend-framework-update/ui';
import {toast} from "react-toastify";
import {sendApiRequest} from "../send-api-request/send-api-request";
import {environment} from '../../../environments/environment';
import {UnhideVehiclesRequest, UnhideVehiclesResponse} from "../../models/vehicle";

export interface UnhideVehicleButtonProps {
  selectedCount: number;
  selectedVehicleIds: string[];
  handleResetSelectionsAfterUnhide: () => void;
  refreshData: () => void;
}

export const UnhideVehicleButton = (props: UnhideVehicleButtonProps) => {
  const { t } = useTranslation();

  const customIdFailed = 'custom-id-Failed';
  const customIdSuccess = 'custom-id-success';

  const successToast = (successText: string) => {
    toast.success(successText, {
      position: 'top-center',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      toastId: customIdSuccess,
    });
  };

  const errorToast = (errorText: string) => {
    toast.error(errorText, {
      position: 'top-center',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      toastId: customIdFailed,
    });
  };

  const handleUnhideButtonClick = () => {
    sendApiRequest(
      `${environment.restEndpoint}/vehicle/vehicle-service/unhide`,
      'post',
      {
        vehicleIds: props.selectedVehicleIds,
      } as UnhideVehiclesRequest
    ).then((response ) => {
      props.handleResetSelectionsAfterUnhide();
      if (response.ok) {
        response.json().then((data) => {
          const unhidingResponseData = data as UnhideVehiclesResponse
          if (unhidingResponseData && unhidingResponseData.notUnhiddenVehiclesIds.length > 0) {
            const notHiddenVehiclesCount = unhidingResponseData.notUnhiddenVehiclesIds.length;
            errorToast(`${t('contact-details.unhide-button.vehicles-unhiding-partial-success')} ${notHiddenVehiclesCount}.`);
          } else {
            successToast(t('contact-details.unhide-button.vehicles-unhiding-success'));
          }
          props.refreshData();
        })
      } else {
        errorToast(t('contact-details.unhide-button.vehicles-unhiding-failure'));
      }
    });
  }

  return (
    <Button
      disabled={props.selectedCount <= 0}
      buttonStyle={ButtonStyle.OUTLINE}
      className={`bg-white w-fit`}
      onClick={handleUnhideButtonClick}
    >
      <Icon iconName="visibility" />
      <span className=" pl-[5px]">
            {props.selectedCount <= 0
              ? t('contact-details.unhide-button.no-contacts-selected')
              : t('contact-details.unhide-button.title')}
          </span>
    </Button>
  );
};

export default UnhideVehicleButton;

export interface WarrantyReminder {
  id?: number;
  dealerId?: string;
  vehicle: Vehicle;
  warrantyEndDate?: Date;
  creationDate?: Date;
  lastSendoutDate?: Date;
  expired?: boolean;
}

export interface Vehicle {
  id?: number;
  vehicleObjectId?: string;
  firstRegistrationDate?: Date;
  model?: string;
  brand?: string;
  vin?: string;
  lastService?: Date;
  licensenumber?: string;
  contact: Contact;
}

export interface Contact {
  id?: number;
  contactObjectId?: string;
  firstname?: string;
  name?: string;
  salutation?: string;
  isCompany?: boolean;
  email?: string;
  addressLine1?: string;
  addressLine2?: string;
  zip?: string;
  city?: string;
  street?: string;
  countryCode?: string;
  districtAreaCode?: string;
}

export interface RemindersRequest {
  filter?: RemindersFilters;
  pageSize?: number;
  pageIndex?: number;
  sortField?: Field;
  sortDesc?: boolean;
}

export interface RemindersFilters {
  search?: string;
  showOnlyIds?: number[];
  hideIds?: number[];
  sent?: boolean;
  onlyActive?: boolean;
}

export enum Field {
  NONE,
  WARRANTY_END_DATE,
  LAST_SENDOUT_DATE,
  CONTACT_NAME,
  CONTACT_FIRSTNAME,
  CONTACT_ADDRESS_LINE_1,
  CONTACT_ADDRESS_LINE_2,
  CONTACT_ZIP,
  CONTACT_CITY,
  CONTACT_STREET,
  CONTACT_COUNTRY,
  CONTACT_EMAIL,
  CONTACT_DISTRICT,
  VEHICLE_MODEL,
  VEHICLE_BRAND,
  VEHICLE_VIN,
  VEHICLE_FIRST_REGISTRATION,
  VEHICLE_LAST_SERVICE,
}

import './vehicle-create.module.scss';

import {Headline, MultiselectOptionsProps,} from '@suzuki-frontend-framework-update/ui';
import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {useApiGetData} from '../../components/useApiGetData/useApiGetData';
import {environment} from '../../../environments/environment';
import {Vehicle} from '../../models/vehicle';
import {sendApiRequest} from '../../components/send-api-request/send-api-request';
import {VehicleForm, VehicleFormValue} from '../vehicle-form/vehicle-form';
import {toast} from 'react-toastify';
import {useSelector} from 'react-redux';
import {selectAllUserManagement} from '../../store/slices/user-management.slice';
import {Session} from '@ory/client';
import {Dealer} from '../../models/dealer';

export function VehicleCreate() {
  const navigate = useNavigate();
  const {t} = useTranslation();

  const entities = useSelector(selectAllUserManagement);
  const [session, setSession] = useState({} as Session);

  useEffect(() => {
    const lastEntity = entities[entities.length - 1];
    if (lastEntity && lastEntity.currentUser) {
      setSession(lastEntity.currentUser);
    }
  }, [entities]);

  const [formValues, setFormValues] = useState<VehicleFormValue>({
    vin: '',
    make: '',
    model: '',
    type: '',
    registrationDate: undefined,
    equipment: '',
    registrationNumber: '',
    color: '',
    engineVolume: 0,
    fuel: '',
    owner: [],
  });

  const customIdSuccess = 'custom-id-success';
  const customIdFailed = 'custom-id-Failed';
  const successToast = (successText: string) => {
    toast.success(successText, {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      toastId: customIdSuccess,
    });
  };

  const errorToast = (errorText: string) => {
    toast.error(errorText, {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      toastId: customIdFailed,
    });
  };

  // const handleChange = (prop: string, value: any) => {
  //   setFormValues({ ...formValues, [prop]: value });
  // };

  const [dealerOptions, setDealerOptions] = useState<MultiselectOptionsProps[]>(
    []
  );
  const [owner, setOwner] = useState<string[]>([]);

  const dealerData = useApiGetData<Dealer[]>(
    `${environment.restEndpoint}/dealer/dealer-service/dealers`,
    'post',
    'dealers'
  );

  useEffect(() => {
    if (dealerData.status !== 200) {
      return;
    }

    if (dealerOptions.length <= 0) {
      const toSelectOptions = (
        dealers: Dealer[] | undefined
      ): MultiselectOptionsProps[] => {
        if (dealers && dealers.length > 0) {
          return dealers.map((d) => {
            return {
              key: d.objectID,
              name: `${d.name} ${d.nameAppendum} (${d.dealercode})`,
              value: d.dealercode,
            } as MultiselectOptionsProps;
          });
        } else {
          return [];
        }
      };
      const options = toSelectOptions(dealerData.data);
      setDealerOptions(options);
      if (!session?.identity?.traits?.isAdmin) {
        setOwner(
          session.identity?.traits?.dealers?.map((d: Dealer) => d.dealercode)
        );
      }
    }
  }, [dealerData]);

  const handleSubmit = (formValues: VehicleFormValue) => {
    if (!formValues.vin) {
      errorToast(t('error-required'));
      return;
    } else if (formValues) {
      if (!formValues.vin.trim()) {
        errorToast(t('error-required'));
        return;
      }
    }
    sendApiRequest(
      `${environment.restEndpoint}/vehicle/vehicle-service/vehicle`,
      'post',
      {
        vin: formValues.vin,
        modified: new Date(),
        created: new Date(),
        modifiedBy: session?.id,
        createdBy: session?.id,
        active: true,
        brand: formValues.make,
        model: formValues.model,
        type: formValues.type,
        firstRegistraion: formValues.registrationDate,
        configurationtype: formValues.equipment,
        VehicleSpecification: {
          color: formValues.color,
          vehicleloadcapacity: formValues.engineVolume,
          fueltype: formValues.fuel,
        },
        owner: session.identity.traits.isAdmin
          ? createOwnerList(formValues.owner ? formValues.owner : [])
          : owner,
      } as Vehicle
    )
      .then((response) => response.json())
      .then((data) => {
        sendApiRequest(`${environment.restEndpoint}/action/action`, 'post', {
          title:
            createOwnerListFormatted(formValues.owner ? formValues.owner : []).length > 0 || owner.length > 0  ?
              t('vehicle-create-action.title', {
                dealerCode: session.identity.traits.isAdmin
                  ? createOwnerListFormatted(formValues.owner ? formValues.owner : [])
                  : owner,
              }): t('vehicle-create-action.title-without-dealerCode'),
          actionDate: new Date(),
          origin: "SCRM",
          contexts: [{
            id: data.ID,
            type: 'VEHICLE'
          }],
          referenceCollection: 'VEHICLES',
        })
          .then((response) => {
            if (response.ok) {
              successToast(t('success'));
              navigate(`/vehicles/view/${data.ID}`);
            } else {
              alert(t('error') + response.statusText);
              navigate(`/vehicles/view/${data.ID}`);
            }
          })
          .catch((e) => alert(t('error') + e));
        //navigate(`/vehicles/view/${data.ID}`);
      })
      .catch((e) => errorToast(t('error') + e));
  };

  const createOwnerList = (os: MultiselectOptionsProps[]): string[] => {
    const ret: string[] = [];
    os.forEach((o) => ret.push(o.value as string));

    return ret;
  };


  const createOwnerListFormatted = (os: MultiselectOptionsProps[]): string[] => {
    const ret: string[] = [];
    os.sort().forEach((o, index ) => ret.push(index === 0 ? o.value as string:  " " + o.value as string));

    return ret;
  };

  const handleCancel = () => {
    navigate(`/vehicles`);
  };

  return (
    <div className="grow">
      <Headline className="p-8 pb-0" title={t('vehicle-create')}/>
      <VehicleForm
        handleCancel={handleCancel}
        handleSubmit={handleSubmit}
        formValues={formValues}
        disabledInputs={{registrationNumber: true}}
        dealerOptions={dealerOptions}
      />
    </div>
  );
}

export default VehicleCreate;

import {TestDriveData} from "../../models/test-drive";
import React, {ReactElement, useEffect, useState} from "react";
import {DatePickerProps, SelectInputProps, TextareaProps} from "@sde/basic-ui-library";
import {TestDriveInformationMoto} from "./test-drive-information-moto";
import {TestDriveInformationAuto} from "./test-drive-information-auto";
import {determineModelType} from "../../helpers/model-helper";
import {VehicleModelType} from "../../models/vehicle-model";

export interface TestDriveInformationProps {
  testDrive: TestDriveData;
  modelNameEditable?: ReactElement<SelectInputProps>;
  plannedDateEditable?: ReactElement<DatePickerProps>;
  dealerCommentEditable?: ReactElement<TextareaProps>;
}

export const TestDriveInformation = (props: TestDriveInformationProps) => {
  const [modelType, setModelType] = useState<VehicleModelType>(VehicleModelType.UNDEFINED);

  useEffect(() => {
    const { testDrive } = props;
    if (!testDrive) return;

    const { isMotorcycle, dealercode } = testDrive;
    const modelType = determineModelType(isMotorcycle, dealercode);

    setModelType(modelType);
  }, [props.testDrive]);

  return modelType === VehicleModelType.AUTO ? <TestDriveInformationAuto { ...props } /> : <TestDriveInformationMoto { ...props } />;
}

import { useTranslation } from 'react-i18next';
import {
  Button,
  ButtonStyle,
  Icon,
  Modal,
  Spinner,
  Table,
} from '@suzuki-frontend-framework-update/ui';
import { format } from 'react-string-format';
import { ExcelImportResponseRow } from '../../models/contact';
import { Link } from 'react-router-dom';

export interface ConstactsImportPopupProps {
  rows: ExcelImportResponseRow[];
  setModalState: (state: boolean) => void;
  modalState: boolean;
  loading?: boolean;
}

export const ConstactsImportPopup = (props: ConstactsImportPopupProps) => {
  const { t } = useTranslation();

  return (
    <div>
      <Modal
        key="excel-import-result-modal"
        show={props.modalState}
        handleClose={() => props.setModalState(false)}
        title={t('contacts.import.excel-import-result')}
        size="min-w-[440px]"
      >
        <div className="p-4">
          {props.loading ? (
            <Spinner />
          ) : (
            <Table
              basic
              columns={[
                {
                  Header: t('contacts.import.row'),
                  accessor: 'rowNumber',
                },
                {
                  Header: t('contacts.import.salutation'),
                  accessor: 'salutation',
                },
                {
                  Header: t('contacts.people-companies.name'),
                  accessor: 'name',
                },
                {
                  Header: t('contacts.people-companies.address'),
                  accessor: 'street',
                  Cell: ({ row }: any) => {
                    return (
                      <div className="flex flex-col">
                        {row.original.address &&
                          row.original.address.street && (
                            <span>{row.original.address.street}</span>
                          )}
                        {row.original.address && row.original.address.city && (
                          <span>
                            {row.original.address.zip
                              ? row.original.address.zip + ' '
                              : ''}
                            {row.original.address.city}
                          </span>
                        )}
                      </div>
                    );
                  },
                },
                {
                  Header: t('contacts.import.dealerNumber'),
                  accessor: 'dealerNumber',
                },
                {
                  Header: t('contacts.import.consentDate'),
                  accessor: 'consentDate',
                  Cell: ({ row }: any) => {
                    return (
                      <span>
                        {row.original.consentDate
                          ? new Date(
                              row.original.consentDate
                            ).toLocaleDateString()
                          : '-'}
                      </span>
                    );
                  },
                },
                {
                  Header: t('contacts.import.result'),
                  accessor: 'error',
                  Cell: ({ row }: any) => {
                    const saved =
                      row.original.savedId && row.original.savedId.length > 0;
                    const isCompany =
                      row.original.salutation &&
                      row.original.salutation.toLowerCase() === 'firma';
                    const isError =
                      row.original.error && row.original.error.length > 0;

                    return (
                      <span>
                        {!saved &&
                          isError &&
                          t('contacts.import.failed') +
                            t('contacts.import.error.' + row.original.error)}
                        {saved &&
                          isError &&
                          t('contacts.import.imported') +
                            t('contacts.import.with-errors') +
                            t('contacts.import.error.' + row.original.error)}
                        {saved &&
                          !isError &&
                          t('contacts.import.imported') +
                            t('contacts.import.without-errors')}
                        {saved && (
                          <Link
                            to={
                              isCompany
                                ? `/companies/view/${row.original.savedId}`
                                : `/contacts/view/${row.original.savedId}`
                            }
                          >
                            <div className="underline font-suzuki-bold">
                              {t('contacts.import.open-saved')}
                            </div>
                          </Link>
                        )}
                      </span>
                    );
                  },
                },
              ]}
              data={props.rows}
            />
          )}
        </div>
      </Modal>
    </div>
  );
};

export default ConstactsImportPopup;

import { Spinner } from '@sde/basic-ui-library';
import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { NewsletterActivationResult } from "../../components/newsletter/newsletter-activation-result";
import { verifySubscriber } from "../../store/slices/newsletter.slice";

let hasRequested = false;

export default function ActivateNewsletterSubscription() {
  const { tenant } = useParams();
  const isMoto = tenant === 'moto';
  const [searchParams] = useSearchParams();
  const [isNewsletterBeingActivated, setIsNewsletterBeingActivated] = useState<boolean>(true);
  const [isNewsletterActivated, setIsNewsletterActivated] = useState<boolean>(true);
  const [isSweepstakeNewsletter, setIsSweepstakeNewsletter] = useState<boolean>(false);

  useEffect(() => {
    if (hasRequested) return;
    verifySubscriber({
      email: searchParams.get('email') || '',
      newsletterCodes: searchParams.getAll('newsletterCode'),
    }).then(response => {
      setIsNewsletterActivated(response.result);
      setIsNewsletterBeingActivated(false);
    }).catch(error => {
      console.error(error);
      setIsNewsletterActivated(false);
      setIsNewsletterBeingActivated(false);
    });
    const fromSweepstakeParam = searchParams.get('fromSweepstake');
    setIsSweepstakeNewsletter(fromSweepstakeParam !== null ? fromSweepstakeParam === 'true' : false);
    hasRequested = true;
  }, [searchParams]);

  return (
    <>
      {isNewsletterBeingActivated ?
        <div className='m-auto'>
          <Spinner />
        </div>
        :
        <NewsletterActivationResult isActivationSuccessful={isNewsletterActivated}
                                    isMoto={isMoto}
                                    isSweepstake={isSweepstakeNewsletter}
                                    sweepstakeType={searchParams.get('sweepstakeType')}/>
      }
    </>
  );
}

import {
  TestDriveData, TestDriveStatus,
} from "../../models/test-drive";
import {useTranslation} from "react-i18next";
import React from "react";
import {
  Badge,
  Button,
  ButtonStyle,
  Modal,
} from "@sde/basic-ui-library";
import {CustomerType} from "../../models/contact";
import {format} from "react-string-format";
import {TestDriveInformation} from "../test-drive-information/test-drive-information";
import {TestDriveResponseReadOnly} from "../test-drive-response/test-drive-response-read-only";
import {TextLine} from "../text-line/text-line";

export interface ShowTestDriveDetailsPopupProps {
  setModalState: (state: boolean) => void;
  modalState: boolean;
  testDrive: TestDriveData;
  refreshData: () => void;
}

export const ShowTestDriveDetailsPopup = (props: ShowTestDriveDetailsPopupProps) => {
  const { t } = useTranslation();
  const notSpecifiedText = t('communication.test-drive.report.modals.not-specified');

  const handleCancel = () => {
    props.setModalState(false);
  };

  return (
    <div>
      <Modal
        key="schedule-test-drive-modal"
        title={t('communication.test-drive.report.modals.show_test_drive_details.modal_title')}
        show={props.modalState}
        handleClose={() => handleCancel()}
        size='min-w-[440px]'
      >
        <div className="flex flex-col">
          <div className="flex flex-row items-start ps-5 gap-x-8 align-middle">
            <span className="font-suzuki-headline text-dark" style={{fontSize: '30px'}}>{props.testDrive.companyId ? `${props.testDrive?.contactSalutation} ${props.testDrive?.companyName}` : `${props.testDrive?.contactSalutation} ${props.testDrive?.contactFirstName} ${props.testDrive?.contactLastName}`}</span>
            <Badge
              name={
                props.testDrive?.customerState === CustomerType.CUSTOMER
                  ? t(
                    'contact-details.customer'
                  ).toLocaleUpperCase()
                  : props.testDrive?.customerState === CustomerType.PROSPECT
                    ? t(
                      'contact-details.prospect'
                    ).toLocaleUpperCase()
                    : props.testDrive?.customerState === CustomerType.LC
                      ? t(
                        'contact-details.lost-customer'
                      ).toLocaleUpperCase()
                      : notSpecifiedText.toLocaleUpperCase()
              }
              title={
                props.testDrive?.customerState === CustomerType.CUSTOMER
                  ? t('contact-details.customer')
                  : props.testDrive?.customerState === CustomerType.PROSPECT
                    ? t('contact-details.prospect')
                    : props.testDrive?.customerState === CustomerType.LC
                      ? t('contact-details.lost-customer')
                      : notSpecifiedText.toLocaleUpperCase()
              }
            />
          </div>
          <div className="flex items-start ps-5 pt-2">
            <span className="text-dark-gray font-suzuki-regular">
              {
                `${format(
                  t('communication.test-drive.report.modals.created-at'),
                  props.testDrive.createAt ? new Date(props.testDrive.createAt).toLocaleDateString(
                    'de-DE', {year: 'numeric', month: '2-digit', day: '2-digit'}
                  ) : notSpecifiedText,
                  props.testDrive.origin ? t(`communication.test-drive.origin.${props.testDrive.origin}`) : notSpecifiedText,
                )} ${props.testDrive.lastChangeDate ? format(
                  t('communication.test-drive.report.modals.modified-at'),
                  new Date(props.testDrive.lastChangeDate).toLocaleDateString(
                    'de-DE', {year: 'numeric', month: '2-digit', day: '2-digit'}
                  ),
                  props.testDrive.lastChangeEditor ? props.testDrive.lastChangeEditor : notSpecifiedText,
                ) : ''}`
              }
            </span>
          </div>
          <TestDriveInformation testDrive={props.testDrive} />
          {props.testDrive.status === TestDriveStatus.CANCELED ?
            <div className="pl-5">
              <TextLine
                labelText={t('communication.test-drive.report.modals.show_test_drive_details.reason_for_canceling_not_required')}
                value={props.testDrive.cancelingReason}
              />
            </div>
            :
            <TestDriveResponseReadOnly testDrive={props.testDrive} />
          }
        </div>
        <div className="flex flex-row gap-x-6 justify-end">
          <Button
            className="bg-white"
            buttonStyle={ButtonStyle.OUTLINE}
            onClick={handleCancel}
            px="5"
            py="3"
          >
            {t('communication.test-drive.report.modals.close')}
          </Button>
        </div>
      </Modal>
    </div>
  );
}

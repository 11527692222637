import { DashboardConfig, DashboardData, DashboardDataItem, DashboardUserRole, DashboardWidget, } from './dashboard-config';
import { useApiGetData } from '../../components/useApiGetData/useApiGetData';
import { environment } from '../../../environments/environment';
import {TestDriveOrigin, TestDriveRequestBody, TestDriveStatus} from '../../models/test-drive';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppDispatch } from '../../store/setup';
import { QuestionnairesType } from '../../models/questionnaires-types';
import { Session } from '@ory/client';
import { useTranslation } from 'react-i18next';
import { sendApiRequest } from '../../components/send-api-request/send-api-request';
import { ProgressState, SurveysRequest } from '../../models/receiver';
import { stringToDate } from '../../helpers/date-helper';
import { dashboardFilterActions, dashboardFilterSelector, } from '../../store/slices/dashboard.slice';
import { CrrEntry, GetCrrReportRequest } from '../../models/crr-entry';
import { getCurrentFiscalYear, processEntries, } from '../report/crr-report-parser';
import ReportFilters from '../../components/report-filter/report-filter';
import { selectAllUserManagement } from '../../store/slices/user-management.slice';
import { isAdmin, isAreaAdmin, isAutoDealer, isAutoDealerOrAdmin, isMotoDealer, isMotoDealerOrAdmin, } from '../../helpers/session-utils';
import { Headline, WidgetGauge, WidgetKpi, } from '@suzuki-frontend-framework-update/ui';
import AppointmentsChart from '../../components/appointments-chart/appointments-chart';
import { SalesChart } from '../../components/sales-chart/sales-chart';
import { compareQuestionnaireTypes } from '../../helpers/questionnaire-helper';
import { WebRequest } from "../../models/web-request";

export interface DashboardProps {
  className?: string;
}

const Dashboard = (props: DashboardProps) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const entities = useSelector(selectAllUserManagement);
  const [session, setSession] = useState({} as Session);

  ////
  const [dashboardUrl, setDashboardUrl] = useState('');
  const [zohoToken, setZohoToken] = useState('');
  ////

  useEffect(() => {
    const lastEntity = entities[entities.length - 1];
    if (lastEntity && lastEntity.currentUser) {
      setSession(lastEntity.currentUser);
    }
  }, [entities]);

  useEffect(() => {
    dispatch(dashboardFilterActions.resetState);
  }, []);

  const dashboardFilterState = useSelector(dashboardFilterSelector);
  const dispatch: AppDispatch = useDispatch();
  if (
    dashboardFilterState.key === '' ||
    location.key !== dashboardFilterState.key
  ) {
    dispatch(dashboardFilterActions.init(location.key));
  }

  const initialTestDriveRequestBody: TestDriveRequestBody = {
    pageSize: 100000000,
    pageIndex: 0,
    filter: {
      dateFrom: new Date(),
      dateTo: new Date(),
    },
  };

  const [newOpenTestDrivesRequestBody, setNewOpenTestDrivesRequestBodyBody] =
    useState<TestDriveRequestBody>(initialTestDriveRequestBody);

  const [testDriveRequestBodyLastWeek, setTestDriveRequestBodyLastWeekBody] =
    useState<TestDriveRequestBody>(initialTestDriveRequestBody);

  const testDriversCountDataLastWeek = useApiGetData<number>(
    `${environment.restEndpoint}/action/testdrives/GetTestDrivesCount`,
    'post',
    'count',
    testDriveRequestBodyLastWeek
  );

  const testDriversCountDataLastMonth = useApiGetData<number>(
    `${environment.restEndpoint}/action/testdrives/GetTestDrivesCount`,
    'post',
    'count',
    newOpenTestDrivesRequestBody
  );


  const [webRequest, setWebRequest] = useState<WebRequest>({
    filter: {
      notCompleted: true,
    },
  })

  const offersCount = useApiGetData<number>(
    `${environment.restEndpoint}/action/offers/count/get`,
    'post',
    'count',
      webRequest
  );

  const carConfigurationOffersCount = useApiGetData<number>(
    `${environment.restEndpoint}/action/car-configuration-offers/count/get`,
    'post',
    'count',
      webRequest
  );

  const consultingAppointmentsCount = useApiGetData<number>(
      `${environment.restEndpoint}/action/appointments/consulting/count/get`,
      'post',
      'count',
      webRequest
  );

  const serviceAppointmentsCount = useApiGetData<number>(
    `${environment.restEndpoint}/action/appointments/vehicle-service/count/get`,
    'post',
    'count',
      webRequest
  );

  const [crrReportRequest, setCrrReportRequest] = useState<GetCrrReportRequest>({});
  const [crr5Value, setCrr5Value] = useState<number>(0);
  const [crr10Value, setCrr10Value] = useState<number>(0);

  const crrEntries = useApiGetData<CrrEntry[]>(
    `${environment.restEndpoint}/invitation/crr/report`,
    'post',
    'entries',
    crrReportRequest
  );



  const [iframeHeight, setIframeHeight] = useState('600px'); // Default height
  const iframeRef = useRef<HTMLIFrameElement | null>(null);

  useEffect(() => {
    const fiscalYear = getCurrentFiscalYear();
    const now = new Date();
    now.setUTCHours(0, 0, 0, 0);

    const sevenDaysAgo = new Date();
    sevenDaysAgo.setUTCHours(0, 0, 0, 0);
    sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);

    const oneMonthAgo = new Date();
    oneMonthAgo.setUTCHours(0, 0, 0, 0);
    oneMonthAgo.setDate(oneMonthAgo.getDate() - 28);

    setCrrReportRequest({
      dealers: dashboardFilterState.dealerFilter.dealers,
      dateFrom: new Date(fiscalYear, 3, 1, 12),
      dateTo: new Date(fiscalYear + 1, 2, 31, 12),
    });

    const allTestDriveStatuses = Object.keys(TestDriveStatus).filter(key => isNaN(Number(key)));
    const allTestDriveOrigins = Object.keys(TestDriveOrigin).filter(key => isNaN(Number(key)));
    setTestDriveRequestBodyLastWeekBody({
      ...testDriveRequestBodyLastWeek,
      filter: {
        dateFrom: sevenDaysAgo,
        dateTo: now,
        dealers: dashboardFilterState.dealerFilter.dealers,
        statuses: allTestDriveStatuses,
        origins: allTestDriveOrigins
      },
    });
    setNewOpenTestDrivesRequestBodyBody({
      ...newOpenTestDrivesRequestBody,
      filter: {
        dealers: dashboardFilterState.dealerFilter.dealers,
        statuses: [TestDriveStatus.NEW_OPEN],
        origins: allTestDriveOrigins
      },
    });
    setWebRequest({
      ...webRequest,
      filter: {
        notCompleted: true,
        dateFrom: stringToDate(dashboardFilterState.filter.dateFrom),
        dateTo: stringToDate(dashboardFilterState.filter.dateTo),
        dealers: dashboardFilterState.dealerFilter.dealers,
      }
    })
  }, [dashboardFilterState.dealerFilter.dealers]);

  useEffect(() => {
    if (
      crrEntries.data &&
      crrReportRequest.dateFrom &&
      crrReportRequest.dateTo
    ) {
      const ccr5Entries = processEntries(
        crrEntries.data,
        crrReportRequest.dateFrom,
        crrReportRequest.dateTo,
        true
      ).pop();
      const ccr10Entries = processEntries(
        crrEntries.data,
        crrReportRequest.dateFrom,
        crrReportRequest.dateTo,
        false
      ).pop();
      setCrr5Value(
        ccr5Entries ? Math.round(ccr5Entries.average.inspectionsRate * 100) : 0
      );
      setCrr10Value(
        ccr10Entries
          ? Math.round(ccr10Entries.average.inspectionsRate * 100)
          : 0
      );
    }
  }, [crrEntries.data]);

  const [surveysValues, setSurveysValues] = useState<Map<string, number>>(
    new Map()
  );

  const questionnairesTypes = useApiGetData<QuestionnairesType[]>(
    `${environment.restEndpoint}/survey-service/questionnaire-types`,
    'get',
    'questionnaireTypes'
  );

  enum SurveySuffix {
    SENT_SUFFIX = '-sent',
    FINISHED_SUFFIX = '-finished',
    PERCENTAGE_SUFFIX = '-percentage',
  }

  function getSurveysWidgets(): DashboardWidget[] {
    const tenants: string[] = [];
    if (isMotoDealerOrAdmin(session)) tenants.push('moto');
    if (isAutoDealerOrAdmin(session)) tenants.push('auto');

    return (
      questionnairesTypes.data
        ?.sort((a, b) => compareQuestionnaireTypes(a, b))
        .filter((type) => tenants.includes(type.tenant))
        .map(
          (type) =>
            [
              {
                id: type.id + SurveySuffix.SENT_SUFFIX,
                type: 'kpi_widget',
                label: t(
                  'crm.website-metrics.survey-tool.sent-' +
                    type.name +
                    (tenants.length === 2 ? '-prefix' : '')
                ),
                link:
                  '/communication/surveys/' + type.id + '/sent?dashboard=sent',
              },
              {
                id: type.id + SurveySuffix.FINISHED_SUFFIX,
                type: 'kpi_widget',
                label: t(
                  'crm.website-metrics.survey-tool.finished-' +
                    type.name +
                    (tenants.length === 2 ? '-prefix' : '')
                ),
                link:
                  '/communication/surveys/' +
                  type.id +
                  '/sent?dashboard=finished',
              },
              {
                id: type.id + SurveySuffix.PERCENTAGE_SUFFIX,
                type: 'kpi_widget',
                label: t(
                  'crm.website-metrics.survey-tool.percentage-' +
                    type.name +
                    (tenants.length === 2 ? '-prefix' : '')
                ),
              },
            ] as DashboardWidget[]
        )
        ?.flat() ?? []
    );
  }

  useEffect(() => {
    if (questionnairesTypes?.data && questionnairesTypes?.data?.length > 0) {
      questionnairesTypes?.data.forEach(async (type) => {
        const sentCount = (
          await sendApiRequest(
            `${environment.restEndpoint}/survey-service/receiver/get-receivers-count`,
            'post',
            {
              filter: {
                typeId: String(type.id),
                progressStates: [
                  ProgressState.NOT_STARTED,
                  ProgressState.IN_PROGRESS,
                  ProgressState.COMPLETED,
                ],
                dealerCodes: dashboardFilterState.dealerFilter.dealers,
              },
            } as SurveysRequest
          ).then((response) => response.json())
        ).count as number;
        surveysValues.set(type.id + SurveySuffix.SENT_SUFFIX, sentCount ?? 0);

        const finishedCount = (
          await sendApiRequest(
            `${environment.restEndpoint}/survey-service/receiver/get-receivers-count`,
            'post',
            {
              filter: {
                typeId: String(type.id),
                progressStates: [ProgressState.COMPLETED],
                dealerCodes: dashboardFilterState.dealerFilter.dealers,
              },
            } as SurveysRequest
          ).then((response) => response.json())
        ).count as number;
        surveysValues.set(
          type.id + SurveySuffix.FINISHED_SUFFIX,
          finishedCount ?? 0
        );

        surveysValues.set(
          type.id + SurveySuffix.PERCENTAGE_SUFFIX,
          Math.round((finishedCount / sentCount) * 100)
        );
      });
    }
  }, [questionnairesTypes, dashboardFilterState.dealerFilter.dealers]);

  const data: DashboardData = {
    categories: [
      {
        items: [
          {
            key: 1,
            value: crr5Value > 100 ? 100 : crr5Value,
            hiddenFor: [DashboardUserRole.MOTO_DEALER],
          },
          {
            key: 2,
            value: crr10Value > 100 ? 100 : crr10Value,
            hiddenFor: [DashboardUserRole.MOTO_DEALER],
          },
        ],
      },
      {
        items: [
          {
            key: 1,
            value: testDriversCountDataLastWeek.data ? testDriversCountDataLastWeek.data : 0,
          },
          {
            key: 2,
            value: testDriversCountDataLastMonth.data ? testDriversCountDataLastMonth.data : 0,
          },
          {
            key: 3,
            value: offersCount.data ?? 0,
            hiddenFor: [DashboardUserRole.MOTO_DEALER],
          },
          {
            key: 4,
            value: carConfigurationOffersCount.data ?? 0,
            hiddenFor: [DashboardUserRole.MOTO_DEALER],
          },
          {
            key: 5,
            value: consultingAppointmentsCount.data ?? 0,
            hiddenFor: [DashboardUserRole.MOTO_DEALER],
          },
          {
            key: 6,
            value: serviceAppointmentsCount.data ?? 0,
            hiddenFor: [DashboardUserRole.MOTO_DEALER],
          },
        ],
      },
      {
        items: getSurveysWidgets().map((widget, index) => {
          return {
            key: index,
            value: surveysValues.has(String(widget.id))
              ? surveysValues.get(String(widget.id))
              : 0,
            hiddenFor: [DashboardUserRole.MOTO_DEALER],
          } as DashboardDataItem;
        }),
      },
    ],
  };

  const isWidgetItemHidden = (item: DashboardDataItem): boolean => {
    if (!item.hiddenFor) {
      return false;
    }

    const roleCheckMap = {
      [DashboardUserRole.ADMIN]: isAdmin,
      [DashboardUserRole.ASM_DSM]: isAreaAdmin,
      [DashboardUserRole.AUTO_DEALER]: (session: Session) =>
        isAutoDealer(session) && !isAdmin(session),
      [DashboardUserRole.MOTO_DEALER]: (session: Session) =>
        isMotoDealer(session) && !isAdmin(session),
    };

    return item.hiddenFor.some((role) => roleCheckMap[role]?.(session));
  };

  return (
    <div
      className={`${
        props.className ? props.className : ''
      } bg-neutral-200 min-h-screen p-8`}
    >
      <div className="container mx-auto px-8">
        <div
          id="filter"
          className="flex justify-around border-b border-dark px-16 pt-4 pb-8 space-x-16"
        >
          <ReportFilters resetOnPageReload />
        </div>

        <div id="widgets" className="pt-8">
          {DashboardConfig(getSurveysWidgets()).categories.map(
            (cat, cIndex) => {
              const catValue = data.categories[cIndex];
              if (!catValue) return;

              const visibleChildrenCount = cat.widgets.reduce(
                (count, widget, wIndex) => {
                  const widgetValue = catValue.items[wIndex];
                  return count + (!isWidgetItemHidden(widgetValue) ? 1 : 0);
                },
                0
              );

              return (
                visibleChildrenCount > 0 && (
                  <div key={cat.label} className="pb-4">
                    <Headline className="text-dark-gray" title={cat.label} />
                    <div className="grid xl:grid-cols-3 grid-cols-1 gap-4">
                      {cat.widgets.map((widget, wIndex) => {
                        const widgetValue = catValue.items[wIndex];
                        return (
                          !isWidgetItemHidden(widgetValue) && (
                            <div key={widget.label} className={`min-w-[360px]`}>
                              <div
                                className={
                                  widget.onClick || widget.link
                                    ? 'cursor-pointer'
                                    : ''
                                }
                                onClick={(e) => {
                                  e.stopPropagation();
                                  if (widget.onClick) widget.onClick();
                                  if (widget.link) navigate(widget.link);
                                }}
                              >
                                {widget.type === 'kpi_widget' ? (
                                  <WidgetKpi
                                    text={widget.label}
                                    value={widgetValue.value}
                                    valueRange={widget.valueRange}
                                  />
                                ) : (
                                  <WidgetGauge
                                    text={widget.label}
                                    value={widgetValue.value}
                                    valueRange={widget.value}
                                  />
                                )}
                              </div>
                            </div>
                          )
                        );
                      })}
                    </div>
                  </div>
                )
              );
            }
          )}
        </div>
        {isAdmin(session) && (
          <>
            <SalesChart
              dealers={dashboardFilterState.dealerFilter.dealers}
              session={session}
            />
            <AppointmentsChart
              dealers={dashboardFilterState.dealerFilter.dealers}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default Dashboard;

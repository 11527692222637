import { BasicInput, BasicOutput, Button, ButtonStyle, Checkbox, MultipleValueInput, MultipleValueInputRow, SideNavigation, } from '@suzuki-frontend-framework-update/ui';
import './company-form.module.scss';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { sendApiRequest } from "../../components/send-api-request/send-api-request";
import { environment } from "../../../environments/environment";
import { Country } from "../../models/country";
import { SelectInput, SelectOptionsProps } from "@sde/basic-ui-library";

export interface CompanyValue {
  name?: string;
  street?: string;
  address?: string;
  postcode?: string;
  city?: string;
  countrycode?: string;
  isDealer?: boolean;
  email: Array<MultipleValueInputRow>;
  phone: Array<MultipleValueInputRow>;
  fax: Array<MultipleValueInputRow>;
  foundingYear?: number;
}

export type CompanyFormProps = {
  handleCancel: () => void;
  handleSubmit: (formValues: CompanyValue) => void;
  handleChange?: (prop: string, value: string) => void;
  handleCheckboxChange?: (prop: string) => void;
  handleMultipleInputChange?: (
    prop: string
  ) => (value: Array<MultipleValueInputRow>) => void;
  formValues: CompanyValue;
};

export const CompanyForm = (props: CompanyFormProps) => {
  const { t } = useTranslation();
  const [countryOptions, setCountryOptions] = useState<SelectOptionsProps[]>([]);
  const [formValues, setFormValues] = useState<CompanyValue>(props.formValues);

  useEffect(() => {
    setFormValues(props.formValues);
  }, [props.formValues]);


  useEffect(() => {
    sendApiRequest(
      `${environment.restEndpoint}/contact/contact-service/country/list`,
      'get'
    )
      .then((response) => response.json())
      .then((data) => {
        const countryOpts: SelectOptionsProps[] = [];
        if (data && data.countries) {
          (data.countries as Country[]).forEach((i: Country) => {
            if (i.code && i.active && (i.textDe || i.textEn))
              countryOpts.push({
                key: i.code,
                value: i.code,
                name: i.textDe ? i.textDe : i.textEn ? i.textEn : ''
              });
          });
        }
        setCountryOptions([{
          key: '',
          value: '',
          name: `${t('contact-form.dropdownEmpty')}`,
        }, ...countryOpts.sort((a, b) => a.name.localeCompare(b.name))]);
      });
  }, []);

  const handleChange = (prop: string, value: string) => {
    setFormValues({ ...formValues, [prop]: value });
  };

  const handleCheckboxChange = (prop: string) => {
    setFormValues((prev: CompanyValue) => {
      return { ...formValues, [prop]: !prev[prop as keyof CompanyValue] };
    });
  };

  const handleMultipleInputChange =
    (prop: string) => (value: Array<MultipleValueInputRow>) => {
      setFormValues({ ...formValues, [prop]: value });
    };

  const inputWidth = '20rem';
  return (
    <SideNavigation
      values={[
        { id: 'personal-data', title: t('contact-form.personal-data') },
        { id: 'kontakt-data', title: t('contact-form.kontakt-data') },
      ]}
      topOffset="13rem"
    >
      <div className="w-full flex flex-col gap-y-10 bg-gray pr-4 pb-4">
        <div className="text-right flex flex-row gap-x-4 justify-end">
          <Button
            className="bg-white"
            buttonStyle={ButtonStyle.OUTLINE}
            onClick={props.handleCancel}
            px="5"
            py="3"
          >
            {t('contact-form.cancel')}
          </Button>
          <Button onClick={() => props.handleSubmit(formValues)} px="5" py="3">
            {t('contact-form.submit')}
          </Button>
        </div>
        <BasicOutput title={t('contact-form.personal-data')} id="personal-data">
          <div className="pl-12 pr-5 ">
            <div className="flex flex-col items-end gap-y-5 w-6/12">
              <BasicInput
                onChange={(e) => handleChange('name', e.target.value)}
                width={inputWidth}
                label={{ name: t('contact-form.name'), position: 'left' }}
                value={formValues.name}
                required
              />

              <BasicInput
                onChange={(e) => handleChange('foundingYear', e.target.value)}
                width={inputWidth}
                label={{
                  name: t('contact-details.founding-year'),
                  position: 'left',
                }}
                value={formValues.foundingYear}
                type="number"
                min={1000}
                step={1}
              />
              <Checkbox
                label={t('contact-details.isDealer')}
                checked={formValues.isDealer}
                rightLabel={false}
                onChange={(e) => handleCheckboxChange('isDealer')}
              />
            </div>
          </div>
        </BasicOutput>

        <BasicOutput title={t('contact-form.kontakt-data')} id="kontakt-data">
          <div className="pl-12 pr-5 ">
            <div className="flex flex-col items-end gap-y-5 w-6/12 pb-10">
              <BasicInput
                onChange={(e) => handleChange('street', e.target.value)}
                width={inputWidth}
                label={{ name: t('contact-form.street'), position: 'left' }}
                value={formValues.street}
              />
              <BasicInput
                onChange={(e) => handleChange('address', e.target.value)}
                width={inputWidth}
                label={{ name: t('contact-form.address'), position: 'left' }}
                value={formValues.address}
              />
              <BasicInput
                onChange={(e) => handleChange('postcode', e.target.value)}
                width={inputWidth}
                label={{ name: t('contact-form.postcode'), position: 'left' }}
                value={formValues.postcode}
              />
              <BasicInput
                onChange={(e) => handleChange('city', e.target.value)}
                width={inputWidth}
                label={{ name: t('contact-form.city'), position: 'left' }}
                value={formValues.city}
              />
              <SelectInput
              onSelect={(e) => handleChange('countrycode', e)}
              width={inputWidth}
              label={{
                name: t('contact-form.country'),
                position: 'left',
              }}
              value={formValues.countrycode}
              options={countryOptions}
            />
            </div>
            <div className="flex flex-col gap-y-10 pb-5">
              <MultipleValueInput
                showStandardLabel={true}
                onChange={handleMultipleInputChange('email')}
                width={inputWidth}
                label={t('contact-form.email')}
                options={[
                  { key: 0, name: t('device-type.private'), value: 'private' },
                  {
                    key: 1,
                    name: t('device-type.business'),
                    value: 'business',
                  },
                ]}
                value={formValues.email}
              />
              <MultipleValueInput
                onChange={handleMultipleInputChange('phone')}
                width={inputWidth}
                label={t('contact-form.phone')}
                options={[
                  { key: 0, name: t('device-type.private'), value: 'private' },
                  { key: 1, name: t('device-type.mobile'), value: 'mobile' },
                  {
                    key: 2,
                    name: t('device-type.business'),
                    value: 'business',
                  },
                ]}
                value={formValues.phone}
                regex={/^([0-9 \-+]*)$/}
              />
              <MultipleValueInput
                onChange={handleMultipleInputChange('fax')}
                width={inputWidth}
                label={t('contact-form.fax')}
                options={[
                  { key: 0, name: t('device-type.private'), value: 'private' },
                  {
                    key: 1,
                    name: t('device-type.business'),
                    value: 'business',
                  },
                ]}
                value={formValues.fax}
                regex={/^([0-9 \-+]*)$/}
              />
            </div>
          </div>
        </BasicOutput>
        <div className="text-right flex flex-row gap-x-4 justify-end">
          <Button
            className="bg-white"
            buttonStyle={ButtonStyle.OUTLINE}
            onClick={props.handleCancel}
            px="5"
            py="3"
          >
            {t('contact-form.cancel')}
          </Button>
          <Button onClick={() => props.handleSubmit(formValues)} px="5" py="3">
            {t('contact-form.submit')}
          </Button>
        </div>
      </div>
    </SideNavigation>
  );
};

export default CompanyForm;

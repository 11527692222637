import React, { useEffect, useState } from 'react';
import { Sweepstake, SweepstakeErrorMessageByCode } from '../../models/sweepstake';
import { BasicInput, Button, ButtonStyle, Modal, SelectInput, SelectOptionsProps } from '@sde/basic-ui-library';
import { Newsletter } from '../../models/newsletter';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { ApiError } from '../../models/error';
import { createSweepstake, updateSweepstake } from "../../store/slices/sweepstake.slice";

export interface EditSweepstakePopupProps {
  isOpen: boolean,
  sweepstake?: Sweepstake,
  newsletters: Newsletter[],
  onClose: () => void,
  onUpdate: () => void,
}

export interface FormError {
  [index: string]: string;
}

export default function EditSweepstakePopup(props: Readonly<EditSweepstakePopupProps>) {
  const { t } = useTranslation();
  const [sweepstakeFormValues, setSweepstakeFormValues] = useState<Sweepstake>();
  const [formErrors, setFormErrors] = useState<FormError>({});
  const [newsletterOptions, setNewsletterOptions] = useState<SelectOptionsProps[]>([]);

  const prepareSelectOptions = (newsletters: Newsletter[]): SelectOptionsProps[] => {
    const options = newsletters.map(n => {
      return {
        key: n.objectId,
        name: n.name,
        value: n.objectId,
      } as SelectOptionsProps;
    }).sort((a, b) => (a.name > b.name) ? 1 : -1);
    return [{ key: '-null', name: '-', value: undefined }, ...options];
  };

  const validateForm = (): boolean => {
    setFormErrors({});
    let valid = true;
    let newFormErrors = {};
    if (!sweepstakeFormValues?.name) {
      newFormErrors = { ...newFormErrors, name: t('communication.sweepstake.overview.form.validation_name') };
      valid = false;
    }
    if (!sweepstakeFormValues?.code) {
      newFormErrors = { ...newFormErrors, code: t('communication.sweepstake.overview.form.validation_code') };
      valid = false;
    }
    if (!sweepstakeFormValues?.newsletterId || sweepstakeFormValues.newsletterId === '-') {
      newFormErrors = { ...newFormErrors, newsletterId: t('communication.sweepstake.overview.form.validation_newsletter') };
      valid = false;
    }
    setFormErrors(newFormErrors);
    return valid;
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;
    if (!sweepstakeFormValues) return;
    try {
      const response = sweepstakeFormValues.id ? await updateSweepstake(sweepstakeFormValues) : await createSweepstake(sweepstakeFormValues);
      if (response.ok) {
        if (sweepstakeFormValues.id) {
          toast.success(t('communication.sweepstake.overview.popup.success_edit').toString());
        } else {
          toast.success(t('communication.sweepstake.overview.popup.success_create').toString());
        }
        props.onUpdate();
        props.onClose();
        return;
      }
      const apiError = (await response.json()) as ApiError;
      if (apiError?.message && SweepstakeErrorMessageByCode.has(apiError.message)) {
        toast.error(t(SweepstakeErrorMessageByCode.get(apiError.message)!).toString());
        return;
      }
    } catch (e) {
      console.error(e);
      toast.error(t('error_popup.unknown').toString());
    }
  };

  useEffect(() => {
    setSweepstakeFormValues({
      id: props.sweepstake?.id,
      name: props.sweepstake?.name,
      code: props.sweepstake?.code,
      newsletterId: props.sweepstake?.newsletterId,
    });
    setNewsletterOptions(prepareSelectOptions(props.newsletters));
  }, [props.isOpen]);

  return (
    <Modal
      title={props.sweepstake ? t('communication.sweepstake.overview.modal.title_edit') : t('communication.sweepstake.overview.modal.title_create')}
      size={'w-[640px]'}
      show={props.isOpen}
      handleClose={() => props.onClose()}>
      <div className="px-20 pb-3">
        <div className="flex flex-col gap-6 mb-10">
          <BasicInput id="input-name"
                      label={{
                        name: t('communication.sweepstake.overview.form.label_name'),
                        position: 'top',
                      }}
                      value={sweepstakeFormValues?.name ?? ''}
                      onChange={e => setSweepstakeFormValues({ ...sweepstakeFormValues, name: e.target.value })}
                      error={formErrors.name}
                      required/>
          <BasicInput id="input-code"
                      label={{
                        name: t('communication.sweepstake.overview.form.label_code'),
                        position: 'top',
                      }}
                      value={sweepstakeFormValues?.code ?? ''}
                      onChange={e => setSweepstakeFormValues({ ...sweepstakeFormValues, code: e.target.value })}
                      error={formErrors.code}
                      required/>
          <SelectInput id="input-newsletter"
                       label={{
                         name: t('communication.sweepstake.overview.form.label_newsletter'),
                         position: 'top',
                       }}
                       options={newsletterOptions}
                       value={sweepstakeFormValues?.newsletterId ?? ''}
                       onSelect={value => setSweepstakeFormValues({ ...sweepstakeFormValues, newsletterId: value })}
                       error={formErrors.newsletterId}
                       required/>
        </div>
        <div className="flex justify-end gap-2">
          <Button className="bg-white"
                  buttonStyle={ButtonStyle.OUTLINE}
                  onClick={() => props.onClose()}>
            {t('standard_controls.cancel_uppercase')}
          </Button>
          <Button px="5" py="3" onClick={() => handleSubmit()}>
            {t('communication.sweepstake.overview.modal.button_save')}
          </Button>
        </div>
      </div>
    </Modal>
  );
}
